export interface IFileInfo {
  folderName: string;
  subFolderName: string;
  fileName: string;
}

export class FileInfo implements IFileInfo {
  public folderName: string;
  public subFolderName: string;
  public fileName: string;
}
