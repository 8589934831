<div class="card-body">
  <div class="row no-gutters">
    <div class="col-sm-12">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          Development Area
        </label>
        <div class="col-sm-4">
          <kendo-dropdownlist
            [formControl]="developmentArea"
            [data]="developmentAreaOptions"
            [textField]="'name'"
            [valueField]="'id'"
            [disabled]="!canEditCnd"
          ></kendo-dropdownlist>
        </div>
        <label class="col-sm-2 col-form-label">
          Scenario Type
        </label>
        <div class="col-sm-4">
          <kendo-dropdownlist
            [formControl]="scenarioType"
            [data]="scenarioTypeOptions"
            [textField]="'name'"
            [valueField]="'id'"
            [disabled]="!canEditCnd"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          Handover #
        </label>
        <div class="col-sm-4">
          <input
            [formControl]="handoverNumber"
            type="text"
            class="k-textbox form-control"
            maxlength="4"
            [readOnly]="!canEditCnd"
          />
        </div>
        <label class="col-sm-2 col-form-label">
          Scenario Name
        </label>
        <div class="col-sm-4">
          <input
            type="text"
            class="k-textbox form-control"
            [formControl]="scenarioName"
            maxlength="128"
            [readOnly]="!canEditCnd"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          Greenfield Install Timeframe (months)
        </label>
        <div class="col-sm-4">
          <input
            [formControl]="greenfieldInstallTimeframe"
            type="text"
            class="k-textbox form-control"
            maxlength="2"
            [readOnly]="!canEditCnd"
          />
        </div>
        <label class="col-sm-2 col-form-label">
          Contingency
        </label>
        <div class="col-sm-4">
          <kendo-numerictextbox
            #inputContingency
            (valueChange)="handleContingencyChange($event)"
            [format]="formatOptions"
            [decimals]="2"
            [step]="0.01"
            [min]="0"
            [max]="100"
            [value]="contingency"
            [autoCorrect]="true"
            [class]="cellClassForContingency()"
            [disabled]="!canEditCnd"
            width="50%"
          ></kendo-numerictextbox>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          Flowline Threshold (ft)
        </label>
        <div class="col-sm-4">
          <input
            [formControl]="flowlineThreshold"
            type="text"
            class="k-textbox form-control"
            maxlength="6"
            [readOnly]="!canEditCnd"
          />
        </div>
        <label class="col-sm-2 col-form-label">
          Assumed # Wells per Test Separator
        </label>
        <div class="col-sm-4">
          <input
            [formControl]="assumedNumWellsPerTestSeparator"
            type="text"
            class="k-textbox form-control"
            maxlength="3"
            [readOnly]="!canEditCnd"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label class="col-form-label">
            Cost Year:
          </label>
        </div>
        <div class="col-sm-4">
          <kendo-dropdownlist
            [data]="lstYears"
            [(ngModel)]="selectedYear"
            (valueChange)="handleChangeYear($event)"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
          <button kendoButton (click)="updateScenarioCost()" style="margin:5px" *ngIf="showUpdateCostButton">
            Update Cost
          </button>
        </div>
      </div>
      <div class="form-group row">
        <app-input-summary-upload
          [developmentAreaId]="this.developmentArea.value"
          [scenarioTypeId]="scenarioType"
          (openDeletePads)="onOpenDeletePads($event)"
          (openCopyPads)="onOpenCopyPads($event)"
          (openActiveForOutlook)="onOpenActiveForOutlook($event)"
          (openSmartImport)="onOpenSmartImport($event)"
          (openImportPadBuilder)="onOpenImportPadBuilder($event)"
          (refreshSmartImportPads)="onRefreshPadsSmartImport($event)"

        ></app-input-summary-upload>
      </div>
    </div>
  </div>
</div>
