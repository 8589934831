<button kendoButton class="k-button" (click)="open()" primary="true" [disabled]="disabled">
  {{ buttonText }}
</button>

<kendo-dialog
  title="Failed Validations"
  *ngIf="messageDialogOpened"
  (close)="close()"
  minWidth="250"
  height="525"
  width="900"
>
  <div class="row">
    <div class="col-md-12" style="padding-bottom:2%">
      <table title="Duplicate Check" class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th>Properties</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of duplicateArrayList">
            <td>{{ item }}</td>
            <td>{{ duplicateError }}</td>
          </tr>
          <tr *ngFor="let item of blanksFound">
            <td>Blank {{ item.errorMessageName }}</td>
            <td>{{ item.errorMessageName }} is required for all items</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="close()" primary="true">OK</button>
  </kendo-dialog-actions>
</kendo-dialog>
