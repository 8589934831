import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { FitCcInputTypes } from '../../models/fit-cc-input-types.enum';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'fit-cc-input',
  templateUrl: './fit-cc-input.component.html',
  styleUrls: ['./fit-cc-input.component.scss'],
})
// tslint:disable: ban-types
export class FitCCInputComponent implements ControlValueAccessor, OnInit {
  @Input()
  placeholder: string;
  @Input()
  required: boolean;
  @Input()
  max: number;
  @Input()
  min: number;
  @Input()
  decimals: number;
  @Input()
  readonly: boolean;
  @Input()
  spinners: boolean;
  @Input()
  type: FitCcInputTypes;
  @Input()
  disabled: boolean;
  @Input()
  step: number;
  @Input()
  enableErrorMsj = true;
 

  @Output()
  onChange: EventEmitter<any>;

  formControl: FormControl;
  format: NumberFormatOptions | string;
  types = FitCcInputTypes;
  correctType: boolean;
  private ngUnsubscribe: Subject<void>;
  private isInitFormControl: boolean;

  onModelChange: Function;
  onModelTouched: Function;

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
    this.isInitFormControl = false;
    this.ngUnsubscribe = new Subject<void>();
    this.formControl = new FormControl({
      disabled: this.disabled ? this.disabled : this.ngControl.disabled,
      value: this.ngControl.value,
    });
    this.formControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(value => this.onChangeEmit(value));
    this.onChange = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.checkExistType();
    this.checkTypes();
    this.formControl.setValidators(this.required ? [Validators.required] : null);
  }

  private checkExistType() {
    if (FitCcInputTypes[this.type]) {
      this.correctType = true;
    } else {
      this.correctType = false;
    }
  }

  private checkTypes() {
    if (this.type === FitCcInputTypes.DECIMAL) {
      this.format = 'n' + (this.decimals ? this.decimals : '4');
    }
    if (this.type === FitCcInputTypes.INTEGER) {
      this.format = 'n';
    }
    if (this.type === FitCcInputTypes.PERCENTAGE) {
      this.format = 'p2';
      this.max = 1;
      this.min = 0;
      this.spinners = true;
      this.step = 0.1;
    }
    if (this.type === FitCcInputTypes.CURRENCY) {
      this.format = 'c';
    }
    if (this.type === FitCcInputTypes.GEOGRAPHY) {
      this.format = 'n8';
    }
  }

  onChangeEmit(value) {
    if (this.isInitFormControl) {
      this.onModelChange(value);
      this.onChange.emit(value);
    } else {
      this.isInitFormControl = true;
    }
  }

  getFirstError() {
    const objValue = Object.values(this.ngControl.errors)[0];
    if (typeof objValue === 'string' || objValue instanceof String) {
      return objValue;
    } else {
      return Object.keys(this.ngControl.errors)[0];
    }
  }

  onTouchEmit($event) {
    this.onModelTouched($event);
  }

  writeValue(value: any): void {
    this.formControl.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
