import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './can-deactivate.component';
import { ScenarioStoreService } from '../../services/scenario-store.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  public tryExport: boolean;
  public message: string;
  constructor(private scenarioStoreService: ScenarioStoreService) {
    this.scenarioStoreService.tryExport$.subscribe(value => (this.tryExport = value));
  }

  public canDeactivate(component: ComponentCanDeactivate): boolean {
    if (!component.canDeactivate()) {
      this.scenarioStoreService.showSavePopUpBs$.next(true);
    }
    return true;
  }
}
