export const environment = {
  production: true,
  PricetDomainURL: (<any>window)._env.PricetDomainURL,
  SecurityDomainURL: (<any>window)._env.SecurityDomainURL,
  EnvironmentTag: (<any>window)._env.EnvironmentTag,
  PricetWebURL: (<any>window)._env.PricetWebURL,
  PacerDomainURL: (<any>window)._env.PacerDomainURL,
  SmartDomainURL: (<any>window)._env.SmartDomainURL,
  PbsorDomainURL: (<any>window)._env.PbsorDomainURL,
  SmartAppURL: (<any>window)._env.SmartAppURL,
};
