import { Injectable } from '@angular/core';
import { durationInMonths, addMonths } from '@progress/kendo-date-math';
import groupBy from 'lodash.groupby';
import { UtilService } from '../../../services/util.service';
import { InputFacilityAssignmentModel, IPadBracketFields } from './input-facility-assignment.model';
import { OverriddenFieldsService } from '../../../services/overridden-fields.service';
import { assumedSlotsPerHeader } from '../../../api/mock/mockData';
import { IPadBracket } from '../../../api/pad-bracket';
import { keyFacilityItem } from '../../../api/input-item';
import { InputSummaryModel } from '../input-summary/input-summary-model';

@Injectable({
  providedIn: 'root',
})
export class InputFacilityAssignmentService {
  public readonly PadFields: IPadBracketFields;
  public contingencyValue: number;

  constructor(private utilService: UtilService, private overridenFieldsService: OverriddenFieldsService) {
    this.PadFields = InputFacilityAssignmentModel.IPadFields;
  }

  public calculateExecutionCategories(padConfigGridData: any[], greenfieldInstallTimeframe: number): void {
    const sortedPadsByPopDate = this.sortPadsByDate(padConfigGridData, this.PadFields.popDate);
    const sortedPadsBySatelliteRfsd = this.sortPadsByDate(padConfigGridData, this.PadFields.tragetSatelliteRfsd);
    const sortedPadsByCtbRfsd = this.sortPadsByDate(padConfigGridData, this.PadFields.tragetCtbRfsd);
    const sortedPadsByCompressionRfsd = this.sortPadsByDate(padConfigGridData, this.PadFields.compressionRfsd);
    const sortedPadsByWaterDisposalRfsd = this.sortPadsByDate(padConfigGridData, this.PadFields.waterDisposalRfsd);
    const sortedPadsByFracPondRfsd = this.sortPadsByDate(padConfigGridData, this.PadFields.fracPondRfsd);

    this.utilService.forEachPad(padConfigGridData, (padName: string) => {
      this.calculateExecutionCategory(
        padConfigGridData,
        padName,
        this.PadFields.targetSatellite,
        this.PadFields.satelliteExecutionCategory,
        this.PadFields.targetSatellite,
        sortedPadsBySatelliteRfsd,
        greenfieldInstallTimeframe
      );
      this.calculateExecutionCategory(
        padConfigGridData,
        padName,
        this.PadFields.targetCtb,
        this.PadFields.ctbExecutionCategory,
        this.PadFields.targetCtb,
        sortedPadsByCtbRfsd,
        greenfieldInstallTimeframe
      );
      this.calculateExecutionCategory(
        padConfigGridData,
        padName,
        this.PadFields.associatedCompStation,
        this.PadFields.compExecutionCategory,
        this.PadFields.compressionRfsd,
        sortedPadsByCompressionRfsd,
        greenfieldInstallTimeframe
      );
      this.calculateExecutionCategory(
        padConfigGridData,
        padName,
        this.PadFields.associatedSwdFacility,
        this.PadFields.swdExecutionCategory,
        this.PadFields.waterDisposalRfsd,
        sortedPadsByWaterDisposalRfsd,
        greenfieldInstallTimeframe
      );
      this.calculateExecutionCategory(
        padConfigGridData,
        padName,
        this.PadFields.fracPond,
        this.PadFields.pondExecutionCategory,
        this.PadFields.fracPondRfsd,
        sortedPadsByFracPondRfsd,
        greenfieldInstallTimeframe
      );
    });
  }

  public calculateCumulativeWellCounts(padConfigGridData: any[]): void {
    const sortedPadsByPopDate = this.sortPadsByDate(padConfigGridData, this.PadFields.popDate);
    this.utilService.forEachPad(padConfigGridData, (padName: string) => {
      this.calculateCumulativeWellCount(padConfigGridData, padName, sortedPadsByPopDate);
    });
  }

  public calculateExecutionCategory(
    padConfigGridData: any[],
    column: string,
    targetField: string,
    executionCategoryField: string,
    dateDependencyField: string,
    sortedPadsByDate: any[],
    greenfieldInstallTimeframe: number
  ): void {
    if (this.overridenFieldsService.isPadConfigFieldOverridden(column, executionCategoryField)) {
      return;
    }
    const targetValue = this.getPadFieldValue(padConfigGridData, column, targetField);
    const date = this.getPadFieldValue(padConfigGridData, column, dateDependencyField);
    if (!targetValue || targetValue.toUpperCase() === 'N/A') {
      return this.setPadFieldValue(padConfigGridData, column, executionCategoryField, '');
    } else {
      // Code commented for user story -1370100
      //Below code commented because it overide actually value while copying pad details
      //from other scenario and it is not showing copied values as it overide using below code.
     // this.setPadFieldValue(padConfigGridData, column, executionCategoryField, 'Greenfield');
    }
    const firstTargetIndex = sortedPadsByDate.findIndex((element, index, array): boolean => {
      return element[this.utilService.camelize(targetField)]?.toUpperCase() === targetValue.toUpperCase();
    });

    if (sortedPadsByDate[firstTargetIndex].padName === column) {
     // Code commented for user story -1370100
     //Below code commented because it overide actually value while copying pad details
     //from other scenario and it is not showing copied values as it overide using below code.
     //  this.setPadFieldValue(padConfigGridData, column, executionCategoryField, 'Greenfield');
    } else if (sortedPadsByDate[firstTargetIndex][targetField] === targetValue) {
      this.setPadFieldValue(
        padConfigGridData,
        column,
        executionCategoryField,
        sortedPadsByDate[firstTargetIndex][executionCategoryField]
      );
      
      if (
        sortedPadsByDate[firstTargetIndex][executionCategoryField] === 'Greenfield' &&
        durationInMonths(sortedPadsByDate[firstTargetIndex][this.utilService.camelize(dateDependencyField)], date) <
          greenfieldInstallTimeframe
      ) {
        let lastIndex = 0;
        let currIndex = 0;
        for (let i = 0; i < sortedPadsByDate.length; i++) {
          if (
            sortedPadsByDate[i][targetField]?.toUpperCase() === targetValue.toUpperCase() &&
            column !== sortedPadsByDate[i].padName
          ) {
            lastIndex = i;
          }
          if (
            sortedPadsByDate[i][targetField]?.toUpperCase() === targetValue.toUpperCase() &&
            column === sortedPadsByDate[i].padName
          ) {
            currIndex = i;
          }
        }

        const firstTargetIndexArray = sortedPadsByDate.filter((element, index, array): boolean => {
          return element[this.utilService.camelize(targetField)]?.toUpperCase() === targetValue.toUpperCase();
        });

        if (firstTargetIndexArray.length > 2) {
          if (
            sortedPadsByDate[currIndex].padOrder - 1 === sortedPadsByDate[lastIndex].padOrder &&
            durationInMonths(sortedPadsByDate[firstTargetIndex][this.utilService.camelize(dateDependencyField)], date) <
              greenfieldInstallTimeframe
          ) {
            this.setPadFieldValue(
              padConfigGridData,
              column,
              executionCategoryField,
              sortedPadsByDate[lastIndex][executionCategoryField]
            );
          } else {
            this.setPadFieldValue(
              padConfigGridData,
              column,
              executionCategoryField,
              sortedPadsByDate[firstTargetIndex][executionCategoryField]
            );
          }
        } else {
          this.setPadFieldValue(
            padConfigGridData,
            column,
            executionCategoryField,
            sortedPadsByDate[firstTargetIndex][executionCategoryField]
          );
        }
      } else {
        this.setPadFieldValue(padConfigGridData, column, executionCategoryField, 'Brownfield');
      }
      // }
    } else if (
      durationInMonths(sortedPadsByDate[firstTargetIndex][this.utilService.camelize(dateDependencyField)], date) <
      greenfieldInstallTimeframe
    ) {
      this.setPadFieldValue(padConfigGridData, column, executionCategoryField, 'Greenfield');
    } else {
      this.setPadFieldValue(padConfigGridData, column, executionCategoryField, 'Brownfield');
    }
  }

  public updatePadOrder(padConfigGridData: any[], sortedPadsByPopDate: any[]): void {
    for (const sortedPad of sortedPadsByPopDate) {
      const padName = sortedPad[this.utilService.camelize(this.PadFields.padName)];
      this.setPadFieldValue(
        padConfigGridData,
        padName,
        this.PadFields.padOrder,
        sortedPad[this.utilService.camelize(this.PadFields.padOrder)]
      );
    }
  }

  public sortPadsByDate(padConfigGridData: any[], dateDependencyField: string): any[] {
    const padOrderRow = this.getRowByFieldName(padConfigGridData, this.PadFields.padOrder);
    const padPopDatesRow = this.getRowByFieldName(padConfigGridData, this.PadFields.popDate);
    const padRigDownMoveOffDateRow = this.getRowByFieldName(padConfigGridData, this.PadFields.padRigDownMoveOffDate);
    const padSpudDatesRow = this.getRowByFieldName(padConfigGridData, this.PadFields.spudDate);
    const padWellCountRow = this.getRowByFieldName(padConfigGridData, this.PadFields.wellCount);
    const padStatusRow = this.getRowByFieldName(padConfigGridData, this.PadFields.padStatus);
    const padPgnNumberRow = this.getRowByFieldName(padConfigGridData, this.PadFields.pgnNumber);
    const padActiveForOutlookRow = this.getRowByFieldName(padConfigGridData, this.PadFields.activeForOutlook);
    const padDevelopmentAreaRow = this.getRowByFieldName(padConfigGridData, this.PadFields.developmentArea);
    const padIsPartOfCo2Development = this.getRowByFieldName(padConfigGridData, this.PadFields.isPartOfCo2Development);
    const padTargetSatelliteRow = this.getRowByFieldName(padConfigGridData, this.PadFields.targetSatellite);
    const padTargetSatelliteRfsdRow = this.getRowByFieldName(padConfigGridData, this.PadFields.tragetSatelliteRfsd);
    const padTargetCTBRow = this.getRowByFieldName(padConfigGridData, this.PadFields.targetCtb);
    const padTargetCTBRfsdRow = this.getRowByFieldName(padConfigGridData, this.PadFields.tragetCtbRfsd);
    const padTargetTrainRow = this.getRowByFieldName(padConfigGridData, this.PadFields.targetTrain);
    const satelliteExecutionCategoryRow = this.getRowByFieldName(
      padConfigGridData,
      this.PadFields.satelliteExecutionCategory
    );
    const ctbExecutionCategoryRow = this.getRowByFieldName(padConfigGridData, this.PadFields.ctbExecutionCategory);
    const cumulativeWellCountRow = this.getRowByFieldName(padConfigGridData, this.PadFields.cumulativeWellCount);
    const padWellTestLocationRow = this.getRowByFieldName(padConfigGridData, this.PadFields.wellTestLocation);
    const associatedCompStationRow = this.getRowByFieldName(padConfigGridData, this.PadFields.associatedCompStation);
    const compExecutionCategoryRow = this.getRowByFieldName(padConfigGridData, this.PadFields.compExecutionCategory);
    const padCompressionRfsdRow = this.getRowByFieldName(padConfigGridData, this.PadFields.compressionRfsd);
    const associatedSwdFacilityRow = this.getRowByFieldName(padConfigGridData, this.PadFields.associatedSwdFacility);
    const waterDisposalRfsdRow = this.getRowByFieldName(padConfigGridData, this.PadFields.waterDisposalRfsd);
    const swdExecutionCategoryRow = this.getRowByFieldName(padConfigGridData, this.PadFields.swdExecutionCategory);
    const fracPondRow = this.getRowByFieldName(padConfigGridData, this.PadFields.fracPond);
    const fracPondRfsdRow = this.getRowByFieldName(padConfigGridData, this.PadFields.fracPondRfsd);
    const padCumulativeHeaderSlotsRow = this.getRowByFieldName(padConfigGridData, this.PadFields.cumulativeHeaderSlots);
    const padCumulativeTestSeparatorsRow = this.getRowByFieldName(
      padConfigGridData,
      this.PadFields.cumulativeTestSeparators
    );
    const pondExecutionCategoryRow = this.getRowByFieldName(padConfigGridData, this.PadFields.pondExecutionCategory);
    const padContingencyRow = this.getRowByFieldName(padConfigGridData, this.PadFields.padContingency);
    const padCommentsRow = this.getRowByFieldName(padConfigGridData, this.PadFields.comments);
    const isPadNameOverridenRow = this.getRowByFieldName(padConfigGridData, this.PadFields.isPadNameOverriden);
    const yearCostRow = this.getRowByFieldName(padConfigGridData, this.PadFields.yearCost);
    const idRow = this.getRowByFieldName(padConfigGridData, this.PadFields.id);
    const padsArray: any[] = [];
    for (const key in padPopDatesRow) {
      if (padPopDatesRow.hasOwnProperty(key) && key !== 'fieldName') {
        const pad = {};
        pad[this.utilService.camelize(this.PadFields.padName)] = key;
        pad[this.utilService.camelize(this.PadFields.padOrder)] = padOrderRow[key];
        pad[this.utilService.camelize(this.PadFields.popDate)] = padPopDatesRow[key];
        pad[this.utilService.camelize(this.PadFields.padRigDownMoveOffDate)] = padRigDownMoveOffDateRow[key];
        pad[this.utilService.camelize(this.PadFields.spudDate)] = padSpudDatesRow[key];
        pad[this.utilService.camelize(this.PadFields.wellCount)] = padWellCountRow[key];
        pad[this.utilService.camelize(this.PadFields.padStatus)] = padStatusRow[key];
        pad[this.utilService.camelize(this.PadFields.pgnNumber)] = padPgnNumberRow[key];
        pad[this.utilService.camelize(this.PadFields.activeForOutlook)] = padActiveForOutlookRow[key];
        pad[this.utilService.camelize(this.PadFields.developmentArea)] = padDevelopmentAreaRow[key];
        pad[this.utilService.camelize(this.PadFields.isPartOfCo2Development)] = padIsPartOfCo2Development[key];
        pad[this.utilService.camelize(this.PadFields.targetSatellite)] = padTargetSatelliteRow[key];
        pad[this.utilService.camelize(this.PadFields.tragetSatelliteRfsd)] = padTargetSatelliteRfsdRow[key];
        pad[this.utilService.camelize(this.PadFields.targetCtb)] = padTargetCTBRow[key];
        pad[this.utilService.camelize(this.PadFields.tragetCtbRfsd)] = padTargetCTBRfsdRow[key];
        pad[this.utilService.camelize(this.PadFields.targetTrain)] = padTargetTrainRow[key];
        pad[this.utilService.camelize(this.PadFields.satelliteExecutionCategory)] = satelliteExecutionCategoryRow[key];
        pad[this.utilService.camelize(this.PadFields.ctbExecutionCategory)] = ctbExecutionCategoryRow[key];
        pad[this.utilService.camelize(this.PadFields.cumulativeWellCount)] = cumulativeWellCountRow[key];
        pad[this.utilService.camelize(this.PadFields.cumulativeHeaderSlots)] = padCumulativeHeaderSlotsRow[key];
        pad[this.utilService.camelize(this.PadFields.cumulativeTestSeparators)] = padCumulativeTestSeparatorsRow[key];
        pad[this.utilService.camelize(this.PadFields.wellTestLocation)] = padWellTestLocationRow[key];
        pad[this.utilService.camelize(this.PadFields.associatedCompStation)] = associatedCompStationRow[key];
        pad[this.utilService.camelize(this.PadFields.compressionRfsd)] = padCompressionRfsdRow[key];
        pad[this.utilService.camelize(this.PadFields.compExecutionCategory)] = compExecutionCategoryRow[key];
        pad[this.utilService.camelize(this.PadFields.associatedSwdFacility)] = associatedSwdFacilityRow[key];
        pad[this.utilService.camelize(this.PadFields.waterDisposalRfsd)] = waterDisposalRfsdRow[key];
        pad[this.utilService.camelize(this.PadFields.swdExecutionCategory)] = swdExecutionCategoryRow[key];
        pad[this.utilService.camelize(this.PadFields.fracPond)] = fracPondRow[key];
        pad[this.utilService.camelize(this.PadFields.fracPondRfsd)] = fracPondRfsdRow[key];
        pad[this.utilService.camelize(this.PadFields.pondExecutionCategory)] = pondExecutionCategoryRow[key];
        pad[this.utilService.camelize(this.PadFields.padContingency)] = padContingencyRow[key];
        pad[this.utilService.camelize(this.PadFields.comments)] = padCommentsRow[key];
        pad[this.utilService.camelize(this.PadFields.isPadNameOverriden)] = isPadNameOverridenRow
          ? isPadNameOverridenRow[key]
          : false;
        pad[this.utilService.camelize(this.PadFields.yearCost)] = yearCostRow ? yearCostRow[key] : null;
        pad[this.utilService.camelize(this.PadFields.id)] = idRow ? idRow[key] : 0;
        if (pad[this.utilService.camelize(dateDependencyField)]) {
          padsArray.push(pad);
        }
      }
    }
    padsArray.sort((a, b) => {
      const timeA = a[this.utilService.camelize(dateDependencyField)].getTime();
      const timeB = b[this.utilService.camelize(dateDependencyField)].getTime();
      if (timeA - timeB < 0) {
        return -1;
      } else if (timeA - timeB > 0) {
        return 1;
      } else if (timeA === timeB) {
        const popTimeA = a[this.utilService.camelize(this.PadFields.popDate)].getTime();
        const popTimeB = b[this.utilService.camelize(this.PadFields.popDate)].getTime();
        if (popTimeA - popTimeB < 0) {
          return -1;
        } else if (popTimeA - popTimeB > 0) {
          return 1;
        } else if (popTimeA === popTimeB) {
          const spudTimeA = a[this.utilService.camelize(this.PadFields.spudDate)].getTime();
          const spudtimeB = b[this.utilService.camelize(this.PadFields.spudDate)].getTime();
          if (spudTimeA - spudtimeB < 0) {
            return -1;
          } else if (spudTimeA - spudtimeB > 0) {
            return 1;
          } else if (spudTimeA === spudtimeB) {
            const padNameA = a[this.utilService.camelize(this.PadFields.padName)].toUpperCase();
            const padNameB = b[this.utilService.camelize(this.PadFields.padName)].toUpperCase();
            if (padNameA < padNameB) {
              return -1;
            } else if (padNameA > padNameB) {
              return 1;
            }
            return 0;
          }
        }
      }
    });
    if (dateDependencyField === this.PadFields.popDate) {
      let i = 0;
      for (const pad of padsArray) {
        pad[this.utilService.camelize(this.PadFields.padOrder)] = i;
        i++;
      }
    }
    return padsArray;
  }

  public calculateCumulativeWellCount(padConfigGridData: any[], padName: string, sortedPadsByPopDate: any[]): void {
    const wellTestLocation = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.wellTestLocation);
    const padOrder = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.padOrder);
    const wellCount: number = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.wellCount);
    let cumulativeWellCount: number = sortedPadsByPopDate.reduce((accumulator, currentPad, index, array) => {
      if (
        currentPad.wellTestLocation?.toUpperCase() === wellTestLocation?.toUpperCase() &&
        currentPad[this.utilService.camelize(this.PadFields.padOrder)] < padOrder &&
        currentPad.padName !== padName
      ) {
        return (accumulator += currentPad.wellCount);
      } else {
        return accumulator;
      }
    }, 0);
    cumulativeWellCount += wellCount;
    this.setPadFieldValue(padConfigGridData, padName, this.PadFields.cumulativeWellCount, cumulativeWellCount);
  }

  public calculateIndividualHeaderSlotsCtbTrains(
    padConfigGridData: any[],
    padName: string,
    infraItemsGridData: any[]
  ): number {
    let individualHeaderCount = 0;
    for (const infraItem of infraItemsGridData) {
      if (
        (infraItem.infraestructureScopeCategory &&
          infraItem.infraestructureScopeCategory.name === 'New CTB Trains' &&
          infraItem.itemDetail.name === '12000 BOPD') ||
        (infraItem.infraestructureScopeCategory &&
          infraItem.infraestructureScopeCategory.name === 'New CTB Trains' &&
          infraItem.itemDetail.name === '8500 BOPD') ||
        (infraItem.infraestructureScopeCategory &&
          infraItem.infraestructureScopeCategory.name === 'New CTB Trains' &&
          infraItem.itemDetail.name === 'Other')
      ) {
        individualHeaderCount += infraItem[padName] * 2;
      }
      if (
        infraItem.infraestructureScopeCategory &&
        infraItem.infraestructureScopeCategory.name === 'New CTB Trains' &&
        infraItem.itemDetail.name === 'Truncated'
      ) {
        individualHeaderCount += infraItem[padName];
      }
    }
    individualHeaderCount *= assumedSlotsPerHeader;
    return individualHeaderCount;
  }

  public calculateIndividualHeaderSlotsSatellites(
    padConfigGridData: any[],
    padName: string,
    infraItemsGridData: any[]
  ): number {
    let individualHeaderCount = 0;
    for (const infraItem of infraItemsGridData) {
      if (infraItem.infraestructureScopeCategory && infraItem.infraestructureScopeCategory.name === 'New Satellites') {
        individualHeaderCount += infraItem[padName] * 2;
      }
    }
    individualHeaderCount *= assumedSlotsPerHeader;
    return individualHeaderCount;
  }

  public calculateIndividualTestSeparators(
    padName: string,
    infraItemsGridData: any[],
    infraScopeCategory: string
  ): number {
    let individualTestSeparatorsCount = 0;
    for (const infraItem of infraItemsGridData) {
      if (
        infraItem.infraestructureScopeCategory &&
        infraItem.infraestructureScopeCategory.name === infraScopeCategory
      ) {
        individualTestSeparatorsCount += infraItem[padName];
      }
    }
    return individualTestSeparatorsCount;
  }

  public calculateWellTestLocations(padConfigGridData: any[]): void {
    this.utilService.forEachPad(padConfigGridData, padName => {
      if (!this.overridenFieldsService.isPadConfigFieldOverridden(padName, this.PadFields.wellTestLocation)) {
        const targetSatellite = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.targetSatellite);
        const targetCtb = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.targetCtb);
        const targetTrain = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.targetTrain);
        if (targetSatellite?.toUpperCase() !== 'N/A' && targetSatellite?.toUpperCase() !== '') {
          this.setPadFieldValue(padConfigGridData, padName, this.PadFields.wellTestLocation, targetSatellite);
        } else if (!targetCtb && !targetTrain) {
          this.setPadFieldValue(padConfigGridData, padName, this.PadFields.wellTestLocation, 'Not Assigned');
        } else if (!targetTrain) {
          this.setPadFieldValue(padConfigGridData, padName, this.PadFields.wellTestLocation, targetCtb);
        } else {
          this.setPadFieldValue(
            padConfigGridData,
            padName,
            this.PadFields.wellTestLocation,
            targetCtb + ' ' + targetTrain
          );
        }
      }
    });
  }

  public calculateRfsd(padConfigGridData: any[], facilityDependencyField: string, rfsdDependencyField: string): void {
    this.utilService.forEachPad(padConfigGridData, padName => {
      if (this.overridenFieldsService.isPadConfigFieldOverridden(padName, rfsdDependencyField)) {
        return;
      }
      const facility = this.getPadFieldValue(padConfigGridData, padName, facilityDependencyField);
      const popDate = this.getPadFieldValue(padConfigGridData, padName, this.PadFields.popDate);
      if (facilityDependencyField.toUpperCase() === this.PadFields.fracPond.toUpperCase()) {
        this.setPadFieldValue(padConfigGridData, padName, rfsdDependencyField, addMonths(popDate, -4));
      } else {
        this.setPadFieldValue(padConfigGridData, padName, rfsdDependencyField, popDate);
      }
    });
  }

  public calculateCumulativeHeaderSlots(
    inputItemsData: any[],
    orderedPads: IPadBracket[],
    padConfigGridData: any[]
  ): void {
    const padsGroupedByWellTestLocation = groupBy(orderedPads, (pad: IPadBracket) => {
      return pad.wellTestLocation;
    });
    for (const wellTestLocation in padsGroupedByWellTestLocation) {
      if (padsGroupedByWellTestLocation.hasOwnProperty(wellTestLocation)) {
        const orderedPadsByWellTestLocation: IPadBracket[] = padsGroupedByWellTestLocation[wellTestLocation];
        let headerSlotsCountByWellTestLocation = 0;
        for (const orderedPad of orderedPadsByWellTestLocation) {
          const padName = orderedPad.padName;

          let headerSlotsCount = 0;

          const ctbHeaderCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_HEADER);
          const satelliteHeaderCount = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.SATELLITE_HEADER
          );
          const targetSatelliteValue = this.getPadFieldValue(
            padConfigGridData,
            padName,
            this.PadFields.targetSatellite
          );

          const isBlank = !targetSatelliteValue;

          if (isBlank) {
            headerSlotsCount = ctbHeaderCount * 4;
          } else {
            headerSlotsCount = satelliteHeaderCount * 4;
          }
          if (!this.overridenFieldsService.isPadConfigFieldOverridden(padName, this.PadFields.cumulativeHeaderSlots)) {
            this.setPadFieldValue(
              padConfigGridData,
              padName,
              this.PadFields.cumulativeHeaderSlots,
              headerSlotsCount + headerSlotsCountByWellTestLocation
            );

            headerSlotsCountByWellTestLocation = headerSlotsCount + headerSlotsCountByWellTestLocation;
          } else {
            headerSlotsCountByWellTestLocation = this.getPadFieldValue(
              padConfigGridData,
              padName,
              this.PadFields.cumulativeHeaderSlots
            );
          }
        }
      }
    }
  }

  public calculateCumulativeTestSeparators(
    inputItemsData: any[],
    orderedPads: IPadBracket[],
    padConfigGridData: any[]
  ): void {
    const padsGroupedByWellTestLocation = groupBy(orderedPads, (pad: IPadBracket) => {
      return pad.wellTestLocation;
    });
    for (const wellTestLocation in padsGroupedByWellTestLocation) {
      if (padsGroupedByWellTestLocation.hasOwnProperty(wellTestLocation)) {
        const orderedPadsByWellTestLocation: IPadBracket[] = padsGroupedByWellTestLocation[wellTestLocation];
        let testSeparatorsCountByWellTestLocation = 0;
        for (const orderedPad of orderedPadsByWellTestLocation) {
          const padName = orderedPad.padName;
          let testSeparatorsCount = 0;

          const targetSatelliteValue = this.getPadFieldValue(
            padConfigGridData,
            padName,
            this.PadFields.targetSatellite
          );

          if (!targetSatelliteValue) {
            testSeparatorsCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_TEST_SEPARATOR);
          } else {
            testSeparatorsCount = this.getInputItemCount(
              inputItemsData,
              padName,
              keyFacilityItem.SATELLITE_TEST_SEPARATOR
            );
          }

          if (
            !this.overridenFieldsService.isPadConfigFieldOverridden(padName, this.PadFields.cumulativeTestSeparators)
          ) {
            this.setPadFieldValue(
              padConfigGridData,
              padName,
              this.PadFields.cumulativeTestSeparators,
              testSeparatorsCount + testSeparatorsCountByWellTestLocation
            );
            testSeparatorsCountByWellTestLocation = testSeparatorsCount + testSeparatorsCountByWellTestLocation;
          } else {
            testSeparatorsCountByWellTestLocation = this.getPadFieldValue(
              padConfigGridData,
              padName,
              this.PadFields.cumulativeTestSeparators
            );
          }
        }
      }
    }
  }

  public recalculateExtraHeaderSlots(
    inputItemsData: any[],
    orderedPads: IPadBracket[],
    padConfigGridData: any[]
  ): void {
    const padsGroupedByWellTestLocation = groupBy(orderedPads, (pad: IPadBracket) => {
      return pad.wellTestLocation;
    });
    for (const wellTestLocation in padsGroupedByWellTestLocation) {
      if (padsGroupedByWellTestLocation.hasOwnProperty(wellTestLocation)) {
        const orderedPadsByWellTestLocation: IPadBracket[] = padsGroupedByWellTestLocation[wellTestLocation];
        let cumulativeHeaderSlotsCount = 0;
        for (const orderedPad of orderedPadsByWellTestLocation) {
          const padName = orderedPad.padName;
          let headerSlotsCount = 0;
          const ctbHeaderSlots = this.calculateIndividualHeaderSlotsCtbTrains(
            padConfigGridData,
            padName,
            inputItemsData
          );
          const satelliteHeaderSlots = this.calculateIndividualHeaderSlotsSatellites(
            padConfigGridData,
            padName,
            inputItemsData
          );
          const cumulativeWellCount = orderedPad.cumulativeWellCount;
          let extraCtbHeaders = 0;
          let extraSatelliteHeaders = 0;
          const extraCtbHeadersItem = inputItemsData.find(item => item.name === 'CTB Header');
          if (!extraCtbHeadersItem) {
            return;
          }
          const extraSatelliteHeadersItem = inputItemsData.find(item => item.name === 'Satellite Header');
          const greenfieldSatelliteInstall = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
          );

          if (wellTestLocation?.toUpperCase() === orderedPad.targetSatellite?.toUpperCase()) {
            headerSlotsCount = satelliteHeaderSlots + cumulativeHeaderSlotsCount + ctbHeaderSlots;
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, 'CTB Header')) {
              extraCtbHeaders = -1 * (ctbHeaderSlots / assumedSlotsPerHeader);
              headerSlotsCount -= ctbHeaderSlots;
            } else {
              headerSlotsCount += extraCtbHeadersItem[padName] * assumedSlotsPerHeader;
              extraCtbHeaders = extraCtbHeadersItem[padName];
            }
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, 'Satellite Header')) {
              if (greenfieldSatelliteInstall === 1) {
                extraSatelliteHeaders = 4;
              }
              if (headerSlotsCount < cumulativeWellCount) {
                const requiredHeaderSlots = cumulativeWellCount - headerSlotsCount;
                extraSatelliteHeaders += Math.ceil(requiredHeaderSlots / assumedSlotsPerHeader);

                if (greenfieldSatelliteInstall === 1) {
                  headerSlotsCount += extraSatelliteHeaders * assumedSlotsPerHeader + 4;
                } else {
                  headerSlotsCount += extraSatelliteHeaders * assumedSlotsPerHeader;
                }
              }
            } else {
              headerSlotsCount += extraSatelliteHeadersItem[padName] * assumedSlotsPerHeader;
              extraSatelliteHeaders = extraSatelliteHeadersItem[padName];
            }
            extraCtbHeadersItem[padName] = extraCtbHeaders;
            extraSatelliteHeadersItem[padName] = extraSatelliteHeaders;
            cumulativeHeaderSlotsCount = headerSlotsCount;
          } else {
            // dont add implicit coreGreenfieldSatellite headers?
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_HEADER)) {
              const baseGreenfieldCtbInstall = this.getInputItemCount(
                inputItemsData,
                padName,
                keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
              );
              const oneTrainCtbAddition = this.getInputItemCount(
                inputItemsData,
                padName,
                keyFacilityItem.TRAIN_1_CTB_ADDITION
              );

              if (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) {
                extraCtbHeaders = 8;
              } else if (
                (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
                (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
              ) {
                extraCtbHeaders = 4;
              } else {
                extraCtbHeaders = 0;
              }

              extraCtbHeadersItem[padName] = extraCtbHeaders;

              this.calculateCumulativeHeaderSlots(inputItemsData, orderedPads, padConfigGridData);

              headerSlotsCount = this.getPadFieldValue(
                padConfigGridData,
                padName,
                this.PadFields.cumulativeHeaderSlots
              );

              // headerSlotsCount = ctbHeaderSlots + satelliteHeaderSlots + cumulativeHeaderSlotsCount;

              if (headerSlotsCount < cumulativeWellCount) {
                const requiredHeaderSlots = cumulativeWellCount - headerSlotsCount;
                extraCtbHeaders += Math.ceil(requiredHeaderSlots / assumedSlotsPerHeader);
              }
            } else {
              headerSlotsCount += extraCtbHeadersItem[padName] * assumedSlotsPerHeader;
              extraCtbHeaders = extraCtbHeadersItem[padName];
            }
            if (this.overridenFieldsService.isPadItemCountOverridden(padName, 'Satellite Header')) {
              headerSlotsCount += extraSatelliteHeadersItem[padName] * assumedSlotsPerHeader;
              extraSatelliteHeaders = extraSatelliteHeadersItem[padName];
            }
            extraCtbHeadersItem[padName] = extraCtbHeaders;
            extraSatelliteHeadersItem[padName] = extraSatelliteHeaders;
            this.calculateCumulativeHeaderSlots(inputItemsData, orderedPads, padConfigGridData);

            cumulativeHeaderSlotsCount = headerSlotsCount;
          }
        }
      }
    }
  }

  public recalculateExtraTestSeparators(
    inputItemsData: any[],
    orderedPads: IPadBracket[],
    padConfigGridData: any[],
    assumedNumWellsPerTestSeparator: number
  ): void {
    const padsGroupedByWellTestLocation = groupBy(orderedPads, (pad: IPadBracket) => {
      return pad.wellTestLocation;
    });
    for (const wellTestLocation in padsGroupedByWellTestLocation) {
      if (padsGroupedByWellTestLocation.hasOwnProperty(wellTestLocation)) {
        const orderedPadsByWellTestLocation: IPadBracket[] = padsGroupedByWellTestLocation[wellTestLocation];
        let cumulativeTestSeparators = 0;
        for (const orderedPad of orderedPadsByWellTestLocation) {
          const padName = orderedPad.padName;
          let testSeparatorsCount = 0;
          const ctbTestSeparators = this.calculateIndividualTestSeparators(padName, inputItemsData, 'New CTB Trains');
          const satelliteTestSeparators = this.calculateIndividualTestSeparators(
            padName,
            inputItemsData,
            'New Satellites'
          );
          const cumulativeWellCount = orderedPad.cumulativeWellCount;
          let extraCtbTestSeparators = 0;
          let extraSatelliteTestSeparators = 0;
          const extraCtbTestSeparatorsItem = inputItemsData.find(item => item.name === 'CTB Test Separator');
          if (!extraCtbTestSeparatorsItem) {
            return;
          }
          const extraSatelliteTestSeparatorsItem = inputItemsData.find(
            item => item.name === 'Satellite Test Separator'
          );
          const greenfieldSatelliteInstall = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
          );

          if (wellTestLocation?.toUpperCase() === orderedPad.targetSatellite?.toUpperCase()) {
            testSeparatorsCount = satelliteTestSeparators + cumulativeTestSeparators + ctbTestSeparators;
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, 'CTB Test Separator')) {
              extraCtbTestSeparators = -1 * ctbTestSeparators;
              testSeparatorsCount -= ctbTestSeparators;
            } else {
              testSeparatorsCount += extraCtbTestSeparatorsItem[padName];
              extraCtbTestSeparators = extraCtbTestSeparatorsItem[padName];
            }
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, 'Satellite Test Separator')) {
              if (greenfieldSatelliteInstall === 1) {
                extraSatelliteTestSeparators = 3;
              }
              if (testSeparatorsCount * assumedNumWellsPerTestSeparator < cumulativeWellCount) {
                const requiredHeaderSlots = cumulativeWellCount - testSeparatorsCount * assumedNumWellsPerTestSeparator;
                extraSatelliteTestSeparators += Math.ceil(requiredHeaderSlots / assumedNumWellsPerTestSeparator);
                if (greenfieldSatelliteInstall === 1) {
                  testSeparatorsCount += extraSatelliteTestSeparators + 3;
                } else {
                  testSeparatorsCount += extraSatelliteTestSeparators;
                }
              }
            } else {
              testSeparatorsCount += extraSatelliteTestSeparatorsItem[padName];
              extraSatelliteTestSeparators = extraSatelliteTestSeparatorsItem[padName];
            }
            extraCtbTestSeparatorsItem[padName] = extraCtbTestSeparators;
            extraSatelliteTestSeparatorsItem[padName] = extraSatelliteTestSeparators;
            cumulativeTestSeparators = testSeparatorsCount;
          } else {
            testSeparatorsCount = ctbTestSeparators + satelliteTestSeparators + cumulativeTestSeparators;
            if (!this.overridenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_TEST_SEPARATOR)) {
              const baseGreenfieldCtbInstall = this.getInputItemCount(
                inputItemsData,
                padName,
                keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
              );
              const oneTrainCtbAddition = this.getInputItemCount(
                inputItemsData,
                padName,
                keyFacilityItem.TRAIN_1_CTB_ADDITION
              );
              if (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) {
                extraCtbTestSeparators = 6;
              } else if (
                (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
                (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
              ) {
                extraCtbTestSeparators = 3;
              } else {
                extraCtbTestSeparators = 0;
              }

              extraCtbTestSeparatorsItem[padName] = extraCtbTestSeparators;
              this.calculateCumulativeTestSeparators(inputItemsData, orderedPads, padConfigGridData);

              testSeparatorsCount = this.getPadFieldValue(
                padConfigGridData,
                padName,
                this.PadFields.cumulativeTestSeparators
              );

              if (testSeparatorsCount * assumedNumWellsPerTestSeparator < cumulativeWellCount) {
                const requiredHeaderSlots = cumulativeWellCount - testSeparatorsCount * assumedNumWellsPerTestSeparator;
                extraCtbTestSeparators += Math.ceil(requiredHeaderSlots / assumedNumWellsPerTestSeparator);
              }
            } else {
              testSeparatorsCount += extraCtbTestSeparatorsItem[padName];
              extraCtbTestSeparators = extraCtbTestSeparatorsItem[padName];
            }
            if (this.overridenFieldsService.isPadItemCountOverridden(padName, 'Satellite Test Separator')) {
              testSeparatorsCount += extraSatelliteTestSeparatorsItem[padName];
              extraSatelliteTestSeparators = extraSatelliteTestSeparatorsItem[padName];
            }
            extraCtbTestSeparatorsItem[padName] = extraCtbTestSeparators;
            extraSatelliteTestSeparatorsItem[padName] = extraSatelliteTestSeparators;
            this.calculateCumulativeTestSeparators(inputItemsData, orderedPads, padConfigGridData);
          }
        }
      }
    }
  }

  public calculateContingencyPerPad(padConfigGridData: any[], inputSummaryContingency: number): void {
    this.utilService.forEachPad(padConfigGridData, padName => {
      if (!this.overridenFieldsService.isPadConfigFieldOverridden(padName, this.PadFields.padContingency)) {
        this.setPadFieldValue(padConfigGridData, padName, this.PadFields.padContingency, inputSummaryContingency);
      }
    });
  }

  // Same code in openScenarioService, important when the code change.
  public transformPadBuilderData(padBuilderData: any[], contingencyValue: number, summary: InputSummaryModel): any[] {
    const padConfigData = [];
    // temp while fixing invalid names
    for (const padBuilder of padBuilderData) {
      padBuilder.pbPadName = padBuilder.padName;
      padBuilder.padName = this.utilService.camelize(padBuilder.padName);
    }
    for (const fieldName in this.PadFields) {
      if (this.PadFields.hasOwnProperty(fieldName)) {
        const padConfig = {};
        switch (fieldName) {
          case 'padOrder':
            padConfig['fieldName'] = {
              name: 'padOrder',
              displayName: 'Pad Order',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.padOrder;
            }
            break;
          case 'spudDate':
            padConfig['fieldName'] = {
              name: 'spudDate',
              displayName: 'SPUD Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = new Date(padBuilder.spudDate);
            }
            break;
          case 'padRigDownMoveOffDate':
            padConfig['fieldName'] = {
              name: 'padRigDownMoveOffDate',
              displayName: 'Pad RDMO Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] =
                padBuilder.padRigDownMoveOffDate === undefined || padBuilder.padRigDownMoveOffDate === null
                  ? null
                  : new Date(padBuilder.padRigDownMoveOffDate);
            }
            break;
          case 'popDate':
            padConfig['fieldName'] = {
              name: 'popDate',
              displayName: 'POP Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = new Date(padBuilder.popDate);
            }
            break;
          case 'wellCount':
            padConfig['fieldName'] = {
              name: 'wellCount',
              displayName: 'Well Count',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.wellCount;
            }
            break;
          case 'padStatus':
            padConfig['fieldName'] = {
              name: 'padStatus',
              displayName: 'Pad Status',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = 'C&D Space';
            }
            break;
          case 'pgnNumber':
            padConfig['fieldName'] = {
              name: 'pgnNumber',
              displayName: 'PGN #',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'activeForOutlook':
            padConfig['fieldName'] = {
              name: 'activeForOutlook',
              displayName: 'Active For Outlook',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'developmentArea':
            padConfig['fieldName'] = {
              name: 'developmentArea',
              displayName: 'Development Area',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = summary.developmentArea.name;
            }
            break;
          case 'isPartOfCo2Development':
            padConfig['fieldName'] = {
              name: 'isPartOfCo2Development',
              displayName: 'Part of CO2 Development',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = 'No';
            }
            break;
          case 'targetSatellite':
            padConfig['fieldName'] = {
              name: 'targetSatellite',
              displayName: 'Target Satellite',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.targetSatellite;
            }
            break;
          case 'tragetSatelliteRfsd':
            padConfig['fieldName'] = {
              name: 'tragetSatelliteRfsd',
              displayName: 'Satellite RFSD',
              isVisible: false,
              parentName: 'targetSatellite',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = null;
            }
            break;
          case 'satelliteExecutionCategory':
            padConfig['fieldName'] = {
              name: 'satelliteExecutionCategory',
              displayName: 'Satellite Execution Category',
              isVisible: false,
              parentName: 'targetSatellite',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'targetCtb':
            padConfig['fieldName'] = {
              name: 'targetCtb',
              displayName: 'Target CTB',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.targetCtb;
            }
            break;
          case 'tragetCtbRfsd':
            padConfig['fieldName'] = {
              name: 'tragetCtbRfsd',
              displayName: 'CTB RFSD',
              isVisible: false,
              parentName: 'targetCtb',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = null;
            }
            break;
          case 'ctbExecutionCategory':
            padConfig['fieldName'] = {
              name: 'ctbExecutionCategory',
              displayName: 'CTB Execution Category',
              isVisible: false,
              parentName: 'targetCtb',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'targetTrain':
            padConfig['fieldName'] = {
              name: 'targetTrain',
              displayName: 'Target Train',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.ctbTrain;
            }
            break;
          case 'wellTestLocation':
            padConfig['fieldName'] = {
              name: 'wellTestLocation',
              displayName: 'Well Test Location',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'cumulativeWellCount':
            padConfig['fieldName'] = {
              name: 'cumulativeWellCount',
              displayName: 'Cumulative Well Count',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = 0;
            }
            break;
          case 'cumulativeHeaderSlots':
            padConfig['fieldName'] = {
              name: 'cumulativeHeaderSlots',
              displayName: 'Cumulative Header Slots',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = 0;
            }
            break;

          case 'cumulativeTestSeparators':
            padConfig['fieldName'] = {
              name: 'cumulativeTestSeparators',
              displayName: 'Cumulative Test Separators',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = 0;
            }
            break;
          case 'associatedCompStation':
            padConfig['fieldName'] = {
              name: 'associatedCompStation',
              displayName: 'Associated Comp. Station',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.associatedCompStation;
            }
            break;
          case 'compressionRfsd':
            padConfig['fieldName'] = {
              name: 'compressionRfsd',
              displayName: 'Compression RFSD',
              isVisible: false,
              parentName: 'associatedCompStation',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = null;
            }
            break;
          case 'compExecutionCategory':
            padConfig['fieldName'] = {
              name: 'compExecutionCategory',
              displayName: 'Comp. Execution Category',
              isVisible: false,
              parentName: 'associatedCompStation',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;

          case 'associatedSwdFacility':
            padConfig['fieldName'] = {
              name: 'associatedSwdFacility',
              displayName: 'Associated SWD Facility',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = padBuilder.associatedSwdFacility;
            }
            break;
          case 'waterDisposalRfsd':
            padConfig['fieldName'] = {
              name: 'waterDisposalRfsd',
              displayName: 'Water Disposal RFSD',
              isVisible: false,
              parentName: 'associatedSwdFacility',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = null;
            }
            break;
          case 'swdExecutionCategory':
            padConfig['fieldName'] = {
              name: 'swdExecutionCategory',
              displayName: 'SWD Execution Category',
              isVisible: false,
              parentName: 'associatedSwdFacility',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'fracPond':
            padConfig['fieldName'] = {
              name: 'fracPond',
              displayName: 'Frac Pond',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'fracPondRfsd':
            padConfig['fieldName'] = {
              name: 'fracPondRfsd',
              displayName: 'Frac Pond RFSD',
              isVisible: false,
              parentName: 'fracPond',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = null;
            }
            break;
          case 'pondExecutionCategory':
            padConfig['fieldName'] = {
              name: 'pondExecutionCategory',
              displayName: 'Pond Execution Category',
              isVisible: false,
              parentName: 'fracPond',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
          case 'padContingency':
            padConfig['fieldName'] = {
              name: 'padContingency',
              displayName: 'Pad Contingency',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = +contingencyValue;
            }
            break;
          case 'comments':
            padConfig['fieldName'] = {
              name: 'comments',
              displayName: 'Comments',
              isVisible: false,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBuilder of padBuilderData) {
              padConfig[padBuilder.padName] = '';
            }
            break;
        }
        if (Object.keys(padConfig).length > 0) {
          padConfigData.push(padConfig);
        }
      }
    }
    return padConfigData;
  }

  public getPadFieldValue(padConfigGridData: any[], padName: string, fieldName: string): any {
    return padConfigGridData.filter(row => row.fieldName.name === fieldName)[0][padName];
  }

  public getRowByFieldName(padConfigGridData: any[], fieldName: string): any[] {
    return padConfigGridData.filter(row => row.fieldName.name === fieldName)[0];
  }

  public getInputItemCount(inputItemsData: any[], padBracket: string, itemName: string): number {
    const inputItemVal = inputItemsData
      .filter(item => item.name === itemName)
      .reduce((sum, current) => sum + (current[padBracket] != undefined ? current[padBracket] : 0), null);
    return inputItemVal;
  }

  public setPadFieldValue(padConfigGridData: any[], padName: string, fieldName: string, value: any): void {
    padConfigGridData.filter(row => row.fieldName.name === fieldName)[0][padName] = value;
  }

  public updatePadFieldIsVisible(padConfigGridData: any[], fieldName: string, isVisible? : boolean): void {
    if (
      padConfigGridData !== null &&
      padConfigGridData !== undefined &&
      fieldName !== null &&
      fieldName !== undefined
    ) {
      padConfigGridData.forEach(padCfg => {
        if (
          padCfg.fieldName.name !== undefined &&
          padCfg.fieldName.name !== null &&
          padCfg.fieldName.name === fieldName
        ) {
          if(isVisible !== undefined)
          padCfg.fieldName.isExpanded = isVisible;
          else
            padCfg.fieldName.isExpanded = !padCfg.fieldName.isExpanded;
        }
        if (
          padCfg.fieldName.parentName !== undefined &&
          padCfg.fieldName.parentName !== null &&
          padCfg.fieldName.parentName === fieldName
        ) {
          if(isVisible !== undefined)
          padCfg.fieldName.isVisible = isVisible;
          else
          padCfg.fieldName.isVisible = !padCfg.fieldName.isVisible;
        }
      });
    }
  }

  public setPadNameComment(padConfigGridData: any[], padNameChange: string, comment: string): void {
    this.utilService.forEachPad(padConfigGridData, p => {
      if (p !== undefined && p !== null) {
        if (p === padNameChange) {
          this.setPadFieldValue(padConfigGridData, p, this.PadFields.comments, comment);
        }
      }
    });

    // padConfigGridData.forEach(pad => {
    //  if (pad.padName !== undefined && pad.padName !== null) {
    //    if (pad.padName === padName) {
    //      //this.setPadFieldValue(padConfigGridData, pad.padName, this.PadFields.comments, comment);
    //      pad.comments = comment;
    //    }
    //  }
    // });

    // this.utilService.forEachPad(padConfigGridData, p => {
    //  this.setPadFieldValue(padConfigGridData, p , this.PadFields.comments, comment);
    // });
  }
}
