<form
  novalidate
  [formGroup]="formNewBOD"
  id="createNewBODForm"
  #form="ngForm"
  (ngSubmit)="createNewPerfBOD(formNewBOD.value, formNewBOD.valid)"
>
  <div class="card-body">
    <p class="card-title center">
      Create New Performance BOD
    </p>
    <div class="col-sm-10">
      <div class="form-actions text-right">
        <button kendoButton formnovalidate (mousedown)="goBack()" [disabled]="!canEditPerformanceBod">Back</button>
        <span>|</span>
        <button kendoButton form="createNewBODForm" [primary]="true" [disabled]="!canEditPerformanceBod">
          Save
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Basin Area *
          </label>
          <div class="col-sm-2">
            <kendo-dropdownlist
              formControlName="basinId"
              [data]="basinOptions"
              [textField]="'name'"
              [valueField]="'id'"
              (selectionChange)="basinSelectionChange($event)"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-dropdownlist>
          </div>
          <label class="col-sm-1 col-form-label">
            Geographic Area *
          </label>
          <div class="col-sm-2">
            <kendo-dropdownlist
              [disabled]="isDisabledGeographic"
              formControlName="geographicAreaId"
              [data]="geographicAreaOptionsFiltered"
              [textField]="'name'"
              [valueField]="'id'"
              (selectionChange)="geographicAreaSelectionChange($event)"
            ></kendo-dropdownlist>
          </div>
          <label class="col-sm-1 col-form-label text-right">
            Development Area *
          </label>
          <div class="col-sm-2">
            <kendo-dropdownlist
              [disabled]="isDisabledDevelopment"
              formControlName="developmentAreaId"
              [data]="developmentAreaOptionsFiltered"
              [textField]="'name'"
              [valueField]="'id'"
            ></kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <!--AFE Details-->
    <!-- <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Default AFE No.&nbsp;*
          </label>
          <div class="col-sm-2">
            <kendo-maskedtextbox
              formControlName="defaultAfeNumber"
              [value]="defaultAfeNo"
              [maskValidation]="true"
              [mask]="afeMask"
              [disabled]="!canEditPerformanceBod"
              required
            >
            </kendo-maskedtextbox>
          </div>
          <label class="col-sm-1 col-form-label">
            Default AFE Amount *
          </label>
          <div class="col-sm-2">
            <kendo-numerictextbox formControlName="defaultAfeAmount" class="form-control string"></kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div> -->

    <!--AFE Details-->

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Batch *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="batch"
              class="k-textbox form-control"
              maxlength="50"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <label class="col-sm-1 col-form-label">
            Number of Packages *
          </label>
          <div class="col-sm-2">
            <kendo-dropdownlist
              formControlName="packageId"
              [data]="numPackages"
              textField="name"
              valueField="id"
              (valueChange)="handlePackagesChange($event, formNewBOD)"
              [(ngModel)]="selectedItem"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-dropdownlist>
          </div>
          <label *ngIf="selectedItem.id >= 0" class="col-sm-1 col-form-label text-right">
            Package 1 Name *
          </label>
          <div class="col-sm-2" *ngIf="selectedItem.id >= 0">
            <input
              type="text"
              formControlName="package1Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="selectedItem.id >= 2">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-7 col-form-label text-right">
            Package 2 Name *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="package2Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="selectedItem.id >= 3">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-7 col-form-label text-right">
            Package 3 Name *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="package3Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="selectedItem.id >= 4">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-7 col-form-label text-right">
            Package 4 Name *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="package4Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters" *ngIf="selectedItem.id >= 5">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-7 col-form-label text-right">
            Package 5 Name *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="package5Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Performance BOD Name *
          </label>
          <div class="col-sm-2">
            <input
              type="text"
              formControlName="performanceBODName"
              class="k-textbox form-control"
              maxlength="50"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <label class="col-sm-1 col-form-label">
            Project Number
          </label>
          <div class="col-sm-2">
            <input
              type="number"
              max="20000"
              min="0"
              formControlName="projectNumber"
              class="k-textbox form-control"
              maxlength="5"
              oninput="this.value = Math.abs(this.value)"
              onKeyDown="if(this.value.length > 5) { this.value = '';return false;} "
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <label class="col-sm-1 col-form-label">
            Include Unspecified Pads?
          </label>
          <div class="col-sm-2">
            <kendo-dropdownlist
              formControlName="unspecifiedPads"
              [data]="yesNoList"
              textField="text"
              valueField="value"
              [(ngModel)]="selectedUnspecifiedPads"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Performance PM
          </label>
          <div class="col-sm-2">
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="performancePMSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'performancePMSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <label class="col-sm-1 col-form-label">
            Performance Team Lead
          </label>
          <div class="col-sm-2">
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="performanceTLSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'performanceTLSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <label class="col-sm-1 col-form-label text-right">
            Reservoir Engineer
          </label>
          <div class="col-sm-2">
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="reservoirEngineerSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'reservoirEngineerSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            Water Engineer
          </label>
          <div class="col-sm-2">
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="waterEngineerSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'waterEngineerSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-1 col-form-label">
            General Comments
          </label>
          <div class="col-sm-8">
            <textarea
              class="form-control"
              formControlName="generalComments"
              rows="5"
              maxlength="500"
              [readOnly]="!canEditPerformanceBod"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-10">
      <div class="form-actions text-right">
        <button kendoButton formnovalidate (mousedown)="goBack()" [disabled]="!canEditPerformanceBod">Back</button>
        <span>|</span>
        <button kendoButton form="createNewBODForm" [primary]="true" [disabled]="!canEditPerformanceBod">
          Save
        </button>
      </div>
    </div>
  </div>
</form>
