<div class="k-card-body" style="padding-top: 0px;">
    <kendo-tabstrip #tabstrip class="tabstrip-width" [keepTabContent]="true" [animate]="true">
      <kendo-tabstrip-tab
        [title]="'Copy Pad Assignments'"
        [disabled]="copyPadsAssigmentDisabled"
        [selected]="copyPadsAssigmentTab"
      >
        <ng-template kendoTabContent style="padding-top: 0px;">
          <div class="card-body">
            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group row" style="margin-bottom: 0px; padding-top:0px">
                  <div class="col-sm-2 col-form-label">
                    <label>Option:</label>
                  </div>
                  <div class="col-sm-6">
                    <kendo-dropdownlist
                      [data]="optionCopyPadData"
                      [textField]="'name'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [(ngModel)]="optionValue"
                      (valueChange)="optionCopyPadChange($event)"
                      style="width:auto"
                    ></kendo-dropdownlist>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group row">
                  <label class="col-sm-6 col-form-label">
                    From Scenario:
                  </label>
                  <label class="col-sm-6 col-form-label" [hidden]="hiddenOptions">
                    To Scenario:
                  </label>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <kendo-dropdownlist
                      [data]="scenariosFromFiltered"
                      [textField]="'name'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [defaultItem]="defaultItem"
                      (valueChange)="scenarioFromChange($event)"
                      style="width:100%;"
                      [filterable]="true"
                      (filterChange)="handleFilterScenarioFrom($event)"
                      [listHeight] = "400"
                      [popupSettings]="{ height: 400 }"
                    ></kendo-dropdownlist>
                  </div>
                  <div class="col-sm-6">
                    <kendo-dropdownlist
                      [data]="scenariosTo"
                      [textField]="'name'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [(ngModel)]="scenarioId"
                      disabled="true"
                      [popupSettings]="{ width: 320, height: auto }"
                      style="width:100%; height:auto"
                      [hidden]="hiddenOptions"
                      [listHeight] = "400"
                    ></kendo-dropdownlist>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-6">
                    From Pads:
                  </label>
                  <label class="col-sm-6" [hidden]="hiddenOptions">
                    To Pads:
                  </label>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <button kendoButton [primary]="true" class="k-button" (click)="selectAll('From') ">
                      Select All
                    </button>
                    <button kendoButton [primary]="true" class="k-button" (click)="clearAll('From')">
                      Clear All
                    </button>                    
                  </div>
                  <div class="col-sm-6">
                    <button kendoButton [primary]="true" class="k-button" (click)="selectAll('To')" [hidden]="hiddenOptions">
                      Select All
                    </button>
                    <button kendoButton [primary]="true" class="k-button" (click)="clearAll('To')" [hidden]="hiddenOptions">
                      Clear All
                    </button>                    
                  </div>                
                </div>             
                <div class="form-group row">
                  <div class="col-md-4">
                    <kendo-multiselect
                      #multiSelectFrom
                      [data]="padBracketFromListFiltered"
                      textField="padName"
                      valueField="id"
                      [autoClose]="isCloseDisabledFrom"
                      (valueChange)="fromPadChange($event)"
                      (open)="onOpenFrom($event)"
                      [disabled]="fromPadsDisabled"
                      [filterable]="true"
                      (filterChange)="handleFilterScenarioFromSelection($event)"
                    ></kendo-multiselect>
                  </div>
                  <div class="col-md-4 offset-md-2">
                    <kendo-multiselect
                      #multiSelectTo
                      [data]="padBracketToListFiltered"
                      textField="padName"
                      valueField="id"
                      [autoClose]="isCloseDisabledTo"
                      (valueChange)="toPadChange($event)"
                      (open)="onOpenTo($event)"
                      [hidden]="hiddenOptions"
                      [filterable]="true"
                      (filterChange)="handleFilterScenarioToSelection($event)"
                    ></kendo-multiselect>
                  </div>
                </div>
                <div class="form-group row" style="margin-bottom: 100px">
                  <div class="col-sm-12">
                    <button
                      kendoButton
                      [primary]="true"
                      class="k-button"
                      (click)="moveSummary()"
                      [disabled]="nextButtonDisabled"
                      [hidden]="optionValue == 1"
                      style="margin:10px"
                    >
                      {{ buttonName }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        [title]="'Summary'"
        [hidden]="hiddenOptions"
        [disabled]="summaryDisabled"
        [selected]="summaryTab"
      >
        <ng-template kendoTabContent>
          <div class="card-body">
            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group row">
                  <div class="col-sm-5">
                    <p>From Scenario:</p>
                  </div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-5">
                    <p>To Scenario:</p>
                  </div>
                  <div class="col-sm-1"></div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-5">
                    <p>{{ scenarioFromName }}</p>
                  </div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-5">
                    <p>{{ scenarioToName }}</p>
                  </div>
                  <div class="col-sm-1"></div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <kendo-grid [data]="summaryData">
                      <kendo-grid-column field="padNameFrom" title="From Pad Name"></kendo-grid-column>
                      <kendo-grid-column field="padNameTo" title="To Pad Name"></kendo-grid-column>
                    </kendo-grid>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <button kendoButton [primary]="true" class="k-button" (click)="previousPage()">Previous</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>
  <kendo-dialog-actions>
      <button kendoButton (click)="onCancelAction()">Cancel</button>
      <button kendoButton (click)="onClickOk()" [primary]="true" [disabled]="shouldSubmitButtonDisabled()">
        {{ submitButtonName }}
      </button>
  </kendo-dialog-actions>

  <kendo-dialog 
  title="Existing pads" 
  *ngIf="openExistingPadsDialog" 
  (close)="closeExistingPadsDialog()" 
  [width]="1200"
  [height]="600">
  <br />
  <br />
  <div class="card-body-smart-link">
    <div class="row no-gutters">
      <div class="col-sm-10">
        <div class="form-group row">
          <div class="col-sm-4">
            <label class="col-form-label">
              The following pads already exist in the current scenario:
            </label>
          </div>
          <div class="col-sm-6">
            <kendo-multiselect
              #multiSelectExistingPads
              [data]="existingPads"
              textField="padName"
              valueField="id"
              [autoClose]="isCloseDisabledFrom"
              [(ngModel)]="existingPads"
              [disabled]="true"
            ></kendo-multiselect>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-10">
            <label class="col-form-label">
              Submit - Remove the duplicate pads and continue with import
            </label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-10">
            <label class="col-form-label">
              Cancel - Return to 'Import Pads' window
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeExistingPadsDialog()">Cancel</button>
    <button kendoButton primary="true" (click)="submitExistingPadsDialog()">Submit</button>
  </kendo-dialog-actions>
</kendo-dialog>