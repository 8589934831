import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDasSimple } from '../api/das-simple';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import * as Helpers from '../_shared/helpers';
import { ISequence } from '../api/scenarioSequence';
import { IScenarioSequence } from '../api/scenarioSequence';
import { IDevAreaCode } from '../api/scenarioSequence';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const httpOptionsDownload = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType: 'blob' as 'json',
};

@Injectable({
  providedIn: 'root',
})
export class DasSimpleService {
  public baseUrlDasSimple: string = environment.PricetDomainURL + 'DasSimple';
  public dasSimple$: Observable<IDasSimple>;
  public loader: EventEmitter<any>;
  public refresh: EventEmitter<any>;
  public GetSequenceByIdUrl: string = environment.PricetDomainURL + 'SmartExternal/GetSequenceById/';
  public GetSequenceByDevAreaStatusUrl: string =
    environment.PricetDomainURL + 'SmartExternal/GetSequencesByDevAreaAndStatus/';
  public GetDevAreaCodes: string = environment.PricetDomainURL + 'PbsorExternal/GetDevAreas/';
  private dasSimpleValue$: BehaviorSubject<IDasSimple>;
  private handleError: HandleError;
  public baseUrlSmart: string = environment.PricetDomainURL + 'SmartExternal';

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.dasSimpleValue$ = new BehaviorSubject<IDasSimple>(null);
    this.dasSimple$ = this.dasSimpleValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(id: string) {
    this.loader = new EventEmitter();
    this.loader.emit(false);
    this.getDasSimpleById(id).subscribe((dasSimple: IDasSimple) => {
      this.dasSimpleValue$.next(dasSimple);
      this.loader.emit(true);
      this.loader.complete();
    });
  }

  public cleanObservables(): void {
    this.dasSimpleValue$.next(null);
  }

  public delete(removedItems: IDasSimple[]): Observable<any> {
    return this.http
      .post<IDasSimple[]>(`${this.baseUrlDasSimple}/Update`, removedItems, httpOptions)
      .pipe(catchError(this.handleError('updateInfrastructureBodPackage')));
  }

  public getArray(cai: string): Observable<IDasSimple[]> {
    return this.http.get<IDasSimple[]>(`${this.baseUrlDasSimple}/List/`).pipe(
      map((dasSimples: IDasSimple[]) => {
        // here filter logic
        if (cai) {
          dasSimples = dasSimples.filter(
            d =>
              !cai ||
              d.createdBy.toLocaleLowerCase() === cai.toLocaleLowerCase() ||
              !cai ||
              d.updatedBy.toLocaleLowerCase() === cai.toLocaleLowerCase()
          );
        }

        dasSimples = dasSimples.map((item: IDasSimple) => {
          item.createdDate = Helpers.convertUtcToLocalTime(item.createdDate);
          item.updatedDate = Helpers.convertUtcToLocalTime(item.updatedDate);
          return item;
        });

        // this.dasSimpleValue$.next(dasSimples);
        return dasSimples;
      }),
      catchError(error => {
        this.handleError('getArray');
        return throwError(error);
      })
    );
  }

  public nameExist(id: string, name: string): Observable<any> {
    return this.doesInfrastructureBodNameExist(id, name);
  }

  public create(data: IDasSimple[]) {
    this.refresh = new EventEmitter();
    this.refresh.emit(false);
    this.createDasSimple(data).subscribe(
      (result: any) => {
        this.refresh.emit(true);
        this.refresh.complete();
      },
      (error: any) => {
        this.refresh.error(error);
        this.refresh.complete();
      }
    );
  }
  public update(data: IDasSimple[]) {
    this.refresh = new EventEmitter();
    this.refresh.emit(false);
    this.updateDasSimple(data).subscribe(
      (result: any) => {
        this.refresh.emit(true);
        this.refresh.complete();
      },
      (error: any) => {
        this.refresh.error(error);
        this.refresh.complete();
      }
    );
  }

  public setValue(value: any) {
    this.dasSimpleValue$.next(value);
  }

  public getSequenceById(id: string): Observable<ISequence[]> {
    return this.http.get<ISequence[]>(`${this.GetSequenceByIdUrl}${id}`).pipe(
      catchError(error => {
        this.handleError('getSequenceById');
        return throwError(error);
      })
    );
  }

  public getSequenceByDevAreaStatus(devAreaCode: string, status: string): Observable<any[]> {
    return this.http.get<IScenarioSequence[]>(`${this.GetSequenceByDevAreaStatusUrl}${devAreaCode}/${status}`).pipe(
      catchError(error => {
        this.handleError('getSequenceByDevAreaStatus');
        return throwError(error);
      })
    );
  }

  public getDevAreaCodes(): Observable<any> {
    return this.http.get<IDevAreaCode[]>(this.GetDevAreaCodes).pipe(catchError(this.handleError('getDevAreaCodes')));
  }

  public downloadDasSimpleExcelFile(id: string): Observable<Blob> {
    return this.http.post<Blob>(`${this.baseUrlDasSimple}/Download/` + id, null, httpOptionsDownload).pipe(
      catchError(error => {
        this.handleError('downloadDasSimpleExcelFile');
        return throwError(error);
      })
    );
  }

  private getDasSimpleById(id: string): Observable<IDasSimple> {
    return this.http
      .get<IDasSimple>(`${this.baseUrlDasSimple}/GetById/${id}`)
      .pipe(catchError(this.handleError('getDasSimpleById')));
  }

  private doesInfrastructureBodNameExist(id: string, name: string): Observable<any> {
    const data = { id, name };
    return this.http.post<any>(`${this.baseUrlDasSimple}/DoesNameExist/`, data, httpOptions).pipe(
      catchError(error => {
        this.handleError('doesInfrastructureBodNameExist');
        return throwError(error);
      })
    );
  }

  private createDasSimple(data: IDasSimple[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlDasSimple}/Create`, data, httpOptions).pipe(
      catchError(error => {
        this.handleError('createDasSimple');
        return throwError(error);
      })
    );
  }

  private updateDasSimple(data: IDasSimple[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrlDasSimple}/Update`, data, httpOptions).pipe(
      catchError(error => {
        this.handleError('updateDasSimple');
        return throwError(error);
      })
    );
  }

  public UploadDasSimpleCapex(dasSimpleId: string): any {
    let url = `${this.baseUrlSmart}/UploadDasSimpleCapex/${dasSimpleId}`;
    return this.http.get<any>(url, httpOptions).subscribe(data => console.log(data));
  }
}
