import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownListFilterComponent } from './drop-down-list-filter/drop-down-list-filter.component';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InCellTabDirective } from '../_directives/incell-tab.directive';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';
import { MessageToolbarComponent } from '../modules/standard-facility/cost-estimate/message-toolbar/message-toolbar.component';
import { BodDirective } from '../_directives/bod.directive';
import { PAndESignaturesDirective } from '../_directives/p-and-esignatures.directive';
import { SftEnterKeyDirective } from '../_directives/sft-enter-key.directive';
import { PAndEEnterKeyDirective } from '../_directives/p-and-e-enter-key.directive';
import { LogsBodComponent } from './logs-bod/logs-bod.component';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  FitCcFooterComponent,
  FitCcGridModule,
  FitCcUnauthorizedComponent,
} from './fit-common-components-lib/public_api';
import { MasterDataLogComponentComponent } from './master-data-log-component/master-data-log-component.component';
import { SftMasterDataLogComponent } from './sft-master-data-log/sft-master-data-log.component';

@NgModule({
  imports: [CommonModule, DropDownsModule, DropDownListModule, GridModule, FitCcGridModule],
  declarations: [
    DropDownListFilterComponent,
    InCellTabDirective,
    FacilityDetailsComponent,
    MessageToolbarComponent,
    BodDirective,
    PAndESignaturesDirective,
    SftEnterKeyDirective,
    PAndEEnterKeyDirective,
    FitCcFooterComponent,
    FitCcUnauthorizedComponent,
    LogsBodComponent,
    MasterDataLogComponentComponent,
    SftMasterDataLogComponent
  ],
  exports: [
    DropDownListFilterComponent,
    InCellTabDirective,
    FacilityDetailsComponent,
    MessageToolbarComponent,
    BodDirective,
    PAndESignaturesDirective,
    SftEnterKeyDirective,
    PAndEEnterKeyDirective,
    LogsBodComponent,
    FitCcGridModule,
    FitCcFooterComponent,
    FitCcUnauthorizedComponent,
    MasterDataLogComponentComponent,
    SftMasterDataLogComponent
  ],
})
export class SharedModule {}
