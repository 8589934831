import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodFracPond } from 'src/app/api/performance-bod-frac-pond';
import { map } from 'rxjs/operators/map';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodFracPondService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodFracPond[] = [];
  public originalData: IPerformanceBodFracPond[] = [];
  public createdItems: IPerformanceBodFracPond[] = [];
  public updatedItems: IPerformanceBodFracPond[] = [];
  public deletedItems: IPerformanceBodFracPond[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodFracPond[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodFracPond(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodFracPond(this.performanceBodId).pipe(map(data => {
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodFracPond(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodFracPond(this.deletedItems);
  }

}
