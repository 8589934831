import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class SummaryPackageInformationEditService extends BehaviorSubject<any[]> {
  public performanceBodId: string;
  public data: IPerformanceBodPads[] = [];
  private originalData: IPerformanceBodPads[] = [];
  private createdItems: IPerformanceBodPads[] = [];
  private updatedItems: IPerformanceBodPads[] = [];
  private deletedItems: IPerformanceBodPads[] = [];
  private applySetOrder = false;
  private setOrderBy: string;

  constructor(private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
    // for Lines with API Call, check kendo
    // Lines with observable
    
    this.performanceBodStoreService.retrievePerformanceBodPads(this.performanceBodId);
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(padsData => {
      padsData = padsData.filter(row => !row.wellPadName.includes('Unspecified'));
      this.data = padsData;
      this.originalData = cloneData(padsData);
      super.next(padsData);
    });
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length || this.updatedItems.length || this.createdItems.length || this.applySetOrder
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    // Apply order
    this.setOrder();

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceBodPadsWithPeakProduction(this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceBodPadsWithPeakProduction(this.updatedItems));
    }

    if (this.createdItems.length) {
      completed.push(this.performanceBodDataService.createPerformanceBodPadsWithPeakProduction(this.createdItems));
    }

    this.reset();
    zip(...completed).subscribe(() => this.read());
  }

  public cancelChanges(): void {
    this.reset();

    // for Lines with API Call, check kendo
    // Line with observable
    this.performanceBodStoreService.retrievePerformanceBodPads(this.performanceBodId);
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  /**
   *
   * @param columnsToCheck Array {name: 'Name of the column to check',errorMessageName: 'How should the column name should be displayed in the error'}
   * @param BODPackages List of BODPackages if needed (Use packageName as a name in the array to check for this)
   */

  public blanksExists(columnsToCheck, BODPackages?) {
    const blanksArray = new Array();
    columnsToCheck.forEach(element => {
      if (element.name === 'packageName') {
        if (this.checkPerformanceBodPackagesBlanksExist(BODPackages)) {
          blanksArray.push({ name: element.name, errorMessageName: element.errorMessageName });
        }
      } else {
        let arrayToCheck = new Array();
        if (element.name === 'wellPadName') {
          arrayToCheck = this.data.filter(row => row[element.name].trim() === '');
        } else {
          arrayToCheck = this.data.filter(row => row[element.name] === null);
        }

        if (arrayToCheck.length > 0) {
          blanksArray.push({ name: element.name, errorMessageName: element.errorMessageName });
        }
      }
    });
    return blanksArray;
  }

  public checkPerformanceBodPackagesBlanksExist(BODPackages) {
    let foundelement = null;
    this.data.forEach(element => {
      if (foundelement !== undefined) {
        foundelement = BODPackages.find(ob => ob.id === element.performanceBodPackagesId);
      }
    });
    if (foundelement === undefined) {
      return true;
    }
    return false;
  }

  public duplicatesExist(columnName: string): string {
    // cycle through every item to find duplicate
    let dup = '';
    for (let idx = 0; idx < this.data.length; idx++) {
      const e = this.data[idx][columnName].toUpperCase();
      const res = this.data.filter((x, i) => {
        const xData = x[columnName].toUpperCase();
        if (xData === e && idx !== i) {
          if (dup !== '') {
            dup += '--';
          }
          dup += x[columnName].trim().toUpperCase();
          // return x;
        }
      });
    }
    if (dup !== '') {
      const arrDup = dup.split('--');
      const unique = arrDup.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });
      return unique.join('  -  ');
    } else {
      return dup;
    }
  }

  public validateRemovePads(performanceBodPadsIdSelected: string): Observable<boolean> {
    return this.performanceBodDataService.validDeletePerformanceBodPads(performanceBodPadsIdSelected);
  }

  public markAsSetOrder(cai: string): void {
    this.setOrderBy = cai;
    this.applySetOrder = true;
  }

  public applyLogicFromWellPads(): void {
    const padsChanges = this.performanceBodCalculationsService.padNumberOfWellsCalculations(this.data);
    for(const pad of padsChanges) {
      this.update(pad);
    }
  }

  public getDeletedItems(): IPerformanceBodPads[] {
    return this.deletedItems;
  }
  protected setOrder(): void {
    if (this.applySetOrder) {
      if (this.data.length > 0) {
        const obj = this.data[0];
        if (!obj.hasOwnProperty('sortOrder')) {
          return;
        }
      } else {
        // nothing to be order it
        return;
      }
      let order = 0;
      for (const dataItem of this.data) {
        dataItem.sortOrder = order;
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.setOrderBy;
        dataItem.updatedDate = dataItem.updatedDate; //NOSONAR
        this.update(dataItem);
        order++;
      }
    }
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
    this.applySetOrder = false;
  }
}
