<div class="k-card-body">
    <kendo-tabstrip #tabstrip class="tabstrip-width" [keepTabContent]="true" [animate]="true">
      <kendo-tabstrip-tab
        [title]="'Select pads for deletion'"
        [disabled]="copyPadsAssigmentDisabled"
        [selected]="copyPadsAssigmentTab"
      >
        <ng-template kendoTabContent>
          <div class="card-body">
            <div class="row no-gutters">
              <div class="col-sm-12">

                <div class="form-group row">
                  <div class="col-sm-6">
                    <kendo-multiselect
                      #multiSelectTo
                      [data]="padBracketToListFiltered"
                      textField="padName"
                      valueField="id"
                      [autoClose]="isCloseDisabledTo"
                      (valueChange)="toPadDeleteChange($event)"
                      (open)="onOpenTo($event)"
                      [hidden]="hiddenOptions"
                      [filterable]="true"
                      (filterChange)="handleFilterScenarioToSelection($event)"
                    ></kendo-multiselect>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <button
                      kendoButton
                      [primary]="true"
                      class="k-button"
                      (click)="moveDeleteSummary()"
                      [disabled]="nextButtonDeleteDisabled"
                      style="margin:10px"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        [title]="'Summary'"
        [hidden]="hiddenOptions"
        [disabled]="summaryDisabled"
        [selected]="summaryTab"
      >
        <ng-template kendoTabContent>
          <div class="card-body">
            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <kendo-grid [data]="summaryData">
                      <kendo-grid-column field="padNameTo" title="Pad Name"></kendo-grid-column>
                    </kendo-grid>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <button kendoButton [primary]="true" class="k-button" (click)="previousPage()">Previous</button>
                    <button
                      kendoButton
                      [primary]="true"
                      class="k-button"
                      style="margin:10px"
                      (click)="ereaseSelection()"
                    >
                      Erase selection
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>

<kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button kendoButton (click)="onClickOk()" [primary]="true"
            [disabled]="summaryDisabled">
      Delete Pads
    </button>
</kendo-dialog-actions>