import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-scenario-data',
  templateUrl: './manage-scenario-data.component.html',
  styleUrls: ['./manage-scenario-data.component.css'],
})
export class ManageScenarioDataComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
