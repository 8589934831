import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { FormCanDeactivate } from '../../../_guards/can-deactivate/form-candeactivate';
import { NgForm } from '@angular/forms';
import { IPricetScenarioSummary } from '../../../api/pricetScenario';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { EditPadsComponent } from '../edit-pads/edit-pads.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService, NotificationRef } from '@progress/kendo-angular-notification';
import { ICostLogicDetector } from '../../../api/cost-logic-detector';
import { DeletePadsComponent } from '../input-summary/delete-pads/delete-pads.component';
import { CopyPadsComponent } from '../input-summary/copy-pads/copy-pads.component';
import { ActiveForOutlookComponent } from '../input-summary/active-for-outlook/active-for-outlook.component';
import { ImportFromSmartComponent } from '../input-summary/import-from-smart/import-from-smart.component';
import { SelectPadsAfterSmartImportComponent } from '../input-summary/select-pads-after-smart-import/select-pads-after-smart-import.component';
import { ImportPadBuilderComponent } from '../input-summary/import-pad-builder/import-pad-builder.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CndToolBarComponent } from '../cnd-tool-bar/cnd-tool-bar.component';
import { CostByPadDialogComponent } from '../cost-by-pad-dialog/cost-by-pad-dialog.component';
import { InputItemsComponent } from '../input-items/input-items.component';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-pricettool',
  templateUrl: './pricet-tool.component.html',
  styleUrls: ['./pricet-tool.component.css'],
})
export class PricetToolComponent extends FormCanDeactivate implements OnInit, AfterViewInit, OnDestroy {
  public position = 'top';
  public scenarioName = 'New Scenario';
  public isNewScenario = false;
  public openEditPadsDialog = false;
  public selectedPadIdForEdit?: string = null;

  @ViewChild('form', { static: false })
  public form: NgForm;

  @ViewChild('tabStrip', { static: false })
  public tabStrip: TabStripComponent;
  @ViewChild(EditPadsComponent, { static: false })
  public editPadsComponent: EditPadsComponent;
  @ViewChild(CndToolBarComponent, { static: false })
  public cndToolbarComponent: CndToolBarComponent;
  @ViewChild(InputComponent, { static: false })
  public inputComponent: InputComponent;
  private notificationRef: NotificationRef;
  public scenarioId;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    private dialogService: DialogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.checkIsNew();
    if (this.isNewScenario) {
      this.scenarioStoreService.setCostLogicDetector(true);
      this.scenarioName = 'New Scenario';
      this.scenarioStoreService.deletePadbuilderDataImported();
      this.scenarioStoreService.changeNewScenario(true);
      this.scenarioStoreService.changeScenarioID(Guid.create().toString());
      this.scenarioStoreService.changeTab(1);
    } else {
      this.scenarioName = 'Loading scenario...';
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.scenarioId = params.get('id');
        this.scenarioStoreService.openScenario(this.scenarioId);
      });
    }

    this.scenarioStoreService.pricetSummary$.subscribe((value: IPricetScenarioSummary) => {
      if (value.scenarioName !== '') {
        this.scenarioName = value.scenarioName;
      }
    });

    this.notificationRef = this.createNotificationInfo('Processing info for Cost Items...');
    this.scenarioStoreService.cndCostMappingSft$.subscribe(
      costs => {
        if (costs.length > 0) {
          this.notificationRef.hide();
          this.notificationRef = this.createNotificationSuccess('Cost for Items has been loaded.');
          setTimeout(() => {
            this.notificationRef.hide();
          }, 8000);
          this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
            if (!detector.isMappedCostReady) {
              //it means cost already loaded
              this.scenarioStoreService.setCostLogicDetectorForMappedCost(true);
            }
          });
        }
      },
      (error: any) => {
        this.notificationRef.hide();
        this.createNotificationError(
          'There was an issue calculating Costs for some Items. ' + 'Please try again later.'
        );
      }
    );

    this.scenarioStoreService.scenarioID$.subscribe(i => {
      this.scenarioId = i;
    });
  }

  public ngOnDestroy(): void {
    this.scenarioStoreService.initCatalogsObservables(true);
    this.scenarioStoreService.initTransactionalObservables(true);
    if (this.notificationRef) {
      this.notificationRef.hide();
    }
  }

  public ngAfterViewInit(): void {
    this.scenarioStoreService.changeTab$.subscribe(data => {
      this.tabStrip.selectTab(data);
    });
  }

  public onTabSelect(e: any) {
    if (e.index !== null && e.index !== undefined) {
      if (e.index === 0) {
        // Tab PRICET Summary
        this.scenarioStoreService.updatePadByPadCostsGrid([]);
      }
    }
  }

  public submit() {
    // empty
  }

  public submitEditPadsDialog(): void {
    if (this.editPadsComponent) {
      this.spinner.show();
      this.editPadsComponent.editPadsService
        .saveChanges()
        .toPromise()
        .then((response: any) => {
          this.scenarioStoreService.setCostLogicDetector(false);
          this.scenarioStoreService.openScenario(this.scenarioId);
          this.closeEditPadsDialog();
          this.spinner.hide();
        })
        .catch((error: any) => {
          this.spinner.hide();
          this.notificationService.show({
            content: 'There was an issue changing Pad Name.',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 700 },
            type: { style: 'error', icon: true },
          });
        });
    }
  }

  public closeEditPadsDialog(): void {
    this.openEditPadsDialog = false;
  }

  public onOpenEditPads(args: any): void {
    const { padId, open } = args;
    this.selectedPadIdForEdit = padId;
    this.openEditPadsDialog = open;
  }

  public onSaveScenario(args: any): void {
    if (this.inputComponent && this.inputComponent.inputItemsComponent)
      this.inputComponent.inputItemsComponent.selectedPad = null;
    //Loads Default Scenario Costs using default year for the scenario instead of alternate costs
    if (this.inputComponent.inputItemsComponent.alternatePadCosts) {
      this.inputComponent.inputItemsComponent.loadDefaultScenarioCosts();
    }

    if (args === 'save') {
      this.cndToolbarComponent.onSave();
    } else if (args === 'saveAs') {
      this.cndToolbarComponent.saveAs();
    }
  }

  public onOpenPadCostByYear(args: any): void {
    const { justOpened } = args;
    if (justOpened) {
      const dialogRef = this.dialogService.open({
        title: 'Choose Year by Pad',
        width: 1200,
        minWidth: 300,
        height: 600,
        content: CostByPadDialogComponent,
      });

      dialogRef.result.subscribe((r: any) => {
        if (r.refreshScenario) {
          this.createNotificationSuccess('Updated Pad Bracket Cost');
          this.scenarioStoreService.openScenario(this.scenarioId);
        }
      });
    }
  }

  private checkIsNew(): void {
    const routeName = this.router.url.split('/');
    const route = routeName[2];
    if (route === 'New') {
      this.isNewScenario = true;
    }
  }

  public isReadyToOverridaPadName(): boolean {
    if (this.editPadsComponent) {
      return !this.editPadsComponent.editPadsService.hasChanges();
    }
    return true;
  }

  public createNotificationSuccess(message: string): NotificationRef {
    return this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'success', icon: true },
      closable: false,
    });
  }

  public createNotificationError(message: string): void {
    this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'error', icon: true },
    });
  }

  public createNotificationInfo(message: string): NotificationRef {
    return this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'info', icon: true },
      closable: true,
    });
  }

  public onOpenDeletePads(isOpen: boolean): void {
    if (isOpen) {
      this.dialogService.open({
        title: 'Delete Pads',
        width: 1200,
        height: 700,
        content: DeletePadsComponent,
      });
    }
  }

  public onOpenCopyPads(isOpen: boolean): void {
    if (isOpen) {
      this.dialogService.open({
        title: 'Copy Pads',
        width: 1200,
        height: 700,
        content: CopyPadsComponent,
      });
    }
  }

  public onOpenActiveForOutlook(isOpen: boolean): void {
    if (isOpen) {
      this.dialogService.open({
        title: 'Mass update Active for outlook',
        width: 1200,
        minWidth: 300,
        height: 600,
        content: ActiveForOutlookComponent,
      });
    }
  }

  public onOpenSmartImport(isOpen: boolean): void {
    if (isOpen) {
      const dialogRef = this.dialogService.open({
        title: 'Import from SMART Data',
        width: 1200,
        height: 700,
        content: ImportFromSmartComponent,
      });

      dialogRef.result.subscribe((r: any) => {
        if (r.openSelectPadDialog) {
          this.dialogService.open({
            title: 'Select Pads',
            width: 1200,
            height: 800,
            content: SelectPadsAfterSmartImportComponent,
          });
        }
      });
    }
  }


  public onRefreshSmartImportPads(isOpen: boolean): void {
    if (isOpen) {
      const dialogRef = this.dialogService.open({
        title: "Confirmation",
        content: "Are you sure to refresh the pads?",
        actions: [{ text: "No" }, { text: "Yes", themeColor: "primary" }],
        width: 450,
        height: 200,
        minWidth: 250,
      });

      dialogRef.result.subscribe((result: { [key: string]: string }) => {
        if (result.text == 'Yes') {
          const dialogRef = this.dialogService.open({
            title: 'Refresh Pads from SMART Data',
            width: 1200,
            height: 700,
            content: ImportFromSmartComponent,
          });
          const importFromSmartComponent = dialogRef.content.instance;
          importFromSmartComponent.isRefreshPads = true;
        } else {

        }
      });
    }
  }

  public onOpenImportPadBuilder(isOpen: boolean): void {
    if (isOpen) {
      this.dialogService.open({
        title: 'Import PadBuilder Data',
        width: 1200,
        minWidth: 300,
        height: 600,
        content: ImportPadBuilderComponent,
      });
    }
  }

  public onSaveScenarioCost(isUpdated: boolean): void {
    if (isUpdated) {
      //Reset all variables to ensure scenario is loaded as if initial load
      this.inputComponent.inputItemsComponent.resetPadCostVariables();
      this.scenarioStoreService.openScenario(this.scenarioId);
      this.spinner.hide();
      this.notificationService.show({
        content: 'Scenario Cost Year was updated.',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
      });
    }
  }
}
