<div class="card-body">
  <kendo-grid
  [kendoGridBinding]="scenarioDevData"
  [pageSize]="pageSize"
  style="min-height: 250px"
  [pageable]="{
    buttonCount: buttonCount,
    info: info,
    type: type,
    pageSizes: pageSizes,
    previousNext: previousNext
  }"
  [skip]="skip"
  [sortable]="true"
  [filterable]="true"
  [loading]="isLoading"
  (dataStateChange)="onStateChange($event)"
>
    <kendo-grid-column field="padName" title="Pad Name"> </kendo-grid-column>
    <kendo-grid-column field="name" title=" Scenario Name"> </kendo-grid-column>
    <kendo-grid-column field="createdDate" title="Created Date" [filterable]="false" format="{0:d}"></kendo-grid-column>
    <kendo-grid-column field="" title="Created By" width="130">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.createdBy }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
