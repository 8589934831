import { AppLogEntry, AppLogType } from '../api/logEntry';
import { Guid } from 'guid-typescript';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private authenticationService: AuthenticationService) {}

  logError(sourceName: string, message: string, userMessage: string) {
    const entry: AppLogEntry = {
      id: Guid.create().toString(),
      applicationName: 'PRICET',
      createdDate: new Date(),
      generalMessage: message,
      logLevel: AppLogType.Error,
      sourceName: sourceName,
      createdBy: '',
      userMessage: userMessage,
    };

    this.authenticationService.logErrorEntry(entry).subscribe();
  }
}
