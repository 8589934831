import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, ColumnReorderEvent, PagerComponent } from '@progress/kendo-angular-grid';
import { State, SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { UtilService } from '../../../services/util.service';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { ICostLogicDetector } from '../../../api/cost-logic-detector';

@Component({
  selector: 'app-padbypad-summary',
  templateUrl: './padbypad-summary.component.html',
  styleUrls: ['./padbypad-summary.component.css'],
})
export class PadbypadSummaryComponent implements OnInit {
  public tmpPads: any[] = [];
  public tmpafePads: any[] = [];
  public padByPadData: any[];
  public padByInfraTypePadData:any[];
  public pads: any[];
  public items: any[];
  public padBuilderPads: any[];
  public gridAfeTypeView: GridDataResult;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public padCountsColumnGroupName: string = 'Scope Costs per Pad';
  public state: State = {};
  public taxRate: number;
  public multiple = false;
  public allowUnsort = true;
  public allPadBuilderPads: any[];
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public canEditCnd: boolean = false;
  public showPager: boolean = true;

  public selectedAfeTypePads: any[] = [];
  public selectedPads: any[] = [];
  public padList: any[] = [];

  public readonly hiddenRows: string[];
  public isLoading = true;
  public costYear = '2019';

  @ViewChild('msPadByPad', { static: true }) public msPadByPad: MultiSelectComponent;

  @Input() public alternatePadCosts;
  @Input() public originalInputInfraData;
  public position = 'top';
  constructor(
    private utilService: UtilService,
    private scenarioStoreService: ScenarioStoreService,
    private changeDetector: ChangeDetectorRef,
    private permissionsProvider: PermissionsProvider
  ) {
    this.hiddenRows = scenarioStoreService.hiddenRows;
  }

  public ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.padByPadData = [];
    this.padByInfraTypePadData = [];
    this.pads = [];

    this.scenarioStoreService.pricetSummary$.subscribe(data => {
      this.taxRate = data.tax;
      this.changeDetector.markForCheck();
    });

    this.scenarioStoreService.year$.subscribe(data => {
      this.costYear = data.toString();
    });

    this.scenarioStoreService.infrastructureItems$.subscribe(data => {
      this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
        if (detector.isInfrastructureItemsReady && detector.isMappedCostReady && detector.isPadBuilderDataReady) {
          this.pads = data.pads;
          this.allPadBuilderPads = data.pads;
          this.items = this.hideRows(data.inputItems);
          this.padByInfraTypePadData = this.calculatePadByPadByAFETypeSummaryData(this.items, this.padBuilderPads, this.taxRate);
          this.loadAfeTypeItems();
          this.padByPadData = this.calculatePadByPadSummaryData(this.items, this.padBuilderPads, this.taxRate);
          this.loadItems();
          this.changeDetector.markForCheck();
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
    });

    this.scenarioStoreService.summaryPadBuilderDataPage$.subscribe(data => {
      this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
        if (detector.isInfrastructureItemsReady && detector.isMappedCostReady && detector.isPadBuilderDataReady) {
          this.padBuilderPads = data;
          this.msPadByPad.reset();
          this.padByInfraTypePadData = this.calculatePadByPadByAFETypeSummaryData(this.items, this.padBuilderPads, this.taxRate);
          this.loadAfeTypeItems();
          this.padByPadData = this.calculatePadByPadSummaryData(this.items, this.padBuilderPads, this.taxRate);
          this.loadItems();
          this.changeDetector.markForCheck();
          this.padList = [];
          this.padBuilderPads.forEach(p => {
            this.padList.push({ text: p.pbPadName, value: p, isDisabled: false });
          });
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
    });

    this.scenarioStoreService.padByPadCostUpdate$.subscribe(data => {
      this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
        if (detector.isInfrastructureItemsReady && detector.isMappedCostReady && detector.isPadBuilderDataReady) {
          this.onValueChangedByAFEType(this.tmpafePads);
          this.onValueChanged(this.tmpPads)
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
    });
  }

  public onAfeTypeDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridAfeTypeView = process(this.padByInfraTypePadData, this.state);
  }

  public sortAfeTypeChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadAfeTypeItems();
  }

  public onAfeTypeColumnReorder(columnReorderEvent: ColumnReorderEvent): void {
    const columnGroup = columnReorderEvent.column.parent ? columnReorderEvent.column.parent.title : null;
    if (columnGroup && columnGroup === this.padCountsColumnGroupName) {
      this.scenarioStoreService.reorderPadbuilderPad(
        columnReorderEvent.column['field'],
        columnReorderEvent.oldIndex,
        columnReorderEvent.newIndex
      );
    }
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.padByPadData, this.state);
  }
 
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }
 
  public onColumnReorder(columnReorderEvent: ColumnReorderEvent): void {
    const columnGroup = columnReorderEvent.column.parent ? columnReorderEvent.column.parent.title : null;
    if (columnGroup && columnGroup === this.padCountsColumnGroupName) {
      this.scenarioStoreService.reorderPadbuilderPad(
        columnReorderEvent.column['field'],
        columnReorderEvent.oldIndex,
        columnReorderEvent.newIndex
      );
    }
  }

  public calculatePadByPadByAFETypeSummaryData(inputItems: any[], pPads: any[], vtax: number): any[] {
    const padByPadSummaryData = [];
    const scenarioAfeTypeData = [];
    const map = new Map();
    if (!pPads) {
      pPads = [];
    }
    if (vtax !== null && vtax !== undefined) {
      // Mapping Infrastructure Types
      inputItems.forEach(item => {
        if (!map.has(item.scenarioAfeType.id) && item.scenarioAfeType.name !== '' && item.name !== '') {
          map.set(item.scenarioAfeType.id, true);
          if(item.scenarioAfeType.name!=null){
            scenarioAfeTypeData.push({
            
              id: item.scenarioAfeType.id,
              name: item.scenarioAfeType.name,
              displayName: item.scenarioAfeType.displayName,
            });
          }       
        }
      });

      const padGrandTotalCost: number[] = [];
      scenarioAfeTypeData.forEach(afeType => {
        const items = inputItems.filter(item => item.scenarioAfeType.id === afeType.id);

        const padsCost = [];
        pPads.forEach((pad, padIndex) => {
          if (!padGrandTotalCost[padIndex]) {
            padGrandTotalCost[padIndex] = 0;
          }
          let padItemsCost = 0;
          items.forEach(item => {
            const padName = pad.padName;
            let padContingency;
            const tmpPadWithContingency = this.pads.find(o => o.padName === pad.padName);
            if (tmpPadWithContingency !== null && tmpPadWithContingency !== undefined) {
              padContingency = tmpPadWithContingency.padContingency;
            } else {
              padContingency = pad.padContingency;
            }
            const padItemCount = item[padName];
            const itemCostPunit = item.costPunit;
            let itemCost;

            if (
              item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
              item.name === 'Cost Adjustment/Allowance: General Infr' ||
              item.name === 'Cost Adjustment/Allowance: Water Infr' ||
              item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
              item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
            ) {
              itemCost = this.scenarioStoreService.getItemCostPadByLogic(
                pad.padName,
                item.name,
                item.id,
                padItemCount,
                itemCostPunit,
                pad.yearCost,
                this.alternatePadCosts,
                this.originalInputInfraData
              );
            } else {
              itemCost =
                this.scenarioStoreService.getItemCostPadByLogic(
                  pad.padName,
                  item.name,
                  item.id,
                  padItemCount,
                  itemCostPunit,
                  pad.yearCost,
                  this.alternatePadCosts,
                  this.originalInputInfraData
                ) *
                (1 + vtax) *
                (1 + padContingency);
            }
            itemCost = isNaN(itemCost) ? 0 : itemCost;
            padItemsCost += itemCost; // changed to $M, us:224479;
          });
          padGrandTotalCost[padIndex] += padItemsCost;
          padsCost.push(padItemsCost);
        });
        padByPadSummaryData.push({
          afeType: afeType.displayName,
          padsCost,
        });
      });

      padByPadSummaryData.push({
        afeType: 'Grand Total',
        padsCost: padGrandTotalCost,
      });
    }

    return padByPadSummaryData;
  }

  public getAFETypeSubTotal(padsCostByInfraType: any[]): number {
    let vsubTotal = 0;

    padsCostByInfraType.forEach(pad => {
      pad = isNaN(pad) ? 0 : pad;
      vsubTotal += pad;
    });

    return vsubTotal;
  }

  public calculatePadByPadSummaryData(inputItems: any[], pPads: any[], vtax: number): any[] {
    const padByPadSummaryData = [];
    const infraTypes = [];
    const map = new Map();
    if (!pPads) {
      pPads = [];
    }
    if (vtax !== null && vtax !== undefined) {
      // Mapping Infrastructure Types
      inputItems.forEach(item => {
        if (!map.has(item.infrastructureClass.id) && item.infrastructureClass.name !== '' && item.name !== '') {
          map.set(item.infrastructureClass.id, true);
          infraTypes.push({
            id: item.infrastructureClass.id,
            name: item.infrastructureClass.name,
            displayName: item.infrastructureClass.displayName,
          });
        }
      });
  
      const padGrandTotalCost: number[] = [];
      infraTypes.forEach(infraType => {
        const items = inputItems.filter(item => item.infrastructureClass.id === infraType.id);
  
        const padsCost = [];
        pPads.forEach((pad, padIndex) => {
          if (!padGrandTotalCost[padIndex]) {
            padGrandTotalCost[padIndex] = 0;
          }
          let padItemsCost = 0;
          items.forEach(item => {
            const padName = pad.padName;
            let padContingency;
            const tmpPadWithContingency = this.pads.find(o => o.padName === pad.padName);
            if (tmpPadWithContingency !== null && tmpPadWithContingency !== undefined) {
              padContingency = tmpPadWithContingency.padContingency;
            } else {
              padContingency = pad.padContingency;
            }
            const padItemCount = item[padName];
            const itemCostPunit = item.costPunit;
            let itemCost;
  
            if (
              item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
              item.name === 'Cost Adjustment/Allowance: General Infr' ||
              item.name === 'Cost Adjustment/Allowance: Water Infr' ||
              item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
              item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
            ) {
              itemCost = this.scenarioStoreService.getItemCostPadByLogic(
                pad.padName,
                item.name,
                item.id,
                padItemCount,
                itemCostPunit,
                pad.yearCost,
                this.alternatePadCosts,
                this.originalInputInfraData
              );
            } else {
              itemCost =
                this.scenarioStoreService.getItemCostPadByLogic(
                  pad.padName,
                  item.name,
                  item.id,
                  padItemCount,
                  itemCostPunit,
                  pad.yearCost,
                  this.alternatePadCosts,
                  this.originalInputInfraData
                ) *
                (1 + vtax) *
                (1 + padContingency);
            }
            itemCost = isNaN(itemCost) ? 0 : itemCost;
            padItemsCost += itemCost; // changed to $M, us:224479;
          });
          padGrandTotalCost[padIndex] += padItemsCost;
          padsCost.push(padItemsCost);
        });
        padByPadSummaryData.push({
          infraType: infraType.displayName,
          padsCost,
        });
      });
  
      padByPadSummaryData.push({
        infraType: 'Grand Total',
        padsCost: padGrandTotalCost,
      });
    }
  
    return padByPadSummaryData;
  }

  public getSubTotal(padsCostByInfraType: any[]): number {
    let vsubTotal = 0;
  
    padsCostByInfraType.forEach(pad => {
      pad = isNaN(pad) ? 0 : pad;
      vsubTotal += pad;
    });
  
    return vsubTotal;
  }
  
  public onValueChanged(selectedPads: any[]) {
    let tax: number;
    tax = this.taxRate;
    // Set selected Pads to use on Tab Click
    this.tmpPads = selectedPads;
    if (selectedPads.length === 0) {
      this.scenarioStoreService.padBuilderDataPage$.subscribe(data => {
        this.padBuilderPads = data;
      });
  
      this.padByPadData = this.calculatePadByPadSummaryData(this.items, this.padBuilderPads, tax);
      this.showPager = true;
    } else {
      const vpads = [];
      const map = new Map();
  
      selectedPads.forEach(p => {
        if (!map.has(p.value)) {
          map.set(p.value, true);
          vpads.push(p.value);
        }
      });
      this.padBuilderPads = vpads;
  
      this.padByPadData = this.calculatePadByPadSummaryData(this.items, vpads, tax);
      this.showPager = false;
    }
    this.loadItems();
  }
  
  public getGrandTotal2(item: any): number {
    let grandTotal2 = 0;
    let allData: any[] = [];
    let tax: number;
    tax = this.taxRate;
  
    if (item !== null && item !== undefined) {
      allData = this.calculatePadByPadSummaryData(this.items, this.allPadBuilderPads, tax);
  
      if (allData !== null && allData !== undefined) {
        allData.forEach(pad => {
          if (item.infraType === pad.infraType && pad.padsCost !== null && pad.padsCost !== undefined) {
            pad.padsCost.forEach(cost => {
              cost = isNaN(cost) ? 0 : cost;
              grandTotal2 += cost;
            });
          }
        });
      }
    }
    return grandTotal2;
  }

  public onValueChangedByAFEType(selectedAfeTypePads: any[]) {
    let tax: number;
    tax = this.taxRate;
    // Set selected Pads to use on Tab Click
    this.tmpafePads = selectedAfeTypePads;
    if (selectedAfeTypePads.length === 0) {
      this.scenarioStoreService.padBuilderDataPage$.subscribe(data => {
        this.padBuilderPads = data;
      });

      this.padByInfraTypePadData = this.calculatePadByPadByAFETypeSummaryData(this.items, this.padBuilderPads, tax);
      this.showPager = true;
    } else {
      const vpads = [];
      const map = new Map();

      selectedAfeTypePads.forEach(p => {
        if (!map.has(p.value)) {
          map.set(p.value, true);
          vpads.push(p.value);
        }
      });
      this.padBuilderPads = vpads;

      this.padByInfraTypePadData = this.calculatePadByPadByAFETypeSummaryData(this.items, vpads, tax);
      this.showPager = false;
    }
    this.loadAfeTypeItems();
  }

  public getAFETypeGrandTotal(item: any): number {
    let grandTotal2 = 0;
    let allData: any[] = [];
    let tax: number;
    tax = this.taxRate;

    if (item !== null && item !== undefined) {
      allData = this.calculatePadByPadByAFETypeSummaryData(this.items, this.allPadBuilderPads, tax);

      if (allData !== null && allData !== undefined) {
        allData.forEach(pad => {
          if (item.afeType === pad.afeType && pad.padsCost !== null && pad.padsCost !== undefined) {
            pad.padsCost.forEach(cost => {
              cost = isNaN(cost) ? 0 : cost;
              grandTotal2 += cost;
            });
          }
        });
      }
    }
    return grandTotal2;
  }

  private loadItems(): void {
    if (!this.state.filter || !this.state.filter.filters || this.state.filter.filters.length === 0) {
      this.gridView = {
        data: orderBy(this.padByPadData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: this.padByPadData.length,
      };
    } else {
      this.gridView = process(this.padByPadData, this.state);
    }
  }

  public hideRows(data: any[]): any[] {
    const inputItems = data.filter(ar => !this.hiddenRows.find(rm => rm === ar.name));

    return inputItems;
  }

  private loadAfeTypeItems(): void {
    if (!this.state.filter || !this.state.filter.filters || this.state.filter.filters.length === 0) {
      this.gridAfeTypeView = {
        data: orderBy(this.padByInfraTypePadData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: this.padByInfraTypePadData.length,
      };
    } else {
      this.gridAfeTypeView = process(this.padByInfraTypePadData, this.state);
    }
  }

  public onTabSelect(e: any) {
    if (e.index !== null && e.index !== undefined) {
      if (e.index === 0) {
     
      }
    }
  }
}
