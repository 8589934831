<label style="font-weight: bold;">&#42;File Size Limit: </label>&nbsp;
<label> {{restrictions ? restrictions.maxFileSize / 1000000 : ''}} MB</label>
<br />
<div>
  <label style="font-weight: bold;">&#42;Accepted File Types: </label>
  <label>{{restrictions ? restrictions.allowedExtensions.join(", ").toUpperCase() : ''}} </label>
</div>
<kendo-upload [saveUrl]="uploadSaveUrl"
              [removeUrl]="uploadRemoveUrl"
              (upload)="uploadEventHandler($event)"
              (success)="successEventHandler($event)"
              (error)="errorEventHandler($event)"
              [(ngModel)]="files"
              [disabled]="isDisabled()"
              [multiple]="true"
              [restrictions]="restrictions">
</kendo-upload>

<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button kendoButton (click)="onConfirmAction()" [primary]="true"
          [disabled]="!isDisabled()">
    OK
  </button>
</kendo-dialog-actions>
