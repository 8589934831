

  <ng-template #toolbarTemplate>
    <div *ngIf="showMessage">
      <span class="k-icon k-i-information"></span>
      <span #toolbarElement>
        {{ text }}
      </span>
    </div>
    
  </ng-template>
  
 

