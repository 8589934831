import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../../../services/data.service';
import { PermissionsProvider } from '../../../../services/permissions.provider';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';
import { ScenarioPadBracket } from '../../../../api/scenario-pad-bracket';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { IPadNames } from '../../../../api/pad-names';

@Component({
  selector: 'app-delete-pads',
  templateUrl: './delete-pads.component.html',
  styleUrls: ['./delete-pads.component.css']
})
export class DeletePadsComponent implements OnInit {
  @ViewChild('multiSelectTo', { static: false }) public multiSelectTo: MultiSelectComponent;
  @Input() public aNewPadWasImported: boolean;

  public copyPadsAssigmentDisabled = false;
  public copyPadsAssigmentTab = true;
  public padBracketToListFiltered = [];
  public isCloseDisabledTo = false;
  public hiddenOptions = false;
  public padBracketToList = [];
  public valuePadBracketDelete: ScenarioPadBracket[] = [];
  public valuePadBracketFrom: ScenarioPadBracket[] = [];
  public valuePadBracketTo: ScenarioPadBracket[] = [];
  public originalPadBracketToList: ScenarioPadBracket[] = [];
  public nextButtonDeleteDisabled = true;
  public isOpenDisabledTo= false;
  public nextButtonDisabled = true;
  public summaryDisabled = true;
  public summaryTab = false;
  public summaryData: IPadNames[] = [];
  public dialogOpenedDeletePads = false;
  public fromPadsDisabled = true;
  public optionValue = 0;
  public buttonName = 'Next';
  public scenarioId: string;
  public selectPadsAssignmentTab = true;
  public selectPadsAssignmentDisabled = false;
  public canEditCnd = false;

  constructor(private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private dialog: DialogRef,
    private dataService: DataService,
    private scenarioStoreService: ScenarioStoreService,
    private permissionsProvider: PermissionsProvider) { }

  ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.scenarioID$.subscribe(data => {
      this.scenarioId = data;
      this.loadPadData();
    });  
  }

  public toPadDeleteChange(value: any): void {
    this.valuePadBracketDelete = value;
    this.comparePadItemsList();
    this.nextButtonDeleteDisabled = value.length >= 1 ? false : true;
    if (value.length === 100) {
      this.multiSelectTo.toggle(false);
      this.isCloseDisabledTo = true;
      this.isOpenDisabledTo = true;
    } else {
      this.isCloseDisabledTo = false;
      this.isOpenDisabledTo = false;
    }
  }

  public comparePadItemsList(): void {
    if (
      this.valuePadBracketFrom !== undefined &&
      this.valuePadBracketFrom !== null &&
      this.valuePadBracketTo !== undefined &&
      this.valuePadBracketTo !== null
    ) {
      if (
        this.valuePadBracketTo.length === this.valuePadBracketFrom.length &&
        this.valuePadBracketFrom.length > 0 &&
        this.valuePadBracketTo.length > 0
      ) {
        this.nextButtonDisabled = false;
      } else {
        this.nextButtonDisabled = true;
      }
    }
  }

  public onOpenTo(event: any): void {
    if (this.isOpenDisabledTo) {
      event.preventDefault();
    }
  }

  public handleFilterScenarioToSelection(value: any) {
    if (this.padBracketToList)
    {
      this.padBracketToListFiltered = this.padBracketToList.filter(
        (s) => s.padName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }    
  }

  public moveDeleteSummary() {
    this.summaryDisabled = false;
    this.copyPadsAssigmentTab = false;
    this.summaryTab = true;
    this.copyPadsAssigmentDisabled = true;
    this.summaryData = [];
    for (let i = 0; i < this.valuePadBracketDelete.length; i++) {
      const item: IPadNames = {
        padNameFrom: null,
        padNameTo: this.valuePadBracketDelete[i].padName,
      };
      this.summaryData.push(item);
    }
  }

  public ereaseSelection() {
    this.dialogOpenedDeletePads = false;
    this.valuePadBracketDelete = [];

    this.multiSelectTo.reset();
    this.fromPadsDisabled = true;
    this.summaryDisabled = true;
    this.copyPadsAssigmentTab = true;
    this.summaryTab = false;
    this.copyPadsAssigmentDisabled = false;
    this.optionValue = 0;
    this.nextButtonDisabled = true;
    this.nextButtonDeleteDisabled = true;
    this.optionCopyPadChange(this.optionValue);
  }

  public optionCopyPadChange(value: any): void {
    if (value === 1) {
      this.hiddenOptions = true;
      this.buttonName = 'Import';
      this.multiSelectTo.reset();
      this.valuePadBracketTo = [];
      if (this.valuePadBracketFrom !== null && this.valuePadBracketFrom !== undefined) {
        if (this.valuePadBracketFrom.length > 0) {
          this.nextButtonDisabled = false;
        }
      }
    } else {
      this.hiddenOptions = false;
      this.buttonName = 'Next';
      this.comparePadItemsList();
    }
  }

  public deletePads(): void {
    this.spinner.show();
    this.showInfo('Deleting Pads...', 'info');
    const ids = this.valuePadBracketDelete.map(item => item.id);
    const deletedPads = this.originalPadBracketToList.filter(p => ids.includes(p.id));
    this.dataService.DeletePads(deletedPads).subscribe(data => {
      this.scenarioStoreService.openScenario(this.scenarioId);
      this.showInfo('The delete pad process was complete', 'success');
      this.spinner.hide();
      this.dialog.close();
    });
  }

  public showInfo(value: string, typeChange: string) {
    switch (typeChange) {
      case 'info': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 200 },
          type: { style: 'info', icon: true },
        });
        break;
      }
      case 'success': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 300 },
          type: { style: 'success', icon: true },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  public previousPage(): void {
    this.copyPadsAssigmentTab = true;
    this.selectPadsAssignmentTab = true;
    this.summaryTab = false;
    this.copyPadsAssigmentDisabled = false;
    this.selectPadsAssignmentDisabled = false;
    this.summaryDisabled = true;
  }

  public loadPadData(): void {
    this.dataService.getScenarioPadBracketByScenarioId(this.scenarioId).subscribe(data => {
      this.originalPadBracketToList = data;
      this.padBracketToList = this.mapScenarioPadBracketToAny(data);
      this.padBracketToListFiltered = this.padBracketToList;
    });
  }

  public mapScenarioPadBracketToAny(source: ScenarioPadBracket[]): any[] {
    return source.map((item: ScenarioPadBracket) => {
      return {
        id: item.id,
        padName: item.padNameOverride ? item.padNameOverride : item.padName,
        scenarioId: item.scenarioId
      };
    });
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onClickOk(): void {
    this.deletePads();
  }

}
