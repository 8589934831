<p class="card-title center">
  Pad-by-Pad Cost Summary ($M)
</p>

<div id="scenario" class="cnd-input">
  <kendo-tabstrip #tabStrip [tabPosition]="position" (tabSelect)="onTabSelect($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab [title]="'AFE Type'" [selected]="true">
      <ng-template kendoTabContent *loadOnDemand>
        
            <br />
            <br />
                <kendo-multiselect #msPadByPad [data]="padList"
                [(ngModel)]="selectedAfeTypePads"
                textField="text"
                valueField="value"
                (valueChange)="onValueChangedByAFEType($event)"
                [disabled]="!canEditCnd">
              </kendo-multiselect>

            <br />
            <br />

            <kendo-grid [data]="gridAfeTypeView" [resizable]="true" [skip]="skip" [pageSize]="pageSize" [scrollable]="'virtual'"
                        [rowHeight]="34" [height]="500"
                        [loading]="isLoading"
                        (dataStateChange)="onAfeTypeDataStateChange($event)" [filter]="state.filter" [sortable]="{ allowUnsort: allowUnsort, mode: 'multiple'}"
                        kendoGridHeaderTemplate [sort]="sort" (sortChange)="sortAfeTypeChange($event)" (columnReorder)="onAfeTypeColumnReorder($event)" [reorderable]="true">

              <kendo-grid-column [locked]="true" field="afeType" title="AFE Type" width="150" [reorderable]="false">
                <ng-template let-column let-columnIndex="columnIndex">
                  {{column.title}}
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <div>
                    {{dataItem.afeType}}
                  </div>
                </ng-template>
              </kendo-grid-column>

              <ng-container *ngFor="let pad of padBuilderPads; let i = index">
                <kendo-grid-column [sortable]="false" filter="numeric" [field]="pad.padName" [title]="pad.pbPadName" width="150" editor="numeric">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span>
                      {{dataItem.padsCost [i] | currency : 'USD' : 'symbol': '1.0'}}
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </ng-container>

              <kendo-grid-column field="afeType" title="Sub Total" width="150" [reorderable]="false">
                <ng-template let-column let-columnIndex="columnIndex">
                  {{column.title}}
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <div>
                    {{getAFETypeSubTotal(dataItem.padsCost) | currency : 'USD' : 'symbol': '1.0'}}
                  </div>
                </ng-template>
              </kendo-grid-column>


              <kendo-grid-column field="afeType" title="Grand Total" width="150" [reorderable]="false">
                <ng-template let-column let-columnIndex="columnIndex">
                  {{column.title}}
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <div>
                    {{getAFETypeGrandTotal(dataItem) | currency : 'USD' : 'symbol': '1.0'}}
                  </div>
                </ng-template>
              </kendo-grid-column>

            </kendo-grid>
            <app-padbypad-summary-pager *ngIf="showPager && pads.length > 5">
            </app-padbypad-summary-pager>
      </ng-template>
    </kendo-tabstrip-tab>
    
   <kendo-tabstrip-tab [title]="'Infrastructure  Type'" [selected]="false">
      <ng-template kendoTabContent *loadOnDemand>
       
           <br />
            <br />
                <kendo-multiselect #msPadByPad [data]="padList"
                [(ngModel)]="selectedPads"
                textField="text"
                valueField="value"
                (valueChange)="onValueChangedByAFEType($event)"
                [disabled]="!canEditCnd">
              </kendo-multiselect>

            <br />
            <br />
       
        <kendo-grid [data]="gridView" [resizable]="true" [skip]="skip" [pageSize]="pageSize" [scrollable]="'virtual'"
            [rowHeight]="34" [height]="325"
            [loading]="isLoading"
            (dataStateChange)="onDataStateChange($event)" [filter]="state.filter" [sortable]="{ allowUnsort: allowUnsort, mode: 'multiple'}"
            kendoGridHeaderTemplate [sort]="sort" (sortChange)="sortChange($event)" (columnReorder)="onColumnReorder($event)" [reorderable]="true">

      <kendo-grid-column [locked]="true" field="infrastructureType" title="Infrastructure Type" width="150" [reorderable]="false">
        <ng-template let-column let-columnIndex="columnIndex">
          {{column.title}}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div>
            {{dataItem.infraType}}
          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-container *ngFor="let pad of padBuilderPads; let i = index">
        <kendo-grid-column [sortable]="false" filter="numeric" [field]="pad.padName" [title]="pad.pbPadName" width="150" editor="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>
              {{dataItem.padsCost [i] | currency : 'USD' : 'symbol': '1.0'}}
            </span>
          </ng-template>
        </kendo-grid-column>
      </ng-container>

      <kendo-grid-column field="infrastructureType" title="Sub Total" width="150" [reorderable]="false">
        <ng-template let-column let-columnIndex="columnIndex">
          {{column.title}}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div>
            {{getSubTotal(dataItem.padsCost) | currency : 'USD' : 'symbol': '1.0'}}
          </div>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column field="infrastructureType" title="Grand Total" width="150" [reorderable]="false">
        <ng-template let-column let-columnIndex="columnIndex">
          {{column.title}}
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div>
            {{getGrandTotal2(dataItem) | currency : 'USD' : 'symbol': '1.0'}}
          </div>
        </ng-template>
      </kendo-grid-column>

        </kendo-grid>
        <app-padbypad-summary-pager *ngIf="showPager && pads.length > 5">
        </app-padbypad-summary-pager>
        </ng-template>
    </kendo-tabstrip-tab>
    
  </kendo-tabstrip>
</div>




