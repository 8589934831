export function convertUtcToLocalTime(date: Date): Date {
  if (date !== undefined && date != null) {
    let utcDateString = date.toString();
    // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
    if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
      utcDateString += 'Z';
    }
    const date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    const utcDate = new Date(utcDateString);
    utcDate.setHours(0, 0, 0, 0);
    if (date1.getTime() === utcDate.getTime()) {
      return utcDate;
    } else {
      return date1;
    }
  }
  return null;
}

export function convertUtcToLocalTime2(date: Date): Date {
  if (date !== undefined && date != null) {
    let utcDateString = date.toString();
    // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
    if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
      utcDateString += 'Z';
    }
    return new Date(utcDateString);
  }
  return null;
}

export function decodeEntities(str: string): string {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');
  if (str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
  }
  return str;
}

export function setValueToOverridableProperties(entity: any, update: any): any {
  for (const property in entity) {
    if (entity.hasOwnProperty(property + 'Overridable')) {
      if (entity[property] !== update[property]) {
        entity[property + 'Overridable'] = update[property];
      }
    }
  }
  return entity;
}

export function convertToAfeFormat(text: string): string {
  if (text !== undefined && text != null) {
    if (text.length === 13) {
      const hypenSign = '-';
      let output = text;
      let position = 5;

      for (let i = 1; i <= 2; i++) {
        position = position * i + (i === 1 ? 0 : 1);
        output = [output.slice(0, position), hypenSign, output.slice(position)].join('');
      }
      return output;
    }
  }
  return text;
}

export function flatten(filter : any) : any {
  const filters = (filter || {}).filters;
  if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

export function removeTempCss(idTemp: string): void {
  // Remove the css
  const elementById = document.getElementById(idTemp);
  if (elementById) {
    elementById.parentNode.removeChild(elementById);
  }
}