import { take } from 'rxjs/operators';
import { concat } from 'rxjs';
import { ScopeAssumptionsService } from '../../../services/scope-assumptions.service';
import { Component, Inject, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDasSimple } from '../../../api/das-simple';
import { AuthenticationService } from '../../../services';
import { DasSimpleService } from '../../../services/das-simple.service';
import { DevelopmentAreaService } from '../../../services/master-data.service';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { UserPermissionsService } from '../../../services/user-permission.service';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ISequence, IDevAreaCode } from '../../../api/scenarioSequence';
import { environment } from 'src/environments/environment';
import { SmartImportSequenceService } from '../../../services/smart-import-sequence.service';

@Component({
  selector: 'app-das-simple-details',
  templateUrl: './das-simple-details.component.html',
  styleUrls: ['./das-simple-details.component.css'],
})
export class DasSimpleDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('tabStripSequence') public tabStripSequence: TabStripComponent;
  public formNewDasSimple: FormGroup;
  public canEditCnd = false;
  public isNewDasSimple = false;
  public isGetSequenceComplete = false;
  public dasSimpleData: IDasSimple;
  public formatOptions: any = {
    maximumFractionDigits: 4,
    minimumFractionDigits: 1,
    style: 'percent',
  };
  public openGetSequenceDialog = false;
  public formGetSequence: FormGroup;

  public developmentAreas: IDevAreaCode[];
  public devAreaList: any[];
  public sequenceList: any[];
  public sequence: ISequence[];
  public sequenceStatusList: any[] = [
    { text: 'Draft', value: 'Draft' },
    { text: 'Promoted', value: 'Promoted' },
    { text: 'Published', value: 'Published' },
  ];
  public selectedOptionIndexTab1: boolean = true;
  public selectedOptionIndexTab2: boolean;
  public selectedOptionIndex: number = 0;
  public smartUrl: string = environment.SmartAppURL + 'sequence/';
  @Output() public smartImportSequenceUpdate = new EventEmitter<any[]>();
  private userPermissionsService: UserPermissionsService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private dasSimpleService: DasSimpleService,
    private smartImportSequenceService: SmartImportSequenceService,
    private scopeAssumptionsService: ScopeAssumptionsService
  ) {
    this.userPermissionsService = userPermissionsServiceFactory();
  }

  public ngOnInit(): void {
    this.createNewFormGroup(null);
    this.checkIsNew();
    this.canEditCnd = this.permissionsProvider.canEditCnd;

    if (this.isNewDasSimple) {
      this.dasSimpleService.getDevAreaCodes().subscribe(data => {
        if (data.result.length > 0) {
          this.developmentAreas = data.result;
          this.devAreaList = this.developmentAreas
            .map(item => ({ text: item.developmentAreaDescription, value: item.developmentAreaCode }))
            .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
        }
      });
      this.dasSimpleData = {
        id: null,
        name: null,
        smartSeqName: null,
        smartSeqLink: null,
        comments: null,
        isDeleted: false,
        sortOrder: 0,
        developmentAreasCode: null,
        stateCode: null,
        createdBy: null,
        createdDate: null,
        updatedBy: null,
        updatedDate: null,
        dasLastUpdatedDate: null,
      };

      this.dasSimpleService.getDevAreaCodes().subscribe(data => {
        if (data.result.length > 0) {
          this.developmentAreas = data.result;
          this.devAreaList = this.developmentAreas
            .map(item => ({ text: item.developmentAreaDescription, value: item.developmentAreaCode }))
            .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
        }
      });
    } else {
      this.dasSimpleService.loader.subscribe(
        (isLoading: boolean) => {
          if (isLoading) {
            const getDevAreasFirst = this.dasSimpleService.getDevAreaCodes().pipe();

            getDevAreasFirst.subscribe(data => {
              if (data.result.length > 0) {
                this.developmentAreas = data.result;
                this.devAreaList = this.developmentAreas
                  .map(item => ({ text: item.developmentAreaDescription, value: item.developmentAreaCode }))
                  .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
              }
            });

            concat(getDevAreasFirst)
              .toPromise()
              .then(() => {
                this.dasSimpleService.dasSimple$.subscribe((dasSimple: IDasSimple) => {
                  this.dasSimpleData = dasSimple;
                  this.loadForm();
                });
              });
          }
        },
        (error: any) => {
          // error message here
        }
      );
    }
  }

  public ngOnDestroy(): void {
    this.dasSimpleService.cleanObservables();
  }

  public createNewFormGroup(data: any) {
    if (data === null) {
      this.formNewDasSimple = new FormGroup({
        name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        smartSeqName: new FormControl(null, { updateOn: 'change', validators: [Validators.required] }),
        smartSeqLink: new FormControl(null, { updateOn: 'change', validators: [Validators.required] }),
        dasLastUpdatedDate: new FormControl(null, { validators: [Validators.required] }),
        developmentAreasCode: new FormControl(null, [Validators.required]),
        stateCode: new FormControl(),
        comments: new FormControl(null, [Validators.maxLength(500)]),
      });
    }

    this.formGetSequence = new FormGroup({
      devArea: new FormControl(null, [Validators.required]),
      sequenceStatus: new FormControl(null, [Validators.required]),
      sequence: new FormControl(null, [Validators.required]),
      smartLink: new FormControl(null, [Validators.required]),
    });
  }

  public loadForm() {
    // Load facility
    if (this.dasSimpleData) {
      this.formNewDasSimple = new FormGroup({
        name: new FormControl(this.dasSimpleData.name, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        smartSeqName: new FormControl(this.dasSimpleData.smartSeqName, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        smartSeqLink: new FormControl(this.dasSimpleData.smartSeqLink, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        dasLastUpdatedDate: new FormControl(this.dasSimpleData.dasLastUpdatedDate, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        developmentAreasCode: new FormControl(this.dasSimpleData.developmentAreasCode, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        stateCode: new FormControl(this.dasSimpleData.stateCode),
        comments: new FormControl(this.dasSimpleData.comments, {
          updateOn: 'change',
          validators: [Validators.maxLength(500)],
        }),
      });
    }
  }

  public validateDasSimple(form: any): boolean {
    let isValid = true;

    if (form === null || form === undefined) {
      this.showWarning('Information of the new Das Simple is required.');
      isValid = false;
    } else {
      if (form.name === null || form.name === undefined || form.name.length < 1) {
        this.showWarning('Name is a required field.');
        isValid = false;
      }

      if (form.smartSeqName === null || form.smartSeqName === undefined || form.smartSeqName.length < 1) {
        this.showWarning('Sequence Name is a required field.');
        isValid = false;
      }

      if (form.smartSeqLink === null || form.smartSeqLink === undefined || form.smartSeqLink.length < 1) {
        this.showWarning('Sequence Link is a required field.');
        isValid = false;
      }

      if (form.developmentAreasCode === null || form.developmentAreasCode === undefined) {
        this.showWarning('Development Area is a required field.');
        isValid = false;
      }

      if (!this.formNewDasSimple.valid && isValid) {
        this.showWarning('Please check the required fields in Das Simple details.');
        isValid = false;
      }
    }

    return isValid;
  }

  public saveDasSimple(smartImportSequences: any[], scopeAssumptions: any[]): void {
    const value: any = this.formNewDasSimple.value;

    

    if (this.validateDasSimple(value)) {
      this.spinner.show();

      this.dasSimpleService
        .nameExist(this.dasSimpleData ? this.dasSimpleData.id : null, value.name)
        .subscribe((exist: boolean) => {
          if (exist && this.isNewDasSimple) {
            this.showWarning('Das Simple Name already exists, please select a different Name.');
            this.spinner.hide();
            return;
          }
          this.dasSimpleData.name = value.name;
          this.dasSimpleData.smartSeqName = value.smartSeqName;
          this.dasSimpleData.smartSeqLink = value.smartSeqLink;
          this.dasSimpleData.dasLastUpdatedDate = value.dasLastUpdatedDate;
          this.dasSimpleData.developmentAreasCode = value.developmentAreasCode;
          this.dasSimpleData.comments = value.comments;

          if (this.isNewDasSimple) {
            this.dasSimpleData.id = Guid.create().toString();
            this.dasSimpleData.createdDate = new Date();
            this.dasSimpleData.createdBy = this.authenticationService.getCurrentUser().toUpperCase();
          }

          this.dasSimpleData.updatedDate = new Date();
          this.dasSimpleData.updatedBy = this.authenticationService.getCurrentUser().toUpperCase();
          if (this.isNewDasSimple) {
            this.dasSimpleService.create([this.dasSimpleData]);
          } else {
            this.dasSimpleService.update([this.dasSimpleData]);
            // Check this later
            // this.dasSimpleService.refresh(this.dasSimpleData.id);
          }

          this.dasSimpleService.refresh.subscribe(
            (refreshed: boolean) => {
              if (refreshed) {
                this.spinner.hide();
                this.showSaveNotificationMessage();
                if (this.isNewDasSimple) {
                  smartImportSequences = smartImportSequences.map((item: any) => {
                    item.dasSimpleId = this.dasSimpleData.id;
                    return item;
                  });

                  scopeAssumptions[0].dasSimpleId = this.dasSimpleData.id;

                  const first = this.smartImportSequenceService
                    .createSmartImportSequence(smartImportSequences)
                    .pipe();

                  const second = this.scopeAssumptionsService.createScopeAssumptions(scopeAssumptions).pipe();

                  concat(first, second)
                    .toPromise()
                    .then(() => {
                      this.dasSimpleService.UploadDasSimpleCapex(this.dasSimpleData.id);
                      this.router.navigate(['Scenario/DasSimple/id', this.dasSimpleData.id]);
                    });
                }
              }
            },
            (error: any) => {
              this.showError('Error updating das simple. Please try refreshing browser.');
              console.log(error);
              this.spinner.hide();
            }
          );
        });
    } else {
      this.spinner.hide();
      return;
    }
  }

  public showError(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'error', icon: true },
      hideAfter: 1400,
    });
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'warning', icon: true },
      hideAfter: 1400,
    });
  }

  public showInfo(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'info', icon: true },
      hideAfter: 1400,
    });
  }

  public showSaveNotificationMessage() {
    this.notificationService.show({
      animation: { type: 'fade', duration: 400 },
      content: 'Das Simple Saved Successfully',
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 1400,
    });
  }

  public getSequenceById(form: FormGroup): void {
    let sequenceId: string = "";
    if (this.selectedOptionIndex === 0) {
      sequenceId = this.formGetSequence.controls['sequence'].value.value;
    } else if (this.selectedOptionIndex === 1) {
      const sequenceUrl = this.formGetSequence.controls['smartLink'].value;
      let uriArray = sequenceUrl.split("/");    
      sequenceId = uriArray[uriArray.length - 1]; 
    }
    if (sequenceId !== null && sequenceId !== undefined) {
      this.spinner.show();
      this.dasSimpleService.getSequenceById(sequenceId).subscribe(
        data => {
          if (data != null) {
            this.sequence = data;
            this.populateSequenceFields(this.sequence, form);
            this.spinner.hide();
            this.isGetSequenceComplete = true;
          }
        },
        err => {
          console.log(err);
          this.spinner.hide();
        }
      );
    }
  }

  public populateSequenceFields(sequence: ISequence[], form: FormGroup): void {
    this.closeSequenceDialog();
    this.smartImportSequenceUpdate.next(sequence);
    this.formNewDasSimple.patchValue({
      smartSeqName: sequence[0].sequenceName,
      smartSeqLink: this.smartUrl + sequence[0].sequenceId,
      developmentAreasCode: sequence[0].devAreaCode,
      stateCode: this.getStateFromDevArea(sequence[0].devAreaCode),
      dasLastUpdatedDate: new Date(),
    });

    this.formNewDasSimple.markAsDirty();
  }

  public closeSequenceDialog() {
    this.openGetSequenceDialog = false;
  }

  public openSequenceDialog() {
    this.openGetSequenceDialog = true;
  }

  public onTabSelect(event: any): void {
    this.selectedOptionIndex = event.index;

    switch (event.index) {
      case 0:
        // Search sequence
        this.formGetSequence.get('devArea').enable();
        this.formGetSequence.get('sequenceStatus').enable();
        this.formGetSequence.get('sequence').enable();
        this.formGetSequence.get('smartLink').disable();
        this.formGetSequence.clearValidators();
        break;
      case 1:
        // Provide link for smart
        this.formGetSequence.get('devArea').disable();
        this.formGetSequence.get('sequenceStatus').disable();
        this.formGetSequence.get('sequence').disable();
        this.formGetSequence.get('smartLink').enable();
        this.formGetSequence.clearValidators();
        break;
      default:
        // tslint:disable-next-line: no-console
        console.log('An error has ocurred at select tab.');
        break;
    }
  }

  public handleDevAreaChange(value: any, form: FormGroup) {
    if (value !== null && value !== undefined) {
      const sequenceStatus = form.controls['sequenceStatus'].value;
      if (sequenceStatus !== null && sequenceStatus !== undefined) {
        this.sequenceList = [];
        this.formGetSequence.get('sequence').setValue(null);
        this.dasSimpleService.getSequenceByDevAreaStatus(value.value, sequenceStatus.value).subscribe(data => {
          if (data.length > 0) {
            this.sequenceList = data
              .map(item => ({ text: item.name, value: item.id }))
              .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
          }
        });
      }
    }
  }

  public handleSequenceStatusChange(value: any, form: FormGroup) {
    if (value !== null && value !== undefined) {
      const devArea = form.controls['devArea'].value;
      if (devArea !== null && devArea !== undefined) {
        this.sequenceList = [];
        this.formGetSequence.get('sequence').setValue(null);
        this.dasSimpleService.getSequenceByDevAreaStatus(devArea.value, value.value).subscribe(data => {
          if (data.length > 0) {
            this.sequenceList = data
              .map(item => ({ text: item.name, value: item.id }))
              .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));
          }
        });
      }
    }
  }

  public getStateFromDevArea(devAreaCode: string): string {
    if (this.developmentAreas) {
      const devArea = this.developmentAreas.find(
        c => c.developmentAreaCode != null && c.developmentAreaCode.toLowerCase() === devAreaCode.toLowerCase()
      );
      if (devArea) {
        return devArea.stateCode;
      }
    }
    return '';
  }

  public onChange(value: any, fieldName: string): void {
    if (this.dasSimpleData && this.dasSimpleData.hasOwnProperty(fieldName)) {
      this.dasSimpleData[fieldName] = value.value ? value.value : value;
      this.dasSimpleService.setValue(this.dasSimpleData);
    }
  }

  private checkIsNew(): void {
    const routeName = this.router.url.split('/');
    const bodRoute = routeName[3];
    if (bodRoute === 'New') {
      this.isNewDasSimple = true;
    }
  }
}
