import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PagerService {
  constructor() {}

  calculatePages(list: any[], pageSize: number): number[] {
    const pageCount = Math.ceil(list.length / pageSize);
    const pages = [];
    for (let i = 0; i < pageCount; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  getPage(list: any[], pageNumber: number, pageSize: number): any[] {
    const pageElements = list.slice((pageNumber - 1) * pageSize, pageSize * pageNumber);
    return pageElements;
  }
}
