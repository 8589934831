<form novalidate [formGroup]="formNewBOD" id="createNewBODForm" #form="ngForm" (ngSubmit)="saveFromBOD()">
  <div class="card-body-bod-detail">
    <p class="card-title">
      BOD Details
    </p>

    <!--<div class="col-sm-12">
      <div class="form-actions text-right">
        <button kendoButton form="createNewBODForm" [primary]="true">Save</button>
      </div>
    </div>-->
    <!-- <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-8">
            <div>
              <label class="col-form-label">
                Status
              </label>
            </div>
            <kendo-dropdownlist
              formControlName="statusIndicatorId"
              [defaultItem]="{ id: null, name: 'Select status...' }"
              [data]="statusIndicators"
              [textField]="'name'"
              [valueField]="'id'"
              [disabled]="!canEditPerformanceBod"
              style="width: 250px;"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Basin Area *
              </label>
            </div>
            <kendo-dropdownlist
              formControlName="basinId"
              [data]="basinOptions"
              [textField]="'name'"
              [valueField]="'id'"
              (selectionChange)="basinSelectionChange($event)"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-dropdownlist>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Geographic Area *
              </label>
            </div>
            <kendo-dropdownlist
              [disabled]="isDisabledGeographic"
              formControlName="geographicAreaId"
              [data]="geographicAreaOptionsFiltered"
              [textField]="'name'"
              [valueField]="'id'"
              (selectionChange)="geographicAreaSelectionChange($event)"
            ></kendo-dropdownlist>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Development Area *
              </label>
            </div>
            <kendo-dropdownlist
              [disabled]="isDisabledDevelopment"
              formControlName="developmentAreaId"
              [data]="developmentAreaOptionsFiltered"
              [textField]="'name'"
              [valueField]="'id'"
            ></kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <!-- AFE DETAILS-->
    <!-- <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Default AFE No
              </label>
            </div>
            <div>
              <input
                type="text"
                class="k-textbox form-control"
                [value]="convertToAfeFormat(defaultAfeNumber)"
                [readOnly]="true"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Default AFE Amount
              </label>
            </div>
            <div>
              <input
                type="text"
                class="k-textbox form-control"
                [value]="defaultAfeAmount | currency: 'USD':'symbol'"
                [readOnly]="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--AFE DETAILS-->

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Batch *
              </label>
            </div>
            <input
              type="text"
              formControlName="batch"
              class="k-textbox form-control"
              maxlength="50"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Number of Packages *
              </label>
            </div>
            <kendo-dropdownlist
              formControlName="packageId"
              [data]="numPackages"
              textField="name"
              valueField="id"
              [itemDisabled]="itemDisabled"
              (valueChange)="handlePackagesChange($event, formNewBOD)"
              [(ngModel)]="selectedItem"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-dropdownlist>
          </div>
          <div class="col-sm-4">
            <label *ngIf="selectedItem.id >= 0" class="col-form-label text-right">
              Package 1 Name *
            </label>
            <div *ngIf="selectedItem.id >= 0">
              <input
                type="text"
                formControlName="package1Name"
                class="k-textbox form-control"
                maxlength="100"
                [readOnly]="!canEditPerformanceBod"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Performance BOD Name *
              </label>
            </div>
            <input
              type="text"
              formControlName="performanceBODName"
              class="k-textbox form-control"
              maxlength="50"
              (keyup)="onNameChange()"
              [(ngModel)]="performanceBODName"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Project Number
              </label>
            </div>
            <input
              type="number"
              max="20000"
              min="0"
              formControlName="projectNumber"
              class="k-textbox form-control"
              maxlength="5"
              oninput="this.value = Math.abs(this.value)"
              onKeyDown="if(this.value.length > 5) { this.value = '';return false;} "
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
          <div class="col-sm-4" *ngIf="selectedItem.id >= 2">
            <label class="col-form-label">
              Package 2 Name *
            </label>
            <input
              type="text"
              formControlName="package2Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Performance PM
              </label>
            </div>
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="performancePMSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'performancePMSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Performance PM Signature
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodSignatures
                    ? getFormatDate(
                        performanceBodSignatures.performanceProjectManagerCai,
                        performanceBodSignatures.performanceProjectManagerDate
                      )
                    : null
                }}
              </label>
            </div>
          </div>
          <div class="col-sm-4" *ngIf="selectedItem.id >= 3">
            <label class="col-form-label">
              Package 3 Name *
            </label>
            <input
              type="text"
              formControlName="package3Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Performance Team Lead
              </label>
            </div>
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="performanceTLSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'performanceTLSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Performance Team Lead Signature
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodSignatures
                    ? getFormatDate(
                        performanceBodSignatures.performanceTeamLeadCai,
                        performanceBodSignatures.performanceTeamLeadDate
                      )
                    : null
                }}
              </label>
            </div>
          </div>
          <div class="col-sm-4" *ngIf="selectedItem.id >= 4">
            <div>
              <label class="col-form-label">
                Package 4 Name *
              </label>
            </div>
            <input
              type="text"
              formControlName="package4Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label text-right">
                Reservoir Engineer
              </label>
            </div>
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="reservoirEngineerSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'reservoirEngineerSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Reservoir Engineer Signature
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodSignatures
                    ? getFormatDate(
                        performanceBodSignatures.performanceReservoirEngineerCai,
                        performanceBodSignatures.performanceReservoirEngineerDate
                      )
                    : null
                }}
              </label>
            </div>
          </div>
          <div class="col-sm-4" *ngIf="selectedItem.id >= 5">
            <div>
              <label *ngIf="selectedItem.id >= 5" class="col-form-label text-right">
                Package 5 Name *
              </label>
            </div>
            <input
              type="text"
              formControlName="package5Name"
              class="k-textbox form-control"
              maxlength="100"
              [readOnly]="!canEditPerformanceBod"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label text-right">
                Water Engineer
              </label>
            </div>
            <kendo-autocomplete
              [filterable]="true"
              [data]="data"
              class="form-control "
              formControlName="waterEngineerSignature"
              (filterChange)="filterChange($event)"
              (valueChange)="valueChange($event, 'waterEngineerSignature')"
              [disabled]="!canEditPerformanceBod"
            >
            </kendo-autocomplete>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label">
                Water Engineer Signature
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodSignatures
                    ? getFormatDate(
                        performanceBodSignatures.performanceWaterEngineerCai,
                        performanceBodSignatures.performanceWaterEngineerDate
                      )
                    : null
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <label class="col-form-label text-right">
              General Comments
            </label>
          </div>
          <div class="col-sm-12">
            <textarea
              class="form-control"
              formControlName="generalComments"
              rows="5"
              maxlength="500"
              [readOnly]="!canEditPerformanceBod"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <div>
              <label class="col-form-label text-right">
                Created by
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodData
                    ? getInfoCreation(performanceBodData.createdBy, performanceBodData.createdDate)
                    : null
                }}
              </label>
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label class="col-form-label text-right">
                Last Updated by
              </label>
            </div>
            <div>
              <label class="col-form-label">
                {{
                  performanceBodData
                    ? getInfoCreation(performanceBodData.updatedBy, performanceBodData.updatedDate)
                    : null
                }}
              </label>
            </div>
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>

    <!--<div class="col-sm-12">
      <div class="form-actions text-right">
        <button kendoButton form="createNewBODForm" [primary]="true">Save</button>
      </div>
    </div>-->
  </div>
</form>
