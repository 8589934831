<ng-template #clearTooltip let-anchor>
  <span
    ><b>{{ anchor.nativeElement.id === 'sort' ? 'Sorted By: ' : 'Filtered By: ' }}</b></span
  >
  <br />
  <div *ngFor="let desc of getSortDescription()">
    <div *ngIf="anchor.nativeElement.id === 'sort'">
      <span>{{ desc }}</span>
      <br />
    </div>
  </div>
  <div *ngFor="let desc of getFilterDescription()">
    <div *ngIf="anchor.nativeElement.id === 'filter'">
      <span>{{ desc }}</span>
      <br />
    </div>
  </div>
  <span><i>Click to clear</i></span>
</ng-template>
<ng-template #headerTooltip let-anchor>
  <span
    ><b>{{ anchor.nativeElement.getAttribute('data-expanded') }}</b></span
  >
</ng-template>
<div kendoTooltip [tooltipTemplate]="clearTooltip" filter=".clear" class="card-body">
  <kendo-grid
    class="fit-cc-grid"
    [data]="view | async"
    [height]="533"
    [pageSize]="this.baseService.getCurrentState().take"
    [skip]="this.baseService.getCurrentState().skip"
    [sort]="this.baseService.getCurrentState().sort"
    [pageable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [scrollable]="true"
    [selectable]="true"
    [resizable]="true"
    [columnMenu]="{ lock: true }"
    [filter]="this.baseService.getCurrentState().filter"
    filterable="{{ filterableHandler() }}"
    (filterChange)="filterChange($event)"
    (sortChange)="sortChange($event)"
    (dataStateChange)="onStateChange($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    [kendoGridInCellEditing]="createFormGroup"
    (cellClose)="cellCloseHandler($event)"
    [navigable]="true"
    [fitCcinCellTab]="createFormGroup"
    (columnResize)="onResize($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    (pageChange)="pageChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        *ngIf="canAddNewRecord"
        kendoButton
        class="ml-2 px-1 k-button k-grid-edit-command k-button-md k-rounded-md k-button-solid-primary k-button-solid"
        (click)="add()"
      >
        <span class="k-icon k-i-plus m-1"></span>Add new
      </button>
      <span class="ml-1" *ngIf="this.baseService.hasChanges() && this.editType == 'inline'">
        <button kendoButton class="k-button k-primary" (click)="saveChanges()">
          <span class="k-icon k-i-save m-1"></span>Save Changes
        </button>
        <button kendoButton class="k-button" (click)="cancelChanges()">
          <span class="k-icon k-i-cancel m-1"></span>Discard Changes
        </button>
      </span>
      <button
        kendoButton
        id="sort"
        class="k-button k-primary ml-2 mt-2 mb-2 clear"
        *ngIf="activeSort()"
        (click)="this.baseService.clearSort()"
      >
        <span class="k-icon k-i-unsort m-1"></span>
      </button>
      <button
        kendoButton
        id="filter"
        class="k-button k-primary ml-2 mt-2 mb-2 clear"
        *ngIf="activeFilter()"
        (click)="this.baseService.clearFilter()"
      >
        <span class="k-icon k-i-filter-clear m-1"></span>
      </button>
      <button
        kendoButton
        title="Clear Persisted Settings"
        class="k-button k-primary mr-2  mt-2 mb-2 float-right"
        *ngIf="this.baseService.persistedSettings"
        (click)="clearPersist()"
      >
        <span class="k-icon k-i-cancel m-1"></span>
      </button>
    </ng-template>
    <kendo-grid-command-column *ngIf="this.editType === 'popup' || this.deleteAction" title="Actions" width="100">
      <ng-template kendoGridCellTemplate>
        <button class="ml-2 px-1" *ngIf="this.editType == 'popup'" kendoButton kendoGridEditCommand [primary]="true">
          Edit
        </button>
        <button *ngIf="this.deleteAction" class="ml-2 px-1" kendoButton kendoGridRemoveCommand [primary]="true">
          Delete
        </button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column
      *ngFor="let col of columns; index as i"
      [field]="col.field"
      [title]="col.title"
      [width]="col.width"
      [hidden]="col.hiddenInGrid"
      [filter]="col.type"
      [editor]="col.type"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <div kendoTooltip [tooltipTemplate]="headerTooltip" filter=".column-heading">
          <span class="column-heading" [attr.data-expanded]="col.extended ? col.extended : col.title">{{
            col.title
          }}</span>
        </div>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.type == 'dropdown' || col.type == 'multiselect'"
      >
        <fit-cc-column-filter
          [isPrimitive]="false"
          [field]="col.field"
          [filterService]="filterService"
          [currentFilter]="filter"
          [filterOperator]="this.baseService.filterOperatorHandler(col.type)"
          textField="text"
          valueField="value"
          [data]="col.options"
        >
        </fit-cc-column-filter>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" *ngIf="col.type === 'text' || col.type === 'numeric'">
        <fit-cc-cell-indicator
          [item]="dataItem"
          [field]="col.field"
          [updatedItems]="baseService.getUpdatedItems()"
          [originalData]="baseService.getOriginalData()"
        >
        </fit-cc-cell-indicator>
        <span class="k-cell-padding">
          {{ getString(dataItem, col) }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.type === 'date'">
        <fit-cc-cell-indicator
          [item]="dataItem"
          [field]="col.field"
          [updatedItems]="baseService.getUpdatedItems()"
          [originalData]="baseService.getOriginalData()"
        >
        </fit-cc-cell-indicator>
        <span class="k-cell-padding">
          {{ dataItem[col.field] | date: 'shortDate' }}
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.type === 'boolean'">
        <fit-cc-cell-indicator
          [item]="dataItem"
          [field]="col.field"
          [updatedItems]="baseService.getUpdatedItems()"
          [originalData]="baseService.getOriginalData()"
        >
        </fit-cc-cell-indicator>
        <span class="k-cell-padding text-center">
          <i [ngClass]="dataItem[col.field] ? 'k-icon k-i-check' : 'k-icon k-i-close'"> </i>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.type === 'dropdown'">
        <fit-cc-cell-indicator
          [item]="dataItem"
          [field]="col.field"
          [updatedItems]="baseService.getUpdatedItems()"
          [originalData]="baseService.getOriginalData()"
        >
        </fit-cc-cell-indicator>
        <span class="k-cell-padding">
          {{ dataItem[col.field] }}
        </span>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem
        let-formGroup
        *ngIf="col.type === 'dropdown' && editType === 'inline'"
      >
        <kendo-dropdownlist
          [formControl]="this.formGroup.get(col.field)"
          [defaultItem]="{ text: 'Select item...', value: null }"
          [data]="col.options"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          [hidden]="col.hidden"
          [required]="col.required"
        >
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem *ngIf="col.type === 'multiselect'">
        <fit-cc-cell-indicator
          [item]="dataItem"
          [field]="col.field"
          [updatedItems]="baseService.getUpdatedItems()"
          [originalData]="baseService.getOriginalData()"
        >
        </fit-cc-cell-indicator>
        <span class="k-cell-padding">
          <div *ngFor="let item of dataItem[col.field]">
            {{ item }}
          </div>
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup *ngIf="col.type === 'multiselect' && editType === 'inline'">
        <kendo-multiselect
          name="col.field"
          [data]="col.options"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          [formControl]="this.formGroup.get(col.field)"
        >
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <fit-cc-popup-form
    [columnsForm]="columns"
    [model]="editDataItem"
    [isNew]="isNew"
    [selectedLogTable]="selectedLogTable"
    (save)="saveHandler($event)"
    (cancel)="cancelHandler()"
  >
  </fit-cc-popup-form>
</div>
<ng-container #dialogContainer></ng-container>
