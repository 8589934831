<div [formGroup]="locationForm" class="fit-cc-location-form"
    [ngClass]="{ 'fit-cc-location-form__active': active }">
    <div class="row mb-1">
        <div class="col">
            <fit-cc-input formControlName="longitude" placeholder="{{ longitudeLabel }}" [type]="'GEOGRAPHY'"
                [readonly]="readonly" [disabled]="disabled" [spinners]="true" (onChange)="longitudeHandler($event)">
            </fit-cc-input>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <fit-cc-input formControlName="latitude" placeholder="{{ latitudeLabel }}" [type]="'GEOGRAPHY'"
                [readonly]="readonly" [disabled]="disabled" [spinners]="true" (onChange)="latitudeHandler($event)">
            </fit-cc-input>
        </div>
    </div>
    <a *ngIf="!disabled && !readonly" title="Change location" [href]="" (click)="iconHandler()"><i
            class="k-icon k-i-marker-pin align-position-icon"></i></a>
</div>