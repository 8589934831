<div class="pacer-page-header">
  <h4>C&D Scenarios</h4>
</div>

<div class="card-body">
  <div class="row">
    <kendo-grid
      [data]="gridData"
      [filter]="state.filter"
      [filterable]="true"
      (dataStateChange)="onDataStateChange($event)"
      [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
      [sort]="sort"
      [loading]="isLoading"
      (sortChange)="sortChange($event)"
      style="height: auto; overflow-x: hidden;"
      accesskey="true"
      [selectable]="true"
      [kendoGridSelectBy]="scenarioSelectedKey"
      [(selectedKeys)]="scenariosSelected"
    >
      <kendo-grid-column field="developmentAreaName" title="Development Area"></kendo-grid-column>
      <kendo-grid-column field="isActiveForOutlook" title="Active for Outlook?" ></kendo-grid-column>
      <div *kendoGridDetailTemplate="let dataItem">
        <app-scenario-data-expansion
          [developmentAreaName]="dataItem.developmentAreaName"
          [isActiveForOutlook]="dataItem.isActiveForOutlook"
        ></app-scenario-data-expansion>
      </div>
    </kendo-grid>
  </div>
</div>
