<kendo-dialog *ngIf="active" (close)="closeForm()"  class="k-dialog-wrapper ng-star-inserted" [width]="400" >
    <kendo-dialog-titlebar>
      {{ dialogTitle != null?  dialogTitle : isNew ? 'Add new item' : 'Edit item'}}
    </kendo-dialog-titlebar>
  <div style="height:400px; overflow:auto; padding:1.143em; margin:-1.143em;">
      <form novalidate [formGroup]="editForm">
          <div class="form-group"  *ngFor="let column of columnsForm">
              <div *ngIf="!column.hidden">
                  <fit-cc-form-control [column]="column" [columns]="columnsForm" [form]="editForm" [isNew]="isNew"></fit-cc-form-control>
              </div>
          </div>
      </form>
  </div>
      <kendo-dialog-actions class="no-border k-dialog-buttongroup k-dialog-button-layout-stretched">
          <button class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid ng-star-inserted" (click)="onCancel($event)">Cancel</button>
          <button class="k-button k-button-md k-rounded-md k-button-solid-primary k-button-solid ng-star-inserted" [disabled]="!editForm.valid" (click)="onSave($event)">Save</button>
      </kendo-dialog-actions>
  </kendo-dialog>
