<div *ngIf="showProgressBar" class="mat-app-background basic-container">
    <app-progress-bar-indeterminate-example></app-progress-bar-indeterminate-example>
  </div>
  <form novalidate [formGroup]="formSmartImport" #form="ngForm">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)" #tabStripSequence>
      <kendo-tabstrip-tab [title]="'Search for sequence'" [selected]="selectedOptionIndexTab1">
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Dev area:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="devArea"
                  [data]="devAreaListFiltered"
                  textField="text"
                  valueField="value"
                  (valueChange)="handleDevAreaChange($event, formSmartImport)"
                  style="width:100%;"
                  [filterable]="true"
                  (filterChange)="handleFilterSmartDevArea($event)"
                  [listHeight] = "400"                 
                >
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Sequence status:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="sequenceStatus"
                  [data]="sequenceStatusList"
                  textField="text"
                  valueField="value"
                  (valueChange)="handleSequenceStatusChange($event, formSmartImport)"
                  [disabled]="!canEditCnd"
                  style="width:100%;"
                >
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Sequence:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="sequence"
                  [data]="sequenceListFiltered"
                  textField="text"
                  [disabled]="sequenceSelectDisabled"
                  valueField="value"
                  [listHeight] = "400"
                  style="width:100%;"
                  [filterable]="true"
                  (filterChange)="handleFilterSmartSequence($event)"
                >
                </kendo-dropdownlist>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Provide link to SMART.'" [selected]="selectedOptionIndexTab2">
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="card-body-smart-link">
            <div class="row no-gutters">
              <div class="col-sm-10">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label class="col-form-label">
                      SMART link:
                    </label>
                  </div>
                  <div class="col-sm-6">
                    <textarea class="form-control" formControlName="smartLink" rows="5" maxlength="500"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button *ngIf="!isRefreshPads" kendoButton primary="true" (click)="onClickOk()">Submit</button>
    <button  *ngIf="isRefreshPads" kendoButton primary="true" (click)="onClickRefreshPads()">Refresh Pads</button>
  </kendo-dialog-actions>
