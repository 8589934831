import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { ICndCostMappingSft } from '../api/cnd-cost-mapping-sft';
import { concat } from 'rxjs';
import { IDasSimpleCostsModel } from '../api/das-simple-costs-model';
import { keyFacilityItem, keyFacilityItemDetail } from '../api/input-item';
import { IScopeAssumptions } from '../api/IScopeAssumptions';
import { ISmartImportSequence } from '../api/smart-import-sequence';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export enum keyStateCode {
  STATE_NEW_MEXICO_CODE = 'NM',
  STATE_TEXAS_CODE = 'TX',
}

@Injectable({
  providedIn: 'root',
})
export class DasSimpleCalculationsService {
  public baseUrlCndCostMappingSft: string = environment.PacerDomainURL + 'CndCostMappingSft';
  public baseUrlScenario: string = environment.PricetDomainURL + 'Scenario';
  public baseUrlFacilityItem: string = environment.PacerDomainURL + 'FacilityItem';
  public GetDevAreaCodes: string = environment.PricetDomainURL + 'PbsorExternal/GetDevAreas/';
  public dasSimpleCosts$: Observable<IDasSimpleCostsModel[]>;
  private handleError: HandleError;
  private dasSimpleCostsValue$: BehaviorSubject<IDasSimpleCostsModel[]>;
  private isLoaded$: BehaviorSubject<boolean>;
  private costForItems: any[] = [];
  private facilityItems: any[] = [];
  private developmentAreas: any[] = [];

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    this.dasSimpleCostsValue$ = new BehaviorSubject<IDasSimpleCostsModel[]>([]);
    this.dasSimpleCosts$ = this.dasSimpleCostsValue$.asObservable();
    this.isLoaded$ = new BehaviorSubject<boolean>(null);
    this.handleError = httpErrorHandler.createHandleError();
  }

  public load(): void {
    const isLoaded  = this.isLoaded$.getValue();
    if (!isLoaded) {
      this.getCndCostMappingSft().subscribe((cndCostMappingSft: ICndCostMappingSft[]) => {
        const first = this.getCostForDasSimple(cndCostMappingSft)
        .pipe(map((keyValuePair: any[]) => {
          this.costForItems = keyValuePair;
        }),take(1));
  
  
        const ids = cndCostMappingSft.map(item => item.cndItemId);
        const second = this.getFaciltityItemsFromIds(ids)
        .pipe(map((facilityItems: any[]) => {
          this.facilityItems = facilityItems;
        }), take(1));
  
  
        const getDevAreasFirst = this.getDevAreaCodes().pipe(take(1));
  
        getDevAreasFirst.subscribe(data => {
          if (data.result.length > 0) {
            this.developmentAreas = data.result;
          }
        });
  
        concat(first, second)
          .toPromise()
          .then(() => {
            const dasSimplesCosts: IDasSimpleCostsModel[] = [];
  
          /*NOSONAR*/  this.costForItems.map((cost: any) => {
              const dasSimpleCost: IDasSimpleCostsModel = {
                id: cost.key,
                name: null,
                type: null,
                cost: cost.value,
              };
              dasSimplesCosts.push(dasSimpleCost);
              return cost;
            });
  
         /*NOSONAR*/ this.facilityItems.map((facilityItem: any) => {
              const dasSimpleCost = dasSimplesCosts.find(c => c.id === facilityItem.id);
              if (dasSimpleCost) {
                dasSimpleCost.name = facilityItem.name;
                dasSimpleCost.type = facilityItem.type;
              }
              return facilityItem;
            });
  
            this.dasSimpleCostsValue$.next(dasSimplesCosts);
            this.isLoaded$.next(true);
          })
          .catch((error: any) => {
            this.dasSimpleCostsValue$.error(error);
          });
      });
    }
  }

  public cleanObservables(): void {
    // this.dasSimpleCostsValue$.next(null);
    // this.isLoaded$.next(null);
  }

  public calculate1TrainCtb(count: number): number {
    const train1CtbId = keyFacilityItemDetail.GREENFIELD_1_TRAIN_CTB_INSTALL_20_DESIGN;
    const cost1TrainCtb = this.getCostByItemId(train1CtbId);
    const ctbPadCost = this.getCostByItemName(keyFacilityItem.CTB_PAD_COST);
    const commercialOilTieIn = this.getCostByItemName(keyFacilityItem.COMMERCIAL_OIL_TIE_IN);
    const facilityRadioTower60 = this.getCostByItemName(keyFacilityItem.FACILITY_RADIO_TOWER_60);
    const standardNetworkEquipmentPackage = this.getCostByItemName(keyFacilityItem.STANDARD_NETWORK_EQUIPMENT_PACKAGE);
    // Fixed value
    const landDamages = 28000;
    console.log('cost1TrainCtb = ' + cost1TrainCtb);
    console.log('ctbPadCost = ' + ctbPadCost);
    console.log('commercialOilTieIn = ' + commercialOilTieIn);
    console.log('facilityRadioTower60 = ' + facilityRadioTower60);
    console.log('standardNetworkEquipmentPackage = ' + standardNetworkEquipmentPackage);
    console.log('landDamages = ' + landDamages);
    return (
      count *
      (cost1TrainCtb +
        ctbPadCost * 102 +
        commercialOilTieIn +
        facilityRadioTower60 +
        standardNetworkEquipmentPackage +
        landDamages)
    );
  }

  public calculate1TrainAdditionCtb(count: number): number {
    const cost1TrainAdditionCtb = this.getCostByItemName(keyFacilityItem.TRAIN_1_CTB_ADDITION);
    const ctbPadCost = this.getCostByItemName(keyFacilityItem.CTB_PAD_COST);
    // Fixed value
    const landDamages = 28000;
    console.log('cost1TrainAdditionCtb = ' + cost1TrainAdditionCtb);
    console.log('ctbPadCost = ' + ctbPadCost);
    console.log('landDamages = ' + landDamages);
    return count * (cost1TrainAdditionCtb + ctbPadCost * 102 + landDamages);
  }

  public calculateSatellite(count: number): number {
    const costSatelliteCtb = this.getCostByItemName(keyFacilityItem.GREENFIELD_SATELLITE_INSTALL);
    const hiAccurracySatelliteMetering = this.getCostByItemName(keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE);
    const facilityRadioTower60 = this.getCostByItemName(keyFacilityItem.FACILITY_RADIO_TOWER_60);
    const standardNetworkEquipmentPackage = this.getCostByItemName(keyFacilityItem.STANDARD_NETWORK_EQUIPMENT_PACKAGE);
    // Fixed value
    const landDamages = 12800;

    console.log('costSatelliteCtb = ' + costSatelliteCtb);
    console.log('hiAccurracySatelliteMetering = ' + hiAccurracySatelliteMetering);
    console.log('facilityRadioTower60 = ' + facilityRadioTower60);
    console.log('standardNetworkEquipmentPackage = ' + standardNetworkEquipmentPackage);
    console.log('landDamages = ' + landDamages);

    return (
      count *
      (costSatelliteCtb +
        hiAccurracySatelliteMetering +
        facilityRadioTower60 +
        standardNetworkEquipmentPackage +
        landDamages)
    );
  }

  public calculate2TrainSwd(swdInjLineId: string, liquidsTransferLineId: string, count: number): number {
    const cost2TrainSwd = this.getCostByItemName(keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL);
    const costSwdInjLineDetail = this.getCostByItemId(swdInjLineId);
    const costSwdInjLineRiser = this.getPipelineRiserCost(swdInjLineId);
    const swdInjectionHPump = this.getCostByItemName(keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION);
    const swdWellPadNHookup = this.getCostByItemName(keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP);
    const facilityRadioTower60 = this.getCostByItemName(keyFacilityItem.FACILITY_RADIO_TOWER_60);
    const standardNetworkEquipmentPackage = this.getCostByItemName(keyFacilityItem.STANDARD_NETWORK_EQUIPMENT_PACKAGE);
    // Fixed value
    const landDamages = 12800 + 56800 + 3900;

    console.log('cost2TrainSwd = ' + cost2TrainSwd);
    console.log('costSwdInjLineDetail = ' + costSwdInjLineDetail);
    console.log('costSwdInjLineRiser = ' + costSwdInjLineRiser);
    console.log('swdInjectionHPump = ' + swdInjectionHPump);
    console.log('swdWellPadNHookup = ' + swdWellPadNHookup);
    console.log('facilityRadioTower60 = ' + facilityRadioTower60);
    console.log('standardNetworkEquipmentPackage = ' + standardNetworkEquipmentPackage);
    console.log('landDamages = ' + landDamages);

    return (
      count *
      (cost2TrainSwd +
        6 * swdInjectionHPump +
        swdWellPadNHookup +
        costSwdInjLineDetail * 1000 +
        costSwdInjLineRiser +
        facilityRadioTower60 +
        standardNetworkEquipmentPackage +
        landDamages)
    );
  }

  public calculate2TrainAdditionSwd(count: number): number {
    const cost2TrainAdditionSwd = this.getCostByItemName(keyFacilityItem.TWO_TRAIN_SWD_ADDITION);
    const swdInjectionHPump = this.getCostByItemName(keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION);
    // Fixed value
    const landDamages = 12800;
    console.log('cost2TrainAdditionSwd = ' + cost2TrainAdditionSwd);
    console.log('swdInjectionHPump = ' + swdInjectionHPump);
    console.log('landDamages = ' + landDamages);

    return count * (cost2TrainAdditionSwd + 6 * swdInjectionHPump + landDamages);
  }

  public calculateFracPondVolume(fracPondTypeId: string, count: number, developmentAreaCode: string): number {
    const isRwcs = fracPondTypeId.toUpperCase() === keyFacilityItemDetail.FRAC_PONDS_RWCS;
    developmentAreaCode = developmentAreaCode ? developmentAreaCode : '';
    const stateCodeByDevAreaCode = this.developmentAreas.find(
      d => d.developmentAreaCode != null && d.developmentAreaCode.toUpperCase() === developmentAreaCode.toUpperCase()
    );

    let costFracPondVolume = 0;
    switch (stateCodeByDevAreaCode.stateCode.toUpperCase() )
    {
        case keyStateCode.STATE_TEXAS_CODE:
            if (isRwcs){
              costFracPondVolume = 4000;
            } else {
              costFracPondVolume = 2500;
            }
            break;
        case keyStateCode.STATE_NEW_MEXICO_CODE:
          if (isRwcs){
            costFracPondVolume = 5000;
          } else {
            costFracPondVolume = 3500;
          }
            break;
        default:
          costFracPondVolume = this.getCostByItemId(fracPondTypeId);
            break;
    }    
    
    const aerationCirculationSkid = isRwcs ? this.getCostByItemName(keyFacilityItem.AERATION_SKID) : 0;
    // Fixed value
    const landDamages = 28800;

    console.log('costFracPondVolume = ' + costFracPondVolume);
    console.log('aerationCirculationSkid = ' + aerationCirculationSkid);
    console.log('landDamages = ' + landDamages);

    if (count === 0) {
      return 0;
    }
    return count * (costFracPondVolume + aerationCirculationSkid) + landDamages;
  }

  public calculateFru(count: number): number {
    const costRecycleWaterTransferLine = this.getCostByItemId(
      keyFacilityItemDetail.RECYCLE_WATER_TRANSFER_LINE_8_SDR7_HDPE
    );
    const fixedWaterRecycle = this.getCostByItemName(keyFacilityItem.FIXED_WATER_RECICLYE_UNIT_INSTALL);
    const recycleTransferPump = this.getCostByItemName(keyFacilityItem.RECYCLE_TRANSFER_PUMP_SKID);
    // Fixed value
    const landDamages = 3900;

    console.log('costRecycleWaterTransferLine = ' + costRecycleWaterTransferLine);
    console.log('fixedWaterRecycle = ' + fixedWaterRecycle);
    console.log('recycleTransferPump = ' + recycleTransferPump);
    console.log('landDamages = ' + landDamages);

    return count * (fixedWaterRecycle + recycleTransferPump + costRecycleWaterTransferLine * 1000 + landDamages);
  }

  public calculatePadBase(bgWhCount: number, developmentAreaCode: string): number {
    developmentAreaCode = developmentAreaCode ? developmentAreaCode : '';
    const stateCodeByDevAreaCode = this.developmentAreas.find(
      d => d.developmentAreaCode != null && d.developmentAreaCode.toUpperCase() === developmentAreaCode.toUpperCase()
    );

    const reservePit = this.getCostByItemName(keyFacilityItem.RESERVE_PIT_CONSTRUCTION);
    const postDrillPad = this.getCostByItemName(keyFacilityItem.POST_DRILL_PAD_RECLAMATION);
    // Fixed value
    const landDamages = 68000;
    const isTexas = stateCodeByDevAreaCode
      ? stateCodeByDevAreaCode.stateCode.toUpperCase() === keyStateCode.STATE_TEXAS_CODE
      : false;

    if (bgWhCount === 0) {
      const wpAg = this.getCostByItemId(keyFacilityItemDetail.WELL_PAD_MULTI_USE_SUB_GRADE_WH_ABOVE_GRADE);
      console.log('wpAg = ' + wpAg);
      return wpAg + landDamages + (isTexas ? reservePit + postDrillPad : 0);
    }
    console.log('reservePit = ' + reservePit);
    console.log('postDrillPad = ' + postDrillPad);
    console.log('landDamages = ' + landDamages);
    return isTexas ? reservePit + postDrillPad : 0;
  }

  public calculateWellBase(wellsPerTestSeparatorCount: number, numberOfWells: number): number {
    const wellAlHookupGl = this.getCostByItemName(keyFacilityItem.WELLHEAD_AL_HOOKUP_GAS_LIFT);
    const surveyAndPermitting = this.getCostByItemName(keyFacilityItem.SURVEY_AND_PERMITTING);
    const flowlineRisers = this.getCostByItemId(keyFacilityItemDetail.FLOWLINE_RISERS_2_PER_WELL_FL);
    const ctbHeader = this.getCostByItemName(keyFacilityItem.CTB_HEADER);
    const ctbTestSeparator = this.getCostByItemName(keyFacilityItem.CTB_TEST_SEPARATOR);

    console.log('reserwellAlHookupGlvePit = ' + wellAlHookupGl);
    console.log('surveyAndPermitting = ' + surveyAndPermitting);
    console.log('flowlineRisers = ' + flowlineRisers);
    console.log('ctbHeader = ' + ctbHeader);
    console.log('ctbTestSeparator = ' + ctbTestSeparator);
    console.log('numberOfWells = ' + numberOfWells);

    return (
      (wellAlHookupGl +
        surveyAndPermitting +
        2 * flowlineRisers +
        ctbHeader / 4 +
        (wellsPerTestSeparatorCount === 0 ? 0 : ctbTestSeparator / wellsPerTestSeparatorCount)) *
      numberOfWells
    );
  }

  public getCostByItemName(itemName: string): number {
    const dasSimpleCosts = this.dasSimpleCostsValue$.value;
    if (dasSimpleCosts) {
      const costByItemName = dasSimpleCosts.find(
        c => c.name != null && c.name.toLowerCase() === itemName.toLowerCase()
      );
      if (costByItemName) {
        return costByItemName.cost;
      }
    }
    return null;
  }

  public getCostByItemId(id: string): number {
    const dasSimpleCosts = this.dasSimpleCostsValue$.value;
    id = id ? id : '';
    if (dasSimpleCosts) {
      const costByItemId = dasSimpleCosts.find(c => c.id.toLowerCase() === id.toLowerCase());
      if (costByItemId) {
        return costByItemId.cost;
      }
    }
    return null;
  }

  public getPipelineRiserCost(id: string): number {
    const dasSimpleCosts = this.dasSimpleCostsValue$.value;
    id = id ? id : '';
    try {
      if (dasSimpleCosts) {
        const costByItemId = dasSimpleCosts.find(c => c.id.toLowerCase() === id.toLowerCase());
        if (costByItemId) {
          const indexOf = costByItemId.type.indexOf('"');
          const type = costByItemId.type.substring(0, indexOf);
          let itemName: string = '';
          switch (costByItemId.name) {
            case 'In-Field Gas Gathering/Sales, HP':
              itemName = 'HP Gas Pipeline Riser';
              break;
            case 'In-Field Gas Gathering/Sales, LP':
              itemName = 'LP Gas Pipeline Riser';
              break;
            case 'Produced Water Gathering':
              itemName = 'Produced Water Pipeline Riser';
              break;
            case 'In-Field Liquids Gathering from Satellite':
              itemName = 'Liquids Pipeline Riser';
              break;
            case 'Gas Lift Distribution':
              itemName = 'Gas Lift Pipeline Riser';
              break;
            case 'SWD Injection Line':
              itemName = 'SWD Injection Line Riser';
              break;
          }
          const pipelineRiserId = dasSimpleCosts.find(
            c => c.name != null && c.name.toLowerCase() === itemName.toLowerCase() && c.type.startsWith(type)
          );

          return pipelineRiserId.cost;
        }
      }
    } catch (e) {
      return 0;
    }

    return 0;
  }

  // #region Infrastructure
  public calculateLPGas(lpGasLineId: string, count: number): number {
    const gasGatherinSalesLP = this.getCostByItemId(lpGasLineId);
    const pipelineRiser = this.getPipelineRiserCost(lpGasLineId);
    const landDamages = 7.3;

    console.log('Gas gathering sales LP = ' + gasGatherinSalesLP);
    console.log('HP Gas pipeline riser = ' + pipelineRiser);
    console.log('Land damages = ' + landDamages);

    return count * 5280 * gasGatherinSalesLP + Math.ceil(count) * pipelineRiser + count * 5280 * landDamages;
  }

  public calculateHPGas(hpGasLineId: string, count: number): number {
    const gasGatheringSalesHP = this.getCostByItemId(hpGasLineId);
    const pipelineRiser = this.getPipelineRiserCost(hpGasLineId);
    const landDamages = 3.9;
    console.log('Gas gathering sales HP = ' + gasGatheringSalesHP);
    console.log('HP Gas pipeline riser = ' + pipelineRiser);
    console.log('Land damages = ' + landDamages);

    const hpGas = count * 5280 * gasGatheringSalesHP + Math.ceil(count) * pipelineRiser + count * 5280 * landDamages;

    return hpGas;
  }

  public calculateWater(waterLineId: string, count: number): number {
    const producedWaterGathering = this.getCostByItemId(waterLineId);
    const producedWaterPipelineRiser = this.getPipelineRiserCost(waterLineId);
    const landDamages = 7.3;

    console.log('producedWaterGathering = ' + producedWaterGathering);
    console.log('producedWaterPipelineRiser = ' + producedWaterPipelineRiser);
    console.log('landDamages = ' + landDamages);

    return (
      count * 5280 * producedWaterGathering + Math.ceil(count) * producedWaterPipelineRiser + count * 5280 * landDamages
    );
  }

  public calculateLiquid(liquidLineId: string, count: number): number {
    const infieldLiquidsGatheringSatellite = this.getCostByItemId(liquidLineId);
    const liquidsPipelineRiser = this.getPipelineRiserCost(liquidLineId);
    const landDamages = 7.3;

    console.log('infieldLiquidsGatheringSatellite = ' + infieldLiquidsGatheringSatellite);
    console.log('liquidsPipelineRiser = ' + liquidsPipelineRiser);
    console.log('landDamages = ' + landDamages);

    return (
      count * 5280 * infieldLiquidsGatheringSatellite +
      Math.ceil(count) * liquidsPipelineRiser +
      count * 5280 * landDamages
    );
  }

  public calculateGasLift(gasLiftLineId: string, count: number): number {
    const gasLiftDistribution = this.getCostByItemId(gasLiftLineId);
    const pipelineRiser = this.getPipelineRiserCost(gasLiftLineId);
    const landDamages = 3.9;

    console.log('gasLiftDistribution = ' + gasLiftDistribution);
    console.log('pipelineRiser = ' + pipelineRiser);
    console.log('landDamages = ' + landDamages);

    return count * 5280 * gasLiftDistribution + Math.ceil(count) * pipelineRiser + count * 5280 * landDamages;
  }

  public calculateFlowlines(wellCount: number, count: number): number {
    // The type is 4" Flex (Flowlines)
    const flowlineMaterialsAndInstallation = this.getCostByItemName(
      keyFacilityItem.FLOWLINE_MATERIALS_AND_INSTALLATION
    );
    const flowlineTrenching = this.getCostByItemName(keyFacilityItem.FLOWLINE_TRENCHING);
    const landDamages = 2.7;

    console.log('flowlineMaterialsAndInstallation = ' + flowlineMaterialsAndInstallation);
    console.log('flowlineTrenching = ' + flowlineTrenching);
    console.log('landDamages = ' + landDamages);

    return (
      count * 5280 * flowlineMaterialsAndInstallation * wellCount + count * 5280 * (flowlineTrenching + landDamages)
    );
  }

  public calculateGeneralInfrastructure(count: number): number {
    const roadConstructionAndCaliche = this.getCostByItemName(keyFacilityItem.ROAD_CONSTRUCTION_AND_CALICHE);
    const edsMainLine = this.getCostByItemName(keyFacilityItem.EDS_MAIN_LINE_6_MVA);
    const fiberOpticCable = this.getCostByItemName(keyFacilityItem.FIBER_OPTIC_CABLE);
    const landDamages = 21;

    console.log('Road Construction and Caliche = ' + roadConstructionAndCaliche);
    console.log('EDS Main line = ' + edsMainLine);
    console.log('Fiber Optic Cable = ' + fiberOpticCable);

    return count * 5280 * (roadConstructionAndCaliche + edsMainLine + fiberOpticCable + landDamages);
  }

  // #endregion

  // #region Gas
  public calculateCompressorStation(count: number): number {
    const greenFieldCompressorStationInstall = this.getCostByItemName(
      keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
    );
    const compressorStationVRU = this.getCostByItemId(keyFacilityItemDetail.CTB_VRU_FX_8);
    const commercialGasTieIn = this.getCostByItemName(keyFacilityItem.COMMERCIAL_GAS_TIE_IN);
    const flare = this.getCostByItemName(keyFacilityItem.FLARE_SYSTEM);
    const facilityRadioTower = this.getCostByItemName(keyFacilityItem.FACILITY_RADIO_TOWER_60);
    const standardNetworkEquipmentPackage = this.getCostByItemName(keyFacilityItem.STANDARD_NETWORK_EQUIPMENT_PACKAGE);
    const landDamages = 20000;

    console.log('compressorStationVRU = ' + compressorStationVRU);
    console.log('commercialGasTieIn = ' + commercialGasTieIn);
    console.log('flare = ' + flare);
    console.log('facilityRadioTower = ' + facilityRadioTower);
    console.log('standardNetworkEquipmentPackage = ' + standardNetworkEquipmentPackage);
    console.log('landDamages = ' + landDamages);

    return (
      count *
      (greenFieldCompressorStationInstall +
        compressorStationVRU +
        commercialGasTieIn +
        flare +
        facilityRadioTower +
        standardNetworkEquipmentPackage +
        landDamages)
    );
  }

  public calculateElectricCOOPStation(count: number): number {
    return count * 7000000;
  }

  public calculateElectricCOOPTrain(count: number): number {
    return count * 20000000;
  }

  public calculateCOOP(coopCompressorId: string, count: number) {
    const coopCost = this.getCostByItemId(coopCompressorId);

    return count * coopCost;
  }

  public calculateRentals(rentalId: string, count: number) {
    const rentalCost = this.getCostByItemId(rentalId);

    return count * rentalCost;
  }
  // #endregion

  // #region Other
  public calculateTower(count: number): number {
    const radioTowerBackbone = this.getCostByItemName(keyFacilityItem.RADIO_TOWER_BACKBONE);
    console.log('radioTowerBackbone = ' + radioTowerBackbone);
    return count * radioTowerBackbone;
  }

  public calculateBGWH(wellCount: number, count: number): number {
    const wellPadMultiUse = this.getCostByItemId(keyFacilityItemDetail.WELL_PAD_MULTI_USE_SUB_GRADE_WH_BELOW_GRADE);
    const perWellCostAdderSubGradeCellars = this.getCostByItemName(
      keyFacilityItem.PER_WELL_COST_ADDER_SUB_GRADE_WD_CELLARS
    );
    const landDamages = 68000;
    console.log('wellPadMultiUse = ' + wellPadMultiUse);
    console.log('perWellCostAdderSubGradeCellars = ' + perWellCostAdderSubGradeCellars);
    console.log('landDamages = ' + landDamages);

    return count * (wellPadMultiUse + perWellCostAdderSubGradeCellars * wellCount + landDamages);
  }
  // #endregion

  public setTaxesLogicToAssumptions(scopeAssumptions: IScopeAssumptions, smartSequence: any): void {
    const stateCode = this.getStateFromDevArea(smartSequence.devAreaCode);

    if (stateCode === keyStateCode.STATE_TEXAS_CODE) {
      scopeAssumptions.tax = 0.0825;
    } else if (stateCode === keyStateCode.STATE_NEW_MEXICO_CODE) {
      scopeAssumptions.tax = 0.07;
    } else {
      scopeAssumptions.tax = 0.0825;
    }
  }

  private getStateFromDevArea(devAreaCode: string): string {
    if (this.developmentAreas) {
      const devArea = this.developmentAreas.find(
        c => c.developmentAreaCode != null && c.developmentAreaCode.toLowerCase() === devAreaCode.toLowerCase()
      );
      if (devArea) {
        return devArea.stateCode;
      }
    }
    return '';
  }

  private getCndCostMappingSft(): Observable<ICndCostMappingSft[]> {
    return this.http.get<ICndCostMappingSft[]>(`${this.baseUrlCndCostMappingSft}/List/`).pipe(
      catchError(error => {
        this.handleError('getCndCostMappingSft');
        return throwError(error);
      })
    );
  }

  private getCostForDasSimple(costMappingsSft: ICndCostMappingSft[]): Observable<any[]> {
    return this.http.post<any[]>(`${this.baseUrlScenario}/DasSimpleCosts`, costMappingsSft, httpOptions).pipe(
      catchError(error => {
        this.handleError('getCostForDasSimple');
        return throwError(error);
      })
    );
  }

  private getFaciltityItemsFromIds(ids: string[]): Observable<any[]> {
    return this.http.post<any[]>(`${this.baseUrlFacilityItem}/ArrayByIds`, ids, httpOptions).pipe(
      catchError(error => {
        this.handleError('getFaciltityItemsFromIds');
        return throwError(error);
      })
    );
  }

  private getDevAreaCodes(): Observable<any> {
    return this.http.get<any[]>(this.GetDevAreaCodes).pipe(catchError(this.handleError('getDevAreaCodes')));
  }
}
