import {IFitCcBaseColumn } from './fit-cc-ibase-column';

export const FitCcBaseColumn: IFitCcBaseColumn[]  = [
  {
    field : 'id',
    title: 'ID',
    type: 'text',
  },
  {
    field: 'name',
    title: 'Name',
    type: 'text',
  }
];
