<div class="card-body">
  <kendo-grid
    [data]="view | async"
    [filter]="filter"
    (filterChange)="handlerFilter($event)"
    filterable="menu"
    (dataStateChange)="onDataStateChange($event)"
    [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    style="height: auto; overflow-x: hidden; margin-bottom: 2vw;"
    [loading]="isLoading"
  >
    <kendo-grid-column field="name" title="DAS Project Name"></kendo-grid-column>
    <kendo-grid-column field="developmentAreasCode" title="Development Area"> </kendo-grid-column>
    <kendo-grid-column
      field="createdDate"
      title="Created Date"
      filter="date"
      format="{0:MM/dd/yyyy}"
    ></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By"></kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Last Updated Date" filter="date" format="{0:MM/dd/yyyy}">
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Last Updated By"></kendo-grid-column>
    <kendo-grid-command-column title="" width="220">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/Scenario/DasSimple/id',dataItem.id]" target="_blank" class="k-button k-button-md k-rounded-md k-button-solid-primary k-button-solid" style="color: #fff;"> Open</a>
        <button kendoGridRemoveCommand [primary]="true" (click)="openDelete(dataItem)" [hidden]="!isAdmin()" style="background:red">
          Delete
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<div class="example-wrapper">
  <kendo-dialog
    title="Confirmation Deletion"
    *ngIf="openedDelete"
    (close)="closeDelete('cancel')"
    [minWidth]="250"
    [width]="450"
  >
    <p style="margin: 30px; text-align: center;">
      Are you sure you want to delete "{{ dasSimpleSelected.name }}" project?
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="removeHandler()">Yes</button>
      <button kendoButton (click)="closeDelete('cancel')" >No</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
