import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PermissionsProvider } from '../services/permissions.provider';
import { FacilityProviderService } from '../modules/standard-facility/_services/facility-provider.service';
import { IFacilityBod } from '../_shared/_models/facility.bod.model';

@Directive({
  selector: '[appSftEnterKey]',
})
export class SftEnterKeyDirective implements OnInit, OnDestroy {
  @Input('appSftEnterKey')
  public currentFormGroup: (args: any) => FormGroup;
  private unsubKeydown: () => void;
  private hasPermissionToEdit = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public facilityProviderService: FacilityProviderService,
    private permissionsProvider: PermissionsProvider
  ) {}

  public ngOnInit(): void {
    this.unsubKeydown = this.renderer.listen(this.el.nativeElement, 'keydown', e => this.onKeydown(e));

    this.facilityProviderService.facilityBod$.subscribe((facilityBod: IFacilityBod) => {
      if (facilityBod) {
        this.facilityProviderService.isReadOnlyFacility$.subscribe((isReadOnlyFacility: boolean) => {
          if (isReadOnlyFacility) {
            this.hasPermissionToEdit = false;
          } else {
            if (this.permissionsProvider.isAdmin) {
              this.hasPermissionToEdit = true;
            } else {
              this.hasPermissionToEdit = this.permissionsProvider.canEditSft;
            }
          }
        });
      } else {
        if (this.permissionsProvider.canEditSft) {
          this.hasPermissionToEdit = true;
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubKeydown();
  }

  public onKeydown(e: KeyboardEvent): void {
    if (e.key !== 'Enter') {
      // Handle just tabs
      return;
    }

    if (!this.hasPermissionToEdit) {
      // Prevent the focus from moving to the next element
      e.preventDefault();
    }
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDownHandler(e: KeyboardEvent): void {
    if (e.key !== 'Enter') {
      // Handle just tabs
      return;
    }

    if (!this.hasPermissionToEdit) {
      // Prevent the focus from moving to the next element
      e.preventDefault();
    }
  }
}
