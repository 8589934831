import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  // Default name for default Wbs facility
  FACILITY_WBS_DEFAULT_NAME = 'Budget';
  FACILITY_WBS_DEFAULT_NUMBER = '-';

  ATTACHMENT_FOLDER_NOTES = 'Notes';
  TEMP_CSS_HIDE_CLOSE_DIALOG_ID = 'tempKendo1122019';
  APP_CONFIG_DISCIPLINE_TYPE_CIVIL_ID = 'DISCIPLINE_TYPE_CIVIL_ID';
  APP_CONFIG_DISCIPLINE_TYPE_IE_ID = 'DISCIPLINE_TYPE_IE_ID';
  APP_CONFIG_DISCIPLINE_TYPE_MECHANICAL_ID = 'DISCIPLINE_TYPE_MECHANICAL_ID';
  APP_CONFIG_FACILITY_ITEM_TYPE_EQUIPMENT_ID = 'FACILITY_ITEM_TYPE_EQUIPMENT_ID';
  APP_CONFIG_FACILITY_ITEM_TYPE_LABOR_ID = 'FACILITY_ITEM_TYPE_LABOR_ID';
  APP_CONFIG_FACILITY_ITEM_TYPE_LABOR_AND_CONTRACTOR_ID = 'FACILITY_ITEM_TYPE_LABOR_AND_CONTRACTOR_ID';
  APP_CONFIG_STATUS_INDICATOR_AWAITING_ID = 'STATUS_INDICATOR_AWAITING_ID';
  APP_CONFIG_STATUS_INDICATOR_APPROVED_ID = 'STATUS_INDICATOR_APPROVED_ID';
  APP_CONFIG_STATUS_INDICATOR_REOPEN_ID = 'STATUS_INDICATOR_REOPEN_ID';
  APP_CONFIG_STATUS_INDICATOR_CLOSED_ID = 'STATUS_INDICATOR_CLOSED_ID';
  APP_CONFIG_FACILITY_TYPE_CTB_ID = 'FACILITY_TYPE_CTB_ID';
  APP_CONFIG_FACILITY_TYPE_CS_ID = 'FACILITY_TYPE_CS_ID';
  APP_CONFIG_FACILITY_TYPE_SWD_ID = 'FACILITY_TYPE_SWD_ID';
  APP_CONFIG_FACILITY_TYPE_SAT_ID = 'FACILITY_TYPE_SAT_ID';
  APP_CONFIG_FACILITY_TYPE_NOBLE_ID = 'FACILITY_TYPE_NOBLE_ID';
  APP_CONFIG_OPTIONS_TO_EXCLUDE_CE_LABORS = 'OPTIONS_TO_EXCLUDE_CE_LABORS';
  APP_CONFIG_OPTIONS_TO_EXCLUDE_CE_EQUIPMENTS = 'OPTIONS_TO_EXCLUDE_CE_EQUIPMENTS';
  APP_CONFIG: any[] = [];
}
