// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //PricetDomainURL: 'https://dev-pricet-mcbu.chevron.com/pricetdomain/api/',
  PricetDomainURL: 'https://localhost:44327/api/',
  //SecurityDomainURL: 'https://dev-pricet-mcbu.chevron.com/security/api/',
  SecurityDomainURL: 'https://localhost:44326/api/',
  //PacerDomainURL: 'https://dev-pricet-mcbu.chevron.com/pacerdomain/api/',
  PacerDomainURL: 'https://localhost:44328/api/',
  EnvironmentTag: 'DEV',
  PricetWebURL: 'http://localhost:4201',
  SmartDomainURL: 'https://smart-so-mcbu-api-dev.azure.chevron.com/api/v2/',
  PbsorDomainURL: 'https://pbsor-mcbu-api-dev.azure.chevron.com/api/',
  SmartAppURL: 'https://smart-mcbu-dev.azure.chevron.com/',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
