import { Component, Input, HostListener } from '@angular/core';
@Component({
  selector: 'fit-cc-footer',
  templateUrl: './fit-cc-footer.component.html',
  styleUrls: ['./fit-cc-footer.component.scss'],
})
export class FitCcFooterComponent {
  @Input() appVersion: string = '0.0.0';
  @Input() dbVersion: string = '0.0.0';
  @Input() appEnvironment: string = 'dev';
  @Input() contactUrl: string = 'https://mcbu.chevron.com/upo/';
  @Input() contactCaption: string = 'MCBU UP&O';
  @Input() techUrl: string = 'https://go.chevron.com/fitsupport';
  @Input() techCaption: string = 'IT Help Desk';
  @Input() feedbackUrl: string = '';
  @Input() feedbackCaption: string = '[App]'
  
  public isFullScreen:boolean = false;
  public isFixed: boolean = true;

  @HostListener('document:fullscreenchange', [] ) 
  public closing() {
    this.isFullScreen = !this.isFullScreen;
  }

  public currentYear: number = new Date().getFullYear();
  
}
