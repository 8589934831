export enum InlineActions {
  Create,
  Retrieve,
  Update,
  Delete,
}

export enum SupplementalEquipmentTypeEnum {
  Expansion = 1,
  Addition = 2,
}

export enum DialogGridActions {
  EditedBeforeCancel,
  NewAtParent,
  Saved,
  NotEdited,
}
