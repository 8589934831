<div class="panelbar-wrapper">
  <br />
  <br />
  <kendo-panelbar>
    <kendo-panelbar-item title="Standard Facilities - Summary" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-standard-facilities-summary (performanceBodUpdate)="onPerformanceBodUpdate($event)"></app-standard-facilities-summary>
      </ng-template>
    </kendo-panelbar-item>
    <kendo-panelbar-item title="Standard Facilities - Central Tank Battery" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-standard-facilities-central-tank-battery (performanceBodUpdate)="onPerformanceBodUpdate($event)"></app-standard-facilities-central-tank-battery>
      </ng-template>
    </kendo-panelbar-item>
    <kendo-panelbar-item title="Standard Facilities - Compressor and Dehydration" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-standard-facilities-compressor (performanceBodUpdate)="onPerformanceBodUpdate($event)"></app-standard-facilities-compressor>
      </ng-template>
    </kendo-panelbar-item>
    <kendo-panelbar-item title="Standard Facilities - Salt Water Disposal" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-standard-facilities-salt-water-disposal (performanceBodUpdate)="onPerformanceBodUpdate($event)"></app-standard-facilities-salt-water-disposal>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</div>
