<kendo-grid
  #waterDisposal
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  class="infrastructure-water-disposal-draggable"
  [rowClass]="rowCallback"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!performanceBodWaterDisposalService.hasChanges()"
      (click)="cancelChanges(waterDisposal)"
      [primary]="true"
    >
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodId" title="PerdoformanceBodId" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPackagesList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="performanceBodPackagesList"
        [defaultItem]="{ id: null, name: 'Select item...' }"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('performanceBodPackagesId')"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="Water Disposal Description" [editable]="true"> </kendo-grid-column>
  <kendo-grid-column
    field="readyForServiceDate"
    title="Water Disposal RFSD"
    [filterable]="filterable"
    filter="date"
    [width]="150"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.readyForServiceDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('readyForServiceDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="designDisposalRatesBwpd"
    title="Design Disposal Rates (BWPD)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="maxPermitPressurePsig"
    title="Max Permit Pressure (PSIG)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="anticipatedOperatingPressurePsig"
    title="Anticipated Operating Pressure (PSIG)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="disposalPumpingCapacityBwpd"
    title="Disposal Capacity /Pumping Capacity (BWPD)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
