import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IScopeNonStandardOutsideFactoryProjects } from 'src/app/api/scope-non-standard-outside-factory-projects';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';

@Injectable({
  providedIn: 'root',
})
export class ScopeNonStandardOutsideFactoryProjectsService extends InlineEditService implements IEditableInline {
  public data: IScopeNonStandardOutsideFactoryProjects[] = [];
  public originalData: IScopeNonStandardOutsideFactoryProjects[] = [];
  public createdItems: IScopeNonStandardOutsideFactoryProjects[] = [];
  public updatedItems: IScopeNonStandardOutsideFactoryProjects[] = [];
  public deletedItems: IScopeNonStandardOutsideFactoryProjects[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IScopeNonStandardOutsideFactoryProjects[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createScopeNonStandardOutsideFactoryProjects(this.createdItems);
  }

  public retrieve(): Observable<IScopeNonStandardOutsideFactoryProjects[]> {
    return this.performanceBodStoreService.scopeNonStandardOutsideFactoryProjects$; // the subscribe is applied inside InlineEditService
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updateScopeNonStandardOutsideFactoryProjects(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updateScopeNonStandardOutsideFactoryProjects(this.deletedItems);
  }

  public cancelChanges(): void {
    super.cancelChanges();
    this.performanceBodStoreService.retrieveScopeNonStandardOutsideFactoryProjects(this.performanceBodId);
  }
}
