import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PerformanceBODComponent } from './performance-bod.component';
import { PerfomanceBodCreateNewComponent } from './perfomance-bod-create-new/perfomance-bod-create-new.component';
import { PerformanceBodToolComponent } from './performance-bod-tool/performance-bod-tool.component';
import { AuthGuard } from 'src/app/_guards/auth.guard';

export const performanceBodRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'MyBODs',
        component: PerformanceBODComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'My BODs',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'AllBODs',
        component: PerformanceBODComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'All BODs',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'CreateNew',
        component: PerfomanceBodCreateNewComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Create New',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: ':id',
        data: {
          text: 'Performance BOD',
        },
        component: PerformanceBodToolComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(performanceBodRoutes)],
  exports: [RouterModule],
})
export class PerformanceBodRoutingModule {}
