import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Injectable, InjectionToken, DebugElement } from '@angular/core';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { TabStripModule, SplitterModule, PanelBarModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { FitCommonComponentsLibComponent } from './fit-common-components-lib.component';
import { FitCcFooterComponent } from './fit-cc-footer/fit-cc-footer.component';

import { FitCcUnauthorizedComponent } from './fit-cc-unauthorized/fit-cc-unauthorized.component';
import { FitCcCellIndicatorModule } from './fit-cc-cell-indicator/fit-cc-cell-indicator.module';
import { FitCcGridModule } from './fit-cc-grid/fit-cc-grid.module';
import { FitCcDirectivesModule } from './directives/directives.module';
import { FitCcExportToExcelModule } from './fit-cc-export-to-excel/fit-cc-export-to-excel.module';
import { FitCcPopupFormModule } from './fit-cc-popup-form/fit-cc-popup-form.module';
import { FitCcFormControlModule } from './fit-cc-form-control/fit-cc-form-control.module';
import { FitCcColumnFilterModule } from './fit-cc-column-filter/fit-cc-column-filter.module';
import { FitCCInputModule } from './fit-cc-input/fit-cc-input.module';
import { FitCcDialogModule } from './fit-cc-dialog/fit-cc-dialog.module';
import { FitCCTemplateModule } from './fit-cc-template/fit-cc-template.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GridModule,
    ExcelModule,
    TabStripModule,
    SplitterModule,
    PanelBarModule,
    DropDownsModule,
    InputsModule,
    TooltipModule,
    DateInputsModule,
    PopupModule,
    UploadModule,
    DialogsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NotificationModule,
    TreeViewModule,
    FitCCTemplateModule,
    FitCcGridModule,
    FitCcDirectivesModule,
    FitCcCellIndicatorModule,
    FitCcExportToExcelModule,
    FitCcPopupFormModule,
    FitCcFormControlModule,
    FitCcColumnFilterModule,
    FitCCInputModule,
    FitCcDialogModule
  ],
  declarations: [
    FitCommonComponentsLibComponent
  ],
  exports: [
    FitCommonComponentsLibComponent,
    FitCCTemplateModule,
    FitCcGridModule,
    FitCcCellIndicatorModule,
    FitCcExportToExcelModule,
    FitCcPopupFormModule,
    FitCcColumnFilterModule,
    FitCCInputModule,
    FitCcDialogModule
  ],
  providers: [DatePipe],
})
export class FitCommonComponentsLibModule {}
