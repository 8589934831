import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FitCcDialogService } from './fit-cc-dialog.service';

@Component({
  selector: 'fit-cc-dialog',
  templateUrl: './fit-cc-dialog.component.html',
  styleUrls: ['./fit-cc-dialog.component.scss'],
})
export class FitCcDialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContainer', { read: ViewContainerRef, static: true }) private dialogContainerRef: ViewContainerRef;

  constructor(private fitCcDialogService: FitCcDialogService) {}

  ngOnInit() {
    this.fitCcDialogService.loadContainerRef(this.dialogContainerRef);
  }

  ngOnDestroy() {
    this.fitCcDialogService.removeContainerRef();
  }
}
