import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, CompositeFilterDescriptor, filterBy, process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { AuthenticationService } from 'src/app/services';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { UtilService } from 'src/app/services/util.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';
import { Guid } from 'guid-typescript';
import { WaterScopeWaterDisposalService } from './water-scope-water-disposal.service';
import { IPerformanceBodWSWaterDisposal } from 'src/app/api/performance-bod-ws-water-disposal';

const DRAGGABLE_SELECTOR = '.performane-bod-water-disposable-draggable';
@Component({
  selector: 'app-water-scope-water-disposal',
  templateUrl: './water-scope-water-disposal.component.html',
  
})
export class WaterScopeWaterDisposalComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodId: string;
  public formGroup: FormGroup;
  public yesNoList: any[] = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ];
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public performanceBodWSWaterDisposalService: WaterScopeWaterDisposalService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  @ViewChild('waterDisposal')
  private performanceBodWSWaterDisposalGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(WaterScopeWaterDisposalService) performanceBodWSWaterDisposalServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.performanceBodWSWaterDisposalService = performanceBodWSWaterDisposalServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.performanceBodWSWaterDisposalService.performanceBodId = id;
      this.performanceBodWSWaterDisposalService.read();
    });
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.view = this.performanceBodWSWaterDisposalService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.dragAndDropService.initialize(this.performanceBodWSWaterDisposalService.data, DRAGGABLE_SELECTOR, () => {
      this.performanceBodWSWaterDisposalService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(dataItem.id),
      isDeleted: new FormControl(dataItem.isDeleted, [Validators.required]),
      performanceBodId: new FormControl(dataItem.performanceBodId),
      performanceBodPackagesId: new FormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      source: new FormControl(dataItem.source, [Validators.minLength(0), Validators.maxLength(256)]),
      contractExecuted: new FormControl(dataItem.contractExecuted, [
        Validators.minLength(0),
        Validators.maxLength(256),
      ]),
      contractStartDate: new FormControl(dataItem.contractStartDate),
      contractEndDate: new FormControl(dataItem.contractEndDate),
      deliveryRoute: new FormControl(dataItem.deliveryRoute, [Validators.minLength(0), Validators.maxLength(256)]),
      pipelineType: new FormControl(dataItem.pipelineType, Validators.maxLength(256)),
      minMaxDeliveryRateBwpd: new FormControl(dataItem.minMaxDeliveryRateBwpd, Validators.max(99999999999999)),
      rowSecured: new FormControl(dataItem.rowSecured, [Validators.minLength(0), Validators.maxLength(256)]),
      comments: new FormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(1026)]),
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 3) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.performanceBodWSWaterDisposalService.assignValues(dataItem, formGroup.value);
      this.performanceBodWSWaterDisposalService.updateItem(dataItem);
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IPerformanceBodWSWaterDisposal = {
      id: Guid.create().toString(),
      contractExecuted: false,
      rowSecured: false,
      performanceBodPackagesId: '',
      performanceBodId: this.performanceBodId,
      pipelineType: null,
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: null,
    };

    item.createdBy = this.authenticationService.getCurrentUser();
    item.createdDate = new Date();
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      formGroup.patchValue({
        performanceBodId: this.performanceBodId,
      });
      this.performanceBodWSWaterDisposalService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.performanceBodWSWaterDisposalService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodWSWaterDisposalService.markAsSetOrder(this.permissionsProvider.cai);
    } else {
      this.utilService.markFormGroupAsTouched(formGroup);
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.performanceBodWSWaterDisposalService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWSWaterDisposalService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        animation: { type: 'fade', duration: 500 },
        content: 'Water source - Water Disposal Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.performanceBodWSWaterDisposalGrid.closeCell();
    this.performanceBodWSWaterDisposalGrid.cancelCell();

    if (this.performanceBodWSWaterDisposalService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodWSWaterDisposalService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.performanceBodWSWaterDisposalService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWSWaterDisposalService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.performanceBodWSWaterDisposalService.read();


  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    this.performanceBodPackagesList = this.performanceBodPackagesList.filter(x => x.id !== null && x.id !== undefined);
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  public getYesNo(option: boolean): any {
    return this.yesNoList.find(x => x.value === option);
  }
}
