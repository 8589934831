import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { UserPermissionsService } from '../../../services/user-permission.service';
import { DasSimpleScopeCountEditService } from './das-simple-scope-count-edit.service';
import { map } from 'rxjs/operators/map';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services';
import { ISequence } from '../../../api/scenarioSequence';
import { ISmartImportSequence } from '../../../api/smart-import-sequence';
import * as Helpers from '../../../_shared/helpers';
import { DasSimpleCalculationsService } from './../../../services/das-simple-calculations.service';
import { Guid } from 'guid-typescript';
import { ScopeAssumptionsService } from '../../../services/scope-assumptions.service';
import { IScopeAssumptions } from '../../../api/IScopeAssumptions';
import { DasSimpleService } from '../../../services/das-simple.service';
import { IDasSimple } from '../../../api/das-simple';

@Component({
  selector: 'app-das-simple-scope-count',
  templateUrl: './das-simple-scope-count.component.html',
  styleUrls: ['./das-simple-scope-count.component.css'],
})
export class DasSimpleScopeCountComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public view: Observable<GridDataResult>;
  // @Output() public infrastructureBodUpdate = new EventEmitter<string>();
  @ViewChild('smartImportSequenceGrid')
  public smartImportSequenceGrid: GridComponent;

  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public userPermissionsService: UserPermissionsService;
  public dasSimpleScopeCountEditService: DasSimpleScopeCountEditService;
  public canEditCnd = false;
  public isLoading = true;
  public dasSimpleData: IDasSimple = {
    id: null,
    name: null,
    smartSeqName: null,
    smartSeqLink: null,
    comments: null,
    isDeleted: false,
    sortOrder: 0,
    developmentAreasCode: null,
    stateCode: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    dasLastUpdatedDate: null,
  };
  // Here calculation variables
  public train1Ctb = 0;
  public train1CtbAddition = 0;
  public satellite = 0;
  public train2Swd = 0;
  public train2SwdAddition = 0;
  public fracPondVolume = 0;
  public fru = 0;
  public lpGas = 0;
  public hpGas = 0;
  public water = 0;
  public liquid = 0;
  public gasLift = 0;
  public flowlines = 0;
  public cs = 0;
  public rental = 0;
  public coop = 0;
  public electricCoopStation = 0;
  public electricCoopTrain = 0;
  public generalInfrastructure = 0;
  public tower = 0;
  public bgwh = 0;
  public padBase = 0;
  public wellBase = 0;

  public currentScopeAssumptions: IScopeAssumptions;
  public isNewDasSimple = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    @Inject(DasSimpleScopeCountEditService) dasSimpleScopeCountEditServiceFactory: any,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private permissionsProvider: PermissionsProvider,
    private dasSimpleCalculationsService: DasSimpleCalculationsService,
    private dasSimpleService: DasSimpleService,
    private scopeAssumptionsService: ScopeAssumptionsService
  ) {
    this.userPermissionsService = userPermissionsServiceFactory();
    this.dasSimpleScopeCountEditService = dasSimpleScopeCountEditServiceFactory();
  }

  public ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.checkIsNew();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.dasSimpleScopeCountEditService.initialize(params.get('id'));
      this.dasSimpleScopeCountEditService.read();
    });

    this.dasSimpleScopeCountEditService.loader.subscribe(
      (loaded: boolean) => {
        if (!loaded) {
          this.isLoading = loaded;
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );

    this.view = this.dasSimpleScopeCountEditService.pipe(
      map(data => {
        if (this.isNewDasSimple) {
          this.reCalculateCosts();
        }
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    // Emmit data is done when complete to load calculations
    this.dasSimpleCalculationsService.dasSimpleCosts$.subscribe(
      (costs: any[]) => {
        if (costs && costs.length > 0) {
          // this works to emmit
          this.scopeAssumptionsService.fire();
        }
      },
      (error: any) => {
        this.notificationService.show({
          content: error.message,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 400 },
          type: { style: 'error', icon: true },
          hideAfter: 1400,
        });
      }
    );

    this.dasSimpleService.dasSimple$.subscribe((dasSimple: IDasSimple) => {
      this.dasSimpleData = dasSimple ? dasSimple : this.dasSimpleData;
      // Emmit scope assumption
      this.scopeAssumptionsService.fire();
    });

    this.scopeAssumptionsService.scopeAssumptions$.subscribe((scopeAssumptions: IScopeAssumptions[]) => {
      if (scopeAssumptions && scopeAssumptions.length > 0) {
        this.currentScopeAssumptions = scopeAssumptions[0];
        if (this.currentScopeAssumptions) {
          this.reCalculateCosts();
        }
      }
    });
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      dasSimpleId: new FormControl(dataItem.dasSimpleId),
      padName: new FormControl(dataItem.padName),
      developmentArea: new FormControl(dataItem.developmentArea),
      section: new FormControl(dataItem.section),
      facility: new FormControl(dataItem.facility),
      numberOfWells: new FormControl(dataItem.numberOfWells),
      popDate: new FormControl(dataItem.popDate),
      padCost: new FormControl(dataItem.padCost),
      train1Ctb: new FormControl(dataItem.train1Ctb),
      train1CtbAddition: new FormControl(dataItem.train1CtbAddition),
      satellite: new FormControl(dataItem.satellite),
      compressorStation: new FormControl(dataItem.compressorStation),
      rentals: new FormControl(dataItem.rentals),
      coop: new FormControl(dataItem.coop),
      electricCoopStation: new FormControl(dataItem.electricCoopStation),
      electricCoopTrain: new FormControl(dataItem.electricCoopTrain),
      train2Swd: new FormControl(dataItem.train2Swd),
      train2SwdAddition: new FormControl(dataItem.train2SwdAddition),
      fracPondVolume: new FormControl(dataItem.fracPondVolume),
      fru: new FormControl(dataItem.fru),
      lpGas: new FormControl(dataItem.lpGas),
      hpGas: new FormControl(dataItem.hpGas),
      water: new FormControl(dataItem.water),
      liquid: new FormControl(dataItem.liquid),
      gasLift: new FormControl(dataItem.gasLift),
      flowlines: new FormControl(dataItem.flowlines),
      generalInfrastructure: new FormControl(dataItem.generalInfrastructure),
      tower: new FormControl(dataItem.tower),
      bgwh: new FormControl(dataItem.bgwh),
      misc: new FormControl(dataItem.misc),
      padBase: new FormControl(dataItem.padBase),
      wellBase: new FormControl(dataItem.wellBase),
      comments: new FormControl(dataItem.comments, [Validators.maxLength(100)]),
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditCnd) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid) {
        if (column.field === 'padCost') {
          dataItem.padCostOverriden = formGroup.value.padCost;
        }

        if (column.field === 'gasLift') {
          dataItem.gasLiftOverriden =
            this.currentScopeAssumptions.gasLiftLength === formGroup.value.gasLift ? null : formGroup.value.gasLift;
        }

        if (column.field === 'flowlines') {
          dataItem.flowlinesOverriden =
            this.currentScopeAssumptions.flowlineLength === formGroup.value.flowlines
              ? null
              : formGroup.value.flowlines;
        }

        if (column.field === 'generalInfrastructure') {
          dataItem.generalInfrastructureOverriden =
            this.currentScopeAssumptions.generalInfLength === formGroup.value.generalInfrastructure
              ? null
              : formGroup.value.generalInfrastructure;
        }

        dataItem.updatedDate = new Date();
        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = this.authenticationService.getCurrentUser();
        formGroup.value.updatedDate = dataItem.updatedDate;

        this.dasSimpleScopeCountEditService.assignValues(dataItem, formGroup.value);
        this.calculatePadCost(dataItem);
        this.dasSimpleScopeCountEditService.updateItem(dataItem);
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.dasSimpleScopeCountEditService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dasSimpleScopeCountEditService.saveChanges();
    // TODO
    // this.infrastructureBodUpdate.next(this.authenticationService.getCurrentUser());
  }

  public saveFromDasSimpleSummary(): void {
    this.smartImportSequenceGrid.closeCell();
    this.smartImportSequenceGrid.cancelCell();

    if (this.dasSimpleScopeCountEditService.hasChanges()) {
      this.spinner.show();
      this.dasSimpleScopeCountEditService.saveChanges();
      // this.infrastructureBodUpdate.next(this.authenticationService.getCurrentUser());
    }
  }

  public cancelChanges(grid: any): void {
    this.smartImportSequenceGrid.cancelCell();
    this.dasSimpleScopeCountEditService.cancelChanges();
    this.isLoading = true;

    this.dasSimpleScopeCountEditService.loader.subscribe(
      (loaded: boolean) => {
        if (!loaded) {
          this.isLoading = loaded;
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.dasSimpleScopeCountEditService.read();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  public rowCallback(context: RowClassArgs) {
    // empty
  }

  public addSmartImportSequences(sequences: ISequence[]) {
    const dataFromDataSequence = [];
    sequences.map((item: ISequence, index: number) => {
      const smartImportSequence: ISmartImportSequence = {
        isDeleted: false,
        sortOrder: 0,
        padName: item.padName,
        developmentArea: item.devAreaCode,
        section: item.section,
        facility: item.facilityCTB,
        numberOfWells: item.wells ? item.wells.length : 0,
        popDate: Helpers.convertUtcToLocalTime(item.popDate),
        padCost: 0,
        train1Ctb: 0,
        train1CtbAddition: 0,
        satellite: 0,
        compressorStation: 0,
        rentals: 0,
        coop: 0,
        electricCoopStation: 0,
        electricCoopTrain: 0,
        train2Swd: 0,
        train2SwdAddition: 0,
        fracPondVolume: 0,
        fru: 0,
        lpGas: 0,
        hpGas: 0,
        water: 0,
        liquid: 0,
        gasLift: 0,
        flowlines: 0,
        generalInfrastructure: 0,
        tower: 0,
        bgwh: 0,
        misc: 0,
        padBase: 0,
        wellBase: 0,
        padCostOverriden: null,
        train1CtbOverriden: null,
        train1CtbAdditionOverriden: null,
        satelliteOverriden: null,
        compressorStationOverriden: null,
        rentalsOverriden: null,
        coopOverriden: null,
        electricCoopStationOverriden: null,
        electricCoopTrainOverriden: null,
        train2SwdOverriden: null,
        train2SwdAdditionOverriden: null,
        fracPondVolumeOverriden: null,
        fruOverriden: null,
        lpGasOverriden: null,
        hpGasOverriden: null,
        waterOverriden: null,
        liquidOverriden: null,
        gasLiftOverriden: null,
        flowlinesOverriden: null,
        generalInfrastructureOverriden: null,
        towerOverriden: null,
        bgwhOverriden: null,
        miscOverriden: null,
        padBaseOverriden: null,
        wellBaseOverriden: null,
        comments: null,
        dasSimpleId: this.dasSimpleScopeCountEditService.dasSimpleId,
        dasSimple: null,
        id: Guid.create().toString(),
        createdBy: this.authenticationService.getCurrentUser().toUpperCase(),
        createdDate: new Date(),
        updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
        updatedDate: new Date(),
      };

      this.changeDefaultCounts(smartImportSequence);
      dataFromDataSequence.push(smartImportSequence);

      return item;
    });

    this.dasSimpleScopeCountEditService.dataFromDataSequence = dataFromDataSequence;
    this.dasSimpleScopeCountEditService.updatedItems = dataFromDataSequence;
    this.dasSimpleScopeCountEditService.read();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      this.dasSimpleScopeCountEditService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
    }
  }

  public istOverriddenField(dataItem: any, field: string): boolean {
    if (dataItem !== null && dataItem !== undefined) {
      return dataItem[field + 'Overriden'] !== undefined && dataItem[field + 'Overriden'] != null ? true : false;
    }
    return false;
  }

  public getTotalPadCost(dataItem: ISmartImportSequence): number {
    if (dataItem.padCostOverriden) {
      return dataItem.padCostOverriden;
    }

    return dataItem.padCost;
  }

  public calculatePadCost(dataItem: any): void {
    if (!dataItem.padCostOverriden) {
      // console.log('*************************************');
      // console.log('Count = ', dataItem.train1Ctb);
      // console.log('1 Train CTB costs: ');
      this.train1Ctb = this.dasSimpleCalculationsService.calculate1TrainCtb(dataItem.train1Ctb);
      // console.log('Total = ' + this.train1Ctb);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('1 Train CTB Addition costs: ');
      // console.log('Count = ', dataItem.train1CtbAddition);
      this.train1CtbAddition = this.dasSimpleCalculationsService.calculate1TrainAdditionCtb(dataItem.train1CtbAddition);
      // console.log('Total = ' + this.train1CtbAddition);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Satellite costs: ');
      // console.log('Count = ', dataItem.satellite);
      this.satellite = this.dasSimpleCalculationsService.calculateSatellite(dataItem.satellite);
      // console.log('Total = ' + this.satellite);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Train 2 SWD costs: ');
      // console.log('Count = ', dataItem.train2Swd);
      this.train2Swd = this.dasSimpleCalculationsService.calculate2TrainSwd(
        this.currentScopeAssumptions.swdInjLineId,
        this.currentScopeAssumptions.liquidsTransferLineId,
        dataItem.train2Swd
      );
      // console.log('Total = ' + this.train2Swd);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Train 2 SWD Addition costs: ');
      // console.log('Count = ', dataItem.train2SwdAddition);
      this.train2SwdAddition = this.dasSimpleCalculationsService.calculate2TrainAdditionSwd(dataItem.train2SwdAddition);
      // console.log('Total = ' + this.train2SwdAddition);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Frac Ponds costs: ');
      // console.log('Count = ', dataItem.fracPondVolume);
      this.fracPondVolume = this.dasSimpleCalculationsService.calculateFracPondVolume(
        this.currentScopeAssumptions.fracPondTypeId,
        dataItem.fracPondVolume,
        dataItem.developmentArea
      );
      // console.log('Total = ' + this.fracPondVolume);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('FRU costs: ');
      // console.log('Count = ', dataItem.fru);
      this.fru = this.dasSimpleCalculationsService.calculateFru(dataItem.fru);
      // console.log('Total = ' + this.fru);
      // console.log('*************************************');

      // Infrastructure
      // console.log('*************************************');
      // console.log('Count = ', dataItem.lpGas);
      // console.log('LP Gas: ');
      this.lpGas = this.dasSimpleCalculationsService.calculateLPGas(
        this.currentScopeAssumptions.lpGasLineId,
        dataItem.lpGas
      );
      // console.log('LP Gas: ' + this.lpGas);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.hpGas);
      // console.log('HP Gas: ');
      this.hpGas = this.dasSimpleCalculationsService.calculateHPGas(
        this.currentScopeAssumptions.hpGasLineId,
        dataItem.hpGas
      );
      // console.log('HP Gas: ' + this.hpGas);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.water);
      // console.log('Water: ');
      this.water = this.dasSimpleCalculationsService.calculateWater(
        this.currentScopeAssumptions.prodWaterLineId,
        dataItem.water
      );
      // console.log('Water: ' + this.water);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.liquid);
      // console.log('Liquid: ');
      this.liquid = this.dasSimpleCalculationsService.calculateLiquid(
        this.currentScopeAssumptions.liquidsTransferLineId,
        dataItem.liquid
      );
      // console.log('Liquid: ' + this.liquid);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.gasLift);
      // console.log('Gas Lift: ');
      this.gasLift = this.dasSimpleCalculationsService.calculateGasLift(
        this.currentScopeAssumptions.gasLiftLineId,
        dataItem.gasLift
      );
      // console.log('Gas Lift: ' + this.gasLift);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.liquid);
      // console.log('Flowlines: ');
      this.flowlines = this.dasSimpleCalculationsService.calculateFlowlines(dataItem.numberOfWells, dataItem.flowlines);
      // console.log('Flowlines: ' + this.flowlines);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('Count = ', dataItem.liquid);
      // console.log('General Infrastructure: ');
      this.generalInfrastructure = this.dasSimpleCalculationsService.calculateGeneralInfrastructure(
        dataItem.generalInfrastructure
      );
      // console.log('General Infrastructure: ' + this.generalInfrastructure);
      // console.log('*************************************');

      //Gas
      // console.log('*************************************');
      // console.log('Count = ', dataItem.compressorStation);
      // console.log('Compressor Station: ');
      this.cs = this.dasSimpleCalculationsService.calculateCompressorStation(dataItem.compressorStation);
      // console.log('compressor station: ' + this.cs);
      // console.log('*************************************');

      // console.log('*************************************');
      this.rental = this.dasSimpleCalculationsService.calculateRentals(
        this.currentScopeAssumptions.rentalId,
        dataItem.rentals
      );
      // console.log('rental: ' + this.rental);
      // console.log('*************************************');

      this.coop = this.dasSimpleCalculationsService.calculateCOOP(this.currentScopeAssumptions.coopId, dataItem.coop);
      // console.log('coop: ' + this.coop);
      // console.log('*************************************');

      // console.log('*************************************');
      this.electricCoopStation = this.dasSimpleCalculationsService.calculateElectricCOOPStation(
        dataItem.electricCoopStation
      );
      // console.log('Electric coop station: ' + this.electricCoopStation);
      this.electricCoopTrain = this.dasSimpleCalculationsService.calculateElectricCOOPTrain(dataItem.electricCoopTrain);
      // console.log('Electric coop train: ' + this.electricCoopTrain);
      // console.log('*************************************');

      // Other
      // console.log('*************************************');
      // console.log('Tower costs: ');
      // console.log('Count: ', dataItem.tower);
      this.tower = this.dasSimpleCalculationsService.calculateTower(dataItem.tower);
      // console.log('Total = ' + this.fru);
      // console.log('*************************************');

      // console.log('*************************************');
      // console.log('BGWH costs: ');
      // console.log('Count: ', dataItem.bgwh);
      this.bgwh = this.dasSimpleCalculationsService.calculateBGWH(dataItem.numberOfWells, dataItem.bgwh);
      // console.log('Total = ' + this.fru);
      // console.log('*************************************');

      dataItem.padCost =
        this.train1Ctb +
        this.train1CtbAddition +
        this.satellite +
        this.train2Swd +
        this.train2SwdAddition +
        this.fracPondVolume +
        this.fru +
        this.cs +
        this.rental +
        this.coop +
        this.electricCoopStation +
        this.electricCoopTrain +
        this.lpGas +
        this.hpGas +
        this.water +
        this.liquid +
        this.gasLift +
        this.flowlines +
        this.generalInfrastructure +
        this.tower +
        this.bgwh;

      dataItem.padCost = isNaN(dataItem.padCost) ? null : dataItem.padCost;
      // console.log('*************************************');
      // console.log('Pad Base costs: ');
      this.padBase = this.dasSimpleCalculationsService.calculatePadBase(dataItem.bgwh, dataItem.developmentArea);
      // console.log('Total = ' + this.padBase);
      // console.log('*************************************');
      // console.log('*************************************');
      // console.log('Well Base costs: ');
      this.wellBase = this.dasSimpleCalculationsService.calculateWellBase(
        this.currentScopeAssumptions.wellsPerTestSeparator,
        dataItem.numberOfWells
      );
      // console.log('Total = ' + this.wellBase);
      // console.log('*************************************');
      // Total
      // console.log('*************************************');
      // console.log('Final costs: ');

      const planCost = dataItem.padCost + this.padBase + this.wellBase + dataItem.misc;
      const contingency = this.currentScopeAssumptions.contingency * planCost;
      const tax = this.currentScopeAssumptions.tax * (contingency + planCost);

      dataItem.padCost = (planCost + tax + contingency) / 1000;
      // console.log('Pad Base= ' + this.padBase);
      // console.log('Well Base= ' + this.wellBase);
      // console.log('Plan cost= ' + planCost);
      // console.log('Contigency= ' + contingency);
      // console.log('Tax= ' + tax);
      // console.log('TOTAL PAD COST: ' + dataItem.padCost);
      // console.log('*************************************');

      this.dasSimpleScopeCountEditService.updateItem(dataItem);
    }
  }

  public reCalculateCosts(): void {
    for (const smartImportSequence of this.dasSimpleScopeCountEditService.data) {
      this.changeDefaultCounts(smartImportSequence);
      this.calculatePadCost(smartImportSequence);
    }
  }

  public changeDefaultCounts(smartImportSequence: ISmartImportSequence): void {
    // Gas lift
    if (smartImportSequence.gasLift === this.currentScopeAssumptions.gasLiftLength) {
      smartImportSequence.gasLiftOverriden = null;
    } else {
      if (smartImportSequence.gasLiftOverriden === undefined || smartImportSequence.gasLiftOverriden == null) {
        smartImportSequence.gasLift = this.currentScopeAssumptions.gasLiftLength;
        smartImportSequence.gasLiftOverriden = null;
      }
    }

    // Flowlines
    if (smartImportSequence.flowlines === this.currentScopeAssumptions.flowlineLength) {
      smartImportSequence.flowlinesOverriden = null;
    } else {
      if (smartImportSequence.flowlinesOverriden === undefined || smartImportSequence.flowlinesOverriden == null) {
        smartImportSequence.flowlines = this.currentScopeAssumptions.flowlineLength;
        smartImportSequence.flowlinesOverriden = null;
      }
    }

    // General Infrastructure
    if (smartImportSequence.generalInfrastructure === this.currentScopeAssumptions.generalInfLength) {
      smartImportSequence.generalInfrastructureOverriden = null;
    } else {
      if (
        smartImportSequence.generalInfrastructureOverriden === undefined ||
        smartImportSequence.generalInfrastructureOverriden == null
      ) {
        smartImportSequence.generalInfrastructure = this.currentScopeAssumptions.generalInfLength;
        smartImportSequence.generalInfrastructureOverriden = null;
      }
    }
  }

  private checkIsNew(): void {
    const routeName = this.router.url.split('/');
    const bodRoute = routeName[3];
    if (bodRoute === 'New') {
      this.isNewDasSimple = true;
    }
  }
}
