import { Injectable, EventEmitter } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodSignatures } from 'src/app/api/performance-bod-signatures';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodStatus } from 'src/app/api/performance-bod-status';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodDetailService {
  public refreshed: EventEmitter<any>;
  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService
  ) {}

  public getPerformanceBodId(): Observable<any> {
    return this.performanceBodStoreService.performanceBodId$;
  }

  public getPerformanceBodById(): Observable<any> {
    return this.performanceBodStoreService.performanceBod$;
  }

  public getPerformanceBodPackage(): Observable<IPerformanceBodPackages[]> {
    return this.performanceBodStoreService.performanceBodPackagesData$;
  }

  public getPerformanceBodSignatures(): Observable<IPerformanceBodSignatures[]> {
    return this.performanceBodStoreService.performanceBodSignaturesData$;
  }

  public getPerformanceBodStatus(): Observable<IPerformanceBodStatus[]> {
    return this.performanceBodStoreService.performanceBodStatus$;
  }

  public refreshPerformanceBod(performanceBodId: string): void {
    this.performanceBodStoreService.refreshPerformanceBod(performanceBodId);
  }

  public save(
    performanceBod: IPerformanceBod,
    newPackages: IPerformanceBodPackages[],
    updatedPackages: IPerformanceBodPackages[],
    performanceBodStatus: IPerformanceBodStatus[]
  ): void {
    let one: any;
    let two: any;
    let three: any;
    let four: any;

    this.refreshed = new EventEmitter<any>();
    this.refreshed.emit(false);
    one = this.performanceBodStoreService.updateAsync(performanceBod).pipe(take(1));
    two = this.performanceBodStoreService.addPackagesAsync(newPackages).pipe(take(1));
    three = this.performanceBodStoreService.updatePackagesAsync(updatedPackages).pipe(take(1));
    four = performanceBodStatus[0].id ?
    this.performanceBodStoreService.updatePerformanceBodStatusAsync(performanceBodStatus).pipe(take(1)) :
    this.performanceBodStoreService.createPerformanceBodStatusAsync(performanceBodStatus).pipe(take(1))
    concat(one, two, three, four)
      .toPromise()
      .then(() => {
        this.refreshPerformanceBod(performanceBod.id);
        this.performanceBodStoreService.refreshingBodDetails.subscribe(
          (isRefreshing: boolean) => {
            if (!isRefreshing) {
              this.refreshed.emit(true);
              this.refreshed.complete();
            }
          },
          (error: any) => {
            this.refreshed.error(error);
            this.refreshed.complete();
          }
        );
      })
      .catch(error => {
        this.refreshed.error(error);
        this.refreshed.complete();
      });
  }

  public savePerformanceBod(performanceBod: IPerformanceBod): Observable<any> {
    return this.performanceBodStoreService.updateAsync(performanceBod);
  }

  public savePerformanceBodSignatures(performanceBodSignatures: IPerformanceBodSignatures): Observable<any> {
    return this.performanceBodStoreService.createPerformanceBoDSignatures(performanceBodSignatures);
  }

  public savePerformanceBodPackage(updatedPackages: IPerformanceBodPackages[]): Observable<any> {
    return this.performanceBodStoreService.updatePackagesAsync(updatedPackages);
  }

  public addNewPerformanceBodPackage(newPackages: IPerformanceBodPackages[]): Observable<any> {
    return this.performanceBodStoreService.addPackagesAsync(newPackages);
  }

  public validPerformanceBOD(data: IPerformanceBod): Observable<any> {
    return this.performanceBodDataService.validPerformanceBOD(data);
  }
}
