import { NgModule } from '@angular/core';
import { PricetToolComponent } from './modules/cAndD/pricet/pricet-tool.component';
import { RouterModule, Routes } from '@angular/router';
import { PricetToolIndexComponent } from './modules/cAndD/pricet/pricet-tool-index.component';
import { OpenScenarioComponent } from './modules/cAndD/open-scenario/open-scenario.component';
import { CanDeactivateGuard } from './_guards/can-deactivate/CanDeactivateGuard';
import { AuthGuard } from './_guards/auth.guard';
import { NotAuthorizedComponent } from './_shared/notauthorized/not-authorized.component';
import { HelpComponent } from './_shared/help/help.component';
import { ExportDataComponent } from './modules/cAndD/export-data/export-data.component';
import { ManageScenarioDataComponent } from './modules/cAndD/manage-scenario-data/manage-scenario-data.component';
import { ReportDashboardComponent } from './modules/cAndD/report-dashboard/report-dashboard.component';
import { RedirectGuard } from './_guards/redirect-guard';
import { DasSimpleSummaryComponent } from './modules/cAndD/das-simple-summary/das-simple-summary.component';
import { DasSimpleListComponent } from './modules/cAndD/das-simple-list/das-simple-list.component';
import { ScenarioDataComponent } from './modules/cAndD/scenario-data/scenario-data.component';
export const routes: Routes = [
  // C&D
  // C&D Scenarios
  {
    path: 'Scenario',
    data: {
      text: 'C&D Scenarios',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    children: [
      {
        path: 'New',
        component: PricetToolComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          text: 'Create New Scenario',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'List',
        component: OpenScenarioComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Open Scenario',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'id/:id',
        component: PricetToolComponent,
        data: {
          text: 'Create New Scenario',
        },
      },
      {
        path: 'DasSimple/New',
        component: DasSimpleSummaryComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Create DAS Simple',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'DasSimple/id/:id',
        data: { text: 'Das simple summary' },
        component: DasSimpleSummaryComponent,
      },
      {
        path: 'DasSimple/List',
        component: DasSimpleListComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Open DAS Simple',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
    ],
  },
  // Performance BOD
  {
    path: 'performanceBOD',
    data: {
      text: 'Performance BOD',
      subModuleRoutes: 'performanceBOD',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    loadChildren: () => import('./modules/performance-bod/performance-bod.module').then(m => m.PerformanceBodModule),
  },
  // sft
  {
    path: 'standardFacility',
    data: {
      text: 'SFT Facilities',
      subModuleRoutes: 'StandardFacility',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    loadChildren: () =>
      import('./modules/standard-facility/standard-facility.module').then(m => m.StandardFacilityModule),
  },
  // P&E
  {
    path: 'ExistingFacilities',
    data: {
      text: 'P&E Facilities',
      subModuleRoutes: 'ExistingFacilities',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    loadChildren: () =>
      import('./modules/existing-facilities/existing-facilities.module').then(m => m.ExistingFacilitiesModule),
  },
  // fit
  {
    path: 'InfrastructureBod',
    data: {
      text: 'FIT BOD',
      subModuleRoutes: 'InfrastructureBod',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    loadChildren: () => import('./modules/infrastructure/infrastructure.module').then(m => m.InfrastructureModule),
  },

  //commented below lines since PRICET MAP Has been removed
  // {
  //   path: 'gis',
  //   component: PricetToolIndexComponent,
  //   canActivate: [RedirectGuard],
  //   data: {
  //     text: 'GIS Map',
  //     isForAdmin: true,
  //     isInGlamGroup: true,
  //     externalUrl: 'pricetmap/',
  //   },
  // },
  // Reports
  {
    path: 'reports',
    data: {
      text: 'Reports',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    children: [
      {
        path: 'ExportData',
        component: ExportDataComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Export Data',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'ManageScenarioData',
        component: ManageScenarioDataComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Manage Scenario Data',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
      {
        path: 'ReportDashboard',
        component: ReportDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Power BI Dashboard',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },

      {
        path: 'ScenarioData',
        component: ScenarioDataComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'C&D Scenario Data',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
    ],
  },

  // help
  {
    path: 'help',
    data: {
      text: 'Help',
      isForAdmin: true,
      isInGlamGroup: true,
    },
    children: [
      {
        path: 'Knowledge Base',
        component: PricetToolIndexComponent,
        resolve: { url: 'externalUrlRedirectResolver' },
        canActivate: [AuthGuard],
        data: {
          text: 'Knowledge Base',
          isForAdmin: true,
          isInGlamGroup: true,
          externalUrl: 'https://go.chevron.com/PRICETKnowledgeBase',
        },
      },
      {
        path: 'Contacts',
        component: HelpComponent,
        canActivate: [AuthGuard],
        data: {
          text: 'Contacts',
          isForAdmin: true,
          isInGlamGroup: true,
        },
      },
    ],
  },
  // admin
  {
    path: 'admin',
    data: {
      text: 'Admin',
      subModuleRoutes: 'Admin',
      isForAdmin: true,
      isInGlamGroup: false,
    },
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },

  // {
  //   path: 'performance-bod',
  //   data: { text: 'Performance BOD' },
  //   component: PerformanceBodToolComponent,
  // },

  {
    path: 'notauthorized',
    data: { text: 'Not Authorized' },
    component: NotAuthorizedComponent,
  },

  {
    path: '',
    data: { text: '' },
    component: PricetToolIndexComponent,
    canActivate: [AuthGuard],
  },

  {
    path: '**',
    data: { text: '' },
    redirectTo: '/',
  },
  //{ path: 'existing-cilities', loadChildren: () => import('./existing-facilities/existing-facilities.module').then(m => m.ExistingFacilitiesModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
