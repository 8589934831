<kendo-floatinglabel
  *ngIf="correctType"
  [text]="placeholder"
  [ngClass]="{ 'p-0': !placeholder }"
  class="fit-cc-input__label"
>
  <kendo-numerictextbox
    *ngIf="
      type === types.INTEGER ||
      type === types.DECIMAL ||
      type === types.CURRENCY ||
      type === types.PERCENTAGE ||
      type === types.GEOGRAPHY
    "
    [formControl]="formControl"
    [autoCorrect]="'autoCorrect'"
    [min]="min"
    [max]="max"
    [decimals]="decimals"
    [disabled]="disabled"
    [readonly]="readonly"
    [spinners]="spinners"
    [format]="format"
    [step]="step"
    (click)="onTouchEmit($event)"
    [ngClass]="{ 'negative-value': formControl.value < 0 && (type === types.DECIMAL || type === types.CURRENCY) }"
    class="fit-cc-input__textbox"
  >
  </kendo-numerictextbox>
  <input
    kendoTextBox
    *ngIf="type === types.TEXT"
    [disabled]="disabled"
    [formControl]="formControl"
    [readonly]="readonly"
    (click)="onTouchEmit($event)"
    class="fit-cc-input__textbox"
  />
  <kendo-datepicker
    *ngIf="type === types.DATETIME"
    [formControl]="formControl"
    [format]="'yyyy-MM-dd'"
    placeholder=""
    (click)="onTouchEmit($event)"
  >
  </kendo-datepicker>
</kendo-floatinglabel>
<span
  class="k-tooltip-validation"
  *ngIf="
    enableErrorMsj && ngControl && ((ngControl.errors && ngControl.touched) || (ngControl.errors && ngControl.dirty))
  "
  ><span class="k-icon k-i-info"></span>{{ getFirstError() }}</span
>
