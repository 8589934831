import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { FitCCInputModule } from '../fit-cc-input/fit-cc-input.module';
import { FitCcDirectivesModule } from '../directives/directives.module';
import { FitCcCellIndicatorModule } from '../fit-cc-cell-indicator/fit-cc-cell-indicator.module';
import { FitCcColumnFilterModule } from '../fit-cc-column-filter/fit-cc-column-filter.module';
import { FitCcDialogModule } from '../fit-cc-dialog/fit-cc-dialog.module';
import { FitCcExportToExcelModule } from '../fit-cc-export-to-excel/fit-cc-export-to-excel.module';
import { FitCcPopupFormModule } from '../fit-cc-popup-form/fit-cc-popup-form.module';
import { FitCcGridComponent } from './fit-cc-grid.component';
import { FitCcPopupButtonModule } from './../fit-cc-popup-button/fit-cc-popup-button.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    GridModule,
    DateInputsModule,
    FitCcDirectivesModule,
    FitCcCellIndicatorModule,
    FitCcExportToExcelModule,
    FitCcPopupFormModule,
    FitCcDialogModule,
    TooltipModule,
    DropDownsModule,
    FitCcColumnFilterModule,
    FitCCInputModule,
    FitCcPopupButtonModule
  ],
  declarations: [FitCcGridComponent],
  providers: [NotificationService],
  exports: [FitCcGridComponent],
})
export class FitCcGridModule {}
