<div class="input-content">
  <kendo-panelbar [animate]="!isIE()" [keepItemContent]="true">
    <kendo-panelbar-item [title]="'Summary'" expanded="true" [selected]="true">
      <ng-template kendoPanelBarContent>
        <app-input-summary
          (openDeletePads)="onOpenDeletePads($event)"
          (openCopyPads)="onOpenCopyPads($event)"
          (openActiveForOutlook)="onOpenActiveForOutlook($event)"
          (openSmartImport)="onOpenSmartImport($event)"
          (openImportPadBuilder)="onOpenImportPadBuilder($event)"
          (saveScenarioCost)="onSaveScenarioCost($event)"
          (refreshSmartImportPads)="onRefreshPadsSmartImport($event)"
        ></app-input-summary>
      </ng-template>
    </kendo-panelbar-item>
    <kendo-panelbar-item [title]="'Facility Assignment'" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-input-facility-assignment (openEditPads)="onOpenEditPads($event)"></app-input-facility-assignment>
      </ng-template>
    </kendo-panelbar-item>
    <kendo-panelbar-item [title]="'Scope Count'" expanded="true">
      <ng-template kendoPanelBarContent>
        <app-input-items (openEditPads)="onOpenEditPads($event)"></app-input-items>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</div>
