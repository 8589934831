import { Injectable } from '@angular/core';
import { IPerformanceBodPads } from '../api/performance-bod-pads';
import { IPerformanceBodWellPad } from '../api/performance-bod-well-pad';
import { IPerformanceBodFacilityPad } from '../api/performance-bod-facility-pad';
import { IPerformanceBodPipelines } from '../api/performance-bod-pipelines';
import { InfrastructureCommercial } from '../api/infrastructure-commercial';
import { IPerformanceBodSummary } from '../api/performance-bod-summary';
import { IPerformanceBodCompressor } from '../api/performance-bod-compressor';
import { IPerformanceBodCentralTankBattery } from '../api/performance-bod-central-tank-battery';
import { IPerformanceBodSaltWaterDisposal } from '../api/performance-bod-salt-water-disposal';
import { IPerformanceBodExistingFacilities } from '../api/performance-bod-existing-facilities';
import { InfrastructureRegulatoryPermitting } from '../api/infrastructure-regulatory-permitting';
import { IPerformanceExpectedPeakProductionRates } from '../api/performance-expected-peak-production-rates';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodCalculationsService {
  private spudDateLogicData: any[] = [];
  private popDateLogicData: any[] = [];
  private numberOfWellsLogicData: any[] = [];
  private wellCountsLogicData: any[] = [];
  private wellPadsPermitsRequiredByLogicData: any[] = [];
  private expectedPeakProductionLogicData: any[] = [];
  constructor() {
    // empty
  }

  public spudDateChange(performanceBodPads: IPerformanceBodPads[]): void {
    for (const performanceBodPad of performanceBodPads) {
      const getIndex = () => {
        for (let idx = 0; idx < this.spudDateLogicData.length; idx++) {
          if (this.spudDateLogicData[idx].performanceBodPadsId === performanceBodPad.id) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.spudDateLogicData.splice(index, 1, {
          performanceBodPadsId: performanceBodPad.id,
          performanceBodPackagesId: performanceBodPad.performanceBodPackagesId,
          spudDate: performanceBodPad.spudDate,
        });
      } else {
        this.spudDateLogicData.push({
          performanceBodPadsId: performanceBodPad.id,
          performanceBodPackagesId: performanceBodPad.performanceBodPackagesId,
          spudDate: performanceBodPad.spudDate,
        });
      }
    }
  }

  public popDateChange(performanceBodPads: IPerformanceBodPads[]): void {
    for (const performanceBodPad of performanceBodPads) {
      const getIndex = () => {
        for (let idx = 0; idx < this.popDateLogicData.length; idx++) {
          if (this.popDateLogicData[idx].performanceBodPadsId === performanceBodPad.id) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.popDateLogicData.splice(index, 1, {
          performanceBodPadsId: performanceBodPad.id,
          performanceBodPackagesId: performanceBodPad.performanceBodPackagesId,
          popDate: performanceBodPad.popDate,
        });
      } else {
        this.popDateLogicData.push({
          performanceBodPadsId: performanceBodPad.id,
          performanceBodPackagesId: performanceBodPad.performanceBodPackagesId,
          popDate: performanceBodPad.popDate,
        });
      }
    }
  }

  public numberOfWellsChange(performanceBodPads: IPerformanceBodPads[]): void {
    for (const performanceBodPad of performanceBodPads) {
      const getIndex = () => {
        for (let idx = 0; idx < this.numberOfWellsLogicData.length; idx++) {
          if (this.numberOfWellsLogicData[idx].performanceBodPadsId === performanceBodPad.id) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.numberOfWellsLogicData.splice(index, 1, {
          performanceBodPadsId: performanceBodPad.id,
          numberOfWells: performanceBodPad.numberOfWells,
        });
      } else {
        this.numberOfWellsLogicData.push({
          performanceBodPadsId: performanceBodPad.id,
          numberOfWells: performanceBodPad.numberOfWells,
        });
      }
    }
  }

  public wellCountsChange(performanceBodWellPads: IPerformanceBodWellPad[]): void {
    for (const wellPad of performanceBodWellPads) {
      const getIndex = () => {
        for (let idx = 0; idx < this.wellCountsLogicData.length; idx++) {
          if (this.wellCountsLogicData[idx].performanceBodPadsId === wellPad.performanceBodPadsId) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.wellCountsLogicData.splice(index, 1, {
          performanceBodPadsId: wellPad.performanceBodPadsId,
          wellCount: wellPad.wellCount,
        });
      } else {
        this.wellCountsLogicData.push({
          performanceBodPadsId: wellPad.performanceBodPadsId,
          wellCount: wellPad.wellCount,
        });
      }
    }
  }

  public wellPadsPermitsRequiredByChange(performanceBodWellPads: IPerformanceBodWellPad[]): void {
    for (const wellPad of performanceBodWellPads) {
      const getIndex = () => {
        for (let idx = 0; idx < this.wellPadsPermitsRequiredByLogicData.length; idx++) {
          if (
            this.wellPadsPermitsRequiredByLogicData[idx].performanceBodPackagesId === wellPad.performanceBodPackagesId
          ) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.wellPadsPermitsRequiredByLogicData.splice(index, 1, {
          performanceBodPackagesId: wellPad.performanceBodPackagesId,
          permitsRequiredBy: wellPad.permitsRequiredBy,
        });
      } else {
        this.wellPadsPermitsRequiredByLogicData.push({
          performanceBodPackagesId: wellPad.performanceBodPackagesId,
          permitsRequiredBy: wellPad.permitsRequiredBy,
        });
      }
    }
  }

  public wellPadDatesCalculations(wellPads: IPerformanceBodWellPad[]): IPerformanceBodWellPad[] {
    const wellPadsToApplyLogic: IPerformanceBodWellPad[] = [];
    for (const logic of this.spudDateLogicData) {
      for (const wellPad of wellPads.filter(w => w.performanceBodPadsId === logic.performanceBodPadsId)) {
        if (logic.spudDate) {
          // Well Pad RFSD
          wellPad.wellPadRfsd = this.addDays(logic.spudDate, -30);
          // Permits Required By
          wellPad.permitsRequiredBy = this.addDays(logic.spudDate, -210);
          wellPadsToApplyLogic.push(wellPad);
        }
      }
    }

    return wellPadsToApplyLogic;
  }

  public expectedPEakProductionUnspecifiedPackages(expectedPeakProductionRatesPackages: string[]): void {
    for (const packageRate of expectedPeakProductionRatesPackages) {
      const getIndex = () => {
        for (let idx = 0; idx < this.expectedPeakProductionLogicData.length; idx++) {
          if (this.expectedPeakProductionLogicData[idx].performanceBodPackagesId === packageRate) {
            return idx;
          }
        }
        return -1;
      };
      const index = getIndex();
      if (index !== -1) {
        this.expectedPeakProductionLogicData.splice(index, 1, {
          performanceBodPackagesId: packageRate,
        });
      } else {
        this.expectedPeakProductionLogicData.push({
          performanceBodPackagesId: packageRate,
        });
      }
    }
  }

  public wellPadWellCountCalculations(wellPads: IPerformanceBodWellPad[]): IPerformanceBodWellPad[] {
    const wellPadsToApplyLogic: IPerformanceBodWellPad[] = [];
    for (const logic of this.numberOfWellsLogicData) {
      for (const wellPad of wellPads.filter(w => w.performanceBodPadsId === logic.performanceBodPadsId)) {
        wellPad.wellCount = logic.numberOfWells;
        wellPadsToApplyLogic.push(wellPad);
      }
    }

    return wellPadsToApplyLogic;
  }

  public padNumberOfWellsCalculations(pads: IPerformanceBodPads[]): IPerformanceBodPads[] {
    const padsToApplyLogic: IPerformanceBodPads[] = [];
    for (const logic of this.wellCountsLogicData) {
      for (const pad of pads.filter(w => w.id === logic.performanceBodPadsId)) {
        pad.numberOfWells = logic.wellCount;
      }
    }

    return padsToApplyLogic;
  }

  public facilityPadCalculations(facilityPads: IPerformanceBodFacilityPad[]): IPerformanceBodFacilityPad[] {
    const facilityPadsToApplyLogic: IPerformanceBodFacilityPad[] = [];
    for (const logic of this.popDateLogicData) {
      for (const facilityPad of facilityPads.filter(
        w => w.performanceBodPackagesId === logic.performanceBodPackagesId
      )) {
        if (logic.popDate) {
          // Facility Pad RFSD
          facilityPad.padRfsd = this.addDays(logic.popDate, -210);
          // Permits Required By
          facilityPad.permitsRequiredBy = this.addDays(logic.popDate, -270);
          facilityPadsToApplyLogic.push(facilityPad);
        }
      }
    }

    return facilityPadsToApplyLogic;
  }

  public pipelinesCalculations(pipelines: IPerformanceBodPipelines[]): IPerformanceBodPipelines[] {
    const pipelinesToApplyLogic: IPerformanceBodPipelines[] = [];
    for (const logic of this.popDateLogicData) {
      for (const pipeline of pipelines.filter(w => w.performanceBodPackagesId === logic.performanceBodPackagesId)) {
        if (logic.popDate) {
          // Pipeline RFSD
          pipeline.pipelineRfsd = this.addDays(logic.popDate, -30);
          // Permits Required By
          pipeline.permitsRequiredBy = this.addDays(logic.popDate, -240);
          pipelinesToApplyLogic.push(pipeline);
        }
      }
    }

    return pipelinesToApplyLogic;
  }

  public commercialCalculations(commercials: InfrastructureCommercial[]): InfrastructureCommercial[] {
    const commercialsToApplyLogic: InfrastructureCommercial[] = [];
    for (const logic of this.popDateLogicData) {
      for (const commercial of commercials.filter(w => w.performanceBodPadsId === logic.performanceBodPadsId)) {
        if (logic.popDate) {
          // RFSD
          commercial.readyForServiceDate = this.addDays(logic.popDate, -30);
          commercialsToApplyLogic.push(commercial);
        }
      }
    }

    return commercialsToApplyLogic;
  }

  public standardFacilitiesCalculations(
    standardFacilities: IPerformanceBodSummary[],
    compressors: IPerformanceBodCompressor[],
    centralTankBateries: IPerformanceBodCentralTankBattery[],
    saltWaterDisposals: IPerformanceBodSaltWaterDisposal[]
  ): IPerformanceBodSummary[] {
    // 1. New ,2. Existing
    const standardFacilitiesToApplyLogic: IPerformanceBodSummary[] = [];
    for (const logic of this.popDateLogicData) {
      for (const standarFacility of standardFacilities.filter(
        w => w.performanceBodPadsId === logic.performanceBodPadsId
      )) {
        if (logic.popDate) {
          // Ready for Service Data and Bod Lockdown Date logic
          let days = 0;
          if (standarFacility.isNew === 1) {
            // RFSD
            standarFacility.readyForServiceDate = this.addDays(logic.popDate, -30);

            const compresor = compressors.find(c => c.id === standarFacility.performanceBodCtbSwdCs);
            if (compresor) {
              days = -240;
            } else {
              const centralTankBatery = centralTankBateries.find(c => c.id === standarFacility.performanceBodCtbSwdCs);
              if (centralTankBatery) {
                days = -210;
              } else {
                const saltWaterDisposal = saltWaterDisposals.find(c => c.id === standarFacility.performanceBodCtbSwdCs);
                if (saltWaterDisposal) {
                  days = -240;
                }
              }
            }
            // Bod Lock Down
            standarFacility.bodLockdownDate = this.addDays(logic.popDate, days);
          }
          if (standarFacility.isNew === 2) {
            standarFacility.readyForServiceDate = null;
            standarFacility.bodLockdownDate = null;
          }
        }
      }
    }

    return standardFacilitiesToApplyLogic;
  }

  public existingFaciltiesCalculations(
    existingFacilties: IPerformanceBodExistingFacilities[]
  ): IPerformanceBodExistingFacilities[] {
    const existingFaciltiesToApplyLogic: IPerformanceBodExistingFacilities[] = [];
    for (const logic of this.popDateLogicData) {
      for (const existingFacilty of existingFacilties.filter(
        w => w.performanceBodPadsId === logic.performanceBodPadsId
      )) {
        if (logic.popDate) {
          // readyForServiceDate
          existingFacilty.readyForServiceDate = this.addDays(logic.popDate, -30);
          existingFaciltiesToApplyLogic.push(existingFacilty);
        }
      }
    }

    return existingFaciltiesToApplyLogic;
  }

  public regulatoryPermittingCalculations(
    regulatoryPermittings: InfrastructureRegulatoryPermitting[]
  ): InfrastructureRegulatoryPermitting[] {
    const regulatoryPermittingsToApplyLogic: InfrastructureRegulatoryPermitting[] = [];
    for (const logic of this.spudDateLogicData) {
      for (const regulatoryPermitting of regulatoryPermittings.filter(
        w => w.performanceBodPackagesId === logic.performanceBodPackagesId
      )) {
        if (logic.spudDate) {
          // Requested Construction Start Date
          regulatoryPermitting.requestedConstructionStartDate = this.addDays(logic.spudDate, -210);
          regulatoryPermittingsToApplyLogic.push(regulatoryPermitting);
        }
      }
    }

    return regulatoryPermittingsToApplyLogic;
  }

  public regulatoryPermittingPermitsRequiredByCalculations(
    regulatoryPermittings: InfrastructureRegulatoryPermitting[]
  ): InfrastructureRegulatoryPermitting[] {
    const regulatoryPermittingsToApplyLogic: InfrastructureRegulatoryPermitting[] = [];
    for (const logic of this.wellPadsPermitsRequiredByLogicData) {
      for (const regulatoryPermitting of regulatoryPermittings.filter(
        w => w.performanceBodPackagesId === logic.performanceBodPackagesId
      )) {
        if (logic.permitsRequiredBy) {
          regulatoryPermitting.requestedConstructionStartDate = logic.permitsRequiredBy;
          regulatoryPermittingsToApplyLogic.push(regulatoryPermitting);
        }
      }
    }

    return regulatoryPermittingsToApplyLogic;
  }
  public wellPadCreationCalculation(newWellPad: IPerformanceBodWellPad, existingPads: IPerformanceBodPads[]): void {
    const matchPads = existingPads.filter(p => p.id === newWellPad.performanceBodPadsId);
    this.spudDateChange(matchPads);
    this.popDateChange(matchPads);
    this.numberOfWellsChange(matchPads);
    this.wellPadDatesCalculations([newWellPad]);
    this.wellPadWellCountCalculations([newWellPad]);
  }

  public facilityPadCreationCalculation(
    newFacilityPad: IPerformanceBodFacilityPad,
    existingPads: IPerformanceBodPads[]
  ): void {
    const matchPads = existingPads.filter(p => p.performanceBodPackagesId === newFacilityPad.performanceBodPackagesId);
    this.popDateChange(matchPads);
    this.facilityPadCalculations([newFacilityPad]);
  }

  public pipelinesCreationCalculation(
    newPipeline: IPerformanceBodPipelines,
    existingPads: IPerformanceBodPads[]
  ): void {
    const matchPads = existingPads.filter(p => p.performanceBodPackagesId === newPipeline.performanceBodPackagesId);
    this.popDateChange(matchPads);
    this.pipelinesCalculations([newPipeline]);
  }

  public commercialCreationCalculation(
    newCommercial: InfrastructureCommercial,
    existingPads: IPerformanceBodPads[]
  ): void {
    const matchPads = existingPads.filter(p => p.performanceBodPackagesId === newCommercial.performanceBodPackagesId);
    this.popDateChange(matchPads);
    this.commercialCalculations([newCommercial]);
  }

  public standardFacilityUpdateCalculation(
    newStandardFacility: IPerformanceBodSummary,
    existingPads: IPerformanceBodPads[],
    compressors: IPerformanceBodCompressor[],
    centralTankBateries: IPerformanceBodCentralTankBattery[],
    saltWaterDisposals: IPerformanceBodSaltWaterDisposal[]
  ): void {
    const matchPads = existingPads.filter(p => p.id === newStandardFacility.performanceBodPadsId);
    this.popDateChange(matchPads);
    this.standardFacilitiesCalculations([newStandardFacility], compressors, centralTankBateries, saltWaterDisposals);
  }

  public existingFacilityCreationCalculation(
    newExistingFacility: IPerformanceBodExistingFacilities,
    existingPads: IPerformanceBodPads[]
  ): void {
    const matchPads = existingPads.filter(p => p.id === newExistingFacility.performanceBodPadsId);
    this.popDateChange(matchPads);
    this.existingFaciltiesCalculations([newExistingFacility]);
  }

  public regulatoryPermittingCreationCalculation(
    newexistingRegulatory: InfrastructureRegulatoryPermitting,
    existingPads: IPerformanceBodPads[]
  ): void {
    const matchPads = existingPads.filter(p => p.id === newexistingRegulatory.performanceBodPackagesId);
    this.popDateChange(matchPads);
    this.regulatoryPermittingCalculations([newexistingRegulatory]);
  }

  public expectedPeakRateCalculations(
    expectedPeakProductionRates: IPerformanceExpectedPeakProductionRates[],
    pads: IPerformanceBodPads[]
  ): IPerformanceExpectedPeakProductionRates[] {
    const expectedPeakProductionRatesToApplyLogic: IPerformanceExpectedPeakProductionRates[] = [];
    for (const logic of this.expectedPeakProductionLogicData) {
      const padIds = pads
        .filter(p => p.performanceBodPackagesId === logic.performanceBodPackagesId)
        .map((item: IPerformanceBodPads) => {
          return item.id.toLowerCase();
        });
      const expecedPeakByPad = expectedPeakProductionRates.filter(w =>
        padIds.includes(w.performanceBodPadsId.toLowerCase())
      );
      for (const expectedPeak of expecedPeakByPad) {
        //
        if (expectedPeak.wellPadName.toLowerCase().includes('unspecified')) {
          expectedPeak.oilBopd = expectedPeak.oilBopdOverridable;
          expectedPeak.waterBwpd = expectedPeak.waterBwpdOverridable;
          expectedPeak.lpGasMmscfd = expectedPeak.lpGasMmscfdOverridable;
          expectedPeak.hpGasMmscfd = expectedPeak.hpGasMmscfdOverridable;
          expectedPeak.gasLiftMmscfd = expectedPeak.gasLiftMmscfdOverridable;

          const removedUnspecified = expecedPeakByPad.filter(e => e.wellPadName.toLowerCase() !== 'unspecified');

          if (expectedPeak.oilBopdOverridable === null) {
            expectedPeak.oilBopd = removedUnspecified
              .map((o: IPerformanceExpectedPeakProductionRates) => o.oilBopd)
              .reduce((sum: number, current: number) => isNaN(sum) ? 0 : sum + (isNaN(current) ? 0 : current), 0);
          }

          if (expectedPeak.waterBwpdOverridable === null) {
            expectedPeak.waterBwpd = removedUnspecified
              .map((o: IPerformanceExpectedPeakProductionRates) => o.waterBwpd)
              .reduce((sum: number, current: number) => isNaN(sum) ? 0 : sum + (isNaN(current) ? 0 : current), 0);
          }

          if (expectedPeak.lpGasMmscfdOverridable === null) {
            expectedPeak.lpGasMmscfd = removedUnspecified
              .map((o: IPerformanceExpectedPeakProductionRates) => o.lpGasMmscfd)
              .reduce((sum: number, current: number) => isNaN(sum) ? 0 : sum + (isNaN(current) ? 0 : current), 0);
          }

          if (expectedPeak.hpGasMmscfdOverridable === null) {
            expectedPeak.hpGasMmscfd = removedUnspecified
              .map((o: IPerformanceExpectedPeakProductionRates) => o.hpGasMmscfd)
              .reduce((sum: number, current: number) => isNaN(sum) ? 0 : sum + (isNaN(current) ? 0 : current), 0);
          }

          if (expectedPeak.gasLiftMmscfdOverridable === null) {
            expectedPeak.gasLiftMmscfd = removedUnspecified
              .map((o: IPerformanceExpectedPeakProductionRates) => o.gasLiftMmscfd)
              .reduce((sum: number, current: number) => isNaN(sum) ? 0 : sum + (isNaN(current) ? 0 : current), 0);
          }

          expectedPeakProductionRatesToApplyLogic.push(expectedPeak);
        }
      }
    }

    return expectedPeakProductionRatesToApplyLogic;
  }

  public clearCalculations(): void {
    this.spudDateLogicData = [];
    this.popDateLogicData = [];
    this.numberOfWellsLogicData = [];
    this.wellCountsLogicData = [];
    this.expectedPeakProductionLogicData = [];
  }

  public addDays(date, days): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }
}
