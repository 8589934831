import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcPopupFormComponent } from './fit-cc-popup-form.component';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FitCcFormControlModule } from '../fit-cc-form-control/fit-cc-form-control.module';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, DialogModule, FitCcFormControlModule],
  declarations: [FitCcPopupFormComponent],
  exports: [FitCcPopupFormComponent],
})
export class FitCcPopupFormModule {}
