<div [formGroup]="form">
  <!-- <label [attr.for]="column.field" [hidden]="column.hidden">{{ column.title }}</label> -->

  <div [ngSwitch]="column.type" class="fit-cc-form__w100">
    <!-- text, numeric -->
    <fit-cc-input *ngSwitchDefault
                  [formControlName]="column.field"
                  [type]="column.type"
                  [hidden]="column.hidden"
                  [required]="column.required"
                  [enableErrorMsj]="false"
                  [placeholder]="column.title"></fit-cc-input>

    <!-- textarea -->
    <kendo-floatinglabel [text]="column.title" *ngSwitchCase="'textarea'" class="fit-cc-form__w100">
      <textarea kendoTextArea
                  class="fit-cc-form__w100"
                  [formControlName]="column.field"
                  [hidden]="column.hidden"
                  [id]="column.field"
                  [required]="column.required"
                  [readonly]="disabledControl">
      </textarea>
      </kendo-floatinglabel>

      <!-- Switch -->
      <label class="k-form-field ml-2" *ngSwitchCase="'boolean'">
        <span>{{ column.title }} </span>
        <kendo-switch class="fit-cc-form__switch"
                      [id]="column.field"
                      [formControlName]="column.field"
                      [onLabel]="' '"
                      [offLabel]="' '"
                      [disabled]="disabledControl">
        </kendo-switch>
      </label>

      <!-- dropdown -->
      <kendo-floatinglabel [text]="column.title" *ngSwitchCase="'dropdown'" class="fit-cc-form__w100">
        <kendo-dropdownlist class="fit-cc-form__w100"
                            [id]="column.field"
                            [formControlName]="column.field"
                            [data]="column.options"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [valuePrimitive]="true"
                            [hidden]="column.hidden"
                            [required]="column.required"
                            (valueChange)="handledropDownChange($event, form)"
                            [disabled]="disabledControl">
        </kendo-dropdownlist>
      </kendo-floatinglabel>

      <!-- multiselect -->
      <kendo-floatinglabel [text]="column.title" *ngSwitchCase="'multiselect'" class="fit-cc-form__w100">
        <kendo-multiselect class="fit-cc-form__w100"
                           [id]="column.field"
                           [formControlName]="column.field"
                           [data]="column.options"
                           [textField]="'text'"
                           [valueField]="'value'"
                           [valuePrimitive]="true"
                           [hidden]="column.hidden"
                           [required]="column.required"
                           (valueChange)="handledropDownChange($event, form)"
                           [disabled]="disabledControl">
        </kendo-multiselect>
      </kendo-floatinglabel>

      <div *ngIf="(!isValid && isDirty) || (!isValid && isTouched)" class="fit-cc-form__warning">
        <span>
          <i class="k-icon k-i-warning"></i>
          {{ column.title }} is required
        </span>
      </div>
  </div>
</div>
