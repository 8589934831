import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fit-cc-cell-indicator',
  templateUrl: './fit-cc-cell-indicator.component.html',
  styleUrls: ['./fit-cc-cell-indicator.component.scss'],
})
export class FitCcCellIndicatorComponent  {
  @Input() item: any;
  @Input() field: any;
  @Input() updatedItems: any[];
  @Input() originalData: any[];

  private isNew(item: any = {}): boolean {
    return !item.id;
  }

  private itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  };

  checkUpdated(item: any, field: string, updatedItemsArray: any[], originalItemsArray: any[]): boolean {
    let index;
    let indexO;
    let updatedItem;
    let originalItem;

    if (!this.isNew(item)) {
      index = this.itemIndex(item, updatedItemsArray);
      indexO = this.itemIndex(item, originalItemsArray);

      if (index !== -1) {
        updatedItem = updatedItemsArray[index][field];
        originalItem = originalItemsArray[indexO][field];

        if (updatedItem === undefined || updatedItem === null) {
          return false;
        }

        if (Array.isArray(originalItem) && Array.isArray(updatedItem)) {
          let result = false;
          updatedItem.forEach(o =>
            originalItem.forEach(u => {
              if (originalItem.indexOf(o) === updatedItem.indexOf(u)) {
                if (o !== u) {
                  result = true;
                }
              } else if (updatedItem.length !== originalItem.length) {
                result = true;
              }
            })
          );

          return result;
        } else {
          if (
            Object.prototype.toString.call(updatedItem) === '[object Date]' &&
            Object.prototype.toString.call(originalItem) === '[object Date]'
          ) {
            if (updatedItem.getTime() !== originalItem.getTime()) {
              return true;
            }
          } else if (updatedItem !== originalItem) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }
}
