import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCCInputComponent } from './components/fit-cc-input/fit-cc-input.component';
import { InputsModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FitCcLocationInputComponent } from './components/fit-cc-location-input/fit-cc-location-input.component';
import { FloatingLabelModule } from '@progress/kendo-angular-label';

@NgModule({
  imports: [
    CommonModule,
    InputsModule,
    NumericTextBoxModule,
    FormsModule,
    ReactiveFormsModule,
    DateInputsModule,
    FloatingLabelModule,
  ],
  declarations: [FitCCInputComponent, FitCcLocationInputComponent],
  exports: [FitCCInputComponent, FitCcLocationInputComponent],
})
export class FitCCInputModule {}
