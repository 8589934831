<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" [width]="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid #infraWellPad
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            class="infrastructure-well-pad-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!performanceBodWellPadService.hasChanges()"
            (click)="cancelChanges(infraWellPad)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column title="Well Pad" field="performanceBodPadsId" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="wellPadName"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPadsList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem?.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="performanceBodPadsList"
                          textField="wellPadName"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPadsId')"
                          (valueChange)="changePackage($event, dataItem, formGroup)">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
        </ng-template>
      </kendo-dropdownlist>
      <span #labelPopup></span>
      <kendo-popup [anchor]="labelPopup"
                   [hidden]="messageDuplicate"
                   popupClass="k-widget k-tooltip k-tooltip-validation">
        The Well Pad Name is already selected.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesName" title="Package Name" [editable]="false" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{
        getPerformanceBodPackages(getPerformanceBodPads(dataItem.performanceBodPadsId)?.performanceBodPackagesId)?.name
      }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate"
                     title="C&D/HO Estimate"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="120">
  </kendo-grid-column>

  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"  
                     title="Approved AFE Amount"
                     [editable]="true" 
                     editor="numeric" 
                     filter="numeric"
                     width="150">
  </kendo-grid-column>

  <kendo-grid-column field="wellCount"
                     title="Well Count"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="120">
  </kendo-grid-column>
  <kendo-grid-column field="openClosedLoop" title="Open or Closed Loop" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="value"
                         valueField="text"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="openClosedLoopList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="openClosedLoopList"
                          textField="text"
                          valueField="value"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('openClosedLoop')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="padDimensions" title="Dimensions" [editable]="true" [width]="120"> </kendo-grid-column>
  <kendo-grid-column field="padDesign" title="Pad Design" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="value"
                         valueField="text"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="padDesignList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.padDesign }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="padDesignList"
                          textField="text"
                          valueField="value"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('padDesign')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="padOrientation" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="value"
                         valueField="text"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="padOrientationList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      <div kendoTooltip title="Reference Standard Drilling Pad drawings">Pad Orientation</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.padOrientation }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="padOrientationList"
                          textField="text"
                          valueField="value"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('padOrientation')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="permitsRequiredBy"
                     title="Permits Required By"
                     [filterable]="filterable"
                     filter="date"
                     [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.permitsRequiredBy | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.permitsRequiredBy"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('permitsRequiredBy')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="wellPadRfsd" title="Well Pad RFSD" [filterable]="filterable" filter="date" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.wellPadRfsd | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.wellPadRfsd"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('wellPadRfsd')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="120"></kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
