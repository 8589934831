<div class="card-body">
    <p class="card-title center">
        Power Users for PRICET
    </p>
    <kendo-grid [resizable]="true" [data]="gridView" [scrollable]="'virtual'" [rowHeight]="33" [height]="600"
        [loading]="gridView === null">

        <kendo-grid-column field="name" title="Name" width="200">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                {{dataItem.name}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="name" title="CAI" width="200">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                {{dataItem.cai}}
            </ng-template>
        </kendo-grid-column>


    </kendo-grid>
</div>