import { Component, OnInit, AfterViewInit, Input, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { IDevelopmentArea } from '../../../api/devArea';
import { ScenarioType } from '../../../api/scenarioType';


interface IPadNames {
  padNameFrom: string;
  padNameTo: string;
}

@Component({
  selector: 'app-input-summary-upload',
  styleUrls: ['./input-summary-upload.component.css'],
  templateUrl: './input-summary-upload.component.html'
})
export class InputSummaryUploadComponent implements OnInit, OnDestroy, AfterViewInit {
  
  // Dialog attributes
  public dialogOpened = false;
  public dialogSmartOpened = false;
  public copyPadButtonDisabled = true;	
  public canEditCnd = false;
  public scenarioId: string;

  @Input() developmentAreaId: IDevelopmentArea;
  @Input() scenarioTypeId: ScenarioType;
  @Output() public openDeletePads = new EventEmitter<boolean>();
  @Output() public openCopyPads = new EventEmitter<boolean>();
  @Output() public openActiveForOutlook = new EventEmitter<boolean>();
  @Output() public openSmartImport = new EventEmitter<boolean>();
  @Output() public openImportPadBuilder = new EventEmitter<boolean>();
  @Output() public refreshSmartImportPads = new EventEmitter<boolean>();
  
  constructor(private dataService: DataService,
    private scenarioStoreService: ScenarioStoreService,
    private permissionsProvider: PermissionsProvider
  ) { }

  public ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.dialogOpened = false;
    this.dialogSmartOpened = false;

    this.scenarioStoreService.scenarioID$.subscribe(data => {
      this.scenarioId = data;
      this.loadPadData();
    });

    this.scenarioStoreService.isScenarioCreated.subscribe((created: boolean) => {
      if (created) {
        this.loadPadData();
      }
    });
  }

  public ngAfterViewInit() {
    this.loadPadData();
  }

  public ngOnDestroy(): void {
    this.openDeletePads.complete();    
    this.openCopyPads.complete();
    this.openImportPadBuilder.complete();
    this.openActiveForOutlook.complete();
    this.openSmartImport.complete();
    this.refreshSmartImportPads.complete();
  }

  public openDialog() {
    if (this.developmentAreaId) {
      this.openImportPadBuilder.next(true);
    } else {
      alert('Please select a development area before uploading a Pad Builder file');
    }
  }

  public openSmarTImportDialog() {
    if (this.developmentAreaId) {
      this.openSmartImport.next(true);
    } else {
      alert('Please select a development area before uploading a Pad Builder file');
    }
  }


  public refreshSmarTImportPadsDialog() {
    if (this.developmentAreaId) {
      this.refreshSmartImportPads.next(true);
    } else {
      alert('Please select a development area before uploading a Pad Builder file');
    }
  }


  public openDialogPad() {
    this.openCopyPads.next(true);
  }

  public openDeletePadDialog() {
    this.openDeletePads.next(true); 
  }

  public openActiveForOutlookDialog() {
    this.openActiveForOutlook.next(true);
  }

  public loadPadData(): void {
    this.dataService.getScenarioPadBracketByScenarioId(this.scenarioId).subscribe(padBrackets => {
      if (!this.canEditCnd) {
        this.copyPadButtonDisabled = true;
      } else {
        if (padBrackets.length === 0) {
          this.copyPadButtonDisabled = true;
        } else {
          this.copyPadButtonDisabled = false;
        }
      }
    });
  }
}
