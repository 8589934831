<div class="row chevron-fit-header">
  <div class="col-sm-10">
    <div class="row">
      <div class="col-sm-12">
        <div class="navbar-global-links">
          <a href="http://inside.chevron.com">Inside Home</a> | <a href="http://onesearch.chevron.com">OneSearch</a> |
          <a href="http://whitepages.chevron.com/Search/Search.aspx">People Finder</a> |
          <a href="#">{{
            authRslt
              .replace('"', '')
              .replace('"', '')
              .toUpperCase()
          }}</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <div class="chevron-fit-title">
          <a routerLink="/" routerLinkActive="active">{{ toolName }}</a>
        </div>
        <div id="chevron-fit-menu" *ngIf="showMenu">
          <kendo-menu [items]="items" (select)="onSelect($event)"></kendo-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-2 chevron-logo">
    <img src="assets/Chevron_Logo2.png" height="58" width="51" />
  </div>
  <div kendoDialogContainer></div>
</div>

