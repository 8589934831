import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Basin } from '../api/basin';
import { GeographicArea } from '../api/geographicArea';
import { State } from '../api/state';
import { IDevelopmentArea } from '../api/devArea';
import { ICompressorCapacityAssumptions } from '../api/compressorCapacityAssumptions';
import { PadContingencyAssumptions as PadContingencyAssumption } from '../api/padContingencyAssumptions';
import { Kind } from '../api/kind';
import { Location } from '../api/location';
import { Rfsd } from '../api/rfsd';
import { InfrastructureClass } from '../api/infrastructure-class';
import { InfrastructureScopeCategory } from '../api/infrastructure-scope-category';
import { ScenarioType } from '../api/scenarioType';
import { IScenario } from '../api/pricetScenario';
import { ScenarioRig } from '../api/scenario-Rig';
import { ScenarioPbSummary } from '../api/padbuilder-master';
import { InputItem, ItemCostModel, ItemAndDetailsModel } from '../api/input-item';
import { GasForecastRow } from '../api/gas-forecast-row';
import { PadbuilderImport } from '../api/padbuilder-import';
import { WaterForecastRow } from '../api/water-forecast-row';
import { LandDamagesPipe } from '../api/landDamagesPipe';
import { LandDamagesOther } from '../api/landDamagesOther';
import { IReportOut } from '../api/reportout';
import { IViewPricetScenario } from '../api/viewPricetScenario';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { FacilityItemModel } from '../api/facility-item-model';
import { UnitOfMeasure } from '../api/unitOfMeasure-model';
import { take } from '@progress/kendo-data-query/dist/npm/transducers';
import { ScenarioPadBracket } from '../api/scenario-pad-bracket';
import { ICopyPads } from '../api/CopyPads';
import { ISelectPads } from '../api/SelectPads';
import { ICndCostMappingSft } from '../api/cnd-cost-mapping-sft';
import { YearCellTemplateDirective } from '@progress/kendo-angular-dateinputs';
import { IScenarioPadBracketCost } from '../api/scenario-pad-bracket-cost';
import { CNDMassExcelImport } from '../api/cndmassexcel-import';
import { ScenarioAfeType } from '../api/scenarioAfeType';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const httpOptionsDownload = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType: 'blob' as 'json',
};

const httpOptionsNoCache = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    'Content-Type': 'application/json',
  }),
};

@Injectable({ providedIn: 'root' })
export class DataService extends BehaviorSubject<any[]> {
  public baseUrlPadContingencyAssumptions: string = environment.PricetDomainURL + 'PadContingencyAssumptions';
  public baseUrlFacilityItem: string = environment.PacerDomainURL + 'FacilityItem';
  public baseUrlPrctKind: string = environment.PricetDomainURL + 'Kind';
  public baseUrlScenarioWellSchedule: string = environment.PricetDomainURL + 'ScenarioWellSchedule';
  public baseUrlScenario: string = environment.PricetDomainURL + 'Scenario';
  public baseUrlScenarioRig: string = environment.PricetDomainURL + 'ScenarioRig';
  public baseUrlRfsds: string = environment.PricetDomainURL + 'ReadyForServiceDateTiming';
  public baseUrlUnitOfMeasure: string = environment.PacerDomainURL + 'UnitOfMeasure';
  public baseUrlInfrastructureClass: string = environment.PricetDomainURL + 'InfrastructureClass';
  public baseUrlInfrastructureScopeCategory: string = environment.PricetDomainURL + 'InfrastructureScopeCategory';
  public baseUrlLocation: string = environment.PricetDomainURL + 'Location';
  public baseUrlKind: string = environment.PricetDomainURL + 'Kind';
  public baseUrlDevelopmentArea: string = environment.PricetDomainURL + 'DevelopmentArea';
  public baseUrlState: string = environment.PricetDomainURL + 'State';
  public baseUrlBasin: string = environment.PricetDomainURL + 'Basin';
  public baseUrlGeographicArea: string = environment.PricetDomainURL + 'GeographicArea';
  public baseUrlCompressorCapacityAssumptions: string = environment.PricetDomainURL + 'CompressorCapacityAssumptions';
  public baseUrlLandDamagesPipes: string = environment.PricetDomainURL + 'LandDamagesPipes';
  public baseUrlLandDamages: string = environment.PricetDomainURL + 'LandDamages';
  public baseUrlScenarioType: string = environment.PricetDomainURL + 'ScenarioType';
  public baseUrlScenarioPadBracket: string = environment.PricetDomainURL + 'ScenarioPadBracket';
  public baseUrlSmart: string = environment.PricetDomainURL + 'SmartExternal';
  public baseUrlScenarioEquipment: string = environment.PricetDomainURL + 'ScenarioEquipment';
  public baseUrlScenarioPadCost: string = environment.PricetDomainURL + 'ScenarioPadCost';
  public baseUrlScenarioPadBracketCost: string = environment.PricetDomainURL + 'ScenarioPadBracketCost';
  public baseUrlScenarioAfeType: string = environment.PricetDomainURL + 'ScenarioAfeType';
  private handleError: HandleError;
  public baseUrlScenarioExp: string = environment.PricetDomainURL + 'ScenarioExp';
  public BASE_URL = environment.PricetDomainURL;
  public data: any[] = [];
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    super([]);
    this.handleError = httpErrorHandler.createHandleError();
  }

  public getPadContingencyAssumptions(): Observable<PadContingencyAssumption[]> {
    return this.http
      .get<PadContingencyAssumption[]>(this.baseUrlPadContingencyAssumptions + '/List')
      .pipe(catchError(this.handleError('getPadContingencyAssumptions')));
  }

  public getInputItems(year: number): Observable<InputItem[]> {
    return this.http
      .get<InputItem[]>(`${this.baseUrlFacilityItem}/ListWithCostsByYear/${year}`)
      .pipe(catchError(this.handleError('getInputItems')));
  }

  // Get C&D Domain, information from Well Schedule, InputPadModel.cs entity (Library) --- >ListByScenario gouping
  public getPadbuilderData(id: string): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.baseUrlScenarioWellSchedule}/ListByScenario/${id}`, httpOptionsNoCache)
      .pipe(catchError(this.handleError('getPadbuilderData')));
  }

  // Retrive the waterforecast information to fill the tab Grid
  public getListWaterForcastByScenario(scenarioID: string): Observable<WaterForecastRow[]> {
    return this.http
      .get<WaterForecastRow[]>(`${this.baseUrlScenario}/ListWaterForcastByScenario/${scenarioID}`)
      .pipe(catchError(this.handleError('getListWaterForcastByScenario')));
  }

  // Retrive the gasforecast information to fill the tab Grid
  public getListGasForcastByScenario(scenarioID: string): Observable<GasForecastRow[]> {
    return this.http
      .get<GasForecastRow[]>(`${this.baseUrlScenario}/ListGasForcastByScenario/${scenarioID}`)
      .pipe(catchError(this.handleError('getListGasForcastByScenario')));
  }

  public getScenarioByKey(scenarioId: string): Observable<ScenarioPbSummary> {
    return this.http
      .get<ScenarioPbSummary>(`${this.baseUrlScenario}/GetByKey/${scenarioId}`)
      .pipe(catchError(this.handleError('getScenarioByKey')));
  }

  public getScenarioRigs(scenarioId: string): Observable<ScenarioRig[]> {
    return this.http
      .get<ScenarioRig[]>(`${this.baseUrlScenarioRig}/ListByScenarioId/${scenarioId}`)
      .pipe(catchError(this.handleError('getScenarioRigs')));
  }

  public getRfsdOptions(): Observable<Rfsd[]> {
    return this.http.get<Rfsd[]>(`${this.baseUrlRfsds}/List/`).pipe(catchError(this.handleError('getRfsdOptions')));
  }

  public getInfrastructureClassOptions(): Observable<InfrastructureClass[]> {
    return this.http
      .get<InfrastructureClass[]>(`${this.baseUrlInfrastructureClass}/List/`)
      .pipe(catchError(this.handleError('getInfrastructureClassOptions')));
  }

  public getScenarioAfeTypeOptions(): Observable<ScenarioAfeType[]> {
    return this.http
      .get<ScenarioAfeType[]>(`${this.baseUrlScenarioAfeType}/List/`)
      .pipe(catchError(this.handleError('getScenarioAfeTypeOptions')));
  }
  public getScopeCategoryOptions(): Observable<InfrastructureScopeCategory[]> {
    return this.http
      .get<InfrastructureScopeCategory[]>(`${this.baseUrlInfrastructureScopeCategory}/List/`)
      .pipe(catchError(this.handleError('getScopeCategoryOptions')));
  }

  public getScopeCategoryOptionsToAdditionalRows(): Observable<InfrastructureScopeCategory[]> {
    return this.http
      .get<InfrastructureScopeCategory[]>(`${this.baseUrlInfrastructureScopeCategory}/ListToAdditionalRows/`)
      .pipe(catchError(this.handleError('getScopeCategoryOptionsToAdditionalRows')));
  }

  public getLocationOptions(): Observable<Location[]> {
    return this.http
      .get<Location[]>(`${this.baseUrlLocation}/List/`)
      .pipe(catchError(this.handleError('getLocationOptions')));
  }

  public getKindOptions(): Observable<Kind[]> {
    return this.http.get<Kind[]>(`${this.baseUrlKind}/List/`).pipe(catchError(this.handleError('getKindOptions')));
  }

  public getCompressorCapacityAssumptions(): Observable<ICompressorCapacityAssumptions[]> {
    return this.http
      .get<ICompressorCapacityAssumptions[]>(this.baseUrlCompressorCapacityAssumptions + '/List')
      .pipe(catchError(this.handleError('getCompressorCapacityAssumptions')));
  }

  public getDevArea(): Observable<IDevelopmentArea[]> {
    return this.http
      .get<IDevelopmentArea[]>(this.baseUrlDevelopmentArea + '/List')
      .pipe(catchError(this.handleError('getDevelopmentArea')));
  }

  public getDeletedDevArea(): Observable<IDevelopmentArea[]> {
    return this.http
      .get<IDevelopmentArea[]>(this.baseUrlDevelopmentArea + '/ListDeleted')
      .pipe(catchError(this.handleError('getDeletedDevArea')));
  }

  public getState(): Observable<State[]> {
    return this.http.get<State[]>(this.baseUrlState + '/List').pipe(catchError(this.handleError('getState')));
  }

  public getBasin(): Observable<Basin[]> {
    return this.http.get<Basin[]>(this.baseUrlBasin + '/List').pipe(catchError(this.handleError('getBasin')));
  }

  public getDeletedBasin(): Observable<Basin[]> {
    return this.http
      .get<Basin[]>(this.baseUrlBasin + '/ListDeleted')
      .pipe(catchError(this.handleError('getDeletedBasin')));
  }

  public getGeographicArea(): Observable<GeographicArea[]> {
    return this.http
      .get<GeographicArea[]>(this.baseUrlGeographicArea + '/List')
      .pipe(catchError(this.handleError('getGeographicArea')));
  }

  public getDeletedGeographicArea(): Observable<GeographicArea[]> {
    return this.http
      .get<GeographicArea[]>(this.baseUrlGeographicArea + '/ListDeleted')
      .pipe(catchError(this.handleError('getGeographicArea')));
  }

  public getLandDamagesPipe(): Observable<LandDamagesPipe[]> {
    return this.http
      .get<LandDamagesPipe[]>(this.baseUrlLandDamagesPipes + '/List')
      .pipe(catchError(this.handleError('getLandDamagesPipe')));
  }

  public getLandDamagesOther(): Observable<LandDamagesOther[]> {
    return this.http
      .get<LandDamagesOther[]>(this.baseUrlLandDamages + '/List')
      .pipe(catchError(this.handleError('getLandDamagesOther')));
  }

  public openPricetScenario(value: string): Observable<IScenario> {
    return this.http
      .get<IScenario>(`${this.baseUrlScenario}/OpenScenario/${value}`)
      .pipe(catchError(this.handleError('openPricetScenario')));
  }

  public getScenarioTypes(): Observable<ScenarioType[]> {
    return this.http
      .get<ScenarioType[]>(this.baseUrlScenarioType + '/List')
      .pipe(catchError(this.handleError('getScenarioTypes')));
  }

  public deletePadbuilderImported(pricetMasterInputId: string): Observable<string> {
    return this.http
      .get<string>(`${this.baseUrlScenario}/DeletePadbuilderImported/${pricetMasterInputId}`)
      .pipe(catchError(this.handleError('deletePadbuilderImported')));
  }

  public deleteScenario(scenarioId: string): Observable<string> {
    return this.http
      .get<string>(`${this.baseUrlScenario}/DeleteByKey/${scenarioId}`)
      .pipe(catchError(this.handleError('deleteByKey')));
  }

  //////// Save methods //////////
  // POST: add a new padbuilder import to the server
  public savePadbuilder(pbImport: PadbuilderImport): Observable<PadbuilderImport> {
    return this.http
      .post<PadbuilderImport>(`${this.baseUrlScenario}/ImportFile`, pbImport, httpOptions)
      .pipe(catchError(this.handleErrorSavePadbuilder));
  }

  // POST: Import data from SMART
  public smartSequenceImport(pbImport: PadbuilderImport): Observable<PadbuilderImport> {
    return this.http
      .post<PadbuilderImport>(`${this.baseUrlSmart}/SequenceImport`, pbImport, httpOptions)
      .pipe(catchError(this.handleErrorSavePadbuilder));
  }

  // POST: Refresh Pads from Import data from SMART
  public refreshPadsSmartSequenceImport(pbImport: PadbuilderImport): Observable<PadbuilderImport> {
    return this.http
      .post<PadbuilderImport>(`${this.baseUrlSmart}/RefreshPadsFromSequenceImport`, pbImport, httpOptions)
      .pipe(catchError(this.handleError('RefreshPadsFromSequenceImport')));
  }

  // Method to save the complete scenario for PRICET
  public savePricetScenario(scenario: IScenario): Observable<IScenario> {
    return this.http
      .post<IScenario>(`${this.baseUrlScenario}/SaveScenario`, scenario, httpOptions)
      .pipe(catchError(this.handleError('savePricetScenario')));
  }

  public UploadScenarioCapex(scenarioId: string): any {
    let url = `${this.baseUrlSmart}/UploadScenarioCapex/${scenarioId}`;
    return this.http.get<any>(url, httpOptions).subscribe(data => console.log(data));
  }

  public getScenarios(): Observable<IViewPricetScenario[]> {
    return this.http.get(this.baseUrlScenario + '/ListViewScenario').pipe(
      map((data: IViewPricetScenario[]) => {
        return data
          .filter(s => s.name.trim() !== '' && !s.isDeleted)
          .map(item => {
            return {
              id: item.id,
              name: item.name,
              scenarioTypeName: item.scenarioTypeName,
              developmentAreaName: item.developmentAreaName,
              createdBy: item.createdBy,
              createdDate: item.createdDate !== null ? new Date(item.createdDate) : null,
              updatedBy: item.updatedBy,
              updatedDate: item.updatedDate !== null ? new Date(item.updatedDate) : null, // item.updatedDate,
              entityState: item.entityState,
              isSelected: false,
              isActiveForOutlook: item.isActiveForOutlook,
              isDeleted: item.isDeleted,
            } as IViewPricetScenario;
          });
      }),
      catchError(this.handleError('getScenarios'))
    );
  }

  public getItemDefaultCostByYear(itemCost: ItemCostModel): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrlFacilityItem}/GetDefaultCostByYear`, itemCost, httpOptions)
      .pipe(catchError(this.handleError('getItemDefaultCostByYear')));
  }

  public getSelectedScenariosInFile(ids: string[]): Observable<Blob> {
    return this.http
      .post<Blob>(`${this.baseUrlScenario}/GetSelected/`, ids, httpOptionsDownload)
      .pipe(catchError(this.handleError('getSelectedScenariosInFile')));
  }

  public getPadCostSummaryByScenarioId(scenarioId: string): Observable<Blob> {
    return this.http
      .post<Blob>(`${this.baseUrlScenario}/PadCostSummaryByScenarioId/`, [scenarioId], httpOptionsDownload)
      .pipe(catchError(this.handleError('getPadCostSummaryByScenarioId')));
  }

  public getReportOutData(ids: string[]): Observable<IReportOut[]> {
    return this.http
      .post<IReportOut[]>(`${this.baseUrlScenario}/BuildReportOut/`, JSON.stringify(ids), httpOptions)
      .pipe(catchError(this.handleError('getReportOutData')));
  }

  public generateReporByModel(reportOut: IReportOut[]): Observable<Blob> {
    return this.http
      .post<Blob>(`${this.baseUrlScenario}/GetReporOutByModel/`, reportOut, httpOptionsDownload)
      .pipe(catchError(this.handleError('getReporOutByModel')));
  }

  // Performance Domain
  public getFacilityItemsByYear(year: Number): Observable<FacilityItemModel[]> {
    return this.http.get<FacilityItemModel[]>(`${this.baseUrlFacilityItem}/ListCnDWithCostByYear/${year}`).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError('getFacilityItemsByYear'))
    );
  }

  public getCnDItemsAndDetails(): Observable<ItemAndDetailsModel[]> {
    return this.http.get<ItemAndDetailsModel[]>(`${this.baseUrlFacilityItem}/ListCnDItemsAndDetails/`).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError('getCnDItemsAndDetails'))
    );
  }

  public getUnitOfMeasureOptions(): Observable<UnitOfMeasure[]> {
    return this.http
      .get<UnitOfMeasure[]>(`${this.baseUrlUnitOfMeasure}/List/`)
      .pipe(catchError(this.handleError('getUnitOfMeasureOptions')));
  }

  public getScenarioPadBracketByScenarioId(scenarioId: string): Observable<ScenarioPadBracket[]> {
    return this.http
      .get<ScenarioPadBracket[]>(`${this.baseUrlScenarioPadBracket}/ListByScenarioId/${scenarioId}`)
      .pipe(catchError(this.handleError('ListByScenarioId')));
  }

  public CopyPads(pads: ICopyPads[]): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioPadBracket + '/CopyPads', JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('CopyPads')));
  }

  public DeletePads(pads: ScenarioPadBracket[]): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioPadBracket + '/Delete', JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('DeletePads')));
  }

  public SelectPads(pads: ISelectPads): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioWellSchedule + '/SelectPads', JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('SelectPads')));
  }

  public ImportPads(pads: ICopyPads[]): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioPadBracket + '/ImportPads', JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('ImportPads')));
  }

  public CheckExistingPads(pads: ICopyPads[]): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioPadBracket + '/CheckExistingPads', JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('CopyPads')));
  }

  public getCndCostMappingSftWithCosts(): Observable<ICndCostMappingSft[]> {
    return this.http
      .get<ICndCostMappingSft[]>(`${this.baseUrlFacilityItem}/getCndCostMappingSftWithCosts/`)
      .pipe(catchError(this.handleError('getCndCostMappingSftWithCosts')));
  }

  public overridePaNames(pads: any[]): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrlScenarioPadBracket}/OverridePadName`, JSON.stringify(pads), httpOptions)
      .pipe(catchError(this.handleError('overridePaNames')));
  }

  private handleErrorSavePadbuilder(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // tslint:disable-next-line: no-console
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // tslint:disable-next-line: no-console
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }

  public updateScenarioCost(scenarioId: string, year: string): Observable<any> {
    const scenarioCost = { scenarioId: scenarioId, year: year };

    return this.http
      .post<any>(
        `${this.baseUrlScenarioEquipment}/UpdateScenarioCostWithYear`,
        JSON.stringify(scenarioCost),
        httpOptions
      )
      .pipe();
  }

  public processScenarioPadCost(scenarioPadBrackets: any[]): Observable<any> {
    return this.http
      .post<any>(
        `${this.baseUrlScenarioPadCost}/ProcessScenarioPadCost`,
        JSON.stringify(scenarioPadBrackets),
        httpOptions
      )
      .pipe();
  }

  public getScenarioPadBracketCostByScenarioId(scenarioId: string): Observable<IScenarioPadBracketCost[]> {
    return this.http
      .get<IScenarioPadBracketCost[]>(`${this.baseUrlScenarioPadBracketCost}/ListByScenarioId/${scenarioId}`)
      .pipe(catchError(this.handleError('getScenarioPadBracketCostByScenarioId')));
  }

  public updateScenarioPadBracketCost(scenarioPadBracketCost: IScenarioPadBracketCost[]): Observable<any> {
    return this.http
      .post(this.baseUrlScenarioPadBracketCost + '/Update', JSON.stringify(scenarioPadBracketCost), httpOptions)
      .pipe(catchError(this.handleError('updateScenarioPadBracketCost')));
  }

  public exportFacilityAssignmentAndScopeCountByScenarioId(scenarioId: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.baseUrlScenario}/exportFacilityAssignmentAndScopeCountByScenarioId/${scenarioId}`,
        httpOptionsDownload
      )
      .pipe(catchError(this.handleError('exportFacilityAssignmentAndScopeCountByScenarioId')));
  }

  public saveMassImport(pbImport: CNDMassExcelImport): Observable<CNDMassExcelImport> {
    return this.http
      .post<CNDMassExcelImport>(`${this.baseUrlScenario}/ImportMassExcelFile`, pbImport, httpOptions)
      .pipe(catchError(this.handleErrorSavePadbuilder));
  }

  public getScenariosData(): Observable<IViewPricetScenario[]> {
    return this.http.get(this.baseUrlScenario + '/ListViewScenarioData').pipe(
      map((data: IViewPricetScenario[]) => {
        return data
          .filter(s => s.name.trim() !== '' && !s.isDeleted)
          .map(item => {
            return {
              id: item.id,
              name: item.name,
              scenarioTypeName: item.scenarioTypeName,
              developmentAreaName: item.developmentAreaName,
              createdBy: item.createdBy,
              createdDate: item.createdDate !== null ? new Date(item.createdDate) : null,
              updatedBy: item.updatedBy,
              updatedDate: item.updatedDate !== null ? new Date(item.updatedDate) : null, // item.updatedDate,
              entityState: item.entityState,
              isSelected: false,
              isActiveForOutlook: item.isActiveForOutlook,
              isDeleted: item.isDeleted,
            } as IViewPricetScenario;
          });
      }),
      catchError(this.handleError('getScenarios'))
    );
  }

  public getScenarioExpansionsByCAI(devName: string): Observable<any[]> {
    return this.http
      .get<any[]>(this.baseUrlScenario + `/ListExpansionsByCAI/` + `${devName}`)
      .pipe(catchError(this.handleError('ListExpansionsByCAI')));
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
  }
}
