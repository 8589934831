import { ComponentCanDeactivate } from './can-deactivate.component';
import { NgForm } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate {
  abstract get form(): NgForm;
  constructor() {
    super();
  }
  canDeactivate(): boolean {
    return false;
  }
}
