
<kendo-dialog title="Comments"
*ngIf="openDialog"
(close)="onCloseChangeRecord()"
[height]="300"
[width]="700">
</kendo-dialog>


<div style="text-align: right;">
  <h5>Status: <span class="status-highlighted">
      {{ currentStatus }}
  </span>
  </h5>
</div>
