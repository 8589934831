<div class="container-fluid">
  <div class="row mt-1">
    <div class="col-sm">
      <div class="float-right">
        <button kendoButton [primary]="true" class="k-button" (click)="saveFromDasSimple()" [disabled]="!canEditCnd">
          Save Changes
        </button>
      </div>
      <div class="float-right" style="padding-right: 5px;">
        <button kendoButton icon="file-excel" [primary]="true" (click)="exportToExcel()" [disabled]="isNewDasSimple">
          Export to Excel
        </button>
      </div>
    </div>
  </div>
</div>
<div>
  <div id="performancebod">
    <kendo-tabstrip #tabStrip [keepTabContent]="true" [tabPosition]="position" (tabSelect)="onTabSelect($event)">
      <kendo-tabstrip-tab [title]="'DAS Simple Summary'" [selected]="true">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="DAS Summary" expanded="true" [selected]="true">
                <ng-template kendoPanelBarContent>
                  <app-das-simple-details
                      (smartImportSequenceUpdate)="onSmartImportSequenceUpdate($event)"
                    ></app-das-simple-details>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="Notes & Attachments" expanded="true" [hidden]="true" >
                <ng-template kendoPanelBarContent>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'DAS Simple Inputs'" [selected]="false">
        <ng-template kendoTabContent>
          <div class="panelbar-wrapper">
            <br />
            <br />
            <kendo-panelbar [keepItemContent]="true">
              <kendo-panelbar-item title="Scope Assumptions" expanded="true" [selected]="true">
                <ng-template kendoPanelBarContent>
                  <app-das-simple-scope-assumptions></app-das-simple-scope-assumptions>
                </ng-template>
              </kendo-panelbar-item>
              <kendo-panelbar-item title="DAS Simple" expanded="true">
                <ng-template kendoPanelBarContent>
                  <app-das-simple-scope-count></app-das-simple-scope-count>
                </ng-template>
              </kendo-panelbar-item>
            </kendo-panelbar>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
