import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

const fieldNameStr = 'fieldName';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
        return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
      })
      .replace(/\s+/g, '')
      .replace('.', '_');
  }
  
  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return msie > 0 || trident > 0;
  }

  forEachPad(padConfigGridData: any[], fn: (padName: string) => void): void {
    for (const key in padConfigGridData[0]) {
      if (padConfigGridData[0].hasOwnProperty(key) && key !== fieldNameStr) {
        fn(key);
      }
    }
  }

  getMaxOfArray(array: any[], propKey: string) {
    const numArray = [];
    array.forEach(function(a) {
      numArray.push(a[propKey]);
    });

    return Math.round(Math.max.apply(null, numArray));
  }

  public markFormGroupAsTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  public getNoSpecialCharacters(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
        return letter.toUpperCase();
      })
      .replace(/\s+/g, '')
      .replace('.', '_');
  }

  public getFormGroupDirtyFieldsNamesAndValues(formGroup: FormGroup, namesDictionary: Map<string,string>){
    let fields = [];
    (<any>Object).values(formGroup.controls).forEach((control: AbstractControl) => {
      if(!control.pristine){
        const controlName = this.getControlName(control);
        fields.push({name: namesDictionary.get(controlName), value: control.value, controlName: controlName});
      }
    });
    return fields;
  }

  private getControlName(control: AbstractControl): string | null {
    let group = <FormGroup>control.parent;

    if (!group) {
      return null;
    }

    let name: string;

    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);

      if (childControl !== control) {
        return;
      }

      name = key;
    });

    return name;
  }

}
