import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { IViewPricetScenario } from '../../../api/viewPricetScenario';
import 'src/app/_shared/extends';

@Component({
  selector: 'app-scenario-data',
  templateUrl: './scenario-data.component.html',
  styleUrls: ['./scenario-data.component.css'],
})
export class ScenarioDataComponent implements OnInit {
  @ViewChild('notiftemplate', { read: TemplateRef })
  public notificationTemplate: TemplateRef<any>;
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc',
    },
  ];
  public scenarios: IViewPricetScenario[];
  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public fileUrl: string;
  public scenariosSelected: string[] = [];
  public openScenariosWithAutoPopDialog = false;
  public scenarioReportResultList: string[] = [];
  public isLoading = true;
  constructor(private dataService: DataService, private notificationService: NotificationService) {
    // empty
  }

  public scenarioSelectedKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  public ngOnInit(): void {
    this.dataService.getScenariosData().subscribe(
      data => {
        this.scenarios = data;
        this.gridData = process(this.scenarios, this.state);
        this.scenariosSelected = [];
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.notificationService.show({
          content: 'Error opening all Scenarios',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'info', icon: true },
        });
      }
    );
  }

  public onDataStateChange(state: any): void {
    this.state = state;
    this.gridData = process(this.scenarios, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridData = {
      data: orderBy(this.scenarios, this.sort),
      total: this.scenarios.length,
    };
  }
}
