import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, concat, throwError } from 'rxjs';
import clonedeep from 'lodash.clonedeep';
import { Guid } from 'guid-typescript';
import { Basin } from '../api/basin';
import { State } from '../api/state';
import { Rfsd } from '../api/rfsd';
import { IDevelopmentArea } from '../api/devArea';
import { GeographicArea } from '../api/geographicArea';
import { LandDamagesPipe } from '../api/landDamagesPipe';
import { LandDamagesOther } from '../api/landDamagesOther';
import { ICompressorCapacityAssumptions } from '../api/compressorCapacityAssumptions';
import { InfrastructureClass } from '../api/infrastructure-class';
import { InfrastructureScopeCategory } from '../api/infrastructure-scope-category';
import { Location } from '../api/location';
import { Kind } from '../api/kind';
import { ScenarioPbSummary } from '../api/padbuilder-master';
import { IScenario, IPricetScenarioSummary } from '../api/pricetScenario';
import { ScenarioType } from '../api/scenarioType';
import { IReportOut } from '../api/reportout';
import { ScenarioPadEquipmentScopeCountModel } from '../api/padItemInventory';
import { InputItem, ItemCostModel, ItemAndDetailsModel, keyFacilityItem } from '../api/input-item';
import { PadContingencyAssumptions } from '../api/padContingencyAssumptions';
import { IPadBracketAndFacility } from '../api/padBracketAndFacility';
import {
  InputFacilityAssignmentModel,
  IPadBracketFields,
} from '../modules/cAndD/input-facility-assignment/input-facility-assignment.model';
import { InputItemsModel } from '../modules/cAndD/input-items/input-items.model';
import { ContingencyModel } from '../modules/cAndD/contingency/contingency-model';
import { InputSummaryModel } from '../modules/cAndD/input-summary/input-summary-model';
import { DataService } from './data.service';
import { OverriddenFieldsService } from './overridden-fields.service';
import { InputItemsService } from '../modules/cAndD/input-items/input-items.service';
import { InputFacilityAssignmentService } from '../modules/cAndD/input-facility-assignment/input-facility-assignment.service';
import { OpenScenarioService } from '../modules/cAndD/open-scenario/open-scenario.service';
import { PagerService } from '../modules/cAndD/pager/pager.service';
import { PagerModel } from '../modules/cAndD/pager/pager.model';
import { UnitOfMeasure } from '../api/unitOfMeasure-model';
import { AuthenticationService } from '.';
import { ICndCostMappingSft } from '../api/cnd-cost-mapping-sft';
import { catchError, map, take } from 'rxjs/operators';
import { ICostLogicDetector } from '../api/cost-logic-detector';
import { IScenarioPadBracketCost } from '../api/scenario-pad-bracket-cost';
import { isTryStatement } from 'typescript';
import { promise } from 'protractor';
import { resolve } from 'dns';
import { ScenarioAfeType } from '../api/scenarioAfeType';

@Injectable({
  providedIn: 'root',
})
export class ScenarioStoreService {
  public isScenarioCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isUpdateItemDetailLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  public padConfigData$: Observable<any[]>;
  public padBuilderData$: Observable<any[]>;
  public padBuilderDataPage$: Observable<any[]>;
  public facilityPadBuilderData$: Observable<any[]>;
  public facilityPadBuilderDataPage$: Observable<any[]>;
  public scopeCountPadBuilderData$: Observable<any[]>;
  public scopeCountPadBuilderDataPage$: Observable<any[]>;
  public costSheetPadBuilderData$: Observable<any[]>;
  public costSheetPadBuilderDataPage$: Observable<any[]>;
  public summaryPadBuilderData$: Observable<any[]>;
  public summaryPadBuilderDataPage$: Observable<any[]>;
  public padPager$: Observable<PagerModel>;
  public facilityPadPager$: Observable<PagerModel>;
  public scopeCountPadPager$: Observable<PagerModel>;
  public costSheetPadPager$: Observable<PagerModel>;
  public summaryPadPager$: Observable<PagerModel>;
  public contingency$: Observable<ContingencyModel>;
  public infrastructureItems$: Observable<InputItemsModel>;
  public inputSummary$: Observable<InputSummaryModel>;
  public ScenarioPbSummary$: Observable<ScenarioPbSummary>;
  public rfsdOptions$: Observable<Rfsd[]>;
  public unitOfMeasureOptions$: Observable<UnitOfMeasure[]>;
  public infrastructureClassOptions$: Observable<InfrastructureClass[]>;
  public scopeCategoryOptions$: Observable<InfrastructureScopeCategory[]>;
  public scopeCategoryOptionsAll$: Observable<InfrastructureScopeCategory[]>;
  public locationOptions$: Observable<Location[]>;
  public kindOptions$: Observable<Kind[]>;
  public itemsAndDetailsOptions$: Observable<ItemAndDetailsModel[]>;
  public developmentAreaOptions$: Observable<IDevelopmentArea[]>;
  public scenarioTypeOptions$: Observable<ScenarioType[]>;
  public stateOptions$: Observable<State[]>;
  public basinOptions$: Observable<Basin[]>;
  public geographicArea$: Observable<GeographicArea[]>;
  public landDamagesPipeData$: Observable<LandDamagesPipe[]>;
  public landDamagesOtherData$: Observable<LandDamagesOther[]>;
  public contingencyOptions$: Observable<PadContingencyAssumptions[]>;
  public compressorCapacityAssumptionsOptions$: Observable<ICompressorCapacityAssumptions[]>;
  public scenarioID$: Observable<string>;
  public newScenario$: Observable<boolean>;
  public importExecuted$: Observable<boolean>;
  public tryExport$: Observable<boolean>;
  public showSavePopUp$: Observable<boolean>;
  public showSavePopUpMessage$: Observable<string>;
  public year$: Observable<number>;
  public scenarioPadBracketCosts$: Observable<IScenarioPadBracketCost[]>;
  public loadSpinner$: Observable<boolean>;

  public newBOD$: Observable<boolean>;
  public pricetSummary$: Observable<IPricetScenarioSummary>;
  public changeTab$: Observable<number>;
  public reportOut$: Observable<IReportOut[]>;
  public padByPadCostUpdate$: Observable<any>;

  public showSavePopUpBs$: BehaviorSubject<boolean>;
  public showSavePopUpMessageBs$: BehaviorSubject<string>;

  public newBodBs$: BehaviorSubject<boolean>;
  public errorOpenScenario: any = {};
  public errorInitPricet: boolean;
  public readonly PadFields: IPadBracketFields;
  public scenario: IScenario;
  public contingencyValue: number;
  public readonly hiddenRows: string[];
  public cndCostMappingSft$: Observable<ICndCostMappingSft[]>;
  public sequenceId$: Observable<string>;
  public costLogicDetector$: Observable<ICostLogicDetector>;
  private padByPadCostUpdateBs$: BehaviorSubject<any>;
  private changeTabBs$: BehaviorSubject<number>;
  private reportOutBs$: BehaviorSubject<IReportOut[]>;
  private pricetSummaryBs$: BehaviorSubject<IPricetScenarioSummary>;
  private contingencyBs$: BehaviorSubject<ContingencyModel>;
  private padConfigDataBs$: BehaviorSubject<any[]>;
  private padBuilderDataBs$: BehaviorSubject<any[]>;
  private padBuilderDataPageBs$: BehaviorSubject<any[]>;
  private facilityPadBuilderDataBs$: BehaviorSubject<any[]>;
  private facilityPadBuilderDataPageBs$: BehaviorSubject<any[]>;
  private scopeCountPadBuilderDataBs$: BehaviorSubject<any[]>;
  private scopeCountPadBuilderDataPageBs$: BehaviorSubject<any[]>;
  private costSheetPadBuilderDataBs$: BehaviorSubject<any[]>;
  private costSheetPadBuilderDataPageBs$: BehaviorSubject<any[]>;
  private summaryPadBuilderDataBs$: BehaviorSubject<any[]>;
  private summaryPadBuilderDataPageBs$: BehaviorSubject<any[]>;
  private padPagerBs$: BehaviorSubject<PagerModel>;
  private facilityPadPagerBs$: BehaviorSubject<PagerModel>;
  private scopeCountPadPagerBs$: BehaviorSubject<PagerModel>;
  private costSheetPadPagerBs$: BehaviorSubject<PagerModel>;
  private summaryPadPagerBs$: BehaviorSubject<PagerModel>;
  private infrastructureItemsBs$: BehaviorSubject<InputItemsModel>;
  private inputSummaryBs$: BehaviorSubject<InputSummaryModel>;
  private scenarioPbSummaryBs$: BehaviorSubject<ScenarioPbSummary>;
  private rfsdOptionsBs$: BehaviorSubject<Rfsd[]>;
  private unitOfMeasureOptionsBs$: BehaviorSubject<UnitOfMeasure[]>;
  private infrastructureClassOptionsBs$: BehaviorSubject<InfrastructureClass[]>;
  private scopeCategoryOptionsBs$: BehaviorSubject<InfrastructureScopeCategory[]>;
  private scopeCategoryOptionsAllBs$: BehaviorSubject<InfrastructureScopeCategory[]>;
  private locationOptionsBs$: BehaviorSubject<Location[]>;
  private kindOptionsBs$: BehaviorSubject<Kind[]>;
  private itemsAndDetailsOptionsBs$: BehaviorSubject<ItemAndDetailsModel[]>;
  private developmentAreaOptionsBs$: BehaviorSubject<IDevelopmentArea[]>;
  private scenarioTypeOptionsBs$: BehaviorSubject<ScenarioType[]>;
  private stateOptionsBs$: BehaviorSubject<State[]>;
  private basinOptionsBs$: BehaviorSubject<Basin[]>;
  private geographicAreaBs$: BehaviorSubject<GeographicArea[]>;
  private landDamagesPipeDataBs$: BehaviorSubject<LandDamagesPipe[]>;
  private landDamagesOtherDataBs$: BehaviorSubject<LandDamagesOther[]>;
  private contingencyOptionsBs$: BehaviorSubject<PadContingencyAssumptions[]>;
  private compressorCapacityAssumptionsOptionsBs$: BehaviorSubject<ICompressorCapacityAssumptions[]>;
  private newScenarioBs$: BehaviorSubject<boolean>;
  private importExecutedBs$: BehaviorSubject<boolean>;
  private tryExportBs$: BehaviorSubject<boolean>;
  private scenarioIdBs$: BehaviorSubject<string>;
  private yearBs$: BehaviorSubject<number>;
  private cndCostMappingSftBs$: BehaviorSubject<ICndCostMappingSft[]>;
  private sequenceIdBs$: BehaviorSubject<string>;
  private costLogicDetectorBs$: BehaviorSubject<ICostLogicDetector>;
  public scenarioPadBracketCostsBs$: BehaviorSubject<IScenarioPadBracketCost[]>;
  private loadSpinnerBs$: BehaviorSubject<boolean>;
  public scenarioAfeTypeOptions$: Observable<ScenarioAfeType[]>;
  private scenarioAfeTypeOptionsBs$: BehaviorSubject<ScenarioAfeType[]>;

  constructor(
    private dataService: DataService,
    private inputFacilityAssignmentService: InputFacilityAssignmentService,
    private overriddenFieldsService: OverriddenFieldsService,
    private inputItemsService: InputItemsService,
    private pagerService: PagerService,
    private openScenarioService: OpenScenarioService,
    private authenticationService: AuthenticationService
  ) {
    this.errorInitPricet = false;
    this.PadFields = InputFacilityAssignmentModel.IPadFields;
    this.hiddenRows = [
      'CTB Train - 12000 BOPD',
      'CTB Train - 8500 BOPD',
      'CTB Train - 1000 BOPD (Single Well)',
      'Upgrade CTB Inlet Prod. Sep. to 3-Phase',
      'Adder for Startup Dehy Tower',
      'CS Brownfield Adder',
      'SWD Train - 15000 BWPD',
      'Condensate Stabilizer',
    ];

    this.initCatalogsObservables(false);
    this.initTransactionalObservables(false);
    this.initializeMasterData();
    this.cndCostMappingSftBs$ = new BehaviorSubject([]);
    this.cndCostMappingSft$ = this.cndCostMappingSftBs$.asObservable();
    this.initializeCndMappingCost();
  }

  public initializeItemsGrid(): void {
    this.dataService.getFacilityItemsByYear(this.yearBs$.value).subscribe(facItemData => {
      // Used to set the related data of C&D Domain
      const rfsds: Rfsd[] = clonedeep(this.rfsdOptionsBs$.value);
      const scopeCategories: InfrastructureScopeCategory[] = clonedeep(this.scopeCategoryOptionsAllBs$.value);
      const locations: Location[] = clonedeep(this.locationOptionsBs$.value);
      const kinds: Kind[] = clonedeep(this.kindOptionsBs$.value);
      const infrastructureClasses: InfrastructureClass[] = clonedeep(this.infrastructureClassOptionsBs$.value);
      const unitOfMeasures: UnitOfMeasure[] = clonedeep(this.unitOfMeasureOptionsBs$.value);
      const scenarioAfeType: ScenarioAfeType[] = clonedeep(this.scenarioAfeTypeOptionsBs$.value);
      // > 1) Items: Transform Facility Item model to UI Items and Detail logic, source Performance Domain
      const infraItemsModel = clonedeep(this.infrastructureItemsBs$.value);
      infraItemsModel.inputItems = this.inputItemsService.transformFacilityItemsToLoad(
        facItemData,
        rfsds,
        scopeCategories,
        locations,
        kinds,
        infrastructureClasses,
        unitOfMeasures,
        scenarioAfeType
      );
      // > 2) Pads
      infraItemsModel.pads = clonedeep(this.padBuilderDataBs$.value);
      this.addPadColumns(infraItemsModel.inputItems);
      this.infrastructureItemsBs$.next(infraItemsModel);
      if (this.isReadyForCalculation()) {
        this.calculatePadFields();
        this.calculatePadItemCounts();
        let costLogicDetector = this.costLogicDetectorBs$.value;
        costLogicDetector.isInfrastructureItemsReady = true;
        costLogicDetector.isPadBuilderDataReady = true;
        this.costLogicDetectorBs$.next(costLogicDetector);
      }
    });
  }

  public initializeMasterData(): void {   
    this.dataService.getRfsdOptions().subscribe(
      data => {
        this.rfsdOptionsBs$.next([]);
        this.rfsdOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );

    this.dataService.getUnitOfMeasureOptions().subscribe(
      data => {
        this.unitOfMeasureOptionsBs$.next([]);
        this.unitOfMeasureOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );

    this.dataService.getInfrastructureClassOptions().subscribe(
      data => {
        this.infrastructureClassOptionsBs$.next([]);
        this.infrastructureClassOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getScopeCategoryOptionsToAdditionalRows().subscribe(
      data => {
        this.scopeCategoryOptionsBs$.next([]);
        this.scopeCategoryOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );

    this.dataService.getScopeCategoryOptions().subscribe(
      data => {
        this.scopeCategoryOptionsAllBs$.next([]);
        this.scopeCategoryOptionsAllBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getLocationOptions().subscribe(
      data => {
        this.locationOptionsBs$.next([]);
        this.locationOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getKindOptions().subscribe(
      data => {
        this.kindOptionsBs$.next([]);
        this.kindOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getCnDItemsAndDetails().subscribe(
      data => {
        this.itemsAndDetailsOptionsBs$.next([]);
        this.itemsAndDetailsOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );

    this.dataService.getScenarioAfeTypeOptions().subscribe(
      data => {
        this.scenarioAfeTypeOptionsBs$.next([]);
        this.scenarioAfeTypeOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );

    let devAreasData: IDevelopmentArea[], devAreasDeletedData: IDevelopmentArea[];
    const getDevAreas = this.dataService.getDevArea().pipe(
      map((devAreas: IDevelopmentArea[]) => {
        devAreasData = devAreas;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get available Dev Areas.');
      }),
      take(1)
    );

    const getDevAreasDeleted = this.dataService.getDeletedDevArea().pipe(
      map((devAreas: IDevelopmentArea[]) => {
        devAreasDeletedData = devAreas;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get rest of Dev Areas.');
      }),
      take(1)
    );

    concat(getDevAreas, getDevAreasDeleted)
      .toPromise()
      .then(() => {
        devAreasData = devAreasData.concat(devAreasDeletedData);
        this.developmentAreaOptionsBs$.next([]);
        this.developmentAreaOptionsBs$.next(devAreasData);
        this.errorInitPricet = false;
      })
      .catch((reason: any) => {
        this.errorInitPricet = true;
      });

    this.dataService.getScenarioTypes().subscribe(
      data => {
        this.scenarioTypeOptionsBs$.next([]);
        this.scenarioTypeOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getState().subscribe(
      data => {
        this.stateOptionsBs$.next([]);
        this.stateOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    let basinData: Basin[], basinDeletedData: Basin[];

    const getBasins = this.dataService.getBasin().pipe(
      map((basins: Basin[]) => {
        basinData = basins;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get available Dev Areas.');
      }),
      take(1)
    );

    const getBasinsDeleted = this.dataService.getDeletedBasin().pipe(
      map((basins: Basin[]) => {
        basinDeletedData = basins;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get rest of Dev Areas.');
      }),
      take(1)
    );

    concat(getBasins, getBasinsDeleted)
      .toPromise()
      .then(() => {
        basinData = basinData.concat(basinDeletedData);
        this.basinOptionsBs$.next([]);
        this.basinOptionsBs$.next(basinData);
        this.errorInitPricet = false;
      })
      .catch((reason: any) => {
        this.errorInitPricet = true;
      });

    this.dataService.getBasin().subscribe(
      data => {
        this.basinOptionsBs$.next([]);
        this.basinOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    let geographicAreasData: GeographicArea[], geographicAreasDeletedData: GeographicArea[];
    const getGeographicAreas = this.dataService.getGeographicArea().pipe(
      map((geographicAreas: GeographicArea[]) => {
        geographicAreasData = geographicAreas;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get available Dev Areas.');
      }),
      take(1)
    );

    const getGeographicAreasDeleted = this.dataService.getDeletedGeographicArea().pipe(
      map((geographicAreas: GeographicArea[]) => {
        geographicAreasDeletedData = geographicAreas;
      }),
      catchError(() => {
        return throwError('An error has ocurred: Unable to get rest of Dev Areas.');
      }),
      take(1)
    );

    concat(getGeographicAreas, getGeographicAreasDeleted)
      .toPromise()
      .then(() => {
        geographicAreasData = geographicAreasData.concat(geographicAreasDeletedData);
        this.geographicAreaBs$.next([]);
        this.geographicAreaBs$.next(geographicAreasData);
        this.errorInitPricet = false;
      })
      .catch((reason: any) => {
        this.errorInitPricet = true;
      });

    this.dataService.getLandDamagesPipe().subscribe(
      data => {
        this.landDamagesPipeDataBs$.next([]);
        this.landDamagesPipeDataBs$.next(data);
        this.calculatePadItemCounts();
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getLandDamagesOther().subscribe(
      data => {
        this.landDamagesOtherDataBs$.next([]);
        this.landDamagesOtherDataBs$.next(data);
        this.calculatePadItemCounts();
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getPadContingencyAssumptions().subscribe(
      (data: PadContingencyAssumptions[]) => {
        this.contingencyOptionsBs$.next([]);
        this.contingencyOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
    this.dataService.getCompressorCapacityAssumptions().subscribe(
      (data: ICompressorCapacityAssumptions[]) => {
        this.compressorCapacityAssumptionsOptionsBs$.next([]);
        this.compressorCapacityAssumptionsOptionsBs$.next(data);
        this.errorInitPricet = false;
      },
      err => {
        this.errorInitPricet = true;
      }
    );
  }

  public initializePadPager(): void {
    this.changePadPage(1, this.padPagerBs$.value.pageSize);
    const padPages = this.pagerService.calculatePages(this.padBuilderDataBs$.value, this.padPagerBs$.value.pageSize);
    this.padPagerBs$.next({
      currentPage: 1,
      pageSize: this.padPagerBs$.value.pageSize,
      pages: padPages,
      total: this.padBuilderDataBs$.value.length,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.changeFacilityPadPage(1, this.facilityPadPagerBs$.value.pageSize);
    const fPadPages = this.pagerService.calculatePages(this.facilityPadBuilderDataBs$.value, this.facilityPadPagerBs$.value.pageSize);
    this.facilityPadPagerBs$.next({
      currentPage: 1,
      pageSize: this.facilityPadPagerBs$.value.pageSize,
      pages: fPadPages,
      total: this.facilityPadBuilderDataBs$.value.length,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });

    this.changeScopePadPage(1, this.scopeCountPadPagerBs$.value.pageSize);
    const sPadPages = this.pagerService.calculatePages(this.scopeCountPadBuilderDataBs$.value, this.scopeCountPadPagerBs$.value.pageSize);
    this.scopeCountPadPagerBs$.next({
      currentPage: 1,
      pageSize: this.scopeCountPadPagerBs$.value.pageSize,
      pages: sPadPages,
      total: this.scopeCountPadBuilderDataBs$.value.length,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });

    this.changeCostSheetPadPage(1, this.costSheetPadPagerBs$.value.pageSize);
    const cPadPages = this.pagerService.calculatePages(this.costSheetPadBuilderDataBs$.value, this.costSheetPadPagerBs$.value.pageSize);
    this.costSheetPadPagerBs$.next({
      currentPage: 1,
      pageSize: this.costSheetPadPagerBs$.value.pageSize,
      pages: cPadPages,
      total: this.costSheetPadBuilderDataBs$.value.length,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });

    this.changeSummaryPadPage(1, this.summaryPadPagerBs$.value.pageSize);
    const smryPadPages = this.pagerService.calculatePages(this.summaryPadBuilderDataBs$.value, this.summaryPadPagerBs$.value.pageSize);
    this.summaryPadPagerBs$.next({
      currentPage: 1,
      pageSize: this.summaryPadPagerBs$.value.pageSize,
      pages: smryPadPages,
      total: this.summaryPadBuilderDataBs$.value.length,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    
  }

  public initializePricetSummary(scenario: IScenario): void {
    this.pricetSummaryBs$.next({
      id: scenario.id,
      developmentArea:
        this.inputSummaryBs$.value.developmentArea === null ? '' : this.inputSummaryBs$.value.developmentArea.name,
      scenarioType: this.inputSummaryBs$.value.scenarioType.name,
      scenarioName: scenario.name,
      handover: scenario.handoverNumber,
      contingency: scenario.contingencyPercentage,
      state: this.stateOptionsBs$.value.filter(s => s.id === scenario.stateId)[0]?.name,
      tax: scenario.taxRate,
      basin: this.basinOptionsBs$.value.filter(b => b.id === scenario.basinId)[0]?.name,
      geographicArea: this.geographicAreaBs$.value.filter(g => g.id === scenario.geographicAreaId)[0]?.name,
      scenarioNotes: scenario.scenarioNotes,
      year: scenario.costYear,
    });

    //this.padBuilderDataPageBs$.next(this.padBuilderDataPageBs$.value);
  }

  public addPadColumns(inputItems: any[]): void {
    const padConfigData = clonedeep(this.padConfigDataBs$.value);
    this.inputItemsService.generatePadCountColumns(inputItems, padConfigData);
  }

  public greenfieldInstallTimeframeChanged(value: number): void {
    const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value);
    inputSummary.greenfieldInstallTimeframe = value;
    this.inputSummaryBs$.next(inputSummary);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public assumedNumWellsPerTestSeparatorChanged(value: number): void {
    const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value);
    inputSummary.assumedNumWellsPerTestSeparator = value;
    this.inputSummaryBs$.next(inputSummary);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public calculatePadFields(): void {
    if (this.newScenarioBs$.value === false) {
      const padConfigData = clonedeep(this.padConfigDataBs$.value);
      this.inputFacilityAssignmentService.calculateRfsd(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.targetSatellite,
        InputFacilityAssignmentModel.IPadFields.tragetSatelliteRfsd
      );
      this.inputFacilityAssignmentService.calculateRfsd(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.targetCtb,
        InputFacilityAssignmentModel.IPadFields.tragetCtbRfsd
      );
      this.inputFacilityAssignmentService.calculateRfsd(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.associatedCompStation,
        InputFacilityAssignmentModel.IPadFields.compressionRfsd
      );
      this.inputFacilityAssignmentService.calculateRfsd(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.associatedSwdFacility,
        InputFacilityAssignmentModel.IPadFields.waterDisposalRfsd
      );
      this.inputFacilityAssignmentService.calculateRfsd(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.fracPond,
        InputFacilityAssignmentModel.IPadFields.fracPondRfsd
      );
      this.updatePadOrder(padConfigData); // never changes?
      this.inputFacilityAssignmentService.calculateExecutionCategories(
        padConfigData,
        this.inputSummaryBs$.value.greenfieldInstallTimeframe
      );
      this.inputFacilityAssignmentService.calculateWellTestLocations(padConfigData);
      this.inputFacilityAssignmentService.calculateCumulativeWellCounts(padConfigData);
      const inputSummaryContingency = this.inputSummaryBs$.value.contingency;
      this.inputFacilityAssignmentService.calculateContingencyPerPad(padConfigData, inputSummaryContingency);
      this.padConfigDataBs$.next(padConfigData);
      const padList = this.inputFacilityAssignmentService.sortPadsByDate(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.popDate
      );
      const infraItemsModel: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
      infraItemsModel.pads = padList;
      this.infrastructureItemsBs$.next(infraItemsModel);
    }
  }

  public updatePadOrder(padConfigData: any[]): void {
    const sortedPads = this.inputFacilityAssignmentService.sortPadsByDate(
      padConfigData,
      InputFacilityAssignmentModel.IPadFields.popDate
    );
    this.inputFacilityAssignmentService.updatePadOrder(padConfigData, sortedPads);
  }

  public calculatedContingencyChanged(data: ContingencyModel): void {
    this.contingencyBs$.next(data);
    if (!this.overriddenFieldsService.summaryContingency) {
      const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value);
      inputSummary.contingency = data.valuePercentage;
      this.inputSummaryBs$.next(inputSummary);
      this.calculatePadFields();
      this.calculatePadItemCounts();
    }
    this.pricetSummaryBs$.next({
      id: this.pricetSummaryBs$.value.id,
      developmentArea:
        this.inputSummaryBs$.value.developmentArea === null ? '' : this.inputSummaryBs$.value.developmentArea.name,
      scenarioType:
        this.inputSummaryBs$.value.scenarioType === null ? '' : this.inputSummaryBs$.value.scenarioType.name,
      scenarioName: this.inputSummaryBs$.value.scenarioName,
      handover: this.inputSummaryBs$.value.handoverNumber,
      contingency: this.inputSummaryBs$.value.contingency,
      state: this.pricetSummaryBs$.value.state,
      tax: this.pricetSummaryBs$.value.tax,
      basin: this.pricetSummaryBs$.value.basin,
      geographicArea:
        this.inputSummaryBs$.value.geographicArea === null
          ? this.pricetSummaryBs$.value.geographicArea
          : this.inputSummaryBs$.value.geographicArea.name,
      scenarioNotes: this.pricetSummaryBs$.value.scenarioNotes,
      year: this.pricetSummaryBs$.value.year,
    });
  }

  public pricetNotesSummaryUpdated(pricetNotes: string): void {
    this.pricetSummaryBs$.next({
      id: this.pricetSummaryBs$.value.id,
      developmentArea: this.pricetSummaryBs$.value.developmentArea,
      scenarioType: this.pricetSummaryBs$.value.scenarioType,
      scenarioName: this.pricetSummaryBs$.value.scenarioName,
      handover: this.pricetSummaryBs$.value.handover,
      contingency: this.pricetSummaryBs$.value.contingency,
      state: this.pricetSummaryBs$.value.state,
      tax: this.pricetSummaryBs$.value.tax,
      basin: this.pricetSummaryBs$.value.basin,
      geographicArea: this.pricetSummaryBs$.value.geographicArea,
      scenarioNotes: pricetNotes,
      year: this.pricetSummaryBs$.value.year,
    });
  }

  public inputSummaryUpdated(data: InputSummaryModel): void {
    const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value, data);
    this.inputSummaryBs$.next(inputSummary);
    this.pricetSummaryBs$.next({
      id: this.pricetSummaryBs$.value.id,
      developmentArea:
        this.inputSummaryBs$.value.developmentArea === null ? '' : this.inputSummaryBs$.value.developmentArea.name,
      scenarioType:
        this.inputSummaryBs$.value.scenarioType === null ? '' : this.inputSummaryBs$.value.scenarioType.name,
      scenarioName: this.inputSummaryBs$.value.scenarioName,
      handover: this.inputSummaryBs$.value.handoverNumber,
      contingency: inputSummary.contingency,
      state: this.inputSummaryBs$.value.state.name,
      tax: this.inputSummaryBs$.value.state.taxRate,
      basin: this.inputSummaryBs$.value.basin.name,
      geographicArea: this.inputSummaryBs$.value.geographicArea.name,
      scenarioNotes: this.pricetSummaryBs$.value.scenarioNotes,
      year: this.pricetSummaryBs$.value.year,
    });
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public isInputSummaryContingencyOverryiden(): boolean {
    return this.overriddenFieldsService.summaryContingency;
  }

  public inputSummaryContingencyOverridden(value: number): void {
    const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value);
    inputSummary.contingency = value;
    this.overriddenFieldsService.summaryContingency = true;
    this.inputSummaryBs$.next(inputSummary);
    this.pricetSummaryBs$.next({
      id: this.pricetSummaryBs$.value.id,
      developmentArea:
        this.inputSummaryBs$.value.developmentArea === null ? '' : this.inputSummaryBs$.value.developmentArea.name,
      scenarioType:
        this.inputSummaryBs$.value.scenarioType === null ? '' : this.inputSummaryBs$.value.scenarioType.name,
      scenarioName: this.inputSummaryBs$.value.scenarioName,
      handover: this.inputSummaryBs$.value.handoverNumber,
      contingency: inputSummary.contingency,
      state: this.pricetSummaryBs$.value.state,
      tax: this.pricetSummaryBs$.value.tax,
      basin: this.pricetSummaryBs$.value.basin,
      geographicArea:
        this.inputSummaryBs$.value.geographicArea === null
          ? this.pricetSummaryBs$.value.geographicArea
          : this.inputSummaryBs$.value.geographicArea.name,
      scenarioNotes: this.pricetSummaryBs$.value.scenarioNotes,
      year: this.pricetSummaryBs$.value.year,
    });
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public clearOverrideInputSummaryContingency(): void {
    this.overriddenFieldsService.summaryContingency = false;
    const inputSummary: InputSummaryModel = Object.assign({}, this.inputSummaryBs$.value);
    inputSummary.contingency = this.contingencyBs$.value.valuePercentage;
    this.inputSummaryBs$.next(inputSummary);
    this.pricetSummaryBs$.next({
      id: this.pricetSummaryBs$.value.id,
      developmentArea:
        this.inputSummaryBs$.value.developmentArea === null ? '' : this.inputSummaryBs$.value.developmentArea.name,
      scenarioType:
        this.inputSummaryBs$.value.scenarioType === null ? '' : this.inputSummaryBs$.value.scenarioType.name,
      scenarioName: this.inputSummaryBs$.value.scenarioName,
      handover: this.inputSummaryBs$.value.handoverNumber,
      contingency: inputSummary.contingency,
      state: this.pricetSummaryBs$.value.state,
      tax: this.pricetSummaryBs$.value.tax,
      basin: this.pricetSummaryBs$.value.basin,
      geographicArea: this.pricetSummaryBs$.value.geographicArea,
      scenarioNotes: this.pricetSummaryBs$.value.scenarioNotes,
      year: this.pricetSummaryBs$.value.year,
    });
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public padConfigFieldOverridden(padName: string, fieldName: string): void {
    this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, true);
  }

  public clearOverridePadConfigField(padName: string, fieldName: string): void {
    this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, false);
  }

  public updatePadConfigField(padName: string, fieldName: string, value: any): void {
    const padConfig = clonedeep(this.padConfigDataBs$.value);
    const contingency = this.inputSummaryBs$.value.contingency;
    this.inputFacilityAssignmentService.setPadFieldValue(padConfig, padName, fieldName, value);
    if (
      fieldName === InputFacilityAssignmentModel.IPadFields.satelliteExecutionCategory ||
      fieldName === InputFacilityAssignmentModel.IPadFields.ctbExecutionCategory ||
      fieldName === InputFacilityAssignmentModel.IPadFields.compExecutionCategory ||
      fieldName === InputFacilityAssignmentModel.IPadFields.swdExecutionCategory ||
      fieldName === InputFacilityAssignmentModel.IPadFields.pondExecutionCategory
    ) {
      if (value !== 'Calculated') {
        this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, true);
      } else {
        this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, false);
      }
    }
    if (
      fieldName === InputFacilityAssignmentModel.IPadFields.wellTestLocation ||
      fieldName === InputFacilityAssignmentModel.IPadFields.compressionRfsd ||
      fieldName === InputFacilityAssignmentModel.IPadFields.waterDisposalRfsd ||
      fieldName === InputFacilityAssignmentModel.IPadFields.fracPondRfsd ||
      fieldName === InputFacilityAssignmentModel.IPadFields.padContingency ||
      fieldName === InputFacilityAssignmentModel.IPadFields.tragetSatelliteRfsd ||
      fieldName === InputFacilityAssignmentModel.IPadFields.tragetCtbRfsd
    ) {
      const padContingency: number = value;
      if ((value && padContingency !== contingency) || padContingency === 0) {
        this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, true);
      } else if ((value && padContingency !== contingency) || padContingency === 0) {
        this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, true);
      } else {
        this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, false);
      }
    }

    if (
      fieldName === InputFacilityAssignmentModel.IPadFields.cumulativeWellCount ||
      fieldName === InputFacilityAssignmentModel.IPadFields.cumulativeHeaderSlots ||
      fieldName === InputFacilityAssignmentModel.IPadFields.cumulativeTestSeparators
    ) {
      const countValue: number = value;
      this.overriddenFieldsService.setPadConfigFieldOverridden(padName, fieldName, countValue != null);
    }

    this.padConfigDataBs$.next(padConfig);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public updatePadItemCount(padName: string, itemName: string, dataItem: any, count: number): void {
    const itemId = dataItem.id;
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    if (count === null) {
      this.overriddenFieldsService.setPadItemCountOverridden(padName, itemName, itemId, false);
    } else {
      this.overriddenFieldsService.setPadItemCountOverridden(padName, itemName, itemId, true);
      this.inputItemsService.setInputItemsCount(infraItems.inputItems, padName, itemName, count, itemId,dataItem.respToExpandFacilityItemId);
    }
    this.infrastructureItemsBs$.next(infraItems);
    this.calculatePadFields();
    this.calculatePadItemCounts(false, itemId);
  }

  public updateAdditionalItemCount(padName: string, itemDisplayName: string, count: number): void {
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    this.inputItemsService.setAdditionalItemCount(infraItems.inputItems, padName, itemDisplayName, count);
    this.infrastructureItemsBs$.next(infraItems);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public updatedInfraItemsGrid(): void {
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public calculatePadItemCounts(isCostUnitOverridden: boolean = false, itemId: string = ''): void {
    if (this.newScenarioBs$.value === false) {
      let padConfigData = clonedeep(this.padConfigDataBs$.value);
      const infraItemsModel: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
      const landDamagesOtherData: LandDamagesOther[] = clonedeep(this.landDamagesOtherDataBs$.value);
      const landDamagesPipeData: LandDamagesPipe[] = clonedeep(this.landDamagesPipeDataBs$.value);
      let orderedPadConfigs = infraItemsModel.pads;
      const padNames = infraItemsModel.pads.map(pad => pad.padName);

      this.inputItemsService.calculateGreenfield1TrainCtbInstall(infraItemsModel.inputItems, orderedPadConfigs);

      this.inputItemsService.calculateBaseGreenfieldCompressorInstall(infraItemsModel.inputItems, orderedPadConfigs);
      this.inputItemsService.calculateBaseGreenfieldSwdInstall(infraItemsModel.inputItems, orderedPadConfigs);

      this.inputItemsService.calculateGreenfieldSatelliteInstall(infraItemsModel.inputItems, orderedPadConfigs);

      this.inputItemsService.calculateHighAccurancySatelliteMeteringUpgrade(infraItemsModel.inputItems, padNames);
      // this.inputItemsService.calculateCtbTrain1200(infraItemsModel.inputItems, orderedPadConfigs);
      this.inputItemsService.calculateExtraCtbTank(infraItemsModel.inputItems, padNames);
      this.inputItemsService.calculateCtbVru(infraItemsModel.inputItems, padNames);
      this.inputItemsService.calculateCtbFlashGasCompressor(infraItemsModel.inputItems, padNames);
      this.inputItemsService.calculateExtraCtbLact(infraItemsModel.inputItems, padNames);
      this.inputItemsService.calculateExtraCtbWaterTransferPump(infraItemsModel.inputItems, orderedPadConfigs);

      this.inputFacilityAssignmentService.calculateCumulativeHeaderSlots(
        infraItemsModel.inputItems,
        orderedPadConfigs,
        padConfigData
      );

      this.inputFacilityAssignmentService.calculateCumulativeTestSeparators(
        infraItemsModel.inputItems,
        orderedPadConfigs,
        padConfigData
      );

      this.inputFacilityAssignmentService.recalculateExtraHeaderSlots(
        infraItemsModel.inputItems,
        orderedPadConfigs,
        padConfigData
      );
      const assumedNumWellsPerTestSeparator = this.inputSummaryBs$.value.assumedNumWellsPerTestSeparator;
      this.inputFacilityAssignmentService.recalculateExtraTestSeparators(
        infraItemsModel.inputItems,
        orderedPadConfigs,
        padConfigData,
        assumedNumWellsPerTestSeparator
      );
      this.padConfigDataBs$.next(padConfigData);
      padConfigData = clonedeep(this.padConfigDataBs$.value);
      orderedPadConfigs = this.inputFacilityAssignmentService.sortPadsByDate(
        padConfigData,
        InputFacilityAssignmentModel.IPadFields.popDate
      );
      infraItemsModel.pads = orderedPadConfigs;

      // review order after gas & water tabs implemented
      // calculate gas & water forecast fields
      // calculate ISBL fields dependant on gas & water forecast
      this.inputItemsService.calculateCompressorStationDependantItems(infraItemsModel.inputItems, padNames);
      // calculate OSBL fields dependant on ISBL fields
      this.inputItemsService.calculateWellPad_SingleUse_AboveGradeWH(infraItemsModel.inputItems, padNames);
      const devAreaState = this.inputSummaryBs$.value.state;
      const devareaStateAbbreviation = devAreaState ? devAreaState.stateAbbreviation : '';
      const flowlineThreshold = this.inputSummaryBs$.value.flowlineThreshold;
      if (!isCostUnitOverridden) {
        const fracPondItems = infraItemsModel.inputItems.filter(x => x.name == 'Frac Ponds');
        fracPondItems.forEach(item => {
          this.inputItemsService.calculateFracPondCost(infraItemsModel.inputItems, devareaStateAbbreviation, item.id);
        });
      }
      this.inputItemsService.calculateSimpleOSBLFields(
        infraItemsModel.inputItems,
        orderedPadConfigs,
        devareaStateAbbreviation,
        flowlineThreshold
      );
      this.inputItemsService.calculatePipelineRisers(infraItemsModel.inputItems, padNames, itemId);
      this.inputItemsService.calculateCtbPadSize(infraItemsModel.inputItems, orderedPadConfigs);
      this.inputItemsService.calculateCtbBrownfieldAdder(infraItemsModel.inputItems, orderedPadConfigs);
      this.inputItemsService.calculateSatelliteBrowfieldAdder(infraItemsModel.inputItems, padNames);
      this.inputItemsService.calculateHPFEBrownfieldAdder(infraItemsModel.inputItems, orderedPadConfigs);
      this.inputItemsService.calculateCSBrownfieldAdder(infraItemsModel.inputItems, orderedPadConfigs);
      // Default Calculation for Liquids Pipeline Risers
      this.inputItemsService.calculateForLiquidsPipelineRiser(infraItemsModel.inputItems, padNames, itemId);
      this.inputItemsService.calculateSWDInjectionHPump(infraItemsModel.inputItems, padNames);

      this.inputItemsService.calculateSWDBrownfieldAdder(infraItemsModel.inputItems, padNames);

      // Perform land damages calculations
      // Prevoius code with MockData //this.inputItemsService.calculateLandDamages(infraItemsModel.inputItems, padNames);
      this.landDamagesOtherDataBs$.next(landDamagesOtherData);
      this.landDamagesPipeDataBs$.next(landDamagesPipeData);
      this.inputItemsService.calculateLandDamages(
        infraItemsModel.inputItems,
        padNames,
        landDamagesPipeData,
        landDamagesOtherData,
        this.pricetSummaryBs$.value.state
      );
      this.inputItemsService.calculateTotals(infraItemsModel.inputItems, padNames);
      this.infrastructureItemsBs$.next(infraItemsModel);
      // Require recalculate after update observable
      this.inputItemsService.calculateCtbPadSize(infraItemsModel.inputItems, orderedPadConfigs);
    }
  }

  public updateItemDetail(
    itemName: string,
    itemId: string,
    itemDetailId: string,
    costValue: number,
    noteValue: string
  ): void {
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    const devAreaState = this.inputSummaryBs$.value.state;
    const devareaStateAbbreviation = devAreaState ? devAreaState.stateAbbreviation : '';
    const stateSelected = this.inputSummaryBs$.value.state;
    const stateAbbreviation = stateSelected ? stateSelected.stateAbbreviation : '';

    const itemCost: ItemCostModel = {
      id: Guid.EMPTY,
      itemId,
      itemDetailId: itemDetailId === null || itemDetailId === undefined ? Guid.EMPTY : itemDetailId,
      year: this.yearBs$.value,
      stateAbbreviation,
      name: '',
    };
    this.isUpdateItemDetailLoading.emit(true);
    // Get the default cost and then apply the logic in the item service.
    this.dataService.getItemDefaultCostByYear(itemCost).subscribe(
      costValueReturned => {
        if (costValueReturned !== undefined) {
          const infraItemsModel: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
          this.inputItemsService.updateItemDetail(
            infraItemsModel.inputItems,
            itemName,
            costValueReturned,
            noteValue,
            devareaStateAbbreviation,
            itemId,
            itemDetailId ? itemDetailId : ''
          );
          this.inputItemsService.updateItemCostPunit(
            infraItemsModel.inputItems,
            itemName,
            itemId,
            costValue,
            costValueReturned
          );
          this.infrastructureItemsBs$.next(infraItemsModel);
          this.calculatePadFields();
          this.calculatePadItemCounts(false, itemId);
        }
        this.isUpdateItemDetailLoading.emit(false);
      },
      err => {
        this.errorInitPricet = true;
        this.isUpdateItemDetailLoading.error(err);
      }
    );
  }

  public padbuilderImportCompleted(pScenarioId: string): void {
    this.changeScenarioID(pScenarioId);
    this.overriddenFieldsService.reset();
    this.dataService.getScenarioByKey(pScenarioId).subscribe(data => {
      this.dataService.getScenarioRigs(data.id).subscribe(dataRig => {
        let pbSummary = clonedeep(this.scenarioPbSummaryBs$.value);
        const pbTmp = data as ScenarioPbSummary;
        pbTmp.scenarioRigs = dataRig;
        pbSummary = pbTmp;
        this.scenarioPbSummaryBs$.next(pbSummary);
      });
    });
    // Call get padBuilder data C&D Domain, source InputPadModel.cs
    this.dataService.getPadbuilderData(pScenarioId).subscribe(data => {
      const inputSummarySubscription = this.inputSummary$.subscribe(summary => {
        const padConfigData = this.inputFacilityAssignmentService.transformPadBuilderData(
          data,
          this.contingencyValue,
          summary
        );
        const padBuilderData = this.inputFacilityAssignmentService.sortPadsByDate(
          padConfigData,
          InputFacilityAssignmentModel.IPadFields.popDate
        );
        for (const d of data) {
          const padBuilderPad = padBuilderData.find(pad => pad.padName === d.padName);
          padBuilderPad.pbPadName = d.pbPadName;
        }
        this.padBuilderDataBs$.next(padBuilderData);
        this.facilityPadBuilderDataBs$.next(padBuilderData);
        this.scopeCountPadBuilderDataBs$.next(padBuilderData);
        this.costSheetPadBuilderDataBs$.next(padBuilderData);
        this.summaryPadBuilderDataBs$.next(padBuilderData);
        this.initializePadPager();
        this.padConfigDataBs$.next(padConfigData);
        this.initCatalogsObservables(false);
        this.initializeMasterData();
        this.initializeItemsGrid();
        this.newScenarioBs$.next(false);
        this.importExecutedBs$.next(true);
      });
      inputSummarySubscription.unsubscribe();
    });
  }

  public reorderPadbuilderPad(padName: string, oldIndex: number, newIndex: number): void {
    const indexOffset = (this.padPagerBs$.value.currentPage - 1) * this.padPagerBs$.value.pageSize;
    const padBuilderPads: any[] = clonedeep(this.padBuilderDataBs$.value);
    const movedPad = padBuilderPads.find(pad => pad.padName === padName);
    padBuilderPads.splice(oldIndex + indexOffset, 1);
    padBuilderPads.splice(newIndex + indexOffset, 0, movedPad);
    this.padBuilderDataBs$.next(padBuilderPads);
    this.changePadPage(this.padPagerBs$.value.currentPage, this.padPagerBs$.value.pageSize);
  }

  public isReadyForCalculation(): boolean {
    const wellScheduleLoaded = this.padConfigDataBs$.value.length > 0;
    const itemsMasterDataLoaded = this.infrastructureItemsBs$.value.inputItems.length > 0;

    if (wellScheduleLoaded && itemsMasterDataLoaded) {
      return true;
    } else {
      return false;
    }
  }

  public changeFacilityPadPage(pageNumber: number, pageSize: number,isPageSizeCalculateRequired: boolean=false): void {
    const padPagerData: PagerModel = clonedeep(this.facilityPadPagerBs$.value);
    const padPage: any[] = this.pagerService.getPage(this.facilityPadBuilderDataBs$.value, pageNumber, pageSize);
    this.facilityPadBuilderDataPageBs$.next(padPage);
    padPagerData.currentPage = pageNumber;
    padPagerData.pageSize = pageSize;
    if (padPagerData.pages.length === 0 || pageNumber === padPagerData.pages[padPagerData.pages.length - 1]) {
      padPagerData.nextPageEnabled = false;
      padPagerData.lastPageEnabled = false;
    } else {
      padPagerData.nextPageEnabled = true;
      padPagerData.lastPageEnabled = true;
    }
    if (padPagerData.currentPage === 1) {
      padPagerData.firstPageEnabled = false;
      padPagerData.previousPageEnabled = false;
    } else {
      padPagerData.firstPageEnabled = true;
      padPagerData.previousPageEnabled = true;
    }
    padPagerData.pages = isPageSizeCalculateRequired ? this.pagerService.calculatePages(this.facilityPadBuilderDataBs$.value, pageSize) : padPagerData.pages;
    this.facilityPadPagerBs$.next(padPagerData);

  }

  public changeScopePadPage(pageNumber: number, pageSize: number, isPageSizeCalculateRequired: boolean = false): void {
    const padPagerData: PagerModel = clonedeep(this.scopeCountPadPagerBs$.value);
    const padPage: any[] = this.pagerService.getPage(this.scopeCountPadBuilderDataBs$.value, pageNumber, pageSize);
    this.scopeCountPadBuilderDataPageBs$.next(padPage);
    padPagerData.currentPage = pageNumber;
    padPagerData.pageSize = pageSize;
    if (padPagerData.pages.length === 0 || pageNumber === padPagerData.pages[padPagerData.pages.length - 1]) {
      padPagerData.nextPageEnabled = false;
      padPagerData.lastPageEnabled = false;
    } else {
      padPagerData.nextPageEnabled = true;
      padPagerData.lastPageEnabled = true;
    }
    if (padPagerData.currentPage === 1) {
      padPagerData.firstPageEnabled = false;
      padPagerData.previousPageEnabled = false;
    } else {
      padPagerData.firstPageEnabled = true;
      padPagerData.previousPageEnabled = true;
    }
    padPagerData.pages = isPageSizeCalculateRequired ? this.pagerService.calculatePages(this.scopeCountPadBuilderDataBs$.value, pageSize) : padPagerData.pages;
    this.scopeCountPadPagerBs$.next(padPagerData);
  }

  public changeCostSheetPadPage(pageNumber: number, pageSize: number, isPageSizeCalculateRequired: boolean = false): void {
    const padPagerData: PagerModel = clonedeep(this.costSheetPadPagerBs$.value);
    const padPage: any[] = this.pagerService.getPage(this.costSheetPadBuilderDataBs$.value, pageNumber, pageSize);
    this.costSheetPadBuilderDataPageBs$.next(padPage);
    padPagerData.currentPage = pageNumber;
    padPagerData.pageSize = pageSize;
    if (padPagerData.pages.length === 0 || pageNumber === padPagerData.pages[padPagerData.pages.length - 1]) {
      padPagerData.nextPageEnabled = false;
      padPagerData.lastPageEnabled = false;
    } else {
      padPagerData.nextPageEnabled = true;
      padPagerData.lastPageEnabled = true;
    }
    if (padPagerData.currentPage === 1) {
      padPagerData.firstPageEnabled = false;
      padPagerData.previousPageEnabled = false;
    } else {
      padPagerData.firstPageEnabled = true;
      padPagerData.previousPageEnabled = true;
    }
    padPagerData.pages = isPageSizeCalculateRequired ? this.pagerService.calculatePages(this.costSheetPadBuilderDataBs$.value, pageSize) : padPagerData.pages;
    this.costSheetPadPagerBs$.next(padPagerData);
  }

  public changeSummaryPadPage(pageNumber: number, pageSize: number, isPageSizeCalculateRequired: boolean = false): void {
    const padPagerData: PagerModel = clonedeep(this.summaryPadPagerBs$.value);
    const padPage: any[] = this.pagerService.getPage(this.summaryPadBuilderDataBs$.value, pageNumber, pageSize);
    this.summaryPadBuilderDataPageBs$.next(padPage);
    padPagerData.currentPage = pageNumber;
    padPagerData.pageSize = pageSize;
    if (padPagerData.pages.length === 0 || pageNumber === padPagerData.pages[padPagerData.pages.length - 1]) {
      padPagerData.nextPageEnabled = false;
      padPagerData.lastPageEnabled = false;
    } else {
      padPagerData.nextPageEnabled = true;
      padPagerData.lastPageEnabled = true;
    }
    if (padPagerData.currentPage === 1) {
      padPagerData.firstPageEnabled = false;
      padPagerData.previousPageEnabled = false;
    } else {
      padPagerData.firstPageEnabled = true;
      padPagerData.previousPageEnabled = true;
    }
    padPagerData.pages = isPageSizeCalculateRequired ? this.pagerService.calculatePages(this.summaryPadBuilderDataBs$.value, pageSize) : padPagerData.pages;
    this.summaryPadPagerBs$.next(padPagerData);
  }

  public changePadPage(pageNumber: number, pageSize: number): void {
    const padPagerData: PagerModel = clonedeep(this.padPagerBs$.value);
    const padPage: any[] = this.pagerService.getPage(this.padBuilderDataBs$.value, pageNumber, pageSize);
    this.padBuilderDataPageBs$.next(padPage);
    padPagerData.currentPage = pageNumber;
    padPagerData.pageSize = pageSize;
    if (padPagerData.pages.length === 0 || pageNumber === padPagerData.pages[padPagerData.pages.length - 1]) {
      padPagerData.nextPageEnabled = false;
      padPagerData.lastPageEnabled = false;
    } else {
      padPagerData.nextPageEnabled = true;
      padPagerData.lastPageEnabled = true;
    }
    if (padPagerData.currentPage === 1) {
      padPagerData.firstPageEnabled = false;
      padPagerData.previousPageEnabled = false;
    } else {
      padPagerData.firstPageEnabled = true;
      padPagerData.previousPageEnabled = true;
    }
      this.padPagerBs$.next(padPagerData);
  }

  public getScenarioData(): IScenario {
    // Variables used in the store service.(Source)
    const inputSummaryData = this.inputSummaryBs$.value;
    const pricetSummary = this.pricetSummaryBs$.value;
    const pbSummaryData = this.scenarioPbSummaryBs$.value;
    const contingencyData = this.contingencyBs$.value;
    const infrastructureItemsData = this.infrastructureItemsBs$.value; // Items and ScopeCounts.
    const padsData = this.inputFacilityAssignmentService.sortPadsByDate(
      this.padConfigDataBs$.value,
      InputFacilityAssignmentModel.IPadFields.popDate
    );

    const padbuilderData = this.padBuilderDataBs$.value;
    for (const padBuilder of padbuilderData) {
      const padName = padBuilder.padName;
      const pad = padsData.find(p => p.padName === padName);
      pad['pbPadName'] = padBuilder.pbPadName;
    }

    // Used to get the overriden Values and Used to get the Counts.
    const overriddenService = this.overriddenFieldsService;
    const inputItemService = this.inputItemsService;

    // Map objects.(Destination)
    // Prepare arrays to get the pads, scopecounts and items
    const vPadBracketsAndFacility: IPadBracketAndFacility[] = [];
    const vScenarioEquipments: InputItem[] = [];
    const vScenarioPadEquipmentScopeCounts: ScenarioPadEquipmentScopeCountModel[] = [];
    // Look into the Pads from Input Facility Assignement
    for (const pad of padsData) {
      // Create object to get the PadBracket and Facility Data (Inputs)
      const vPad: IPadBracketAndFacility = {
        id: Guid.EMPTY,
        scenarioId: Guid.EMPTY,
        scenarioPadBracketId: Guid.EMPTY,
        scenarioFacilityAssignmentId: Guid.EMPTY,
        // Scenario Pad Bracket Data
        padName: pad['pbPadName'],
        padOrder: pad.padOrder,
        wellCount: pad.wellCount,
        spudDate: this.getDateUTC(pad.spudDate),
        popDate: this.getDateUTC(pad.popDate),
        padRigDownMoveOffDate: this.getDateUTC(pad.padRigDownMoveOffDate),
        // Scenario Facility Assignment
        padStatus: pad.padStatus,
        pgnNumber: pad.pgnNumber,
        activeForOutlook: pad.activeForOutlook,
        developmentArea: pad.developmentArea,
        isPartOfCo2Development: this.getIsPartOfCo2Development(pad.isPartOfCo2Development),
        targetSatellite: pad.targetSatellite,
        targetCtb: pad.targetCtb,
        targetTrain: pad.targetTrain,
        satelliteExecutionCategory: pad.satelliteExecutionCategory,
        satelliteExecutionCategoryOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.satelliteExecutionCategory
        )
          ? pad.satelliteExecutionCategory
          : null,
        ctbExecutionCategory: pad.ctbExecutionCategory,
        ctbExecutionCategoryOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.ctbExecutionCategory
        )
          ? pad.ctbExecutionCategory
          : null,
        wellTestLocation: pad.wellTestLocation,
        associatedCompStation: pad.associatedCompStation,
        compExecutionCategory: pad.compExecutionCategory,
        compressorExecutionCategoryOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.compExecutionCategory
        )
          ? pad.compExecutionCategory
          : null,
        associatedSwdFacility: pad.associatedSwdFacility,
        swdExecutionCategory: pad.swdExecutionCategory,
        swdExecutionCategoryOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.swdExecutionCategory
        )
          ? pad.swdExecutionCategory
          : null,
        fracPond: pad.fracPond,
        pondExecutionCategory: pad.pondExecutionCategory,
        pondExecutionCategoryOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.pondExecutionCategory
        )
          ? pad.pondExecutionCategory
          : null,
        cumulativeWellCount: pad.cumulativeWellCount,
        cumulativeHeaderSlots: pad.cumulativeHeaderSlots,
        cumulativeHeaderSlotsOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.cumulativeHeaderSlots
        )
          ? pad.cumulativeHeaderSlots
          : null,
        cumulativeTestSeparators: pad.cumulativeTestSeparators,
        cumulativeTestSeparatorsOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.cumulativeTestSeparators
        )
          ? pad.cumulativeTestSeparators
          : null,
        padContingency: Number(pad.padContingency),
        padContingencyOverriddenFlag: overriddenService.isPadConfigFieldOverridden(
          pad.padName,
          this.PadFields.padContingency
        )
          ? Number(pad.padContingency)
          : -1,
        compressionRfsd: this.getDateUTC(pad.compressionRfsd),
        compressorRfsdOverriddenFlag: this.getDateUTC(
          overriddenService.isPadConfigFieldOverridden(pad.padName, this.PadFields.compressionRfsd)
            ? pad.compressionRfsd
            : null
        ),
        waterDisposalRfsd: this.getDateUTC(pad.waterDisposalRfsd),
        waterDisposalRfsdOverriddenFlag: this.getDateUTC(
          overriddenService.isPadConfigFieldOverridden(pad.padName, this.PadFields.waterDisposalRfsd)
            ? pad.waterDisposalRfsd
            : null
        ),
        fracPondRfsd: this.getDateUTC(pad.fracPondRfsd),
        fracPondRfsdOverriddenFlag: this.getDateUTC(
          overriddenService.isPadConfigFieldOverridden(pad.padName, this.PadFields.fracPondRfsd)
            ? pad.fracPondRfsd
            : null
        ),
        targetSatelliteRfsd: this.getDateUTC(pad.tragetSatelliteRfsd),
        targetSatelliteRfsdOverriddenFlag: this.getDateUTC(
          overriddenService.isPadConfigFieldOverridden(pad.padName, this.PadFields.tragetSatelliteRfsd)
            ? pad.tragetSatelliteRfsd
            : null
        ),
        targetCtbRfsd: this.getDateUTC(pad.tragetCtbRfsd),
        targetCtbRfsdOverriddenFlag: this.getDateUTC(
          overriddenService.isPadConfigFieldOverridden(pad.padName, this.PadFields.tragetCtbRfsd)
            ? pad.tragetCtbRfsd
            : null
        ),
        comments: pad.comments,
        isPadNameOverriden: pad.isPadNameOverriden,
        yearCost: pad.yearCost,
      };
      // Add pad data to array
      vPadBracketsAndFacility.push(vPad);
    }

    // Look into Scenario Equipments to map the objects
    infrastructureItemsData.inputItems.forEach(item => {
      const vScenarioEquipment: InputItem = {
        id: item.id,
        infrastructureClass:
          item.infrastructureClass === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.infrastructureClass.displayName,
                id: item.infrastructureClass.id,
                name: item.infrastructureClass.name,
              },
        locationId: item.locationId,
        location:
          item.location === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.location.displayName,
                id: item.location.id,
                name: item.location.name,
              },
        infraestructureScopeCategoryId: item.infraestructureScopeCategoryId,
        infraestructureScopeCategory:
          item.infraestructureScopeCategory === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
                infrastructureClassId: Guid.EMPTY,
              }
            : {
                displayName: item.infraestructureScopeCategory.displayName,
                id: item.infraestructureScopeCategory.id,
                name: item.infraestructureScopeCategory.name,
                infrastructureClassId: item.infraestructureScopeCategory.infrastructureClassId,
              },
        rfsdTimingId: item.rfsdTimingId,
        rfsd:
          item.rfsd === null || undefined
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.rfsd.displayName,
                id: item.rfsd.id,
                name: item.rfsd.name,
              },
        kindId: item.kindId,
        kind:
          item.kind === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.kind.displayName,
                id: item.kind.id,
                name: item.kind.name,
              },
        scenarioAfeTypeId: item.scenarioAfeTypeId,
        scenarioAfeType:
          item.scenarioAfeType === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.scenarioAfeType.displayName,
                id: item.scenarioAfeType.id,
                name: item.scenarioAfeType.name,
              },
        respToExpandFacilityItemId: item.respToExpandFacilityItemId,
        unitOfMeasure:
          item.unitOfMeasure === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.unitOfMeasure.displayName,
                id: item.unitOfMeasure.id,
                name: item.unitOfMeasure.name,
              },
        userCategory: item.userCategory,
        itemDetailId: item.itemDetailId,
        itemDetail:
          item.itemDetail === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                itemTypeId: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.itemDetail.displayName,
                id: item.itemDetail.id,
                itemTypeId: item.itemDetail.itemTypeId,
                name: item.itemDetail.displayName,
              },
        name: item.displayName,
        displayName: item.displayName,
        costPunit: item.costPunit,
        costUnitOverride: item.costUnitOverride,
        orderItem: item.orderItem,
        isVisible: item.isVisible,
        itemNotes: item.itemNotes,
        itemType:
          item.itemType === null
            ? {
                displayName: '',
                id: Guid.EMPTY,
                name: '',
              }
            : {
                displayName: item.itemType.displayName,
                id: item.itemType.id,
                name: item.itemType.displayName,
              },
        itemTypeId: item.itemTypeId,
        total: item.total,
        itemDetails: null,
        unitOfMeasureId: item.unitOfMeasureId,
        isExpanded: item.isExpanded,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
      };
      vScenarioEquipments.push(vScenarioEquipment);
    });

    // Look into Items and Pads to get the Scope Counts Values
    for (const inputItem of infrastructureItemsData.inputItems) {
      for (let p = 0; p < vPadBracketsAndFacility.length; p++) {
        const vPadName = padsData[p].padName;
        let vItemName = inputItem.name;

        let vCount: number;
        let vIsCountOverridden: boolean;

        if (!vItemName) {
          vItemName = inputItem.displayName;
          if (vItemName) {
            vCount = inputItemService.getInputItemCountByDisplayName(
              infrastructureItemsData.inputItems,
              vPadName,
              vItemName,
              inputItem.id
            );
            vIsCountOverridden = this.overriddenFieldsService.isPadItemCountOverriddenByDisplayName(
              vPadName,
              vItemName,
              inputItem.id
            );
          }
        } else {
          vCount = inputItemService.getInputItemCount(
            infrastructureItemsData.inputItems,
            vPadName,
            vItemName,
            inputItem.id,
            inputItem.respToExpandFacilityItemId
          );
          vIsCountOverridden = this.overriddenFieldsService.isPadItemCountOverridden(vPadName, vItemName, inputItem.id);
        }
        const inventory: ScenarioPadEquipmentScopeCountModel = {
          facilityItemId: inputItem.itemDetail?.id == null ? inputItem.id : inputItem.itemDetail.id,
          padName: padsData[p]['pbPadName'],
          scopeCount: vCount,
          scopeCountOverriddenFlag: vIsCountOverridden ? vCount : -1,
          id: Guid.EMPTY,
          scenarioId: Guid.EMPTY,
          scenarioPadBracketId: Guid.EMPTY,
          parentRespToExpandFacilityItemId : inputItem.respToExpandFacilityItemId
        };

        // Add object Inventory to array
        vScenarioPadEquipmentScopeCounts.push(inventory);
      }
    }

    let vStateId = Guid.EMPTY;
    if (pricetSummary.state !== null || pricetSummary.state !== undefined) {
      const vState = this.stateOptionsBs$.value.filter(s => s.name === pricetSummary.state)[0];
      if (vState !== null && vState !== undefined) {
        vStateId = vState.id;
      }
    }
    // Prepare the Principal Objet to send
    const vScenario: IScenario = {
      id: this.scenarioIdBs$.value,
      name: this.inputSummaryBs$.value.scenarioName,
      // Set SCENARIO_EQUIPMENT
      items: vScenarioEquipments,
      // Set SCENARIO_PAD_BRACKET and SCENARIO_FACILITY_ASSIGNMENT
      padBrackets: vPadBracketsAndFacility,
      // Set SCENARIO_PAD_EQUIPMENT_SCOPE_COUNT
      padItemInventories: vScenarioPadEquipmentScopeCounts,
      scenarioNotes: this.pricetSummaryBs$.value.scenarioNotes,
      user: this.authenticationService.getCurrentUser().toUpperCase(),
      isSaveAs: false,
      developmentAreaId: inputSummaryData.developmentArea !== null ? inputSummaryData.developmentArea.id : Guid.EMPTY,
      scenarioTypeId: inputSummaryData.scenarioType !== null ? inputSummaryData.scenarioType.id : Guid.EMPTY,
      handoverNumber: inputSummaryData.handoverNumber,
      taxRate: pricetSummary.tax,
      contingencyPercentage: inputSummaryData.contingency,
      contingencyPercentageOverriddenFlag: overriddenService.summaryContingency,
      flowlineThresholdDistance: inputSummaryData.flowlineThreshold,
      greenfieldInstallTimeframe: inputSummaryData.greenfieldInstallTimeframe,
      assumedWellsPerTestSeparator: inputSummaryData.assumedNumWellsPerTestSeparator,
      calculatedContingencyPercentage: contingencyData.valuePercentage,
      estimateDescription: contingencyData.estimateDescription,
      numberOfDevWells: contingencyData.numberOfDevWells,
      estimateTimeFrame: contingencyData.estimateTimeFrame,
      padContingencyNotes: contingencyData.notes,
      costYear: Number(this.yearBs$.value),
      createdBy: this.authenticationService.getCurrentUser().toUpperCase(),
      fileName: pbSummaryData.fileName,
      lastPbSaveDate: pbSummaryData.lastPbSaveDate,
      rigCount: pbSummaryData.rigCount,
      forecastNotes: pbSummaryData.forecastNotes,
      createdDate: pbSummaryData.createdDate,
      updatedDate: pbSummaryData.updatedDate,
      updatedBy: pbSummaryData.updatedBy,
      sourceScenarioId: Guid.EMPTY,
      basinId: inputSummaryData.basin !== null ? inputSummaryData.basin.id : Guid.EMPTY,
      geographicAreaId: inputSummaryData.geographicArea !== null ? inputSummaryData.geographicArea.id : Guid.EMPTY,
      stateId: vStateId,
      isDeleted: this.inputSummaryBs$.value.isDeleted,
      sequenceId: this.scenario ? this.scenario.sequenceId : this.sequenceIdBs$.value,
      unitOfMeasures: this.getKeyValuePairs(this.unitOfMeasureOptionsBs$.value),
    };
    return vScenario;
  }

  public getPadsData(): any {
    const padsData = this.inputFacilityAssignmentService.sortPadsByDate(
      this.padConfigDataBs$.value,
      InputFacilityAssignmentModel.IPadFields.popDate
    );

    return padsData;
  }

  public getKeyValuePairs(unitsOfMeasure: UnitOfMeasure[]): { key: string; value: string }[] {
    return unitsOfMeasure.map(obj => ({ key: obj.id.toString(), value: obj.name }));
  }

  public getDateUTC(date) {
    if (date !== undefined && date !== null) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    } else {
      return date;
    }
  }

  public changeTryExport(status: boolean) {
    this.tryExportBs$.next(status);
  }

  public getTryExport(): boolean {
    return this.tryExportBs$.value;
  }

  public changeNewScenario(status: boolean) {
    this.newScenarioBs$.next(status);

    if (status === true) {
      this.overriddenFieldsService.reset();
      this.padConfigDataBs$.next([]);
      this.padBuilderDataBs$.next([]);
      this.padBuilderDataPageBs$.next([]);
      this.facilityPadBuilderDataBs$.next([]);
      this.facilityPadBuilderDataPageBs$.next([]);
      this.scopeCountPadBuilderDataBs$.next([]);
      this.scopeCountPadBuilderDataPageBs$.next([]);
      this.costSheetPadBuilderDataBs$.next([]);
      this.costSheetPadBuilderDataPageBs$.next([]);
      this.summaryPadBuilderDataBs$.next([]);
      this.summaryPadBuilderDataPageBs$.next([]);
      this.padPagerBs$.next({
        currentPage: 1,
        pages: [1],
        pageSize: 5,
        total: 0,
        firstPageEnabled: false,
        lastPageEnabled: true,
        nextPageEnabled: true,
        previousPageEnabled: false,
      });

      this.facilityPadPagerBs$.next({
        currentPage: 1,
        pages: [1],
        pageSize: 5,
        total: 0,
        firstPageEnabled: false,
        lastPageEnabled: true,
        nextPageEnabled: true,
        previousPageEnabled: false,
      });

      this.scopeCountPadPagerBs$.next({
        currentPage: 1,
        pages: [1],
        pageSize: 5,
        total: 0,
        firstPageEnabled: false,
        lastPageEnabled: true,
        nextPageEnabled: true,
        previousPageEnabled: false,
      });

      this.costSheetPadPagerBs$.next({
        currentPage: 1,
        pages: [1],
        pageSize: 5,
        total: 0,
        firstPageEnabled: false,
        lastPageEnabled: true,
        nextPageEnabled: true,
        previousPageEnabled: false,
      });

      this.summaryPadPagerBs$.next({
        currentPage: 1,
        pages: [1],
        pageSize: 5,
        total: 0,
        firstPageEnabled: false,
        lastPageEnabled: true,
        nextPageEnabled: true,
        previousPageEnabled: false,
      });

      this.scenarioPbSummaryBs$.next({
        $id: '',
        sequenceName: '',
        fileName: '',
        lastPbSaveDate: '',
        forecastNotes: '',
        rigCount: 0,
        scenarioRigs: [],
        scenarioId: '',
        id: '',
        createdBy: null,
        createdDate: null,
        updatedBy: null,
        updatedDate: null,
      });
      this.infrastructureItemsBs$.next({
        inputItems: [],
        pads: [],
        padBuilderPads: [],
      });
      this.inputSummaryBs$.next({
        developmentArea: null,
        scenarioType: null,
        state: null,
        handoverNumber: '',
        scenarioName: '',
        basin: null,
        geographicArea: null,
        contingency: 0,
        flowlineThreshold: 15840,
        assumedNumWellsPerTestSeparator: 8,
        greenfieldInstallTimeframe: 12,
        isDeleted: false,
        year: 2019,
      });
      this.contingencyBs$.next({
        estimateDescription: '',
        numberOfDevWells: '',
        estimateTimeFrame: '',
        valuePercentage: 0,
        notes: '',
      });
      this.pricetSummaryBs$.next({
        id: '',
        developmentArea: '',
        scenarioType: '',
        scenarioName: '',
        handover: null,
        contingency: null,
        state: '',
        tax: 0,
        basin: '',
        geographicArea: '',
        scenarioNotes: '',
        year: 2019,
      });
    }
  }

  public changeScenarioID(id: string) {
    this.scenarioIdBs$.next(id);
  }

  public openScenario(scenarioId: string): void {
    this.scenarioIdBs$.next(scenarioId);

    //spinner start
    this.loadSpinnerBs$.next(true);

    // Source C&D Domain
    this.getScenarioPadBracketCosts();
    this.dataService.openPricetScenario(scenarioId).subscribe(
      data => {
        //spinner start
        this.loadSpinnerBs$.next(true);

        const unitOfMeasures: UnitOfMeasure[] = clonedeep(this.unitOfMeasureOptionsBs$.value);
        const scenario: IScenario = data;
        this.scenario = scenario;
        this.updateYear(scenario.costYear);

        this.overriddenFieldsService.reset();
        this.padConfigDataBs$.next([]);
        const padConfigData = this.openScenarioService.transformPadBrackets(scenario.padBrackets);
        this.padConfigDataBs$.next(padConfigData);
        const padBuilderData = this.inputFacilityAssignmentService.sortPadsByDate(
          padConfigData,
          InputFacilityAssignmentModel.IPadFields.popDate
        );
        for (const padBracket of scenario.padBrackets) {
          const padBuilderPad = padBuilderData.find(pad => pad.padName === padBracket.padName);
          padBuilderPad.pbPadName = padBracket['pbPadName'];
        }
        this.padBuilderDataBs$.next(padBuilderData);
        this.facilityPadBuilderDataBs$.next(padBuilderData);
        this.scopeCountPadBuilderDataBs$.next(padBuilderData);
        this.costSheetPadBuilderDataBs$.next(padBuilderData);
        this.summaryPadBuilderDataBs$.next(padBuilderData);
        this.initializePadPager();
        const infraItemsModel = clonedeep(this.infrastructureItemsBs$.value);
        const itemsAndDetails: ItemAndDetailsModel[] = clonedeep(this.itemsAndDetailsOptionsBs$.value);

        const tmpInputItems: InputItem[] = this.inputItemsService.transformFacilityItemsToLoadDetails(
          itemsAndDetails,
          scenario.items,
          unitOfMeasures
        );

        // > 1) Items: Transform Facility Items, set details

        infraItemsModel.inputItems = this.moveAdditionalItemsToBottom(tmpInputItems);

        //  > 2) Pads
        infraItemsModel.pads = clonedeep(this.padBuilderDataBs$.value);
        this.openScenarioService.populateScopeCounts(
          infraItemsModel.inputItems,
          scenario.padItemInventories,
          scenario.padBrackets
        );

        // Set Development Area , State and Scenario Type
        this.infrastructureItemsBs$.next(infraItemsModel);
        let costLogicDetector = this.costLogicDetectorBs$.value;
        costLogicDetector.isInfrastructureItemsReady = true;
        costLogicDetector.isPadBuilderDataReady = true;
        this.costLogicDetectorBs$.next(costLogicDetector);

        const inputSummary = this.openScenarioService.transformInputSummary(
          scenario,
          this.developmentAreaOptionsBs$.value,
          this.stateOptionsBs$.value,
          this.scenarioTypeOptionsBs$.value,
          this.basinOptionsBs$.value,
          this.geographicAreaBs$.value
        );
        this.inputSummaryBs$.next(inputSummary);

        // Set Contingency
        this.contingencyBs$.next({
          valuePercentage: scenario.calculatedContingencyPercentage,
          estimateDescription: scenario.estimateDescription,
          estimateTimeFrame: scenario.estimateTimeFrame,
          notes: scenario.padContingencyNotes,
          numberOfDevWells: scenario.numberOfDevWells,
        });

        this.sequenceIdBs$.next(scenario.sequenceId);

        // Set PadBuilder information and Rigs
        this.dataService.getScenarioRigs(scenarioId).subscribe(rig => {
          const tscenarioPbSummary: ScenarioPbSummary = {
            createdBy: scenario.createdBy,
            createdDate: scenario.createdDate,
            sequenceName: scenario.sequenceName,
            fileName: scenario.fileName,
            forecastNotes: scenario.forecastNotes,
            lastPbSaveDate: scenario.lastPbSaveDate,
            rigCount: scenario.rigCount,
            scenarioRigs: rig,
            updatedBy: scenario.updatedBy,
            updatedDate: scenario.updatedDate,
            id: scenario.id,
            scenarioId: scenario.id,
            $id: '',
          };
          this.scenarioPbSummaryBs$.next(tscenarioPbSummary);
        });

        this.initializePricetSummary(scenario);

        this.overriddenFieldsService.summaryContingency = scenario.contingencyPercentageOverriddenFlag; // before scenario.contingencyPercentageOverriddenFlag > -1;

        scenario.padBrackets.forEach(pb => {
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.satelliteExecutionCategory,
            pb.satelliteExecutionCategoryOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.ctbExecutionCategory,
            pb.ctbExecutionCategoryOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.tragetSatelliteRfsd,
            pb.targetSatelliteRfsdOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.tragetCtbRfsd,
            pb.targetCtbRfsdOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.compressionRfsd,
            pb.compressorRfsdOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.compExecutionCategory,
            pb.compressorExecutionCategoryOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.waterDisposalRfsd,
            pb.waterDisposalRfsdOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.swdExecutionCategory,
            pb.swdExecutionCategoryOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.fracPondRfsd,
            pb.fracPondRfsdOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.pondExecutionCategory,
            pb.pondExecutionCategoryOverriddenFlag !== null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.padContingency,
            pb.padContingencyOverriddenFlag > -1
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.cumulativeTestSeparators,
            pb.cumulativeTestSeparatorsOverriddenFlag != null
          );
          this.overriddenFieldsService.setPadConfigFieldOverridden(
            pb.padName,
            this.PadFields.cumulativeHeaderSlots,
            pb.cumulativeHeaderSlotsOverriddenFlag != null
          );
        });

        scenario.padItemInventories.forEach(padItems => {
          const item = scenario.items.find(it => it.id === padItems.facilityItemId);
          if (!item) {
            return;
          }
          this.overriddenFieldsService.setPadItemCountOverridden(
            padItems.padName,
            item ? item.displayName : null,
            item.id,
            padItems.scopeCountOverriddenFlag > -1
          );
        });

        this.errorOpenScenario.hasError = false;

        setTimeout(() => {
          if (this.isReadyForCalculation()) {
            this.calculatePadFields();
            this.calculatePadItemCounts();
          }
        }, 6000);

        //setting buffer time so that costs will not get mixed
        //DO NOT remove this
        setTimeout(() => {
          this.loadSpinnerBs$.next(false);
        }, 6000);
      },
      (error: any) => {
        this.errorOpenScenario.hasError = true;
        this.loadSpinnerBs$.next(false);
      }
    );
  }

  public moveAdditionalItemsToBottom(items: any[]): any[] {
    const additionalItems = [];
    for (const item of items) {
      if (!item.name) {
        additionalItems.push(item);
      }
    }
    items = items.filter(item => item.name);
    for (const additionalItem of additionalItems) {
      items.push(additionalItem);
    }
    return items;
  }

  public getScenarioDataToSaveAs(nameSaveAs: string): IScenario {
    const vScenarioCopy = this.getScenarioData();

    // Set the new name and new id to generate a copy of the  current scenario.
    vScenarioCopy.sourceScenarioId = vScenarioCopy.id;
    vScenarioCopy.isSaveAs = true;
    // New Id
    vScenarioCopy.id = Guid.create().toString();
    vScenarioCopy.name = nameSaveAs;

    return vScenarioCopy;
  }

  public changeTab(index: number) {
    this.changeTabBs$.next(index);
  }

  public deletePadbuilderDataImported(): void {
    if (this.importExecutedBs$.value) {
      this.importExecutedBs$.next(false);
      this.dataService.deletePadbuilderImported(this.scenarioIdBs$.value).subscribe((data: string) => {
        // empty
      });
    }
    if (this.padConfigDataBs$.value) {
      this.padConfigDataBs$.next([]);
    }
  }

  public deleteScenario(scenarioId: string): void {
    this.dataService.deleteScenario(scenarioId).subscribe((data: string) => {
      // empty
    });
  }

  public setImportExecuted(value: boolean): void {
    this.importExecutedBs$.next(value);
  }

  public isAdditionalItem(id: string) {
    return this.inputItemsService.isAdditionalItem(id.toUpperCase());
  }

  // Update the cost changed for the item, get the default cost from API to validate if the cost was deleted then set the default cost
  // with te appropiate item, item detail, year and state. Set the indicator of overridden
  //  Overwrite Cost / Unit column in Scope Count Grid, us228994
  public updateItemCostPunit(itemName: string, itemId: string, itemDetailId: string, costValue?: number): void {
    if (itemName !== null && itemName !== undefined && itemId !== null && itemId !== undefined) {
      const stateSelected = this.inputSummaryBs$.value.state;
      const stateAbbreviation = stateSelected ? stateSelected.stateAbbreviation : '';
      // Set the information needed to check the defaults
      const itemCost: ItemCostModel = {
        id: Guid.EMPTY,
        itemId,
        itemDetailId: itemDetailId === null || itemDetailId === undefined ? Guid.EMPTY : itemDetailId,
        year: this.yearBs$.value,
        stateAbbreviation,
        name: '',
      };

      this.isUpdateItemDetailLoading.emit(true);
      // Get the default cost and then apply the logic in the item service.
      this.dataService.getItemDefaultCostByYear(itemCost).subscribe(
        data => {
          const costDefault = data;

          if (costDefault !== undefined) {
            const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
            this.inputItemsService.updateItemCostPunit(infraItems.inputItems, itemName, itemId, costValue, costDefault);
            this.infrastructureItemsBs$.next(infraItems);
            this.calculatePadFields();
            this.calculatePadItemCounts(true);
            this.isUpdateItemDetailLoading.emit(false);
          }
        },
        err => {
          this.errorInitPricet = true;
          this.isUpdateItemDetailLoading.emit(false);
        }
      );
    }
  }

  public updateItemIsVisible(itemNameSelected: string, parentItemIdSelected: string, isExpandend: boolean): void {
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    this.inputItemsService.updateItemIsVisible(
      infraItems.inputItems,
      itemNameSelected,
      parentItemIdSelected,
      isExpandend
    );
    this.infrastructureItemsBs$.next(infraItems);
  }

  public updatePadFieldIsVisible(fieldName: string): void {
    const padConfig = clonedeep(this.padConfigDataBs$.value);
    this.inputFacilityAssignmentService.updatePadFieldIsVisible(padConfig, fieldName);
    this.padConfigDataBs$.next(padConfig);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public updateAllPadFieldIsVisible(isVisible : boolean): void {
    const padConfig = clonedeep(this.padConfigDataBs$.value);
    let fields = ['padOrder','targetSatellite','targetCtb','wellTestLocation','associatedCompStation','associatedSwdFacility','fracPond',];
    fields.forEach((fieldName)=>{
      this.inputFacilityAssignmentService.updatePadFieldIsVisible(padConfig, fieldName, isVisible);
    })
    
    this.padConfigDataBs$.next(padConfig);
    this.calculatePadFields();
    this.calculatePadItemCounts();
  }

  public updatePadByPadCostsGrid(message: any) {
    this.padByPadCostUpdateBs$.next(message);
  }

  public setPadComment(padName: string, comment: string): void {
    const padConfigData = clonedeep(this.padConfigDataBs$.value);
    this.inputFacilityAssignmentService.setPadNameComment(padConfigData, padName, comment);

    this.padConfigDataBs$.next(padConfigData);
    const padList = this.inputFacilityAssignmentService.sortPadsByDate(
      padConfigData,
      InputFacilityAssignmentModel.IPadFields.popDate
    );
    const infraItemsModel: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    infraItemsModel.pads = padList;
    this.infrastructureItemsBs$.next(infraItemsModel);
  }

  public getItemCostPadByLogic(
    padName: string,
    item: string,
    itemId: string,
    itemCountByPad: number,
    itemCostPerUnit: number,
    costYear: string,
    alternatePadCosts: boolean = false,
    originalInputInfraData: any[] = []
  ): number {
    const infraItemsModel: InputItemsModel = this.infrastructureItemsBs$.value;
    const mappingCostSft: ICndCostMappingSft[] = this.cndCostMappingSftBs$.value;
    let itemCost = null;
    if (this.yearBs$.value.toString() != costYear) {
      const itemInfo = infraItemsModel.inputItems.find(input => input.displayName == item);
      if (itemInfo && itemInfo.itemDetail && itemInfo.itemDetail.id) {
        //facility type is present, so we have to fetch cost for that facility item type
        itemCost = this.scenarioPadBracketCostsBs$.value.find(
          cost =>
            cost.scenarioId == this.scenarioIdBs$.value &&
            cost.facilityItemId == itemInfo.itemDetail.id &&
            cost.yearCost.toString() == costYear
        );
      } //facility item type is not present
      else {
        itemCost = this.scenarioPadBracketCostsBs$.value.find(
          cost =>
            cost.scenarioId == this.scenarioIdBs$.value &&
            cost.facilityItemId == itemId &&
            cost.yearCost.toString() == costYear
        );
      }

      if (itemCost) {
        if (itemCost.costOverride) {
          itemCostPerUnit = itemCost.costOverride;
        } else {
          itemCostPerUnit = itemCost.cost;
        }
      }
    } else if (this.yearBs$.value.toString() == costYear && alternatePadCosts) {
      //const itemId = infraItemsModel.inputItems.find(input => input.displayName == item).id;
      const itemCost = originalInputInfraData.find(cost => cost.id == itemId);
      if (itemCost) {
        if (itemCost.costOverride) {
          itemCostPerUnit = itemCost.costOverride;
        } else {
          itemCostPerUnit = itemCost.costPunit;
        }
      }
    }
    itemCountByPad = this.inputItemsService.getItemCostPadByLogic(
      infraItemsModel.inputItems,
      mappingCostSft,
      padName,
      item,
      itemId,
      itemCountByPad,
      itemCostPerUnit,
      costYear
    );
    return itemCountByPad;
  }

  private getIsPartOfCo2Development(isPartOfCo2Development: string): number {
    const value = isPartOfCo2Development ? (isPartOfCo2Development.toLowerCase() === 'yes' ? 1 : 0) : null;
    return value;
  }

  public setSequenceId(sequenceId: string) {
    this.sequenceIdBs$.next(sequenceId);
  }

  public updateYear(year: number) {
    this.yearBs$.next(year);
  }

  public overridePaNames(pads: any[]): Observable<any> {
    return this.dataService.overridePaNames(pads);
  }

  public initCatalogsObservables(refresh: boolean): void {
    if (refresh) {
      this.refreshCatalogsObservables();
    }
    this.rfsdOptionsBs$ = new BehaviorSubject([]);
    this.rfsdOptions$ = this.rfsdOptionsBs$.asObservable();
    this.unitOfMeasureOptionsBs$ = new BehaviorSubject([]);
    this.unitOfMeasureOptions$ = this.unitOfMeasureOptionsBs$.asObservable();
    this.infrastructureClassOptionsBs$ = new BehaviorSubject([]);
    this.infrastructureClassOptions$ = this.infrastructureClassOptionsBs$.asObservable();
    this.scopeCategoryOptionsBs$ = new BehaviorSubject([]);
    this.scopeCategoryOptions$ = this.scopeCategoryOptionsBs$.asObservable();
    this.scopeCategoryOptionsAllBs$ = new BehaviorSubject([]);
    this.scopeCategoryOptionsAll$ = this.scopeCategoryOptionsAllBs$.asObservable();
    this.locationOptionsBs$ = new BehaviorSubject([]);
    this.locationOptions$ = this.locationOptionsBs$.asObservable();
    this.kindOptionsBs$ = new BehaviorSubject([]);
    this.kindOptions$ = this.kindOptionsBs$.asObservable();
    this.itemsAndDetailsOptionsBs$ = new BehaviorSubject([]);
    this.itemsAndDetailsOptions$ = this.itemsAndDetailsOptionsBs$.asObservable();
    this.developmentAreaOptionsBs$ = new BehaviorSubject([]);
    this.developmentAreaOptions$ = this.developmentAreaOptionsBs$.asObservable();
    this.scenarioTypeOptionsBs$ = new BehaviorSubject([]);
    this.scenarioTypeOptions$ = this.scenarioTypeOptionsBs$.asObservable();
    this.stateOptionsBs$ = new BehaviorSubject([]);
    this.stateOptions$ = this.stateOptionsBs$.asObservable();
    this.basinOptionsBs$ = new BehaviorSubject([]);
    this.basinOptions$ = this.basinOptionsBs$.asObservable();
    this.geographicAreaBs$ = new BehaviorSubject([]);
    this.geographicArea$ = this.geographicAreaBs$.asObservable();
    this.landDamagesPipeDataBs$ = new BehaviorSubject([]);
    this.landDamagesPipeData$ = this.landDamagesPipeDataBs$.asObservable();
    this.landDamagesOtherDataBs$ = new BehaviorSubject([]);
    this.landDamagesOtherData$ = this.landDamagesOtherDataBs$.asObservable();
    this.contingencyOptionsBs$ = new BehaviorSubject([]);
    this.contingencyOptions$ = this.contingencyOptionsBs$.asObservable();
    this.compressorCapacityAssumptionsOptionsBs$ = new BehaviorSubject([]);
    this.compressorCapacityAssumptionsOptions$ = this.compressorCapacityAssumptionsOptionsBs$.asObservable();
    this.scenarioAfeTypeOptionsBs$ = new BehaviorSubject([]);
    this.scenarioAfeTypeOptions$ = this.scenarioAfeTypeOptionsBs$.asObservable();
    if (refresh) {
      this.initializeMasterData();
    }
  }

  public initTransactionalObservables(refresh: boolean): void {
    if (refresh) {
      this.refreshTransactionalObservables();
    }
    this.newScenarioBs$ = new BehaviorSubject<boolean>(false);
    this.newScenario$ = this.newScenarioBs$.asObservable();
    this.importExecutedBs$ = new BehaviorSubject<boolean>(false);
    this.importExecuted$ = this.importExecutedBs$.asObservable();
    this.tryExportBs$ = new BehaviorSubject<boolean>(false);
    this.tryExport$ = this.tryExportBs$.asObservable();
    this.showSavePopUpBs$ = new BehaviorSubject<boolean>(false);
    this.showSavePopUp$ = this.showSavePopUpBs$.asObservable();
    this.showSavePopUpMessageBs$ = new BehaviorSubject<string>(
      'There are unsaved changes to this scenario. Are you certain that you want to navigate away from this page?'
    );
    this.showSavePopUpMessage$ = this.showSavePopUpMessageBs$.asObservable();

    this.scenarioIdBs$ = new BehaviorSubject<string>(Guid.EMPTY);
    this.scenarioID$ = this.scenarioIdBs$.asObservable();
    this.yearBs$ = new BehaviorSubject<number>(null);
    this.year$ = this.yearBs$.asObservable();

    this.changeTabBs$ = new BehaviorSubject<number>(1);
    this.changeTab$ = this.changeTabBs$.asObservable();
    this.sequenceIdBs$ = new BehaviorSubject<string>(Guid.EMPTY);
    this.sequenceId$ = this.sequenceIdBs$.asObservable();

    this.padConfigDataBs$ = new BehaviorSubject([]);
    this.padConfigData$ = this.padConfigDataBs$.asObservable();

    this.contingencyBs$ = new BehaviorSubject({
      estimateDescription: '',
      numberOfDevWells: '',
      estimateTimeFrame: '',
      valuePercentage: 0,
      notes: '',
    });
    this.contingency$ = this.contingencyBs$.asObservable();

    this.pricetSummaryBs$ = new BehaviorSubject({
      id: '',
      developmentArea: '',
      scenarioType: '',
      scenarioName: '',
      handover: null,
      contingency: null,
      state: '',
      tax: 0,
      basin: '',
      geographicArea: '',
      scenarioNotes: '',
      year: 2019,
    });
    this.pricetSummary$ = this.pricetSummaryBs$.asObservable();

    this.scenarioPbSummaryBs$ = new BehaviorSubject({
      $id: '',
      name: '',
      sequenceName:'',
      fileName: '',
      lastPbSaveDate: '',
      forecastNotes: '',
      rigCount: 0,
      scenarioRigs: [],
      scenarioId: this.scenarioIdBs$.value,
      id: '',
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
    });
    this.ScenarioPbSummary$ = this.scenarioPbSummaryBs$.asObservable();

    this.padBuilderDataBs$ = new BehaviorSubject([]);
    this.padBuilderData$ = this.padBuilderDataBs$.asObservable();

    this.padBuilderDataPageBs$ = new BehaviorSubject([]);
    this.padBuilderDataPage$ = this.padBuilderDataPageBs$.asObservable();

    this.padPagerBs$ = new BehaviorSubject<PagerModel>({
      currentPage: 1,
      pages: [1],
      pageSize: 5,
      total: 0,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.padPager$ = this.padPagerBs$.asObservable();

    this.facilityPadBuilderDataBs$ = new BehaviorSubject([]);
    this.facilityPadBuilderData$ = this.facilityPadBuilderDataBs$.asObservable();
    this.facilityPadBuilderDataPageBs$ = new BehaviorSubject([]);
    this.facilityPadBuilderDataPage$ = this.facilityPadBuilderDataPageBs$.asObservable();
    this.facilityPadPagerBs$ = new BehaviorSubject<PagerModel>({
      currentPage: 1,
      pages: [1],
      pageSize: 5,
      total: 0,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.facilityPadPager$ = this.facilityPadPagerBs$.asObservable();

    this.scopeCountPadBuilderDataBs$ = new BehaviorSubject([]);
    this.scopeCountPadBuilderData$ = this.scopeCountPadBuilderDataBs$.asObservable();
    this.scopeCountPadBuilderDataPageBs$ = new BehaviorSubject([]);
    this.scopeCountPadBuilderDataPage$ = this.scopeCountPadBuilderDataPageBs$.asObservable();
    this.scopeCountPadPagerBs$ = new BehaviorSubject<PagerModel>({
      currentPage: 1,
      pages: [1],
      pageSize: 5,
      total: 0,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.scopeCountPadPager$ = this.scopeCountPadPagerBs$.asObservable();

    this.costSheetPadBuilderDataBs$ = new BehaviorSubject([]);
    this.costSheetPadBuilderData$ = this.costSheetPadBuilderDataBs$.asObservable();
    this.costSheetPadBuilderDataPageBs$ = new BehaviorSubject([]);
    this.costSheetPadBuilderDataPage$ = this.costSheetPadBuilderDataPageBs$.asObservable();
    this.costSheetPadPagerBs$ = new BehaviorSubject<PagerModel>({
      currentPage: 1,
      pages: [1],
      pageSize: 5,
      total: 0,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.costSheetPadPager$ = this.costSheetPadPagerBs$.asObservable();

    this.summaryPadBuilderDataBs$ = new BehaviorSubject([]);
    this.summaryPadBuilderData$ = this.summaryPadBuilderDataBs$.asObservable();
    this.summaryPadBuilderDataPageBs$ = new BehaviorSubject([]);
    this.summaryPadBuilderDataPage$ = this.summaryPadBuilderDataPageBs$.asObservable();
    this.summaryPadPagerBs$ = new BehaviorSubject<PagerModel>({
      currentPage: 1,
      pages: [1],
      pageSize: 5,
      total: 0,
      firstPageEnabled: false,
      lastPageEnabled: true,
      nextPageEnabled: true,
      previousPageEnabled: false,
    });
    this.summaryPadPager$ = this.summaryPadPagerBs$.asObservable();

    this.infrastructureItemsBs$ = new BehaviorSubject({
      year: this.yearBs$.value,
      inputItems: [],
      pads: [],
      padBuilderPads: [],
    });
    this.infrastructureItems$ = this.infrastructureItemsBs$.asObservable();

    this.padByPadCostUpdateBs$ = new BehaviorSubject<any>(null);
    this.padByPadCostUpdate$ = this.padByPadCostUpdateBs$.asObservable();

    this.reportOutBs$ = new BehaviorSubject([]);
    this.reportOut$ = this.reportOutBs$.asObservable();

    this.inputSummaryBs$ = new BehaviorSubject({
      developmentArea: null,
      scenarioType: null,
      state: null,
      handoverNumber: '',
      scenarioName: '',
      basin: null,
      geographicArea: null,
      contingency: this.contingencyBs$.value.valuePercentage,
      flowlineThreshold: 15840,
      assumedNumWellsPerTestSeparator: 8,
      greenfieldInstallTimeframe: 12,
      isDeleted: false,
      year: 2019,
    });
    this.inputSummary$ = this.inputSummaryBs$.asObservable();

    this.costLogicDetectorBs$ = new BehaviorSubject({
      isMappedCostReady: false,
      isInfrastructureItemsReady: false,
      isPadBuilderDataReady: false,
    });

    this.costLogicDetector$ = this.costLogicDetectorBs$.asObservable();

    this.scenarioPadBracketCostsBs$ = new BehaviorSubject([]);
    this.scenarioPadBracketCosts$ = this.scenarioPadBracketCostsBs$.asObservable();

    this.loadSpinnerBs$ = new BehaviorSubject(false);
    this.loadSpinner$ = this.loadSpinnerBs$.asObservable();
  }

  private initializeCndMappingCost(): void {
    if (this.cndCostMappingSftBs$.value.length == 0) {
      this.dataService.getCndCostMappingSftWithCosts().subscribe(
        data => {
          this.cndCostMappingSftBs$.next(data);
          let costLogicDetector = this.costLogicDetectorBs$.value;
          costLogicDetector.isMappedCostReady = true;
          this.costLogicDetectorBs$.next(costLogicDetector);
          this.errorInitPricet = false;
        },
        err => {
          this.errorInitPricet = true;
        }
      );
    } else {
      let costLogicDetector = this.costLogicDetectorBs$.value;
      costLogicDetector.isMappedCostReady = true;
      this.costLogicDetectorBs$.next(costLogicDetector);
    }
  }
  private refreshCatalogsObservables(): void {
    this.rfsdOptionsBs$.complete();
    this.unitOfMeasureOptionsBs$.complete();
    this.infrastructureClassOptionsBs$.complete();
    this.scopeCategoryOptionsBs$.complete();
    this.scopeCategoryOptionsAllBs$.complete();
    this.locationOptionsBs$.complete();
    this.kindOptionsBs$.complete();
    this.itemsAndDetailsOptionsBs$.complete();
    this.developmentAreaOptionsBs$.complete();
    this.scenarioTypeOptionsBs$.complete();
    this.stateOptionsBs$.complete();
    this.basinOptionsBs$.complete();
    this.geographicAreaBs$.complete();
    this.landDamagesPipeDataBs$.complete();
    this.landDamagesOtherDataBs$.complete();
    this.contingencyOptionsBs$.complete();
    this.compressorCapacityAssumptionsOptionsBs$.complete();
    this.scenarioAfeTypeOptionsBs$.complete();
    // this.cndCostMappingSftBs$.complete();
  }

  private refreshTransactionalObservables(): void {
    this.newScenarioBs$.complete();
    this.importExecutedBs$.complete();
    this.tryExportBs$.complete();
    this.showSavePopUpBs$.complete();
    this.showSavePopUpMessageBs$.complete();
    this.scenarioIdBs$.complete();
    this.yearBs$.complete();
    this.changeTabBs$.complete();
    this.sequenceIdBs$.complete();
    this.padConfigDataBs$.complete();
    this.contingencyBs$.complete();
    this.pricetSummaryBs$.complete();
    this.scenarioPbSummaryBs$.complete();
    this.padBuilderDataBs$.complete();
    this.padBuilderDataPageBs$.complete();
    this.padPagerBs$.complete();
    this.infrastructureItemsBs$.complete();
    this.padByPadCostUpdateBs$.complete();
    this.reportOutBs$.complete();
    this.inputSummaryBs$.complete();
    this.costLogicDetectorBs$.complete();
    this.scenarioPadBracketCostsBs$.complete();
    this.facilityPadBuilderDataBs$.complete();
    this.facilityPadBuilderDataPageBs$.complete();
    this.scopeCountPadBuilderDataBs$.complete();
    this.scopeCountPadBuilderDataPageBs$.complete();
    this.costSheetPadBuilderDataBs$.complete();
    this.costSheetPadBuilderDataPageBs$.complete();
    this.summaryPadBuilderDataBs$.complete();
    this.summaryPadBuilderDataPageBs$.complete();
    this.facilityPadPagerBs$.complete();
    this.scopeCountPadPagerBs$.complete();
    this.costSheetPadPagerBs$.complete();
    this.summaryPadPagerBs$.complete();
  }

  public setCostLogicDetector(value: boolean): void {
    let costLogicDetector = this.costLogicDetectorBs$.value;
    costLogicDetector.isInfrastructureItemsReady = value;
    costLogicDetector.isPadBuilderDataReady = value;
    this.costLogicDetectorBs$.next(costLogicDetector);
  }

  public setCostLogicDetectorForMappedCost(value: boolean): void {
    let costLogicDetector = this.costLogicDetectorBs$.value;
    costLogicDetector.isMappedCostReady = value;
    this.costLogicDetectorBs$.next(costLogicDetector);
  }

  public updateScenarioCost(year: string): Observable<any> {
    return this.dataService.updateScenarioCost(this.scenarioIdBs$.value, year);
  }
  public updatePadCostYear(data: any[]) {
    if (data && data.length) {
      let padData = clonedeep(this.padBuilderDataPageBs$.value);
      if (padData && padData.length) {
        padData.map(d => {
          let item = data.find(x => x.id == d.id);
          if (item) {
            d.yearCost = item.yearCost;
          }
        });
        this.padBuilderDataPageBs$.next(padData);
      }
    }
  }

  public getScenarioPadBracketCosts(): void {
    const scenarioId = this.scenarioIdBs$.value;
    this.dataService
      .getScenarioPadBracketCostByScenarioId(scenarioId)
      .subscribe(data => this.scenarioPadBracketCostsBs$.next(data));
  }

  public setScenarioPadBracketOverrideCost(facilityItemId: string, year: number, overrideCost: number): void {
    const overrideScenarioPadBracket = this.scenarioPadBracketCostsBs$.value.map(item => {
      if (item.facilityItemId === facilityItemId && item.yearCost === year) {
        item.costOverride = overrideCost;
      }
      return item;
    });
    this.scenarioPadBracketCostsBs$.next(overrideScenarioPadBracket);
  }

  public async updateInfrastructureItemDetails(dataItem: any, rowIndex: any, operation: string): Promise<string> {
    let infraItems = clonedeep(this.infrastructureItemsBs$.value);
    let dataItemId = clonedeep(dataItem).id;
    if (operation == 'Clone') {
      dataItem.isCloned = true;
      dataItem.id = Guid.create().toString();
      dataItem.itemDetail = dataItem.itemDetails.filter(x => x.name == dataItem.itemDetail.name)[0];
      let index = infraItems.inputItems.findLastIndex(
        x => x.respToExpandFacilityItemId == dataItemId && x.isCloned == false
      );
      index++;
      dataItem = await this.setPadCountZero(dataItem);
      infraItems.inputItems.splice(index, 0, dataItem);
      console.log('parent item', dataItem);
      let childItems = infraItems.inputItems.filter(x => x.respToExpandFacilityItemId == dataItemId);
      let i = 1;
      for await (let childItem of childItems) {
        let newChildItem = clonedeep(childItem);
        let itemDetail = newChildItem.itemDetails.filter(x => x.name == newChildItem.itemDetail.name)[0]
        newChildItem.id = Guid.create().toString();
        newChildItem.isCloned = true;
        newChildItem.respToExpandFacilityItemId = dataItem.id;
        newChildItem.itemDetail = itemDetail;
        index++;
        newChildItem = await this.setPadCountZero(newChildItem);
        infraItems.inputItems.splice(index, 0, newChildItem);
      }
    } else {
      //delete
      let index = infraItems.inputItems.findIndex(x => x.id == dataItemId);
      infraItems.inputItems.splice(index, 1);

      let childItems = infraItems.inputItems.filter(x => x.respToExpandFacilityItemId == dataItemId);
      childItems.forEach(childItem => {
        infraItems.inputItems.findIndex(x => x.id == childItem.Id);
        infraItems.inputItems.splice(index, 1);
      });
    }
    this.infrastructureItemsBs$.next(infraItems);
    this.calculatePadFields();
    this.calculatePadItemCounts(false,dataItem.id);
    return '';
  }

  public setPadCountZero(dataItem:any)
  {
    let padData = clonedeep(this.padBuilderDataPageBs$.value);
    for(let pad of padData){
      dataItem[pad.padName] = 0;
    }
    return dataItem;
  }

  public updateChildItemDetails(
    parentId: string,
    newParentId: string,
    respToExpandFacilityItemId: string
  ): Promise<boolean> {
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    if (respToExpandFacilityItemId != Guid.EMPTY) {
      return new Promise(resolve => {
        resolve(true);
      });
    }
    let childItems = infraItems.inputItems.filter(x => x.respToExpandFacilityItemId == parentId);
    for (let item of childItems) {
      item.respToExpandFacilityItemId = newParentId;
    }
    this.infrastructureItemsBs$.next(infraItems);
    return new Promise(resolve => {
      resolve(true);
    });
  }

  public updateAllItemIsVisible(isVisible : boolean): void {
    const infraItems: InputItemsModel = clonedeep(this.infrastructureItemsBs$.value);
    this.inputItemsService.updateAllItemIsVisible(infraItems.inputItems,isVisible);
    this.infrastructureItemsBs$.next(infraItems);
  }
}
