import { Component, OnInit, ViewChild } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ISelectPads } from '../../../../api/SelectPads';
import { IPadNames } from '../../../../api/pad-names';
import { ScenarioPadBracket } from '../../../../api/scenario-pad-bracket';
import { DataService } from '../../../../services/data.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';

@Component({
  selector: 'app-select-pads-after-smart-import',
  templateUrl: './select-pads-after-smart-import.component.html',
  styleUrls: ['./select-pads-after-smart-import.component.css']
})
export class SelectPadsAfterSmartImportComponent implements OnInit {
  @ViewChild('multiSelectTo', { static: false }) public multiSelectTo: MultiSelectComponent;
  
  public selectPadsAssignmentDisabled = false;
  public selectPadsAssignmentTab = true;
  public valuePadBracketSelect: ScenarioPadBracket[];
  public padsBeforeSaveList: any[];
  public nextButtonSelectDisabled = true;
  public isCloseDisabledTo = false;
  public isOpenDisabledTo= false;
  public hiddenOptions = false;
  public padBracketToList: any[];
  public padBracketToListFiltered: any[];
  public summaryDisabled = true;
  public summaryTab = false;
  public summaryData: IPadNames[] = [];
  public copyPadsAssigmentTab = true;
  public copyPadsAssigmentDisabled = false;
  public selectPadItems: ISelectPads;
  public scenarioId: string;

  constructor(private spinner: NgxSpinnerService,
    private dialog: DialogRef,
    private dataService: DataService,    
    private scenarioStoreService: ScenarioStoreService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.scenarioStoreService.scenarioID$.subscribe(scenarioid => {
      this.scenarioId = scenarioid;
      this.dataService.getPadbuilderData(this.scenarioId).subscribe(data => {
        this.padsBeforeSaveList = data;
        if(this.padsBeforeSaveList != undefined && this.padsBeforeSaveList != null){
          this.padsBeforeSaveList.sort(
            (n1,n2)=>{
            const dt1 = Date.parse(n1.spudDate);
            const dt2 = Date.parse(n2.spudDate);
            return dt1-dt2;
          })
        }
        this.spinner.hide();
      }, ((error: any) => {
        this.spinner.hide();
      }));  
    });      
  }

  public selectAllPadsToImport() {
    this.valuePadBracketSelect = this.padsBeforeSaveList;
    this.multiSelectTo.value = this.valuePadBracketSelect;
    this.nextButtonSelectDisabled = false;
  }

  public clearAllPadsToImport() {
    this.multiSelectTo.reset();
    this.valuePadBracketSelect = [];
    this.nextButtonSelectDisabled = true;
  }

  public toPadSelectChange(value: any): void {
    this.valuePadBracketSelect = value;
    this.nextButtonSelectDisabled = value.length >= 1 ? false : true;
    this.isCloseDisabledTo = false;
    this.isOpenDisabledTo = false;
  }

  public onOpenTo(event: any): void {
    if (this.isOpenDisabledTo) {
      event.preventDefault();
    }
  }

  public handleFilterScenarioToSelection(value: any) {
    if (this.padBracketToList)
    {
      this.padBracketToListFiltered = this.padBracketToList.filter(
        (s) => s.padName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }    
  }

  public moveSelectSummary() {
    this.summaryDisabled = false;
    this.selectPadsAssignmentTab = false;
    this.summaryTab = true;
    this.selectPadsAssignmentDisabled = true;
    this.summaryData = [];
    for (let i = 0; i < this.valuePadBracketSelect.length; i++) {
      const item: IPadNames = {
        padNameFrom: null,
        padNameTo: this.valuePadBracketSelect[i].padName,
      };
      this.summaryData.push(item);
    }
  }

  public previousPage(): void {
    this.copyPadsAssigmentTab = true;
    this.selectPadsAssignmentTab = true;
    this.summaryTab = false;
    this.copyPadsAssigmentDisabled = false;
    this.selectPadsAssignmentDisabled = false;
    this.summaryDisabled = true;
  }

  public selectPads(): void {
    this.spinner.show();
    this.selectPadItems = this.preparedSelectedScenarios2();
    this.dataService.SelectPads(this.selectPadItems).subscribe(data => {
      this.showInfo('The import was completed.', 'success');
      this.scenarioStoreService.setCostLogicDetector(false);
      this.scenarioStoreService.padbuilderImportCompleted(this.scenarioId);
      this.spinner.hide();
      this.dialog.close();
    })
  }

  public preparedSelectedScenarios2(): ISelectPads {
    const selectedPads = {} as ISelectPads;
    var pads: string[] = [];
    this.valuePadBracketSelect.forEach((element, index) => {
      pads.push(this.valuePadBracketSelect[index].padName);
    });

    selectedPads.scenarioId = this.scenarioId;
    selectedPads.selectedPads = pads;

    return selectedPads;
  }

  public showInfo(value: string, typeChange: string) {
    switch (typeChange) {
      case 'info': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 200 },
          type: { style: 'info', icon: true },
        });
        break;
      }
      case 'success': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 300 },
          type: { style: 'success', icon: true },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onClickOk(): void {
    this.selectPads();
  }

}
