import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricettool-open',
  templateUrl: './pricet-tool-open.component.html',
  styleUrls: ['./pricet-tool-open.component.css'],
})
export class PricetToolOpenComponent implements OnInit {
  public dialogOpened = true;

  constructor() {}

  ngOnInit() {
    this.dialogOpened = true;
  }

  public closeDialog(component) {
    this.dialogOpened = false;
  }

  public openDialog(component) {
    this.dialogOpened = true;
  }
}
