<kendo-grid
  #smartImportSequenceGrid
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [loading]="isLoading"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  [rowClass]="rowCallback"
  [height]="800"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!dasSimpleScopeCountEditService.hasChanges()"
      (click)="cancelChanges(smartImportSequenceGrid)"
      [primary]="true"
    >
      Cancel
    </button>
  </ng-template>
  <kendo-grid-column
    title="Pad Name"
    field="padName"
    [editable]="false"
    width="150px"
    [locked]="true"
  ></kendo-grid-column>
  <kendo-grid-column-group title="SMART Sequence Imports" [locked]="false">
    <kendo-grid-column
      title="# Wells"
      field="numberOfWells"
      [editable]="false"
      width="100px"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column title="Dev. Area" field="developmentArea" [editable]="false" width="150px"></kendo-grid-column>
    <kendo-grid-column title="Section" field="section" [editable]="false" width="150px"></kendo-grid-column>
    <kendo-grid-column title="Facility" field="facility" [editable]="false" width="150px"></kendo-grid-column>
    <kendo-grid-column
      field="popDate"
      title="POP Date"
      [editable]="false"
      width="150px"
      [filterable]="filterable"
      filter="date"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.popDate | date: 'MM/dd/yyyy' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker
          [value]="dataItem.popDate"
          [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('popDate')"
        ></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column
    field="padCost"
    title="Pad Cost ($M)"
    [editable]="true"
    editor="numeric"
    filter="numeric"
    width="150px"
    class="no-padding-no-background"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [class]="'custom-cell-template'" [class.overridden-field]="istOverriddenField(dataItem, 'padCost')">
        {{ getTotalPadCost(dataItem) | currency: 'USD':'symbol':'1.0' }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column-group title="CTB’s / SAT" [locked]="false">
    <kendo-grid-column
      field="train1Ctb"
      title="1-Tr CTB"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value x (((Greenfield 1-Train CTB Install '20 Design') + (CTB Pad Cost * 102) + (Commercial oil tie-in) + (60' Facility Radio Tower) + (Standard network equipment package) + (Land Damages)))"
          >1-Tr CTB</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="train1CtbAddition"
      title="1-Tr CTB Add"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span title="Input Value x ((1-Train CTB Addition) +  (CTB Pad Cost * 102) + (Land Damages))"
          >1-Tr CTB Add</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="satellite" title="Sat" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value * (Greenfield Satellite Install + Hi-Accuracy Satellite Metering Upgrade + 60' Facility Radio Tower + Standard network equipment package + (Land Damages))"
          >Satellite</span
        >
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group title="Gas" [locked]="false">
    <kendo-grid-column
      field="compressorStation"
      title="CS"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value x (Greenfield Compressor Station Install + Compressor Station VRU (Detail = FX8) + Commercial gas tie-in +  flare + 60' Facility Radio Tower + Standard network equipment package + Land Damages)"
          >CS</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="rentals"
      title="Rentals"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
    </kendo-grid-column>
    <kendo-grid-column field="coop" title="COOP" [editable]="true" editor="numeric" filter="numeric" width="150px">
    </kendo-grid-column>
    <kendo-grid-column
      field="electricCoopStation"
      title="Elec COOP Station"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="electricCoopTrain"
      title="Elec COOP Train"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group title="Water" [locked]="false">
    <kendo-grid-column
      field="train2Swd"
      title="2-Tr SWD"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value x (Greenfield 2-Train SWD Install + (6 x 'SWD Injection H-Pump (at station)') + (SWD Well Pad and Hookup) + ('SWD Inj Line (Detail)' x 1000) + SWD Inj Line Riser  + 60' Facility Radio Tower + Standard network equipment package + (Land Damages))"
          >2-Tr SWD</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="train2SwdAddition"
      title="2-Tr SWD Add"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span title="Input Value * (2-Train SWD Addition + (6 x 'SWD Injection H-Pump (at station)') + Land Damages)"
          >2-Tr SWD Add</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="fracPondVolume"
      title="Frac Pond Vol."
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value * (Frac Ponds (Detail = RWCS or EPS) + 'Aeration/Circulation Skid (for recycle)' (only when Detail = RWCS)) + (Land Damages)"
          >Frac Pond Vol.</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="fru" title="FRU" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value * (Fixed Water Recycle Unit Install +  Recycle Transfer Pump Skid + (Recycle Water Transfer Line (Detail = 8' SDR7 HDPE) x 1000) + Land Damages)"
          >FRU</span
        >
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group title="Pipeline & Gen. Infrastructure (Length in Mi)" [locked]="false">
    <kendo-grid-column field="lpGas" title="LP Gas" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value * 5280 * 'In-Field Gas Gathering/Sales, LP') + ((Lengh Pipeline Rounded Up to Nearest Mile)* LP Gas Pipeline Riser (based on line size)) + (Input Value * 5280 * Land Damages)"
          >LP Gas</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="hpGas" title="HP Gas" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value x 5280 x 'In-Field Gas Gathering/Sales, HP' Detail cost) + ((Lengh Pipeline Rounded Up to Nearest Mile) * HP Gas Pipeline Riser (based on line size)) + (Input Value x 5280 x Land Damages)"
          >HP Gas</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="water" title="Water" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value x 5280 x 'Produced Water Gathering') + ((Lengh Pipeline Rounded Up to Nearest Mile)*Produced Water Pipeline Riser (based on line size)) + (Input Value x 5280 x Land Damages)"
          >Water</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="liquid" title="Liquid" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value x 5280 x 'In-Field Liquids Gathering from Satellite') + ((Lengh Pipeline Rounded Up to Nearest Mile)* Liquids Pipeline Riser (based on line size)) + (Input Value x 5280 x Land Damages)"
          >Liquid</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="gasLift"
      title="Gas Lift"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
      class="no-padding-no-background"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value x 5280 x 'Gas Lift Distribution') + ((Lengh Pipeline Rounded Up to Nearest Mile)* Gas Lift Pipeline Riser (based on line size)) + (Input Value x 5280 x Land Damages)"
          >Gas Lift</span
        >
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [class]="'custom-cell-template'" [class.overridden-field]="istOverriddenField(dataItem, 'gasLift')">
          {{ dataItem.gasLift }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="flowlines"
      title="Flowlines"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
      class="no-padding-no-background"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="(Input Value x 5280 x 'Flowline Materials and Installation' x 'Well Count') + (Input Value x 5280 x ('Flowline Trenching' + Land Damages))"
          >Flowlines</span
        >
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [class]="'custom-cell-template'" [class.overridden-field]="istOverriddenField(dataItem, 'flowlines')">
          {{ dataItem.flowlines }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="generalInfrastructure"
      title="Gen.Infr"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
      class="no-padding-no-background"
    >
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value x 5280 x (Road Constrution and Caliche + EDS Main Line (>6 MVA) + Fiber Optic Cable + Land Damages)"
          >Gen.Infr</span
        >
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span
          [class]="'custom-cell-template'"
          [class.overridden-field]="istOverriddenField(dataItem, 'generalInfrastructure')"
        >
          {{ dataItem.generalInfrastructure }}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group title="Other" [locked]="false">
    <kendo-grid-column field="tower" title="Tower" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span title="Input Value * 300' Radio Tower (Backbone)">Tower</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="bgwh" title="BGWH" [editable]="true" editor="numeric" filter="numeric" width="150px">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <span
          title="Input Value * ('Well Pad - Multi-Use / Sub-Grade WH' where Detail = 'Below Grade' + ('Per-Well Cost Adder: Sub-Grade WH Cellars' x Well count) + Land Damages)"
          >BGWH</span
        >
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="misc"
      title="Misc. ($M)"
      [editable]="true"
      editor="numeric"
      filter="numeric"
      width="150px"
    >
    </kendo-grid-column>
    <kendo-grid-column field="padBase" [hidden]="true" width="150px"> </kendo-grid-column>
    <kendo-grid-column field="wellBase" [hidden]="true" width="150px"> </kendo-grid-column>
    <kendo-grid-column field="comments" title="Comments" [editable]="true" width="1150px"> </kendo-grid-column>
  </kendo-grid-column-group>
</kendo-grid>
