import { Injectable, EventEmitter } from '@angular/core';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { Observable } from 'rxjs';
import { InlineActions } from 'src/app/api/inline-actions.enum';
import { map, catchError } from 'rxjs/operators';
import { ISmartImportSequence } from '../../../api/smart-import-sequence';
import { SmartImportSequenceService } from '../../../services/smart-import-sequence.service';
import clonedeep from 'lodash.clonedeep';

@Injectable()
export class DasSimpleScopeCountEditService extends InlineEditService implements IEditableInline {
  public data: ISmartImportSequence[] = [];
  public originalData: ISmartImportSequence[] = [];
  public createdItems: ISmartImportSequence[] = [];
  public updatedItems: ISmartImportSequence[] = [];
  public deletedItems: ISmartImportSequence[] = [];
  public dataFromDataSequence: ISmartImportSequence[] = [];
  public completed: EventEmitter<boolean>;
  public dasSimpleId: string;

  constructor(private smartImportSequenceService: SmartImportSequenceService) {
    super();
  }

  public initialize(id: string) {
    this.dasSimpleId = id;
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: ISmartImportSequence[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.smartImportSequenceService
      .createSmartImportSequence(this.createdItems)
      .pipe((unitOfWork: any) => {
        return unitOfWork;
      });
  }

  public retrieve(): Observable<any> {
    this.loader = new EventEmitter();
    this.loader.emit(true);
    return this.smartImportSequenceService.getByDasSimpleId(this.dasSimpleId).pipe(
      map(
        (data: any[]) => {
          this.loader.emit(false);
          this.loader.complete();
          if (this.dataFromDataSequence.length > 0) {
            data = clonedeep(this.dataFromDataSequence);
          }
          return data
          .sort((a, b) => new Date(a.popDate).getTime() - new Date(b.popDate).getTime());
        },
        catchError(error => {
          this.loader.error(error);
          this.loader.complete();
          return error;
        })
      )
    );
  }

  public update(): Observable<any> {
    return this.smartImportSequenceService
      .updateSmartImportSequence(this.updatedItems)
      .pipe((unitOfWork: any) => {
        this.dataFromDataSequence = [];
        return unitOfWork;
      });
  }

  public delete(): Observable<any> {
    return this.smartImportSequenceService
      .updateSmartImportSequence(this.deletedItems)
      .pipe((unitOfWork: any) => {
        return unitOfWork;
      });
  }

  public cancelChanges(): void {
    super.cancelChanges();
    this.dataFromDataSequence = [];
    this.fetch(InlineActions.Retrieve);
  }
}
