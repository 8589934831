export interface IPerformanceAFE extends RequestInit {
  id: string;
  performanceBodId: string;
  name: string;
  number: string;
  amount: number;
  isDefault?: boolean;
  isDeleted: boolean;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
  chargeCode: string;
}

export class PerformanceAFE implements IPerformanceAFE {
  public id: string;
  public performanceBodId: string;
  public name: string;
  public number: string;
  public amount: number;
  public isDefault: boolean;
  public isDeleted: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public chargeCode: string;
}
