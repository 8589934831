import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IMasterDataLog } from '../_models/master-data-logs.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})
export class MasterDataLogsService extends BehaviorSubject<any[]> {
  public data: any[] = [];
  constructor(private http: HttpClient) {
    super([]);
  }

  public getMasterDataLogsByTableType(tableType: string): Observable<IMasterDataLog[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'SFTMasterDataLogs/ListLogsByTableType/' + tableType;
    return this.http.get<IMasterDataLog[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        this.data = data;
        return this.data;
      })
    );
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
  }

  public createLogs(logs: IMasterDataLog[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'MasterDataLogs/Create';
    const toCreate = logs.map(item => {
      return {
        tableType: item.tableType,
        operation: item.operation,
        fieldName: item.fieldName,
        previousValue: item.previousValue ? item.previousValue.toString() : item.previousValue,
        currentValue: item.currentValue ? item.currentValue.toString() : item.currentValue,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(map(data => data as any[]));
  }
}
