<div *ngIf="isPreformanceBodEdited">
  <span style="color: #d51923; font-size: 1.7em; margin-top: .5em;"
    >Changes has been made to performance bod. Kindly reassign performance bod.</span
  >
</div>

<div class="panel-facility-details grid-container">
  <div class="row">
    <div class="col-5">
      <label><b> Associated Perf BOD</b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('associatedPrefBodName') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Facility Name</b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('facilityName') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Project Name</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected.projectName : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Project Number</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected.projectNumber : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>Basin Area </b></label>
    </div>
    <div class="col-7">{{ getInfoFromBasin('name') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Geographic Area </b></label>
    </div>
    <div class="col-7">{{ getInfoFromGeographicArea('name') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Development Area </b></label>
    </div>
    <div class="col-7">{{ getInfoFromDevelopmentArea('name') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Facility Type</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? getFacilityTypeById(facilitySelected)?.name : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Associated Wellpads </b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('wellPadName') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> New/Existing </b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('newOrExisting') }}</div>
  </div>

  <div
    class="row"
    [hidden]="isVisible(globals.APP_CONFIG_FACILITY_TYPE_CTB_ID, globals.APP_CONFIG_FACILITY_TYPE_SWD_ID)"
  >
    <div class="col-5">
      <label><b>No Of Trains </b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('noOfTrains') }}</div>
  </div>

  <div class="row" [hidden]="isVisible(globals.APP_CONFIG_FACILITY_TYPE_CS_ID)">
    <div class="col-5">
      <label><b>No Of Compressors</b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('noOfCompressors') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>&nbsp;</b></label>
    </div>
    <div class="col-7">&nbsp;</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> SFT Design Version</b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('sftDesignVersion') }}</div>
  </div>

  <div class="row" [hidden]="isVisible(globals.APP_CONFIG_FACILITY_TYPE_SWD_ID)">
    <div class="col-5">
      <label><b>Number of H-Pumps </b></label>
    </div>
    <div class="col-7">{{ getInfoFromFacilityPrefBod('numberOfHpumps') }}</div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>GPS Longitude </b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? getTextInfoFromFacilityProjectCostEstimates('longitude') : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> GPS Latitude </b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? getTextInfoFromFacilityProjectCostEstimates('latitude') : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>Default AFE No </b></label>
    </div>
    <div class="col-7">
      {{ defaultWbsNumber ? convertToAfeFormat(defaultWbsNumber) : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Default AFE Amount </b></label>
    </div>
    <div class="col-7">
      {{ defaultWbsAmount ? defaultWbsAmount : '0.00' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> TAX Rate </b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected.taxRate : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Contingency </b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected.contingency : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> General&Administrative </b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected.generalAndAdministrative : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b> Addition or Expansion </b></label>
    </div>
    <div class="col-7">
      {{
        facilitySelected.additionOrExpansion != null
          ? facilitySelected?.additionOrExpansion == 1
            ? 'Addition'
            : 'Expansion'
          : ''
      }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>BLM Facility Project</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected?.blmFacilityProject : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>Oil Takeaway/Destination</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected?.oilTakeawayDestination : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>Water Takeaway/Destination</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected?.waterTakeawayDestination : '' }}
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <label><b>Gas Takeaway/Destination</b></label>
    </div>
    <div class="col-7">
      {{ facilitySelected ? facilitySelected?.gasTakeawayDestination : '' }}
    </div>
  </div>
</div>
