import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable()
export class AuthGuard implements CanActivate {
  private isUser: boolean;

  constructor(private router: Router, authService: AuthenticationService) {
    this.isUser = authService.isInGroupValue;
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isUser) {
      return true;
    } else {
      this.router.navigate(['/notauthorized']);
      return false;
    }
  }
}
