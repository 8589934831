import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ICopyPads } from '../../../../api/CopyPads';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';
import { IPadNames } from '../../../../api/pad-names';
import { ScenarioPadBracket } from '../../../../api/scenario-pad-bracket';
import { DataService } from '../../../../services/data.service';
import { PermissionsProvider } from '../../../../services/permissions.provider';
import { AuthenticationService } from '../../../../services';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IViewPricetScenario } from '../../../../api/viewPricetScenario';

@Component({
  selector: 'app-copy-pads',
  templateUrl: './copy-pads.component.html',
  styleUrls: ['./copy-pads.component.css'],
})
export class CopyPadsComponent implements OnInit {
  @ViewChild('multiSelectTo', { static: false }) public multiSelectTo: MultiSelectComponent;
  @ViewChild('multiSelectFrom', { static: false }) public multiSelectFrom: MultiSelectComponent;

  public copyPadsAssigmentDisabled = false;
  public copyPadsAssigmentTab: boolean = true;
  public optionCopyPadData: any[] = [{ id: 0, name: 'Overwrite Existing Pads' }, { id: 1, name: 'Import New Pads' }];
  public optionValue = 0;
  public hiddenOptions = false;
  public buttonName = 'Next';
  public submitButtonName = 'Copy pads';
  public valuePadBracketTo: ScenarioPadBracket[];
  public valuePadBracketFrom: ScenarioPadBracket[];
  public nextButtonDisabled = true;
  public summaryDisabled = true;
  public summaryTab = false;
  public padBracketToList: any[] = [];
  public padBracketFromList: any[] = [];
  public padBracketFromListFiltered: any[] = [];
  public padBracketToListFiltered: any[] = [];
  public isCloseDisabledFrom = false;
  public isOpenDisabledFrom = false;
  public fromPadsDisabled = true;
  public isCloseDisabledTo = false;
  public isOpenDisabledTo = false;
  public summaryData: IPadNames[] = [];
  public copyPadItems: ICopyPads[] = [];
  public scenarioId: string;
  public originalPadBracketToList: ScenarioPadBracket[];
  public canEditCnd = false;
  public existingPads: ScenarioPadBracket[];
  public openExistingPadsDialog = false;
  public scenarioToName: string;
  public scenarioFromName: string;
  public selectPadsAssignmentTab = true;
  public selectPadsAssignmentDisabled = false;
  public scenariosFrom: IViewPricetScenario[];
  public scenariosFromFiltered: IViewPricetScenario[];
  public scenariosTo: IViewPricetScenario[];

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: DialogRef,
    private notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    private dataService: DataService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.scenarioID$.subscribe(data => {
      this.scenarioId = data;
      this.loadPadData();
    });
    this.getScenarios();
  }

  public getScenarios(): void {
    this.dataService.getScenarios().subscribe(data => {
      this.scenarioToName = '';
      if (data !== undefined && data !== null) {
        this.scenariosTo = data.filter(x => x.id === this.scenarioId);

        this.scenariosFrom = data.filter(x => x.id !== this.scenarioId);
        if (this.scenariosTo.length > 0) {
          this.scenarioToName = this.scenariosTo[0].name;
        }
        this.scenariosFromFiltered = this.scenariosFrom;
      }
    });
  }

  public loadPadData(): void {
    this.dataService.getScenarioPadBracketByScenarioId(this.scenarioId).subscribe(data => {
      this.originalPadBracketToList = data;
      this.padBracketToList = this.mapScenarioPadBracketToAny(data);
      this.padBracketToListFiltered = this.padBracketToList;
    });
  }

  // Kendo PopUp Copy Pads
  public scenarioFromChange(value: any): void {
    if (this.optionValue === 0) {
      this.valuePadBracketFrom = [];
      this.multiSelectFrom.data = [];
      this.multiSelectFrom.reset();
      this.isCloseDisabledFrom = false;
      this.isOpenDisabledFrom = false;
      this.comparePadItemsList();
      if (value !== null) {
        this.dataService.getScenarioPadBracketByScenarioId(value).subscribe(data => {
          this.padBracketFromList = this.mapScenarioPadBracketToAny(data);
          this.padBracketFromListFiltered = this.padBracketFromList;
        });
        this.scenarioFromName = this.scenariosFrom.filter(x => x.id === value)[0].name;
        this.fromPadsDisabled = false;
      } else {
        this.padBracketFromList = [];
        this.padBracketFromListFiltered = [];
        this.fromPadsDisabled = true;
      }
    } else if (this.optionValue === 1) {
      this.valuePadBracketFrom = [];
      this.multiSelectFrom.data = [];
      this.multiSelectFrom.reset();
      this.isCloseDisabledFrom = false;
      this.isOpenDisabledFrom = false;
      this.fromPadsDisabled = false;

      if (value !== null) {
        this.dataService.getScenarioPadBracketByScenarioId(value).subscribe(data => {
          this.padBracketFromList = this.mapScenarioPadBracketToAny(data);
          this.padBracketFromListFiltered = this.padBracketFromList;
        });
        this.scenarioFromName = this.scenariosFrom.filter(x => x.id === value)[0].name;
      }
    }
  }

  public optionCopyPadChange(value: any): void {
    if (value === 1) {
      this.hiddenOptions = true;
      this.buttonName = 'Import';
      this.submitButtonName = this.buttonName;
      this.multiSelectTo.reset();
      this.valuePadBracketTo = [];
      if (this.valuePadBracketFrom !== null && this.valuePadBracketFrom !== undefined) {
        if (this.valuePadBracketFrom.length > 0) {
          this.nextButtonDisabled = false;
        }
      }
    } else {
      this.hiddenOptions = false;
      this.buttonName = 'Next';
      this.submitButtonName = 'Copy Pads';
      this.comparePadItemsList();
    }
  }

  public comparePadItemsList(): void {
    if (
      this.valuePadBracketFrom !== undefined &&
      this.valuePadBracketFrom !== null &&
      this.valuePadBracketTo !== undefined &&
      this.valuePadBracketTo !== null
    ) {
      if (
        this.valuePadBracketTo.length === this.valuePadBracketFrom.length &&
        this.valuePadBracketFrom.length > 0 &&
        this.valuePadBracketTo.length > 0
      ) {
        this.nextButtonDisabled = false;
      } else {
        this.nextButtonDisabled = true;
      }
    }
  }

  public selectAll(choose: string) {
    if (choose.toLowerCase() === 'To'.toLowerCase()) {
      this.valuePadBracketTo = this.padBracketToList;
      this.multiSelectTo.value = this.valuePadBracketTo;
    } else {
      this.valuePadBracketFrom = this.padBracketFromList;
      this.multiSelectFrom.value = this.valuePadBracketFrom;
    }
    if (this.hiddenOptions) {
      this.showOrHideByOptionCopyDataSelected(!(this.multiSelectFrom.value.length > 0));
    } else {
      this.checkIfGotTheSameSelected();
    }
  }

  public clearAll(choose: string) {
    if (choose.toLowerCase() === 'To'.toLowerCase()) {
      this.multiSelectTo.reset();
      this.valuePadBracketTo = [];
    } else {
      this.multiSelectFrom.reset();
      this.valuePadBracketFrom = [];
    }
    if (this.hiddenOptions) {
      this.showOrHideByOptionCopyDataSelected(true);
    } else {
      this.checkIfGotTheSameSelected();
    }
  }

  private showOrHideByOptionCopyDataSelected(value: boolean): void {
    // It's mean Import pads is selected and not needed to check if have same in From dropdown list.
    this.nextButtonDisabled = value;
  }

  public checkIfGotTheSameSelected() {
    this.nextButtonDisabled =
      this.multiSelectTo.value.length > 0 && this.multiSelectFrom.value.length > 0
        ? !(this.multiSelectTo.value.length === this.multiSelectFrom.value.length)
        : true;
  }

  public fromPadChange(value: any): void {
    this.valuePadBracketFrom = value;
    if (this.optionValue === 0) {
      this.comparePadItemsList();
    } else if (this.optionValue === 1) {
      if (value.length > 0) {
        this.nextButtonDisabled = false;
      } else {
        this.nextButtonDisabled = true;
      }
    }

    // if (value.length === 100) {
    //   this.multiSelectFrom.toggle(false);
    //   this.isCloseDisabledFrom = true;
    //   this.isOpenDisabledFrom = true;
    // } else {
    //   this.isCloseDisabledFrom = false;
    //   this.isOpenDisabledFrom = false;
    // }
  }

  public onOpenFrom(event: any): void {
    if (this.isOpenDisabledFrom) {
      event.preventDefault();
    }
  }

  public handleFilterScenarioFromSelection(value: any) {
    if (this.padBracketFromList) {
      this.padBracketFromListFiltered = this.padBracketFromList.filter(
        s => s.padName.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
  }

  public handleFilterScenarioFrom(value: any) {
    if (this.scenariosFrom) {
      this.scenariosFromFiltered = this.scenariosFrom.filter(
        s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
  }

  public toPadChange(value: any): void {
    this.valuePadBracketTo = value;
    this.comparePadItemsList();
    this.isCloseDisabledTo = false;
    this.isOpenDisabledTo = false;
  }

  public onOpenTo(event: any): void {
    if (this.isOpenDisabledTo) {
      event.preventDefault();
    }
  }

  public handleFilterScenarioToSelection(value: any) {
    if (this.padBracketToList) {
      this.padBracketToListFiltered = this.padBracketToList.filter(
        s => s.padName.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }
  }

  public moveSummary(): void {
    switch (this.buttonName) {
      case 'Next':
        this.summaryDisabled = false;
        this.copyPadsAssigmentTab = false;
        this.summaryTab = true;
        this.copyPadsAssigmentDisabled = true;
        this.summaryData = [];
        for (let i = 0; i < this.valuePadBracketTo.length; i++) {
          const item: IPadNames = {
            padNameFrom: this.valuePadBracketFrom[i].padName,
            padNameTo: this.valuePadBracketTo[i].padName,
          };
          this.summaryData.push(item);
        }
        break;
      case 'Import':
        this.importPads();
        break;
      default:
        break;
    }
  }

  public mapScenarioPadBracketToAny(source: ScenarioPadBracket[]): any[] {
    return source.map((item: ScenarioPadBracket) => {
      return {
        id: item.id,
        padName: item.padNameOverride ? item.padNameOverride : item.padName,
        scenarioId: item.scenarioId,
      };
    });
  }

  public showInfo(value: string, typeChange: string) {
    switch (typeChange) {
      case 'info': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 200 },
          type: { style: 'info', icon: true },
        });
        break;
      }
      case 'success': {
        this.notificationService.show({
          content: value,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 300 },
          type: { style: 'success', icon: true },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  public preparedSelectedScenarios(): ICopyPads[] {
    let copyPadsItems: ICopyPads[] = [];
    this.valuePadBracketFrom.forEach((element, index) => {
      const item: ICopyPads = {
        index: 0,
        scenarioIdFrom: this.valuePadBracketFrom[index].scenarioId,
        scenarioIdTo: this.valuePadBracketTo[index].scenarioId,
        scenarioPadIdFrom: this.valuePadBracketFrom[index].id,
        scenarioPadIdTo: this.valuePadBracketTo[index].id,
        updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
      };
      copyPadsItems.push(item);
    });

    return copyPadsItems;
  }

  public previousPage(): void {
    this.copyPadsAssigmentTab = true;
    this.selectPadsAssignmentTab = true;
    this.summaryTab = false;
    this.copyPadsAssigmentDisabled = false;
    this.selectPadsAssignmentDisabled = false;
    this.summaryDisabled = true;
  }

  public copyPads(): void {
    this.spinner.show();
    this.showInfo('Copy Pads are in progress...', 'info');
    this.copyPadItems = this.preparedSelectedScenarios();
    this.dataService.CopyPads(this.copyPadItems).subscribe(data => {
      this.scenarioStoreService.openScenario(this.scenarioId);
      this.showInfo('The copy pad process was complete', 'success');

      this.spinner.hide();
      this.dialog.close();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  public importPads(): void {
    this.spinner.show();
    this.copyPadItems = [];
    for (const value of this.valuePadBracketFrom) {
      const item: ICopyPads = {
        index: 0,
        scenarioIdFrom: value.scenarioId,
        scenarioIdTo: this.scenarioId,
        scenarioPadIdFrom: value.id,
        scenarioPadIdTo: value.id,
        updatedBy: this.authenticationService.getCurrentUser().toUpperCase(),
      };
      this.copyPadItems.push(item);
    }

    this.dataService.CheckExistingPads(this.copyPadItems).subscribe(data => {
      if (data !== null && data !== undefined) {
        if (data.length > 0) {
          //Show Popup with pads
          this.existingPads = data;
          this.spinner.hide();
          this.openExistingPadsDialog = true;
        } else {
          this.showInfo('Import Pad(s) are in progress...', 'info');
          //Import pads
          this.dataService.ImportPads(this.copyPadItems).subscribe(data => {
            this.scenarioStoreService.openScenario(this.scenarioId);
            this.showInfo('The Import pad process was complete', 'success');
            this.spinner.hide();
            this.dialog.close();
          });
        }
      }
    });
  }

  public submitExistingPadsDialog() {
    this.existingPads.forEach(element => {
      this.copyPadItems.forEach((copypadItem, index) => {
        if (copypadItem.scenarioPadIdFrom == element.id) {
          this.copyPadItems.splice(index, 1);
        }
      });
    });
    if (this.copyPadItems.length > 0) {
      this.spinner.show();
      this.showInfo('Import Pad(s) are in progress...', 'info');
      this.dataService.ImportPads(this.copyPadItems).subscribe(data => {
        this.scenarioStoreService.openScenario(this.scenarioId);
        this.openExistingPadsDialog = false;
        this.showInfo('The Import pad process was complete', 'success');
        this.spinner.hide();
        this.dialog.close();
      });
    } else {
      this.showInfo('There are no new pads to import...', 'info');
      this.openExistingPadsDialog = false;
      this.dialog.close();
    }
  }

  public closeExistingPadsDialog() {
    this.openExistingPadsDialog = false;
  }

  public shouldSubmitButtonDisabled(): boolean {
    if (this.optionValue == 1) {
      return this.nextButtonDisabled;
    } else {
      return this.summaryDisabled;
    }
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onClickOk(): void {
    if (this.optionValue == 1) {
      this.importPads();
    } else {
      this.copyPads();
    }
  }
}
