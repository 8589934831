import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { InfrastructureRecycle } from 'src/app/api/infrastructure-recycle';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { InfrastructureRecycleService } from './infrastructure-recycle.service';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-recycle-draggable';

@Component({
  selector: 'app-infrastructure-recycle',
  templateUrl: './infrastructure-recycle.component.html',

})
export class InfrastructureRecycleComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public messageDuplicate: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodId: string;
  public padNames: string[] = [];
  public formGroup: FormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  public selectOption: any[] = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }];
  public infrastructureRecycleService: InfrastructureRecycleService;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();

  @ViewChild('infrastructureRecycle')
  private infrastructureRecycleGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,    
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(InfrastructureRecycleService) infrastructureRecycleServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.infrastructureRecycleService = infrastructureRecycleServiceFactory();
  }

  public ngOnInit(): void {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.infrastructureRecycleService.performanceBodId = id;
      this.infrastructureRecycleService.read();
    });

    this.view = this.infrastructureRecycleService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );    

    this.dragAndDropService.initialize(this.infrastructureRecycleService.data, DRAGGABLE_SELECTOR, () => {
      this.infrastructureRecycleService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(dataItem.id, [Validators.required]),
      performanceBodPackagesId: new FormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      description: new FormControl(dataItem.description, [Validators.required, Validators.maxLength(250)]),
      rotfFixRecycle: new FormControl(dataItem.rotfFixRecycle, [Validators.minLength(0), Validators.maxLength(250)]),
      isPadRequired: new FormControl(dataItem.isPadRequired, [Validators.required]),
      location: new FormControl(dataItem.location, [Validators.minLength(0), Validators.maxLength(250)]),
      maxTds: new FormControl(dataItem.maxTds, [Validators.minLength(0), Validators.maxLength(250)]),
      blendRadio: new FormControl(dataItem.blendRadio, [Validators.minLength(0), Validators.maxLength(250)]),
      comments: new FormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(500)]),
      throughputMbwpd: new FormControl(dataItem.throughputMbwpd, [Validators.min(0), Validators.max(100000)]),
      readyForServiceDate: new FormControl(dataItem.readyForServiceDate, [Validators.required]),
      isDeleted: new FormControl(dataItem.isDeleted, [Validators.required]),
      createdBy: new FormControl(dataItem.createdBy),
      createdDate: new FormControl(dataItem.createdDate),
      updatedBy: new FormControl(dataItem.updatedBy),
      updatedDate: new FormControl(dataItem.updatedDate),
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
            args.sender.data.data[i].description === formGroup.value.description.trim() &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }

      if (this.messageDuplicate) {
        dataItem.updatedDate = new Date();
        // dataItem.updatedBy = this.currentUser.cai;

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;

        this.infrastructureRecycleService.assignValues(dataItem, formGroup.value);
        this.infrastructureRecycleService.updateItem(dataItem);
      }
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: InfrastructureRecycle = {
      id: Guid.create().toString(),
      performanceBodPackagesId: ' ',
      description: null,
      rotfFixRecycle: null,
      isPadRequired: false,
      location: null,
      maxTds: null,
      blendRadio: null,
      comments: null,
      throughputMbwpd: 0,
      readyForServiceDate: null,
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
    };

    item.createdDate = new Date();
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let i = 0; i <= sender.data.total - 1; i++) {
        if (
          sender.data.data[i].performanceBodPackagesId === formGroup.value.performanceBodPackagesId &&
          sender.data.data[i].description === formGroup.value.description &&
          sender.data.data[i].id !== formGroup.value.id
        ) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate) {
      if (formGroup.valid) {
        this.infrastructureRecycleService.createItem(formGroup.value);
        sender.closeRow(rowIndex);
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.dataSourceRefreshed(this.infrastructureRecycleService.data);
        this.dragAndDropService.refreshDragAndDrop();
        this.infrastructureRecycleService.markAsSetOrder(this.permissionsProvider.cai);
      } else {
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.infrastructureRecycleService.removeItem(dataItem);

    sender.cancelCell();

  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructureRecycleService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Infrastructure - Recycle Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructureRecycleGrid.closeCell();
    this.infrastructureRecycleGrid.cancelCell();

    if (this.infrastructureRecycleService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.infrastructureRecycleService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.infrastructureRecycleService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();      
    }
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.infrastructureRecycleService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.infrastructureRecycleService.read();


  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public getSelectOption(id: number): any {
    return this.selectOption.find(x => x.id === id);
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
