export class FitCcBaseModel  {
    $id: string;
    id: string;
    name: string;
    displayName: string;
    effectiveDate?: Date;
    expiryDate?: Date;
    activeInd?: string;
    isDelete: boolean;
    createdBy?: string;
    createdDate?: Date;
    updatedBy?: string;
    updatedDate?: Date;
    entityState?: number;
    
}  