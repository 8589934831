import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { InputItemsComponent } from '../input-items/input-items.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnDestroy {
  constructor(private utilService: UtilService) {}
  @Output() public openEditPads = new EventEmitter<any>();
  @Output() public openDeletePads = new EventEmitter<any>();
  @Output() public openCopyPads = new EventEmitter<any>();
  @Output() public openActiveForOutlook = new EventEmitter<any>();
  @Output() public openSmartImport = new EventEmitter<any>();
  @Output() public openImportPadBuilder = new EventEmitter<any>();
  @Output() public saveScenarioCost = new EventEmitter<any>();
  @Output() public refreshSmartImportPads = new EventEmitter<any>();

  @ViewChild(InputItemsComponent, { static: false })
  public inputItemsComponent: InputItemsComponent;
  

  ngOnDestroy() {
    this.openDeletePads.complete();
    this.openCopyPads.complete();
    this.openImportPadBuilder.complete();
    this.openActiveForOutlook.complete();
    this.openSmartImport.complete();
    this.refreshSmartImportPads.complete();
  }

  isIE() {
    return this.utilService.isIE();
  }

  public onOpenEditPads(args: any): void {
    this.openEditPads.next(args);
  }

  public onOpenDeletePads(args: any): void {
    this.openDeletePads.next(args);
  }

  public onOpenCopyPads(args: any): void {
    this.openCopyPads.next(args);
  }

  public onOpenActiveForOutlook(args: any): void {
    this.openActiveForOutlook.next(args);
  }

  public onOpenSmartImport(args: any): void {
    this.openSmartImport.next(args);
  }

  public onRefreshPadsSmartImport(args: any): void {
    this.refreshSmartImportPads.next(args);
  }

  public onOpenImportPadBuilder(args: any): void {
    this.openImportPadBuilder.next(args);
  }

  public onSaveScenarioCost(args: any): void {
    this.saveScenarioCost.next(args);
  }
}
