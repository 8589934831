import { Component, OnInit, ViewChild, Input, HostListener, Inject } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogGridActions } from 'src/app/api/enum';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { IPerformanceBodNotes } from 'src/app/api/performance-bod-notes';
import { Observable } from 'rxjs';
import { FileInfo } from 'src/app/api/fileInfo';
import { SummaryNotesAttachmentEditService } from './summary-notes-attachment-edit.service';
import { State, process } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators/map';
import { IPerformanceBodNotesAttachment } from 'src/app/api/performance-bod-notes-attachment';
import { environment } from 'src/environments/environment';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-summary-notes-attachment',
  styleUrls: ['./summary-notes-attachment.component.css'],
  templateUrl: './summary-notes-attachment.component.html',
})
export class SummaryNotesAttachmentComponent implements OnInit {
  @ViewChild('performanceBodNotesAttachment') public gridNotesAttachment: GridComponent;
  @Input() public performanceBodNotes: IPerformanceBodNotes;
  @Input() public performanceBodId: string;

  public buttonCount = 8;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public ItWasSavedAtLeastOnce: boolean;
  public view: Observable<GridDataResult>;
  public notSavedfiles: FileInfo[] = [];
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };
  public summaryNotesAttachmentEditService: SummaryNotesAttachmentEditService;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private performanceBodDataService: PerformanceBodDataService,    
    private dialogService: DialogService,
    private dialog: DialogRef,
    @Inject(SummaryNotesAttachmentEditService) summaryNotesAttachmentEditServiceFactory: any
  ) {this.summaryNotesAttachmentEditService = summaryNotesAttachmentEditServiceFactory();

  }

  public ngOnInit(): void {
    this.summaryNotesAttachmentEditService.performanceBodNotesId = this.performanceBodNotes.id;
    this.summaryNotesAttachmentEditService.reset();
    this.summaryNotesAttachmentEditService.read();
    this.view = this.summaryNotesAttachmentEditService.pipe(map(data => process(data, this.gridState)));
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.summaryNotesAttachmentEditService.read();
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      // undefined or null means is a new one, so dont require markAsDirty
      if (dataItem.id !== undefined && dataItem.id != null) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;
        this.summaryNotesAttachmentEditService.assignValues(dataItem, formGroup.value);
        this.summaryNotesAttachmentEditService.update(dataItem);
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      this.summaryNotesAttachmentEditService.create(formGroup.value);
      sender.closeRow(rowIndex);
    }
  }

  public removeHandler({ sender, dataItem }) {
    this.summaryNotesAttachmentEditService.remove(dataItem);
    if (dataItem.id) {
      const fileInfo = new FileInfo();
      fileInfo.fileName = dataItem.name;
      fileInfo.folderName = 'PerformanceBodNotes';
      fileInfo.subFolderName = this.performanceBodId + '\\' + dataItem.id;
      this.summaryNotesAttachmentEditService.deletedAttachments.push(fileInfo);
    }
    sender.cancelCell();
  }

  public addHandler({ sender }) {
    const dialogRef = this.dialogService.open({
      content: UploadFileComponent,
      height: 480,
      title: 'Upload Attachments',
      width: 600,
    });
    dialogRef.result.subscribe((r: any) => {
      if (Array.isArray(r)) {
        // Always is going to be just one file
        r.forEach(file => {
          // Here fill anything you need fill out.
          const item: IPerformanceBodNotesAttachment = {
            createdBy: this.authenticationService.getCurrentUser(),
            createdDate: new Date(),
            id: file.uid,
            location: '\\PerformanceBodNotes\\' + this.performanceBodId + '\\' + file.uid + '\\',
            name: file.name,
            performanceBodNotes: null,
            performanceBodNotesId: this.performanceBodNotes.id,
            updatedBy: this.authenticationService.getCurrentUser(),
            updatedDate: new Date(),
          };

          this.notSavedfiles.push({
            fileName: file.name,
            folderName: 'PerformanceBodNotes',
            subFolderName: this.performanceBodId + '\\' + item.id,
          });
          this.summaryNotesAttachmentEditService.create(item);
          sender.data.data.push(item);
        });
      }
    });

    const dialogInfo = dialogRef.content.instance;
    dialogInfo.uploadSaveUrl =
      environment.PacerDomainURL + 'Upload/Attachments/Folder/' + 'PerformanceBodNotes' + '/' + this.performanceBodId;
  }

  public createFormGroup(dataItem: IPerformanceBodNotesAttachment): FormGroup {
    return this.formBuilder.group({
      createdBy: dataItem.createdBy,
      createdDate: dataItem.createdDate,
      id: dataItem.id,
      location: dataItem.location,
      name: dataItem.name,
      performanceBodNotesId: dataItem.performanceBodNotesId,
      updatedBy: dataItem.updatedBy,
      updatedDate: dataItem.updatedDate,
    });
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();

    this.notSavedfiles = [];
    this.ItWasSavedAtLeastOnce = true;

    if (!this.isANewNote()) {
      this.summaryNotesAttachmentEditService.saveChanges();
    } else {
      this.performanceBodNotes.createdBy = this.authenticationService.getCurrentUser();
      this.performanceBodNotes.createdDate = new Date();
      this.performanceBodNotes.updatedBy = this.performanceBodNotes.createdBy;
      this.performanceBodNotes.updatedDate = this.performanceBodNotes.createdDate;
      this.performanceBodNotes.performanceBodId = this.performanceBodId;

      this.summaryNotesAttachmentEditService.createWithAttachments([this.performanceBodNotes]).subscribe(data => {
        this.performanceBodNotes = data.entity !== undefined || data.entity != null ? data.entity[0] : null;
        this.summaryNotesAttachmentEditService.performanceBodNotesId = this.performanceBodNotes.id;
        this.summaryNotesAttachmentEditService.createdItems = this.summaryNotesAttachmentEditService.createdItems.map(
          item => {
            item.performanceBodNotesId = this.performanceBodNotes.id;
            return item;
          }
        );
        this.summaryNotesAttachmentEditService.saveChanges();
      });
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.summaryNotesAttachmentEditService.cancelChanges();
  }

  public download(folderFileName: string): void {
    if (folderFileName === undefined || folderFileName == null) {
      return;
    }
    window.open(
      environment.PacerDomainURL +
        'Upload/Attachments/InFolder/' +
        'PerformanceBodNotes' +
        '/' +
        this.performanceBodId +
        '/' +
        folderFileName,
      '_blank'
    );
  }

  public onCancelAction(): void {
    this.checkNotSavedFiles();
    this.summaryNotesAttachmentEditService.cancelChanges();
    this.dialog.close({
      dialogGridAction: this.ItWasSavedAtLeastOnce
        ? this.isANewNote()
          ? DialogGridActions.NewAtParent
          : DialogGridActions.EditedBeforeCancel
        : DialogGridActions.NotEdited,
    });
  }

  public onConfirmAction(): void {
    this.saveChanges(this.gridNotesAttachment);
    this.summaryNotesAttachmentEditService.wasModified.subscribe((itWasModified: boolean) => {
      if (itWasModified) {
        this.dialog.close({
          attachments: this.summaryNotesAttachmentEditService.data,
          dialogGridAction: this.isANewNote() ? DialogGridActions.NewAtParent : DialogGridActions.Saved,
        });
      }
    });
  }

  public checkNotSavedFiles(): void {
    if (this.notSavedfiles.length > 0) {
      this.performanceBodDataService.removeAttachments(this.notSavedfiles).subscribe();
    }
  }

  public isANewNote(): boolean {
    return this.performanceBodNotes.id === undefined || this.performanceBodNotes.id == null;
  }
}
