import { Injectable } from '@angular/core';
import groupBy from 'lodash.groupby';
import { Guid } from 'guid-typescript';
import { UtilService } from '../../../services/util.service';
import { OverriddenFieldsService } from '../../../services/overridden-fields.service';
import { IPadBracket } from '../../../api/pad-bracket';
import { GasForecastRow } from '../../../api/gas-forecast-row';
import { WaterForecastRow } from '../../../api/water-forecast-row';

import { LandDamagesOther } from '../../../api/landDamagesOther';
import { AdditionalRowsID } from './input-items.model';
import { FacilityItemModel } from '../../../api/facility-item-model';
import { Rfsd } from '../../../api/rfsd';
import { InfrastructureScopeCategory } from '../../../api/infrastructure-scope-category';
import { Kind } from '../../../api/kind';
import {
  InputItem,
  keyFacilityItem,
  ItemAndDetailsModel,
  IDetailsModel,
  keyFacilityItemDetail,
} from '../../../api/input-item';
import { Location } from '../../../api/location';
import { InfrastructureClass } from '../../../api/infrastructure-class';
import { UnitOfMeasure } from '../../../api/unitOfMeasure-model';
import { ICndCostMappingSft } from '../../../api/cnd-cost-mapping-sft';
import { CndCostLogicEnum } from '../../../api/cnd-cost-logic-enum';
import { LandDamagesPipe } from 'src/app/api/landDamagesPipe';
import { ScenarioAfeType } from 'src/app/api/scenarioAfeType';
@Injectable({
  providedIn: 'root',
})
export class InputItemsService {
  private readonly lstAdditionalItemsIds: string[];
  constructor(private utilService: UtilService, private overriddenFieldsService: OverriddenFieldsService) {
    this.lstAdditionalItemsIds = AdditionalRowsID;
  }

  public generatePadCountColumns(inputItemsData: any[], padConfigData: any[]): void {
    for (const inputData of inputItemsData) {
      inputData['total'] = 0;
      inputData['contingency'] = 0;
      this.utilService.forEachPad(padConfigData, padName => {
        inputData[padName] = 0;
      });
    }
  }

  // US 54937
  public calculateGreenfield1TrainCtbInstall(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    const items = inputItemsData.filter(inputItem => inputItem.name === keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL);
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const targetCtb = pad.targetCtb;
      const ctbExecutionCategory = pad.ctbExecutionCategory;

      items.forEach(item => {
        if (
          !this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL, item.id)
        ) {
          if (ctbExecutionCategory !== 'Greenfield') {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL, 0, item.id);
          } else {
            const isFirstPadWithCtb = this.isFirstPadWith(orderedPads, padName, (element, index, array): boolean => {
              return element.targetCtb.toUpperCase() === targetCtb.toUpperCase();
            });
            // Applies a count of ONE, IF this is the earliest instance
            if (isFirstPadWithCtb) {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL, 1, item.id);
            } else {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL, 0, item.id);
            }
          }
        }
      });
    }
  }

  public calculateBaseGreenfieldCompressorInstall(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const orderedPad of orderedPads) {
      const padName = orderedPad.padName;
      const associatedCompStation = orderedPad.associatedCompStation;
      const compExecutionCategory = orderedPad.compExecutionCategory;
      const csInstallItem = inputItemsData.find(x => x.name == keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL);

      if (
        !this.overriddenFieldsService.isPadItemCountOverridden(
          padName,
          keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
        )
      ) {
        if (compExecutionCategory !== 'Greenfield') {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL, 0, csInstallItem?.id);
        } else {
          const isFirstPadWithCtb = this.isFirstPadWith(orderedPads, padName, (element, index, array): boolean => {
            return element.associatedCompStation.toUpperCase() === associatedCompStation.toUpperCase();
          });
          // Applies a count of ONE, IF this is the earliest instance
          if (isFirstPadWithCtb) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL, 1, csInstallItem?.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL, 0, csInstallItem?.id);
          }
        }
      }
    }

    const greenFieldCompressorStationInstallTotal = this.getInputItemValue(
      inputItemsData,
      keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL,
      'total'
    );
    if (greenFieldCompressorStationInstallTotal) {
      const csVRUItems = inputItemsData.filter(x => x.name == keyFacilityItem.COMPRESSOR_STATION_VRU);
      if (greenFieldCompressorStationInstallTotal > 0) {
        csVRUItems.forEach(item => {
          this.setInputItemValue(
            inputItemsData,
            keyFacilityItem.COMPRESSOR_STATION_VRU,
            'total',
            2 * greenFieldCompressorStationInstallTotal,
            item.id
          );
        });
      }
    }
  }

  public calculateBaseGreenfieldSwdInstall(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const orderedPad of orderedPads) {
      const padName = orderedPad.padName;
      const associatedSwdFacility = orderedPad.associatedSwdFacility;
      const swdExecutionCategory = orderedPad.swdExecutionCategory;
      const gfSwdInstallItem = inputItemsData.find(x => x.name == keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL);

      if (
        !this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL)
      ) {
        if (swdExecutionCategory !== 'Greenfield') {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL, 0, gfSwdInstallItem?.id);
        } else {
          const isFirstPadWithCtb = this.isFirstPadWith(orderedPads, padName, (element, index, array): boolean => {
            return element.associatedSwdFacility?.toUpperCase() === associatedSwdFacility?.toUpperCase();
          });
          // Applies a count of ONE, IF this is the earliest instance
          if (isFirstPadWithCtb) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL, 1,gfSwdInstallItem?.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL, 0, gfSwdInstallItem?.id);
          }
        }
      }
    }
  }

  // US 54937
  public calculateCtbTrain1200(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const targetCtb = pad.targetCtb;
      const targetTrain = pad.targetTrain;
      const ctbTrain1200Items = inputItemsData.filter(x => x.name == keyFacilityItem.CTB_TRAIN_12000_BOPD);
      ctbTrain1200Items.forEach(ctbTrain1200Item => {

        const ctbTrain8500 = inputItemsData.find(item => item.name === keyFacilityItem.CTB_TRAIN_8500_BOPD && item.id == ctbTrain1200Item.id)[padName];
        const ctbTrainTruncated = inputItemsData.find(item => item.name === keyFacilityItem.CTB_TRAIN_TRUNCATED && item.id == ctbTrain1200Item.id)[padName];
        const ctbTrain1000 = inputItemsData.find(item => item.name === keyFacilityItem.CTB_TRAIN_1000_BOPD_SINGLE_WELL && item.id == ctbTrain1200Item.id)[
          padName
        ];
  
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_TRAIN_12000_BOPD, ctbTrain1200Item.id)) {
          if (ctbTrain8500 !== 0 || ctbTrainTruncated !== 0 || ctbTrain1000 !== 0 || !targetCtb || !targetTrain) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_TRAIN_12000_BOPD, 0, ctbTrain1200Item.id);
          } else {
            const isFirstPadWithCtbAndTrain = this.isFirstPadWith(
              orderedPads,
              padName,
              (element, index, array): boolean => {
                return element.targetCtb.toUpperCase() === targetCtb.toUpperCase() && element.targetTrain === targetTrain;
              }
            );
            // Applies a count of ONE, IF this is the earliest instance
            if (isFirstPadWithCtbAndTrain) {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_TRAIN_12000_BOPD, 1,  ctbTrain1200Item.id);
            } else {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_TRAIN_12000_BOPD, 0, ctbTrain1200Item.id);
            }
          }
        }
      });
    }
  }

  public calculateTotals(inputItemsData: any[], pads: string[]): void {
    for (const inputItem of inputItemsData) {
      let total = 0;
      for (const pad of pads) {
        total += inputItem[pad] === null || inputItem[pad] === undefined ? 0 : inputItem[pad];
      }
      if (inputItem.name) {
        this.setInputItemValue(inputItemsData, inputItem.name, 'total', total, inputItem.id,inputItem.respToExpandFacilityItemId);
      } else {
        this.setAdditionalItemValue(inputItemsData, inputItem.displayName, 'total', total);
      }
    }
  }

  // US 1558683
  public calculateExtraCtbTank(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName = pad;
      const ctbTankItem = inputItemsData.find(x => x.name == keyFacilityItem.CTB_TANK);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_TANK)) {
        const baseGreenfieldCtbInstall = this.getInputItemValue(
          inputItemsData,
          keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL,
          padName
        );
        const oneTrainCtbAddition = this.getInputItemValue(
          inputItemsData,
          keyFacilityItem.TRAIN_1_CTB_ADDITION,
          padName
        );

        if (
          (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) ||
          (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
          (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
        ) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_TANK, 8, ctbTankItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_TANK, 0, ctbTankItem?.id);
        }
      }
    }
  }

  // US 61920
  public calculateCtbVru(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName = pad;
      const ctbVRUItems = inputItemsData.filter(x => x.name == keyFacilityItem.CTB_VRU);

      ctbVRUItems.forEach(ctbVruItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_VRU, ctbVruItem.id)) {
          const baseGreenfieldCtbInstall = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
          );
          const oneTrainCtbAddition = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.TRAIN_1_CTB_ADDITION
          );
  
          if (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_VRU, 4, ctbVruItem.id);
          } else if (
            (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
            (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
          ) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_VRU, 2, ctbVruItem.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_VRU, 0, ctbVruItem.id);
          }
        }
      });
    }
  }

  // US 61920
  public calculateCtbFlashGasCompressor(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName = pad;
      const ctbFlashGasCSItems = inputItemsData.filter(x => x.name == keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR);
      ctbFlashGasCSItems.forEach(ctbFlashGasCSItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR,ctbFlashGasCSItem.id )) {
          const baseGreenfieldCtbInstall = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
          );
          const oneTrainCtbAddition = this.getInputItemCount(
            inputItemsData,
            padName,
            keyFacilityItem.TRAIN_1_CTB_ADDITION
          );
  
          if (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR, 2,ctbFlashGasCSItem.id );
          } else if (
            (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
            (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
          ) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR, 1,ctbFlashGasCSItem.id );
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR, 0,ctbFlashGasCSItem.id );
          }
        }
      });
    }
  }

  // US 1558683
  public calculateExtraCtbLact(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName = pad;
      const extraCtbLact = 0;
      const ctbLactItem = inputItemsData.find(x => x.name == keyFacilityItem.CTB_LACT);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_LACT)) {
        const baseGreenfieldCtbInstall = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
        );
        const oneTrainCtbAddition = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.TRAIN_1_CTB_ADDITION
        );

        if (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 1) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_LACT, 4, ctbLactItem?.id);
        } else if (
          (baseGreenfieldCtbInstall === 1 && oneTrainCtbAddition === 0) ||
          (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 1)
        ) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_LACT, 2, ctbLactItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_LACT, 0, ctbLactItem?.id);
        }
      }
    }
  }

  // US 1558683
  public calculateExtraCtbWaterTransferPump(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const targetCtb = pad.targetCtb;
      const ctbWaterPumpItems = inputItemsData.filter(x => x.name == keyFacilityItem.CTB_WATER_TRANSFER_PUMP);

      ctbWaterPumpItems.forEach(ctbWaterPumpItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_WATER_TRANSFER_PUMP, ctbWaterPumpItem.id)) {
          const baseGreenfieldCtbInstall = this.getInputItemValue(
            inputItemsData,
            keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL,
            padName
          );
  
          if (baseGreenfieldCtbInstall === 1) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_WATER_TRANSFER_PUMP, 1, ctbWaterPumpItem.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_WATER_TRANSFER_PUMP, 0, ctbWaterPumpItem.id);
          }
        }
      });
    }
  }

  // US 1558683
  public calculateCtbBrownfieldAdder(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const ctbBFAdderItem = inputItemsData.find(x => x.name == keyFacilityItem.CTB_BROWNFIELD_ADDER);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_BROWNFIELD_ADDER)) {
        const baseGreenfieldCtbInstall = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
        );
        const oneTrainCtbAddition = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.TRAIN_1_CTB_ADDITION
        );

        // search all CTB Equipment items

        const ctbHeader = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_HEADER);
        const ctbTestSeparator = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_TEST_SEPARATOR);
        const hiAccurracyCTBMeeting = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.HI_ACCURACY_CTB_METERING_UPGRADE
        );
        const ctbTank = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_TANK);
        const ctbVRU = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_VRU);
        const ctbLACT = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_LACT);
        const ctbWaterTransfer = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.CTB_WATER_TRANSFER_PUMP
        );
        const ctbFlashCompressor = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR
        );

        const ctbEquipmentHasMoreThanZero =
          ctbHeader > 0 ||
          ctbTestSeparator > 0 ||
          hiAccurracyCTBMeeting > 0 ||
          ctbTank > 0 ||
          ctbVRU > 0 ||
          ctbLACT > 0 ||
          ctbWaterTransfer > 0 ||
          ctbFlashCompressor > 0;

        if (baseGreenfieldCtbInstall === 0 && oneTrainCtbAddition === 0 && ctbEquipmentHasMoreThanZero) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_BROWNFIELD_ADDER, 1, ctbBFAdderItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_BROWNFIELD_ADDER, 0, ctbBFAdderItem?.id);
        }
      }
    }
  }

  public calculateGreenfieldSatelliteInstall(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      // Get values of pad
      const padName = pad.padName;
      const targetSatellite = pad.targetSatellite;
      const satelliteExecutionCategory = pad.satelliteExecutionCategory;
      const gfSatInstallItem = inputItemsData.find(x => x.name ==  keyFacilityItem.GREENFIELD_SATELLITE_INSTALL);

      if (
        !this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.GREENFIELD_SATELLITE_INSTALL)
      ) {
        if (satelliteExecutionCategory !== 'Greenfield') {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_SATELLITE_INSTALL, 0, gfSatInstallItem?.id);
        } else {
          // Get validate first pad
          const isFirstPadWithSatellite = this.isFirstPadWith(
            orderedPads,
            padName,
            (element, index, array): boolean => {
              return element.targetSatellite?.toUpperCase() === targetSatellite?.toUpperCase();
            }
          );
          if (isFirstPadWithSatellite) {
            // Applies a count of ONE depending of validations
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_SATELLITE_INSTALL, 1, gfSatInstallItem?.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_SATELLITE_INSTALL, 0, gfSatInstallItem?.id);
          }
        }
      }
    }
  }

  // US61920, US620814
  public calculateHPFEBrownfieldAdder(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const hpfeBFAdderItem = inputItemsData.find(x => x.name == keyFacilityItem.HPFE_BROWNFIELD_ADDER);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER)) {
        // Get values for the wellTestLocation  and targetSatellite
        const wellTestLocation = pad.wellTestLocation;
        const targetSatellite = pad.targetSatellite;

        if (wellTestLocation?.toUpperCase() === targetSatellite?.toUpperCase()) {
          const satelliteExecutionCategory = pad.satelliteExecutionCategory;
          if (satelliteExecutionCategory !== 'Brownfield') {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 0, hpfeBFAdderItem?.id);
          } else {
            // Find any other line item with conditions.
            const hasHPFEItem = inputItemsData.find((item): boolean => {
              return (
                item.location.name === 'HPFE' &&
                item[padName] > 0 &&
                item.name !== keyFacilityItem.HPFE_BROWNFIELD_ADDER
              );
            });
            const satelliteBrownfieldAdder = inputItemsData.find(
              item => item.name === keyFacilityItem.SATELLITE_BROWNFIELD_ADDER
            );

            if (hasHPFEItem && satelliteBrownfieldAdder[padName] === 0) {
              // Applies a count of ONE
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 1, hpfeBFAdderItem?.id);
            } else {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 0, hpfeBFAdderItem?.id);
            }
          }
        } else {
          // Get value of ctbExecutionCategory
          const ctbExecutionCategory = pad.ctbExecutionCategory;
          if (ctbExecutionCategory !== 'Brownfield') {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 0, hpfeBFAdderItem?.id);
          } else {
            // Find any other line item with conditions.
            const hasHPFEItem = inputItemsData.find((item): boolean => {
              return (
                item.location.name === 'HPFE' &&
                item[padName] > 0 &&
                item.name !== keyFacilityItem.HPFE_BROWNFIELD_ADDER
              );
            });
            const ctbBrownfieldAdder = inputItemsData.find(item => item.name === keyFacilityItem.CTB_BROWNFIELD_ADDER);

            if (hasHPFEItem && ctbBrownfieldAdder[padName] === 0) {
              // Applies a count of ONE
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 1, hpfeBFAdderItem?.id);
            } else {
              this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.HPFE_BROWNFIELD_ADDER, 0, hpfeBFAdderItem?.id);
            }
          }
        }
      }
    }
  }

  public calculateCSBrownfieldAdder(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      const csBFAdderItem = inputItemsData.find(x => x.name == keyFacilityItem.CS_BROWNFIELD_ADDER);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CS_BROWNFIELD_ADDER)) {
        const compExecutionCategory = pad.compExecutionCategory;
        if (compExecutionCategory !== 'Brownfield') {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CS_BROWNFIELD_ADDER, 0, csBFAdderItem?.id);
        } else {
          const hasBrownfieldItem = inputItemsData.find((item): boolean => {
            return (
              item.location.name === 'Comp Station' &&
              item[padName] > 0 &&
              item.name !== keyFacilityItem.CS_BROWNFIELD_ADDER
            );
          });
          if (hasBrownfieldItem) {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CS_BROWNFIELD_ADDER, 1, csBFAdderItem?.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CS_BROWNFIELD_ADDER, 0, csBFAdderItem?.id);
          }
        }
      }
    }
  }

  // US 61925
  public calculateCtbPadSize(inputItemsData: any[], orderedPads: IPadBracket[]): void {
    const padsGroupedByTargetCtb = groupBy(orderedPads, (pad: IPadBracket) => {
      return pad.targetCtb;
    });
    const ctbsData = [];
    for (const targetCtb in padsGroupedByTargetCtb) {
      if (padsGroupedByTargetCtb.hasOwnProperty(targetCtb)) {
        const trains = [];
        const groupedByTargetCtbs: IPadBracket[] = padsGroupedByTargetCtb[targetCtb];
        let hasHPFE = false;
        for (const pad of groupedByTargetCtbs) {
          const padName = pad.padName;
          if (pad.targetTrain && trains.indexOf(pad.targetTrain) < 0) {
            trains.push(pad.targetTrain);
          }
          if (this.getInputItemCount(inputItemsData, padName, 'High-Pressure Front End (multi-well unit)') > 0) {
            hasHPFE = true;
          }
        }
        ctbsData[targetCtb] = {
          trainCount: trains.length,
          hasHPFE,
        };
      }
    }

    for (const pad of orderedPads) {
      const padName = pad.padName;
      const ctbPadCostItem = inputItemsData.find(x => x.name == keyFacilityItem.CTB_PAD_COST);
      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.CTB_PAD_COST)) {
        if (
          this.getInputItemCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL) === 1 &&
          this.getInputItemCount(inputItemsData, padName, keyFacilityItem.TRAIN_1_CTB_ADDITION) === 1
        ) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_PAD_COST, 138, ctbPadCostItem?.id);
        } else if (
          (this.getInputItemCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL) === 1 &&
            this.getInputItemCount(inputItemsData, padName, keyFacilityItem.TRAIN_1_CTB_ADDITION) === 0) ||
          (this.getInputItemCount(inputItemsData, padName, keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL) === 0 &&
            this.getInputItemCount(inputItemsData, padName, keyFacilityItem.TRAIN_1_CTB_ADDITION) === 1)
        ) {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_PAD_COST, 102, ctbPadCostItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.CTB_PAD_COST, 0, ctbPadCostItem?.id);
        }
      }
    }
  }

  public calculatePipelineRisers(inputItemsData: any[], pads: string[],itemId:string =''): void {
    for (const pad of pads) {
      const padName = pad;
      this.calculatePipelineRiser(inputItemsData, padName, 'LP Gas Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'HP Gas Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'Condensate Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'Gas Lift Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'SWD Injection Line Riser');
      this.calculatePipelineRiser(inputItemsData, padName, 'Regional Water Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'Produced Water Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'Blanket Gas Pipeline Riser',itemId);
      this.calculatePipelineRiser(inputItemsData, padName, 'Skim Oil Return Pipeline Riser',itemId);
    }
  }

  public calculatePipelineRiser(inputItemsData: any[], padName: string, pipelineRiser: string,itemId:string=''): void {
    let pipelineDependency = '';
    switch (pipelineRiser) {
      case 'LP Gas Pipeline Riser':
        pipelineDependency = 'In-Field Gas Gathering/Sales, LP';
        break;
      case 'HP Gas Pipeline Riser':
        pipelineDependency = 'In-Field Gas Gathering/Sales, HP';
        break;
      case 'Condensate Pipeline Riser':
        pipelineDependency = 'Condensate Gathering';
        break;
      case 'Gas Lift Pipeline Riser':
        pipelineDependency = 'Gas Lift Distribution';
        break;
      case 'SWD Injection Line Riser':
        pipelineDependency = 'SWD Injection Line';
        break;
      case 'Regional Water Pipeline Riser':
        pipelineDependency = 'Regional Water Pipeline';
        break;
      case 'Produced Water Pipeline Riser':
        pipelineDependency = 'Produced Water Gathering';
        break;
      case 'Blanket Gas Pipeline Riser':
        pipelineDependency = 'Blanket Gas Line';
        break;
      case 'Skim Oil Return Pipeline Riser':
        pipelineDependency = 'Skim Oil Return Line';
        break;
    }
    const pipelineRiserItems = inputItemsData.filter(x => x.name == pipelineRiser && (itemId? x.respToExpandFacilityItemId == itemId:true));

    pipelineRiserItems.forEach(pipelineRiserItem => {
      let pipelineRiserCount = 0;      
      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, pipelineRiser, pipelineRiserItem.id)) {
        const pipelineDependencyCount = this.getInputItemCount(inputItemsData, padName, pipelineDependency,(itemId ? itemId : undefined));
        if (pipelineDependencyCount > 0) {
          if (pipelineRiser === 'SWD Injection Line Riser') {
            pipelineRiserCount = 1;
          } else {
            const extraPipelineRisers = Math.round(pipelineDependencyCount / 5280);
            if (pipelineDependencyCount <= 5280) {
              pipelineRiserCount = 1;
            } else {
              pipelineRiserCount = extraPipelineRisers;
            }
          }
          this.setInputItemsCount(inputItemsData, padName, pipelineRiser, pipelineRiserCount, pipelineRiserItem.id,pipelineRiserItem.respToExpandFacilityItemId);
        } else {
          this.setInputItemsCount(inputItemsData, padName, pipelineRiser, pipelineRiserCount, pipelineRiserItem.id,pipelineRiserItem.respToExpandFacilityItemId);
        }
      }
    });
  }

  public updateItemDetail(
    inputItemsData: any[],
    itemName: string,
    costValue: number,
    noteValue: string,
    devAreaState: string,
    itemId: string,
    itemDetailId: string = ''
  ): void {

    if(itemDetailId && itemDetailId.length > 0)
        this.setInputItemValue(inputItemsData, itemName, 'itemDetailId',itemDetailId, itemId );

    if (itemName === 'Frac Ponds') {
      this.calculateFracPondCost(inputItemsData, devAreaState, itemId);
    } else {
      this.setInputItemValue(inputItemsData, itemName, 'costPunit', costValue, itemId);
      this.setInputItemValue(inputItemsData, itemName, 'itemDetail.itemDetailNotes', noteValue, itemId);
      this.setInputItemValue(inputItemsData, itemName, 'costUnitOverride', 0, itemId);
    }
  }

  public calculateFracPondCost(inputItemsData: any[], devAreaState: string, itemId: string): void {
    if (
      (inputItemsData != null && inputItemsData !== undefined) ||
      inputItemsData.find(item => item.name === 'Frac Ponds' && item.id == itemId).itemDetail != null ||
      inputItemsData.find(item => item.name === 'Frac Ponds'&& item.id == itemId).itemDetail !== undefined ||
      inputItemsData.find(item => item.name === 'Frac Ponds'&& item.id == itemId) != null ||
      inputItemsData.find(item => item.name === 'Frac Ponds'&& item.id == itemId) !== undefined
    ) {
      const fracPonds = inputItemsData.find(item => item.name === 'Frac Ponds' && item.id == itemId);
        const fracPondsDetail = fracPonds ? fracPonds.itemDetail.name : null;

        if (devAreaState === 'TX') {
          if (fracPondsDetail === 'RWCS') {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 4.0, fracPonds.id);
          } else if (fracPondsDetail === 'EPS') {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 2.5, fracPonds.id);
          } else {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 0, fracPonds.id);
          }
        } else if (devAreaState === 'NM') {
          if (fracPondsDetail === 'RWCS') {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 5.0, fracPonds.id);
          } else if (fracPondsDetail === 'EPS') {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 3.5, fracPonds.id);
          } else {
            this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 0, fracPonds.id);
          }
        } else {
          this.setInputItemValue(inputItemsData, 'Frac Ponds', 'costPunit', 0, fracPonds.id);
        }
    }
  }

  public calculateSimpleOSBLFields(
    inputItemsData: any[],
    orderedPads: IPadBracket[],
    devAreaState: string,
    flowlineThreshold: number
  ): void {
    for (const pad of orderedPads) {
      const padName = pad.padName;
      this.calculateSWDInjectionLine(inputItemsData, padName);
      this.calculateSwdWellPadAndHookup(inputItemsData, padName);
      this.calculateWaterSourceWellD_C_Costs(inputItemsData, padName);
      this.calculateAerationCirculationSkid_recycle(inputItemsData, padName);
      this.calculateCommercialOilTieIn(inputItemsData, padName);
      this.calculateSurveyAndPermitting(inputItemsData, pad);
      this.calculateFacilityRadioTower60(inputItemsData, padName);
      this.calculateStandardNetworkEquipmentPackage(inputItemsData, padName);
      this.calculateReservePitConstructionAndClosure(inputItemsData, padName, devAreaState);
      this.calculatePostDrillPadReclamation(inputItemsData, padName, devAreaState);
      this.calculateWellheadAndALHookup_GasLift(inputItemsData, pad);
      this.calculateFlowlineRiser(inputItemsData, pad);
      this.calculateFlowlineMaterialsAndInstallation(inputItemsData, pad, flowlineThreshold);
      this.calculateFlowlineTrenching(inputItemsData, pad, flowlineThreshold);
    }
  }

  public calculateSWDInjectionLine(inputItemsData: any[], padName: string): void {
    const swdInjectionLineItems = inputItemsData.filter(x => x.name == keyFacilityItem.SWD_INJECTION_LINE);

    swdInjectionLineItems.forEach(swdInjectionLineItem => {
      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.SWD_INJECTION_LINE, swdInjectionLineItem.id)) {
        const coreGreenfieldSwdInstallCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
        );
        this.setInputItemsCount(
          inputItemsData,
          padName,
          keyFacilityItem.SWD_INJECTION_LINE,
          coreGreenfieldSwdInstallCount * 1000,
          swdInjectionLineItem.id
        );
      }
    });

  }

  public calculateSwdWellPadAndHookup(inputItemsData: any[], padName: string): void {
    const swdWellPadHookupItem = inputItemsData.find(x => x.name == keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP);

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP)) {
      const coreGreenfieldSwdInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
      );
      this.setInputItemsCount(
        inputItemsData,
        padName,
        keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP,
        coreGreenfieldSwdInstallCount,
        swdWellPadHookupItem?.id
      );
    }
  }

  public calculateWaterSourceWellD_C_Costs(inputItemsData: any[], padName: string): void {
    const waterSourceItems = inputItemsData.filter(x => x.name == keyFacilityItem.WATER_SOURCE_WELL_D_C_COSTS);

    waterSourceItems.forEach(waterSourceItem => {
      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.WATER_SOURCE_WELL_D_C_COSTS, waterSourceItem.id)) {
        const waterWellPadAndHookupCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.WATER_WELL_PAD_AND_HOOKUP
        );
        this.setInputItemsCount(
          inputItemsData,
          padName,
          keyFacilityItem.WATER_SOURCE_WELL_D_C_COSTS,
          waterWellPadAndHookupCount,
          waterSourceItem.id
        );
      }
    });

  }

  public calculateCommercialOilTieIn(inputItemsData: any[], padName: string): void {
    const commOilTieInItem = inputItemsData.find(x => x.name == keyFacilityItem.COMMERCIAL_OIL_TIE_IN);

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.COMMERCIAL_OIL_TIE_IN)) {
      const baseGreenfieldCtbInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
      );
      this.setInputItemsCount(
        inputItemsData,
        padName,
        keyFacilityItem.COMMERCIAL_OIL_TIE_IN,
        baseGreenfieldCtbInstallCount,
        commOilTieInItem?.id
      );
    }
  }

  public calculateSurveyAndPermitting(inputItemsData: any[], pad: IPadBracket): void {
    const padName = pad.padName;
    const surveyItem = inputItemsData.find(x => x.name == keyFacilityItem.SURVEY_AND_PERMITTING);

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.SURVEY_AND_PERMITTING)) {
      const wellCount = pad.wellCount;
      this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.SURVEY_AND_PERMITTING, wellCount, surveyItem?.id);
    }
  }

  public calculateFacilityRadioTower60(inputItemsData: any[], padName: string): void {
    const facilityRadioTowerItem = inputItemsData.find(x => x.name == "60' Facility Radio Tower");

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, "60' Facility Radio Tower")) {
      const baseGreenfieldCtbInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
      );
      const coreGreenfieldSatelliteInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
      );
      const coreStandardCompressorStationInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
      );
      const coreGreenfieldSwdInstallCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
      );
      this.setInputItemsCount(
        inputItemsData,
        padName,
        "60' Facility Radio Tower",
        baseGreenfieldCtbInstallCount +
          coreGreenfieldSatelliteInstallCount +
          coreStandardCompressorStationInstallCount +
          coreGreenfieldSwdInstallCount,
          facilityRadioTowerItem?.id
      );
    }
  }

  public calculateStandardNetworkEquipmentPackage(inputItemsData: any[], padName: string): void {
    const stdNetworkEquipItem = inputItemsData.find(x => x.name == 'Standard Network Equipment Package');

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Standard Network Equipment Package')) {
      const facilityRadioTower60Count = this.getInputItemCount(inputItemsData, padName, "60' Facility Radio Tower");
      this.setInputItemsCount(inputItemsData, padName, 'Standard Network Equipment Package', facilityRadioTower60Count, stdNetworkEquipItem?.id);
    }
  }

  public calculateReservePitConstructionAndClosure(inputItemsData: any[], padName: string, devAreaState: string): void {
    const pitConstructionItem = inputItemsData.find(x => x.name == 'Reserve Pit Construction and Closure');

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Reserve Pit Construction and Closure')) {
      if (devAreaState === 'TX') {
        const wellPadSingleUseCount = this.getInputItemCount(
          inputItemsData,
          padName,
          'Well Pad - Single Use / Above-Grade WH'
        );
        this.setInputItemsCount(inputItemsData, padName, 'Reserve Pit Construction and Closure', wellPadSingleUseCount ,pitConstructionItem?.id);
      } else {
        this.setInputItemsCount(inputItemsData, padName, 'Reserve Pit Construction and Closure', 0, pitConstructionItem?.id);
      }
    }
  }

  public calculatePostDrillPadReclamation(inputItemsData: any[], padName: string, devAreaState: string): void {
    const drillPadItem = inputItemsData.find(x => x.name == 'Post Drill Pad Reclamation');

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Post Drill Pad Reclamation')) {
      if (devAreaState === 'NM') {
        const wellPadSingleUseCount = this.getInputItemCount(
          inputItemsData,
          padName,
          'Well Pad - Single Use / Above-Grade WH'
        );
        this.setInputItemsCount(inputItemsData, padName, 'Post Drill Pad Reclamation', wellPadSingleUseCount , drillPadItem?.id);
      } else {
        this.setInputItemsCount(inputItemsData, padName, 'Post Drill Pad Reclamation', 0, drillPadItem?.id);
      }
    }
  }

  public calculateWellheadAndALHookup_GasLift(inputItemsData: any[], pad: IPadBracket): void {
    const padName = pad.padName;
    const wellheadGasLiftItem = inputItemsData.find(x => x.name == 'Wellhead and AL Hookup - Gas Lift');

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Wellhead and AL Hookup - Gas Lift')) {
      const wellCount = pad.wellCount;
      const wellheadAndALHookupESPcount = this.getInputItemCount(
        inputItemsData,
        padName,
        'Wellhead and AL Hookup - ESP'
      );
      this.setInputItemsCount(
        inputItemsData,
        padName,
        'Wellhead and AL Hookup - Gas Lift',
        wellCount - wellheadAndALHookupESPcount,
        wellheadGasLiftItem?.id
      );
    }
  }

  public calculateFlowlineRiser(inputItemsData: any[], pad: IPadBracket): void {
    const padName = pad.padName;
    const flowlineItems = inputItemsData.filter(x => x.name == 'Flowline Risers (2 Per Well)');

    flowlineItems.forEach(flowlineItem => {
      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Flowline Risers (2 Per Well)', flowlineItem.id)) {
        const wellCount = pad.wellCount;
        this.setInputItemsCount(inputItemsData, padName, 'Flowline Risers (2 Per Well)', wellCount * 2, flowlineItem.id);
      }
    });
  }

  public calculateWellPad_SingleUse_AboveGradeWH(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName: string = pad;
      const wellPadSingleUseItems = inputItemsData.filter(x => x.name == 'Well Pad - Single Use / Above-Grade WH');

      wellPadSingleUseItems.forEach(wellPadSingleUseItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Well Pad - Single Use / Above-Grade WH', wellPadSingleUseItem.id)) {
          const perWellCostAdderSubGradeWhCellarsCount = this.getInputItemCount(
            inputItemsData,
            padName,
            'Per-Well Cost Adder: Sub-Grade WH Cellars'
          );
          if (padName.includes('SWD') || perWellCostAdderSubGradeWhCellarsCount !== 0) {
            this.setInputItemsCount(inputItemsData, padName, 'Well Pad - Single Use / Above-Grade WH', 0, wellPadSingleUseItem.id);
          } else {
            this.setInputItemsCount(inputItemsData, padName, 'Well Pad - Single Use / Above-Grade WH', 1, wellPadSingleUseItem.id);
          }
        }
      });
    }
  }

  public calculateFlowlineMaterialsAndInstallation(
    inputItemsData: any[],
    pad: IPadBracket,
    flowlineThreshold: number
  ): void {
    const flowlineMaterialItems = inputItemsData.filter(x => x.name == 'Flowline Materials and Installation');

    flowlineMaterialItems.forEach(flowlineMaterialItem => {
      if (!this.overriddenFieldsService.isPadItemCountOverridden(pad.padName, 'Flowline Materials and Installation', flowlineMaterialItem.id)) {
        const flowlinesLengthOfRoute = this.getInputItemCount(inputItemsData, pad.padName, 'Flowlines - Length of Route');
        if (flowlinesLengthOfRoute <= flowlineThreshold) {
          this.setInputItemsCount(
            inputItemsData,
            pad.padName,
            'Flowline Materials and Installation',
            flowlinesLengthOfRoute * pad.wellCount,
            flowlineMaterialItem.id
          );
        } else {
          this.setInputItemsCount(
            inputItemsData,
            pad.padName,
            'Flowline Materials and Installation',
            flowlinesLengthOfRoute * pad.wellCount * 2,
            flowlineMaterialItem.id
          );
        }
      }
    });
  }

  public calculateFlowlineTrenching(inputItemsData: any[], pad: IPadBracket, flowlineThreshold: number): void {
    const flowLineTrenchingItem = inputItemsData.find(x => x.name == 'Flowline Trenching');
    if (!flowLineTrenchingItem) {
      return;
    }
    if (!this.overriddenFieldsService.isPadItemCountOverridden(pad.padName, 'Flowline Trenching')) {
      const flowlinesLengthOfRoute = this.getInputItemCount(inputItemsData, pad.padName, 'Flowlines - Length of Route');

      if (flowlinesLengthOfRoute <= flowlineThreshold) {
        if (pad.wellCount <= 8) {
          this.setInputItemsCount(inputItemsData, pad.padName, 'Flowline Trenching', flowlinesLengthOfRoute, flowLineTrenchingItem.id);
        } else {
          this.setInputItemsCount(inputItemsData, pad.padName, 'Flowline Trenching', flowlinesLengthOfRoute * 2, flowLineTrenchingItem.id);
        }
      } else {
        if (pad.wellCount <= 4) {
          this.setInputItemsCount(inputItemsData, pad.padName, 'Flowline Trenching', flowlinesLengthOfRoute, flowLineTrenchingItem.id);
        } else {
          this.setInputItemsCount(inputItemsData, pad.padName, 'Flowline Trenching', flowlinesLengthOfRoute * 2, flowLineTrenchingItem.id);
        }
      }
    }
  }

  public calculateAerationCirculationSkid_recycle(inputItemsData: any[], padName: string): void {
    const aerationItem = inputItemsData.find(x => x.name == 'Aeration/Circulation Skid (for recycle)');

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, 'Aeration/Circulation Skid (for recycle)')) {
      const fracPonds = inputItemsData.filter(item => item.name === 'Frac Ponds');
      const fracPondsItemDetail = fracPonds ? fracPonds.map(x => x.itemDetail.name) : null;
      const fracPondsCount = this.getInputItemCount(inputItemsData, padName, 'Frac Ponds');
      if (fracPondsItemDetail && fracPondsItemDetail.includes('RWCS')) {
        this.setInputItemsCount(inputItemsData, padName, 'Aeration/Circulation Skid (for recycle)', fracPondsCount, aerationItem?.id);
      } else {
        this.setInputItemsCount(inputItemsData, padName, 'Aeration/Circulation Skid (for recycle)', 0, aerationItem?.id);
      }
    }
  }

  public calculateLandDamages(
    inputItemsData: any[],
    pads: string[],
    pipeLandDamagesInfo: LandDamagesPipe[],
    otherLandDamagesInfo: LandDamagesOther[],
    stateAbbreviation: string
  ): void {
    for (const pad of pads) {
      const padName = pad;
      const landDamageItem = inputItemsData.find(x => x.name ==  keyFacilityItem.LAND_DAMAGES);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.LAND_DAMAGES)) {
        let landDamages = 0;
        const baseGreenfieldCtbInstallCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
        );
        const coreGreenfieldSatelliteInstallCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
        );
        const coreStandardCompressorStationInstallCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
        );
        const coreGreenfieldSwdInstallCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
        );
        const swdWellPadAndHookupCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP
        );
        const waterSourceWellDcCostsCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.WATER_SOURCE_WELL_D_C_COSTS
        );
        const fracPondsCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.FRAC_PONDS);
        const edsMainLineGd6MvaCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.EDS_MAIN_LINE_6_MVA
        );
        const roadConstructionAndCalicheCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.ROAD_CONSTRUCTION_AND_CALICHE
        );
        const wellPadSingleUseAboveGradeWhCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.WELL_PAD_SINGLE_USE_ABOVE_GRADE_WH
        );
        const wellPadMultiUseSubGradeWhCount = this.getInputItemCount(
          inputItemsData,
          padName,
          keyFacilityItem.WELL_PAD_MULTI_USE_SUB_GRADE_WH
        );

        let pipelineItems = [];
        const inFieldGasGatheringSalesLp = inputItemsData.filter(
          item => item.name === keyFacilityItem.IN_FIELD_GAS_GATHERING_SALES_LP //multi values
        );
        const inFieldGasGatheringSalesHp = inputItemsData.filter(
          item => item.name === keyFacilityItem.IN_FIELD_GAS_GATHERING_SALES_HP //multi values
        );
        const gasLiftDistribution = inputItemsData.filter(item => item.name === keyFacilityItem.GAS_LIFT_DISTRIBUTION); //multi val
        const swdInjectionLine = inputItemsData.filter(item => item.name === keyFacilityItem.SWD_INJECTION_LINE); //multi val
        const regionalWaterPipeline = inputItemsData.filter(
          item => item.name === keyFacilityItem.REGIONAL_WATER_PIPELINE //multi val
        );
        const producedWaterGathering = inputItemsData.filter(
          item => item.name === keyFacilityItem.PRODUCED_WATER_GATHERING //multi val
        );
        const supplyWaterLine = inputItemsData.filter(item => item.name === keyFacilityItem.SUPPLY_WATER_LINE); //multi val
        const pondToPondTransferLine = inputItemsData.filter(
          item => item.name === keyFacilityItem.POND_TO_POND_TRANSFER_LINE //multi val
        );
        const recycleWaterTransferLine = inputItemsData.filter(
          item => item.name === keyFacilityItem.RECYCLE_WATER_TRANSFER_LINE //multi line
        );
        const commercialPipelineOilGathering = inputItemsData.filter(
          item => item.name === keyFacilityItem.COMMERCIAL_PIPELINE_OIL_GATHERING  //multi line
        );
        const oilGathering = inputItemsData.filter(item => item.name === keyFacilityItem.OIL_GATHERING); //multi val
        const flowlineMaterialsAndInstallation = inputItemsData.filter(
          item => item.name === keyFacilityItem.FLOWLINE_MATERIALS_AND_INSTALLATION  //multi val
        );
        
        pipelineItems = inFieldGasGatheringSalesLp.concat(inFieldGasGatheringSalesHp).concat(gasLiftDistribution).concat(swdInjectionLine)
                        .concat(regionalWaterPipeline).concat(producedWaterGathering).concat(supplyWaterLine).concat(pondToPondTransferLine)
                        .concat(recycleWaterTransferLine).concat(commercialPipelineOilGathering).concat(oilGathering).concat(flowlineMaterialsAndInstallation);


        for (const pipeline of pipelineItems) {
          const pipelineLength = pipeline[padName];
          const pipelineSize = this.getPipeLineSizeWithInch(pipeline.itemDetail.displayName);
          let landDamageCost = 0;
          // Perform Land Damages Pipe
          // Get the value LandDamage FT Cost based in the Nominal pipe size, example, 2", 3", ...20",24"... and his respective values cost.
          const pipeLandDamagesElement = pipeLandDamagesInfo.find(obj => obj.nominalPipeSize === pipelineSize);

          if (pipeLandDamagesElement !== undefined) {
            landDamageCost = pipeLandDamagesElement.landDamagesCostPerFoot;
          }

          const pipelineCost = (landDamageCost * pipelineLength) / 1000;
          landDamages += pipelineCost;
        }
        // Perform Land Damages Other
        // Get the element of the array of Lad Daages Other MDT
        const objBaseGreenfieldCtbInstall = otherLandDamagesInfo.filter(
          r => r.scope === keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL //multi
        );
        objBaseGreenfieldCtbInstall.forEach(item => {
          if (item !== undefined) {
            landDamages += (item.unitCost / 1000) * baseGreenfieldCtbInstallCount;
          }
        });  
        const objCoreGreenfieldSatelliteInstall = otherLandDamagesInfo.find(
          r => r.scope === keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
        );
        if (objCoreGreenfieldSatelliteInstall !== undefined) {
          landDamages += (objCoreGreenfieldSatelliteInstall.unitCost / 1000) * coreGreenfieldSatelliteInstallCount;
        }
        const objCoreStandardCompressorStationInstall = otherLandDamagesInfo.find(
          r => r.scope === keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
        );
        if (objCoreStandardCompressorStationInstall !== undefined) {
          landDamages +=
            (objCoreStandardCompressorStationInstall.unitCost / 1000) * coreStandardCompressorStationInstallCount;
        }
        const objCoreGreenfieldSwdInstall = otherLandDamagesInfo.find(
          r => r.scope === keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
        );
        if (objCoreGreenfieldSwdInstall !== undefined) {
          landDamages += (objCoreGreenfieldSwdInstall.unitCost / 1000) * coreGreenfieldSwdInstallCount;
        }
        const objSwdWellPadAndHookup = otherLandDamagesInfo.find(
          r => r.scope === keyFacilityItem.SWD_WELL_PAD_AND_HOOKUP
        );
        if (objSwdWellPadAndHookup !== undefined) {
          landDamages += (objSwdWellPadAndHookup.unitCost / 1000) * swdWellPadAndHookupCount;
        }
        const objWaterSourceWellDcCosts = otherLandDamagesInfo.filter(
          r => r.scope === keyFacilityItem.WATER_SOURCE_WELL_D_C_COSTS //multi
        );
        objWaterSourceWellDcCosts.forEach(item => {
          if (item !== undefined) {
            landDamages += (item.unitCost / 1000) * waterSourceWellDcCostsCount;
          }
        });

        const objFracPonds = otherLandDamagesInfo.filter(r => r.scope === keyFacilityItem.FRAC_PONDS); //multi
        objFracPonds.forEach(item => {
          if (item !== undefined) {
            landDamages += (item.unitCost / 1000) * fracPondsCount;
          }
        });

        const objEdsMainLineGd6Mva = otherLandDamagesInfo.find(r => r.scope === keyFacilityItem.EDS_MAIN_LINE_6_MVA);
        if (objEdsMainLineGd6Mva !== undefined) {
          landDamages += (objEdsMainLineGd6Mva.unitCost / 1000) * edsMainLineGd6MvaCount;
        }
        const objRoadConstructionAndCaliche = otherLandDamagesInfo.find(
          r => r.scope === keyFacilityItem.ROAD_CONSTRUCTION_AND_CALICHE
        );
        if (objRoadConstructionAndCaliche !== undefined) {
          landDamages += (objRoadConstructionAndCaliche.unitCost / 1000) * roadConstructionAndCalicheCount;
        }
        const objWellPadSingleUseAboveGradeWh = otherLandDamagesInfo.filter(
          r => r.scope === keyFacilityItem.WELL_PAD_SINGLE_USE_ABOVE_GRADE_WH //multi
        );
        objWellPadSingleUseAboveGradeWh.forEach(item => {
          if (item !== undefined) {
            landDamages += (item.unitCost / 1000) * wellPadSingleUseAboveGradeWhCount;
          }
        });

        const objWellPadMultiUseSubGradeWh = otherLandDamagesInfo.filter(
          r => r.scope === keyFacilityItem.WELL_PAD_MULTI_USE_SUB_GRADE_WH //multi
        );
        objWellPadMultiUseSubGradeWh.forEach(item => {
          if (item !== undefined) {
            landDamages += (item.unitCost / 1000) * wellPadMultiUseSubGradeWhCount;
          }
        });

        // this change goes as for the New Mexico Land Damages calculation takes only 1/4 of the normal calculation,
        // otherwise will result the normal 100% calculation.

        if (stateAbbreviation.trim() === 'NM' || stateAbbreviation.trim() === 'New Mexico') {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.LAND_DAMAGES, landDamages / 4, landDamageItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, padName, keyFacilityItem.LAND_DAMAGES, landDamages, landDamageItem?.id);
        }
      }
    }
  }

  public getPipelineSize(itemDetail: string): string {
    const inchIndex = itemDetail.search('"');
    const size = itemDetail.substring(0, inchIndex);
    return size;
  }

  public getPipeLineSizeWithInch(itemDetail: string): string {
    if (itemDetail !== null) {
      const inchIndex = itemDetail.search('"');
      const sizeWithInch = itemDetail.substring(0, inchIndex + 1);
      return sizeWithInch;
    } else {
      return '0';
    }
  }

  // For this calculations there are references to Water and Gas Data.
  public lookupGasAndWaterEquipment(
    inputItemsData: any[],
    gasForecastData: GasForecastRow[],
    waterForecastData: WaterForecastRow[],
    pads: string[]
  ): void {
    for (const pad of pads) {
      // Get information of the pad from gas and water
      const padName = pad;
      const padWaterRow = waterForecastData.find(
        row => row.padName && this.utilService.camelize(row.padName) === padName
      );
      const padGasRow = gasForecastData.find(row => row.padName && this.utilService.camelize(row.padName) === padName);

      // Looks up the PRICET "Bracket" name in the Gas Forecast or Water forecast Tab and returns the associated value  for the specific column.
      // Removed Gas and Water logic giving US 1245908
      // this.calculateGasAndWaterEquipment_Pad(
      //   padName,
      //   padGasRow,
      //   'fundStation',
      //   keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL,
      //   inputItemsData
      // );
      // this.calculateGasAndWaterEquipment_Pad(
      //   padName,
      //   padGasRow,
      //   'fundCompressor',
      //   keyFacilityItem.RENTAL_3516,
      //   inputItemsData
      // );
      this.calculateGasAndWaterEquipment_Pad(
        padName,
        padWaterRow,
        'fundHpump',
        keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION,
        inputItemsData
      );
      this.calculateGasAndWaterEquipment_Pad(
        padName,
        padWaterRow,
        'fundTrain',
        keyFacilityItem.SWD_TRAIN_15000_BWPD,
        inputItemsData
      );
      // Removed Gas and Water for this item giving the US 1245908
      // this.calculateGasAndWaterEquipment_Pad(
      //   padName,
      //   padWaterRow,
      //   'fundStation',
      //   keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL,
      //   inputItemsData
      // );
    }
  }

  public calculateGasAndWaterEquipment_Pad(
    padName: string,
    padRow: any,
    fieldName: string,
    itemName: string,
    inputItemsData: any[]
  ): void {
    const gasWaterEquipItem = inputItemsData.find(x => x.name == itemName);

    if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, itemName)) {
      if (padRow) {
        this.setInputItemsCount(inputItemsData, padName, itemName, padRow[fieldName], gasWaterEquipItem?.id);
      } else {
        this.setInputItemsCount(inputItemsData, padName, itemName, 0, gasWaterEquipItem?.id);
      }
    }
  }

  public calculateCompressorStationDependantItems(inputItemsData: any[], pads: string[]): void {
    for (const pad of pads) {
      const padName = pad;
      const coreStandardCompressorStationCount = this.getInputItemCount(
        inputItemsData,
        padName,
        keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
      );
      
      const csVRUItems = inputItemsData.filter(x => x.name == keyFacilityItem.COMPRESSOR_STATION_VRU);
      const flareSysItems = inputItemsData.filter(x => x.name == keyFacilityItem.FLARE_SYSTEM);

      csVRUItems.forEach(csVRUItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.COMPRESSOR_STATION_VRU, csVRUItem.id)) {
          this.setInputItemsCount(
            inputItemsData,
            padName,
            keyFacilityItem.COMPRESSOR_STATION_VRU,
            coreStandardCompressorStationCount * 2,
            csVRUItem.id
          );
        }
      });

      flareSysItems.forEach(flareSysItem => {
        if (!this.overriddenFieldsService.isPadItemCountOverridden(padName, keyFacilityItem.FLARE_SYSTEM, flareSysItem.id)) {
          this.setInputItemsCount(
            inputItemsData,
            padName,
            keyFacilityItem.FLARE_SYSTEM,
            coreStandardCompressorStationCount,
            flareSysItem.id
          );
        }
      });
    }
  }

  public setInputItemsCount(inputItemsData: any[], padBracket: string, itemName: string, count: number, itemId?:string, respToExpandFacilityItemId?:string): void {
    const inputItem = inputItemsData.find(item => item.name === itemName && (itemId? item.id == itemId:true) && (respToExpandFacilityItemId? item.respToExpandFacilityItemId == respToExpandFacilityItemId:true));
    if (inputItem) {
      inputItem[padBracket] = count;
    }
  }

  public setAdditionalItemCount(
    inputItemsData: any[],
    padBracket: string,
    displayItemName: string,
    count: number
  ): void {
    inputItemsData.find(item => item.displayName === displayItemName)[padBracket] = count;
  }

  public setInputItemValue(inputItemsData: any[], itemName: string, itemFieldName: string, value: any, itemId?: string,respToExpandFacilityItemId? : string): void {
    const inputItem = inputItemsData.find(item => item.name === itemName && (itemId? item.id == itemId: true) && (respToExpandFacilityItemId? item.respToExpandFacilityItemId == respToExpandFacilityItemId : true));
    if (inputItem) {
      return (inputItem[itemFieldName] = value);
    }
  }

  public setAdditionalItemValue(
    inputItemsData: any[],
    itemDisplayName: string,
    itemFieldName: string,
    value: any
  ): void {
    inputItemsData.find(item => item.displayName === itemDisplayName)[itemFieldName] = value;
  }

  public setInputItemNameValue(inputItemsData: any[], itemName: string, itemFieldName: string, value: any): void {
    inputItemsData.find(item => item.name === itemName)[itemFieldName].name = value;
  }

  public getInputItemCount(inputItemsData: any[], padBracket: string, itemName: string, itemId?: string, respToExpandFacilityItemId?: string): number {
    if(itemId)
    {
      const inputItem = inputItemsData.find(item => item.name === itemName && item.id == itemId && (respToExpandFacilityItemId ? item.respToExpandFacilityItemId == respToExpandFacilityItemId : true));
      return inputItem? inputItem[padBracket]:null;
    }
    else
    {
      const inputItemCount = inputItemsData.filter(item => item.name === itemName).reduce((sum, current) => sum + (current[padBracket] != undefined? current[padBracket]:0), null);
      return inputItemCount;
    }
  }

  public getInputItemCost(inputItemsData: any[], itemName: string, itemId?:string): number {
    const inputItem = inputItemsData.find(item => item.name === itemName && (itemId? itemId == item.id: true));
    if (inputItem) {
      return inputItem.costPunit;
    }
    return 0;
  }

  public getInputItemCountByDisplayName(inputItemsData: any[], padBracket: string, itemName: string, itemId : string): number {
    return inputItemsData.find(item => item.displayName === itemName && item.id == itemId)[padBracket];
  }

  public getInputItemValue(inputItemsData: any[], itemName: string, itemFieldName: string): any {
    const inputItemVal = inputItemsData.filter(item => item.name === itemName).reduce((sum, current) => sum + (current[itemFieldName]!= undefined? current[itemFieldName]:0), null);
    return inputItemVal;
  }

  public isFirstPadWith(
    orderedPads: IPadBracket[],
    padName: string,
    fn: (pad: IPadBracket, index, array) => boolean
  ): boolean {
    const firstIndex = orderedPads.findIndex(fn);
    if (firstIndex > -1 && orderedPads[firstIndex].padName === padName) {
      return true;
    } else {
      return false;
    }
  }

  public isAdditionalItem(id: string): boolean {
    return this.lstAdditionalItemsIds.includes(id.toUpperCase());
  }

  // Overwrite Cost/Unit column in Scope Count Grid
  public updateItemCostPunit(
    inputItemsData: any[],
    itemName: string,
    itemId: string,
    costValue?: number,
    costDefault?: number
  ): void {
    if (inputItemsData !== null && inputItemsData !== undefined && itemName !== null && itemName !== undefined) {
      if (!this.isAdditionalItem(itemId)) {
        if (costDefault === null || costDefault === undefined) {
          costDefault = 0;
        }
        if (costValue === null || costValue === undefined) {
          this.setInputItemValue(inputItemsData, itemName, 'costPunit', costDefault, itemId);
          this.setInputItemValue(inputItemsData, itemName, 'costUnitOverride', 0,itemId);
        } else {
          if (costValue === costDefault) {
            this.setInputItemValue(inputItemsData, itemName, 'costPunit', costDefault, itemId);
            this.setInputItemValue(inputItemsData, itemName, 'costUnitOverride', 0, itemId);
          } else {
            this.setInputItemValue(inputItemsData, itemName, 'costPunit', costValue, itemId);
            this.setInputItemValue(inputItemsData, itemName, 'costUnitOverride', 1, itemId);
          }
        }
      }
    }
  }

  // Defalt calculation for Liquids Pipeline Risers item
  public calculateForLiquidsPipelineRiser(inputItemsData: any[], pads: string[],itemId : string = ''): void {
    // There is a dependency between “Liquids Pipeline Riser” Row and “In-Field Liquids Gathering from Satellite” row.
    const rowSource = 'In-Field Liquids Gathering from Satellite';
    const rowCalculated = 'Liquids Pipeline Riser';

    // Apply the calculation for each PadName
    pads.forEach(pad => {
      
      let scopeCountCalculated = 0;
      const liqRiserItems = inputItemsData.filter(x => x.name == rowCalculated && (itemId? x.respToExpandFacilityItemId == itemId.trim():true));
      liqRiserItems.forEach(liqRiserItem => {
        // if it is not overwritten, calculate it, get the result...
        if (!this.overriddenFieldsService.isPadItemCountOverridden(pad, rowCalculated, liqRiserItem.id)) {
          const scopeCountSource = this.getInputItemCount(inputItemsData, pad, rowSource,(itemId ? itemId : liqRiserItem.respToExpandFacilityItemId));
          const scopeCountSourceRounded = Math.round(scopeCountSource / 5280) - 1;
          if (scopeCountSource > 0 && scopeCountSourceRounded > 0) {
            scopeCountCalculated = 1 + scopeCountSourceRounded;
          } else if (scopeCountSource > 0 && scopeCountSourceRounded <= 0) {
            scopeCountCalculated = 1;
          } else {
            scopeCountCalculated = 0;
          }
          this.setInputItemsCount(inputItemsData, pad, rowCalculated, scopeCountCalculated, liqRiserItem.id,liqRiserItem.respToExpandFacilityItemId);
        }
      });
    });
  }

  // US 1558685 High Accuracy Satellite Metering Upgrade
  public calculateHighAccurancySatelliteMeteringUpgrade(inputItemsData: any[], pads: string[]): void {
    pads.forEach(item => {
      const greenfieldSatelliteInstall = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
      );
      const satelliteItem = inputItemsData.find(x => x.name == keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE);


      if (
        !this.overriddenFieldsService.isPadItemCountOverridden(
          item,
          keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE
        )
      ) {
        this.setInputItemsCount(
          inputItemsData,
          item,
          keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE,
          greenfieldSatelliteInstall,
          satelliteItem?.id
        );
      }
    });
  }

  // US 1558685 Satellite Brownfield Adder
  public calculateSatelliteBrowfieldAdder(inputItemsData: any[], pads: string[]): void {
    pads.forEach(item => {
      const greenfieldSatelliteInstall = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.GREENFIELD_SATELLITE_INSTALL
      );
      const satelliteHeader = this.getInputItemCount(inputItemsData, item, keyFacilityItem.SATELLITE_HEADER);
      const satelliteTestSeparator = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.SATELLITE_TEST_SEPARATOR
      );
      const hiAccurancySatelliteMeteringUpgrade = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE
      );
      const satellitePumpingKid = this.getInputItemCount(inputItemsData, item, keyFacilityItem.SATELLITE_PIMPING_SKID);
      const bfAdderItem = inputItemsData.find(x => x.name == keyFacilityItem.SATELLITE_BROWNFIELD_ADDER);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(item, keyFacilityItem.SATELLITE_BROWNFIELD_ADDER)) {
        if (
          greenfieldSatelliteInstall === 0 &&
          (satelliteHeader !== 0 ||
            satelliteTestSeparator !== 0 ||
            hiAccurancySatelliteMeteringUpgrade !== 0 ||
            satellitePumpingKid !== 0)
        ) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SATELLITE_BROWNFIELD_ADDER, 1, bfAdderItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SATELLITE_BROWNFIELD_ADDER, 0, bfAdderItem?.id);
        }
      }
    });
  }

  public updateItemIsVisible(
    inputItemsData: any[],
    itemNameSelected: string,
    parentItemIdSelected: string,
    isExpanded: boolean
  ): void {
    if (
      inputItemsData !== null &&
      inputItemsData !== undefined &&
      parentItemIdSelected !== null &&
      parentItemIdSelected !== undefined
    ) {
      inputItemsData.forEach(item => {
        if (
          item.respToExpandFacilityItemId !== undefined &&
          item.respToExpandFacilityItemId !== null &&
          item.respToExpandFacilityItemId === parentItemIdSelected
        ) {
          // this.setInputItemValue(inputItemsData, item.Name, 'isVisible', item.isVisible == 0 ? 1: 0);
          item.isVisible = item.isVisible === 0 ? 1 : 0;
        }
      });
      const itemsSelected = inputItemsData.filter(x => x.id == parentItemIdSelected);
      itemsSelected.forEach(item => {
        this.setInputItemValue(inputItemsData, itemNameSelected, 'isExpanded', !isExpanded, item.id);
      });
    }
  }

  // Method used when the application get the information of Padbuilder for new Scenarios.
  public transformFacilityItemsToLoad(
    facilityItems: FacilityItemModel[],
    rfsdOptions: Rfsd[],
    scopeCategoryOptions: InfrastructureScopeCategory[],
    locationOptions: Location[],
    kindOptions: Kind[],
    infrastructureClassOptions: InfrastructureClass[],
    unitOfMeasureOptions: UnitOfMeasure[],
    scenarioAfeTypeOptions: ScenarioAfeType[],
  ): InputItem[] {
    const infraItemsModel: InputItem[] = [];

    if (
      facilityItems !== null &&
      facilityItems !== undefined &&
      rfsdOptions !== null &&
      rfsdOptions !== undefined &&
      scopeCategoryOptions !== null &&
      scopeCategoryOptions !== undefined &&
      locationOptions !== null &&
      locationOptions !== undefined &&
      kindOptions !== null &&
      kindOptions !== undefined &&
      infrastructureClassOptions !== null &&
      infrastructureClassOptions !== undefined &&
      unitOfMeasureOptions !== null &&
      unitOfMeasureOptions !== undefined &&
      scenarioAfeTypeOptions !== undefined
    ) {
      facilityItems.forEach((facilityItem, index) => {
        const cScopeCategory = scopeCategoryOptions.find(o => o.id === facilityItem.infrastructureScopeCategoryId);
        const cRfsd = rfsdOptions.find(o => o.id === facilityItem.readyForServiceDateTimingId);
        const cLocation = locationOptions.find(o => o.id === facilityItem.locationId);
        const cKind = kindOptions.find(o => o.id === facilityItem.kindId);
        const cScenarioAfeType = scenarioAfeTypeOptions.find(o => o.id === facilityItem.scenarioAfeTypeId);
        let cInfrastructureClass: InfrastructureClass = null;
        if (cScopeCategory !== null && cScopeCategory !== undefined && cScopeCategory !== undefined) {
          cInfrastructureClass = infrastructureClassOptions.find(o => o.id === cScopeCategory.infrastructureClassId);
        }
        const cUnitOfMeasure = unitOfMeasureOptions.find(o => o.id === facilityItem.unitOfMeasureId);

        infraItemsModel[index] = {
          name: facilityItem.name,
          displayName: facilityItem.name,
          itemNotes: facilityItem.itemNotes,
          unitOfMeasure:
            cUnitOfMeasure !== null && cUnitOfMeasure !== undefined
              ? {
                  id: cUnitOfMeasure.id,
                  name: cUnitOfMeasure.name,
                  displayName: cUnitOfMeasure.name,
                }
              : {
                  id: facilityItem.unitOfMeasureId,
                  name: '',
                  displayName: '',
                },
          costPunit: facilityItem.costPunit,
          userCategory: '',
          total: facilityItem.total,
          infraestructureScopeCategoryId: facilityItem.infrastructureScopeCategoryId,
          itemTypeId: Guid.EMPTY,
          itemDetailId: Guid.EMPTY,
          locationId: facilityItem.locationId,
          scenarioAfeTypeId: facilityItem.scenarioAfeTypeId,
          rfsdTimingId: facilityItem.readyForServiceDateTimingId,
          kindId: facilityItem.kindId,
          infraestructureScopeCategory:
            cScopeCategory !== null && cScopeCategory !== undefined
              ? {
                  id: cScopeCategory.id,
                  name: cScopeCategory.name,
                  displayName: cScopeCategory.name,
                  infrastructureClassId: cScopeCategory.infrastructureClassId,
                }
              : {
                  id: facilityItem.infrastructureScopeCategoryId,
                  name: '',
                  displayName: '',
                  infrastructureClassId: Guid.EMPTY,
                },
          infrastructureClass:
            cInfrastructureClass !== null && cInfrastructureClass !== undefined
              ? {
                  id: cInfrastructureClass.id,
                  name: cInfrastructureClass.name,
                  displayName: cInfrastructureClass.name,
                }
              : {
                  id: Guid.EMPTY,
                  name: '',
                  displayName: '',
                },
          location:
            cLocation !== null && cLocation !== undefined
              ? {
                  id: cLocation.id,
                  name: cLocation.name,
                  displayName: cLocation.name,
                }
              : {
                  id: facilityItem.locationId,
                  name: '',
                  displayName: '',
                },
         
          rfsd:
            cRfsd !== null && cRfsd !== undefined
              ? {
                  id: cRfsd.id,
                  name: cRfsd.name,
                  displayName: cRfsd.name,
                }
              : {
                  id: facilityItem.readyForServiceDateTimingId,
                  name: '',
                  displayName: '',
                },
          kind:
            cKind !== null && cKind !== undefined
              ? {
                  id: cKind.id,
                  name: cKind.name,
                  displayName: cKind.name,
                }
              : {
                  id: facilityItem.kindId,
                  name: '',
                  displayName: '',
                },
          scenarioAfeType:
              cScenarioAfeType !== null && cScenarioAfeType !== undefined
              ? {
                  id: cScenarioAfeType.id,
                  name: cScenarioAfeType.name,
                  displayName: cScenarioAfeType.name,
                }
              : {
                  id: facilityItem.scenarioAfeTypeId,
                  name: '',
                  displayName: '',
                },
          itemType: {
            id: Guid.EMPTY,
            name: facilityItem.type,
            displayName: facilityItem.type,
          },
          itemDetail:
            facilityItem.facilityItemDetail !== null && facilityItem.facilityItemDetail
              ? {
                  id: facilityItem.id,
                  name: facilityItem.type,
                  displayName: facilityItem.type,
                  itemTypeId: Guid.EMPTY,
                }
              : null,
          id: facilityItem.id,
          orderItem: facilityItem.sortOrder,
          costUnitOverride: facilityItem.costUnitOverride,
          itemDetails:
            facilityItem.facilityItemDetails !== null && facilityItem.facilityItemDetails !== undefined
              ? facilityItem.facilityItemDetails
              : null,
          isVisible: facilityItem.isVisible,
          respToExpandFacilityItemId: facilityItem.parentFacilityItemId,
          isExpanded: facilityItem.isExpanded,
          unitOfMeasureId: facilityItem.unitOfMeasureId,
          isCloned: false,
          isDeleted: false
        };
      });
    }
    return infraItemsModel;
  }

  // Method used when the application open an existing scenario.
  public transformFacilityItemsToLoadDetails(
    pItemsAndDetails: ItemAndDetailsModel[],
    pScenarioItems: InputItem[],
    unitOfMeasureOptions: UnitOfMeasure[]
  ): InputItem[] {
    const vInputItems: InputItem[] = [];
    if (
      pItemsAndDetails !== null &&
      pItemsAndDetails !== undefined &&
      pScenarioItems !== null &&
      pScenarioItems !== undefined &&
      unitOfMeasureOptions !== null &&
      unitOfMeasureOptions !== undefined
    ) {
      pScenarioItems.forEach((scenItem, index) => {
        
        let itemSelected: ItemAndDetailsModel = null;
        let itemDetailSelected: IDetailsModel = null;
        // Get items with detail of Performance Domain array
        let facilityItemOption = pItemsAndDetails.find(o => o.id === scenItem.id);
        if (!facilityItemOption) {
          for (const item of pItemsAndDetails) {
            itemDetailSelected = item.itemDetails.find(o => o.id === scenItem.id);
            if (itemDetailSelected) {
              itemSelected = item;
              break;
            }
          }

          if (itemSelected) {
            const details = itemSelected.itemDetails.filter(o => o.id !== itemDetailSelected.id);

            facilityItemOption = {
              id: itemDetailSelected.id,
              name: itemSelected.name,
              itemNotes: itemDetailSelected.itemDetailNotes,
              unitOfMeasureId: itemSelected.unitOfMeasureId,
              unitOfMeasureName: itemSelected.unitOfMeasureName,
              orderItem: itemSelected.orderItem,
              itemDetails: itemSelected.itemDetails,
              // itemDetails: details,
            };
          }
        }

        let detailSelected: IDetailsModel = null;
        const cUnitOfMeasure = unitOfMeasureOptions.find(o => o.id === scenItem.unitOfMeasureId);

        if (scenItem.itemDetail !== null && scenItem.itemDetail !== undefined) {
          //We dont have a place where store the id from current selected type in Scopecount, so get the id by name.
          const selectedFacilityItem = facilityItemOption
            ? facilityItemOption.itemDetails.find(x => x.name === scenItem.itemDetail.name && x.id == scenItem.itemDetail.id)
            : null;

          detailSelected = {
            id: selectedFacilityItem ? selectedFacilityItem.id : null,
            name: scenItem.itemDetail.name,
            displayName: scenItem.itemDetail.name,
            itemDetailNotes: '',
          } as IDetailsModel;
        } else {
          detailSelected = {
            id: Guid.EMPTY,
            name: '',
            displayName: '',
            itemDetailNotes: '',
          } as IDetailsModel;
        }

        // Validate match ids Performance and C&D
        if (facilityItemOption !== null && facilityItemOption !== undefined) {
          // Prepare array of details
          const objItemDetails: any[] = [];
          if (facilityItemOption.itemDetails !== null && facilityItemOption.itemDetails !== undefined) {
            facilityItemOption.itemDetails.forEach((d, index2) => {
              if (
                d.name !== null &&
                d.name !== undefined &&
                scenItem.itemTypeId !== null &&
                scenItem.itemTypeId !== undefined
              ) {
                if (d.name.toUpperCase() === scenItem.itemTypeId.toUpperCase()) {
                  detailSelected = {
                    id: d.id,
                    name: d.name,
                    displayName: d.name,
                    itemDetailNotes: d.itemDetailNotes,
                  } as IDetailsModel;
                }
              }
              objItemDetails[index2] = {
                id: d.id,
                name: d.name,
                displayName: d.name,
                itemDetailNotes: d.itemDetailNotes,
              };
            });
          }

          vInputItems[index] = {
            name: facilityItemOption.name,
            displayName: facilityItemOption.name,
            itemNotes: facilityItemOption.itemNotes,
            unitOfMeasure:
              cUnitOfMeasure !== null && cUnitOfMeasure !== undefined
                ? {
                    id: cUnitOfMeasure.id,
                    name: cUnitOfMeasure.name,
                    displayName: cUnitOfMeasure.name,
                  }
                : {
                    id: scenItem.unitOfMeasureId,
                    name: scenItem.unitOfMeasure.name,
                    displayName: scenItem.unitOfMeasure.name,
                  },
            costPunit: scenItem.costPunit,
            userCategory: scenItem.userCategory,
            total: scenItem.total,
            infraestructureScopeCategoryId: scenItem.infraestructureScopeCategoryId,
            itemTypeId: Guid.EMPTY,
            itemDetailId: detailSelected !== null && detailSelected !== undefined ? detailSelected.id : Guid.EMPTY, // performance domain
            // itemDetailId: Guid.EMPTY,// performance domain
            locationId: scenItem.locationId,           
            rfsdTimingId: scenItem.rfsdTimingId,
            kindId: scenItem.kindId,
            scenarioAfeTypeId: scenItem.scenarioAfeTypeId,
            infraestructureScopeCategory:
              scenItem.infraestructureScopeCategory !== null && scenItem.infraestructureScopeCategory !== undefined
                ? {
                    id: scenItem.infraestructureScopeCategory.id,
                    name: scenItem.infraestructureScopeCategory.name,
                    displayName: scenItem.infraestructureScopeCategory.name,
                    infrastructureClassId: scenItem.infraestructureScopeCategory.infrastructureClassId,
                  }
                : {
                    id: scenItem.infraestructureScopeCategoryId,
                    name: '',
                    displayName: '',
                    infrastructureClassId: Guid.EMPTY,
                  },
            infrastructureClass:
              scenItem.infrastructureClass !== null && scenItem.infrastructureClass !== undefined
                ? {
                    id: scenItem.infrastructureClass.id,
                    name: scenItem.infrastructureClass.name,
                    displayName: scenItem.infrastructureClass.name,
                  }
                : {
                    id: Guid.EMPTY,
                    name: '',
                    displayName: '',
                  },
            location:
              scenItem.location !== null && scenItem.location !== undefined
                ? {
                    id: scenItem.location.id,
                    name: scenItem.location.name,
                    displayName: scenItem.location.name,
                  }
                : {
                    id: scenItem.locationId,
                    name: '',
                    displayName: '',
                  },
            rfsd:
              scenItem.rfsd !== null && scenItem.rfsd !== undefined
                ? {
                    id: scenItem.rfsd.id,
                    name: scenItem.rfsd.name,
                    displayName: scenItem.rfsd.name,
                  }
                : {
                    id: scenItem.rfsdTimingId,
                    name: '',
                    displayName: '',
                  },
            kind:
              scenItem.kind !== null && scenItem.kind !== undefined
                ? {
                    id: scenItem.kind.id,
                    name: scenItem.kind.name,
                    displayName: scenItem.kind.name,
                  }
                : {
                    id: scenItem.kindId,
                    name: '',
                    displayName: '',
                  },
            scenarioAfeType:
                scenItem.scenarioAfeType !== null && scenItem.scenarioAfeType !== undefined
                ? {
                    id: scenItem.scenarioAfeType.id,
                    name: scenItem.scenarioAfeType.name,
                    displayName: scenItem.scenarioAfeType.name,
                  }
                : {
                    id: scenItem.locationId,
                    name: '',
                    displayName: '',
                  },
            itemType: {
              id: Guid.EMPTY,
              name: scenItem.itemTypeId,
              displayName: scenItem.itemTypeId,
            },
            itemDetail:
              detailSelected !== null && detailSelected
                ? {
                    id: detailSelected.id,
                    name: detailSelected.name,
                    displayName: detailSelected.displayName,
                    itemTypeId: Guid.EMPTY,
                  }
                : null,
            id: scenItem.id,
            orderItem: scenItem.orderItem,
            costUnitOverride: scenItem.costUnitOverride,
            itemDetails: objItemDetails !== null && objItemDetails !== undefined ? objItemDetails : null,
            isVisible: scenItem.isVisible,
            respToExpandFacilityItemId: scenItem.respToExpandFacilityItemId,
            isExpanded: scenItem.isExpanded,
            unitOfMeasureId: scenItem.unitOfMeasureId,
            isDeleted: scenItem.isDeleted,
            isCloned:scenItem.isCloned
          };
        } else {
          // Additional items
          vInputItems[index] = {
            name: scenItem.name,
            displayName: scenItem.displayName,
            itemNotes: '',
            unitOfMeasure:
              cUnitOfMeasure !== null && cUnitOfMeasure !== undefined
                ? {
                    id: cUnitOfMeasure.id,
                    name: cUnitOfMeasure.name,
                    displayName: cUnitOfMeasure.name,
                  }
                : {
                    id: scenItem.unitOfMeasureId,
                    name: scenItem.unitOfMeasure.name,
                    displayName: scenItem.unitOfMeasure.name,
                  },
            costPunit: scenItem.costPunit,
            userCategory: scenItem.userCategory,
            total: scenItem.total,
            infraestructureScopeCategoryId: scenItem.infraestructureScopeCategoryId,
            itemTypeId: Guid.EMPTY,
            itemDetailId: Guid.EMPTY,
            locationId: scenItem.locationId,
            rfsdTimingId: scenItem.rfsdTimingId,
            kindId: scenItem.kindId,
            scenarioAfeTypeId: scenItem.scenarioAfeTypeId,
            infraestructureScopeCategory:
              scenItem.infraestructureScopeCategory !== null && scenItem.infraestructureScopeCategory !== undefined
                ? {
                    id: scenItem.infraestructureScopeCategory.id,
                    name: scenItem.infraestructureScopeCategory.name,
                    displayName: scenItem.infraestructureScopeCategory.name,
                    infrastructureClassId: scenItem.infraestructureScopeCategory.infrastructureClassId,
                  }
                : {
                    id: scenItem.infraestructureScopeCategoryId,
                    name: '',
                    displayName: '',
                    infrastructureClassId: Guid.EMPTY,
                  },
            infrastructureClass:
              scenItem.infrastructureClass !== null && scenItem.infrastructureClass !== undefined
                ? {
                    id: scenItem.infrastructureClass.id,
                    name: scenItem.infrastructureClass.name,
                    displayName: scenItem.infrastructureClass.name,
                  }
                : {
                    id: scenItem.infrastructureClass.id,
                    name: '',
                    displayName: '',
                  },
            location:
              scenItem.location !== null && scenItem.location !== undefined
                ? {
                    id: scenItem.location.id,
                    name: scenItem.location.name,
                    displayName: scenItem.location.name,
                  }
                : {
                    id: scenItem.locationId,
                    name: '',
                    displayName: '',
                  },
            rfsd:
              scenItem.rfsd !== null && scenItem.rfsd !== undefined
                ? {
                    id: scenItem.rfsd.id,
                    name: scenItem.rfsd.name,
                    displayName: scenItem.rfsd.name,
                  }
                : {
                    id: scenItem.rfsdTimingId,
                    name: '',
                    displayName: '',
                  },
            kind:
              scenItem.kind !== null && scenItem.kind !== undefined
                ? {
                    id: scenItem.kind.id,
                    name: scenItem.kind.name,
                    displayName: scenItem.kind.name,
                  }
                : {
                    id: scenItem.kindId,
                    name: '',
                    displayName: '',
                  },
            scenarioAfeType:
              scenItem.scenarioAfeType !== null && scenItem.scenarioAfeType !== undefined
                ? {
                    id: scenItem.scenarioAfeType.id,
                    name: scenItem.scenarioAfeType.name,
                    displayName: scenItem.scenarioAfeType.name,
                  }
                : {
                    id: scenItem.locationId,
                    name: '',
                    displayName: '',
                  },
            itemType: {
              id: Guid.EMPTY,
              name: scenItem.itemTypeId,
              displayName: scenItem.itemTypeId,
            },
            itemDetail:
              detailSelected !== null && detailSelected !== undefined
                ? {
                    id: detailSelected.id,
                    name: '',
                    displayName: detailSelected.displayName,
                    itemTypeId: Guid.EMPTY,
                    itemDetailNotes: '',
                  }
                : {
                    id: Guid.EMPTY,
                    name: '',
                    displayName: '',
                    itemTypeId: Guid.EMPTY,
                    itemDetailNotes: '',
                  },
            id: scenItem.id,
            orderItem: scenItem.orderItem,
            costUnitOverride: scenItem.costUnitOverride,
            itemDetails: null,
            isVisible: scenItem.isVisible,
            respToExpandFacilityItemId: scenItem.respToExpandFacilityItemId,
            isExpanded: scenItem.isExpanded,
            unitOfMeasureId: scenItem.unitOfMeasureId,
            isCloned:scenItem.isCloned,
            isDeleted:scenItem.isDeleted
          };
        }
      });
    }

    return vInputItems;
  }

  // US 1558694
  public calculateSWDInjectionHPump(inputItemsData: any[], pads: string[]): void {
    pads.forEach(item => {
      const greenfield2TrainSWDInstall = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
      );
      const twoTrainSWDAddition = this.getInputItemCount(inputItemsData, item, keyFacilityItem.TWO_TRAIN_SWD_ADDITION);
      const oneTrainSWDAddition = this.getInputItemCount(inputItemsData, item, keyFacilityItem.ONE_TRAIN_SWD_ADDITION);
      const swdPumpItem = inputItemsData.find(x => x.name == keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION);

      if (
        !this.overriddenFieldsService.isPadItemCountOverridden(item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION)
      ) {
        if (greenfield2TrainSWDInstall === 1 && twoTrainSWDAddition === 1 && oneTrainSWDAddition === 0) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 12, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 1 && twoTrainSWDAddition === 0 && oneTrainSWDAddition === 0) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 6, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 0 && twoTrainSWDAddition === 0 && oneTrainSWDAddition === 1) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 3, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 1 && twoTrainSWDAddition === 0 && oneTrainSWDAddition === 1) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 9, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 1 && twoTrainSWDAddition === 1 && oneTrainSWDAddition === 1) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 15, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 0 && twoTrainSWDAddition === 0 && oneTrainSWDAddition === 0) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 0, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 0 && twoTrainSWDAddition === 1 && oneTrainSWDAddition === 1) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 9, swdPumpItem?.id);
        } else if (greenfield2TrainSWDInstall === 0 && twoTrainSWDAddition === 1 && oneTrainSWDAddition === 0) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 6, swdPumpItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION, 0, swdPumpItem?.id);
        }
      }
    });
  }

  public calculateSWDBrownfieldAdder(inputItemsData: any[], pads: string[]): void {
    pads.forEach(item => {
      const greenfield2TrainSWDInstall = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
      );
      const twoTrainSWDAddition = this.getInputItemCount(inputItemsData, item, keyFacilityItem.TWO_TRAIN_SWD_ADDITION);
      const oneTrainSWDAddition = this.getInputItemCount(inputItemsData, item, keyFacilityItem.ONE_TRAIN_SWD_ADDITION);
      const swdInjectionHPumpAtStation = this.getInputItemCount(
        inputItemsData,
        item,
        keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION
      );
      
      const swdBFAdderItem = inputItemsData.find(x => x.name ==  keyFacilityItem.SWD_BROWNFIELD_ADDER);

      if (!this.overriddenFieldsService.isPadItemCountOverridden(item, keyFacilityItem.SWD_BROWNFIELD_ADDER)) {
        if (
          greenfield2TrainSWDInstall === 0 &&
          twoTrainSWDAddition === 0 &&
          oneTrainSWDAddition === 0 &&
          swdInjectionHPumpAtStation !== 0
        ) {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_BROWNFIELD_ADDER, 1, swdBFAdderItem?.id);
        } else {
          this.setInputItemsCount(inputItemsData, item, keyFacilityItem.SWD_BROWNFIELD_ADDER, 0, swdBFAdderItem?.id);
        }
      }
    });
  }

  public getItemCostPadByLogic(
    inputItemsData: any[],
    mappingCostSft: ICndCostMappingSft[],
    padName: string,
    itemName: string,
    itemId: string,
    itemCountByPad: number,
    itemCostPerUnit: number,
    costYear: string
  ): number {
    var year: number = +costYear;

    // Don't run calculations if mapping cost is empty or undefined
    if (!mappingCostSft || mappingCostSft.length === 0) {
      return itemCountByPad * itemCostPerUnit;
    }

    let cost = 0;
    const greenfield1TrainCtbInstallCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
    );
    const ctb1TrainAdditionCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.TRAIN_1_CTB_ADDITION
    );
    const greenfieldCompressorStationInstallCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.GREENFIELD_COMPRESSOR_STATION_INSTALL
    );

    const greenfield2TrainSWDInstallCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL
    );

    const oneTrainSwdAdditionCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.ONE_TRAIN_SWD_ADDITION
    );
    const twoTrainSwdAdditionCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.TWO_TRAIN_SWD_ADDITION
    );

    const itemCountCsVru = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.COMPRESSOR_STATION_VRU);

    const satelliteBrownfieldAdderCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.SATELLITE_BROWNFIELD_ADDER
    );

    const ctbBrownfieldAdderCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.CTB_BROWNFIELD_ADDER
    );

    const swdBrownfieldAdderCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.SWD_BROWNFIELD_ADDER
    );

    const satelliteHeaderCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.SATELLITE_HEADER);

    const satelliteTestSeparatorCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.SATELLITE_TEST_SEPARATOR
    );

    const hiAccuracySatelliteMeteringUpradeCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.HI_ACCURACY_SATELLITE_METERING_UPGRADE
    );

    const satellitePumpingSkidCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.SATELLITE_PIMPING_SKID
    );

    const swdInjectionHPumpCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.SWD_INJECTION_H_PUMP_AT_STATION
    );

    const ctbHeaderCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_HEADER);

    const ctbTestSeparatorCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_TEST_SEPARATOR);

    const ctbTankCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_TANK);

    const ctbVruCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_VRU);

    const ctbFlashGasCompressorCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR
    );

    const ctbLactCount = this.getInputItemCount(inputItemsData, padName, keyFacilityItem.CTB_LACT);

    const ctbWaterTransferPumpCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.CTB_WATER_TRANSFER_PUMP
    );

    const hiAccuracyCtbMeteringUpgradeCount = this.getInputItemCount(
      inputItemsData,
      padName,
      keyFacilityItem.HI_ACCURACY_CTB_METERING_UPGRADE
    );

    //Below line shown as major bug in sonarcube. Expected an assignment or function call and instead saw an expression.
    // if (!itemCostPerUnit) {
    //   itemCostPerUnit;
    // }

    switch (itemName) {
      case keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL:
        const greenfieldCtbInstallItem = inputItemsData.find(
          inputItem => inputItem.name === keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL
        );
        const greenfieldCtbInstallItemDetail = greenfieldCtbInstallItem ? greenfieldCtbInstallItem.itemDetail : null;
        if (greenfieldCtbInstallItemDetail != null) {
          if (
            greenfieldCtbInstallItemDetail.id ===
            keyFacilityItemDetail.GREENFIELD_1_TRAIN_CTB_INSTALL_20_DESIGN.toLowerCase()
          ) {
            if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 0) {
              const mappedCost = mappingCostSft.find(
                m => m.code === CndCostLogicEnum.GREENFIELD_1TRAIN_CTB_INSTALL_20_DESIGN_8TANK_1TRAIN && m.year === year
              );

              itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
            } else if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount > 0) {
              const mappedCost = mappingCostSft.find(
                m => m.code === CndCostLogicEnum.GREENFIELD_1TRAIN_CTB_INSTALL_20_DESIGN_8TANK_2TRAIN && m.year === year
              );

              if (year >= 2023) {
                const greenfieldCost = this.getInputItemCost(
                  inputItemsData,
                  keyFacilityItem.GREENFIELD_1_TRAIN_CTB_INSTALL,
                  itemId
                );
                const trainAdditionCost = this.getInputItemCost(inputItemsData, keyFacilityItem.TRAIN_1_CTB_ADDITION);

                itemCostPerUnit = greenfieldCost + trainAdditionCost;
              }

              itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
            }
          } else {
            if (ctb1TrainAdditionCount > 0) {
              const oneTrainCostPerUnit = this.getInputItemValue(
                inputItemsData,
                keyFacilityItem.TRAIN_1_CTB_ADDITION,
                'costPunit'
              );

              itemCostPerUnit = itemCostPerUnit + oneTrainCostPerUnit;
            }
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.TRAIN_1_CTB_ADDITION:
        if (greenfield1TrainCtbInstallCount > 0 && ctb1TrainAdditionCount > 0) {
          itemCountByPad = itemCountByPad - greenfield1TrainCtbInstallCount;
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_TANK:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 8;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount === 0 || ctb1TrainAdditionCount == null)
          ) {
            itemCountByPad = itemCountByPad - 8;
          } else if (
            (greenfield1TrainCtbInstallCount === 0 || greenfield1TrainCtbInstallCount == null) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 8;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_HEADER:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 8;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount == null || ctb1TrainAdditionCount === 0)
          ) {
            itemCountByPad = itemCountByPad - 4;
          } else if (
            (greenfield1TrainCtbInstallCount === 0 || greenfield1TrainCtbInstallCount == null) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 4;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_TEST_SEPARATOR:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 6;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount === 0 || ctb1TrainAdditionCount == null)
          ) {
            itemCountByPad = itemCountByPad - 3;
          } else if (
            (greenfield1TrainCtbInstallCount === 0 || greenfield1TrainCtbInstallCount == null) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 3;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_VRU:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 4;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount === 0 || ctb1TrainAdditionCount == null)
          ) {
            itemCountByPad = itemCountByPad - 2;
          } else if (
            (greenfield1TrainCtbInstallCount === 0 || greenfield1TrainCtbInstallCount == null) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 2;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_FLASH_GAS_COMPRESSOR:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 2;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount === 0 || ctb1TrainAdditionCount == null)
          ) {
            itemCountByPad = itemCountByPad - 1;
          } else if (
            (greenfield1TrainCtbInstallCount === 0 || greenfield1TrainCtbInstallCount == null) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 1;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_LACT:
        if (greenfield1TrainCtbInstallCount === 0 && ctb1TrainAdditionCount === 0 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad + 0; //this assignment shown error in sonarqube. Hence added + 0
        } else if (greenfield1TrainCtbInstallCount > 0 || (ctb1TrainAdditionCount > 0 && itemCountByPad > 0)) {
          if (greenfield1TrainCtbInstallCount === 1 && ctb1TrainAdditionCount === 1) {
            itemCountByPad = itemCountByPad - 4;
          } else if (
            greenfield1TrainCtbInstallCount === 1 &&
            (ctb1TrainAdditionCount === 0 || ctb1TrainAdditionCount == null)
          ) {
            itemCountByPad = itemCountByPad - 2;
          } else if (
            (greenfield1TrainCtbInstallCount == null || greenfield1TrainCtbInstallCount === 0) &&
            ctb1TrainAdditionCount === 1
          ) {
            itemCountByPad = itemCountByPad - 2;
          }
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.CTB_WATER_TRANSFER_PUMP:
        if (greenfield1TrainCtbInstallCount === 1 && itemCountByPad > 0) {
          itemCountByPad = itemCountByPad - 1;
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.COMPRESSOR_STATION_VRU:
        if (greenfieldCompressorStationInstallCount != null && itemCountCsVru != null) {
          itemCountByPad = itemCountCsVru - greenfieldCompressorStationInstallCount * 2;
          cost = itemCountByPad * itemCostPerUnit;
        }
        break;
      case keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL:
        if (greenfield2TrainSWDInstallCount === 1 && twoTrainSwdAdditionCount === 0) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.GREENFIELD_2TRAIN_SWD_INSTALL && m.year === year
          );
          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
        } else if (greenfield2TrainSWDInstallCount === 1 && twoTrainSwdAdditionCount === 1) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.GREENFIELD_4TRAIN_SWD_INSTALL && m.year === year
          );
          if (year >= 2023) {
            const greenfieldSWDCost = this.getInputItemCost(
              inputItemsData,
              keyFacilityItem.GREENFIELD_2_TRAIN_SWD_INSTALL,
              itemId
            );
            const twoTrainAdditionCost = this.getInputItemCost(inputItemsData, keyFacilityItem.TWO_TRAIN_SWD_ADDITION);

            itemCostPerUnit = greenfieldSWDCost + twoTrainAdditionCost;
          }

          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
        }

        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.ONE_TRAIN_SWD_ADDITION:
        if (oneTrainSwdAdditionCount === 1) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.TRAIN1_SWD_ADDITION && m.year === year
          );
          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
        } else {
          itemCostPerUnit = 0;
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.TWO_TRAIN_SWD_ADDITION:
        if (itemCountByPad >= greenfield2TrainSWDInstallCount) {
          itemCountByPad = itemCountByPad - greenfield2TrainSWDInstallCount * 1;
        }
        cost = itemCountByPad * itemCostPerUnit;
        break;
      case keyFacilityItem.SATELLITE_BROWNFIELD_ADDER:
        if (satelliteBrownfieldAdderCount === 1) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.SATELLITE_BROWNFIELD_ADDER && m.year === year
          );

          const totalSatelliteCount =
            satelliteHeaderCount +
            satelliteTestSeparatorCount +
            hiAccuracySatelliteMeteringUpradeCount +
            satellitePumpingSkidCount;

          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;

          cost = itemCountByPad * itemCostPerUnit * 0.09 * totalSatelliteCount;
        }
        break;
      case keyFacilityItem.SWD_BROWNFIELD_ADDER:
        if (swdBrownfieldAdderCount === 1) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.SWD_BROWNFIELD_ADDER && m.year === year
          );

          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
          itemCostPerUnit = itemCostPerUnit * 0.09 * swdInjectionHPumpCount;
          cost = itemCountByPad * itemCostPerUnit;
        }
        break;
      case keyFacilityItem.CTB_BROWNFIELD_ADDER:
        if (ctbBrownfieldAdderCount === 1) {
          const mappedCost = mappingCostSft.find(
            m => m.code === CndCostLogicEnum.CTB_BROWNFIELD_ADDER && m.year === year
          );

          const totalCTBEquipmentCount =
            ctbHeaderCount +
            ctbTestSeparatorCount +
            ctbTankCount +
            ctbVruCount +
            ctbFlashGasCompressorCount +
            ctbLactCount +
            ctbWaterTransferPumpCount +
            hiAccuracyCtbMeteringUpgradeCount;

          itemCostPerUnit = mappedCost ? mappedCost.cost : itemCostPerUnit;
          itemCostPerUnit = itemCostPerUnit * 0.09 * totalCTBEquipmentCount;

          cost = itemCountByPad * itemCostPerUnit;
        }
        break;
      default:
        cost = itemCountByPad * itemCostPerUnit;
        break;
    }

    return cost;
  }

  public updateAllItemIsVisible(
    inputItemsData: any[], isVisible? : boolean 
  ): void {
    if (inputItemsData !== null) {
      inputItemsData.forEach(item => {
        if (
          item.respToExpandFacilityItemId !== undefined &&
          item.respToExpandFacilityItemId !== null &&
          item.respToExpandFacilityItemId !== Guid.EMPTY
        ) {
          if(isVisible !== undefined)
            item.isVisible = isVisible ? 1 : 0;           
        }
        else
        {
          if(isVisible !== undefined)
            item.isExpanded = isVisible;  
        }
      });  
    }
  }
}
