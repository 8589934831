<div class="card-body">
  <div class="row">
    <p class="k-block">
      <button kendoButton (click)="clickExportAll()" class="k-button" style="margin-right: 5px;">Export All Scenarios</button>
      <button kendoButton (click)="clickExportSelected()" class="k-button" style="margin-right: 5px;" [disabled]="scenariosSelected.length <= 0">Export Selected Scenarios</button>
    </p>
  </div>

  <div class="row">
    <kendo-grid [data]="gridData" [filter]="state.filter" [filterable]="true"
                (dataStateChange)="onDataStateChange($event)"
                [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single'}"
                [sort]="sort"
                [loading]="isLoading"
                (sortChange)="sortChange($event)" style="height: auto; overflow-x: hidden;"
                accesskey="true" [selectable]="true" [kendoGridSelectBy]="scenarioSelectedKey" [(selectedKeys)]="scenariosSelected">
      <kendo-grid-checkbox-column [width]="50"></kendo-grid-checkbox-column>
      <kendo-grid-column field="name" title="Scenario Name"></kendo-grid-column>
      <kendo-grid-column field="developmentAreaName" title="Development Area"></kendo-grid-column>
      <kendo-grid-column field="scenarioTypeName" title="Scenario Type"></kendo-grid-column>
      <kendo-grid-column field="isActiveForOutlook" title="Active for Outlook?"></kendo-grid-column>
      <kendo-grid-column field="updatedDate" title="Updated Date" filter="date" format="{0:d}"></kendo-grid-column>
      <kendo-grid-column field="updatedBy" title="Updated By"></kendo-grid-column>
    </kendo-grid>
  </div>
  <ng-template #notiftemplate></ng-template>
</div>

<kendo-dialog *ngIf="openScenariosWithAutoPopDialog" (close)="closeExistingPadsDialog()" [width]="600">
  <kendo-dialog-titlebar>
    <div class="my-k-window-title">
      Exported Report Results
    </div>
  </kendo-dialog-titlebar>  
  <div class="card-body-smart-link">
    <div class="row no-gutters">
      <div class="form-group row">
        <div class="col-sm-12">
          <span style="font-size:14px">
            The following Scenarios contains dates with autopopulated information:
          </span>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <ul>
            <li *ngFor="let scenario of scenarioReportResultList">
              {{scenario}}
            </li>
          </ul>
        </div>
      </div>      
    </div>
  </div>
  <kendo-dialog-actions>
    <button class="k-button" (click)="closeExistingPadsDialog()">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>

