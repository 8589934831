import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcFormControlComponent } from './fit-cc-form-control.component';
import { Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FitCCInputModule } from '../fit-cc-input/fit-cc-input.module';
import { FloatingLabelModule } from '@progress/kendo-angular-label';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FloatingLabelModule,
    FormsModule,
    DialogModule,
    InputsModule,
    DropDownsModule,
    DatePickerModule,
    PopupModule,
    FitCCInputModule,
  ],
  declarations: [FitCcFormControlComponent],
  exports: [FitCcFormControlComponent],
})
export class FitCcFormControlModule {}
