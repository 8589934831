<div class="container">
  <div class="form-group row">
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="signPerformanceProjectManager()"
        [disabled]="itHasPerformanceSign()"
        [hidden]="hiddenPerformancePM"
      >
        Performance PM - Click to Sign BOD
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="signPerformanceReservoirEngineer()"
        [disabled]="itHasReservoirEngineer()"
        [hidden]="hiddenReservoirEngineer"
      >
        Reservoir Engineer - Click to Sign BOD
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="signPerformanceTeamLead()"
        [disabled]="itHasTeamLead()"
        [hidden]="hiddenPerformanceTL"
      >
        Performance TL - Click to Sign BOD
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="signWaterEngineer()"
        [disabled]="itHasWaterEngineer()"
        [hidden]="hiddenWaterEngineer"
      >
        Water Engineer - Click to Sign BOD
      </button>
    </div>
  </div>
  <br />
  <!--<div class="form-group row">
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="showConfirmation('projectManager')"
        [disabled]="!canEditPerformanceBod"
        [hidden]="hiddenRecallPerformancePM"
      >
        Performance PM -Recall signature
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="showConfirmation('reservoirEngineer')"
        [disabled]="!canEditPerformanceBod"
        [hidden]="hiddenRecallReservoirEngineer"
      >
        Reservoir Engineer - Recall signature
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="showConfirmation('teamLead')"
        [disabled]="!canEditPerformanceBod"
        [hidden]="hiddenRecallPerformanceTL"
      >
        Performance TL - Recall signature
      </button>
    </div>
    <div class="col-sm-3">
      <button
        kendoButton
        [primary]="true"
        (click)="showConfirmation('waterEngineer')"
        [disabled]="!canEditPerformanceBod"
        [hidden]="hiddenRecallWaterEngineer"
      >
        Water Engineer - Recall signature
      </button>
    </div>
  </div>-->
</div>
