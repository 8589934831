import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { IPAndEProjectBodStatus } from '../_models/pAndE-project-bod-status';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PAndEProjectBodStatusService {
  public baseUrlPAndEProjectBodStatus: string = environment.PacerDomainURL + 'PAndEProjectBodStatus';
  public pAndEProjectBodStatus$: Observable<IPAndEProjectBodStatus[]>;
  public changeStatus$: Observable<boolean>;
  private pAndEProjectStatusValue$: BehaviorSubject<IPAndEProjectBodStatus[]>;
  private changeStatusValue$: BehaviorSubject<boolean>;
  private handleError: HandleError;
  private pAndEProjectBodId: string;
  constructor(private http: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.pAndEProjectStatusValue$ = new BehaviorSubject<IPAndEProjectBodStatus[]>([]);
    this.changeStatusValue$ = new BehaviorSubject<boolean>(false);
    this.pAndEProjectBodStatus$ = this.pAndEProjectStatusValue$.asObservable();
    this.changeStatus$ = this.changeStatusValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
  }

  public initialize(pAndEProjectBodId: string) {
    this.pAndEProjectBodId = pAndEProjectBodId;
    this.getSftBodStatusByFacilityBoId(this.pAndEProjectBodId).subscribe(
      (sftBodStatus: IPAndEProjectBodStatus[]) => {
        this.pAndEProjectStatusValue$.next(sftBodStatus);
      },
      () => {
        this.pAndEProjectStatusValue$.error('Error loading data: could not be able to load P & E bod status.');
      }
    );
  }

  public updateStatus(bodStatus: IPAndEProjectBodStatus[]): void {
    this.create(bodStatus).subscribe((sftBodStatus: IPAndEProjectBodStatus[]) => {
      this.initialize(this.pAndEProjectBodId);
      this.nextChangeStatus(true);
    });
  }
  public setFacilityBodValue(value: any) {
    this.pAndEProjectStatusValue$.next(value);
  }

  public nextChangeStatus(change: boolean): void {
    this.changeStatusValue$.next(change);
  }

  public cleanObservables(): void {
    this.pAndEProjectStatusValue$.next([]);
  }

  public createForSubcribe(created: IPAndEProjectBodStatus[]): Observable<any> {
    return this.create(created);
  }

  private getSftBodStatusByFacilityBoId(pAndEProjectBodId: string): Observable<IPAndEProjectBodStatus[]> {
    return this.http
      .get<IPAndEProjectBodStatus[]>(
        `${this.baseUrlPAndEProjectBodStatus}/ListByPAndEProjectBodId/${pAndEProjectBodId}`
      )
      .pipe(catchError(this.handleError('getFacilityBodById')));
  }

  private create(created: IPAndEProjectBodStatus[]): Observable<any> {
    const toCreate = created.map((item: IPAndEProjectBodStatus) => {
      return {
        pAndEProjectBodId: item.pAndEProjectBodId,
        statusIndicatorId: item.statusIndicatorId,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.http
      .post<IPAndEProjectBodStatus[]>(`${this.baseUrlPAndEProjectBodStatus}/Create`, toCreate, httpOptions)
      .pipe(
        map((returnValue: any) => {
          if (returnValue.exceptions && returnValue.exceptions.length > 0) {
            this.pAndEProjectStatusValue$.error('Error : An error occurred while creating the entries.');
          }
          return returnValue;
        }),
        catchError(this.handleError('Create'))
      );
  }
}
