import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IFitCCDropDown } from './fit-cc-idrop-dowm';

@Injectable({
  providedIn: 'root',
})
export class FitCcUtilities {
  constructor(private notificationService: NotificationService) {}
  public getName(id: string, options: IFitCCDropDown[]) {
    if (id == null || options == null) {
      return null;
    }
    const obj = options.find(x => x.value === id);
    return obj;
  }

  public getActiveItems(options: any[]) {
    return options.filter(o => o.isActive);
  }

  showWarningNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'warning', icon: true },
    });
  }

  showSuccessNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
    });
  }

  showErrorNotification(message: string) {
    this.notificationService.show({
      content: `${message}`,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true },
    });
  }

  public dateFormatYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  public getColumnsFromJson(json: any) {
    var columns = [];
    for (var key in json) {
      let column = {
        field: key,
        title: key,
        type: 'text',
        width: '80',
      };
      columns.push(column);
    }

    return columns;
  }

  public getPersistedSettings<T>(token: string): T {
    let settings: any = localStorage.getItem(token);
    let settingsObject: any;
    if (settings) {
      settingsObject = Object.assign({}, JSON.parse(settings));
      if (settingsObject && settingsObject.state.filter) {
        this.replaceFilterOperator(settingsObject.state.filter);
      }
    }
    return settingsObject;
  }

  public setPersistedSettings<T>(token: string, config): void {
    let settings = Object.assign({}, config);
    if (settings.state.filter !== undefined) {
      this.replaceFilterOperator(settings.state.filter);
    }
    localStorage.setItem(token, JSON.stringify(settings));
  }

  public replaceFilterOperator(obj, onlyString?: boolean) {
    if (obj.filters) {
      this.replaceFilterOperator(obj.filters, onlyString);
    }
    if (Array.isArray(obj)) {
      for (const f of obj) {
        if (f.filters) {
          this.replaceFilterOperator(f.filters, onlyString);
        } else if (f.operator) {
          this.replaceFilterOperator(f, onlyString);
        }
      }
    }

    if (onlyString) {
      if (obj.operator && typeof obj.operator === 'string' && obj.operator.includes('=> {')) {
        obj.operator = JSON.parse('(' + obj.operator + ')');
      }
    } else {
      if (obj.operator && typeof obj.operator === 'function') {
        obj.operator = obj.operator.toString();
      } else if (obj.operator && typeof obj.operator === 'string' && obj.operator.includes('=> {')) {
        obj.operator = JSON.parse('(' + obj.operator + ')');
      }
    }
  }

  public isOperatorFunctionString(obj) {
    if (obj.filters) {
      return this.isOperatorFunctionString(obj.filters);
    }
    if (Array.isArray(obj)) {
      let arr = [];
      for (const f of obj) {
        if (f.filters) {
          arr.push(this.isOperatorFunctionString(f.filters));
        } else if (f.operator) {
          arr.push(this.isOperatorFunctionString(f));
        }
      }
      return arr.includes(true) ? true : false;
    }
    if (obj.operator && typeof obj.operator === 'string' && obj.operator.includes('=> {')) {
      return true;
    } else {
      return false;
    }
  }

  public mapDateFilter = (descriptor: any, columnField: any) => {
    const filters = descriptor.filters || [];

    filters.forEach(filter => {
      if (filter.filters) {
        this.mapDateFilter(filter, columnField);
      } else if (filter.field === columnField && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };
}
