import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { environment } from '../../../../environments/environment';
import { process, State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { IViewPricetScenario } from '../../../api/viewPricetScenario';
import { IReportOut } from '../../../api/reportout';
import 'src/app/_shared/extends';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
})
export class ExportDataComponent implements OnInit {
  @ViewChild('notiftemplate', { read: TemplateRef })
  public notificationTemplate: TemplateRef<any>;
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc',
    },
  ];
  public scenarios: IViewPricetScenario[];
  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public fileUrl: string;
  public scenariosSelected: string[] = [];
  public openScenariosWithAutoPopDialog = false;
  public scenarioReportResultList: string[] = [];
  public isLoading = true;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService) { 
      // empty
    }

  public scenarioSelectedKey(context: RowArgs): string {
    return context.dataItem.id;
  }

  public ngOnInit(): void {
    this.dataService.getScenarios().subscribe(
      data => {
        this.scenarios = data;
        this.gridData = process(this.scenarios, this.state);
        this.scenariosSelected = [];
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.notificationService.show({
          content: 'Error opening all Scenarios',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'info', icon: true },
        });
      }
    );
  }

  public onDataStateChange(state: any): void {
    this.state = state;
    this.gridData = process(this.scenarios, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridData = {
      data: orderBy(this.scenarios, this.sort),
      total: this.scenarios.length,
    };
  }

  public clickExportAll(): void {
    this.notificationService.show({
      content: 'Exporting All Scenarios, this may take a few minutes...',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 800 },
      type: { style: 'info', icon: true },
    });

    this.dataService.getScenarios().subscribe(
      data => {
        window.open(environment.PricetDomainURL + 'Scenario/Download/00000000-0000-0000-0000-000000000000', '_blank');
      },
      () => {
        this.notificationService.show({
          content: 'There was an issue generating Export Data document All scenarios. Please try again later.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'error', icon: true },
        });
      }
    );
  }

  public clickExportSelected(): void {
    if (this.scenariosSelected !== undefined && this.scenariosSelected !== null) {
      if (this.scenariosSelected.length > 0) {
        this.spinner.show();
        let notification = 
        this.createNotificationInfo('Processing info for selected scenarios...');
        this.dataService.getReportOutData(this.scenariosSelected).toPromise().then(
          (reportResults: IReportOut[]) => {
            notification.hide();
            notification = this.createNotificationInfo('Generating export file...');
            const res =
            reportResults
            .filter(d => d.hasAutoPopulatedDates.toLowerCase() === 'Yes'.toLowerCase());
            
            this.scenarioReportResultList =
            res.distinct(d => d.scenarioName).map(item => item.scenarioName);

            this.dataService.generateReporByModel(reportResults).toPromise()
            .then(excelFile => {
              notification.hide();
              this.createExcel(excelFile);
              if (this.scenarioReportResultList.length > 0) {
                this.openScenariosWithAutoPopDialog = true;
              }              
            }).catch(error => {
              notification.hide();
              this.createNotificationError('There was an issue generating Export Data document, selected scenarios. ' + 
              'Please try again later.')
            });            
          }
        ).catch(error => {
          notification.hide();
          this.createNotificationError('There was an issue generating Export Data document, selected scenarios. ' + 
          'Please try again later.');
          });

        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    }
  }

  public closeExistingPadsDialog(): void {
    this.openScenariosWithAutoPopDialog = false;
    this.scenarioReportResultList = [];
  }

  public createExcel(file: Blob): void {
    if (file) {
      const now = new Date();
      const vfileName =
      `ReportOut_${now.getFullYear()}${now.getMonth()}${now.getDay()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}.xlsx`;
      const e = document.createElement('a');
      e.href = window.URL.createObjectURL(file);
      e.download = vfileName;
      e.style.display = 'none';
      document.body.appendChild(e);
      e.click();
      document.body.removeChild(e);
    }    
  }

  public createNotificationError(message: string): void {
    this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'error', icon: true },
    });
  }

  public createNotificationInfo(message: string): NotificationRef {
    return this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'info', icon: true },
      closable: true,
    });
  }  
}
