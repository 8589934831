import { EventEmitter, Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, concat, of, throwError } from 'rxjs';
import clonedeep from 'lodash.clonedeep';
import { InfrastructureRecycle } from '../api/infrastructure-recycle';
import { InfrastructureSourceWater } from '../api/infrastructure-source-water';
import { IPerformanceBod } from '../api/performance-bod';
import { IPerformanceBodCentralTankBattery } from '../api/performance-bod-central-tank-battery';
import { IPerformanceBodCompressor } from '../api/performance-bod-compressor';
import { IPerformanceBodEdsIe } from '../api/performance-bod-eds-ie';
import { IPerformanceBodPipelines } from '../api/performance-bod-pipelines';
import { IPerformanceBodFacilityPad } from '../api/performance-bod-facility-pad';
import { IPerformanceBodFracPond } from '../api/performance-bod-frac-pond';
import { IPerformanceBodPackages } from '../api/performance-bod-package';
import { IPerformanceBodSaltWaterDisposal } from '../api/performance-bod-salt-water-disposal';
import { IPerformanceBodWellPad } from '../api/performance-bod-well-pad';
import { PerformanceBodDataService } from './performance-bod-data.service';
import { IPerformanceBodWaterDisposal } from '../api/performance-bod-water-disposal';
import { IPerformanceExpectedPeakProductionRates } from '../api/performance-expected-peak-production-rates';
import { IPerformanceBodSummary } from '../api/performance-bod-summary';
import { IPerformanceBodPads } from '../api/performance-bod-pads';
import { InfrastructureCommercial } from '../api/infrastructure-commercial';
import { InfrastructureRegulatoryPermitting } from '../api/infrastructure-regulatory-permitting';
import { take, catchError } from 'rxjs/operators';
import { IPerformanceBodWellArtificialLift } from '../api/performance-bod-well-artificial-lift';
import { IScopeNonStandardOutsideFactoryProjects } from '../api/scope-non-standard-outside-factory-projects';
import * as Helpers from '../_shared/helpers';
import { IPerformanceBodExistingFacilities } from '../api/performance-bod-existing-facilities';
import { IPerformanceBodSignatures } from '../api/performance-bod-signatures';
import { IPerformanceBodNotes } from '../api/performance-bod-notes';
import { IPerformanceBodNotesAttachment } from '../api/performance-bod-notes-attachment';
import { IStatusIndicator } from '../api/status-indicator';
import { IPerformanceBodStatus } from '../api/performance-bod-status';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodStoreService {
  // Declaration general
  public errorInitPerformanceBod: boolean;
  public errorOpenPerformanceBod: any = {};
  public errorCreatePerformanceBod: any = {};
  public completed: EventEmitter<any>;
  public refreshed: EventEmitter<any>;
  public refreshingBodDetails: EventEmitter<any>;
  public onLoadArtificialLift: EventEmitter<any>;

  //#region "Declaration of Observable(s)"
  // Declaration of Observable(s)
  public newPerformanceBOD$: Observable<boolean>;
  public version$: Observable<string>;
  public performanceBodId$: Observable<string>;
  public performanceBodSignaturesData$: Observable<IPerformanceBodSignatures[]>;
  public performanceBodPackagesData$: Observable<IPerformanceBodPackages[]>;
  public performanceBodCentralTankBattery$: Observable<IPerformanceBodCentralTankBattery[]>;
  public performanceExpectedPeakProductionRatesData$: Observable<IPerformanceExpectedPeakProductionRates[]>;
  public performanceBodCompressor$: Observable<IPerformanceBodCompressor[]>;
  public performanceBodSaltWaterDisposal$: Observable<IPerformanceBodSaltWaterDisposal[]>;
  public performanceBodFacilityPadData$: Observable<IPerformanceBodFacilityPad[]>;
  public orientationData$: Observable<string[]>;
  public performanceBodsAll$: Observable<IPerformanceBod[]>;
  public performanceBodWellPad$: Observable<IPerformanceBodWellPad[]>;
  public infrastructureSourceWater$: Observable<InfrastructureSourceWater[]>;
  public infrastructureRecycle$: Observable<InfrastructureRecycle[]>;
  public performanceBodFracPond$: Observable<IPerformanceBodFracPond[]>;
  public performanceBodEdsIe$: Observable<IPerformanceBodEdsIe[]>;
  public performanceBodWaterDisposal$: Observable<IPerformanceBodWaterDisposal[]>;
  public performanceBod$: Observable<IPerformanceBod>;
  public performanceBodSummary$: Observable<IPerformanceBodSummary[]>;
  public performanceBodPipelinesData$: Observable<IPerformanceBodPipelines[]>;
  public infrastructureCommercial$: Observable<InfrastructureCommercial[]>;
  public infrastructureRegulatoryPermitting$: Observable<InfrastructureRegulatoryPermitting[]>;

  public performanceBodWellArtificialLiftData$: Observable<IPerformanceBodWellArtificialLift[]>;
  public performanceBodPadsData$: Observable<IPerformanceBodPads[]>;
  public scopeNonStandardOutsideFactoryProjects$: Observable<IScopeNonStandardOutsideFactoryProjects[]>;
  public performanceBodExistingFacilitiesData$: Observable<IPerformanceBodExistingFacilities[]>;
  public performanceBodNotesData$: Observable<IPerformanceBodNotes[]>;
  public performanceBodNotesAttachmentData$: Observable<IPerformanceBodNotesAttachment[]>;
  public statusIndicator$: Observable<IStatusIndicator[]>;
  public performanceBodStatus$: Observable<IPerformanceBodStatus[]>;
  public performanceBodSignatured$: Observable<boolean>;

  //#endregion "Declaration of Observable(s)"

  //#region "Declaration of BehaviorSubject(s)"
  // Declaration of BehaviorSubject(s)
  public _newPerformanceBOD$: BehaviorSubject<boolean>;
  public _version$: BehaviorSubject<string>;
  public _performanceBodId$: BehaviorSubject<string>;
  private _performanceBodSignaturesData$: BehaviorSubject<IPerformanceBodSignatures[]>;
  private _performanceBodPackagesData$: BehaviorSubject<IPerformanceBodPackages[]>;
  private _performanceBodCentralTankBattery$: BehaviorSubject<IPerformanceBodCentralTankBattery[]>;
  private _performanceExpectedPeakProductionRatesData$: BehaviorSubject<IPerformanceExpectedPeakProductionRates[]>;
  private _performanceBodCompressor$: BehaviorSubject<IPerformanceBodCompressor[]>;
  private _performanceBodSaltWaterDisposal$: BehaviorSubject<IPerformanceBodSaltWaterDisposal[]>;
  private _performanceBodFacilityPadData$: BehaviorSubject<IPerformanceBodFacilityPad[]>;
  private _orientationData$: BehaviorSubject<string[]>;
  public _performanceBodsAll$: BehaviorSubject<IPerformanceBod[]>;
  private _performanceBodWellPad$: BehaviorSubject<IPerformanceBodWellPad[]>;
  private _infrastructureSourceWater$: BehaviorSubject<InfrastructureSourceWater[]>;
  private _infrastructureRecycle$: BehaviorSubject<InfrastructureRecycle[]>;
  private _performanceBodWaterDisposal$: BehaviorSubject<IPerformanceBodWaterDisposal[]>;
  private _performanceBodFracPond$: BehaviorSubject<IPerformanceBodFracPond[]>;
  public _performanceBodEdsIe$: BehaviorSubject<IPerformanceBodEdsIe[]>;
  private _performanceBod$: BehaviorSubject<IPerformanceBod>;
  public _performanceBodSummary$: BehaviorSubject<IPerformanceBodSummary[]>;
  private _performanceBodPipelinesData$: BehaviorSubject<IPerformanceBodPipelines[]>;
  private _infrastructureCommercial$: BehaviorSubject<InfrastructureCommercial[]>;
  private _infrastructureRegulatoryPermitting$: BehaviorSubject<InfrastructureRegulatoryPermitting[]>;

  private _performanceBodWellArtificialLiftData$: BehaviorSubject<IPerformanceBodWellArtificialLift[]>;
  private _performanceBodPadsData$: BehaviorSubject<IPerformanceBodPads[]>;
  private _scopeNonStandardOutsideFactoryProjects$: BehaviorSubject<IScopeNonStandardOutsideFactoryProjects[]>;
  private _performanceBodExistingFacilitiesData$: BehaviorSubject<IPerformanceBodExistingFacilities[]>;
  private _performanceBodNotesData$: BehaviorSubject<IPerformanceBodNotes[]>;
  private _performanceBodNotesAttachmentData$: BehaviorSubject<IPerformanceBodNotesAttachment[]>;
  private _statusIndicator$: BehaviorSubject<IStatusIndicator[]>;
  private _performanceBodStatus$: BehaviorSubject<IPerformanceBodStatus[]>;
  public _canEditPerformance$: BehaviorSubject<boolean>;
  public _performanceBodSignatured$: BehaviorSubject<boolean>;

  //#endregion "Declaration of BehaviorSubject(s)"

  constructor(private performanceBodDataService: PerformanceBodDataService) {
    this.createInstances();
  }

  public createInstances() {
    this.errorInitPerformanceBod = false;
    this._performanceBodId$ = new BehaviorSubject<string>('');
    this._performanceBodSignaturesData$ = new BehaviorSubject([]);
    this._performanceBodPackagesData$ = new BehaviorSubject([]);
    this._performanceBodCentralTankBattery$ = new BehaviorSubject([]);
    this._performanceExpectedPeakProductionRatesData$ = new BehaviorSubject([]);
    this._performanceBodCompressor$ = new BehaviorSubject([]);
    this._performanceBodSaltWaterDisposal$ = new BehaviorSubject([]);
    this._performanceBodsAll$ = new BehaviorSubject([]);
    this._infrastructureSourceWater$ = new BehaviorSubject([]);
    this._infrastructureRecycle$ = new BehaviorSubject([]);
    this._performanceBodFracPond$ = new BehaviorSubject([]);
    this._performanceBodEdsIe$ = new BehaviorSubject([]);
    this._performanceBodSummary$ = new BehaviorSubject([]);
    this._infrastructureCommercial$ = new BehaviorSubject([]);
    this._infrastructureRegulatoryPermitting$ = new BehaviorSubject([]);
    this._performanceBodPadsData$ = new BehaviorSubject([]);
    this._newPerformanceBOD$ = new BehaviorSubject<boolean>(false);
    this._performanceBodWellPad$ = new BehaviorSubject([]);
    this._performanceBodFacilityPadData$ = new BehaviorSubject([]);
    this._orientationData$ = new BehaviorSubject(['North', 'South', 'East', 'West']);
    this._performanceBodWaterDisposal$ = new BehaviorSubject([]);
    this._performanceBodPipelinesData$ = new BehaviorSubject([]);
    this._performanceBodWellArtificialLiftData$ = new BehaviorSubject([]);
    this._scopeNonStandardOutsideFactoryProjects$ = new BehaviorSubject([]);
    this._performanceBodExistingFacilitiesData$ = new BehaviorSubject([]);
    this._performanceBod$ = new BehaviorSubject<IPerformanceBod>(null);
    this._performanceBodNotesData$ = new BehaviorSubject([]);
    this._performanceBodNotesAttachmentData$ = new BehaviorSubject([]);
    this._canEditPerformance$ = new BehaviorSubject<boolean>(false);
    this._statusIndicator$ = new BehaviorSubject([]);
    this._performanceBodStatus$ = new BehaviorSubject([]);
    this._performanceBodSignatured$ = new BehaviorSubject<boolean>(false);

    this.newPerformanceBOD$ = this._newPerformanceBOD$.asObservable();
    this.performanceBodId$ = this._performanceBodId$.asObservable();
    this.performanceBodSignaturesData$ = this._performanceBodSignaturesData$.asObservable();
    this.performanceBodPackagesData$ = this._performanceBodPackagesData$.asObservable();
    this.performanceBodCentralTankBattery$ = this._performanceBodCentralTankBattery$.asObservable();
    this.performanceBodCompressor$ = this._performanceBodCompressor$.asObservable();
    this.performanceBodSaltWaterDisposal$ = this._performanceBodSaltWaterDisposal$.asObservable();
    this.performanceExpectedPeakProductionRatesData$ = this._performanceExpectedPeakProductionRatesData$.asObservable();
    this.performanceBodsAll$ = this._performanceBodsAll$.asObservable();
    this.performanceBodEdsIe$ = this._performanceBodEdsIe$.asObservable();
    this.performanceBodWellPad$ = this._performanceBodWellPad$.asObservable();
    this.performanceBodFacilityPadData$ = this._performanceBodFacilityPadData$.asObservable();
    this.orientationData$ = this._orientationData$.asObservable();
    this.infrastructureSourceWater$ = this._infrastructureSourceWater$.asObservable();
    this.infrastructureRecycle$ = this._infrastructureRecycle$.asObservable();
    this.performanceBodFracPond$ = this._performanceBodFracPond$.asObservable();
    this.performanceBodWaterDisposal$ = this._performanceBodWaterDisposal$.asObservable();
    this.performanceBod$ = this._performanceBod$.asObservable();
    this.performanceBodSummary$ = this._performanceBodSummary$.asObservable();
    this.performanceBodPipelinesData$ = this._performanceBodPipelinesData$.asObservable();
    this.infrastructureCommercial$ = this._infrastructureCommercial$.asObservable();
    this.infrastructureRegulatoryPermitting$ = this._infrastructureRegulatoryPermitting$.asObservable();
    this.performanceBodWellArtificialLiftData$ = this._performanceBodWellArtificialLiftData$.asObservable();
    this.performanceBodPadsData$ = this._performanceBodPadsData$.asObservable();
    this.scopeNonStandardOutsideFactoryProjects$ = this._scopeNonStandardOutsideFactoryProjects$.asObservable();
    this.performanceBodExistingFacilitiesData$ = this._performanceBodExistingFacilitiesData$.asObservable();
    this.performanceBodNotesData$ = this._performanceBodNotesData$.asObservable();;
    this.performanceBodNotesAttachmentData$ = this._performanceBodNotesAttachmentData$.asObservable();
    this.statusIndicator$ = this._statusIndicator$.asObservable();
    this.performanceBodStatus$ = this._performanceBodStatus$.asObservable();
    this.performanceBodSignatured$ = this._performanceBodSignatured$.asObservable();
  }

  public cleanPeformanceBodObservables(): void {
    this._performanceBod$.complete();
    this._performanceBodPackagesData$.complete();
    this._performanceBodSignaturesData$.complete();
    this._performanceBodCentralTankBattery$.complete();
    this._performanceBodCompressor$.complete();
    this._performanceBodSaltWaterDisposal$.complete();
    this._performanceExpectedPeakProductionRatesData$.complete();
    this._performanceBodsAll$.complete();
    this._performanceBodEdsIe$.complete();
    this._performanceBodWellPad$.complete();
    this._performanceBodFacilityPadData$.complete();
    this._orientationData$.complete();
    this._infrastructureSourceWater$.complete();
    this._infrastructureRecycle$.complete();
    this._performanceBodFracPond$.complete();
    this._performanceBodWaterDisposal$.complete();
    this._performanceBodSummary$.complete();
    this._performanceBodPipelinesData$.complete();
    this._infrastructureCommercial$.complete();
    this._infrastructureRegulatoryPermitting$.complete();
    this._performanceBodWellArtificialLiftData$.complete();
    this._performanceBodPadsData$.complete();
    this._scopeNonStandardOutsideFactoryProjects$.complete();
    this._performanceBodExistingFacilitiesData$.complete();
    this._performanceBodNotesData$.complete();
    this._performanceBodNotesAttachmentData$.complete();
    this._statusIndicator$.complete();
    this._performanceBodStatus$.complete();
    this._newPerformanceBOD$.complete();
    this._performanceBodId$.complete();
    this._performanceBodSignatured$.complete();
    this.createInstances();
  }

  public setStartCreate(): void {
    this.completed = new EventEmitter<any>();
    this.completed.emit(false);
  }

  public setCompleteCreate(): void {
    this._performanceBodPadsData$.next([]);
    this.completed.emit(true);
    this.completed.complete();
  }

  public deletePerformanceBOD(id: string): void {
    this.performanceBodDataService.deletePerformanceBOD(id).subscribe((data: any) => {});
  }

  public update(performanceBOD: IPerformanceBod): void {
    this.completed = new EventEmitter<any>();
    this.completed.emit(false);
    this.performanceBodDataService.updatePerformanceBOD(performanceBOD).subscribe(
      (data: any) => {
        this.completed.emit(true);
        this.completed.complete();
      },
      (error: any) => {
        this.completed.error(error);
        this.completed.complete();
        this.errorCreatePerformanceBod.hasError = true;
      }
    );
  }

  public updateAsync(performanceBOD: IPerformanceBod): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBOD(performanceBOD);
  }

  public createPerformanceBoDSignatures(signatures: IPerformanceBodSignatures): Observable<any> {
    return this.performanceBodDataService.createPerformanceBODSignatures(signatures);
  }

  public updatePerformanceBodSignaturesAsync(signatures: IPerformanceBodSignatures[]): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBODSignatures(signatures);
  }

  public deletePerformanceBODSignature(signatures: IPerformanceBodSignatures[]): Observable<any> {
    return this.performanceBodDataService.deletePerformanceBODSignature(signatures);
  }

  public createPackages(packages: IPerformanceBodPackages[]): void {
    this.performanceBodDataService.createPerformanceBodPackage(packages).subscribe(
      (data2: any) => {},
      (err: any) => {
        this.errorCreatePerformanceBod.hasError = true;
      }
    );
  }

  public addPackagesAsync(packages: IPerformanceBodPackages[]): Observable<any> {
    const toAdd = packages.map((item: IPerformanceBodPackages) => {
      return {
        name: item.name,
        performanceBodId: item.performanceBodId,
        // tslint:disable-next-line: object-literal-sort-keys
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        sortOrder: item.sortOrder,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.performanceBodDataService.addPerformanceBodPackage(toAdd);
  }

  public updatePackages(packages: IPerformanceBodPackages[]): void {
    this.performanceBodDataService.updatePerformanceBodPackage(packages).subscribe(
      (data2: any) => {},
      (err: any) => {
        this.errorCreatePerformanceBod.hasError = true;
      }
    );
  }

  public updatePackagesAsync(packages: IPerformanceBodPackages[]): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodPackage(packages);
  }

  public ListAllPerformanceBOD(): void {
    this.performanceBodsAll$ = this.performanceBodDataService.getPerformanceBOD();
  }

  //#region "Open Performance BOD"

  public open(id: string): void {
    this._performanceBodId$.next(id);
    this.refreshed = new EventEmitter<any>();
    this.refreshed.emit(false);

    this.performanceBodDataService.getByIdPerformanceBod(id).toPromise()
    .then(bodData => {
      bodData.performanceProjectManagerDate = Helpers.convertUtcToLocalTime(bodData.performanceProjectManagerDate);
      bodData.performanceReservoirEngineerDate = Helpers.convertUtcToLocalTime(
        bodData.performanceReservoirEngineerDate
      );
      bodData.performanceTeamLeadDate = Helpers.convertUtcToLocalTime(bodData.performanceTeamLeadDate);
      bodData.createdDate = Helpers.convertUtcToLocalTime(bodData.createdDate);
      bodData.updatedDate = Helpers.convertUtcToLocalTime(bodData.updatedDate);

      this._performanceBod$.next(bodData);
      this.errorOpenPerformanceBod.hasError = false;
    })
    .catch((error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
    });

    this.performanceBodDataService
      .getByIdPerformanceBodSignatures(id)
      .toPromise()
      .then(signature => {
        signature.performanceProjectManagerDate = Helpers.convertUtcToLocalTime(
          signature.performanceProjectManagerDate
        );
        signature.performanceReservoirEngineerDate = Helpers.convertUtcToLocalTime(
          signature.performanceReservoirEngineerDate
        );
        signature.performanceTeamLeadDate = Helpers.convertUtcToLocalTime(signature.performanceTeamLeadDate);
        signature.createdDate = Helpers.convertUtcToLocalTime(signature.createdDate);
        signature.updatedDate = Helpers.convertUtcToLocalTime(signature.updatedDate);
        this._performanceBodSignaturesData$.next(signature);
        this.errorOpenPerformanceBod.hasError = false;
      })
      .catch((error: any) => {
          this.errorOpenPerformanceBod.hasError = true;
        });


    this.performanceBodDataService.getPerformanceBodStatus(id).toPromise()
    .then(status => {
      this._performanceBodStatus$.next(status);
      this.errorOpenPerformanceBod.hasError = false;
    })
    .catch((error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
    });

    this.performanceBodDataService.getStatusIndicator().toPromise()
    .then(status => {
      this._statusIndicator$.next(status);
      this.errorOpenPerformanceBod.hasError = false;
    })
    .catch((error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
    });

    this.performanceBodDataService.getPerformanceBodPackage(id).toPromise()
    .then(packagesData => {
      this._performanceBodPackagesData$.next(packagesData);
      this.refreshed.emit(true);
      this.refreshed.complete();
    })
    .catch(error => {
        this.refreshed.error(error);
        this.refreshed.complete();
    });

    this.performanceBodDataService.getPerformanceBodPads(id).toPromise().then(bodPads => {
      this._performanceBodPadsData$.next(bodPads);
    });

    this.performanceBodDataService.getPerformanceBodCentralTankBattery(id).subscribe(ctbData => {
      this._performanceBodCentralTankBattery$.next(ctbData);
    });

    this.performanceBodDataService.getPerformanceBodCompressor(id).subscribe(compressorData => {
      this._performanceBodCompressor$.next(compressorData);
    });

    this.performanceBodDataService.getPerformanceBodSaltWaterDisposal(id).subscribe(swdData => {
      this._performanceBodSaltWaterDisposal$.next(swdData);
    });

    this.performanceBodDataService.getPerformanceBodWellPad(id).subscribe(wellPadData => {
      wellPadData.forEach(element => {
        if (element.wellPadRfsd !== null && element.wellPadRfsd !== undefined) {
          element.wellPadRfsd = Helpers.convertUtcToLocalTime(element.wellPadRfsd);
        } else {
          element.wellPadRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodWellPad$.next(wellPadData);
    });
    this.performanceBodDataService.getPerformanceExpectedPeakProductionRates(id).subscribe(data => {
      this._performanceExpectedPeakProductionRatesData$.next(data);
    });

    this.performanceBodDataService.getInfrastructureRecycle(id).subscribe(infrastructureData => {
      infrastructureData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureRecycle$.next(infrastructureData);
    });

    this.performanceBodDataService.getInfrastructureSourceWater(id).subscribe(sourceWaterData => {
      sourceWaterData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureSourceWater$.next(sourceWaterData);
    });

    this.performanceBodDataService.getPerformanceBodFracPond(id).subscribe(fracPondData => {
      this._performanceBodFracPond$.next(fracPondData);
    });

    this.performanceBodDataService.getPerformanceBodEdsIe(id).subscribe(edsIeData => {
      edsIeData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodEdsIe$.next(edsIeData);
    });

    this.performanceBodDataService.getPerformanceBodPipelines(id).subscribe(pipelines => {
      pipelines.forEach(element => {
        if (element.pipelineRfsd !== null && element.pipelineRfsd !== undefined) {
          element.pipelineRfsd = Helpers.convertUtcToLocalTime(element.pipelineRfsd);
        } else {
          element.pipelineRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodPipelinesData$.next(pipelines);
    });

    this.performanceBodDataService.getPerformanceBodWaterDisposal(id).subscribe(waterDisposalData => {
      waterDisposalData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._performanceBodWaterDisposal$.next(waterDisposalData);
    });

    this.performanceBodDataService.getPerformanceBodSummary(id).subscribe(summaryData => {
      this._performanceBodSummary$.next(summaryData);
    });

    this.performanceBodDataService.getPerformanceBodCommercial(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureCommercial$.next(data);
    });

    this.performanceBodDataService.getPerformanceBodRegulatoryPermitting(id).subscribe(data => {
      data.forEach(element => {
        if (element.requestedConstructionStartDate !== null && element.requestedConstructionStartDate !== undefined) {
          element.requestedConstructionStartDate = Helpers.convertUtcToLocalTime(
            element.requestedConstructionStartDate
          );
        } else {
          element.requestedConstructionStartDate = undefined;
        }
      });
      this._infrastructureRegulatoryPermitting$.next(data);
    });

    this.performanceBodDataService.getScopeNonStandardOutsideFactoryProjects(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._scopeNonStandardOutsideFactoryProjects$.next(data);
    });

    this.performanceBodDataService.getPerformanceBodExistingFacilities(id).subscribe(data => {
      this._performanceBodExistingFacilitiesData$.next(data);
    });

    this.performanceBodDataService.getByIdPerformanceBodSignatures(id).subscribe(
      signature => {
        if (signature.length > 0) {
          if ((signature[0].performanceProjectManagerCai != null && signature[0].performanceProjectManagerDate != null) || (signature[0].performanceReservoirEngineerCai != null && signature[0].performanceReservoirEngineerDate != null)
            || (signature[0].performanceTeamLeadCai != null && signature[0].performanceTeamLeadDate != null) || (signature[0].performanceWaterEngineerCai != null && signature[0].performanceWaterEngineerDate != null)) {
            this._performanceBodSignatured$.next(true);
          }
        }
      },
      (error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
      }
    ) 
  }

  //#endregion "Open Performance BOD"

  public retrivePerformanceBodPackage(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceBodPackage(performanceBodId).subscribe(data => {
      this._performanceBodPackagesData$.next(data);
    });
  }

  public retrievePerformanceBodStdFacilityCTBData(id: string) {
    this.performanceBodDataService.getPerformanceBodCentralTankBattery(id).subscribe(data => {
      this._performanceBodCentralTankBattery$.next(data);
    });
  }

  public retrievePerformanceBodStdFacilityCSData(id: string) {
    this.performanceBodDataService.getPerformanceBodCompressor(id).subscribe(data => {
      this._performanceBodCompressor$.next(data);
    });
  }

  public retrievePerformanceBodStdFacilitySWDData(id: string) {
    this.performanceBodDataService.getPerformanceBodSaltWaterDisposal(id).subscribe(data => {
      this._performanceBodSaltWaterDisposal$.next(data);
    });
  }

  public retrievePerformanceBodWellPadData(id: string) {
    this.completed = new EventEmitter<any>();
    this.completed.emit(true);
    this.performanceBodDataService.getPerformanceBodWellPad(id).subscribe(data => {
      data.forEach(element => {
        if (element.wellPadRfsd !== null && element.wellPadRfsd !== undefined) {
          element.wellPadRfsd = Helpers.convertUtcToLocalTime(element.wellPadRfsd);
        } else {
          element.wellPadRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodWellPad$.next(data);
      this.completed.emit(false);
      this.completed.complete();
    });
  }

  public retrievePerformanceBodFacilityPad(id: string) {
    this.performanceBodDataService.getPerformanceBodFacilityPad(id).subscribe(data => {
      this._performanceBodFacilityPadData$.next(data);
    });
  }

  public retrieveInfrastructureRegulatoryPermittingData(id: string) {
    this.performanceBodDataService.getPerformanceBodRegulatoryPermitting(id).subscribe(data => {
      data.forEach(element => {
        if (element.requestedConstructionStartDate !== null && element.requestedConstructionStartDate !== undefined) {
          element.requestedConstructionStartDate = Helpers.convertUtcToLocalTime(
            element.requestedConstructionStartDate
          );
        } else {
          element.requestedConstructionStartDate = undefined;
        }
      });
      this._infrastructureRegulatoryPermitting$.next(data);
    });
  }

  public retrieveInfrastructureSourceWaterData(id: string) {
    this.performanceBodDataService.getInfrastructureSourceWater(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureSourceWater$.next(data);
    });
  }

  public retrievePerformanceBodFracPondData(id: string) {
    this.performanceBodDataService.getPerformanceBodFracPond(id).subscribe(data => {
      this._performanceBodFracPond$.next(data);
    });
  }

  public retrievePerformanceBodEdsIeData(id: string) {
    this.performanceBodDataService.getPerformanceBodEdsIe(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodEdsIe$.next(data);
    });
  }

  public refreshPerformanceBod(id: string): void {
    this.refreshingBodDetails = new EventEmitter<any>();
    this.refreshingBodDetails.emit(true);
    this.performanceBodDataService.getByIdPerformanceBod(id).subscribe(
      performanceBodData => {
        performanceBodData.performanceProjectManagerDate = Helpers.convertUtcToLocalTime(
          performanceBodData.performanceProjectManagerDate
        );
        performanceBodData.performanceReservoirEngineerDate = Helpers.convertUtcToLocalTime(
          performanceBodData.performanceReservoirEngineerDate
        );
        performanceBodData.performanceTeamLeadDate = Helpers.convertUtcToLocalTime(
          performanceBodData.performanceTeamLeadDate
        );

        performanceBodData.createdDate = Helpers.convertUtcToLocalTime(performanceBodData.createdDate);
        performanceBodData.updatedDate = Helpers.convertUtcToLocalTime(performanceBodData.updatedDate);
        this._performanceBod$.next(performanceBodData);

        this.performanceBodDataService.getByIdPerformanceBodSignatures(id).subscribe(
          signatures => {
            signatures.performanceProjectManagerDate = Helpers.convertUtcToLocalTime(
              signatures.performanceProjectManagerDate
            );
            signatures.performanceReservoirEngineerDate = Helpers.convertUtcToLocalTime(
              signatures.performanceReservoirEngineerDate
            );
            signatures.performanceTeamLeadDate = Helpers.convertUtcToLocalTime(signatures.performanceTeamLeadDate);

            signatures.createdDate = Helpers.convertUtcToLocalTime(signatures.createdDate);
            signatures.updatedDate = Helpers.convertUtcToLocalTime(signatures.updatedDate);
            this._performanceBodSignaturesData$.next(signatures);
          },
          error => {
            this.refreshingBodDetails.error(error);
            this.refreshingBodDetails.complete();
          }
        );

        this.performanceBodDataService.getPerformanceBodStatus(id).subscribe(
          status => {
            this._performanceBodStatus$.next(status);
            this.errorOpenPerformanceBod.hasError = false;
          },
          (error: any) => {
            this.errorOpenPerformanceBod.hasError = true;
          }
        );

        this.performanceBodDataService.getPerformanceBodPackage(id).subscribe(
          data => {
            this._performanceBodPackagesData$.next(data);
            this.refreshingBodDetails.emit(false);
            this.refreshingBodDetails.complete();
          },
          error => {
            this.refreshingBodDetails.error(error);
            this.refreshingBodDetails.complete();
          }
        );
      },
      error => {
        this.refreshingBodDetails.error(error);
        this.refreshingBodDetails.complete();
      }
    );
  }

  public retrievePerformanceBodWaterDisposalData(id: string) {
    this.performanceBodDataService.getPerformanceBodWaterDisposal(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._performanceBodWaterDisposal$.next(data);
    });
  }

  public retrievePerformanceBodSummary(performanceBodId: string) {
    this.performanceBodDataService.getPerformanceBodSummary(performanceBodId).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }

        if (element.bodLockdownDate !== null && element.bodLockdownDate !== undefined) {
          element.bodLockdownDate = Helpers.convertUtcToLocalTime(element.bodLockdownDate);
        } else {
          element.bodLockdownDate = undefined;
        }
      });
      this._performanceBodSummary$.next(data);
    });
  }

  public retrievePerformanceBodPipelines(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceBodPipelines(performanceBodId).subscribe(data => {
      data.forEach(element => {
        if (element.pipelineRfsd !== null && element.pipelineRfsd !== undefined) {
          element.pipelineRfsd = Helpers.convertUtcToLocalTime(element.pipelineRfsd);
        } else {
          element.pipelineRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      this._performanceBodPipelinesData$.next(data);
    });
  }

  public retrievePerformanceBodPads(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceBodPads(performanceBodId).subscribe(data => {
      this._performanceBodPadsData$.next(data);
    });
    this.performanceBodDataService.getPerformanceExpectedPeakProductionRates(performanceBodId).subscribe(data => {
      this._performanceExpectedPeakProductionRatesData$.next(data);
    });
  }

  public updateStatus_NewPerformanceBOD(status: boolean) {
    this._newPerformanceBOD$.next(status);
  }

  public getStatus_NewPerformanceBOD(): boolean {
    return this._newPerformanceBOD$.value;
  }

  public retrievePerformanceExpectedPeakProductionRates(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceExpectedPeakProductionRates(performanceBodId).subscribe(data => {
      this._performanceExpectedPeakProductionRatesData$.next(data);
    });
  }

  public retrievePerformanceBodCommercial(id: string) {
    this.performanceBodDataService.getPerformanceBodCommercial(id).subscribe(data => {
      data.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureCommercial$.next(data);
    });
  }

  public retrieveInfrastructureRecycleData(id: string) {
    this.performanceBodDataService.getInfrastructureRecycle(id).subscribe(infrastructureData => {
      infrastructureData.forEach(element => {
        if (element.readyForServiceDate !== null && element.readyForServiceDate !== undefined) {
          element.readyForServiceDate = Helpers.convertUtcToLocalTime(element.readyForServiceDate);
        } else {
          element.readyForServiceDate = undefined;
        }
      });
      this._infrastructureRecycle$.next(infrastructureData);
    });
  }

  // Used to validate the delete packages
  public validateAllowRemovePerformanceBodPads(performanceBodPadsIdSelected: string): boolean {
    let valid: boolean = true;

    let pads: IPerformanceBodPads[] = clonedeep(this._performanceBodPadsData$.value);
    let commercials: InfrastructureCommercial[] = clonedeep(this._infrastructureCommercial$.value);
    let compressors: IPerformanceBodCompressor[] = clonedeep(this._performanceBodCompressor$.value);
    let ctbs: IPerformanceBodCentralTankBattery[] = clonedeep(this._performanceBodCentralTankBattery$.value);
    let edsIes: IPerformanceBodEdsIe[] = clonedeep(this._performanceBodEdsIe$.value);
    let swds: IPerformanceBodSaltWaterDisposal[] = clonedeep(this._performanceBodSaltWaterDisposal$.value);
    let wellPads: IPerformanceBodWellPad[] = clonedeep(this._performanceBodWellPad$.value);
    let wellArtificialLift: IPerformanceBodWellArtificialLift[] = clonedeep(
      this._performanceBodWellArtificialLiftData$.value
    );
    let existingFacilities: IPerformanceBodExistingFacilities[] = clonedeep(
      this._performanceBodExistingFacilitiesData$.value
    );

    if (pads !== null && pads !== undefined) {
      let pad = pads.find(pad => pad.id === performanceBodPadsIdSelected);
      if (pad !== null && pad !== undefined) {
        if (commercials !== null && commercials !== undefined) {
          commercials.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (compressors !== null && compressors !== undefined) {
          compressors.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (ctbs !== null && ctbs !== undefined) {
          ctbs.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (edsIes !== null && edsIes !== undefined) {
          edsIes.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (swds !== null && swds !== undefined) {
          swds.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (wellPads !== null && wellPads !== undefined) {
          wellPads.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (wellArtificialLift !== null && wellArtificialLift !== undefined) {
          wellArtificialLift.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }

        if (existingFacilities !== null && existingFacilities !== undefined) {
          existingFacilities.forEach(c => {
            if (c.performanceBodPadsId === performanceBodPadsIdSelected) {
              valid = false;
            }
          });
        }
      }
    }
    return valid;
  }

  public removePeakProductionRate(performanceBodPadsIdSelected: string): void {
    let peakProds: IPerformanceExpectedPeakProductionRates[] = clonedeep(
      this._performanceExpectedPeakProductionRatesData$.value
    );
    if (peakProds !== null && peakProds !== undefined) {
      let index = peakProds.findIndex(item => item.id === performanceBodPadsIdSelected);
      if (index !== null && index !== undefined) {
        peakProds.splice(index, 1);
      }
      this._performanceExpectedPeakProductionRatesData$.next(peakProds);
    }
  }

  public retrievePerformanceBodWellArtificialLift(performanceBodId: string): void {
    this.onLoadArtificialLift = new EventEmitter<boolean>();
    this.onLoadArtificialLift.emit(false);
    this.performanceBodDataService
      .getPerformanceBodWellArtificialLift(performanceBodId)
      .subscribe(wellArtificialLift => {
        this._performanceBodWellArtificialLiftData$.next(wellArtificialLift);
        this.onLoadArtificialLift.emit(true);
        this.onLoadArtificialLift.complete();
      });
  }

  public retrieveScopeNonStandardOutsideFactoryProjects(performanceBodId: string): void {
    this.performanceBodDataService.getScopeNonStandardOutsideFactoryProjects(performanceBodId).subscribe(data => {
      this._scopeNonStandardOutsideFactoryProjects$.next(data);
    });
  }

  public retrievePerformanceBodExistingFacilities(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceBodExistingFacilities(performanceBodId).subscribe(data => {
      this._performanceBodExistingFacilitiesData$.next(data);
    });
  }

  public getPeformanceBodSignaturesByPerformanceBodId(performanceBodId: string): void {
    this.performanceBodDataService.getByIdPerformanceBodSignatures(performanceBodId).subscribe(
      signature => {
        signature.performanceProjectManagerDate = Helpers.convertUtcToLocalTime(
          signature.performanceProjectManagerDate
        );
        signature.performanceReservoirEngineerDate = Helpers.convertUtcToLocalTime(
          signature.performanceReservoirEngineerDate
        );
        signature.performanceTeamLeadDate = Helpers.convertUtcToLocalTime(signature.performanceTeamLeadDate);
        signature.createdDate = Helpers.convertUtcToLocalTime(signature.createdDate);
        signature.updatedDate = Helpers.convertUtcToLocalTime(signature.updatedDate);
        this.errorOpenPerformanceBod.hasError = false;
      },
      (error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
      }
    );
  }
  public setPerformanceBodSignaturesValue(value: IPerformanceBodSignatures[]): void {
    this._performanceBodSignaturesData$.next(value);
  }

  public retrieveNotesByPerformanceBodId(performanceBodId: string): void {
    this.performanceBodDataService.getPerformanceBodNotes(performanceBodId).toPromise()
    .then(notes => {
      notes.map((item: IPerformanceBodNotes) => {
        item.createdDate = Helpers.convertUtcToLocalTime(item.createdDate);
        item.updatedDate = Helpers.convertUtcToLocalTime(item.updatedDate);
        this._performanceBodNotesData$.next(notes);
        this.errorOpenPerformanceBod.hasError = false;
      });
    })
    .catch((error: any) => {
        this.errorOpenPerformanceBod.hasError = true;
    });
  }

  public retrieveNotesAttachmentByPerformanceBodNotesId(performanceBodNotesId: string): void {
    this.performanceBodDataService.getPerformanceBodNotesAttachment(performanceBodNotesId).subscribe(data => {
      this._performanceBodNotesAttachmentData$.next([]);
      this._performanceBodNotesAttachmentData$.next(data);
    });
  }

  public setCanEditPerformance(val: boolean) {
    this._canEditPerformance$.next(val);
  }

  public getCanEditPerformance(): boolean {
    return this._canEditPerformance$.getValue();
  }

  public retrieveStatusIndicator(): void {
    this.performanceBodDataService.getStatusIndicator().subscribe((data: IStatusIndicator[]) => {
      this._statusIndicator$.next(data);
    });
  }

  public getPerformanceBodStatusById(performanceBodId: string): void {
    this.performanceBodDataService
      .getPerformanceBodStatus(performanceBodId)
      .subscribe((data: IPerformanceBodStatus[]) => {
        this._performanceBodStatus$.next(data);
      });
  }

  public createPerformanceBodStatusAsync(performanceBodStatus: IPerformanceBodStatus[]): Observable<any> {
    const toCreate = performanceBodStatus.map((item: IPerformanceBodStatus) => {
      return {
        performanceBodId: item.performanceBodId,
        statusIndicatorId: item.statusIndicatorId,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.performanceBodDataService.createPerformanceBodStatus(toCreate);
  }

  public updatePerformanceBodStatusAsync(performanceBodStatus: IPerformanceBodStatus[]): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodStatus(performanceBodStatus);
  }
   
}
