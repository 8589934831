import { Component, Input, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { State, process, SortDescriptor } from '@progress/kendo-data-query';
import { ScenarioDataService } from 'src/app/services/scenario-data.service';
import { IViewPricetScenario } from 'src/app/api/viewPricetScenario';
@Component({
  selector: 'app-scenario-data-expansion',
  templateUrl: './app-scenario-data-expansion.component.html',
  styleUrls: ['./app-scenario-data-expansion.component.css'],
})
export class AppScenarioDataExpansionComponent implements OnInit {
  @Input() public developmentAreaName: any;
  @Input() public isActiveForOutlook: any;
  public view: Observable<GridDataResult>;
  public scenarioDevData: IViewPricetScenario[];
  public multiple = false;
  public allowUnsort = true;
  public buttonCount = 10;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public pageSize = 10;
  public isLoading = true;
  public skip = 0;
  public sort: SortDescriptor[] = [
    {
      field: 'PadName',
      dir: 'desc',
    },
  ];
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 20,
  };
  private editService: ScenarioDataService;

  constructor(@Inject(ScenarioDataService) editServiceFactory: any) {
    this.editService = editServiceFactory();
  }

  ngOnInit(): void {
    this.editService.getScenarioExpansionsByDevName(this.developmentAreaName, this.isActiveForOutlook).subscribe(z => {
      this.scenarioDevData = z;
      this.scenarioDevData.forEach(obj => {
          obj.createdDate = this.convertUtcToLocalTime2(obj.createdDate);
      });
      this.isLoading = false;
    });

  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.editService.getScenarioExpansionsByDevName(this.developmentAreaName, this.isActiveForOutlook).subscribe(z => {
      this.scenarioDevData = z;
      this.scenarioDevData.forEach(obj => {
          obj.createdDate = this.convertUtcToLocalTime2(obj.createdDate);
      });
      this.isLoading = false;
    });
  }


  public convertUtcToLocalTime2(date: Date): Date {
    if (date !== undefined && date != null) {
      let utcDateString = date.toString();
      // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
      if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
        utcDateString += 'Z';
      }
      return new Date(utcDateString);
    }
    return null;
  }
}
