<section>
  <p class="change-p">
    The AFE Number you are removing is currently default one. <br />
    What action you would like to take?
  </p>
  <form novalidate [formGroup]="afeForm" id="changeAfe" (ngSubmit)="onConfirmAction(afeForm.value, afeForm.valid)">
    <kendo-dialog-actions>
      <button kendoButton (click)="onCancelAction()">Cancel</button>
      <button kendoButton form="changeAfe" [primary]="true">
        Save &amp; Close
      </button>
    </kendo-dialog-actions>
    <kendo-tabstrip (tabSelect)="onTabSelect($event)" #tabStripAfe>
      <kendo-tabstrip-tab
        [title]="'Replace with existing AFE No.'"
        [selected]="selectedOptionIndexTab1"
        [disabled]="isDisabled"
      >
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="container">
            <div class="row">
              <div class=".col-md">
                <label for="afeDDL" class="control-label">AFE No.&nbsp;*</label>
              </div>
              <div class=".col-md-5">
                <kendo-dropdownlist
                  formControlName="afeDDL"
                  [data]="afeList"
                  [defaultItem]="defaultAfe"
                  [textField]="'number'"
                  [valueField]="'id'"
                  isRequired="true"
                >
                </kendo-dropdownlist>
                <p
                  class="error-validation"
                  [hidden]="afeForm.controls.afeDDL.valid || (afeForm.controls.afeDDL.pristine && !isSubmitted)"
                >
                  An AFE No. is required.
                </p>
              </div>
              <div class="">
                <input
                  _ngcontent-c8=""
                  class="k-checkbox ng-star-inserted"
                  id="selectAllCheckboxId"
                  kendogridselectallcheckbox=""
                  type="checkbox"
                  [checked]="afe.isDefault"
                  disabled
                />
                <label _ngcontent-c8="" class="k-checkbox-label ng-star-inserted" for="selectAllCheckboxId"
                  >Set as Default</label
                >
              </div>
            </div>
          </div>
          <br /><br /><br />
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Set as New AFE No.'" [selected]="selectedOptionIndexTab2">
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="container">
            <div class="row">
              <div class=".col-md">
                <label for="name" class="control-label">Name</label>
              </div>

              <div class=".col-md-5">
                <input kendoTextBox type="text" formControlName="name" class="form-control string" required />
                <p
                  class="error-validation"
                  style="display: inline"
                  [hidden]="afeForm.controls.name.valid || (afeForm.controls.name.pristine && !isSubmitted)"
                >
                  Name is required.
                </p>
              </div>
            </div>
            <br />
            <br />
            <div class="row">
              <div class=".col-md">
                <label for="number" class="control-label">AFE No.&nbsp;*</label>
              </div>
              <div class="">
                <kendo-maskedtextbox
                  formControlName="number"
                  [value]="defaulAfeValue"
                  [mask]="afeMask"
                  [maskValidation]="true"
                  required
                >
                </kendo-maskedtextbox>
                <p
                  class="error-validation"
                  [hidden]="afeForm.controls.number.valid || (afeForm.controls.number.pristine && !isSubmitted)"
                >
                  AFE No. is required.
                </p>
              </div>
              <div class=".col-md">
                <input class="k-checkbox" type="checkbox" [checked]="afe.isDefault" disabled />
                <label class="k-checkbox-label">Set as Default</label>
              </div>
            </div>
            <br />
            <br />
            <div class="row">
              <div class="">
                <label for="amount" class="control-label">AFE Amount</label>
              </div>
              <div class="">
                <kendo-numerictextbox formControlName="amount"></kendo-numerictextbox>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>
</section>
