import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GridDataResult, SelectableSettings, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScenarioPadBracket } from '../../../api/scenario-pad-bracket';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { PadBuilderEditService } from '../input-summary/active-for-outlook/pad-builder-data-edit-service';
import { DataService } from '../../../services/data.service';
import { CostByPadDialogService } from './cost-by-pad-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  templateUrl: './cost-by-pad-dialog.component.html',
  styleUrls: ['./cost-by-pad-dialog.component.css'],
})
export class CostByPadDialogComponent implements OnInit {
  steps: any[] = [
    { label: 'Select', icon: 'search' },
    { label: 'Edit', icon: 'track-changes-enable' },
    { label: 'Save', icon: 'save' },
  ];
  position: string = 'top';
  currentTab: number = 0;
  public canEditCnd: boolean = false;
  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [
      {
        field: 'padOrder',
        dir: 'asc',
      },
    ],
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public mySelection: any[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public costByPadDialogService: CostByPadDialogService;
  public padBuilderData: any[];
  public formActive: FormGroup;
  public valuePadBracket: ScenarioPadBracket[];
  public scenarioId: string;
  public originalPadBracketList: ScenarioPadBracket[];
  public lstYears: string[] = ['2019', '2023', '2024'];
  public selectedYear = '2019';
  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
    drag: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    private scenarioStoreService: ScenarioStoreService,
    @Inject(CostByPadDialogService) costByPadDialogServiceFactory: any,
    private permissionsProvider: PermissionsProvider,
    private dataService: DataService,
    private dialog: DialogRef,
    private spinner: NgxSpinnerService
  ) {
    this.costByPadDialogService = costByPadDialogServiceFactory();
  }

  ngOnInit(): void {
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.view = this.costByPadDialogService.pipe(
      map(data => {
        if (this.costByPadDialogService.completed) {
          //correct the cost years for the pads as dialog closed to avoid sync
          this.getLatestData();
          this.dialog.close({ refreshScenario: true });
        }
        return process(data, this.gridState);
      })
    );
    this.costByPadDialogService.read();
    this.padBuilderData = this.costByPadDialogService.originalData;

    // this.formActive = this.fb.group({
    //   padName: [''],
    // });
  }

  getLatestData() {
    this.costByPadDialogService.getPadData().subscribe(
      data => {
        this.scenarioStoreService.updatePadCostYear(data);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      padName: dataItem.padName,
      yearCost: dataItem.yearCost,
    });
  }

  public onSelectedKeysChange(e: any) {
    const len = this.mySelection.length;

    if (len === 0) {
      this.selectAllState = 'unchecked';
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      args.preventDefault();
    } else if (formGroup.dirty) {
      this.costByPadDialogService.assignValues(dataItem, formGroup.value);
      this.costByPadDialogService.update(dataItem);
    }
  }

  public onDataStateChange(state: any): void {
    this.gridState = state;
    this.costByPadDialogService.read();
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.mySelection = this.costByPadDialogService.data.map(item => item.id);
      if (this.mySelection.length === 0) {
      } else {
        this.selectAllState = 'checked';
      }
    } else {
      this.mySelection = [];
      this.selectAllState = 'unchecked';
    }
  }

  onCancelAction(): void {
    this.dialog.close();
  }

  onSubmit(): void {
    if (this.costByPadDialogService.hasChanges()) {
      this.spinner.show();
      this.costByPadDialogService.saveChanges();
    } else {
      alert('Please change the year for at least one pad before submitting.');
    }
  }

  public updateSelectedPads(): void {
    this.costByPadDialogService.data
      .filter(data => this.mySelection.includes(data.id))
      .map(item => {
        item.yearCost = this.selectedYear;
        this.costByPadDialogService.update(item);
      });
    this.selectAllState = 'unchecked';
    this.mySelection = [];
  }

  public resetPads(): void {
    this.costByPadDialogService.cancelChanges();
    this.selectAllState = 'unchecked';
    this.mySelection = [];
  }
}
