<kendo-grid #performanceBodFracPond
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            class="infrastructure-frac-ponds-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!performanceBodFracPondService.hasChanges()"
            (click)="cancelChanges(performanceBodFracPond)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="fracPondName" title="Frac Pond Name" [editable]="true" [width]="130">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input #input kendoGridFocusable type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Frac Pond Name already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true" [width]="130">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="performanceBodPackagesList"
                          [defaultItem]="{ id: null, name: 'Select item...' }"
                          textField="name"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPackagesId')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate"
                     title="C&D/HO Estimate"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="120">
  </kendo-grid-column>

  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"
                     title="Approved AFE Amount"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     width="150">
</kendo-grid-column>

  <kendo-grid-column field="newPlusExistingVolumeMbbl"
                     title="Planned Volume"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="130">
  </kendo-grid-column>
  <kendo-grid-column field="permitsRequiredBy"
                     title="Permits Required By"
                     [filterable]="filterable"
                     filter="date"
                     [width]="130">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.permitsRequiredBy | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.permitsRequiredBy"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('permitsRequiredBy')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="readyForServiceDate" title="RFSD" [filterable]="filterable" filter="date" [width]="130">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.readyForServiceDate"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('readyForServiceDate')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"></kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
