<div class="content" style="margin-top: 30px">
  <div class="form-group row">
    <div class="col-sm-1">
      <label class="col-form-label">
        Cost Year:
      </label>
    </div>
    <div class="col-sm-2">
      <kendo-dropdownlist [data]="lstYears" [(ngModel)]="selectedYear"> </kendo-dropdownlist>
    </div>
    <div class="col-sm-2">
      <button kendoButton (click)="updateSelectedPads()" [primary]="true">
        Set for selected pads
      </button>
    </div>
    <div class="col-sm-2 offset-5">
      <button kendoButton (click)="resetPads()" class="float-right">
        Reset pads
      </button>
    </div>
  </div>
  <kendo-grid
    #costYearByPad
    [data]="view | async"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [filter]="gridState.filter"
    [filterable]="true"
    (dataStateChange)="onDataStateChange($event)"
    [scrollable]="'scrollable'"
    [navigable]="true"
    style="height: 400px;"
    [kendoGridSelectBy]="'id'"
    [(selectedKeys)]="mySelection"
    (selectedKeysChange)="onSelectedKeysChange($event)"
    (cellClose)="cellCloseHandler($event)"
    (cellClick)="cellClickHandler($event)"
    [selectable]="selectableSettings"
  >
    <kendo-grid-column field="padName" editor="text" [editable]="false" title="Pad Name"> </kendo-grid-column>
    <kendo-grid-column field="yearCost" editor="text" [editable]="true" title="Year">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist [data]="lstYears" [formControl]="formGroup.get('yearCost')"> </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-checkbox-column width="50">
      <ng-template kendoGridHeaderTemplate>
        <input
          class="k-checkbox"
          id="selectAllCheckboxId"
          kendoGridSelectAllCheckbox
          [state]="selectAllState"
          (selectAllChange)="onSelectAllChange($event)"
        />
        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
    </kendo-grid-checkbox-column>
  </kendo-grid>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button kendoButton (click)="onSubmit()" [primary]="true">
    Submit
  </button>
</kendo-dialog-actions>
