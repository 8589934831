<div class="card-body">
  <div #notificationPlaceHolder></div>
  <kendo-grid
    [inCellTab]="createFormGroup"
    #inputFacilityScopeCount
    [data]="gridView"
    [reorderable]="true"
    [resizable]="true"
    [skip]="skip"
    [pageSize]="pageSize"
    [filterable]="true"
    [filter]="state.filter"
    [sortable]="{ allowUnsort: allowUnsort, mode: 'multiple' }"
    [sort]="sort"
    [rowHeight]="36"
    [height]="940"
    [style.maxHeight.px]="940"
    (dataStateChange)="onDataStateChange($event)"
    (sortChange)="sortChange($event)"
    (columnReorder)="onColumnReorder($event)"
    (edit)="expandCollapseHandler($event)"
    (filterChange)="filterChange($event)"
    (cellClick)="cellClickHandler($event)"
    (pageChange)="pageChange($event)"
    (cellClose)="cellCloseHandler($event)"
    [loading]="isLoading"
  >
    <kendo-grid-command-column
      [resizable]="false"
      [reorderable]="false"
      [locked]="true"
      width="20"
      [minResizableWidth]="20"
      class="no-padding"
      field="isCloned"
    >
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      <button
        kendoButton
        look="flat"
        (click)="expandCollapseAllHandler()" 
        style="padding: 0px 0px 0px 0px; border: none; background: #f8f9fa;
    margin: 0px 0px 0px -10px;"
      >
      
        <span [class]="headerCellClassExpandCollapse()"></span>
      </button>
    </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template-cmd' + ' ' + cellClass(dataItem, 'cloneDelete', false)">
          <button type="button"
                  class="k-button k-button-md"
                  [look]="'flat'"
                  (click)="cloneOrDelete(dataItem, rowIndex)"
                  [hidden]="isHiddenClonedDeleted(dataItem)"
                  style="padding: 2px; padding-top: 6px; border-width: 0px;">
            <span [class]="cellClassCloneDelete(dataItem)"></span>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column [resizable]="false"
                               [reorderable]="false"
                               [locked]="true"
                               width="20"
                               [minResizableWidth]="20"
                               class="no-padding"
                               field="isVisible">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template-cmd' + ' ' + cellClass(dataItem, 'expandCollapse', false)">
          <button kendoGridEditCommand
                  [look]="'flat'"
                  [hidden]="isHiddenExpandCollapse(dataItem)"
                  style="padding: 2px; padding-top: 6px;">
            <span [class]="cellClassExpandCollapse(dataItem)"></span>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column [reorderable]="false"
                       [locked]="true"
                       field="displayName"
                       title="Item"
                       width="150"
                       class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'displayName', false)"
             kendoTooltip
             title="{{ dataItem.itemNotes }}"
             [showAfter]="400">
          {{ dataItem.displayName }}
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <input [class]="'input-edit-cell-template ' + 'custom-cell-template ' + cellClass(dataItem, 'displayName', false)"
               kendoGridFocusable
               [formControl]="formGroup.get('displayName')" />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="itemDetail" title="Detail" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div
          [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'itemDetail', false)"
          kendoTooltip
          title="{{ dataItem.itemDetail.itemDetailNotes }}"
          [showAfter]="400"
        >
          {{ dataItem.itemDetail.displayName }}
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <ng-container *ngIf="dataItem.name; else elseBlock">
          <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'itemDetail', false)">
            <kendo-dropdownlist #dropdownEditor
                                kendoGridFocusable
                                [data]="dataItem.itemDetails"
                                [textField]="'displayName'"
                                [valueField]="'name'"
                                [formControl]="formGroup.get('itemDetail')">
            </kendo-dropdownlist>
          </div>
        </ng-container>
        <ng-template #elseBlock>
          <input [class]="'input-edit-cell-template ' + 'custom-cell-template ' + cellClass(dataItem, 'itemDetail', false)"
                 kendoGridFocusable
                 [formControl]="formGroup.get('itemDetail')" />
        </ng-template>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column"> </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="unitOfMeasure.name" title="Unit" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'unitOfMeasure.name', false)"
             *ngIf="!hideController(dataItem)">
          <ng-container *ngIf="dataItem.unitOfMeasure && dataItem.unitOfMeasure.name && dataItem.unitOfMeasure.name !== 'default'">
            {{ dataItem.unitOfMeasure.name }}
          </ng-container>
        </div>
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'unitOfMeasure.name', false)"
             *ngIf="hideController(dataItem)">
          <ng-container *ngIf="dataItem.unitOfMeasure && dataItem.unitOfMeasure.name && dataItem.unitOfMeasure.name !== 'default'">
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'unitOfMeasure.name', false)"
                            kendoGridFocusable
                            [data]="unitOfMeasureOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('unitOfMeasure')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'unitOfMeasure.name', false)"
                            kendoGridFocusable
                            [data]="unitOfMeasureOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('unitOfMeasure')"
                            [hidden]="!hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="numeric"
                       field="costPunit"
                       width="100"
                       class="no-padding"
                       [headerStyle]="highlightColumn ? highlightColumnClass : {}">
      <ng-template kendoGridHeaderTemplate let-column>
        <div>Cost/Unit ($M) {{ selectedPad ? selectedPad.yearCost : null }}</div>
        <div *ngIf="highlightColumn" style="font-size: 12px">{{ selectedPad ? selectedPad.pbPadName : null }}</div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <span [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'costPunit', false)"
              [class.overridden-field]="isCostOverridden(dataItem)"
              [hidden]="hideController(dataItem)">
          {{ dataItem.costPunit | currency: 'USD':'symbol':'1.0' }}
        </span>
        <span [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'costPunit', false)"
              [class.overridden-field]="isCostOverridden(dataItem)"
              [hidden]="!hideController(dataItem)">
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-numerictextbox [class]="cellClass(dataItem, 'costPunit', false)"
                              kendoGridFocusable
                              [formControl]="formGroup.get('costPunit')"
                              [hidden]="hideController(dataItem)"></kendo-numerictextbox>
        <kendo-numerictextbox [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'costPunit', false)"
                              kendoGridFocusable
                              [formControl]="formGroup.get('costPunit')"
                              [hidden]="!hideController(dataItem)"></kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="infrastructureClass.name" title="Infrastructure Type" width="150" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'infrastructureClass.name', false)"
             [hidden]="hideController(dataItem)">
          <ng-container *ngIf="dataItem.infrastructureClass && dataItem.infrastructureClass.name">
            {{ dataItem.infrastructureClass.name }}
          </ng-container>
        </div>
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'infrastructureClass.name', false)"
             [hidden]="!hideController(dataItem)">
          <ng-container *ngIf="dataItem.infrastructureClass && dataItem.infrastructureClass.name"> </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'infrastructureClass.name', false)"
                            kendoGridFocusable
                            [data]="infrastructureTypeOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('infrastructureClass')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="scenarioAfeType.name" title="AFE Type" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'scenarioAfeType.name', false)">
          <ng-container *ngIf="dataItem.scenarioAfeType && dataItem.scenarioAfeType.name && !hideController(dataItem)">
            {{ dataItem.scenarioAfeType.name }}
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist
          #dropdownEditor
          [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'scenarioAfeType.name', false)"
          kendoGridFocusable
          [data]="scenarioAfeTypeOptions"
          [textField]="'name'"
          [valueField]="'name'"
          [formControl]="formGroup.get('scenarioAfeType')"
          [hidden]="hideController(dataItem)"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="location.name" title="Location" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'location.name', false)">
          <ng-container *ngIf="dataItem.location && dataItem.location.name && !hideController(dataItem)">
            {{ dataItem.location.name }}
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'location.name', false)"
                            kendoGridFocusable
                            [data]="locationOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('location')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="infraestructureScopeCategory.name" title="Scope Category" width="150" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'infraestructureScopeCategory.name', false)">
          <ng-container *ngIf="
              dataItem.infraestructureScopeCategory &&
              dataItem.infraestructureScopeCategory.name &&
              !hideController(dataItem)
            ">
            {{ dataItem.infraestructureScopeCategory.name }}
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'infraestructureScopeCategory.name', false)"
                            kendoGridFocusable
                            [data]="scopeCategoryOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('infraestructureScopeCategory')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="rfsd.name" title="RFSD Timing" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'rfsd', false)">
          <ng-container *ngIf="dataItem.rfsd && dataItem.rfsd.name && !hideController(dataItem)">
            {{ dataItem.rfsd.name }}
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'rfsd', false)"
                            kendoGridFocusable
                            [data]="rfsdOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('rfsd')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="kind.name" title="Kind" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'kind.name', false)">
          <ng-container *ngIf="dataItem.kind && dataItem.kind.name && dataItem.kind.name !== 'default' && !hideController(dataItem)">
            {{ dataItem.kind.name }}
          </ng-container>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <kendo-dropdownlist #dropdownEditor
                            [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'kind.name', false)"
                            kendoGridFocusable
                            [data]="kindOptions"
                            [textField]="'name'"
                            [valueField]="'name'"
                            [formControl]="formGroup.get('kind')"
                            [hidden]="hideController(dataItem)">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="userCategory" title="User Category" width="100" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'userCategory', false)">
          {{ dataItem.userCategory }}
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
        <input [class]="'input-edit-cell-template ' + 'custom-cell-template ' + cellClass(dataItem, 'userCategory', false)"
               kendoGridFocusable
               [formControl]="formGroup.get('userCategory')" />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="numeric" field="total" title="Total" width="100" editor="numeric" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'total', false)"
             [hidden]="hideController(dataItem)">
          {{ dataItem.total }}
        </div>
        <div [class]="'custom-cell-template' + ' ' + cellClass(dataItem, 'total', false)"
             [hidden]="!hideController(dataItem)"></div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group *ngIf="padBuilderPads.length > 0"
                             width="100"
                             [title]="padCountsColumnroupName"
                             [reorderable]="false">
      <ng-container *ngFor="let pad of padBuilderPads">
        <kendo-grid-column [sortable]="false"
                           filter="numeric"
                           [field]="pad.padName"
                           [title]="pad.pbPadName"
                           width="150"
                           editor="numeric"
                           class="no-padding"
                           [headerStyle]="
            highlightColumn && (!selectedPad || selectedPad.padName === pad.padName) ? highlightColumnClass : {}
          ">
          <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
            <div kendoTooltip
                 title="{{ getPadComments(pad.padName) }}"
                 [showAfter]="400"
                 (click)="clickPadBracket(pad)">
              <strong>{{ column.title }}</strong>
              <div>{{ getPadTestLocation(pad.padName) }}</div>
              <div>{{ pad.yearCost }}</div>
              <div>{{pad.grandTotal | currency : 'USD' : 'symbol': '1.0'}}</div>
              <button kendoButton
                      look="flat"
                      (click)="onPadCommentClick(pad.padName)"
                      style="padding: 0px 0px 0px 0px; border: none; background: #f8f9fa;">
                <span [class]="headerBtnCellClassIconPlus()"></span>
              </button>
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div kendoTooltip [title]="loadPrice(pad, dataItem)" [showAfter]="400">
              <span [class]="'custom-cell-template' + ' ' + cellClass(dataItem, pad.padName, true)"
                    [class.overridden-field]="isFieldOverridden(pad.padName, dataItem.name, dataItem.id)"
                    [hidden]="hideController(dataItem)">
                {{ dataItem[pad.padName] | number: '1.0-2' }}
              </span>
              <span [class]="'custom-cell-template' + ' ' + cellClass(dataItem, pad.padName, true)"
                    [class.overridden-field]="isFieldOverridden(pad.padName, dataItem.name, dataItem.id)"
                    [hidden]="!hideController(dataItem)">
              </span>
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-formGroup="formGroup">
            <kendo-numerictextbox [class]="cellClass(dataItem, pad.padName, true)"
                                  [formControl]="formGroup.get(pad.padName)"
                                  [hidden]="hideController(dataItem)"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid-column-group>
  </kendo-grid>
  <app-scope-count></app-scope-count>
  <kendo-dialog title="Comments"
                *ngIf="dialogOpenedPadComment"
                (close)="closeDialogPadComment()"
                [height]="300"
                [width]="700">
    <form #form="ngForm"
          [formGroup]="formComment"
          id="fAddComment"
          novalidate
          (ngSubmit)="setPadComment(formComment.value, formComment.valid)">
      <div class="k-card-body">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-sm-12">
              <div class="form-group row">
                <div class="col-sm-12">
                  <textarea class="form-control" formControlName="padComment" rows="5" maxlength="1000"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <kendo-dialog-actions>
                    <button kendoButton formnovalidate [primary]="true" (mousedown)="closeDialogPadComment()">
                      Cancel
                    </button>
                    <button kendoButton form="fAddComment" [primary]="true" [disabled]="!canEditCnd">Save</button>
                  </kendo-dialog-actions>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </kendo-dialog>
</div>
