<kendo-grid
  #fracSupply
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  class="performane-bod-frac-supply-draggable"
  [rowClass]="rowCallback"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!performanceBodWSFracSupplyService.hasChanges()"
      (click)="cancelChanges(performanceBodWSFracSupplyGrid)"
      [primary]="true"
    >
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodId" title="PerdoformanceBodId" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="PAD" [editable]="true" [width]="150">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPackagesList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="performanceBodPackagesList"
        [defaultItem]="{ id: null, name: 'Select item...' }"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('performanceBodPackagesId')"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="source" title="Source" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-column field="contractExecuted" title="Contract Executed" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="text"
        valueField="value"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="yesNoList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getYesNo(dataItem.contractExecuted)?.text }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist
        popupAnchor
        [data]="yesNoList"
        textField="text"
        valueField="value"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('contractExecuted')"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="contractStartDate"
    title="Contract Start Date"
    [filterable]="filterable"
    filter="date"
    [width]="150"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.contractStartDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.contractStartDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('contractStartDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="contractEndDate"
    title="Contract End Date"
    [filterable]="filterable"
    filter="date"
    [width]="150"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.contractEndDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.contractEndDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('contractEndDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="deliveryRoute" title="Delivery Route (To/From)" [editable]="true" [width]="150">
  </kendo-grid-column>
  <kendo-grid-column field="pipelineType" title="Pipeline Type" [editable]="true" [width]="180">
  </kendo-grid-column>

  <kendo-grid-column
    field="minMaxDeliveryRateBwpd"
    title="Min/Max Delivery Rate BWPD"
    [width]="150"
    [editable]="true"
    editor="numeric"
    filter="numeric"
  >
  </kendo-grid-column>
  <kendo-grid-column field="rowSecured" title="ROW Secured" [editable]="true" [width]="120">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="text"
        valueField="value"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="yesNoList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getYesNo(dataItem.rowSecured)?.text }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist
        popupAnchor
        [data]="yesNoList"
        textField="text"
        valueField="value"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('rowSecured')"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
