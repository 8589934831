import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IScopeAssumptions } from './../../../api/IScopeAssumptions';
import { ScopeAssumptionsService } from './../../../services/scope-assumptions.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DasSimpleService } from '../../../services/das-simple.service';
import { IDasSimple } from '../../../api/das-simple';
import { DasSimpleCalculationsService } from '../../../services/das-simple-calculations.service';
import { IDasSimpleCostsModel } from '../../../api/das-simple-costs-model';
import { Guid } from 'guid-typescript';
import { AuthenticationService } from '../../../services';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { ISmartImportSequence } from '../../../api/smart-import-sequence';

@Component({
  selector: 'app-das-simple-scope-assumptions',
  templateUrl: './das-simple-scope-assumptions.component.html',
 
})
export class DasSimpleScopeAssumptionsComponent implements OnInit, OnDestroy {
  public formScopeAssumptions: FormGroup;
  public isNewDasSimple = false;
  public scopeAssumptionsData: IScopeAssumptions = {
    id: Guid.create().toString(),
    tax: 0,
    contingency: 0.25,
    coopId: '',
    flowlineDetailId: '',
    flowlineLength: 1,
    fracPondTypeId: '',
    gasLiftLength: 1,
    gasLiftLineId: '',
    generalInfLength: 1,
    hpGasLineId: '',
    liquidsTransferLineId: '',
    lpGasLineId: '',
    prodWaterLineId: '',
    rentalId: '',
    dasSimpleId: '',
    swdInjLineId: '',
    wellsPerTestSeparator: 8,
  };

  public formatOptions: any = {
    maximumFractionDigits: 4,
    minimumFractionDigits: 1,
    style: 'percent',
  };

  public rentalCompressorsList: any[];
  public coopCompressorsList: any[];
  public fracPondTypeList: any[];
  public swdInjectionDetailList: any[];
  public lpGasLineDetailList: any[];
  public hpGasLineDetailList: any[];
  public prodWaterLineDetailList: any[];
  public liquidsTransferLineDetailList: any[];
  public gasLiftDetailList: any[];
  public flowlineDetailList: any[];
  public canEditCnd = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private scopeAssumptionsService: ScopeAssumptionsService,
    private authenticationService: AuthenticationService,
    private dasSimpleCalculationsService: DasSimpleCalculationsService,
    private permissionsProvider: PermissionsProvider
  ) {}

  public ngOnInit(): void {
    this.checkIsNew();
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.loadForm();
    this.dasSimpleCalculationsService.dasSimpleCosts$.subscribe((data: IDasSimpleCostsModel[]) => {
      if (data !== null) {
        if (data.length > 0) {
          this.route.paramMap.subscribe((params: ParamMap) => {
            const dasSimpleId = params.get('id');
            if (!this.isNewDasSimple) {
              this.scopeAssumptionsService
                .getByDasSimpleId(dasSimpleId)
                .subscribe((scopeAssumptions: IScopeAssumptions[]) => {
                  if (scopeAssumptions.length > 0) {
                    this.scopeAssumptionsData = scopeAssumptions[0];
                    this.fillCombos(data);
                  }
                });
            } else {
              this.fillCombos(data);
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.scopeAssumptionsService.cleanObservables();
  }

  public createNewFormGroup(data: any) {
    if (data === null) {
      this.formScopeAssumptions = new FormGroup({
        wellsPerTestSeparator: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        rentalCompressors: new FormControl(null, [Validators.required]),
        coopCompressors: new FormControl(null, [Validators.required]),
        fracPondType: new FormControl(null, [Validators.required]),
        swdInjectionDetail: new FormControl(null, [Validators.required]),
        lpGasLineDetail: new FormControl(null, [Validators.required]),
        hpGasLineDetail: new FormControl(null, [Validators.required]),
        prodWaterLineDetail: new FormControl(null, [Validators.required]),
        liquidsTransferLineDetail: new FormControl(null, [Validators.required]),
        gasLiftDetail: new FormControl(null, [Validators.required]),
        flowlineDetail: new FormControl(null, [Validators.required]),
        flowlineLength: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        gasLiftLength: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        genInfrLength: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        tax: new FormControl(null, [Validators.required]),
        contingency: new FormControl(null, [Validators.required]),
      });
    }
  }

  public saveDasSimple(): void {
    const value: any = this.formScopeAssumptions.value;

    

    if (this.validateScopeAssumption(value)) {
      this.loadValuesFromForm();

      if (this.isNewDasSimple) {
        this.scopeAssumptionsData.id = Guid.create().toString();
        this.scopeAssumptionsData.createdDate = new Date();
        this.scopeAssumptionsData.createdBy = this.authenticationService.getCurrentUser().toUpperCase();
      }

      this.scopeAssumptionsData.updatedDate = new Date();
      this.scopeAssumptionsData.updatedBy = this.authenticationService.getCurrentUser().toUpperCase();

      if (this.isNewDasSimple) {
        this.scopeAssumptionsService.createScopeAssumptions([this.scopeAssumptionsData]);
      } else {
        this.scopeAssumptionsService
          .updateScopeAssumptions([this.scopeAssumptionsData])
          .subscribe((unitOfWork: any) => {
            // empty implementation
          });
      }
    } else {
      return;
    }
  }

  public validateScopeAssumption(form: any): boolean {
    let isValid = true;

    if (form === null || form === undefined) {
      this.showWarning('Information of the scope assumptions is required.');
      isValid = false;
    } else {
      if (
        form.wellsPerTestSeparator === null ||
        form.wellsPerTestSeparator === undefined ||
        form.wellsPerTestSeparator.length < 1
      ) {
        this.showWarning('wells Per Test Separator is a required field.');
        isValid = false;
      }

      if (form.rentalCompressors === null || form.rentalCompressors === undefined) {
        this.showWarning('Rental compressors is a required field.');
        isValid = false;
      }

      if (form.coopCompressors === null || form.coopCompressors === undefined) {
        this.showWarning('COOP compressors is a required field.');
        isValid = false;
      }

      if (form.fracPondType === null || form.fracPondType === undefined) {
        this.showWarning('Frac pond type is a required field.');
        isValid = false;
      }

      if (form.swdInjectionDetail === null || form.swdInjectionDetail === undefined) {
        this.showWarning('SWD Injection detail is a required field.');
        isValid = false;
      }

      if (form.lpGasLineDetail === null || form.lpGasLineDetail === undefined) {
        this.showWarning('LP Gas Line Detail is a required field.');
        isValid = false;
      }

      if (form.hpGasLineDetail === null || form.hpGasLineDetail === undefined) {
        this.showWarning('HP Gas Line Detail is a required field.');
        isValid = false;
      }

      if (form.prodWaterLineDetail === null || form.prodWaterLineDetail === undefined) {
        this.showWarning('Prod. Water Line Detail is a required field.');
        isValid = false;
      }

      if (form.liquidsTransferLineDetail === null || form.liquidsTransferLineDetail === undefined) {
        this.showWarning('Liquids tranfer Line Detail is a required field.');
        isValid = false;
      }

      if (form.gasLiftDetail === null || form.gasLiftDetail === undefined) {
        this.showWarning('Gas Lift Detail is a required field.');
        isValid = false;
      }

      if (form.swdInjectionDetail === null || form.swdInjectionDetail === undefined) {
        this.showWarning('SWD Injection detail is a required field.');
        isValid = false;
      }

      if (form.flowlineDetail === null || form.flowlineDetail === undefined) {
        this.showWarning('Flowline detail is a required field.');
        isValid = false;
      }

      if (form.flowlineLength === null || form.flowlineLength === undefined) {
        this.showWarning('Flowline length is a required field.');
        isValid = false;
      }

      if (form.gasLiftLength === null || form.gasLiftLength === undefined) {
        this.showWarning('GasLift length is a required field.');
        isValid = false;
      }

      if (form.genInfrLength === null || form.genInfrLength === undefined) {
        this.showWarning('Gen. Infr. length is a required field.');
        isValid = false;
      }

      if (form.tax === null || form.tax === undefined) {
        this.showWarning('Tax is a required field');
        isValid = false;
      }

      if (form.contingency === null || form.contingency === undefined) {
        this.showWarning('Contigency is a required field');
        isValid = false;
      }

      if (!this.formScopeAssumptions.valid && isValid) {
        this.showWarning('Please check the required fields in Scope Assumptions.');
        isValid = false;
      }
    }

    return isValid;
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'warning', icon: true },
      hideAfter: 1400,
    });
  }

  public loadForm() {
    if (this.scopeAssumptionsData) {
      this.formScopeAssumptions = new FormGroup({
        wellsPerTestSeparator: new FormControl(this.scopeAssumptionsData.wellsPerTestSeparator, [Validators.required]),
        rentalCompressors: new FormControl(this.scopeAssumptionsData.rentalId, [Validators.required]),
        coopCompressors: new FormControl(this.scopeAssumptionsData.coopId, [Validators.required]),
        fracPondType: new FormControl(this.scopeAssumptionsData.fracPondTypeId, [Validators.required]),
        swdInjectionDetail: new FormControl(this.scopeAssumptionsData.swdInjLineId, [Validators.required]),
        lpGasLineDetail: new FormControl(this.scopeAssumptionsData.lpGasLineId, [Validators.required]),
        hpGasLineDetail: new FormControl(this.scopeAssumptionsData.hpGasLineId, [Validators.required]),
        prodWaterLineDetail: new FormControl(this.scopeAssumptionsData.prodWaterLineId, [Validators.required]),
        liquidsTransferLineDetail: new FormControl(this.scopeAssumptionsData.liquidsTransferLineId, [
          Validators.required,
        ]),
        gasLiftDetail: new FormControl(this.scopeAssumptionsData.gasLiftLineId, [Validators.required]),
        flowlineDetail: new FormControl(this.scopeAssumptionsData.flowlineDetailId, [Validators.required]),
        flowlineLength: new FormControl(this.scopeAssumptionsData.flowlineLength, [Validators.required]),
        gasLiftLength: new FormControl(this.scopeAssumptionsData.gasLiftLength, [Validators.required]),
        genInfrLength: new FormControl(this.scopeAssumptionsData.generalInfLength, [Validators.required]),
        tax: new FormControl(this.scopeAssumptionsData.tax, [Validators.required]),
        contingency: new FormControl(this.scopeAssumptionsData.contingency, [Validators.required]),
      });
    }
  }

  public loadValuesFromForm() {
    const value: any = this.formScopeAssumptions.value;
    this.scopeAssumptionsData.wellsPerTestSeparator = value.wellsPerTestSeparator;
    this.scopeAssumptionsData.rentalId = value.rentalCompressors.value;
    this.scopeAssumptionsData.coopId = value.coopCompressors.value;
    this.scopeAssumptionsData.fracPondTypeId = value.fracPondType.value;
    this.scopeAssumptionsData.swdInjLineId = value.swdInjectionDetail.value;
    this.scopeAssumptionsData.lpGasLineId = value.lpGasLineDetail.value;
    this.scopeAssumptionsData.hpGasLineId = value.hpGasLineDetail.value;
    this.scopeAssumptionsData.prodWaterLineId = value.prodWaterLineDetail.value;
    this.scopeAssumptionsData.liquidsTransferLineId = value.liquidsTransferLineDetail.value;
    this.scopeAssumptionsData.gasLiftLineId = value.gasLiftDetail.value;
    this.scopeAssumptionsData.flowlineDetailId = value.flowlineDetail.value;
    this.scopeAssumptionsData.flowlineLength = value.flowlineLength;
    this.scopeAssumptionsData.gasLiftLength = value.gasLiftLength;
    this.scopeAssumptionsData.generalInfLength = value.genInfrLength;
    this.scopeAssumptionsData.tax = value.tax;
    this.scopeAssumptionsData.contingency = value.contingency;
  }

  public onChange(value: any, fieldName: string): void {
    if (this.scopeAssumptionsData && this.scopeAssumptionsData.hasOwnProperty(fieldName)) {
      this.scopeAssumptionsData[fieldName] = value.value ? value.value : value;
      this.scopeAssumptionsService.setValue(this.scopeAssumptionsData);
    }
  }

  public checkStateLogic(smartImportSequence: any[]): void {
    const smartImport = smartImportSequence && smartImportSequence.length > 0 ? smartImportSequence[0] : null;
    if (smartImport) {
      this.dasSimpleCalculationsService.setTaxesLogicToAssumptions(this.scopeAssumptionsData, smartImport);
      this.formScopeAssumptions.patchValue({
        tax: this.scopeAssumptionsData.tax,
        contingency: this.scopeAssumptionsData.contingency,
      });

      this.scopeAssumptionsService.setValue(this.scopeAssumptionsData);
    }
  }
  private checkIsNew(): void {
    const routeName = this.router.url.split('/');
    const bodRoute = routeName[3];
    if (bodRoute === 'New') {
      this.isNewDasSimple = true;
    }
  }

  private fillCombos(data: IDasSimpleCostsModel[]) {
    // Rental combo
    let itemArray = ['Rental 3516', 'Rental 3606', 'Rental 3608'];
    this.rentalCompressorsList = data
      .filter(item => itemArray.includes(item.name))
      .map(item => ({ text: item.name, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // COOP combo
    itemArray = ['COOP 3608', 'COOP 3616'];
    this.coopCompressorsList = data
      .filter(item => itemArray.includes(item.name))
      .map(item => ({ text: item.name, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // Frac type combo
    this.fracPondTypeList = data
      .filter(item => item.name === 'Frac Ponds')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // SWD injection line detail combo
    this.swdInjectionDetailList = data
      .filter(item => item.name === 'SWD Injection Line')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // LP gas line detail combo
    this.lpGasLineDetailList = data
      .filter(item => item.name === 'In-Field Gas Gathering/Sales, LP')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // HP gas line detail combo
    this.hpGasLineDetailList = data
      .filter(item => item.name === 'In-Field Gas Gathering/Sales, HP')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // Produced water gathering line detail combo
    this.prodWaterLineDetailList = data
      .filter(item => item.name === 'Produced Water Gathering')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // Liquids transfer line detail combo
    this.liquidsTransferLineDetailList = data
      .filter(item => item.name === 'In-Field Liquids Gathering from Satellite')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // Gas lift line detail combo
    this.gasLiftDetailList = data
      .filter(item => item.name === 'Gas Lift Distribution')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    // Flowline detail combo
    this.flowlineDetailList = data
      .filter(item => item.name === 'Flowline Materials and Installation')
      .map(item => ({ text: item.type, value: item.id }))
      .sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.text > b.text ? 1 : -1) : -1));

    if (this.isNewDasSimple) {
      // Fill combo defaults if it's a new das simple
      this.fillDasScopeAssumptionsCombos();
    } else if (this.scopeAssumptionsData.rentalId == '') {
      // Fill combo das simple inputs(scope assumptions section) with defaults if RentalId is empty
      this.fillDasScopeAssumptionsCombos();
    } else {
      // Fill combo with loaded values
      let itemDefault = this.rentalCompressorsList.find(item => item.value === this.scopeAssumptionsData.rentalId);
      this.formScopeAssumptions.get('rentalCompressors').setValue(itemDefault);

      itemDefault = this.coopCompressorsList.find(item => item.value === this.scopeAssumptionsData.coopId);
      this.formScopeAssumptions.get('coopCompressors').setValue(itemDefault);

      itemDefault = this.fracPondTypeList.find(item => item.value === this.scopeAssumptionsData.fracPondTypeId);
      this.formScopeAssumptions.get('fracPondType').setValue(itemDefault);

      itemDefault = this.swdInjectionDetailList.find(item => item.value === this.scopeAssumptionsData.swdInjLineId);
      this.formScopeAssumptions.get('swdInjectionDetail').setValue(itemDefault);

      itemDefault = this.lpGasLineDetailList.find(item => item.value === this.scopeAssumptionsData.lpGasLineId);
      this.formScopeAssumptions.get('lpGasLineDetail').setValue(itemDefault);

      itemDefault = this.hpGasLineDetailList.find(item => item.value === this.scopeAssumptionsData.hpGasLineId);
      this.formScopeAssumptions.get('hpGasLineDetail').setValue(itemDefault);

      itemDefault = this.prodWaterLineDetailList.find(item => item.value === this.scopeAssumptionsData.prodWaterLineId);
      this.formScopeAssumptions.get('prodWaterLineDetail').setValue(itemDefault);

      itemDefault = this.liquidsTransferLineDetailList.find(
        item => item.value === this.scopeAssumptionsData.liquidsTransferLineId
      );
      this.formScopeAssumptions.get('liquidsTransferLineDetail').setValue(itemDefault);

      itemDefault = this.gasLiftDetailList.find(item => item.value === this.scopeAssumptionsData.gasLiftLineId);
      this.formScopeAssumptions.get('gasLiftDetail').setValue(itemDefault);

      itemDefault = this.flowlineDetailList.find(item => item.value === this.scopeAssumptionsData.flowlineDetailId);
      this.formScopeAssumptions.get('flowlineDetail').setValue(itemDefault);

      this.formScopeAssumptions.get('wellsPerTestSeparator').setValue(this.scopeAssumptionsData.wellsPerTestSeparator);
      this.formScopeAssumptions.get('flowlineLength').setValue(this.scopeAssumptionsData.flowlineLength);
      this.formScopeAssumptions.get('gasLiftLength').setValue(this.scopeAssumptionsData.gasLiftLength);
      this.formScopeAssumptions.get('genInfrLength').setValue(this.scopeAssumptionsData.generalInfLength);
      this.formScopeAssumptions.get('tax').setValue(this.scopeAssumptionsData.tax);
      this.formScopeAssumptions.get('contingency').setValue(this.scopeAssumptionsData.contingency);

      this.formScopeAssumptions.clearValidators();
    }
  }

  private fillDasScopeAssumptionsCombos() {
    let itemDefault = this.rentalCompressorsList.find(item => item.text === 'Rental 3608');
    this.scopeAssumptionsData.rentalId = itemDefault.value;
    this.formScopeAssumptions.get('rentalCompressors').setValue(itemDefault);

    itemDefault = this.coopCompressorsList.find(item => item.text === 'COOP 3608');
    this.scopeAssumptionsData.coopId = itemDefault.value;
    this.formScopeAssumptions.get('coopCompressors').setValue(itemDefault);

    itemDefault = this.fracPondTypeList.find(item => item.text === 'RWCS');
    this.scopeAssumptionsData.fracPondTypeId = itemDefault.value;
    this.formScopeAssumptions.get('fracPondType').setValue(itemDefault);

    itemDefault = this.swdInjectionDetailList.find(item => item.text === '10" XH Zap (Int. Coat)');
    this.scopeAssumptionsData.swdInjLineId = itemDefault.value;
    this.formScopeAssumptions.get('swdInjectionDetail').setValue(itemDefault);

    itemDefault = this.lpGasLineDetailList.find(item => item.text === '16" STD Welded CS');
    this.scopeAssumptionsData.lpGasLineId = itemDefault.value;
    this.formScopeAssumptions.get('lpGasLineDetail').setValue(itemDefault);

    itemDefault = this.hpGasLineDetailList.find(item => item.text === '10" XH Welded CS');
    this.scopeAssumptionsData.hpGasLineId = itemDefault.value;
    this.formScopeAssumptions.get('hpGasLineDetail').setValue(itemDefault);

    itemDefault = this.prodWaterLineDetailList.find(item => item.text === '16" SDR7 HDPE');
    this.scopeAssumptionsData.prodWaterLineId = itemDefault.value;
    this.formScopeAssumptions.get('prodWaterLineDetail').setValue(itemDefault);

    itemDefault = this.liquidsTransferLineDetailList.find(item => item.text === '12" XH Zap (Int. Coat)');
    this.scopeAssumptionsData.liquidsTransferLineId = itemDefault.value;
    this.formScopeAssumptions.get('liquidsTransferLineDetail').setValue(itemDefault);

    itemDefault = this.gasLiftDetailList.find(item => item.text === '6" XH Zap (Int. Bare)');
    this.scopeAssumptionsData.gasLiftLineId = itemDefault.value;
    this.formScopeAssumptions.get('gasLiftDetail').setValue(itemDefault);

    itemDefault = this.flowlineDetailList.find(item => item.text === '4" Flex (Flowlines)');
    this.scopeAssumptionsData.flowlineDetailId = itemDefault.value;
    this.formScopeAssumptions.get('flowlineDetail').setValue(itemDefault);
    this.scopeAssumptionsService.setValue(this.scopeAssumptionsData);
    this.formScopeAssumptions.clearValidators();
  }
}
