import { Component, Input, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process} from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { LogsBodService } from '../_services/logs-bod.service';
import { map } from 'rxjs/operators/map';

@Component({
  selector: 'app-logs-bod',
  templateUrl: './logs-bod.component.html',
  styleUrls: ['./logs-bod.component.css']
})
export class LogsBodComponent implements OnInit {
  public view: Observable<GridDataResult>;
  @Input() public bodId: string;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };
  public isLoading;
  constructor(private logBodService:LogsBodService) { }

  ngOnInit() {
    
    this.logBodService.getLogsByBodId(this.bodId).subscribe( data => {
      this.view = this.logBodService.pipe(map(data => process(data, this.gridState)));
      this.logBodService.read();
    });
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.logBodService.read();
  }

  // public checkBodLogs(bodId: string){
  //   this.view = this.logBodService.getLogsByBodId(bodId).pipe(map(data => process(data, this.gridState)));
  // }

}
