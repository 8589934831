import { FitCcBaseSerializer } from '../_shared/fit-common-components-lib/models/fit-cc-base-serializer';
import {
  BasinModel,
  DevelopmentAreaModel,
  StateModel,
  GeographicAreaModel,
  CompressorCapacityAssumptionsModel,
  LandDamagesPipesModel,
  LandDamagesModel,
  PadContingencyAssumptionsModel,
  PrctItemDetailModel,
  PrctItemModel,
  ItemTypeModel,
  PrctInfrastructureClassModel,
  PrctInfraestructureScopeCategoryModel,
  RfsdTimingModel,
  KindModel,
  LocationModel,
  UnitOfMeasureModel,
  FacilityItemsAndYearlyCostMdtModel,
} from './master-data';
import { IFacilityItemType } from './facility-item-type-model';

export class BasinSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): BasinModel[] {
    return json;
  }

  toJson(data: BasinModel, isNew: boolean): any {
    const item = new BasinModel();
    if (!isNew) {
      item.id = data.id;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.userCai;
      item.createdDate = data.createdDate;
      item.createdBy = data.createdBy;
    } else {
      item.createdDate = new Date(Date.now());
      item.createdBy = this.userCai;
      item.updatedDate = null;
      item.updatedBy = null;
    }
    item.name = data.name;
    item.effectiveDate = new Date(data.effectiveDate);
    item.basinAbbreviation = data.basinAbbreviation;
    item.isDelete = false;
    return item;
  }
}

export class DevelopmentAreaSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }
  devArea = new DevelopmentAreaModel();

  fromJson(json: any[]): DevelopmentAreaModel[] {
    return json;
  }

  toJson(data: DevelopmentAreaModel, isNew: boolean): any {
    const item = new DevelopmentAreaModel();
    if (!isNew) {
      item.id = data.id;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.userCai;
      item.createdDate = data.createdDate;
      item.createdBy = data.createdBy;
    } else {
      item.createdDate = new Date(Date.now());
      item.createdBy = this.userCai;
      item.updatedDate = null;
      item.updatedBy = null;
    }
    item.name = data.name;
    item.effectiveDate = new Date(data.effectiveDate);

    item.entityState = 0;
    item.developmentAreaAbbreviation = data.developmentAreaAbbreviation;
    item.unitRateArea1 = data.unitRateArea1;
    item.mudiDevArea1 = data.mudiDevArea1;
    item.stateId = data.stateId;
    item.geographicAreaId = data.geographicAreaId;
    return item;
  }
}

export class StateSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }
  fromJson(json: any[]): StateModel[] {
    return json;
  }

  toJson(data: StateModel, isNew: boolean): any {
    const item = new StateModel();

    const itemSer = Object.assign(item, data);

    if (!isNew) {
      item.id = data.id;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.userCai;
      item.createdDate = data.createdDate;
      item.createdBy = data.createdBy;
    } else {
      item.createdDate = new Date(Date.now());
      item.createdBy = this.userCai;
      item.updatedDate = null;
      item.updatedBy = null;
    }
    item.name = data.name;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.isDelete = false;

    item.stateAbbreviation = data.stateAbbreviation;
    item.taxRate = data.taxRate;
    return itemSer;
  }
}

export class GeographicAreaSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): GeographicAreaModel[] {
    return json;
  }

  toJson(data: GeographicAreaModel, isNew: boolean): any {
    const item = new GeographicAreaModel();
    if (!isNew) {
      item.id = data.id;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.userCai;
      item.createdDate = data.createdDate;
      item.createdBy = data.createdBy;
    } else {
      item.createdDate = new Date(Date.now());
      item.createdBy = this.userCai;
      item.updatedDate = null;
      item.updatedBy = null;
    }
    item.name = data.name;
    item.effectiveDate = data.effectiveDate;

    item.geographicAreaAbbreviation = data.geographicAreaAbbreviation;
    item.basinId = data.basinId;
    item.basin = data.basin;
    item.developmentArea = data.developmentArea;
    return item;
  }
}

export class CompressorCapacityAssumptionsSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): CompressorCapacityAssumptionsModel[] {
    return json;
  }

  toJson(data: CompressorCapacityAssumptionsModel, isNew: boolean): any {
    const item = new CompressorCapacityAssumptionsModel();

    item.compressorType = data.compressorType;
    item.suctionPressure = data.suctionPressure;
    item.fuelGas = data.fuelGas;
    item.compressorGasRateCapacity =
      data.compressorGasRateCapacity !== null ? parseInt(data.compressorGasRateCapacity.toFixed()) : null;

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;
    return item;
  }
}

export class LandDamagesPipesSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): LandDamagesPipesModel[] {
    return json;
  }

  toJson(data: LandDamagesPipesModel, isNew: boolean): any {
    const item = new LandDamagesPipesModel();

    item.nominalPipeSize = data.nominalPipeSize;
    item.landDamagesCostPerRod = data.landDamagesCostPerRod;
    item.landDamagesCostPerFoot = data.landDamagesCostPerFoot;
    item.comments = data.comments;

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;
    return item;
  }
}

export class LandDamagesSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): LandDamagesModel[] {
    return json;
  }

  toJson(data: LandDamagesModel, isNew: boolean): any {
    const item = new LandDamagesModel();

    item.scope = data.scope;
    item.unitCost = data.unitCost == null ? '' : data.unitCost;
    item.unitOfMeasureId = data.unitOfMeasureId == null ? '' : data.unitOfMeasureId;
    item.comments = data.comments;

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;

    return item;
  }
}

export class PadContingencyAssumptionsSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): PadContingencyAssumptionsModel[] {
    return json;
  }

  toJson(data: PadContingencyAssumptionsModel, isNew: boolean): any {
    const item = new PadContingencyAssumptionsModel();

    item.estimateDescription = data.estimateDescription;
    item.numberOfDevWells = data.numberOfDevWells;
    item.estimateTimeFrame = data.estimateTimeFrame;
    item.contingencyPercentage = data.contingencyPercentage;

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;

    return item;
  }
}

export class PrctItemDetailSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): PrctItemDetailModel[] {
    return json;
  }

  toJson(data: PrctItemDetailModel, isNew: boolean): any {
    const item = new PrctItemDetailModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    item.itemTypeId = data.itemTypeId;
    item.itemDetailNotes = data.itemDetailNotes;
    return item;
  }
}

export class PrctItemSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): PrctItemModel[] {
    const items = [];

    json.forEach(element => {
      let item = new PrctItemModel();
      item = element;

      items.push(item);
    });
    return items;
  }

  toJson(data: PrctItemModel, isNew: boolean): any {
    let item = new PrctItemModel();
    if (!isNew) {
      item.id = data.id;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.userCai;
      item.createdDate = data.createdDate;
      item.createdBy = data.createdBy;
    } else {
      item.createdDate = new Date(Date.now());
      item.createdBy = this.userCai;
      item.updatedDate = null;
      item.updatedBy = null;
    }

    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.entityState = 0;
    item.itemDetailId = data.itemDetailId;
    item.itemDetailName = data.itemDetailName;
    item.infrastructureClassId = data.infrastructureClassId;
    item.infrastructureClassName = data.infrastructureClassName;
    item.locationId = data.locationId;
    item.locationName = data.locationName;
    item.infraestructureScopeCategoryId = data.infraestructureScopeCategoryId;
    item.infraestructureScopeCategoryName = data.infraestructureScopeCategoryName;
    item.rfsdTimingId = data.rfsdTimingId;
    item.rfsdTimingName = data.rfsdTimingName;
    item.kindId = data.kindId;
    item.kindName = data.kindName;
    item.itemTypeId = data.itemTypeId;

    item.itemNotes = data.itemNotes;
    item.unitOfMeasure = data.unitOfMeasure;
    item.respToExpandPrctItemId = data.respToExpandPrctItemId;
    item.OrdenItem = data.OrdenItem;
    return item;
  }
}

export class ItemTypeSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): ItemTypeModel[] {
    return json;
  }

  toJson(data: ItemTypeModel, isNew: boolean): any {
    const item = new ItemTypeModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class PrctInfrastructureClassSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): PrctInfrastructureClassModel[] {
    return json;
  }

  toJson(data: PrctInfrastructureClassModel, isNew: boolean): any {
    const item = new ItemTypeModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class PrctInfraestructureScopeCategorySerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): PrctInfraestructureScopeCategoryModel[] {
    return json;
  }

  toJson(data: PrctInfraestructureScopeCategoryModel, isNew: boolean): any {
    const item = new PrctInfraestructureScopeCategoryModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class RfsdTimingSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): RfsdTimingModel[] {
    return json;
  }

  toJson(data: RfsdTimingModel, isNew: boolean): any {
    const item = new RfsdTimingModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class LocationSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): LocationModel[] {
    return json;
  }

  toJson(data: LocationModel, isNew: boolean): any {
    const item = new LocationModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class KindSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): KindModel[] {
    return json;
  }

  toJson(data: KindModel, isNew: boolean): any {
    const item = new KindModel();
    if (!isNew) {
      item.id = data.id;
    }
    item.name = data.name == null ? '' : data.name;
    item.displayName = data.displayName;
    item.effectiveDate = data.effectiveDate;
    item.expiryDate = data.expiryDate;
    item.activeInd = data.activeInd;
    item.isDelete = false;
    item.createdBy = data.createdBy;
    item.createdDate = data.createdDate;
    item.updatedBy = data.updatedBy;
    item.updatedDate = data.updatedDate;
    item.entityState = 0;
    return item;
  }
}

export class UnitOfMeasureSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): UnitOfMeasureModel[] {
    return json;
  }

  toJson(data: UnitOfMeasureModel, isNew: boolean): any {
    const item = new UnitOfMeasureModel();

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;
    item.isDelete = false;

    return item;
  }
}

export class FacilityItemTypeSerializer implements FitCcBaseSerializer {
  constructor(private userCai: string) {
    this.userCai = this.userCai.toUpperCase();
  }

  fromJson(json: any[]): UnitOfMeasureModel[] {
    return json;
  }

  toJson(data: UnitOfMeasureModel, isNew: boolean): any {
    const item = new UnitOfMeasureModel();

    if (isNew) {
      item.createdBy = this.userCai;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = this.userCai;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.entityState = 0;
    item.isDelete = false;

    return item;
  }
}


export class FacilityItemsAndYearlyCostMdtSerializer implements FitCcBaseSerializer {
  fromJson(json: any[]): FacilityItemsAndYearlyCostMdtModel[] {
    const items = [];
    json.forEach(element => {
      let item = new FacilityItemsAndYearlyCostMdtModel();
      item = element;
      items.push(item);
    });
    return items;
  }

  toJson(data: FacilityItemsAndYearlyCostMdtModel, isNew: boolean): any {
    const item = new FacilityItemsAndYearlyCostMdtModel();
    if (isNew) {
      item.createdBy = data.createdBy;
      item.createdDate = new Date(Date.now());
    } else {
      item.id = data.id;
      item.createdBy = data.createdBy.toUpperCase();
      item.createdDate = data.createdDate;
      item.updatedBy = data.updatedBy;
      item.updatedDate = new Date(Date.now());
    }

    item.name = data.name == null ? '' : data.name;
    item.type = data.type == null ? '' : data.type;
    item.year = data.year == null ? '' : data.year;
    item.costPunit = data.costPunit == null ? 0 : data.costPunit;
    item.itemNotes = data.itemNotes == null ? '' : data.itemNotes;
    item.facilityYearlyCostItemsId = data.facilityYearlyCostItemsId;
    item.unitOfMeasureName = data.unitOfMeasureName == null ? '' : data.unitOfMeasureName;

    item.entityState = 0;
    item.isDelete = false;

    return item;
  }
}
