<div class="k-pager-wrap k-grid-pager k-widget">
  <div>
    <a href="#" tabindex="-1" class="k-link k-pager-nav k-pager-first" (click)="goToFirstPage($event)" [class.k-state-disabled]="!firstPageEnabled" title="Go to the first page">
      <span data-page-button="firstPage" class="k-icon k-i-seek-w" aria-label="Go to the first page"></span>
    </a><a href="#" tabindex="-1" class="k-link k-pager-nav" (click)="goToPreviousPage($event)" [class.k-state-disabled]="!previousPageEnabled" title="Go to the previous page">
      <span data-page-button="previousPage" class="k-icon k-i-arrow-w" aria-label="Go to the previous page"></span>
    </a>
  </div>
  <div>
    <ul class="k-pager-numbers k-reset">
      <ng-container *ngFor="let page of pages">
        <li>
          <a href="#" id="page.id" tabindex="-1" class="k-link" (click)="pageChange($event)" [class.k-state-selected]="currentPage === page"> {{page}} </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div>
    <a href="#" tabindex="-1" class="k-link k-pager-nav" (click)="goToNextPage($event)" [class.k-state-disabled]="!nextPageEnabled" title="Go to the next page">
      <span data-page-button="nextPage" class="k-icon k-i-arrow-e" aria-label="Go to the next page"></span>
    </a>
    <a href="#" tabindex="-1" class="k-link k-pager-nav k-pager-last" (click)="goToLastPage($event)" [class.k-state-disabled]="!lastPageEnabled" title="Go to the last page">
      <span data-page-button="lastPage" class="k-icon k-i-seek-e" aria-label="Go to the last page"></span>
    </a>
  </div>
  <div class="k-pager-sizes">
    <kendo-dropdownlist [data]="dpPages"
                        [(ngModel)]="selectedPageSize"
                        (valueChange)="changePageSize($event)">
    </kendo-dropdownlist><label> pads per page </label>
  </div>
  <div class="k-pager-info k-label">{{(currentPage - 1) * pageSize + 1}} - {{currentPage * pageSize > total ? total : currentPage * pageSize}} of {{total}} pads</div>
</div>
