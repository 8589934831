import { Component, Input, OnInit } from '@angular/core';
import { ScenarioStoreService } from '../../../services/scenario-store.service';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.css'],
})
export class PagerComponent implements OnInit {
  pageSize: number;
  firstPageEnabled: boolean;
  lastPageEnabled: boolean;
  previousPageEnabled: boolean;
  nextPageEnabled: boolean;
  currentPage: number;
  pages: number[];
  total: number;
  newScenario: boolean;
  selectedPageSize: string = '5';
  public dpPages: string[] = ['5', '10', '25', '50', '75', '100', 'All'];
  @Input() componentName : string = '';

  constructor(private scenarioStoreService: ScenarioStoreService) {}

  ngOnInit() {
    this.scenarioStoreService.newScenario$.subscribe(value => (this.newScenario = value));
    this.scenarioStoreService.padPager$.subscribe(data => {
      this.pageSize = this.selectedPageSize === 'All' ? data.total : Number(this.selectedPageSize); //this.newScenario === false ? data.pageSize : 0;
      this.pages = this.newScenario === false ? data.pages : [];
      this.currentPage = this.newScenario === false ? data.currentPage : 0;
      this.total = this.newScenario === false ? data.total : 0;
      this.firstPageEnabled = this.newScenario === false ? data.firstPageEnabled : false;
      this.previousPageEnabled = this.newScenario === false ? data.previousPageEnabled : false;
      this.nextPageEnabled = this.newScenario === false ? data.nextPageEnabled : false;
      this.lastPageEnabled = this.newScenario === false ? data.lastPageEnabled : false;
    });
  }

  changePageSize(event): void {
    if (event === 'All') {
      this.pageSize = this.total;
    } else {
      this.pageSize = event;
    }
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }

  pageChange(event): void {
    event.preventDefault();
    this.currentPage = parseInt(event.target.textContent);
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }

  goToFirstPage(event): void {
    event.preventDefault();
    this.currentPage = 1;
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }

  goToPreviousPage(event): void {
    event.preventDefault();
    this.currentPage = this.currentPage - 1;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }

  goToNextPage(event): void {
    event.preventDefault();
    this.currentPage = this.currentPage + 1;
    if (this.currentPage > this.pages[this.pages.length - 1]) {
      this.currentPage = this.pages[this.pages.length - 1];
    }
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }

  goToLastPage(event): void {
    event.preventDefault();
    this.currentPage = this.pages[this.pages.length - 1];
    this.scenarioStoreService.changePadPage(this.currentPage, this.pageSize);
  }
}
