import { Component, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpProgressEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { delay } from 'rxjs/operators/delay';
import { FileInfo, UploadEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { HtmlParser } from '@angular/compiler';
import * as Helpers from 'src/app/_shared/helpers';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  
})
export class UploadFileComponent {
  public uploadSaveUrl = ''; // should represent an actual API endpoint
  public uploadRemoveUrl = ''; // should represent an actual API endpoint
  public files: FileInfo[];
  public restrictions: FileRestrictions = {
    allowedExtensions: [
      /*Images*/ '.jpeg',
      '.jpg',
      '.gif',
      '.png',
      '.bmp',
      /*Text file*/ '.txt',
      '.pdf',
      /*Microsoft files*/ '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ppt',
      '.pptx',
      '.pub',
      '.one',
      '.mpp',
    ],
    maxFileSize: 50000000 /*Giving the customer 50 mb max file size*/
  };
  private numberOfFiles = 0;
  private uploadCounter = -1;

  constructor(private dialog: DialogRef) {}

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onConfirmAction(): void {
    const responseData = this.files.map((item) => {
      const  value: FileInfo =  Object.assign({}, item);
      value.name = Helpers.decodeEntities(item.name);
      return value;
    });

    this.dialog.close(responseData);
  }

  public isDisabled(): boolean {
    return this.numberOfFiles === this.uploadCounter;
  }

  public uploadEventHandler(e: UploadEvent) {
    this.numberOfFiles+= e.files.length;
    this.uploadCounter = 0;

    const requestData = e.files.map((item) => {
      const  value: FileInfo =  Object.assign({}, item);
      value.name = Helpers.decodeEntities(item.name);
      return value;
    });

    e.data = {
      filesArray: JSON.stringify(requestData)
    };
  }

  public successEventHandler(e: SuccessEvent) {
    this.uploadCounter++;
  }

  public errorEventHandler(e: ErrorEvent) {
    this.uploadCounter++;
  }

}

// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === '/api/Upload/Notes/Attachments') {
      const events: Array<Observable<HttpEvent<any>>> = [0, 30, 60, 100].map(x =>
        of({
          type: HttpEventType.UploadProgress,
          loaded: x,
          total: 100,
        } as HttpProgressEvent).pipe(delay(1000))
      );

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === '/api/Upload/Notes/Remove') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
