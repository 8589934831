import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-panel-performance-bod',
  templateUrl: './panel-performance-bod.component.html',

})

export class PanelPerformanceBodComponent implements OnInit {
  @Output() public performanceBodUpdate = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  public onPerformanceBodUpdate(cai: string): void {
    this.performanceBodUpdate.next(cai);
  }
}
