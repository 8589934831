<kendo-grid
  #performanceAfe
  [data]="view | async"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="true"
  (dataStateChange)="onStateChange($event)"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  style="min-height: 250px"
  [loading]="isLoading"
  [appInCellTab]="createFormGroup"
  [selectable]="selectableSettings"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand [primary]="true" [disabled]="!hasPermissionToEdit">Add AFE</button>
  </ng-template>
  <kendo-grid-column field="name" editor="text" [editable]="hasPermissionToEdit" title="Name"> </kendo-grid-column>
  <kendo-grid-column field="number" editor="text" [editable]="hasPermissionToEdit" title="AFE No.">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ convertToAfeFormat(dataItem.number) }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-maskedtextbox [value]="dataItem.number" [mask]="afeMask" [formControl]="formGroup.get('number')">
      </kendo-maskedtextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="amount"
    editor="numeric"
    [editable]="hasPermissionToEdit"
    title="AFE Amount"
    [format]="{ n: 2 }"
  ></kendo-grid-column>
  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="id" editor="numeric" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="performanceBodId" editor="text" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="createdBy" title="Created By" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="createdDate" title="Created Date" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="updatedBy" title="Updated By" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="updatedDate" title="Updated Date" [hidden]="true"></kendo-grid-column>
  <kendo-grid-command-column title="" width="300px">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [disabled]="!hasPermissionToEdit">Remove</button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<div kendoDialogContainer #removeAFEDialog></div>
