<div id="admin-content">
  <div class="card-body">
    <kendo-grid
      [data]="gridData | async"
      [filter]="state.filter"
      [filterable]="true"
      (dataStateChange)="onDataStateChange($event)"
      (edit)="openHandler($event)"
      [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [rowClass]="rowCallback"
      style="height: auto; overflow-x: hidden;"
      [loading]="cndListService.isLoading"
    >
      <kendo-grid-column field="name" title="Scenario Name"></kendo-grid-column>
      <kendo-grid-column field="developmentAreaName" title="Development Area"></kendo-grid-column>
      <kendo-grid-column field="scenarioTypeName" title="Scenario Type"></kendo-grid-column>
      <kendo-grid-column field="isActiveForOutlook" title="Active for Outlook?"></kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Created Date" filter="date" format="{0:d}"></kendo-grid-column>
      <kendo-grid-column field="createdBy" title="Created By"></kendo-grid-column>
      <kendo-grid-column field="updatedDate" title="Updated Date" filter="date" format="{0:d}"></kendo-grid-column>
      <kendo-grid-column field="updatedBy" title="Updated By"></kendo-grid-column>
      <kendo-grid-command-column title="" width="220">
        <ng-template kendoGridCellTemplate let-dataItem>
         
          <a [routerLink]="['/Scenario/id',dataItem.id]" target="_blank" class="k-button k-button-md k-rounded-md k-button-solid-primary k-button-solid" style="color: #fff;"> Open</a>
          <button
            kendoGridRemoveCommand
            [primary]="true"
            (click)="openDelete(dataItem)"
            [hidden]="hiddenAdmin"
            [disabled]="!isAdmin"
            style="background:red"
          >
            Delete
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
  <div class="example-wrapper">
    <kendo-dialog
      title="Confirmation Deletion"
      *ngIf="openedDelete"
      (close)="closeDelete('cancel')"
      [minWidth]="250"
      [width]="450"
    >
      <p style="margin: 30px; text-align: center;">Are you sure you want to delete the selected project(s)?</p>
      <kendo-dialog-actions>
        <button kendoButton (click)="removeHandler(dataItem)">Yes</button>
        <button kendoButton (click)="closeDelete('cancel')">No</button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
</div>
