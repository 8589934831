import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Renderer2, NgZone, RendererFactory2 } from '@angular/core';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InCellTabDirective } from './modules/cAndD/input-items/incell-tab.directive';

import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { TabStripModule, SplitterModule, PanelBarModule, LayoutModule } from '@progress/kendo-angular-layout';
import { MenuModule } from '@progress/kendo-angular-menu';
import { DropDownsModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadModule, UploadsModule } from '@progress/kendo-angular-upload';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { HeaderComponent } from './_shared/header/header.component';
import { SummaryComponent } from './modules/cAndD/summary/summary.component';
import { InputComponent } from './modules/cAndD/input/input.component';
import { ContingencyComponent } from './modules/cAndD/contingency/contingency.component';
import { InputSummaryComponent } from './modules/cAndD/input-summary/input-summary.component';
import { InputFacilityAssignmentComponent } from './modules/cAndD/input-facility-assignment/input-facility-assignment.component';
import { InputItemsComponent } from './modules/cAndD/input-items/input-items.component';
import { AuthenticationService, initApp } from './services/authentication.service';
import * as interceptors from './_interceptors';
import { PricetToolComponent } from './modules/cAndD/pricet/pricet-tool.component';
import { UserPermissionsService } from './services/user-permission.service';
import { PopupAnchorDirective } from './_directives/popup.anchor-target.directive';
import { InputSummaryUploadComponent } from './modules/cAndD/input-summary/input-summary-upload.component';
import { ProgressBarIndeterminateExampleComponent } from './_shared/progress-bar/progress-bar.component';
import { AppRoutingModule } from './/app-routing.module';
import { PricetToolIndexComponent } from './modules/cAndD/pricet/pricet-tool-index.component';
import { PricetToolOpenComponent } from './modules/cAndD/pricet/pricet-tool-open.component';
import { CanDeactivateGuard } from './_guards/can-deactivate/CanDeactivateGuard';
import { AuthGuard } from './_guards/auth.guard';
import { NotAuthorizedComponent } from './_shared/notauthorized/not-authorized.component';
import { PermissionsProvider, initApp2 } from './services/permissions.provider';
import { HelpComponent } from './_shared/help/help.component';

import { PagerComponent } from './modules/cAndD/pager/pager.component';
import { OpenScenarioComponent } from './modules/cAndD/open-scenario/open-scenario.component';
import { CostSheetComponent } from './modules/cAndD/cost-sheet/cost-sheet.component';
import { ReportOutComponent } from './modules/cAndD/reportout/reportout.component';
import { PadbypadSummaryComponent } from './modules/cAndD/summary/padbypad-summary.component';
import { FitCcBaseService } from './_shared/fit-common-components-lib/lib/fit-cc-base.service';
import { ScenarioDataService } from './services/scenario-data.service';
import {
  BasinService,
  StateService,
  GeographicAreaService,
  CompressorCapacityAssumptionsService,
  LandDamagesService,
  LandDamagesPipesService,
  PadContingencyAssumptionsService,
  PrctItemDetailService,
  DevelopmentAreaService,
  ItemTypeService,
  PrctInfrastructureClassService,
  LocationMDTService,
  PrctInfraestructureScopeCategoryService,
  RfsdTimingService,
  KindService,
  PrctItemService,
  UnitOfMeasureService,
  FacilityItemsAndYearlyCostMdtService,
  FacilityItemTypeService,
  FacilityTypeService,
} from './services/master-data.service';
import { ExportDataComponent } from './modules/cAndD/export-data/export-data.component';
import { ManageScenarioDataComponent } from './modules/cAndD/manage-scenario-data/manage-scenario-data.component';
import { ReportDashboardComponent } from './modules/cAndD/report-dashboard/report-dashboard.component';
import { RequiredValidator } from './_directives/required.directive';
import { SummaryNotesAttachmentComponent } from './modules/performance-bod/summary-notes-attachment/summary-notes-attachment.component';
import { UploadFileComponent } from './modules/performance-bod/upload-file/upload-file.component';
import { RedirectGuard } from './_guards/redirect-guard';
import { NotFoundInterceptor } from './_interceptors/404-interceptor';
import { ScenarioStoreService } from './services/scenario-store.service';
import { SharedModule } from './_shared/shared.module';
import { DragAndDropService } from './_shared/drag-and-drop.service';
import { initConfiguration, PerformanceConfigurationService } from './services/performance-configuration-service';
import { TabContentLoadOnDemandDirective } from './_directives/lazyload.directive';
import { DasSimpleListComponent } from './modules/cAndD/das-simple-list/das-simple-list.component';
import { DasSimpleService } from './services/das-simple.service';
import { DasSimpleSummaryComponent } from './modules/cAndD/das-simple-summary/das-simple-summary.component';
import { DasSimpleDetailsComponent } from './modules/cAndD/das-simple-details/das-simple-details.component';
import { DasSimpleListEditService } from './modules/cAndD/das-simple-list/das-simple-list-edit.service';
import { DasSimpleScopeCountComponent } from './modules/cAndD/das-simple-scope-count/das-simple-scope-count.component';
import { DasSimpleScopeCountEditService } from './modules/cAndD/das-simple-scope-count/das-simple-scope-count-edit.service';
import { SmartImportSequenceService } from './services/smart-import-sequence.service';
import { DasSimpleCalculationsService } from './services/das-simple-calculations.service';
import { DasSimpleScopeAssumptionsComponent } from './modules/cAndD/das-simple-scope-assumptions/das-simple-scope-assumptions.component';
import { UnitRateAreaService } from './_shared/_services/unit.rate.area.service';
import { Globals } from './_shared/Globals';
import { PerformanceBodModule } from './modules/performance-bod/performance-bod.module';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { CndToolBarComponent } from './modules/cAndD/cnd-tool-bar/cnd-tool-bar.component';
import { FitUnsavedChangesGuard } from './_guards/fit-unsaved-changes.guard';
import { CndListService } from './modules/cAndD/open-scenario/cnd-list.service';
import { DataService } from './services/data.service';
import { PadBuilderEditService } from './modules/cAndD/input-summary/active-for-outlook/pad-builder-data-edit-service';
import { EditPadsComponent } from './modules/cAndD/edit-pads/edit-pads.component';
import { EditPadsService } from './modules/cAndD/edit-pads/edit-pads.service';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { LabelModule } from '@progress/kendo-angular-label';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DeletePadsComponent } from './modules/cAndD/input-summary/delete-pads/delete-pads.component';
import { CopyPadsComponent } from './modules/cAndD/input-summary/copy-pads/copy-pads.component';
import { ActiveForOutlookComponent } from './modules/cAndD/input-summary/active-for-outlook/active-for-outlook.component';
import { ImportFromSmartComponent } from './modules/cAndD/input-summary/import-from-smart/import-from-smart.component';
import { SelectPadsAfterSmartImportComponent } from './modules/cAndD/input-summary/select-pads-after-smart-import/select-pads-after-smart-import.component';
import { ImportPadBuilderComponent } from './modules/cAndD/input-summary/import-pad-builder/import-pad-builder.component';
import { CostByPadDialogComponent } from './modules/cAndD/cost-by-pad-dialog/cost-by-pad-dialog.component';
import { SearchCostComponent } from './modules/cAndD/cost-by-pad-dialog/search-cost/search-cost.component';
import { CostAssignationComponent } from './modules/cAndD/cost-by-pad-dialog/cost-assignation/cost-assignation.component';
import { CostByPadDialogService } from './modules/cAndD/cost-by-pad-dialog/cost-by-pad-dialog.service';
import { ImportMassExcelBuilderComponent } from './modules/cAndD/input-summary/import-mass-excel/import-mass-excel.component';
import { CostSheetComponentPager } from './modules/cAndD/pager/cost-sheet-pager/cost-sheet.component';
import { FacilityAssignmentComponentPager } from './modules/cAndD/pager/facility-assignment/facility-assignment.component';
import { PadbypadSummaryComponentPager } from './modules/cAndD/pager/padbypad-summary/padbypad-summary.component';
import { ScopeCountComponentPager } from './modules/cAndD/pager/scope-count/scope-count.component';
import { ScenarioDataComponent } from './modules/cAndD/scenario-data/scenario-data.component';
import { AppScenarioDataExpansionComponent } from './modules/cAndD/scenario-data/app-scenario-data-expansion/app-scenario-data-expansion.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SummaryComponent,
    InputComponent,
    ContingencyComponent,
    InputSummaryComponent,
    InputFacilityAssignmentComponent,
    InputItemsComponent,
    PricetToolComponent,
    PopupAnchorDirective,
    InputSummaryUploadComponent,
    ProgressBarIndeterminateExampleComponent,
    PricetToolIndexComponent,
    PricetToolOpenComponent,
    PagerComponent,
    OpenScenarioComponent,
    CostSheetComponent,
    ReportOutComponent,
    RequiredValidator,
    PadbypadSummaryComponent,
    NotAuthorizedComponent,
    InCellTabDirective,
    HelpComponent,
    ExportDataComponent,
    ManageScenarioDataComponent,
    ReportDashboardComponent,
    TabContentLoadOnDemandDirective,
    DasSimpleListComponent,
    DasSimpleSummaryComponent,
    DasSimpleDetailsComponent,
    DasSimpleScopeCountComponent,
    DasSimpleScopeAssumptionsComponent,
    CndToolBarComponent,
    EditPadsComponent,
    DeletePadsComponent,
    CopyPadsComponent,
    ActiveForOutlookComponent,
    ImportFromSmartComponent,
    SelectPadsAfterSmartImportComponent,
    ImportPadBuilderComponent,
    CostByPadDialogComponent,
    SearchCostComponent,
    CostAssignationComponent,
    ImportMassExcelBuilderComponent,
    CostSheetComponentPager,
    FacilityAssignmentComponentPager,
    PadbypadSummaryComponentPager,
    ScopeCountComponentPager,
    ScenarioDataComponent,
    AppScenarioDataExpansionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GridModule,
    ExcelModule,
    TabStripModule,
    SplitterModule,
    PanelBarModule,
    DropDownsModule,
    ComboBoxModule,
    InputsModule,
    TooltipModule,
    DateInputsModule,
    PopupModule,
    UploadModule,
    DialogsModule,
    NotificationModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    //FitCommonComponentsLibModule,
    ButtonsModule,
    WindowModule,
    SharedModule,
    PerformanceBodModule,
    ToolBarModule,
    LayoutModule,
    ExcelExportModule,
    LabelModule,
    PDFExportModule,
    TooltipsModule,
    UploadsModule,
  ],
  exports: [MatProgressBarModule, NgxSpinnerModule],
  entryComponents: [
    ProgressBarIndeterminateExampleComponent,
    SummaryNotesAttachmentComponent,
    UploadFileComponent,
    EditPadsComponent,
    CostByPadDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.WinAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true,
    },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
    { provide: APP_BASE_HREF, useValue: window.location.pathname },
    {
      deps: [HttpClient],
      provide: UserPermissionsService,
      useFactory: (jsonp: HttpClient) => () => new UserPermissionsService(jsonp),
    },
    { provide: APP_BASE_HREF, useValue: window.location.pathname },
    {
      deps: [HttpClient],
      provide: ScenarioDataService,
      useFactory: (jsonp: HttpClient) => () => new ScenarioDataService(jsonp),
    },
    CanDeactivateGuard,
    FitUnsavedChangesGuard,
    AuthenticationService,
    AuthGuard,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [AuthenticationService], multi: true },
    PermissionsProvider,
    { provide: APP_INITIALIZER, useFactory: initApp2, deps: [PermissionsProvider, ScenarioStoreService], multi: true },
    PerformanceConfigurationService,
    { provide: APP_INITIALIZER, useFactory: initConfiguration, deps: [PerformanceConfigurationService], multi: true },
    {
      deps: [RendererFactory2, NgZone],
      provide: DragAndDropService,
      useFactory: (rendererFactory2: RendererFactory2, zone: NgZone) => () =>
        new DragAndDropService(rendererFactory2.createRenderer(null, null), zone),
    },
    {
      deps: [ScenarioStoreService],
      provide: PadBuilderEditService,
      useFactory: (scenarioStoreService: ScenarioStoreService) => () => new PadBuilderEditService(scenarioStoreService),
    },
    {
      deps: [HttpClient],
      provide: UnitRateAreaService,
      useFactory: (jsonp: HttpClient) => () => new UnitRateAreaService(jsonp),
    },
    FitCcBaseService,
    BasinService,
    StateService,
    DevelopmentAreaService,
    GeographicAreaService,
    CompressorCapacityAssumptionsService,
    LandDamagesService,
    LandDamagesPipesService,
    PadContingencyAssumptionsService,
    PrctItemDetailService,
    ItemTypeService,
    PrctInfrastructureClassService,
    LocationMDTService,
    PrctInfraestructureScopeCategoryService,
    RfsdTimingService,
    KindService,
    PrctItemService,
    UnitOfMeasureService,
    FacilityItemsAndYearlyCostMdtService,
    FacilityItemTypeService,
    FacilityTypeService,
    RedirectGuard,
    CanDeactivateGuard,
    DatePipe,
    DasSimpleService,
    {
      deps: [DasSimpleService],
      provide: DasSimpleListEditService,
      useFactory: (dasSimpleService: DasSimpleService) => () => new DasSimpleListEditService(dasSimpleService),
    },
    {
      deps: [SmartImportSequenceService],
      provide: DasSimpleScopeCountEditService,
      useFactory: (smartImportSequenceService: SmartImportSequenceService) => () =>
        new DasSimpleScopeCountEditService(smartImportSequenceService),
    },
    DasSimpleCalculationsService,
    {
      provide: Globals,
      useClass: Globals,
      multi: false,
    },
    {
      deps: [DataService],
      provide: CndListService,
      useFactory: (dataService: DataService) => () => new CndListService(dataService),
    },
    {
      deps: [ScenarioStoreService],
      provide: EditPadsService,
      useFactory: (scenarioStoreService: ScenarioStoreService) => () => new EditPadsService(scenarioStoreService),
    },
    {
      deps: [ScenarioStoreService, DataService],
      provide: CostByPadDialogService,
      useFactory: (scenarioStoreService: ScenarioStoreService, dataService: DataService) => () =>
        new CostByPadDialogService(scenarioStoreService, dataService),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
