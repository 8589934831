import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cost-assignation',
  templateUrl: './cost-assignation.component.html',
 
})
export class CostAssignationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
