import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State, process, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { IPerformanceBodWellPad } from 'src/app/api/performance-bod-well-pad';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodInfrastructureWellPadService } from './infrastructure-well-pad.service';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.infrastructure-well-pad-draggable';

@Component({
  selector: 'app-infrastructure-well-pad',
  
  templateUrl: './infrastructure-well-pad.component.html',
})
export class InfrastructureWellPadComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public warningMessage: string =
    'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
  public countWellPadName: number = 0;
  public openDialog: boolean = false;

  public padOrientationList: Array<{ text: string; value: string }> = [
    { text: 'North', value: 'North' },
    { text: 'South', value: 'South' },
    { text: 'East', value: 'East' },
    { text: 'West', value: 'West' },
  ];

  public padDesignList: Array<{ text: string; value: string }> = [
    { text: 'BGWH', value: 'BGWH' },
    { text: 'Overlaping Pad', value: 'Overlaping Pad' },
    { text: 'Standard', value: 'Standard' },
  ];

  public openClosedLoopList: Array<{ text: string; value: string }> = [
    { text: 'Open', value: 'Open' },
    { text: 'Closed', value: 'Closed' },
  ];

  @ViewChild('infraWellPad')
  public infrastructureWellPadGrid: any;

  public isLoading = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodPadsList: IPerformanceBodPads[];
  public performanceBodId: string;
  public formGroup: FormGroup;
  public messageDuplicate = true;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public numberOfWellsUpdate = new EventEmitter<any>();
  @Output() public wellPadPermitsRequiredByUpdate = new EventEmitter<any>();
  @Output() public wellPadCreate = new EventEmitter<any>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  public performanceBodWellPadService: PerformanceBodInfrastructureWellPadService;

  private dragAndDropService: DragAndDropService;
  private isANewWellPad = false;
  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(PerformanceBodInfrastructureWellPadService) performanceBodInfrastructureWellPadServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.performanceBodWellPadService = performanceBodInfrastructureWellPadServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.performanceBodWellPadService.performanceBodId = id;
      this.performanceBodWellPadService.read();
    });

    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackagesList = data;
    });

    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });

    this.view = this.performanceBodWellPadService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.dragAndDropService.initialize(this.performanceBodWellPadService.data, DRAGGABLE_SELECTOR, () => {
      this.performanceBodWellPadService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public loadedData(isLoaded: boolean) {
    this.isLoading = isLoaded;
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(dataItem.id),
      isDeleted: new FormControl(dataItem.isDeleted, [Validators.required]),
      chargeCode: new FormControl(dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),
      cndHOEstimate: new FormControl(dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]),
      performanceBodPadsId: new FormControl(dataItem.performanceBodPadsId, [Validators.required]),
      performanceBodPackagesId: new FormControl(dataItem.performanceBodPackagesId),
      wellCount: new FormControl(dataItem.wellCount, [Validators.min(0), Validators.max(50), Validators.required]),
      wellPadRfsd: new FormControl(dataItem.wellPadRfsd, [Validators.required]),
      permitsRequiredBy: [dataItem.permitsRequiredBy],
      padDimensions: new FormControl(dataItem.padDimensions, [Validators.minLength(0), Validators.maxLength(100)]),
      padOrientation: new FormControl(dataItem.padOrientation, [Validators.required]),
      openClosedLoop: new FormControl(dataItem.openClosedLoop, [Validators.required]),
      padDesign: new FormControl(dataItem.padDesign, [Validators.required]),
      comments: new FormControl(dataItem.comments, [Validators.minLength(1), Validators.maxLength(500)]),
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
      name: [dataItem.name],
      afeNumber: new FormControl(dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),  
      afeAmount: new FormControl(dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]),  
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    // always set false in this context
    this.isANewWellPad = false;
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.wellPadRfsd !== undefined && columnIndex === 4) {
        if (dataItem.wellPadRfsd !== null) {
          const y = new Date(dataItem.wellPadRfsd.toString()).getFullYear();
          const m = new Date(dataItem.wellPadRfsd.toString()).getMonth();
          const d = new Date(dataItem.wellPadRfsd.toString()).getDate();
          dataItem.wellPadRfsd = new Date(y, m, d);
        }
      }

      if (dataItem.permitsRequiredBy !== undefined && columnIndex === 5) {
        if (dataItem.permitsRequiredBy !== null) {
          const y = new Date(dataItem.permitsRequiredBy.toString()).getFullYear();
          const m = new Date(dataItem.permitsRequiredBy.toString()).getMonth();
          const d = new Date(dataItem.permitsRequiredBy.toString()).getDate();
          dataItem.permitsRequiredBy = new Date(y, m, d);
        }
      }
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPadsName') {
      columnField = 'performanceBodPadsId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPadsId') {
        dataItem.performanceBodPadsName = this.getPerformanceBodPads(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (formGroup.valid && args.sender.data.total > 0) {
        for (let i = 0; i <= args.sender.data.total - 1; i++) {
          if (
            args.sender.data.data[i].performanceBodPadsId === formGroup.value.performanceBodPadsId &&
            args.sender.data.data[i].id !== formGroup.value.id
          ) {
            this.messageDuplicate = false;
            args.preventDefault();
            return;
          } else {
            this.messageDuplicate = true;
          }
        }
      }

      if (this.messageDuplicate) {
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.authenticationService.getCurrentUser();

        // Date is not working in Object.assign(), so need to be do it manually
        formGroup.value.updatedBy = dataItem.updatedBy;
        formGroup.value.updatedDate = dataItem.updatedDate;

        let updateNumberOfWells = false;
        if (formGroup.value.wellCount) {
          updateNumberOfWells = formGroup.value.wellCount !== dataItem.wellCount;
        }

        let updatePermitsRequiredBy = false;
        if (formGroup.value.permitsRequiredBy) {
          updatePermitsRequiredBy = formGroup.value.permitsRequiredBy !== dataItem.permitsRequiredBy;
        }

        this.performanceBodWellPadService.assignValues(dataItem, formGroup.value);
        if (updateNumberOfWells) {
          this.numberOfWellsUpdate.next(dataItem);
        }
        if (updatePermitsRequiredBy) {
          const performanceBodPad = this.performanceBodPadsList.find(s => s.id === dataItem.performanceBodPadsId);
          dataItem.performanceBodPackagesId = performanceBodPad.performanceBodPackagesId;
          this.wellPadPermitsRequiredByUpdate.next(dataItem);
        }
        this.performanceBodWellPadService.updateItem(dataItem);
      }
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender, rowIndex }) {
    this.isANewWellPad = true;
    const item: IPerformanceBodWellPad = {
      id: null,
      name: '',
      performanceBodPackagesId: '',
      performanceBodPadsId: '',
      chargeCode: '',
      cndHOEstimate: 0,
      wellCount: 0,
      wellPadRfsd: null,
      permitsRequiredBy: null,
      padDimensions: '',
      padOrientation: 'East',
      rigLineType: 'Unspecified',
      openClosedLoop: 'Closed',
      padDesign: 'Standard',
      comments: '',
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: null,
      updatedDate: null,
      afeNumber:'',
      afeAmount: 0,
    };

    if (this.performanceBodPadsList) {
      for (const pad of this.performanceBodPadsList) {
        if (pad.wellPadName === '') {
          this.countWellPadName = this.countWellPadName + 1;
        }
      }
      if (this.countWellPadName === this.performanceBodPadsList.length) {
        this.openDialog = true;
        this.cancelHandler({ sender, rowIndex });
        this.countWellPadName = 0;
        return;
      } else {
        item.createdBy = this.authenticationService.getCurrentUser();
        item.createdDate = new Date();

        sender.addRow(this.createFormGroup(item));
        this.dragAndDropService.onEditingMode();
      }
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    this.messageDuplicate = true;
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid && sender.data.total > 0) {
      for (let i = 0; i <= sender.data.total - 1; i++) {
        if (sender.data.data[i].performanceBodPadsId === formGroup.value.performanceBodPadsId) {
          this.messageDuplicate = false;
          return;
        } else {
          this.messageDuplicate = true;
        }
      }
    }

    if (this.messageDuplicate) {
      if (formGroup.valid) {
        formGroup.patchValue({
          performanceBodId: this.performanceBodId,
        });

        if (this.performanceBodPadsList) {
          for (const pad of this.performanceBodPadsList) {
            if (pad.wellPadName === '') {
              this.countWellPadName = this.countWellPadName + 1;
            }
          }
          if (this.countWellPadName === this.performanceBodPadsList.length) {
            this.openDialog = true;
            this.countWellPadName = 0;
            return;
          } else {
            formGroup.value.id = Guid.create().toString();
            this.performanceBodWellPadService.createItem(formGroup.value);
            sender.closeRow(rowIndex);
            this.dragAndDropService.onEditingClose();
            this.dragAndDropService.dataSourceRefreshed(this.performanceBodWellPadService.data);
            this.dragAndDropService.refreshDragAndDrop();
            this.performanceBodWellPadService.markAsSetOrder(this.permissionsProvider.cai);
          }
        }
      } else {
        this.utilService.markFormGroupAsTouched(formGroup);
      }
    }
  }

  public removeHandler({ sender, dataItem }) {
    this.performanceBodWellPadService.removeItem(dataItem);

    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWellPadService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Infrastructure - Well Pad Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public saveFromBOD(): void {
    this.infrastructureWellPadGrid.closeCell();
    this.infrastructureWellPadGrid.cancelCell();

    if (this.performanceBodWellPadService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.performanceBodWellPadService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

      const subscription = this.performanceBodWellPadService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public cancelChanges(grid: any): void {
    this.messageDuplicate = true;
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.performanceBodWellPadService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.performanceBodWellPadService.read();


  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public getPerformanceBodPads(id: string): any {
    if (this.performanceBodPadsList === null || this.performanceBodPadsList === undefined) {
      return '';
    } else {
      return this.performanceBodPadsList.find(x => x.id === id);
    }
  }

  public changePackage(value, dataItem: IPerformanceBodWellPad, formGroup: FormGroup) {
    const performanceBodPad = this.performanceBodPadsList.find(s => s.id === value);
    let performanceBodPackagesId: string;
    dataItem.performanceBodPackagesId = performanceBodPad ? performanceBodPad.performanceBodPackagesId : null;
    performanceBodPackagesId = dataItem.performanceBodPackagesId;
    formGroup.patchValue({
      performanceBodPackagesId,
    });

    if (!dataItem.id) {
      dataItem.performanceBodPadsId = performanceBodPad ? performanceBodPad.id : null;
      this.wellPadCreate.next(dataItem);
      formGroup.patchValue({
        wellPadRfsd: dataItem.wellPadRfsd,
      });
      formGroup.patchValue({
        permitsRequiredBy: dataItem.permitsRequiredBy,
      });
      formGroup.patchValue({
        wellCount: dataItem.wellCount,
      });
    }
  }

  public closeKendoPopUp() {
    this.openDialog = false;
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
