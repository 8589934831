import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/toPromise';
import { IFacilityAfe } from '../_models/facility.afe';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { InlineActions } from 'src/app/_shared/_models/enums';
import { IFacilityBod } from 'src/app/_shared/_models/facility.bod.model';
import { IFacilityCostItems } from '../../cost-estimate/_models/facility.cost.items.model';
import { environment } from 'src/app/../environments/environment';
import { of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class FacilityAFEService extends InlineEditService implements IEditableInline {
  public loader = new EventEmitter<any>();
  public wasModified = new EventEmitter<any>();
  public data: IFacilityAfe[] = [];
  public originalData: IFacilityAfe[] = [];
  public createdItems: IFacilityAfe[] = [];
  public updatedItems: IFacilityAfe[] = [];
  public deletedItems: IFacilityAfe[] = [];
  public updatedFacilityCostItems: IFacilityCostItems[] = [];

  public facilityBodId?: string = null;

  constructor(private http: HttpClient) {
    super();
    super.fetch(InlineActions.Retrieve, this.data);
  }

  public itemIndex(item: any, data: IFacilityAfe[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/Create/';
    const toCreate = this.createdItems.map(item => {
      return {
        facilityBodId: item.facilityBodId,
        name: item.name,
        number: item.number,
        amount: item.amount,
        isDefault: item.isDefault,
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(
      map((data: any[]) => {
        this.wasModified.emit(true);
        return data;
      })
    );
  }

  public retrieve(): Observable<any> {
    if (!this.facilityBodId) {
      return of([]);
    }
    this.loader.emit(true);
    const serviceUrl: string =
      environment.PacerDomainURL + 'FacilityAfe/ListByFacilityBodId/' + this.facilityBodId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        this.loader.emit(false);
        return data;
      })
    );
  }

  public retrieveByFacilityBodId(facilityId: string): Observable<any> {
    const serviceUrl: string =
      environment.PacerDomainURL + 'FacilityAfe/ListByFacilityBodId/' + facilityId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public update(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/Update/';

    return this.http.post(serviceUrl, JSON.stringify(this.updatedItems), httpOptions).pipe(
      map((data: any[]) => {
        this.wasModified.emit(true);
        return data;
      })
    );
  }

  public delete(): Observable<any> {
    this.loader.emit(true);
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/Update/';

    const wbsIds = this.deletedItems.map(wbs => wbs.id);
    this.removeReferences(wbsIds).subscribe();

    return this.http.post(serviceUrl, JSON.stringify(this.deletedItems), httpOptions).pipe(
      map((data: any[]) => {
        this.wasModified.emit(true);
        this.loader.emit(false);
        return data;
      })
    );
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public getFacilityCostItemsByAfeId(afeId: string): Observable<any> {
    // TODO: PERFORMANCE > PENDING RELATION WITH BOD AFE SECTION
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityCostItems/ListByFacilityAfeId/' + afeId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public updateFacilityCostItemsByAfeId(): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityCostItems/Update';

    return this.http.post(serviceUrl, JSON.stringify(this.updatedFacilityCostItems), httpOptions).pipe(
      map((data: any[]) => {
        this.wasModified.next(true);
        this.updatedFacilityCostItems = [];
        return data;
      })
    );
  }

  public getAfeByFacilityBodId(facilityBodId: string): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/ListByFacilityBodId/' + facilityBodId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public updateTheReplace(updatedItems: IFacilityAfe[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/Update/';

    return this.http.post(serviceUrl, JSON.stringify(updatedItems), httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public createTheReplace(createdItems: IFacilityAfe[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/CreateWithReturn/';
    const toCreate = createdItems.map((item) => {
      return {
        facilityBodId: item.facilityBodId,
        name: item.name,
        number: item.number,
        amount: item.amount,
        isDefault: item.isDefault,
        isDeleted: item.isDeleted,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
      };
    });
    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }


  // Calculations
  // Plan
  public calculatePlanTax(taxRate: number, planCost: number, contingency: number): number {
    const contingencyPlanCost = this.calculatePlanContigency(planCost, contingency);
    return taxRate * (planCost + contingencyPlanCost);
  }

  public calculatePlanContigency(planCost: number, contingency: number): number {
    return contingency * planCost;
  }

  public calculatePlanGeneralAndAdministrative(
    taxRate: number,
    planCost: number,
    contingency: number,
    generalAndAdministrative: number
  ): number {
    const contingencyPlanCost = this.calculatePlanContigency(planCost, contingency);
    const taxPlanCost = this.calculatePlanTax(taxRate, planCost, contingency);
    const gA = generalAndAdministrative * (planCost + contingencyPlanCost + taxPlanCost);

    return gA;
  }

  public calculatePlanTotalCost(
    taxRate: number,
    planCost: number,
    contingency: number,
    generalAndAdministrative: number
  ): number {
    const planTotal =
      planCost +
      this.calculatePlanTax(taxRate, planCost, contingency) +
      this.calculatePlanGeneralAndAdministrative(taxRate, planCost, contingency, generalAndAdministrative) +
      this.calculatePlanContigency(planCost, contingency);

    return planTotal;
  }

  // Commited
  public calculateCommittedTax(taxRate: number, commitedCost: number): number {
    return taxRate * commitedCost;
  }

  public calculateCommittedGeneralAndAdministrative(
    taxRate: number,
    commitedCost: number,
    generalAndAdministrative: number
  ): number {
    const taxCommitedCost = this.calculateCommittedTax(taxRate, commitedCost);
    const gA = generalAndAdministrative * (commitedCost + taxCommitedCost);

    return gA;
  }

  public calculateCommittedTotalCost(taxRate: number, commitedCost: number, generalAndAdministrative: number): number {
    const commitedTotal =
      commitedCost +
      this.calculateCommittedTax(taxRate, commitedCost) +
      this.calculateCommittedGeneralAndAdministrative(taxRate, commitedCost, generalAndAdministrative);

    return commitedTotal;
  }

  public calculateCommitedDelta(
    taxRate: number,
    commitedCost: number,
    planCost: number,
    contingency: number,
    generalAndAdministrative: number
  ): number {
    const commitedDelta =
      this.calculateCommittedTotalCost(taxRate, commitedCost, generalAndAdministrative) /
      this.calculatePlanTotalCost(taxRate, planCost, contingency, generalAndAdministrative);

    return isNaN(commitedDelta) ? 0 : commitedDelta;
  }

  // Spent
  public calculateSpentTax(taxRate: number, spentCost: number): number {
    return taxRate * spentCost;
  }

  public calculateSpentGeneralAndAdministrative(
    taxRate: number,
    spentCost: number,
    generalAndAdministrative: number
  ): number {
    const taxSpentCost = this.calculateSpentTax(taxRate, spentCost);
    const gA = generalAndAdministrative * (spentCost + taxSpentCost);

    return gA;
  }

  public calculateSpentTotalCost(taxRate: number, spentCost: number, generalAndAdministrative: number): number {
    const spentTotal =
      spentCost +
      this.calculateSpentTax(taxRate, spentCost) +
      this.calculateSpentGeneralAndAdministrative(taxRate, spentCost, generalAndAdministrative);

    return spentTotal;
  }

  public calculateSpentDelta(
    taxRate: number,
    spentCost: number,
    planCost: number,
    contingency: number,
    generalAndAdministrative: number
  ): number {
    const spentDelta =
      this.calculateSpentTotalCost(taxRate, spentCost, generalAndAdministrative) /
      this.calculatePlanTotalCost(taxRate, planCost, contingency, generalAndAdministrative);

    return isNaN(spentDelta) ? 0 : spentDelta;
  }
  // Forecast
  public calculateForecastTax(taxRate: number, forecastCost: number): number {
    return taxRate * forecastCost;
  }

  public calculateForecastGeneralAndAdministrative(
    taxRate: number,
    forecastCost: number,
    generalAndAdministrative: number
  ): number {
    const taxForecastCost = this.calculateForecastTax(taxRate, forecastCost);
    const gA = generalAndAdministrative * (forecastCost + taxForecastCost);

    return gA;
  }

  public calculateForecastTotalCost(taxRate: number, forecastCost: number, generalAndAdministrative: number): number {
    const forecastTotal =
      forecastCost +
      this.calculateForecastTax(taxRate, forecastCost) +
      this.calculateForecastGeneralAndAdministrative(taxRate, forecastCost, generalAndAdministrative);

    return forecastTotal;
  }

  public calculateForecastDelta(
    taxRate: number,
    forecastCost: number,
    planCost: number,
    contingency: number,
    generalAndAdministrative: number
  ): number {
    const forecastDelta =
      this.calculateForecastTotalCost(taxRate, forecastCost, generalAndAdministrative) /
      this.calculatePlanTotalCost(taxRate, planCost, contingency, generalAndAdministrative);

    return isNaN(forecastDelta) ? 0 : forecastDelta;
  }
    
  private removeReferences(wbsIds: any[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityCostItems/RemoveWbsReferences/';

    return this.http.post(serviceUrl, JSON.stringify(wbsIds), httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }
}
