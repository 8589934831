import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { IFacilityStdDesigns } from '../_models/facility.std.designs.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable()
export class FacilityStdDesignNoBehaviorSubjectService {
  constructor(private http: HttpClient) {}

  public getFacilityStdDesigns(): Observable<IFacilityStdDesigns[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/List';
    return this.http.get<IFacilityStdDesigns[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDeletedFacilityStdDesigns(): Observable<IFacilityStdDesigns[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/ListDeleted';
    return this.http.get<IFacilityStdDesigns[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDesignById(pcrDesignId: string): Observable<IFacilityStdDesigns> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/GetByKey/' + pcrDesignId;
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public facilityStdDesignsById(id: string): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/GetFacilityStdDesignsById/' + id;
    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public saveFacilityStdDesign(designs: IFacilityStdDesigns[]): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/CreateWithReturn';

    const toCreate = designs.map(item => {
      return {
        name: item.name,
        trainCount: item.trainCount === null || item.trainCount === undefined ? 0 : item.trainCount,
        isAddition: item.isAddition === null || item.isAddition === undefined ? false : item.isAddition,
        facilityTypeId: item.facilityTypeId,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate,
        // name: item.name, trainCount: item.trainCount, isExpansion: item.isAddition,
        // isDeleted: item.isDeleted, facilityTypeId: item.facilityTypeId,
        // facilityType: null, stdDesignEquipment: null,
        // designExpansions: null, designIeScopes: null,
        // stdDesignLaborEstimates: null, designUnitRates: null, facilities: null,
        // createdBy: item.createdBy, createdDate: item.createdDate, updatedBy: item.updatedBy,
        // updatedDate: item.updatedDate
      };
    });

    return this.http.post(serviceUrl, JSON.stringify(toCreate), httpOptions).pipe(map(data => data as any[]));
  }
}
