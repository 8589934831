<kendo-toolbar style="padding-right: 30px; left: 0px; display:block;">
  <kendo-toolbar-button
    [text]="'Mass Import'"
    [showText]="'both'"
    [icon]="'export'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="openImportMassExcel()"
  ></kendo-toolbar-button>
  <kendo-toolbar-button
    [text]="'Mass Export'"
    [showText]="'both'"
    [icon]="'import'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="onExportFacilityAssignmentAndScopeCount()"
  ></kendo-toolbar-button>
  <kendo-toolbar-button
    [text]="'Export Pad Cost Summary'"
    [showText]="'both'"
    [icon]="'file-excel'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="onExportPadCostSummary()"
  ></kendo-toolbar-button>
  <kendo-toolbar-button
    [text]="'Open SMART Sequence'"
    [showText]="'both'"
    [icon]="'info'"
    [showIcon]="'both'"
    themeColor="primary"
    [tabIndex]="tabIndex"
    (click)="openSmartSequenceUrl()"
  >
  </kendo-toolbar-button>
  <kendo-toolbar-button
    *ngIf="!newScenario"
    [text]="'Edit Pads'"
    [showText]="'both'"
    [icon]="'k-icon k-i-edit'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="openEditPadsClick()"
  >
  </kendo-toolbar-button>
  <kendo-toolbar-button
    *ngIf="!newScenario"
    [text]="'Costs by Year'"
    [showText]="'both'"
    [icon]="'k-icon k-i-currency'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="openPadCostByYearClick()"
  >
  </kendo-toolbar-button>
  <kendo-toolbar-button
    [text]="'Save'"
    [showText]="'both'"
    [icon]="'save'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="verifyDefaultCosts('save')"
  >
  </kendo-toolbar-button>
  <kendo-toolbar-button
    [text]="'Save As'"
    [showText]="'both'"
    [icon]="'save'"
    [showIcon]="'both'"
    themeColor="primary"
    [disabled]="!permissionsProvider || !permissionsProvider.canEditCnd"
    [tabIndex]="tabIndex"
    (click)="onSaveAs()"
  >
  </kendo-toolbar-button>
</kendo-toolbar>

<kendo-dialog title="Save As..." *ngIf="saveAsOpened" (close)="closeSaveAs()" [minWidth]="300" [width]="600">
  <form id="frmSaveAs" [formGroup]="saveAsForm" novalidate (ngSubmit)="verifyDefaultCosts('saveAs')">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Development Area</label>
      <div class="col-sm-8">
        <input type="text" readonly class="form-control" formControlName="developmentArea" id="developmentArea" />
        <p
          style="color: red; font-size: .8em; margin-top: .5em;"
          [hidden]="
            saveAsForm.controls.developmentArea.valid ||
            (saveAsForm.controls.developmentArea.pristine && !saveAsSubmitted)
          "
        >
          Development Area is Required.
        </p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Scenario Type</label>
      <div class="col-sm-8">
        <input type="text" readonly class="form-control" formControlName="scenarioType" id="scenarioType" />
        <p
          style="color: red; font-size: .8em; margin-top: .5em;"
          [hidden]="
            saveAsForm.controls.scenarioType.valid || (saveAsForm.controls.scenarioType.pristine && !saveAsSubmitted)
          "
        >
          Scenario Type is Required.
        </p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Handover #</label>
      <div class="col-sm-8">
        <input type="text" readonly class="form-control" formControlName="handover" id="handover" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Scenario Name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="scenarioName"
          id="scenarioName"
          autofocus
          maxlength="128"
        />
        <p
          style="color: red; font-size: .8em; margin-top: .5em;"
          [hidden]="
            saveAsForm.controls.scenarioName.valid || (saveAsForm.controls.scenarioName.pristine && !saveAsSubmitted)
          "
        >
          Scenario Name is Required.
        </p>
      </div>
    </div>
    <kendo-dialog-actions>
      <button kendoButton class="k-button" (click)="closeSaveAs()">Cancel</button>
      <button
        kendoButton
        type="submit"
        form="frmSaveAs"
        class="k-button k-primary"
        [disabled]="!saveAsForm.valid && !permissionsProvider.canEditCnd"
      >
        Save
      </button>
      <ng-template #notiftemplate></ng-template>
    </kendo-dialog-actions>
  </form>
</kendo-dialog>
