import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IScenario } from '../../../api/pricetScenario';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../../services/data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputSummaryModel } from '../input-summary/input-summary-model';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ImportMassExcelBuilderComponent } from '../input-summary/import-mass-excel/import-mass-excel.component';
@Component({
  selector: 'app-cnd-tool-bar',
  templateUrl: './cnd-tool-bar.component.html',

})
export class CndToolBarComponent implements OnInit {
  public newScenarioWithChanges = false;
  public saveAsSubmitted = false;
  public saveAsOpened = false;
  public saveAsForm: FormGroup;
  public openedScenario = false;
  public tryExport = false;
  public scenarioID = '';
  public tabIndex = 1;
  public sequenceId = '';
  @Output() public openEditPads = new EventEmitter<any>();
  @Output() public openPadCostByYear = new EventEmitter<any>();
  @Output() public saveScenario = new EventEmitter<any>();
  public newScenario: boolean = true;

  constructor(
    private router: Router,
    public permissionsProvider: PermissionsProvider,
    public scenarioStoreService: ScenarioStoreService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.scenarioStoreService.inputSummary$.subscribe((data: InputSummaryModel) => {
      this.saveAsForm = new FormGroup({
        developmentArea: new FormControl(data.developmentArea ? data.developmentArea.name : '', [
          Validators.required,
          Validators.minLength(3),
        ]),
        scenarioType: new FormControl(data.scenarioType ? data.scenarioType.name : '', [
          Validators.required,
          Validators.minLength(3),
        ]),
        scenarioName: new FormControl(data.scenarioName, [Validators.required, Validators.minLength(3)]),
        handover: new FormControl(
          data.developmentArea && data.scenarioType && data.handoverNumber ? data.handoverNumber.toString() : ''
        ),
      });
      this.saveAsOpened = false;
      this.saveAsSubmitted = false;
    });

    this.scenarioStoreService.scenarioID$.subscribe(i => {
      this.scenarioID = i;
    });

    this.scenarioStoreService.sequenceId$.subscribe(i => {
      this.sequenceId = i;
    });

    this.scenarioStoreService.isScenarioCreated.subscribe((scenarioWasCreated: boolean) => {
      this.newScenario = !scenarioWasCreated;
    });

    this.checkIsNewUsingRoute();
  }

  public verifyDefaultCosts(action: string): void {
    this.saveScenario.next(action);
  }

  public onSave(): void {
    // Save scenario
    this.newScenarioWithChanges = false;
    this.scenarioStoreService.changeTryExport(false);
    const objScenario = this.scenarioStoreService.getScenarioData();
    if (this.validateScenario(objScenario)) {
      this.showInfo('Saving scenario...');
      this.spinner.show();
      this.scenarioStoreService.isScenarioCreated.emit(false);
      this.dataService.savePricetScenario(objScenario).subscribe(
        (data: any) => {
          this.dataService.UploadScenarioCapex(objScenario.id);
          this.onSaveScenarioComplete(data);
          this.scenarioStoreService.changeNewScenario(false);
          if (this.newScenario) {
            this.router.navigate(['Scenario/id', this.scenarioID]);
          }
          this.scenarioStoreService.isScenarioCreated.emit(true);
          this.scenarioStoreService.isScenarioCreated.complete();
        },
        (err: any) => {
          this.onSaveScenarioError(err);
        }
      );
      this.dataService
        .updateScenarioPadBracketCost(this.scenarioStoreService.scenarioPadBracketCostsBs$.value)
        .subscribe();
    } else {
      return;
    }
  }

  public onSaveAs(): void {
    this.newScenarioWithChanges = false;
    this.scenarioStoreService.changeTryExport(false);
    this.saveAsOpened = true;
    // place this line whenever is implemented the whole save as feature
    this.scenarioStoreService.changeNewScenario(false);
  }

  public validateScenario(entity: IScenario): boolean {
    let isValid = true;

    if (entity === null || entity === undefined) {
      this.showWarning('The information of the scenario is required.');
      isValid = false;
    } else {
      if (entity.developmentAreaId === null || entity.developmentAreaId === Guid.EMPTY) {
        this.showWarning('The Development Area is required.');
        isValid = false;
      }
      if (entity.name === null || entity.name.trim() === '') {
        this.showWarning('The Scenario name is required.');
        isValid = false;
      }
      if (entity.scenarioTypeId === null || entity.scenarioTypeId === Guid.EMPTY) {
        this.showWarning('The Scenario Type is required.');
        isValid = false;
      }
      if (entity.costYear === null || entity.costYear <= 0) {
        this.showWarning('Scenario Inputs Cost Year is not valid.');
        isValid = false;
      }
      if (entity.padBrackets === null) {
        this.showWarning('PadBrakets Data required.');
        isValid = false;
      }
      if (entity.padBrackets !== null && entity.padBrackets.length === 0) {
        this.showWarning('PadBrakets Data required.');
        isValid = false;
      }
      if (entity.items === null) {
        this.showWarning('Scenario Items required.');
        isValid = false;
      }
      if (entity.items !== null && entity.items.length === 0) {
        this.showWarning('Scenario Items required.');
        isValid = false;
      }
      if (entity.padItemInventories === null) {
        this.showWarning('Scope Counts values required.');
        isValid = false;
      }
      if (entity.padItemInventories !== null && entity.padItemInventories.length === 0) {
        this.showWarning('Scope Counts values required.');
        isValid = false;
      }
      if (entity.items && entity.items.length > 0) {
        let helper = {};
        let result = entity.items.reduce(function(r, o) {
          var key =
            o.displayName +
            '-' +
            o.itemDetail.name +
            '-' +
            (o.respToExpandFacilityItemId ? o.respToExpandFacilityItemId : '');
          if (!helper[key]) {
            helper[key] = Object.assign({}, o);
            helper[key].count = 1;
            r.push(helper[key]);
          } else {
            helper[key].count += helper[key].count;
          }
          return r;
        }, []);
        let duplicates = result.filter(x => x.count > 1 && x.displayName.length > 0); //duplicate values
        if (duplicates.length > 0) {
          this.showWarning('Scope count table has duplicate values ' + duplicates.map(x => x.displayName).join('::'));
          isValid = false;
        }
      }

      if (entity.isSaveAs !== null && entity.isSaveAs !== undefined && entity.isSaveAs) {
        if (
          entity.sourceScenarioId === null ||
          entity.sourceScenarioId === undefined ||
          entity.sourceScenarioId === Guid.EMPTY
        ) {
          this.showWarning('To Save As and create a copy, the source scenario is required');
          isValid = false;
        } else if (entity.sourceScenarioId === entity.id) {
          this.showWarning('To Save As and create a copy, the source scenario is wrong.');
          isValid = false;
        }
      }
    }

    return isValid;
  }

  public showInfo(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'info', icon: true },
    });
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'warning', icon: true },
    });
  }

  public onSaveScenarioComplete(value: any) {
    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'The save was completed.',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }, 6000);

    this.saveAsSubmitted = false;
    this.saveAsOpened = false;
    this.scenarioStoreService.setImportExecuted(false);
  }

  public onSaveScenarioError(value: any) {
    setTimeout(() => {
      this.spinner.hide();
      this.showWarning('There was a problem saving');
    }, 6000);

    this.saveAsSubmitted = false;
  }

  public closeSaveAs(): void {
    this.saveAsOpened = false;
    this.saveAsSubmitted = false;
  }

  public saveAs() {
    this.saveAsSubmitted = true;
    if (this.saveAsForm.valid) {
      const pricetScenarioSaveAs = this.scenarioStoreService.getScenarioDataToSaveAs(this.scenarioName.value);
      if (this.validateScenario(pricetScenarioSaveAs)) {
        this.showInfo('Saving As scenario...');
        this.spinner.show();
        this.dataService.savePricetScenario(pricetScenarioSaveAs).subscribe(
          (data: any) => {
            this.onSaveAsScenarioComplete(data, pricetScenarioSaveAs.id);
          },
          (err: any) => {
            this.onSaveScenarioError(err);
          }
        );
      } else {
        return;
      }
    }
  }

  public get scenarioName() {
    return this.saveAsForm.get('scenarioName');
  }

  public onSaveAsScenarioComplete(value: any, id: string) {
    if (id !== undefined && id !== null) {
      this.newScenarioWithChanges = false;
      this.openedScenario = false;
      this.tryExport = false;
      this.scenarioID = Guid.EMPTY;
      // this.scenarioStoreService.changeTryExport(false);
      // this.scenarioStoreService.changeNewScenario(true);
    }
    setTimeout(() => {
      this.scenarioStoreService.openScenario(id);
      this.scenarioStoreService.changeTab(1);
      this.spinner.hide();
      this.notificationService.show({
        content: 'The Save As was completed.',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
      });
    }, 6000);

    this.saveAsSubmitted = false;
    this.saveAsOpened = false;
    this.scenarioStoreService.setImportExecuted(false);
  }

  public onExportPadCostSummary(): void {
    if (this.scenarioID !== undefined && this.scenarioID !== null) {
      this.spinner.show();

      this.dataService.getPadCostSummaryByScenarioId(this.scenarioID).subscribe(
        data => {
          const scenarioName = this.scenarioName.value ? this.scenarioName.value : '';

          if (data) {
            const vfileName = 'PadCostSummaryOf' + scenarioName.replace(/\s/g, '') + '.xlsm';
            const e = document.createElement('a');
            e.href = window.URL.createObjectURL(data);
            e.download = vfileName;
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
          }
        },
        error => {
          this.notificationService.show({
            content: 'There was an issue generating Export Pad Cost Summary document. Please try again later.',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 700 },
            type: { style: 'error', icon: true },
          });
        }
      );

      setTimeout(() => {
        this.spinner.hide();
      }, 5000);
    }
  }

  public openEditPadsClick(): void {
    this.openEditPads.next({ padId: null, open: true });
  }

  private checkIsNewUsingRoute(): void {
    const routeName = this.router.url.split('/');
    const route = routeName[2];
    if (route === 'New') {
      this.newScenario = true;
    } else {
      this.newScenario = false;
    }
  }

  public openSmartSequenceUrl() {
    const urlBase = 'https://smart-mcbu.chevron.com/sequence/';
    if (this.sequenceId != '' && this.sequenceId != null && this.sequenceId != '00000000-0000-0000-0000-000000000000') {
      window.open(urlBase + this.sequenceId.toString(), '_blank');
    } else {
      this.notificationService.show({
        content: "This scenario doesn't have an associated sequence ID",
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'warning', icon: true },
      });
    }
  }

  public openPadCostByYearClick(): void {
    this.openPadCostByYear.next({ justOpened: true });
  }

  public onExportFacilityAssignmentAndScopeCount(): void {
    if (this.scenarioID !== undefined && this.scenarioID !== null) {
      this.spinner.show();

      this.dataService.exportFacilityAssignmentAndScopeCountByScenarioId(this.scenarioID).subscribe(
        data => {
          const scenarioName = this.scenarioName.value ? this.scenarioName.value : '';

          if (data) {
            const vfileName = scenarioName.replace(/\s/g, '') + '.xlsx';
            const e = document.createElement('a');
            e.href = window.URL.createObjectURL(data);
            e.download = vfileName;
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
            this.spinner.hide();
          }
        },
        error => {
          this.notificationService.show({
            content: 'There was an issue generating Export Pad Cost Summary document. Please try again later.',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 700 },
            type: { style: 'error', icon: true },
          });
        }
      );

      //Removed default SetTimeout 5 seconds
    }
  }

  public openImportMassExcel() {
    this.dialogService.open({
      title: 'Import Mass Excel',
      width: 900,
      minWidth: 300,
      height: 600,
      content: ImportMassExcelBuilderComponent,
    });
  }
}
