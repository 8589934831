import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodWellPad } from 'src/app/api/performance-bod-well-pad';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { map } from 'rxjs/operators';
import * as Helpers from 'src/app/_shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodInfrastructureWellPadService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodWellPad[] = [];
  public originalData: IPerformanceBodWellPad[] = [];
  public createdItems: IPerformanceBodWellPad[] = [];
  public updatedItems: IPerformanceBodWellPad[] = [];
  public deletedItems: IPerformanceBodWellPad[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodWellPad[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodWellPad(this.createdItems);
  }

  public retrieve(): Observable<any> {
    return this.performanceBodDataService.getPerformanceBodWellPad(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.wellPadRfsd !== null && element.wellPadRfsd !== undefined) {
          element.wellPadRfsd = Helpers.convertUtcToLocalTime(element.wellPadRfsd);
        } else {
          element.wellPadRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });

      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWellPad(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodWellPad(this.deletedItems);
  }

  public applyLogicFromPads(): void {
    const wellPadChanges = this.performanceBodCalculationsService.wellPadDatesCalculations(this.data);
    for(const wellPad of wellPadChanges) {
      this.updateItem(wellPad);
    }
  }
 
  public applyLogicFromPads2(): void {
    const wellPadChanges = this.performanceBodCalculationsService.wellPadWellCountCalculations(this.data);
    for(const wellPad of wellPadChanges) {
      this.updateItem(wellPad);
    }
  }

}
