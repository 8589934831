import { Injectable, ViewContainerRef } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';

@Injectable({
  providedIn: 'root',
})
export class FitCcDialogService {
  dialogContainerRef: ViewContainerRef;

  constructor(private dialogService: DialogService) {}

  loadContainerRef(dialogRef) {
    this.dialogContainerRef = dialogRef;
  }

  removeContainerRef() {
    this.dialogContainerRef = undefined;
  }

  openDialog(title: string, content: string, actions: Array<any>): DialogRef {
    return this.dialogService.open({
      appendTo: this.dialogContainerRef,
      title: title,
      content: content,
      actions: actions,
      width: 450,
      minWidth: 250,
    });
  }
}
