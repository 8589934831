import { FitCcBaseModel } from "../_shared/fit-common-components-lib/models/fit-cc-base-model";


export class BasinModel extends FitCcBaseModel {
  basinAbbreviation: string;
  geographicArea?: any[];
}

export class DevelopmentAreaModel extends FitCcBaseModel {
  developmentAreaAbbreviation: string;
  mudiDevArea1: string;
  stateId?: string;
  geographicAreaId?: string;
  state?: string;
  effectiveDate?: Date;
  unitRateArea1?: string;
}

export class StateModel extends FitCcBaseModel {
  stateAbbreviation: string;
  taxRate: number;
}

export class GeographicAreaModel extends FitCcBaseModel {
  geographicAreaAbbreviation: string;
  basinId?: string;
  basin?: string;
  developmentArea?: any[];
}

export class CompressorCapacityAssumptionsModel extends FitCcBaseModel {
  compressorType: string;
  suctionPressure: string;
  fuelGas: string;
  compressorGasRateCapacity?: number;
}

export class LandDamagesPipesModel extends FitCcBaseModel {
  nominalPipeSize: string;
  landDamagesCostPerRod: number;
  landDamagesCostPerFoot: number;
  comments: string;
}

export class LandDamagesModel extends FitCcBaseModel {
  scope: string;
  unitCost: string;
  unitOfMeasureId: string;
  comments: string;
}

export class PadContingencyAssumptionsModel extends FitCcBaseModel {
  estimateDescription: string;
  numberOfDevWells: string;
  estimateTimeFrame: string;
  contingencyPercentage?: number;
}

export class PrctItemDetailModel extends FitCcBaseModel {
  itemTypeId: string;
  itemDetailNotes: string;
}

export class PrctItemModel extends FitCcBaseModel {
  itemDetailId: string;
  itemDetailName: string;
  infrastructureClassId: string;
  infrastructureClassName: string;
  locationId: string;
  locationName: string;
  infraestructureScopeCategoryId: string;
  infraestructureScopeCategoryName: string;
  rfsdTimingId: string;
  rfsdTimingName: string;
  kindId: string;
  kindName: string;

  itemNotes: string;
  unitOfMeasure: string;
  respToExpandPrctItemId: string;
  respToExpandPrctItemName: string;
  itemTypeId: string;
  itemTypeName: string;
  OrdenItem: number;
}

export class ItemTypeModel extends FitCcBaseModel {}

export class LocationModel extends FitCcBaseModel {}

export class PrctInfrastructureClassModel extends FitCcBaseModel {}

export class PrctInfraestructureScopeCategoryModel extends FitCcBaseModel {
  infrastructureClassId: string;
  infrastructureClassName: string;
}
export class RfsdTimingModel extends FitCcBaseModel {}

export class KindModel extends FitCcBaseModel {}

export class UnitOfMeasureModel extends FitCcBaseModel {}

export class FacilityItemsAndYearlyCostMdtModel extends FitCcBaseModel {
  type?: string;
  year?: string;
  costPunit?: number;
  itemNotes?: string;
  facilityYearlyCostItemsId: string;
  unitOfMeasureName?: string;
}
