export interface IFitCcAuditHistoryConfig {
  path: string;
  fileName: string;
  dialogTitle?: string;
  columns?: any[];
  type: 'Export' | 'Edit' | 'MasterData' | 'Other';
  endPoints?: string[];
  environment?: string;
  loadFileVia: EnumFitCcLoadFileVia;
  jsonData?: any;
  opened?: boolean;
}

export enum EnumFitCcLoadFileVia {
  Blob = 'Blob',
  Get = 'Get',
  Json = 'Json',
}

export enum EnumFitCcExcelExtension {
  xlsx = 'xlsx',
  cvs = 'cvs'
}
