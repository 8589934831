<kendo-dropdownbutton
  #kendoDropdown
  [buttonClass]="buttonClass"
  [icon]="icon"
  [iconClass]="iconClass"
  [imageUrl]="imageUrl"
  [fillMode]="fillMode"
  [themeColor]="themeColor"
  [tabIndex]="tabIndex"
  [textField]="textField"
  [data]="data"
  [disabled]="disabled"
  [popupSettings]="settings"
  (close)="onCloseHandler($event)"
  (itemClick)="onItemClickHandler($event)"
  (blur)="onBlurHandler($event)"
  (focus)="onFocusHandler($event)"
  (open)="onOpenHandler($event)"
>
{{ label }}
</kendo-dropdownbutton>
