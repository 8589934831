import {
  Component,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ScenarioStoreService } from '../../services/scenario-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InputSummaryModel } from '../../modules/cAndD/input-summary/input-summary-model';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DataService } from '../../services/data.service';
import { Guid } from 'guid-typescript';
import { IScenario } from '../../api/pricetScenario';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { PerformanceBodStoreService } from '../../services/performance-bod-store.service';
import { IUsrPermissions } from '../../api/user-permission';
import { PermissionsProvider } from '../../services/permissions.provider';

export const infrastructureRoutesItems: any = [
  // Fit bod
  { text: 'My BODs', path: 'InfrastructureBod/List/Own' },
  { text: 'All BODs', path: 'InfrastructureBod/List/All' },
  { text: 'Create New', path: 'InfrastructureBod/New' },
];

export const existingFacilitiesRoutesItems: any = [
  // P&E
  { text: 'My Facilities', path: 'ExistingFacilities/List/Own' },
  { text: 'All P&E Facilities', path: 'ExistingFacilities/List/All' },
  { text: 'Create New P&E BOD', path: 'ExistingFacilities/New' },
];

export const adminRoutesItems: any = [
  // Admin
  { text: 'Permissions', path: 'admin/Permissions' },
  { text: 'C&D Master Data', path: 'admin/cd-master-data' },
  { text: 'SFT Master Data', path: 'admin/sft-master-data' },
];

export const standardFacilityRoutesItems: any = [
  // Sft
  { text: 'My Facilities', path: 'standardFacility/list/own' },
  { text: 'All Facilities', path: 'standardFacility/list/all' },
  { text: 'Create New Facility', path: 'standardFacility/create' },
];

export const PerformanceBodRoutesItems: any = [
  // Performance Bod
  { text: 'My BODs', path: 'performanceBOD/MyBODs' },
  { text: 'All BODs', path: 'performanceBOD/AllBODs' },
  { text: 'Create New', path: 'performanceBOD/CreateNew' },
];

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('notiftemplate', { read: TemplateRef })
  public notificationTemplate: TemplateRef<any>;
  public duration = 500;
  public animationType = 'slide';
  public userPermissionValue: IUsrPermissions;
  public items: any[];
  public toolName = 'PRICET';
  public showMenu = false;
  public authRslt = '';
  public authBack = '';
  public listItems: any[];
  public newScenario: boolean;
  public isInGlamGroup: boolean;
  public canEditCnd: boolean = false;
  public candEditPerformanceBod: boolean = false;
  public canEditSft: boolean = false;
  public canEditFit: boolean = false;
  public newScenarioWithChanges: boolean;

  public saveAsOpened: boolean;
  public saveAsForm: FormGroup;
  public saveAsSubmitted = false;
  public result;
  public showPopUpCanDeactivate = false;
  public openedScenario = false;
  public scenarioID = '';
  public tryExport = false;

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
    public scenarioStoreService: ScenarioStoreService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private dialogService: DialogService,
    private performanceBodStoreService: PerformanceBodStoreService,
    private permissionsProvider: PermissionsProvider
  ) {}

  public ngOnInit() {
    this.testAuthentication();
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.isInGlamGroup = this.authenticationService.isInGroupValue;
    if (this.isInGlamGroup) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }

    if (this.permissionsProvider.isAdmin) {
      this.items = this.mapItems(this.router.config);
    } else if (this.isInGlamGroup) {
      this.items = this.mapItems(this.router.config);
    }

    this.scenarioStoreService.newScenario$.subscribe(value => (this.newScenario = value));
    this.changeDetector.markForCheck();

    this.scenarioStoreService.inputSummary$.subscribe((data: InputSummaryModel) => {
      this.saveAsForm = new FormGroup({
        developmentArea: new FormControl(data.developmentArea ? data.developmentArea.name : '', [
          Validators.required,
          Validators.minLength(3),
        ]),
        scenarioType: new FormControl(data.scenarioType ? data.scenarioType.name : '', [
          Validators.required,
          Validators.minLength(3),
        ]),
        scenarioName: new FormControl(data.scenarioName, [Validators.required, Validators.minLength(3)]),
        handover: new FormControl(
          data.developmentArea && data.scenarioType && data.handoverNumber ? data.handoverNumber.toString() : ''
        ),
      });
      this.saveAsOpened = false;
      this.saveAsSubmitted = false;
    });

    this.scenarioStoreService.scenarioID$.subscribe(i => {
      this.scenarioID = i;
      /* if (this.scenarioID != Guid.EMPTY) {
        this.openedScenario = true;
      }
      else {
        this.openedScenario = false;
      } */
    });
  }

  get scenarioName() {
    return this.saveAsForm.get('scenarioName');
  }

  public testAuthentication(): void {
    this.authRslt = this.authenticationService.getCurrentUser();
  }

  public onSelect({ item }): void {
    if (item.path === 'reports/ReportOut') {
      if (
        (this.newScenarioWithChanges === false && this.openedScenario === true && this.scenarioID !== Guid.EMPTY) ||
        (this.openedScenario && this.newScenarioWithChanges && this.scenarioID === Guid.EMPTY) ||
        (this.openedScenario && this.newScenarioWithChanges && this.scenarioID !== Guid.EMPTY) ||
        (this.newScenarioWithChanges && this.openedScenario === false && this.scenarioID !== Guid.EMPTY) ||
        this.scenarioID !== Guid.EMPTY
      ) {
        this.tryExport = true;

        this.showConfirmation(item);
      } else {
        this.scenarioStoreService.changeTryExport(true);
        this.scenarioStoreService.changeNewScenario(false);
        this.spinner.show();
        setTimeout(() => {
          this.spinner.hide();
          this.router.navigate([item.path]);
        }, 3000);
        this.openedScenario = false;
        this.newScenarioWithChanges = false;
      }
    } else if (!item.items) {
      if (
        this.scenarioID !== '00000000-0000-0000-0000-000000000000' ||
        this.openedScenario ||
        this.newScenarioWithChanges
      ) {
        this.showConfirmation(item);
      }
      else {
        if (item.path === 'Scenario/List') {
          if (this.scenarioID !== Guid.EMPTY) {
            this.openedScenario = false;
            this.newScenarioWithChanges = true;
          } else {
            this.newScenarioWithChanges = false;
            this.openedScenario = false;
          }
        } else {
          this.newScenarioWithChanges = false;
          this.scenarioStoreService.changeTryExport(false);
          this.scenarioStoreService.changeNewScenario(false);
        }
        this.router.navigate([item.path]);
      }
    }
  }

  public closeSaveAs(): void {
    this.saveAsOpened = false;
  }

  public saveAs(value: any, valid: boolean) {
    if (this.saveAsForm.valid) {
      this.saveAsSubmitted = true;

      const pricetScenarioSaveAs = this.scenarioStoreService.getScenarioDataToSaveAs(this.scenarioName.value);
      if (this.validateScenario(pricetScenarioSaveAs)) {
        this.showInfo('Saving As scenario...');
        this.spinner.show();
        this.dataService.savePricetScenario(pricetScenarioSaveAs).subscribe(
          (data: any) => {
            this.onSaveAsScenarioComplete(data, pricetScenarioSaveAs.id);
          },
          (err: any) => {
            this.onSaveScenarioError(err);
          }
        );
      } else {
        return;
      }
    }
  }

  public createNew(item: any) {
    
  }

  public validateScenario(entity: IScenario): boolean {
    let isValid = true;

    if (entity === null || entity === undefined) {
      this.showWarning('The information of the scenario is required.');
      isValid = false;
    } else {
      if (entity.developmentAreaId === null || entity.developmentAreaId === Guid.EMPTY) {
        this.showWarning('The Development Area is required.');
        isValid = false;
      }
      if (entity.name === null || entity.name.trim() === '') {
        this.showWarning('The Scenario name is required.');
        isValid = false;
      }
      if (entity.scenarioTypeId === null || entity.scenarioTypeId === Guid.EMPTY) {
        this.showWarning('The Scenario Type is required.');
        isValid = false;
      }
      if (entity.costYear === null || entity.costYear <= 0) {
        this.showWarning('Scenario Inputs Cost Year is not valid.');
        isValid = false;
      }
      if (entity.padBrackets === null) {
        this.showWarning('PadBrakets Data required.');
        isValid = false;
      }
      if (entity.padBrackets !== null && entity.padBrackets.length === 0) {
        this.showWarning('PadBrakets Data required.');
        isValid = false;
      }
      if (entity.items === null) {
        this.showWarning('Scenario Items required.');
        isValid = false;
      }
      if (entity.items !== null && entity.items.length === 0) {
        this.showWarning('Scenario Items required.');
        isValid = false;
      }
      if (entity.padItemInventories === null) {
        this.showWarning('Scope Counts values required.');
        isValid = false;
      }
      if (entity.padItemInventories !== null && entity.padItemInventories.length === 0) {
        this.showWarning('Scope Counts values required.');
        isValid = false;
      }

      if (entity.isSaveAs !== null && entity.isSaveAs !== undefined && entity.isSaveAs) {
        if (
          entity.sourceScenarioId === null ||
          entity.sourceScenarioId === undefined ||
          entity.sourceScenarioId === Guid.EMPTY
        ) {
          this.showWarning('To Save As and create a copy, the source scenario is required');
          isValid = false;
        } else if (entity.sourceScenarioId === entity.id) {
          this.showWarning('To Save As and create a copy, the source scenario is wrong.');
          isValid = false;
        }
      }
    }

    return isValid;
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'warning', icon: true },
    });
  }

  public showInfo(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'info', icon: true },
    });
  }

  public onSaveScenarioComplete(value: any) {
    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'The save was completed.',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
      });
    }, 6000);

    this.saveAsSubmitted = false;
    this.saveAsOpened = false;
    this.scenarioStoreService.setImportExecuted(false);
  }

  public onSaveScenarioError(value: any) {
    setTimeout(() => {
      this.spinner.hide();
      this.showWarning('There was a problem saving');
    }, 6000);

    this.saveAsSubmitted = false;
  }

  public showConfirmation(item: any) {
    if (item.path === 'menu/CreateNew') {
      this.scenarioStoreService.deletePadbuilderDataImported();
      this.createNew(item);
    } else if (item.path === 'menu/Scenario/List') {
      this.newScenarioWithChanges = false;
      this.openedScenario = false;
      this.tryExport = false;
      this.scenarioID = Guid.EMPTY;

      this.router.navigate([item.path]);
    } else if (item.path === 'reports/ReportOut') {
      this.tryExport = false;
      this.openedScenario = false;
      this.newScenarioWithChanges = false;
      this.scenarioID = Guid.EMPTY;
      this.router.navigate([item.path]);
    } else if (item.path === 'performanceBOD/CreateNew') {
      this.performanceBodStoreService._newPerformanceBOD$.next(true);
      this.performanceBodStoreService._performanceBodId$.next('');
      this.router.navigate([item.path]);
    } else if (item.path === 'performanceBOD/AllBODs') {
      this.performanceBodStoreService._newPerformanceBOD$.next(false);
      this.performanceBodStoreService._performanceBodId$.next('');
      this.router.navigate([item.path]);
    } else if (item.path === 'performanceBOD/MyBODs') {
      this.router.navigate([item.path]);
    } else {
      this.router.navigate([item.path]);
      this.openedScenario = false;
      this.newScenarioWithChanges = false;
      this.scenarioID = Guid.EMPTY;
      this.performanceBodStoreService._newPerformanceBOD$.next(false);
      this.performanceBodStoreService._performanceBodId$.next('');
    }
    // }
    // else {
    //   this.tryExport = false;
    //   if (item.path === 'menu/Open') {
    //     this.openedScenario = true;
    //     this.newScenarioWithChanges = true;
    //   } else {
    //     // this.newScenarioWithChanges = false;
    //     this.scenarioStoreService.changeTryExport(false);
    //     // this.scenarioStoreService.changeNewScenario(false);
    //   }
    // }
    //}

    //this.result = JSON.stringify(result);
    //});
  }

  public onSaveAsScenarioComplete(value: any, id: string) {
    if (id !== undefined && id !== null) {
      this.newScenarioWithChanges = false;
      this.openedScenario = false;
      this.tryExport = false;
      this.scenarioID = Guid.EMPTY;
      // this.scenarioStoreService.changeTryExport(false);
      // this.scenarioStoreService.changeNewScenario(true);
    }
    setTimeout(() => {
      this.scenarioStoreService.openScenario(id);
      this.scenarioStoreService.changeTab(1);
      this.spinner.hide();
      this.notificationService.show({
        content: 'The Save As was completed.',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
      });
    }, 6000);

    this.saveAsSubmitted = false;
    this.saveAsOpened = false;
    this.scenarioStoreService.setImportExecuted(false);
  }

  // convert the routes to menu items.
  private mapItems(routes: any[], path?: string): any[] {
    let list: any;
    if (this.permissionsProvider.isAdmin) {
      list = routes
        .filter(item => item.data !== undefined && item.data.isForAdmin === this.permissionsProvider.isAdmin)
        .map(item => {
          let result: any = {};
          // Show ALL
          result = {
            text: item.data.text,
            path: (path ? `${path}/` : '') + item.path,
          };

          if (item.data.subModuleRoutes) {
            if (item.data.subModuleRoutes === 'InfrastructureBod') {
              result.items = infrastructureRoutesItems;
            }
            if (item.data.subModuleRoutes === 'ExistingFacilities') {
              result.items = existingFacilitiesRoutesItems;
            }
            if (item.data.subModuleRoutes === 'Admin') {
              result.items = adminRoutesItems;
            }
            if (item.data.subModuleRoutes === 'StandardFacility') {
              result.items = standardFacilityRoutesItems;
            }
            if (item.data.subModuleRoutes === 'performanceBOD') {
              result.items = PerformanceBodRoutesItems;
            }
          }

          if (item.children) {
            result.items = this.mapItems(item.children, item.path);
          }
          return result;
        });
      return list;
    }

    if (this.isInGlamGroup) {
      list = routes
        .filter(item => item.data !== undefined && item.data.isInGlamGroup === this.isInGlamGroup)
        .map(item => {
          let result: any = {};
          // Show ALL
          result = {
            text: item.data.text,
            path: (path ? `${path}/` : '') + item.path,
          };

          if (item.data.subModuleRoutes) {
            if (item.data.subModuleRoutes === 'InfrastructureBod') {
              result.items = infrastructureRoutesItems;
            }
            if (item.data.subModuleRoutes === 'StandardFacility') {
              result.items = standardFacilityRoutesItems;
            }
            if (item.data.subModuleRoutes === 'ExistingFacilities') {
              result.items = existingFacilitiesRoutesItems;
            }
            // Commented for Releases 2.9
            if (item.data.subModuleRoutes === 'performanceBOD') {
              result.items = PerformanceBodRoutesItems;
            }
          }

          if (item.children) {
            result.items = this.mapItems(item.children, item.path);
          }
          return result;
        });
      return list;
    }
  }
}
