import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverriddenFieldsService {
  public summaryContingency = false;
  public padConfig: any[] = [];
  public padItemCounts: any[] = [];

  constructor() {
    // empty
  }

  public reset(): void {
    this.summaryContingency = false;
    this.padConfig = [];
    this.padItemCounts = [];
  }

  public isPadConfigFieldOverridden(padName: string, fieldName: string): boolean {
    const field = this.padConfig.find(f => {
      return f.padName === padName && f.fieldName === fieldName;
    });
    if (!field || !field.overridden) {
      return false;
    }
    return true;
  }

  public setPadConfigFieldOverridden(padName: string, fieldName: string, overridden: boolean): void {
    const field = this.padConfig.find(f => {
      return f.padName === padName && f.fieldName === fieldName;
    });
    if (!field) {
      this.padConfig.push({
        padName,
        fieldName,
        overridden,
      });
    } else {
      field.overridden = overridden;
    }
  }

  public isPadItemCountOverridden(padName: string, itemName: string, itemId?: string): boolean {
    const field = this.padItemCounts.find(f => {
      return f.itemName === itemName && f.padName === padName && (itemId? f.itemId === itemId: true);
    });
    if (!field || !field.overridden) {
      return false;
    }
    return true;
  }

  public isPadItemCountOverriddenByDisplayName(padName: string, itemName: string, itemId: string): boolean {
    const field = this.padItemCounts.find(f => {
      return f.displayName === itemName && f.padName === padName && f.itemId == itemId;
    });
    if (!field || !field.overridden) {
      return false;
    }
    return true;
  }

  public setPadItemCountOverridden(padName: string, itemName: string, itemId: string, overridden: boolean): void {
    const field = this.padItemCounts.find(f => {
      return f.padName === padName && f.itemName === itemName && f.itemId == itemId;
    });
    if (!field) {
      this.padItemCounts.push({
        padName,
        itemName,
        overridden,
        itemId
      });
    } else {
      field.overridden = overridden;
    }
  }
}
