import { Component, Inject, Input, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { SftMasterDataLogsService } from '../_services/sft-master-data-logs.service';
import { map } from 'rxjs/operators/map';
import { State as stateList } from 'src/app/api/state';
import { GeographicArea } from 'src/app/api/geographicArea';
import { Basin } from 'src/app/api/basin';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import { UnitOfMeasureService } from 'src/app/modules/admin/services/unit.of.measure.service';
import { UnitOfMeasure } from 'src/app/api/unitOfMeasure-model';
import { Rfsd } from 'src/app/api/rfsd';
import { InfrastructureClass } from 'src/app/api/infrastructure-class';
import { InfrastructureScopeCategory } from 'src/app/api/infrastructure-scope-category';
import { Location } from 'src/app/api/location';
import { Kind } from 'src/app/api/kind';

@Component({
  selector: 'app-sft-master-data-log',
  templateUrl: './sft-master-data-log.component.html',
  styleUrls: ['./sft-master-data-log.component.css']
})
export class SftMasterDataLogComponent implements OnInit {
  public view: Observable<GridDataResult>;
  @Input() public selectedTable: string;
  public stateOptions: stateList[];
  public geographicAreaOptions: GeographicArea[];
  public basinOptions: Basin[];
  public rfsdOptions: Rfsd[];
  public infrastructureTypeOptions: InfrastructureClass[];
  public scopeCategoryOptions: InfrastructureScopeCategory[];
  public locationOptions: Location[];
  public kindOptions: Kind[];
  public unitOfMeasureOptions: UnitOfMeasure[];
  public unitOfMeasureService: UnitOfMeasureService;
  public isFacilityStdDesign: boolean=false;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };
  public isLoading;
  constructor(
    private sftMasterDataLogsService: SftMasterDataLogsService,
    private scenarioStoreService: ScenarioStoreService
  ) { }

  ngOnInit(): void {
    this.sftMasterDataLogsService.getSftMasterDataLogsByTableType(this.selectedTable).subscribe(data => {
      this.scenarioStoreService.stateOptions$.subscribe(stateData => {
        this.stateOptions = stateData;
      });
      this.scenarioStoreService.geographicArea$.subscribe(geographicAreaData => {
        this.geographicAreaOptions = geographicAreaData;
      });
      this.scenarioStoreService.basinOptions$.subscribe(basinData => {
        this.basinOptions = basinData;
      });

      this.scenarioStoreService.rfsdOptions$.subscribe(data => {
        this.rfsdOptions = data;
      });
      this.scenarioStoreService.scopeCategoryOptions$.subscribe(data => {
        this.scopeCategoryOptions = data;
      });
      this.scenarioStoreService.infrastructureClassOptions$.subscribe(data => {
        this.infrastructureTypeOptions = data;
      });
      this.scenarioStoreService.locationOptions$.subscribe(data => {
        this.locationOptions = data;
      });
      this.scenarioStoreService.kindOptions$.subscribe(data => {
        this.kindOptions = data;
      });
      this.scenarioStoreService.unitOfMeasureOptions$.subscribe(data => {
        this.unitOfMeasureOptions = data;
      });

      data.forEach(obj => {
        if (this.selectedTable == 'DevelopmentArea') {
          if (obj.fieldName == 'State Id') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousStateName = this.stateOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousStateName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentStateName = this.stateOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentStateName;
            }
          }
          else if (obj.fieldName == 'Geographic Area Id') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousGeoAreaName = this.geographicAreaOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousGeoAreaName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentGeoAreaName = this.geographicAreaOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentGeoAreaName;
            }
          }
        }
        else if (this.selectedTable == 'GeographicArea') {
          if (obj.fieldName == 'Basin Name') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousBasinName = this.basinOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousBasinName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentBasinName = this.basinOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentBasinName;
            }
          }
        }
        else if (this.selectedTable == 'LandDamages') {
          if (obj.fieldName == 'Unit Of Measure') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousUnitOfMeasureName = this.unitOfMeasureOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousUnitOfMeasureName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentUnitOfMeasureName = this.unitOfMeasureOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentUnitOfMeasureName;
            }
          }
        }
        else if (this.selectedTable == 'FacilityItems') {
          if (obj.fieldName == 'Ready For Service Date Timing') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousrfsdOName = this.rfsdOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousrfsdOName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentrfsdName = this.rfsdOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentrfsdName;
            }
          }
          else if (obj.fieldName == 'Scope Category') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousScopeCategoryName = this.scopeCategoryOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousScopeCategoryName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentScopeCategoryName = this.scopeCategoryOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentScopeCategoryName;
            }
          }
          else if (obj.fieldName == 'Infrastructure Type') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousInfrastructureTypeName = this.infrastructureTypeOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousInfrastructureTypeName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentInfrastructureTypeName = this.infrastructureTypeOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentInfrastructureTypeName;
            }
          }
          else if (obj.fieldName == 'Location Name') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousLocationName = this.locationOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousLocationName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentLocationName = this.locationOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentLocationName;
            }
          }
          else if (obj.fieldName == 'Kind Name') {
            if (obj.previousValue != null && obj.previousValue != undefined) {
              const previousKindName = this.kindOptions.find(s => s.id === obj.previousValue).name;
              obj.previousValue = previousKindName;
            }
            if (obj.currentValue != null && obj.currentValue != undefined) {
              const currentKindName = this.kindOptions.find(s => s.id === obj.currentValue).name;
              obj.currentValue = currentKindName;
            }
          }
        }
      });
      if (data.length > 0) {
        this.view = this.sftMasterDataLogsService.pipe(map(data => process(data, this.gridState)));
        this.sftMasterDataLogsService.read();
      }

    });
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.sftMasterDataLogsService.read();
  }
}
