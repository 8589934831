import { IFitCCDropDown } from './fit-cc-idrop-dowm';
import { FitCcInputTypes } from '../lib/fit-cc-input/models/fit-cc-input-types.enum';

export interface IFitCcBaseColumn {
  field: string;
  title: string;
  format?: string;
  type?:
    | 'text'
    | 'numeric'
    | 'boolean'
    | 'date'
    | 'dropdown'
    | 'multiselect'
    | 'custom'
    | 'textarea'
    | FitCcInputTypes.TEXT
    | FitCcInputTypes.GEOGRAPHY
    | FitCcInputTypes.DATETIME
    | FitCcInputTypes.INTEGER
    | FitCcInputTypes.DECIMAL;
  width?: string;
  hiddenInGrid?: boolean;
  hidden?: boolean;
  required?: boolean;
  options?: IFitCCDropDown[];
  extended?: string;
  inner?: IFitCcBaseColumn;
  optionstemp?: IFitCCDropDown[];
  nameColumnRelated?: string;
  dependencyColumns?: string[];
  setOtherField?: string[];
  disabledNew?: boolean;
  disabledEdit?: boolean;
  enableBulkUpdate?: boolean;
  aggregate?: 'count' | 'average';
  filterByValue?: boolean;
  decimals?: number;
}
