import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcPopupButtonComponent } from './components/fit-cc-popup-button.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule
  ],
  declarations: [FitCcPopupButtonComponent],
  exports: [FitCcPopupButtonComponent]
})
export class FitCcPopupButtonModule { }