<div class="card-body">
  <kendo-grid [data]="gridView" [resizable]="true" [skip]="skip" [pageSize]="pageSize" [scrollable]="'virtual'" [loading]="isLoading"
              [rowHeight]="34" [height]="700" (pageChange)="pageChange($event)" [style.maxHeight.px]="700" [filterable]="true"
              (dataStateChange)="onDataStateChange($event)" [filter]="state.filter" [sortable]="{ allowUnsort: allowUnsort, mode: 'multiple'}"
              [sort]="sort" (sortChange)="sortChange($event)" (columnReorder)="onColumnReorder($event)" [reorderable]="true">
    <kendo-grid-column [locked]="true" field="displayName" title="Item" width="150" [reorderable]="false">
      <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
        <div style="margin-top:57px;">
          {{column.title}}
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div>
          {{dataItem.displayName}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="itemDetail" title="Detail" width="100" [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div kendoTooltip title="{{dataItem.itemDetail.itemDetailNotes}}" [showAfter]="0">
          {{dataItem.itemDetail.displayName}}
        </div>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="numeric" field="costPunit" title="Cost/Unit ($M)" width="130" [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div>
          {{dataItem.costPunit | currency : 'USD' : 'symbol': '1.0'}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="numeric" field="total" title="Total ($M)" width="100" editor="numeric" [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div>
          {{dataItem.total | currency : 'USD' : 'symbol': '1.0'}}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group *ngIf="padBuilderPads.length > 0" width="100" [title]="padCountsColumnGroupName" [reorderable]="false">
      <ng-container *ngFor="let pad of padBuilderPads">
        <kendo-grid-column [sortable]="false" filter="numeric" [field]="pad.padName" [title]="pad.pbPadName" width="150" editor="numeric">
          <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
            <strong>
              {{column.title}}
            </strong>
            {{getPadContingency(pad.padName) | percent: '1.1-2'}}
            <strong>
              <i>
                {{getPadSum(pad.padName) | currency : 'USD' : 'symbol': '1.0' }}
              </i>
            </strong>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>
              {{dataItem[pad.padName] | currency : 'USD' : 'symbol': '1.0' }}
            </span>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid-column-group>
  </kendo-grid>
  <app-cost-sheet-pager></app-cost-sheet-pager>
</div>
