export const sampleSummaryData = [
  {
    ProductID: 1,
    ProductName: 'Chai',
    SupplierID: 1,
    CategoryID: 1,
    QuantityPerUnit: '10 boxes x 20 bags',
    UnitPrice: 18,
    UnitsInStock: 39,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
    FirstOrderedOn: new Date(1996, 8, 20),
  },
  {
    ProductID: 2,
    ProductName: 'Chang',
    SupplierID: 1,
    CategoryID: 1,
    QuantityPerUnit: '24 - 12 oz bottles',
    UnitPrice: 19,
    UnitsInStock: 17,
    UnitsOnOrder: 40,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
    FirstOrderedOn: new Date(1996, 7, 12),
  },
  {
    ProductID: 3,
    ProductName: 'Aniseed Syrup',
    SupplierID: 1,
    CategoryID: 2,
    QuantityPerUnit: '12 - 550 ml bottles',
    UnitPrice: 10,
    UnitsInStock: 13,
    UnitsOnOrder: 70,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
    FirstOrderedOn: new Date(1996, 8, 26),
  },
  {
    ProductID: 4,
    ProductName: "Chef Anton's Cajun Seasoning",
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '48 - 6 oz jars',
    UnitPrice: 22,
    UnitsInStock: 53,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
    FirstOrderedOn: new Date(1996, 9, 19),
  },
  {
    ProductID: 5,
    ProductName: "Chef Anton's Gumbo Mix",
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '36 boxes',
    UnitPrice: 21.35,
    UnitsInStock: 0,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
    FirstOrderedOn: new Date(1996, 7, 17),
  },
  {
    ProductID: 6,
    ProductName: "Grandma's Boysenberry Spread",
    SupplierID: 3,
    CategoryID: 2,
    QuantityPerUnit: '12 - 8 oz jars',
    UnitPrice: 25,
    UnitsInStock: 120,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
    FirstOrderedOn: new Date(1996, 9, 19),
  },
  {
    ProductID: 7,
    ProductName: "Uncle Bob's Organic Dried Pears",
    SupplierID: 3,
    CategoryID: 7,
    QuantityPerUnit: '12 - 1 lb pkgs.',
    UnitPrice: 30,
    UnitsInStock: 15,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
    FirstOrderedOn: new Date(1996, 7, 22),
  },
  {
    ProductID: 8,
    ProductName: 'Northwoods Cranberry Sauce',
    SupplierID: 3,
    CategoryID: 2,
    QuantityPerUnit: '12 - 12 oz jars',
    UnitPrice: 40,
    UnitsInStock: 6,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
    FirstOrderedOn: new Date(1996, 11, 1),
  },
  {
    ProductID: 9,
    ProductName: 'Mishi Kobe Niku',
    SupplierID: 4,
    CategoryID: 6,
    QuantityPerUnit: '18 - 500 g pkgs.',
    UnitPrice: 97,
    UnitsInStock: 29,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
    FirstOrderedOn: new Date(1997, 1, 21),
  },
  {
    ProductID: 10,
    ProductName: 'Ikura',
    SupplierID: 4,
    CategoryID: 8,
    QuantityPerUnit: '12 - 200 ml jars',
    UnitPrice: 31,
    UnitsInStock: 31,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
    FirstOrderedOn: new Date(1996, 8, 5),
  },
];

export const products = [
  {
    ProductID: 1,
    ProductName: 'Chai',
    SupplierID: 1,
    CategoryID: 1,
    QuantityPerUnit: '10 boxes x 20 bags',
    UnitPrice: 18.0,
    UnitsInStock: 39,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 2,
    ProductName: 'Chang',
    SupplierID: 1,
    CategoryID: 1,
    QuantityPerUnit: '24 - 12 oz bottles',
    UnitPrice: 19.0,
    UnitsInStock: 17,
    UnitsOnOrder: 40,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 3,
    ProductName: 'Aniseed Syrup',
    SupplierID: 1,
    CategoryID: 2,
    QuantityPerUnit: '12 - 550 ml bottles',
    UnitPrice: 10.0,
    UnitsInStock: 13,
    UnitsOnOrder: 70,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 4,
    ProductName: "Chef Anton's Cajun Seasoning",
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '48 - 6 oz jars',
    UnitPrice: 22.0,
    UnitsInStock: 53,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 5,
    ProductName: "Chef Anton's Gumbo Mix",
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '36 boxes',
    UnitPrice: 21.35,
    UnitsInStock: 0,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 6,
    ProductName: "Grandma's Boysenberry Spread",
    SupplierID: 3,
    CategoryID: 2,
    QuantityPerUnit: '12 - 8 oz jars',
    UnitPrice: 25.0,
    UnitsInStock: 120,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 7,
    ProductName: "Uncle Bob's Organic Dried Pears",
    SupplierID: 3,
    CategoryID: 7,
    QuantityPerUnit: '12 - 1 lb pkgs.',
    UnitPrice: 30.0,
    UnitsInStock: 15,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
  },
  {
    ProductID: 8,
    ProductName: 'Northwoods Cranberry Sauce',
    SupplierID: 3,
    CategoryID: 2,
    QuantityPerUnit: '12 - 12 oz jars',
    UnitPrice: 40.0,
    UnitsInStock: 6,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 9,
    ProductName: 'Mishi Kobe Niku',
    SupplierID: 4,
    CategoryID: 6,
    QuantityPerUnit: '18 - 500 g pkgs.',
    UnitPrice: 97.0,
    UnitsInStock: 29,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 10,
    ProductName: 'Ikura',
    SupplierID: 4,
    CategoryID: 8,
    QuantityPerUnit: '12 - 200 ml jars',
    UnitPrice: 31.0,
    UnitsInStock: 31,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 11,
    ProductName: 'Queso Cabrales',
    SupplierID: 5,
    CategoryID: 4,
    QuantityPerUnit: '1 kg pkg.',
    UnitPrice: 21.0,
    UnitsInStock: 22,
    UnitsOnOrder: 30,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 12,
    ProductName: 'Queso Manchego La Pastora',
    SupplierID: 5,
    CategoryID: 4,
    QuantityPerUnit: '10 - 500 g pkgs.',
    UnitPrice: 38.0,
    UnitsInStock: 86,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 13,
    ProductName: 'Konbu',
    SupplierID: 6,
    CategoryID: 8,
    QuantityPerUnit: '2 kg box',
    UnitPrice: 6.0,
    UnitsInStock: 24,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 14,
    ProductName: 'Tofu',
    SupplierID: 6,
    CategoryID: 7,
    QuantityPerUnit: '40 - 100 g pkgs.',
    UnitPrice: 23.25,
    UnitsInStock: 35,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
  },
  {
    ProductID: 15,
    ProductName: 'Genen Shouyu',
    SupplierID: 6,
    CategoryID: 2,
    QuantityPerUnit: '24 - 250 ml bottles',
    UnitPrice: 15.5,
    UnitsInStock: 39,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 16,
    ProductName: 'Pavlova',
    SupplierID: 7,
    CategoryID: 3,
    QuantityPerUnit: '32 - 500 g boxes',
    UnitPrice: 17.45,
    UnitsInStock: 29,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 17,
    ProductName: 'Alice Mutton',
    SupplierID: 7,
    CategoryID: 6,
    QuantityPerUnit: '20 - 1 kg tins',
    UnitPrice: 39.0,
    UnitsInStock: 0,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 18,
    ProductName: 'Carnarvon Tigers',
    SupplierID: 7,
    CategoryID: 8,
    QuantityPerUnit: '16 kg pkg.',
    UnitPrice: 62.5,
    UnitsInStock: 42,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 19,
    ProductName: 'Teatime Chocolate Biscuits',
    SupplierID: 8,
    CategoryID: 3,
    QuantityPerUnit: '10 boxes x 12 pieces',
    UnitPrice: 9.2,
    UnitsInStock: 25,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 20,
    ProductName: "Sir Rodney's Marmalade",
    SupplierID: 8,
    CategoryID: 3,
    QuantityPerUnit: '30 gift boxes',
    UnitPrice: 81.0,
    UnitsInStock: 40,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 21,
    ProductName: "Sir Rodney's Scones",
    SupplierID: 8,
    CategoryID: 3,
    QuantityPerUnit: '24 pkgs. x 4 pieces',
    UnitPrice: 10.0,
    UnitsInStock: 3,
    UnitsOnOrder: 40,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 22,
    ProductName: "Gustaf's Knäckebröd",
    SupplierID: 9,
    CategoryID: 5,
    QuantityPerUnit: '24 - 500 g pkgs.',
    UnitPrice: 21.0,
    UnitsInStock: 104,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 23,
    ProductName: 'Tunnbröd',
    SupplierID: 9,
    CategoryID: 5,
    QuantityPerUnit: '12 - 250 g pkgs.',
    UnitPrice: 9.0,
    UnitsInStock: 61,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 24,
    ProductName: 'Guaraná Fantástica',
    SupplierID: 10,
    CategoryID: 1,
    QuantityPerUnit: '12 - 355 ml cans',
    UnitPrice: 4.5,
    UnitsInStock: 20,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 25,
    ProductName: 'NuNuCa Nuß-Nougat-Creme',
    SupplierID: 11,
    CategoryID: 3,
    QuantityPerUnit: '20 - 450 g glasses',
    UnitPrice: 14.0,
    UnitsInStock: 76,
    UnitsOnOrder: 0,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 26,
    ProductName: 'Gumbär Gummibärchen',
    SupplierID: 11,
    CategoryID: 3,
    QuantityPerUnit: '100 - 250 g bags',
    UnitPrice: 31.23,
    UnitsInStock: 15,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 27,
    ProductName: 'Schoggi Schokolade',
    SupplierID: 11,
    CategoryID: 3,
    QuantityPerUnit: '100 - 100 g pieces',
    UnitPrice: 43.9,
    UnitsInStock: 49,
    UnitsOnOrder: 0,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 28,
    ProductName: 'Rössle Sauerkraut',
    SupplierID: 12,
    CategoryID: 7,
    QuantityPerUnit: '25 - 825 g cans',
    UnitPrice: 45.6,
    UnitsInStock: 26,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
  },
  {
    ProductID: 29,
    ProductName: 'Thüringer Rostbratwurst',
    SupplierID: 12,
    CategoryID: 6,
    QuantityPerUnit: '50 bags x 30 sausgs.',
    UnitPrice: 123.79,
    UnitsInStock: 0,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 30,
    ProductName: 'Nord-Ost Matjeshering',
    SupplierID: 13,
    CategoryID: 8,
    QuantityPerUnit: '10 - 200 g glasses',
    UnitPrice: 25.89,
    UnitsInStock: 10,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 31,
    ProductName: 'Gorgonzola Telino',
    SupplierID: 14,
    CategoryID: 4,
    QuantityPerUnit: '12 - 100 g pkgs',
    UnitPrice: 12.5,
    UnitsInStock: 0,
    UnitsOnOrder: 70,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 32,
    ProductName: 'Mascarpone Fabioli',
    SupplierID: 14,
    CategoryID: 4,
    QuantityPerUnit: '24 - 200 g pkgs.',
    UnitPrice: 32.0,
    UnitsInStock: 9,
    UnitsOnOrder: 40,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 33,
    ProductName: 'Geitost',
    SupplierID: 15,
    CategoryID: 4,
    QuantityPerUnit: '500 g',
    UnitPrice: 2.5,
    UnitsInStock: 112,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 34,
    ProductName: 'Sasquatch Ale',
    SupplierID: 16,
    CategoryID: 1,
    QuantityPerUnit: '24 - 12 oz bottles',
    UnitPrice: 14.0,
    UnitsInStock: 111,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 35,
    ProductName: 'Steeleye Stout',
    SupplierID: 16,
    CategoryID: 1,
    QuantityPerUnit: '24 - 12 oz bottles',
    UnitPrice: 18.0,
    UnitsInStock: 20,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 36,
    ProductName: 'Inlagd Sill',
    SupplierID: 17,
    CategoryID: 8,
    QuantityPerUnit: '24 - 250 g  jars',
    UnitPrice: 19.0,
    UnitsInStock: 112,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 37,
    ProductName: 'Gravad lax',
    SupplierID: 17,
    CategoryID: 8,
    QuantityPerUnit: '12 - 500 g pkgs.',
    UnitPrice: 26.0,
    UnitsInStock: 11,
    UnitsOnOrder: 50,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 38,
    ProductName: 'Côte de Blaye',
    SupplierID: 18,
    CategoryID: 1,
    QuantityPerUnit: '12 - 75 cl bottles',
    UnitPrice: 263.5,
    UnitsInStock: 17,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 39,
    ProductName: 'Chartreuse verte',
    SupplierID: 18,
    CategoryID: 1,
    QuantityPerUnit: '750 cc per bottle',
    UnitPrice: 18.0,
    UnitsInStock: 69,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 40,
    ProductName: 'Boston Crab Meat',
    SupplierID: 19,
    CategoryID: 8,
    QuantityPerUnit: '24 - 4 oz tins',
    UnitPrice: 18.4,
    UnitsInStock: 123,
    UnitsOnOrder: 0,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 41,
    ProductName: "Jack's New England Clam Chowder",
    SupplierID: 19,
    CategoryID: 8,
    QuantityPerUnit: '12 - 12 oz cans',
    UnitPrice: 9.65,
    UnitsInStock: 85,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 42,
    ProductName: 'Singaporean Hokkien Fried Mee',
    SupplierID: 20,
    CategoryID: 5,
    QuantityPerUnit: '32 - 1 kg pkgs.',
    UnitPrice: 14.0,
    UnitsInStock: 26,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 43,
    ProductName: 'Ipoh Coffee',
    SupplierID: 20,
    CategoryID: 1,
    QuantityPerUnit: '16 - 500 g tins',
    UnitPrice: 46.0,
    UnitsInStock: 17,
    UnitsOnOrder: 10,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 44,
    ProductName: 'Gula Malacca',
    SupplierID: 20,
    CategoryID: 2,
    QuantityPerUnit: '20 - 2 kg bags',
    UnitPrice: 19.45,
    UnitsInStock: 27,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 45,
    ProductName: 'Rogede sild',
    SupplierID: 21,
    CategoryID: 8,
    QuantityPerUnit: '1k pkg.',
    UnitPrice: 9.5,
    UnitsInStock: 5,
    UnitsOnOrder: 70,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 46,
    ProductName: 'Spegesild',
    SupplierID: 21,
    CategoryID: 8,
    QuantityPerUnit: '4 - 450 g glasses',
    UnitPrice: 12.0,
    UnitsInStock: 95,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 47,
    ProductName: 'Zaanse koeken',
    SupplierID: 22,
    CategoryID: 3,
    QuantityPerUnit: '10 - 4 oz boxes',
    UnitPrice: 9.5,
    UnitsInStock: 36,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 48,
    ProductName: 'Chocolade',
    SupplierID: 22,
    CategoryID: 3,
    QuantityPerUnit: '10 pkgs.',
    UnitPrice: 12.75,
    UnitsInStock: 15,
    UnitsOnOrder: 70,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 49,
    ProductName: 'Maxilaku',
    SupplierID: 23,
    CategoryID: 3,
    QuantityPerUnit: '24 - 50 g pkgs.',
    UnitPrice: 20.0,
    UnitsInStock: 10,
    UnitsOnOrder: 60,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 50,
    ProductName: 'Valkoinen suklaa',
    SupplierID: 23,
    CategoryID: 3,
    QuantityPerUnit: '12 - 100 g bars',
    UnitPrice: 16.25,
    UnitsInStock: 65,
    UnitsOnOrder: 0,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 51,
    ProductName: 'Manjimup Dried Apples',
    SupplierID: 24,
    CategoryID: 7,
    QuantityPerUnit: '50 - 300 g pkgs.',
    UnitPrice: 53.0,
    UnitsInStock: 20,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
  },
  {
    ProductID: 52,
    ProductName: 'Filo Mix',
    SupplierID: 24,
    CategoryID: 5,
    QuantityPerUnit: '16 - 2 kg boxes',
    UnitPrice: 7.0,
    UnitsInStock: 38,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 53,
    ProductName: 'Perth Pasties',
    SupplierID: 24,
    CategoryID: 6,
    QuantityPerUnit: '48 pieces',
    UnitPrice: 32.8,
    UnitsInStock: 0,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: true,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 54,
    ProductName: 'Tourtière',
    SupplierID: 25,
    CategoryID: 6,
    QuantityPerUnit: '16 pies',
    UnitPrice: 7.45,
    UnitsInStock: 21,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 55,
    ProductName: 'Pâté chinois',
    SupplierID: 25,
    CategoryID: 6,
    QuantityPerUnit: '24 boxes x 2 pies',
    UnitPrice: 24.0,
    UnitsInStock: 115,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 6,
      CategoryName: 'Meat/Poultry',
      Description: 'Prepared meats',
    },
  },
  {
    ProductID: 56,
    ProductName: 'Gnocchi di nonna Alice',
    SupplierID: 26,
    CategoryID: 5,
    QuantityPerUnit: '24 - 250 g pkgs.',
    UnitPrice: 38.0,
    UnitsInStock: 21,
    UnitsOnOrder: 10,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 57,
    ProductName: 'Ravioli Angelo',
    SupplierID: 26,
    CategoryID: 5,
    QuantityPerUnit: '24 - 250 g pkgs.',
    UnitPrice: 19.5,
    UnitsInStock: 36,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 58,
    ProductName: 'Escargots de Bourgogne',
    SupplierID: 27,
    CategoryID: 8,
    QuantityPerUnit: '24 pieces',
    UnitPrice: 13.25,
    UnitsInStock: 62,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 59,
    ProductName: 'Raclette Courdavault',
    SupplierID: 28,
    CategoryID: 4,
    QuantityPerUnit: '5 kg pkg.',
    UnitPrice: 55.0,
    UnitsInStock: 79,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 60,
    ProductName: 'Camembert Pierrot',
    SupplierID: 28,
    CategoryID: 4,
    QuantityPerUnit: '15 - 300 g rounds',
    UnitPrice: 34.0,
    UnitsInStock: 19,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 61,
    ProductName: "Sirop d'érable",
    SupplierID: 29,
    CategoryID: 2,
    QuantityPerUnit: '24 - 500 ml bottles',
    UnitPrice: 28.5,
    UnitsInStock: 113,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 62,
    ProductName: 'Tarte au sucre',
    SupplierID: 29,
    CategoryID: 3,
    QuantityPerUnit: '48 pies',
    UnitPrice: 49.3,
    UnitsInStock: 17,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 63,
    ProductName: 'Vegie-spread',
    SupplierID: 7,
    CategoryID: 2,
    QuantityPerUnit: '15 - 625 g jars',
    UnitPrice: 43.9,
    UnitsInStock: 24,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 64,
    ProductName: 'Wimmers gute Semmelknödel',
    SupplierID: 12,
    CategoryID: 5,
    QuantityPerUnit: '20 bags x 4 pieces',
    UnitPrice: 33.25,
    UnitsInStock: 22,
    UnitsOnOrder: 80,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 5,
      CategoryName: 'Grains/Cereals',
      Description: 'Breads, crackers, pasta, and cereal',
    },
  },
  {
    ProductID: 65,
    ProductName: 'Louisiana Fiery Hot Pepper Sauce',
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '32 - 8 oz bottles',
    UnitPrice: 21.05,
    UnitsInStock: 76,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 66,
    ProductName: 'Louisiana Hot Spiced Okra',
    SupplierID: 2,
    CategoryID: 2,
    QuantityPerUnit: '24 - 8 oz jars',
    UnitPrice: 17.0,
    UnitsInStock: 4,
    UnitsOnOrder: 100,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
  {
    ProductID: 67,
    ProductName: 'Laughing Lumberjack Lager',
    SupplierID: 16,
    CategoryID: 1,
    QuantityPerUnit: '24 - 12 oz bottles',
    UnitPrice: 14.0,
    UnitsInStock: 52,
    UnitsOnOrder: 0,
    ReorderLevel: 10,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 68,
    ProductName: 'Scottish Longbreads',
    SupplierID: 8,
    CategoryID: 3,
    QuantityPerUnit: '10 boxes x 8 pieces',
    UnitPrice: 12.5,
    UnitsInStock: 6,
    UnitsOnOrder: 10,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 3,
      CategoryName: 'Confections',
      Description: 'Desserts, candies, and sweet breads',
    },
  },
  {
    ProductID: 69,
    ProductName: 'Gudbrandsdalsost',
    SupplierID: 15,
    CategoryID: 4,
    QuantityPerUnit: '10 kg pkg.',
    UnitPrice: 36.0,
    UnitsInStock: 26,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 70,
    ProductName: 'Outback Lager',
    SupplierID: 7,
    CategoryID: 1,
    QuantityPerUnit: '24 - 355 ml bottles',
    UnitPrice: 15.0,
    UnitsInStock: 15,
    UnitsOnOrder: 10,
    ReorderLevel: 30,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 71,
    ProductName: 'Flotemysost',
    SupplierID: 15,
    CategoryID: 4,
    QuantityPerUnit: '10 - 500 g pkgs.',
    UnitPrice: 21.5,
    UnitsInStock: 26,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 72,
    ProductName: 'Mozzarella di Giovanni',
    SupplierID: 14,
    CategoryID: 4,
    QuantityPerUnit: '24 - 200 g pkgs.',
    UnitPrice: 34.8,
    UnitsInStock: 14,
    UnitsOnOrder: 0,
    ReorderLevel: 0,
    Discontinued: false,
    Category: {
      CategoryID: 4,
      CategoryName: 'Dairy Products',
      Description: 'Cheeses',
    },
  },
  {
    ProductID: 73,
    ProductName: 'Röd Kaviar',
    SupplierID: 17,
    CategoryID: 8,
    QuantityPerUnit: '24 - 150 g jars',
    UnitPrice: 15.0,
    UnitsInStock: 101,
    UnitsOnOrder: 0,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 8,
      CategoryName: 'Seafood',
      Description: 'Seaweed and fish',
    },
  },
  {
    ProductID: 74,
    ProductName: 'Longlife Tofu',
    SupplierID: 4,
    CategoryID: 7,
    QuantityPerUnit: '5 kg pkg.',
    UnitPrice: 10.0,
    UnitsInStock: 4,
    UnitsOnOrder: 20,
    ReorderLevel: 5,
    Discontinued: false,
    Category: {
      CategoryID: 7,
      CategoryName: 'Produce',
      Description: 'Dried fruit and bean curd',
    },
  },
  {
    ProductID: 75,
    ProductName: 'Rhönbräu Klosterbier',
    SupplierID: 12,
    CategoryID: 1,
    QuantityPerUnit: '24 - 0.5 l bottles',
    UnitPrice: 7.75,
    UnitsInStock: 125,
    UnitsOnOrder: 0,
    ReorderLevel: 25,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 76,
    ProductName: 'Lakkalikööri',
    SupplierID: 23,
    CategoryID: 1,
    QuantityPerUnit: '500 ml',
    UnitPrice: 18.0,
    UnitsInStock: 57,
    UnitsOnOrder: 0,
    ReorderLevel: 20,
    Discontinued: false,
    Category: {
      CategoryID: 1,
      CategoryName: 'Beverages',
      Description: 'Soft drinks, coffees, teas, beers, and ales',
    },
  },
  {
    ProductID: 77,
    ProductName: 'Original Frankfurter grüne Soße',
    SupplierID: 12,
    CategoryID: 2,
    QuantityPerUnit: '12 boxes',
    UnitPrice: 13.0,
    UnitsInStock: 32,
    UnitsOnOrder: 0,
    ReorderLevel: 15,
    Discontinued: false,
    Category: {
      CategoryID: 2,
      CategoryName: 'Condiments',
      Description: 'Sweet and savory sauces, relishes, spreads, and seasonings',
    },
  },
];

export const samplePadRigData = [
  {
    RigNum: 1,
    PB_Rig_Name: 'Rig 1',
    Start_Date: '9/03/2018',
  },
  {
    RigNum: 2,
    PB_Rig_Name: 'Rig 2',
    Start_Date: '9/03/2018',
  },
  {
    RigNum: 3,
    PB_Rig_Name: 'Rig 3',
    Start_Date: '9/03/2018',
  },
];

export const pivotTableData = [
  {
    InfrastructureType: 'Wellsite',
    CD_15_22_Pad_1: 6944,
    CD_15_22_Pad_2: 6555,
    CD_15_22_Pad_3: 4754,
    Grand_Total: 18253,
  },
  {
    InfrastructureType: 'Shared',
    CD_15_22_Pad_1: 6944,
    CD_15_22_Pad_2: 2343,
    CD_15_22_Pad_3: 6388,
    Grand_Total: 43469,
  },
  {
    InfrastructureType: 'Gas',
    CD_15_22_Pad_1: 6332,
    CD_15_22_Pad_2: 6663,
    CD_15_22_Pad_3: 7987,
    Grand_Total: 54387,
  },
  {
    InfrastructureType: 'Shared',
    CD_15_22_Pad_1: 9765,
    CD_15_22_Pad_2: 6346,
    CD_15_22_Pad_3: 2321,
    Grand_Total: 24243,
  },
  {
    InfrastructureType: 'Shared',
    CD_15_22_Pad_1: 6944,
    CD_15_22_Pad_2: 2343,
    CD_15_22_Pad_3: 6388,
    Grand_Total: 43469,
  },
  {
    InfrastructureType: 'Gas',
    CD_15_22_Pad_1: 6332,
    CD_15_22_Pad_2: 6663,
    CD_15_22_Pad_3: 7987,
    Grand_Total: 54387,
  },
  {
    InfrastructureType: 'Water',
    CD_15_22_Pad_1: 9765,
    CD_15_22_Pad_2: 6346,
    CD_15_22_Pad_3: 2321,
    Grand_Total: 24243,
  },
  {
    InfrastructureType: 'Wellsite',
    CD_15_22_Pad_1: 3434,
    CD_15_22_Pad_2: 6663,
    CD_15_22_Pad_3: 3521,
    Grand_Total: 23424,
  },
  {
    InfrastructureType: 'Gas',
    CD_15_22_Pad_1: 6944,
    CD_15_22_Pad_2: 2343,
    CD_15_22_Pad_3: 6388,
    Grand_Total: 43469,
  },
  {
    InfrastructureType: 'Water',
    CD_15_22_Pad_1: 6332,
    CD_15_22_Pad_2: 6663,
    CD_15_22_Pad_3: 7987,
    Grand_Total: 54387,
  },
  {
    InfrastructureType: 'General',
    CD_15_22_Pad_1: 9765,
    CD_15_22_Pad_2: 6346,
    CD_15_22_Pad_3: 2321,
    Grand_Total: 24243,
  },
  {
    InfrastructureType: 'Water',
    CD_15_22_Pad_1: 34436,
    CD_15_22_Pad_2: 77654,
    CD_15_22_Pad_3: 34566,
    Grand_Total: 95453,
  },
  {
    InfrastructureType: 'General',
    CD_15_22_Pad_1: 9765,
    CD_15_22_Pad_2: 6346,
    CD_15_22_Pad_3: 2321,
    Grand_Total: 24243,
  },
  {
    InfrastructureType: 'Wellsite',
    CD_15_22_Pad_1: 3434,
    CD_15_22_Pad_2: 6663,
    CD_15_22_Pad_3: 3521,
    Grand_Total: 23424,
  },
  {
    InfrastructureType: 'General',
    CD_15_22_Pad_1: 34436,
    CD_15_22_Pad_2: 77654,
    CD_15_22_Pad_3: 34566,
    Grand_Total: 95453,
  },
];

export const inputInfraData = [
  {
    infrastructureType: {
      name: 'Wellsite',
      displayName: 'Wellsite',
    },
    CD_15_22_Pad_1: 1,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 0,
    total: 0,
    itemName: {
      name: 'Greenfield 1-Train CTB Install',
      displayName: 'Greenfield 1-Train CTB Install',
    },
    detail: {
      name: "'17-'18 Design",
      displayName: "'17-'18 Design",
    },
    unit: 'ea',
    costPunit: '1740',
    scopeCategory: {
      name: 'New CTBs',
      displayName: 'New CTBs',
    },
    note:
      'Includes 4x750BBL tanks, single LACT, circ pump, 50 MBWPD water transfer pump capacity,' +
      'Air Compressor, fence, all tank area piping.',
  },
  {
    infrastructureType: {
      name: 'Shared',
      displayName: 'Shared',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 1,
    CD_15_22_Pad_3: 1,
    total: 0,
    itemName: {
      name: 'Greenfield Satellite Install',
      displayName: 'Greenfield Satellite Install',
    },
    detail: {
      name: '',
      displayName: '',
    },
    unit: 'ea',
    costPunit: '1300',
    scopeCategory: {
      name: 'New Satellites',
      displayName: 'New Satellites',
    },
  },
  {
    infrastructureType: {
      name: 'Shared',
      displayName: 'Shared',
    },
    CD_15_22_Pad_1: 1,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 1,
    total: 0,
    itemName: {
      name: 'CTB Pad Cost ($M/Msqft)',
      displayName: 'CTB Pad Cost ($M/Msqft)',
    },
    detail: {
      name: '',
      displayName: '',
    },
    unit: 'Msqft',
    costPunit: '1.2',
    scopeCategory: {
      name: 'CTB Pads',
      displayName: 'CTB Pads',
    },
    note: 'There is a significant difference in pad size required dependent on # trains, HPFE, etc.',
  },
  {
    infrastructureType: {
      name: 'Shared',
      displayName: 'Shared',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 2,
    CD_15_22_Pad_3: 0,
    total: 0,
    itemName: {
      name: 'CTB Train - 12000 BOPD',
      displayName: 'CTB Train - 12000 BOPD',
    },
    detail: {
      name: 'Truncated',
      displayName: 'Truncated',
    },
    unit: 'ea',
    costPunit: '1000',
    scopeCategory: {
      name: 'New CTB Trains',
      displayName: 'New CTB Trains',
    },
    note: 'Includes (2) 4-slot headers, 6x10 tester, 8x30 three-phase, 10x20 HPV, 10x40 HT, (3) Gas Meters',
  },
  {
    infrastructureType: {
      name: 'Shared',
      displayName: 'Shared',
    },
    CD_15_22_Pad_1: 1,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 0,
    total: 0,
    itemName: {
      name: 'CTB Train - 8500 BOPD',
      displayName: 'CTB Train - 8500 BOPD',
    },
    detail: {
      name: '8500 BOPD',
      displayName: '8500 BOPD',
    },
    unit: 'ea',
    costPunit: '2400',
    scopeCategory: {
      name: 'New CTB Trains',
      displayName: 'New CTB Trains',
    },
    note: 'Includes (2) 4-slot headers, 6x10 tester,  8x30 three-phase, 10x20 HPV, 10x20 HT, (3) Gas Meters',
  },
  {
    infrastructureType: {
      name: 'Wellsite',
      displayName: 'Wellsite',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 1,
    total: 0,
    itemName: {
      name: 'High-Pressure Front End (multi-well unit)',
      displayName: 'High-Pressure Front End (multi-well unit)',
    },
    detail: {
      name: '',
      displayName: '',
    },
    unit: 'ea',
    costPunit: '1265',
    scopeCategory: {
      name: 'New HPFEs',
      displayName: 'New HPFEs',
    },
    note: 'note 5',
  },
  {
    infrastructureType: {
      name: 'Wellsite',
      displayName: 'Wellsite',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 2,
    CD_15_22_Pad_3: 0,
    total: 0,
    itemName: {
      name: 'Greenfield Compressor Station Install',
      displayName: 'Greenfield Compressor Station Install',
    },
    detail: {
      name: '',
      displayName: '',
    },
    unit: 'ea',
    costPunit: '2940',
    scopeCategory: {
      name: 'New Compressor Stations',
      displayName: 'New Compressor Stations',
    },
    note:
      'Includes material and greenfield installation cost for 8x15 Inlet Separator,' +
      '(2) 750 BBL Tanks, Cond Xfer Pump, 60 MMSCFD dehy and filter sep, fuel gas skid,' +
      '(2) gas meters, IA Package, Panel Rack, Fence, core CS piping (skids 1,2, A, B, C, and tank area), and Pad.',
  },
];

export const samplePadConfigData = [
  {
    fieldName: { name: 'padOrder', displayName: 'Pad Order' },
    CD_15_22_Pad_1: 1,
    CD_15_22_Pad_2: 2,
    CD_15_22_Pad_3: 3,
  },
  {
    fieldName: { name: 'spudDate', displayName: 'SPUD Date' },
    CD_15_22_Pad_1: new Date('7/1/2019'),
    CD_15_22_Pad_2: new Date('7/22/2021'),
    CD_15_22_Pad_3: new Date('9/29/2021'),
  },
  {
    fieldName: { name: 'popDate', displayName: 'POP Date' },
    CD_15_22_Pad_1: new Date('12/1/2019'),
    CD_15_22_Pad_2: new Date('7/1/2022'),
    CD_15_22_Pad_3: new Date('10/1/2022'),
  },
  {
    fieldName: { name: 'wellCount', displayName: 'Well Count' },
    CD_15_22_Pad_1: 2,
    CD_15_22_Pad_2: 3,
    CD_15_22_Pad_3: 6,
  },
  {
    fieldName: { name: 'padStatus', displayName: 'Pad Status' },
    CD_15_22_Pad_1: 'C&D Space',
    CD_15_22_Pad_2: 'C&D Space',
    CD_15_22_Pad_3: 'Funded',
  },
  {
    fieldName: { name: 'targetSatellite', displayName: 'Target Satellite' },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: { name: 'targetCtb', displayName: 'Target CTB' },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: { name: 'targetTrain', displayName: 'Target Train' },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'satelliteExecutionCategory',
      displayName: 'Satellite Execution Category',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'ctbExecutionCategory',
      displayName: 'CTB Execution Category',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'cumulativeWellCount',
      displayName: 'Cumulative Well Count',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 0,
  },
  {
    fieldName: {
      name: 'cumulativeHeaderSlots',
      displayName: 'Cumulative Header Slots',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 0,
  },
  {
    fieldName: {
      name: 'cumulativeTestSeparators',
      displayName: 'Cumulative Test Separators',
    },
    CD_15_22_Pad_1: 0,
    CD_15_22_Pad_2: 0,
    CD_15_22_Pad_3: 0,
  },
  {
    fieldName: { name: 'wellTestLocation', displayName: 'Well Test Location' },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'associatedCompStation',
      displayName: 'Associated Comp. Station',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: { name: 'compressionRfsd', displayName: 'Compression RFSD' },
    CD_15_22_Pad_1: new Date(),
    CD_15_22_Pad_2: new Date(),
    CD_15_22_Pad_3: new Date(),
  },
  {
    fieldName: {
      name: 'compExecutionCategory',
      displayName: 'Comp. Execution Category',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'associatedSwdFacility',
      displayName: 'Associated SWD Facility',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: {
      name: 'waterDisposalRfsd',
      displayName: 'Water Disposal RFSD',
    },
    CD_15_22_Pad_1: new Date(),
    CD_15_22_Pad_2: new Date(),
    CD_15_22_Pad_3: new Date(),
  },
  {
    fieldName: {
      name: 'swdExecutionCategory',
      displayName: 'SWD Execution Category',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: { name: 'fracPond', displayName: 'Frac Pond' },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
  {
    fieldName: { name: 'fracPondRfsd', displayName: 'Frac Pond RFSD' },
    CD_15_22_Pad_1: new Date(),
    CD_15_22_Pad_2: new Date(),
    CD_15_22_Pad_3: new Date(),
  },
  {
    fieldName: {
      name: 'pondExecutionCategory',
      displayName: 'Pond Execution Category',
    },
    CD_15_22_Pad_1: '',
    CD_15_22_Pad_2: '',
    CD_15_22_Pad_3: '',
  },
];

export const samplePadConfigData2 = [
  {
    padName: 'CD_15_22_Pad_1',
    spudDate: '7/1/2019',
    popDate: '12/1/2019',
    wellCount: 2,
    padStatus: 'C&D Space',
    targetSatellite: '',
    targetCTB: '',
    targetTrain: '',
    associatedCompStation: '',
    fracPond: '',
  },
  {
    padName: 'CD_15_22_Pad_2',
    spudDate: '7/22/2021',
    popDate: '7/1/2022',
    wellCount: 2,
    padStatus: 'C&D Space',
    targetSatellite: '',
    targetCTB: '',
    targetTrain: '',
    associatedCompStation: '',
    fracPond: '',
  },
  {
    padName: 'CD_15_22_Pad_3',
    spudDate: '9/29/2021',
    popDate: '9/29/2021',
    wellCount: 2,
    padStatus: 'C&D Space',
    targetSatellite: '',
    targetCTB: '',
    targetTrain: '',
    associatedCompStation: '',
    fracPond: '',
  },
];

export const developmentAreaOptions = ['Bradford / CMC', 'Brininstool', 'Cotton Draw'];
export const scenarioTypeOptions = [
  'DAS Base Case',
  'DAS Accel Case',
  'DAS Decel Case',
  'Max Rig Case',
  'Handover',
  'Recycle',
];

export const developmentAreas = [
  {
    name: 'Bradford / CMC',
    abbreviation: 'BRDCMC',
    state: {
      name: 'TX',
      taxRate: 8.25,
    },
    basin: {
      name: 'Midland',
    },
    geographicArea: {
      name: 'MCC',
    },
  },
  {
    name: 'Brininstool',
    abbreviation: 'BRNSTL',
    state: {
      name: 'NM',
      taxRate: 5.83,
    },
    basin: {
      name: 'Delaware',
    },
    geographicArea: {
      name: 'South Lea',
    },
  },
  {
    name: 'Cotton Draw',
    abbreviation: 'CTNDRW',
    state: {
      name: 'NM',
      taxRate: 5.83,
    },
    basin: {
      name: 'Delaware',
    },
    geographicArea: {
      name: 'South Lea',
    },
  },
];

export const padStatusOptions = ['C&D Space', 'Handed Over', 'Funded', 'In Operation'];

export const mockRfsds = [
  {
    $id: '1',
    id: '8B8A7EEE-4256-4D0F-9B73-D909CE6D2A34',
    name: 'SPUD',
    displayName: 'SPUD',
  },
  {
    $id: '2',
    id: '58B3DA3D-C1F4-4485-A6B6-28409F68E6C0',
    name: 'POP',
    displayName: 'POP',
  },
  {
    $id: '3',
    id: '4CEE313C-F0F7-494E-B1BF-9591E21316E9',
    name: 'CS RFSD',
    displayName: 'CS RFSD',
  },
  {
    $id: '4',
    id: 'EF5B7F53-DE18-472B-8859-8C061528F11F',
    name: 'SWD RFSD',
    displayName: 'SWD RFSD',
  },
  {
    $id: '5',
    id: '3E0D38F3-FEFB-43A9-B99F-2A245DFC72FE',
    name: 'Pond RFSD',
    displayName: 'Pond RFSD',
  },
];
export const userPermissionsTableData = [
  {
    UserID: 1,
    CAIGlamGroup: 'fxkh',
    Admin: true,
    EditCreate: true,
    ViewOnly: false,
  },
  {
    UserID: 2,
    CAIGlamGroup: 'jsdd',
    Admin: true,
    EditCreate: true,
    ViewOnly: true,
  },
  {
    UserID: 3,
    CAIGlamGroup: 'oepq',
    Admin: false,
    EditCreate: true,
    ViewOnly: false,
  },
];

export const loggingUserMessages = [
  {
    source: 'getPadContingencyAssumptions',
    userMessage: 'Couldnt load Pad Contingencies',
  },
  { source: 'getInputItems', userMessage: 'Couldnt load Input Items' },
  { source: 'getPadbuilderData', userMessage: 'Couldnt load Pad Builder data' },
  { source: 'getReportOutData', userMessage: 'Couldnt load Report Out data' },
  {
    source: 'getListWaterForcastByScenario',
    userMessage: 'Couldnt load Water Forecast data for selected Scenario',
  },
  {
    source: 'getListGasForcastByScenario',
    userMessage: 'Couldnt load Gas Forecast data for selected Scenario',
  },
  {
    source: 'getPadbuilderMaster_ListByPricetMasterInputId',
    userMessage: 'Couldnt load PadbuilderMaster_ListByPricetMasterInput',
  },
  {
    source: 'getPrctPadbuilderRig_ListByPrctPadbuilderMasterId',
    userMessage: 'Couldnt load PrctPadbuilderRig_ListByPrctPadbuilderMasterId',
  },
  { source: 'getRfsdOptions', userMessage: 'Couldnt load Rfsd options' },
  {
    source: 'getInfrastructureClassOptions',
    userMessage: 'Couldnt load Infrastructure class options',
  },
  {
    source: 'getScopeCategoryOptions',
    userMessage: 'Couldnt load Scope Category options',
  },
  {
    source: 'getScopeCategoryOptionsToAdditionalRows',
    userMessage: 'Couldnt load Scope Category options for additional rows',
  },
  {
    source: 'getLocationOptions',
    userMessage: 'Couldnt load Location options',
  },
  { source: 'getKindOptions', userMessage: 'Couldnt load Kind options' },
  {
    source: 'getCompressorCapacityAssumptions',
    userMessage: 'Couldnt load Compressor Capacity Assumptions',
  },
  { source: 'getDevArea', userMessage: 'Couldnt load Development Area data' },
  { source: 'getState', userMessage: 'Couldnt load State data' },
  { source: 'getBasin', userMessage: 'Couldnt load Basin data' },
  {
    source: 'getGeographicArea',
    userMessage: 'Couldnt load Geographic Area data',
  },
  {
    source: 'getLandDamagesPipe',
    userMessage: 'Couldnt load Land Damages pipe data',
  },
  {
    source: 'getLandDamagesOther',
    userMessage: 'Couldnt load Land Damages other data',
  },
  {
    source: 'openPricetScenario',
    userMessage: 'Couldnt open selected scenario',
  },
  {
    source: 'getScenarioTypes',
    userMessage: 'Couldnt load Scenario Types list',
  },
  {
    source: 'deletePadbuilderImported',
    userMessage: 'Couldnt delete imported Pad Builder',
  },
  {
    source: 'savePricetScenario',
    userMessage: 'Couldnt load save changes made for the Scenario',
  },
  { source: 'getScenarios', userMessage: 'Couldnt load Scenario list' },
  { source: 'getScenarioByKey', userMessage: 'Couldnt load Scenario key' },
  {
    source: 'getItemDefaultCostByYear',
    userMessage: 'Couldnt load Item default cost for selected year',
  },
];

export const ctbPadSizeData = {
  withoutHPFE: {
    twoTrainCtbPadSize: 138,
    perTrain: 36,
  },
  withHPFE: {
    twoTrainCtbPadSize: 184,
    perTrain: 48,
  },
};

export const assumedSlotsPerHeader = 4;

export const landDamagesData = {
  pipelineLandDamages: {
    twoInch: 2.7,
    threeInch: 2.7,
    fourInch: 2.7,
    sixInch: 3.9,
    eightInch: 3.9,
    tenInch: 3.9,
    twelveInch: 7.3,
    fourteenInch: 7.3,
    sixteenInch: 7.3,
    eighteenInch: 7.3,
    twentyInch: 7.3,
    twentyFourInch: 13.6,
  },
  otherLandDamages: {
    baseGreenfieldCtbInstall: 28000,
    coreGreenfieldSatelliteInstall: 12800,
    coreStandardCompressorStationInstall: 20000,
    coreGreenfieldSwdInstall: 12800,
    swdWellPadAndHookup: 56800,
    waterSourceWell_DC_Costs: 56800,
    fracPonds: 288,
    edsMainLine_gd_6_MVA: 1.45,
    roadConstructionAndCaliche: 6.02,
    wellPadSingleUse_AboveGrade_WH: 68000,
    wellPadMultiUse_SubGrade_WH: 68000,
  },
};

export const compressorTypeOptions = ['CAT 3516', 'CAT 3608', 'CAT 3616'];
export const suctionPressureOptions = ['50 psig', '80 psig'];
export const gasFuelOptions = ['Produced Gas', 'Residue Gas', 'Ideal Fuel Gas / Electric'];
