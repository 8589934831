<kendo-grid
  #logsGrid
  [data]="view | async"
  [navigable]="true"
  [loading]="isLoading"
  [scrollable]="'scrollable'"
  [resizable]="true"
  [pageable]="true"
  [pageSize]="gridState.take" [skip]="gridState.skip" [sort]="gridState.sort"
  style="height: 400px"
  (dataStateChange)="onStateChange($event)"
>
    <kendo-grid-column field="operation" title="Operation"></kendo-grid-column>
    <kendo-grid-column field="fieldName" title="List/Field Name"></kendo-grid-column>
    <kendo-grid-column field="previousValue" title="Previous Value"></kendo-grid-column>
    <kendo-grid-column field="currentValue" title="New Value"></kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Modified Date">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date: 'MM/dd/yyyy'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Modified By"></kendo-grid-column>
</kendo-grid>
