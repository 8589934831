import { InputItem } from '../../../api/input-item';
import { IPadBracket } from '../../../api/pad-bracket';

export interface InputItemsModel {
  pads: IPadBracket[];
  inputItems: InputItem[];
  padBuilderPads: any[];
}

export const AdditionalRowsID = [
  'A0000000-0000-0000-0000-000000000001',
  'A0000000-0000-0000-0000-000000000002',
  'A0000000-0000-0000-0000-000000000003',
  'A0000000-0000-0000-0000-000000000004',
  'A0000000-0000-0000-0000-000000000005',
  'A0000000-0000-0000-0000-000000000006',
  'A0000000-0000-0000-0000-000000000007',
  'A0000000-0000-0000-0000-000000000008',
  'A0000000-0000-0000-0000-000000000009',
  'A0000000-0000-0000-0000-000000000010',
];
