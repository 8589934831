export interface AppLogEntry {
  // $id: string;
  id: string;
  createdBy: string;
  createdDate: Date;
  applicationName: string;
  sourceName: string;
  userMessage: string;
  generalMessage: string;
  logLevel: string;
}

export enum AppLogType {
  Critical = 'Critical',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Information',
}
