import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PerformanceBodRoutingModule } from './performance-bod-routing.module';
import { PerformanceBODComponent } from './performance-bod.component';
import { PerformanceBodToolComponent } from './performance-bod-tool/performance-bod-tool.component';
import { PerformanceBodDetailComponent } from './performance-bod-detail/performance-bod-detail.component';
import { PerfomanceBodCreateNewComponent } from './perfomance-bod-create-new/perfomance-bod-create-new.component';
import { PanelPerformanceBodComponent } from '../performance-bod/panel-performance-bod/panel-performance-bod.component';
import { StandardFacilitiesSummaryComponent } from './standard-facilities-summary/standard-facilities-summary.component';
import { StandardFacilitiesCentralTankBatteryComponent } from './standard-facilities-central-tank-battery/standard-facilities-central-tank-battery.component';
import { StandardFacilitiesCompressorComponent } from './standard-facilities-compressor/standard-facilities-compressor.component';
import { StandardFacilitiesSaltWaterDisposalComponent } from './standard-facilities-salt-water-disposal/standard-facilities-salt-water-disposal.component';
import { InfrastructureWellPadComponent } from './infrastructure-well-pad/infrastructure-well-pad.component';
import { InfrastructureFacilityPadsComponent } from './infrastructure-facility-pads/infrastructure-facility-pads.component';
import { InfrastructurePipelinesComponent } from './infrastructure-pipelines/infrastructure-pipelines.component';
import { InfrastructureWellArtificialLiftComponent } from './infrastructure-well-artificial-lift/infrastructure-well-artificial-lift.component';
import { InfrastructureEdsIeComponent } from './infrastructure-eds-ie/infrastructure-eds-ie.component';
import { InfrastructureFracPondComponent } from './infrastructure-frac-pond/infrastructure-frac-pond.component';
import { InfrastructureSourceWaterComponent } from './infrastructure-source-water/infrastructure-source-water.component';
import { InfrastructureRecycleComponent } from './infrastructure-recycle/infrastructure-recycle.component';
import { InfrastructureWaterDisposalComponent } from './infrastructure-water-disposal/infrastructure-water-disposal.component';
import { InfrastructureRegulatoryPermittingComponent } from './infrastructure-regulatory-permitting/infrastructure-regulatory-permitting.component';
import { InfrastructureCommercialComponent } from './infrastructure-commercial/infrastructure-commercial.component';
import { SummaryPackageInformationComponent } from './summary-package-information/summary-package-information.component';
import { SummaryPeakProductionRatesComponent } from './summary-peak-production-rates/summary-peak-production-rates.component';
import { ErrorListComponent } from './error-list/error-list.component';
import { ScopeNonStandardOutsideFactoryProjectsComponent } from './scope-non-standard-outside-factory-projects/scope-non-standard-outside-factory-projects.component';
import { ScopeExistingFacilitiesComponent } from './scope-existing-facilities/scope-existing-facilities.component';
import { SummarySignaturesComponent } from './summary-signatures/summary-signatures.component';
import { SummaryNotesComponent } from './summary-notes/summary-notes.component';
import { SummaryNotesAttachmentComponent } from './summary-notes-attachment/summary-notes-attachment.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { MulticheckFilterComponent } from 'src/app/_shared/multicheck-filter/multicheck-filter.component';
import { InfrastructureRoadMaintenanceComponent } from './infrastructure-road/infrastructure-road-maintenance.component';
import {PerformanceAfeComponent} from './performance-afe/performance-afe.component'
// Kendo Components
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MenuModule } from '@progress/kendo-angular-menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SharedModule } from 'src/app/_shared/shared.module';
import { ListService } from 'src/app/_shared/_services/list.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { SummaryPackageInformationEditService } from './summary-package-information/summary-package-information-edit.service';
import { SummaryPeakProductionRatesEditService } from './summary-peak-production-rates/summary-peak-production-rates-edit.service';
import { SummaryNotesEditService } from './summary-notes/summary-notes-edit.service';
import { SummaryNotesAttachmentEditService } from './summary-notes-attachment/summary-notes-attachment-edit.service';
import { PerformanceBodCentralTankBatteryService } from './standard-facilities-central-tank-battery/standard-facilities-central-tank-battery.service';
import { PerformanceBodPerformanceBodCompressorService } from './standard-facilities-compressor/standard-facilities-compressor.service';
import { PerformanceBodSaltWaterDisposalService } from './standard-facilities-salt-water-disposal/standard-facilities-salt-water-disposal.service';
import { StandardFacilitiesSummaryService } from './standard-facilities-summary/standard-facilities-summary.service';
import { ScopeNonStandardOutsideFactoryProjectsService } from './scope-non-standard-outside-factory-projects/scope-non-standard-outside-factory-projects.service';
import { ScopeExistingFacilitiesEditService } from './scope-existing-facilities/scope-existing-facilities-edit.service';
import { PerformanceBodInfrastructureWellPadService } from './infrastructure-well-pad/infrastructure-well-pad.service';
import { InfrastructureFacilityPadsService } from './infrastructure-facility-pads/infrastructure-facility-pads.service';
import { InfrastructurePipelinesService } from './infrastructure-pipelines/infrastructure-pipelines.service';
import { InfrastructureWellArtificialLiftService } from './infrastructure-well-artificial-lift/infrastructure-well-artificial-lift.service';
import { PerformanceBodEdsIeService } from './infrastructure-eds-ie/infrastructure-eds-ie.service';
import { PerformanceBodFracPondService } from './infrastructure-frac-pond/infrastructure-frac-pond.service';
import { InfrastructureSourceWaterService } from './infrastructure-source-water/infrastructure-source-water.service';
import { InfrastructureRecycleService } from './infrastructure-recycle/infrastructure-recycle.service';
import { PerformanceBodWaterDisposalService } from './infrastructure-water-disposal/infrastructure-water-disposal.service';
import { InfrastructureRegulatoryPermittingService } from './infrastructure-regulatory-permitting/infrastructure-regulatory-permitting.service';
import { InfrastructureCommercialService } from './infrastructure-commercial/infrastructure-commercial.service';
import { WaterScopeDrillingSupplyComponent } from './water-scope-drilling-supply/water-scope-drilling-supply.component';
import { WaterScopeFracSupplyComponent } from './water-scope-frac-supply/water-scope-frac-supply.component';
import { WaterScopeWaterDisposalComponent } from './water-scope-water-disposal/water-scope-water-disposal.component';
import { WaterScopeDrillingSupplyService } from './water-scope-drilling-supply/water-scope-drilling-supply.service';
import { WaterScopeFracSupplyService } from './water-scope-frac-supply/water-scope-frac-supply.service';
import { WaterScopeWaterDisposalService } from './water-scope-water-disposal/water-scope-water-disposal.service';
import { InfrastructureRoadMaintenanceService } from './infrastructure-road/infrastructure-road-maintenance.service';
import {PerformanceAFEService} from './performance-afe/performance-afe.service';
import {PerfAfeRemovePopupComponent} from './perf-afe-remove-popup/perf-afe-remove-popup.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
@NgModule({
  declarations: [
    PerformanceBODComponent,
    PerformanceBodToolComponent,
    PerformanceBodDetailComponent,
    PerfomanceBodCreateNewComponent,
    PanelPerformanceBodComponent,
    StandardFacilitiesSummaryComponent,
    StandardFacilitiesCentralTankBatteryComponent,
    StandardFacilitiesCompressorComponent,
    StandardFacilitiesSaltWaterDisposalComponent,
    InfrastructureWellPadComponent,
    InfrastructureFacilityPadsComponent,
    InfrastructurePipelinesComponent,
    InfrastructureWellArtificialLiftComponent,
    InfrastructureRoadMaintenanceComponent,
    PerformanceAfeComponent,
    InfrastructureEdsIeComponent,
    InfrastructureFracPondComponent,
    InfrastructureSourceWaterComponent,
    InfrastructureRecycleComponent,
    InfrastructureWaterDisposalComponent,
    InfrastructureRegulatoryPermittingComponent,
    InfrastructureCommercialComponent,
    SummaryPackageInformationComponent,
    SummaryPeakProductionRatesComponent,
    ErrorListComponent,
    ScopeNonStandardOutsideFactoryProjectsComponent,
    ScopeExistingFacilitiesComponent,
    SummarySignaturesComponent,
    SummaryNotesComponent,
    SummaryNotesAttachmentComponent,
    UploadFileComponent,
    MulticheckFilterComponent,
    WaterScopeDrillingSupplyComponent,
    WaterScopeFracSupplyComponent,
    WaterScopeWaterDisposalComponent,
    StatusIndicatorComponent,
    PerfAfeRemovePopupComponent
  ],
  imports: [
    CommonModule,
    PerformanceBodRoutingModule,
    LayoutModule,
    GridModule,
    DropDownsModule,
    ButtonsModule,
    FormsModule,
    DateInputsModule,
    InputsModule,
    MenuModule,
    HttpClientModule,
    ReactiveFormsModule,
    PopupModule,
    DialogsModule,
    NotificationModule,
    ToolBarModule,
    TooltipModule,
    SharedModule,
    UploadModule,
    ExcelModule,
  ],
  providers: [
    ListService,
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService, PerformanceBodCalculationsService],
      provide: SummaryPackageInformationEditService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () =>
        new SummaryPackageInformationEditService(
          performanceBodDataService,
          performanceBodStoreService,
          performanceBodCalculationsService
        ),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: SummaryPeakProductionRatesEditService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () =>
        new SummaryPeakProductionRatesEditService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: SummaryNotesEditService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () => new SummaryNotesEditService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: SummaryNotesAttachmentEditService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () => new SummaryNotesAttachmentEditService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodCentralTankBatteryService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodCentralTankBatteryService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodPerformanceBodCompressorService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodPerformanceBodCompressorService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodSaltWaterDisposalService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodSaltWaterDisposalService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: ScopeNonStandardOutsideFactoryProjectsService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () =>
        new ScopeNonStandardOutsideFactoryProjectsService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: ScopeNonStandardOutsideFactoryProjectsService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () =>
        new ScopeNonStandardOutsideFactoryProjectsService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService, PerformanceBodCalculationsService],
      provide: ScopeExistingFacilitiesEditService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () =>
        new ScopeExistingFacilitiesEditService(
          performanceBodDataService,
          performanceBodStoreService,
          performanceBodCalculationsService
        ),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: PerformanceBodInfrastructureWellPadService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () =>
        new PerformanceBodInfrastructureWellPadService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: InfrastructureFacilityPadsService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () => new InfrastructureFacilityPadsService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: InfrastructurePipelinesService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () => new InfrastructurePipelinesService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: InfrastructureWellArtificialLiftService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () => new InfrastructureWellArtificialLiftService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodStoreService],
      provide: InfrastructureRoadMaintenanceService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodStoreService: PerformanceBodStoreService
      ) => () => new InfrastructureRoadMaintenanceService(performanceBodDataService, performanceBodStoreService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodEdsIeService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodEdsIeService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodFracPondService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodFracPondService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: InfrastructureSourceWaterService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new InfrastructureSourceWaterService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: InfrastructureRecycleService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new InfrastructureRecycleService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: PerformanceBodWaterDisposalService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new PerformanceBodWaterDisposalService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: WaterScopeDrillingSupplyService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new WaterScopeDrillingSupplyService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: WaterScopeFracSupplyService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new WaterScopeFracSupplyService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService],
      provide: WaterScopeWaterDisposalService,
      useFactory: (performanceBodDataService: PerformanceBodDataService) => () =>
        new WaterScopeWaterDisposalService(performanceBodDataService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: InfrastructureRegulatoryPermittingService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () =>
        new InfrastructureRegulatoryPermittingService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [PerformanceBodDataService, PerformanceBodCalculationsService],
      provide: InfrastructureCommercialService,
      useFactory: (
        performanceBodDataService: PerformanceBodDataService,
        performanceBodCalculationsService: PerformanceBodCalculationsService
      ) => () => new InfrastructureCommercialService(performanceBodDataService, performanceBodCalculationsService),
    },
    {
      deps: [HttpClient],
      provide: PerformanceAFEService,
      useFactory: (jsonp: HttpClient) => () => new PerformanceAFEService(jsonp),
    }
  ],
  entryComponents: [
    PerfAfeRemovePopupComponent
  ]
})
export class PerformanceBodModule {}
