import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { environment } from '../../environments/environment';
import { IStatusIndicator } from '../api/status-indicator';


@Injectable({
  providedIn: 'root'
})
export class StatusIndicatorService {
  public baseUrlSftBodStatus: string = environment.PacerDomainURL + 'StatusIndicator';
  public statusIndicator$: Observable<IStatusIndicator[]>;  ;
  private statusIndicatorValue$: BehaviorSubject<IStatusIndicator[]>;
  private handleError: HandleError;
  private facilityBodId: string;
  constructor(private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler) { 
    this.statusIndicatorValue$ = new BehaviorSubject<IStatusIndicator[]>([]);
    this.statusIndicator$ = this.statusIndicatorValue$.asObservable();   
    this.handleError = httpErrorHandler.createHandleError();    
  }


  public read() {
    this.getStatusIndicators().subscribe((sftBodStatus: IStatusIndicator[]) => {
      this.statusIndicatorValue$.next(sftBodStatus);
    }, () => {
      this.statusIndicatorValue$.error('Error loading data: could not be able to load Status Indicator.');
    });
  }

  public cleanObservables(): void {
    this.statusIndicatorValue$.next([]);
  }

  private getStatusIndicators(): Observable<IStatusIndicator[]> {
    return this.http
      .get<IStatusIndicator[]>(`${this.baseUrlSftBodStatus}/List/`)
      .pipe(catchError(this.handleError('getFacilityBodById')));
  }
}
