import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process, State } from '@progress/kendo-data-query';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { DataService } from '../../../services/data.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IReportOut } from '../../../api/reportout';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reportout',
  templateUrl: './reportout.component.html',
  styleUrls: ['./reportout.component.css'],
})
export class ReportOutComponent implements OnInit {
  public repOutData: any[];

  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;

  public state: State = {};

  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  // public aggregates: any[] = [{ field: 'UnitPrice', aggregate: 'sum' }];

  public products: IReportOut[];

  // public total: any = aggregateBy(this.products, this.aggregates);

  /*public group: any[] = [{
      field: 'Discontinued',
      aggregates: this.aggregates
  }];*/

  constructor(
    private scenarioStoreService: ScenarioStoreService,
    private dataService: DataService,
    private notificationService: NotificationService) {
    this.allData = this.allData.bind(this);
  }

  public ngOnInit() {
    // empty
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.products, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  public allData(): ExcelExportData {
    this.scenarioStoreService.reportOut$.subscribe(data => (this.products = data), error => {
      // empty
    });
    const result: ExcelExportData = {
      data: process(this.products, {}).data,
    };

    return result;
  }

  public click(): void {
    this.dataService.getScenarios().subscribe(
      data => {
        window.open(environment.PricetDomainURL + 'Scenario/Download/00000000-0000-0000-0000-000000000000', '_blank');
      },
      error => {
        this.notificationService.show({
          content: 'There was an issue generating Report Out document. Please try again later.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'error', icon: true },
        });
      }
    );
  }

  private loadItems(): void {
    // empty
  }

}
