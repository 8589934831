import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, filterBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { IDasSimple } from '../../../api/das-simple';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { concat } from 'rxjs';
import { DevelopmentAreaService } from '../../../services/master-data.service';
import { DasSimpleListEditService } from './das-simple-list-edit.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-das-simple-list',
  templateUrl: './das-simple-list.component.html',
  
})
export class DasSimpleListComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public dasSimpleList: IDasSimple[] = [];
  public routeName: any[];
  public showAllBods: boolean = false;
  public isLoading = true;
  public openedDelete: boolean;
  public dasSimpleSelected: IDasSimple;
  public developmentAreaList: any[] = [];
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc',
    },
  ];

  public state: State = {
    sort: [],
    skip: 0,
  };

  public filter: CompositeFilterDescriptor;
  public dasSimpleListEditService: DasSimpleListEditService;

  constructor(
    private router: Router,
    private permissionsProvider: PermissionsProvider,
    private developmentAreaService: DevelopmentAreaService,
    @Inject(DasSimpleListEditService) dasSimpleListEditServiceFactory: any
  ) {
    this.dasSimpleListEditService = dasSimpleListEditServiceFactory();
  }

  public ngOnInit(): void {
    this.routeName = this.router.url.split('/');
    const bodRoute = this.routeName[3];
    if (bodRoute === 'List') {
      this.showAllBods = true;
    }
    this.dasSimpleListEditService.cai = this.showAllBods ? null : this.permissionsProvider.cai;

    // const firstGetThis =
    // concat(this.developmentAreaService.listToDropDown());

    this.view = this.dasSimpleListEditService.pipe(
      map(dasSimples => {
        this.isLoading = false;
        this.dasSimpleList = dasSimples;
        // this.setDevelopmentAreaToList(this.dasSimpleList);
        return process(filterBy(this.dasSimpleList, this.filter), this.state);
      })
    );

    // firstGetThis.subscribe((devAreas: any[]) => {
    //   this.developmentAreaList = devAreas;
    this.dasSimpleListEditService.read();
    // });
  }

  public setDevelopmentAreaToList(dasSimples: IDasSimple[]): void {
    // this.dasSimpleList = this.dasSimpleList.map((item: IDasSimple) => {
    //   const devArea = this.developmentAreaList.find(d => d.value === item.developmentAreaId);
    //   if (devArea) {
    //     item.developmentAreaName = devArea.text;
    //   }
    //   return item;
    // })
  }

  public closeDelete(status) {
    this.openedDelete = false;
  }

  public openDelete(dataItem: any) {
    this.openedDelete = true;
    this.dasSimpleSelected = dataItem;
  }

  public removeHandler() {
    this.openedDelete = false;
    this.dasSimpleSelected.isDeleted = true;
    this.dasSimpleListEditService.removeItem(this.dasSimpleSelected);
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
      this.saveChanges();
      this.view = this.dasSimpleListEditService.pipe(
        map(dasSimples => {
          this.isLoading = false;
          this.dasSimpleList = dasSimples;
          // this.setDevelopmentAreaToList(this.dasSimpleList);
          return process(filterBy(this.dasSimpleList, this.filter), this.state);
        })
      );
    }, 4000);
  }

  public saveChanges(): void {
    this.dasSimpleListEditService.saveChanges();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.view = this.dasSimpleListEditService.pipe(
      map(dasSimples => {
        return process(filterBy(dasSimples, this.filter), this.state);
      })
    );
  }

  public onDataStateChange(state: State): void {
    this.state = state;
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.view = this.dasSimpleListEditService.pipe(
      map(dasSimples => {
        return process(filterBy(dasSimples, this.filter), this.state);
      })
    );
  }

  public isAdmin(): boolean {
    return this.permissionsProvider ? this.permissionsProvider.isAdmin : false;
  }
}
