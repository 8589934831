import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcColumnFilterComponent } from './fit-cc-column-filter.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [FitCcColumnFilterComponent],
  exports: [FitCcColumnFilterComponent],
})
export class FitCcColumnFilterModule {}
