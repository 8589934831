<div class="card-body">
  <kendo-grid
    [data]="gridData"
    [filter]="state.filter"
    [filterable]="true"
    (dataStateChange)="onDataStateChange($event)"
    (edit)="openHandler($event)"
    [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    style="height: auto; overflow-x: hidden; margin-bottom: 2vw;"
  >
    <kendo-grid-column field="name" title="Performance BOD Name"></kendo-grid-column>
    <kendo-grid-column field="projectNumber" title="Project Number" filter="numeric"> </kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By"></kendo-grid-column>
    <kendo-grid-column
      field="createdDate"
      title="Created Date"
      filter="date"
      format="{0:MM/dd/yyyy}"
    ></kendo-grid-column>
    <kendo-grid-column field="updatedBy" title="Last Updated By"></kendo-grid-column>
    <kendo-grid-column field="updatedDate" title="Last Updated Date" filter="date" format="{0:MM/dd/yyyy}">
    </kendo-grid-column>
    <kendo-grid-command-column title="" width="220">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          [primary]="true"
        >
          Open
        </button>
        <button
          kendoGridRemoveCommand
          [primary]="true"
          (click)="openDelete(dataItem)"
          [hidden]="hiddenAdmin"
          [disabled]="!isAdmin"
          style="background:red"
        >
          Delete
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<div class="example-wrapper">
  <kendo-dialog
    title="Confirmation Deletion"
    *ngIf="openedDelete"
    (close)="closeDelete('cancel')"
    [minWidth]="250"
    [width]="450"
  >
    <p style="margin: 30px; text-align: center;">Are you sure you want to delete the selected project(s)?</p>
    <kendo-dialog-actions>
      <button kendoButton (click)="removeHandler(dataItem)">Yes</button>
      <button kendoButton (click)="closeDelete('cancel')">No</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
