<div class="card-body-bod-rates">
  <kendo-grid
    #performanceExpectedPeakProductionRatesGrid
    [data]="view | async"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (add)="addHandler($event)"
    [navigable]="true"
    [resizable]="true"
    [sort]="gridState.sort"
    [sortable]="{ mode: 'multiple' }"
    class="peak-production-draggable"
    [rowClass]="rowCallback"
    filterable="menu"
    [filter]="filter"
    (filterChange)="handlerFilter($event)"
    (dataStateChange)="onStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        kendoButton
        class="k-button"
        [disabled]="!summaryPeakProductionRatesEditService.hasChanges()"
        (click)="cancelChanges(performanceExpectedPeakProductionRatesGrid)"
        [primary]="true"
      >
        Cancel
      </button>
      <button kendoGridAddCommand [primary]="true" [icon]="'plus'"></button>
    </ng-template>
    <kendo-grid-column title="" field="" [hidden]="true" width="1">
      {{ rowLoaded() }}
    </kendo-grid-column>
    <kendo-grid-column title="Well Pad Name" field="performanceBodPadsId" [editable]="true" width="84">
      <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
        <multicheck-filter
          [isPrimitive]="false"
          [field]="column.field"
          textField="wellPadName"
          valueField="id"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="performanceBodPads"
        >
        </multicheck-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getPerformanceBodPad(dataItem.performanceBodPadsId)?.wellPadName }}
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-column="column"
        let-formGroup="formGroup"
        let-isNew="isNew"
      >
        <kendo-dropdownlist
          [data]="performanceBodPads"
          textField="wellPadName"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('performanceBodPadsId')"
          (valueChange)="changePackage($event, dataItem, formGroup)"
          [disabled]="dataItem.isWellPadNameDisabled"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            {{ getPerformanceBodPad(dataItem?.id)?.wellPadName }}
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    
    <kendo-grid-column field="packageName" title="Package Name" [editable]="false" width="100"> </kendo-grid-column>
    <kendo-grid-column
      field="oilBopd"
      title="Oil (BOPD)"
      [editable]="true"
      width="100"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.oilBopd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="waterBwpd"
      title="Water (BWPD)"
      [editable]="true"
      width="100"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.waterBwpd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="lpGasMmscfd"
      title="LP Gas (MMSCFD)"
      [editable]="true"
      width="120"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lpGasMmscfd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="hpGasMmscfd"
      title="HP Gas (MMSCFD)"
      [editable]="true"
      width="120"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.hpGasMmscfd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="totalGasMmscfd"
      title="Total Gas (MMSCFD)"
      [editable]="false"
      width="120"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lpGasMmscfd + dataItem.hpGasMmscfd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="gasLiftMmscfd"
      title="Gas Lift (MMSCFD)"
      [editable]="true"
      width="120"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.gasLiftMmscfd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="totalCompressionMmscfd"
      title="Total Compression (MMSCFD)"
      [editable]="true"
      width="130"
      editor="numeric"
      filter="numeric"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.totalCompressionMmscfd }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="comments" title="Comments" [editable]="true" width="120"> </kendo-grid-column>
    <kendo-grid-command-column title="" width="150">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
        <button kendoGridSaveCommand [primary]="true">Add</button>
        <button kendoGridCancelCommand [primary]="true">Cancel</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid> 
</div>
