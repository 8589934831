<kendo-grid #performanceBodFacilityPadGrid
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            (dataStateChange)="onStateChange($event)"
            class="infrastructure-facility-pads-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!infrastructureFacilityPadsService.hasChanges()"
            (click)="cancelChanges(performanceBodFacilityPadGrid)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>

  <kendo-grid-column field="name" title="Facility Name" [editable]="true" [width]="150">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input #input kendoGridFocusable type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Facility Pad Name already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="true" [width]="150">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist popupAnchor
                          [data]="performanceBodPackagesList"
                          [defaultItem]="{ id: null, name: 'Select item...' }"
                          textField="name"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPackagesId')"
                          (valueChange)="changePackage($event, dataItem, formGroup)">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="chargeCode" title="Dummy Charge code" [editable]="true" width="150"> </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate"
                     title="C&D/HO Estimate"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     [width]="120">
  </kendo-grid-column>

  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>
  
  <kendo-grid-column field="afeAmount"
                     title="Approved AFE Amount"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"
                     width="150">
</kendo-grid-column>

  <kendo-grid-column field="padDimensions" title="Pad Dimensions" [editable]="true" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.padDimensions }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input kendoGridFocusable
             type="text"
             maxlength="100"
             min="0"
             [formControl]="formGroup.get('padDimensions')"
             class="k-textbox" />
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="padOrientation" [editable]="true" [width]="150">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="true"
                         [field]="column.field"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="orientationList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      <div kendoTooltip title="Reference SFT Facility Pad Standard">Pad Orientation</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.padOrientation }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist popupAnchor
                          [defaultItem]="''"
                          [data]="orientationList"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('padOrientation')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="permitsRequiredBy"
                     title="Permits Required By"
                     [editable]="true"
                     editor="date"
                     [width]="150"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.permitsRequiredBy | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.permitsRequiredBy"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('permitsRequiredBy')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="padRfsd"
                     title="RFSD"
                     [editable]="true"
                     editor="date"
                     [width]="100"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.padRfsd | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.padRfsd" [format]="'MM/dd/yyyy'" [formControl]="formGroup.get('padRfsd')">
      </kendo-datepicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"></kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
