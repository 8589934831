import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { FileRestrictions, SuccessEvent, UploadProgressEvent } from '@progress/kendo-angular-upload';
import { PadbuilderImport } from '../../../../api/padbuilder-import';
import { environment } from '../../../../../environments/environment';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Guid } from 'guid-typescript';
import { ScenarioStoreService } from '../../../../services/scenario-store.service';
import { AuthenticationService } from '../../../../services';
import { DataService } from '../../../../services/data.service';
import { CNDMassExcelImport } from 'src/app/api/cndmassexcel-import';

@Component({
  selector: 'app-import-mass-excel',
  templateUrl: './import-mass-excel.component.html',
  styleUrls: ['./import-mass-excel.component.css']
})
export class ImportMassExcelBuilderComponent implements OnInit {
  @ViewChild('Form', { static: true })
  public form: NgForm;
  
  public formUpload: FormGroup;
  public isUploadEnabled = false;
  public uploadSaveUrl = environment.PricetDomainURL + 'Scenario/UploadMassImportFile'; // should represent an actual API endpoint
  public pbRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx'],
    maxFileSize: 1000000000,
  };
  public isExcelFile = false;
  public uploadProgress: number;
  public importProgress: number;
  public pbImport: PadbuilderImport;
  public showProgressBar: boolean;
  public submitted = false;
  public scenarioId: string;
  public excelErrors : any[];
  constructor(private fb: FormBuilder,
    private notificationService: NotificationService,
    private dialog: DialogRef,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private scenarioStoreService: ScenarioStoreService) { }

  ngOnInit(): void {

    this.scenarioStoreService.scenarioID$.subscribe(data => {
      this.scenarioId = data;
    });

    this.formUpload = this.fb.group({       
    });
    
    this.loadPbImport();
  } 

  public errorUpload(e: any) {
    this.isExcelFile = false;
    this.importProgress = 0;
    if (e !== undefined) {
      const err = e.response.error;
      if (err !== undefined) {
        this.notificationService.show({
          content:
            err.message === null || err.message === undefined
              ? 'Something happened when the file was uploading.'
              : err.message,
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'warning', icon: true },
        });
      }
    }
  }

  public successUpload(e: SuccessEvent) {
    this.isExcelFile = true;
    const fileInfo = e.files[0];
    const response = e.response.body;
    this.pbImport.fileName = fileInfo.rawFile.name;
    this.pbImport.fileNameLoaded = response.fileNameLoaded;

    if (fileInfo.rawFile.lastModified) {
      this.pbImport.modificationDate = new Date(fileInfo.rawFile.lastModified);
    } else {
      this.pbImport.modificationDate = new Date(fileInfo.rawFile['lastModifiedDate']);
    }
    this.form.ngSubmit.emit();
  }

  public fileUploading(args: any) {
    this.showProgressBar = true;
    this.notificationService.show({
      content: 'Uploading the file, this may take a few minutes...',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'info', icon: true },
    });
  }

  public uploadProgressHandler(e: UploadProgressEvent) {
    this.uploadProgress = +e.percentComplete / 2;
    this.importProgress = this.uploadProgress;
  }

  public loadPbImport() {
    if (this.formUpload) {
      this.formUpload.reset();
    }
    this.pbImport = this.initModel();
    this.uploadProgress = 0;
  }

  public initModel(): CNDMassExcelImport {
    return {
      fileName: '',
      fileNameLoaded: '',
      modificationDate: null,
      scenarioName: '',
      scenarioId: Guid.EMPTY,
      userName: '',
      forecastNotes: null,
      message: null,
      rigCount: null,
      errors : null
    };
  }

  public import(value: any, valid: boolean) {
    if (!this.pbImport) {
      return;
    }
    if (!this.pbImport.fileNameLoaded) {
      return;
    }
      this.submitted = true;
      this.showProgressBar = true;
      this.notificationService.show({
        content: 'Importing the file, this may take a few minutes...',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 700 },
        type: { style: 'info', icon: true },
      });

      this.pbImport.scenarioId = this.scenarioId;
      this.pbImport.userName = this.authenticationService.getCurrentUser();

      this.dataService.saveMassImport(this.pbImport).subscribe(
        (res: PadbuilderImport) => {
          this.onImportComplete(res);
        },
        (err: any) => {
          this.onImportError(err);
        }
      );
  }

  public onImportComplete(value: any) {
    this.scenarioStoreService.changeNewScenario(false);
    this.showProgressBar = false;
    this.importProgress = 0;
    this.notificationService.show({
      content: 'The import was completed.',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 700 },
      type: { style: 'success', icon: true },
    });
    this.formUpload.reset();
    this.submitted = false;
    const pbData = value as PadbuilderImport;
    this.scenarioStoreService.setCostLogicDetector(false);
    this.scenarioStoreService.padbuilderImportCompleted(pbData.scenarioId);
    this.dialog.close();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  public onImportError(value: any) {
    this.excelErrors = value.errors;
    this.showProgressBar = false;
    this.importProgress = 0;
    this.notificationService.show({
      content: 'Error on Importing PadBuilder file process',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'error', icon: true },
    });

    this.submitted = false;
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

}
