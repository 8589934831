import { Injectable } from '@angular/core';
import { BehaviorSubject, zip } from 'rxjs';
import { IViewPricetScenario } from 'src/app/api/viewPricetScenario';
import { DataService } from 'src/app/services/data.service';

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));
@Injectable()
export class CndListService extends BehaviorSubject<IViewPricetScenario[]> {
  public data: IViewPricetScenario[] = [];
  public originalData: IViewPricetScenario[] = [];
  public deletedItems: IViewPricetScenario[] = [];
  public isLoading = true;
  constructor(private dataService: DataService) {
    super([]);
  }

  public read() {
    this.isLoading = true;
    if (this.data !== undefined) {
      if (this.data.length) {
        this.isLoading = false;
        return super.next(this.data);
      }
    }

    this.dataService
      .getScenarios()
      .toPromise()
      .then(scenarios => {
        this.data = scenarios;
        this.originalData = cloneData(scenarios);
        super.next(scenarios);
        this.isLoading = false;
      });
  }

  public remove(item: any): void {
    this.deletedItems.push(item);
  }

  public saveChanges(): void {
    this.isLoading = true;
    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.dataService.deleteScenario(this.deletedItems[0].id));
    }

    this.reset();
    zip(...completed)
      .toPromise()
      .then(() => this.read());
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
  }
}
