import { IStatusIndicator } from 'src/app/api/status-indicator';
import { IPerformanceBodStatus } from 'src/app/api/performance-bod-status';
import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodSignatures } from 'src/app/api/performance-bod-signatures';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { UserPermissionsService } from 'src/app/services/user-permission.service';
import { IUsrPermissions } from 'src/app/api/user-permission';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogRef, DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { PerformanceBodDetailService } from 'src/app/modules/performance-bod/performance-bod-detail/performance-bod-detail.service';
import { PerformanceBodStatusService } from './../services/performance-bod-status.service';
interface IUser {
  cai: string;
  fullname: string;
}

@Component({
  selector: 'app-summary-signatures',

  templateUrl: './summary-signatures.component.html',
})
export class SummarySignaturesComponent implements OnInit {
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public performanceBodSign = new EventEmitter<string>();
  public performanceBodId: string;
  public performanceBodStatus: IPerformanceBodStatus;
  public statusIndicators: IStatusIndicator[];
  public hiddenPerformancePM: boolean;
  public hiddenReservoirEngineer: boolean;
  public hiddenPerformanceTL: boolean;
  public hiddenWaterEngineer: boolean;
  public hiddenRecallPerformancePM: boolean;
  public hiddenRecallReservoirEngineer: boolean;
  public hiddenRecallPerformanceTL: boolean;
  public hiddenRecallWaterEngineer: boolean;
  // public performanceBodsignaturesList: IPerformanceBodSignatures;
  public performanceBod: IPerformanceBod;
  public usersCnD: IUsrPermissions[];
  public users: IUser[] = [];
  public data: string[];
  public systemCAIs: string[] = [];
  public canEditPerformanceBod = false;
  private performanceBodSignatures: IPerformanceBodSignatures[];

  constructor(
    private spinner: NgxSpinnerService,
    public performanceBodStoreService: PerformanceBodStoreService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private performanceBodStatusService: PerformanceBodStatusService,
  ) {}

  public ngOnInit(): void {
    this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
    });

    this.performanceBodStoreService.performanceBodStatus$.subscribe(status => {
      if (status) {
        this.performanceBodStatus = status.length > 0 ? status[0] : null;
      }
    });

    this.performanceBodStoreService.statusIndicator$.subscribe((data: IStatusIndicator[]) => {
      this.statusIndicators = data;
    });

    this.performanceBodStoreService.performanceBod$.subscribe(signatures => {
      if (signatures) {
        this.performanceBod = signatures;
        if (this.performanceBod.performanceProjectManagerCai != null) {
          if (
            this.performanceBod.performanceProjectManagerCai.toLowerCase() ===
            this.authenticationService.getCurrentUser().toLowerCase()
          ) {
            this.hiddenPerformancePM = false;
          } else {
            this.hiddenPerformancePM = true;
            this.hiddenRecallPerformancePM = true;
          }
        } else {
          this.hiddenPerformancePM = true;
        }
        if (this.performanceBod.performanceReservoirEngineerCai != null) {
          if (
            this.performanceBod.performanceReservoirEngineerCai.toLowerCase() ===
            this.authenticationService.getCurrentUser().toLowerCase()
          ) {
            this.hiddenReservoirEngineer = false;
          } else {
            this.hiddenReservoirEngineer = true;
            this.hiddenRecallReservoirEngineer = true;
          }
        } else {
          this.hiddenReservoirEngineer = true;
        }
        if (this.performanceBod.performanceTeamLeadCai != null) {
          if (
            this.performanceBod.performanceTeamLeadCai.toLowerCase() ===
            this.authenticationService.getCurrentUser().toLowerCase()
          ) {
            this.hiddenPerformanceTL = false;
          } else {
            this.hiddenPerformanceTL = true;
            this.hiddenRecallPerformanceTL = true;
          }
        } else {
          this.hiddenPerformanceTL = true;
        }
        if (this.performanceBod.performanceWaterEngineerCai != null) {
          if (
            this.performanceBod.performanceWaterEngineerCai.toLowerCase() ===
            this.authenticationService.getCurrentUser().toLowerCase()
          ) {
            this.hiddenWaterEngineer = false;
          } else {
            this.hiddenWaterEngineer = true;
            this.hiddenRecallWaterEngineer = true;
          }
        } else {
          this.hiddenWaterEngineer = true;
        }
      }
    });

    this.performanceBodStoreService.performanceBodSignaturesData$.subscribe(signatures => {
      this.performanceBodSignatures = signatures;

      if (this.performanceBodSignatures) {
        if (this.performanceBodSignatures.length > 0) {
          const projectManagerCai =
            this.performanceBodSignatures[0].performanceProjectManagerCai === null
              ? null
              : this.performanceBodSignatures[0].performanceProjectManagerCai.toLowerCase();
          const performanceReservoirCai =
            this.performanceBodSignatures[0].performanceReservoirEngineerCai === null
              ? null
              : this.performanceBodSignatures[0].performanceReservoirEngineerCai.toLowerCase();
          const projectTLCai =
            this.performanceBodSignatures[0].performanceTeamLeadCai === null
              ? null
              : this.performanceBodSignatures[0].performanceTeamLeadCai.toLowerCase();
          const waterEngineerCai =
            this.performanceBodSignatures[0].performanceWaterEngineerCai === null
              ? null
              : this.performanceBodSignatures[0].performanceWaterEngineerCai.toLowerCase();
          const currentUser = this.authenticationService.getCurrentUser().toLowerCase();

          if (projectManagerCai === null) {
            this.hiddenRecallPerformancePM = true;
          } else if (projectManagerCai === currentUser) {
            this.hiddenRecallPerformancePM = false;
          } else {
            this.hiddenRecallPerformancePM = true;
          }

          if (performanceReservoirCai === null) {
            this.hiddenRecallReservoirEngineer = true;
          } else if (performanceReservoirCai === currentUser) {
            this.hiddenRecallReservoirEngineer = false;
          } else {
            this.hiddenRecallReservoirEngineer = true;
          }

          if (projectTLCai === null) {
            this.hiddenRecallPerformanceTL = true;
          } else if (projectTLCai === currentUser) {
            this.hiddenRecallPerformanceTL = false;
          } else {
            this.hiddenRecallPerformanceTL = true;
          }

          if (waterEngineerCai === null) {
            this.hiddenRecallWaterEngineer = true;
          } else if (waterEngineerCai === currentUser) {
            this.hiddenRecallWaterEngineer = false;
          } else {
            this.hiddenRecallWaterEngineer = true;
          }
        }
      }
    });
  }

  public signPerformanceProjectManager(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceProjectManagerCai = this.authenticationService.getCurrentUser();
        this.performanceBodSignatures[0].performanceProjectManagerDate = signDate;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodStatusService.nextChangeStatus(true);
            this.performanceBodSign.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public signPerformanceReservoirEngineer(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceReservoirEngineerCai = this.authenticationService.getCurrentUser();
        this.performanceBodSignatures[0].performanceReservoirEngineerDate = signDate;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodStatusService.nextChangeStatus(true);
            this.performanceBodSign.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public signPerformanceTeamLead(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;
        signDate = new Date();
        this.performanceBodSignatures[0].performanceTeamLeadCai = this.authenticationService.getCurrentUser();
        this.performanceBodSignatures[0].performanceTeamLeadDate = signDate;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodStatusService.nextChangeStatus(true);
            this.performanceBodSign.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public signWaterEngineer(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;
        signDate = new Date();
        this.performanceBodSignatures[0].performanceWaterEngineerCai = this.authenticationService.getCurrentUser();
        this.performanceBodSignatures[0].performanceWaterEngineerDate = signDate;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodStatusService.nextChangeStatus(true);
            this.performanceBodSign.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public recallPerformanceProjectManagerSignature(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceProjectManagerCai = null;
        this.performanceBodSignatures[0].performanceProjectManagerDate = null;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }
  public recallPerformanceReservoirEngineerSignature(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceReservoirEngineerCai = null;
        this.performanceBodSignatures[0].performanceReservoirEngineerDate = null;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }
  public recallPerformanceTeamLeadSignature(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceTeamLeadCai = null;
        this.performanceBodSignatures[0].performanceTeamLeadDate = null;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public recallWaterEngineerSignature(): void {
    if (this.performanceBodSignatures) {
      if (this.performanceBodSignatures.length > 0) {
        let signDate: Date;

        signDate = new Date();
        this.performanceBodSignatures[0].performanceWaterEngineerCai = null;
        this.performanceBodSignatures[0].performanceWaterEngineerDate = null;
        this.performanceBodSignatures[0].updatedDate = signDate;
        this.performanceBodSignatures[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignatures).subscribe(
          () => {
            this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
            this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide();
          }
        );
      }
    }
  }

  public showConfirmation(recallingSignature: string) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to recall your signature?',
      actions: [{ text: 'Cancel', primary: true }, { text: 'Ok' }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text === 'Ok') {
          switch (recallingSignature) {
            case 'projectManager':
              this.recallPerformanceProjectManagerSignature();
              break;

            case 'reservoirEngineer':
              this.recallPerformanceReservoirEngineerSignature();
              break;

            case 'teamLead':
              this.recallPerformanceTeamLeadSignature();
              break;

              case 'waterEngineer':
                this.recallWaterEngineerSignature();
                break;  

            default:
              break;
          }
          this.performanceBodStatus.statusIndicatorId = this.statusIndicators.find(
            s => s.name === 'Awaiting Signatures'
          ).id;
          this.performanceBodStoreService.updatePerformanceBodStatusAsync([this.performanceBodStatus]).subscribe(
            () => {
              this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
              this.spinner.hide();
              this.showMessage();
            },
            (error: any) => {
              this.spinner.hide();
            }
          );
        } else {
        }
      }
    });
  }
  public itHasPerformanceSign(): boolean {
    if (this.performanceBodSignatures.length > 0) {
      if (this.performanceBodSignatures[0].performanceProjectManagerCai!=null){
        if (this.performanceBodSignatures[0].performanceProjectManagerCai === this.authenticationService.getCurrentUser()){
          return true;
        }
      }
    }
    return false;
  }

  public itHasReservoirEngineer(): boolean {
    if (this.performanceBodSignatures.length > 0) {
      if (this.performanceBodSignatures[0].performanceReservoirEngineerCai!=null){
        if (this.performanceBodSignatures[0].performanceReservoirEngineerCai === this.authenticationService.getCurrentUser()){
          return true;
        }
      }
    }
    return false;
  }

  public itHasTeamLead(): boolean {
    if (this.performanceBodSignatures.length > 0) {
      if (this.performanceBodSignatures[0].performanceTeamLeadCai!=null){
        if (this.performanceBodSignatures[0].performanceTeamLeadCai === this.authenticationService.getCurrentUser()){
          return true;
        }
      }
    }
    return false;
  }

  public itHasWaterEngineer(): boolean {
    if (this.performanceBodSignatures.length > 0) {
      if (this.performanceBodSignatures[0].performanceWaterEngineerCai!=null){
        if (this.performanceBodSignatures[0].performanceWaterEngineerCai === this.authenticationService.getCurrentUser()){
          return true;
        }
      }
    }
    return false;
  }

  public showMessage(): void {
    this.notificationService.show({
      content: 'Performance BOD sucessfully saved',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 1000 },
      type: { style: 'success', icon: true },
      hideAfter: 700,
    });
  }
}
