<div class="card-body">
    <!--<kendo-grid [kendoGridBinding]="" [height]="800">
        <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Report Out to Excel</button>
        </ng-template>
        <kendo-grid-column field="pricetMasterInputId" [locked]="true" title="ID" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="padbuilderSourceFilePath" [locked]="true" title="Source File Path" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="scenarioName" [locked]="true" title="Scenario Name" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="rigLine" [locked]="true" title="Rig Line" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="padName" [locked]="true" title="Pad Name" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="padStatus" [locked]="true" title="Pad Status" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="wellCount" [locked]="true" title="Well Count" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="spudDate" [locked]="true" title="Spud Date" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="popMonth" [locked]="true" title="Pop Month" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="scopeRfsd" [locked]="true" title="Scope RFSD" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="rfsdTimingDependency" [locked]="true" title="RFSD Timing Dependency" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="wellTestLocation" [locked]="true" title="Well Test Location" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="centralTankBattery" [locked]="true" title="Central Tank Battery" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="ctbTrain" [locked]="true" title="CTB Train" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="compressionStation" [locked]="true" title="Compression Station" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="swdFacilty" [locked]="true" title="SWD Facility" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="fracPond" [locked]="true" title="Frac Pond" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="infrastructureType" [locked]="true" [locked]="true" title="Infrastructure Type" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="location" [locked]="true" title="Location" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="kind" [locked]="true" title="Kind" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="executionCategory" [locked]="true" title="Execution Category" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="scopeCategory" [locked]="true" title="Scope Category" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="detail" [locked]="true" title="Detail" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="ptctItemDisplayName"[locked]="true" title="Item Display Name" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="scopeCount" [locked]="true" title="Scope Count" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="taxRate" [locked]="true" title="Tax Rate" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="padContingency" [locked]="true" title="Pad Contingency" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="cost" [locked]="true" title="Cost" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="scenarioType" [locked]="true" title="Scenario Type" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="devArea" [locked]="true" title="Dev Area" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="basin" [locked]="true" title="Basin" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="geographicArea" [locked]="true" title="Geographic Area" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="state" [locked]="true" title="State" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp1Id" [locked]="true" title="Well Prop 1 Id" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp2Id" [locked]="true" [locked]="true" [locked]="true" title="Well Prop 2 Id" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp3Id" [locked]="true" title="Well Prop 3 Id" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp4Id" [locked]="true" title="Well Prop 4 Id" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp5Id" [locked]="true" title="Well Prop 5 Id" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp6Id" [locked]="true" title="Well Prop 6 Id" [width]="200">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp7Id" [locked]="true" title="Well Prop 7 Id" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="wellProp8Id" [locked]="true" title="Well Prop 8 Id" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="effectiveDate" [locked]="true" title="Efective Date" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="expiryDate" [locked]="true" title="Expiry Date" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="activeInd" [locked]="true" title="Active Ind" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="padRdmo" [locked]="true" title="Pad Rdmo" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="orderItem" [locked]="true" title="Order Item" [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="padOrder" [locked]="true" title="Pad Order" [width]="350">
        </kendo-grid-column>

        <kendo-grid-excel fileName="ReportOutAllScenarios.xlsx" [fetchData]="allData"></kendo-grid-excel>
    </kendo-grid>
    <app-pager></app-pager>-->
    <button kendoButton (click)="click()">Export All Scenarios</button>
</div>