import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { InputSummaryModel } from './input-summary-model';
import { IDevelopmentArea } from '../../../api/devArea';
import { State } from '../../../api/state';
import { Basin } from '../../../api/basin';
import { GeographicArea } from '../../../api/geographicArea';
import { ScenarioType } from '../../../api/scenarioType';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { NotificationService, NotificationRef } from '@progress/kendo-angular-notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-input-summary',
  styleUrls: ['./input-summary.component.css'],
  templateUrl: './input-summary.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSummaryComponent implements OnInit, OnDestroy {
  public canEditCnd: boolean = false;
  public developmentAreaOptions: IDevelopmentArea[];
  // Array<string> = developmentAreaOptions;
  public stateOptions: State[];
  public basinOptions: Basin[];
  public geographicAreaOptions: GeographicArea[];
  public showUpdateCostButton = false;

  public scenarioTypeOptions: ScenarioType[];
  @ViewChild(NumericTextBoxComponent) public ntbContingency: NumericTextBoxComponent;

  public developmentArea: FormControl = new FormControl(null, Validators.required);
  public scenarioType: FormControl = new FormControl(null, Validators.required);
  public handoverNumber: FormControl = new FormControl({ value: null, disabled: true }, Validators.pattern(/^\d+$/));
  public scenarioName: FormControl = new FormControl({ value: null, disabled: true });
  public year: FormControl = new FormControl(null, Validators.required);
  public contingency: number;
  public flowlineThreshold: FormControl = new FormControl(
    { value: 15840, disabled: false },
    Validators.pattern(/^\d+$/)
  );
  public assumedNumWellsPerTestSeparator: FormControl = new FormControl(
    { value: 8, disabled: false },
    Validators.pattern(/^\d+$/)
  );
  public greenfieldInstallTimeframe: FormControl = new FormControl(
    { value: 0, disabled: false },
    Validators.pattern(/^\d+$/)
  );
  public isDeleted: boolean;
  public inputSummary: InputSummaryModel;

  public dialogOpened = false;
  public formatOptions: any = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
    style: 'percent',
  };
  public lstYears: string[] = [];
  public selectedYear = '';
  public originalYear = '';
  public isNewScenario = true;
  @Output() public openDeletePads = new EventEmitter<boolean>();
  @Output() public openCopyPads = new EventEmitter<boolean>();
  @Output() public openActiveForOutlook = new EventEmitter<boolean>();
  @Output() public openSmartImport = new EventEmitter<boolean>();
  @Output() public openImportPadBuilder = new EventEmitter<boolean>();
  @Output() public saveScenarioCost = new EventEmitter<boolean>();
  @Output() public refreshSmartImportPads = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private scenarioStoreService: ScenarioStoreService,
    private changeDetector: ChangeDetectorRef,
    private permissionsProvider: PermissionsProvider,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {}

  public ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const scenarioId = params.get('id');
      if (scenarioId) {
        this.isNewScenario = false;
      } else {
        this.lstYears = ['2019', '2023', '2024'];
        this.selectedYear = '2019';
        this.scenarioStoreService.updateYear(Number(this.selectedYear));
      }
    });
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.scenarioStoreService.inputSummary$.subscribe((data: InputSummaryModel) => {
      this.developmentArea.setValue(data.developmentArea, {
        emitEvent: false,
      });

      this.scenarioStoreService.developmentAreaOptions$.subscribe(developmentAreas => {
        this.developmentAreaOptions = developmentAreas.filter(d => d.isDeleted === false);
        //Add to selectable DDL if is an old one.
        const isOld = data.developmentArea
          ? developmentAreas.find(d => d.isDeleted === true && data.developmentArea.id)
          : false;
        if (isOld) {
          this.developmentAreaOptions.unshift(isOld);
        }
      });

      this.scenarioType.setValue(data.scenarioType, { emitEvent: false });
      this.handoverNumber.setValue(data.handoverNumber, { emitEvent: false });
      if (
        this.scenarioType.value &&
        (this.scenarioType.value.name === 'Handover' || this.scenarioType.value.name === 'Recycle')
      ) {
        // this.handoverNumber.disable({ emitEvent: false });
        // if (this.scenarioType.value.name === 'Handover' || this.scenarioType.value.name === 'Recycle') {
        this.handoverNumber.enable({ emitEvent: false });
        // }
      } else {
        this.handoverNumber.disable({ emitEvent: false });
      }
      this.scenarioName.setValue(data.scenarioName, { emitEvent: false });
      if (this.scenarioName.value) {
        this.scenarioName.enable({ emitEvent: false });
      }
      this.contingency = data.contingency;
      this.flowlineThreshold.setValue(data.flowlineThreshold, {
        emitEvent: false,
      });
      this.assumedNumWellsPerTestSeparator.setValue(data.assumedNumWellsPerTestSeparator, { emitEvent: false });
      this.greenfieldInstallTimeframe.setValue(data.greenfieldInstallTimeframe, { emitEvent: false });
      this.changeDetector.markForCheck();
    });
    this.scenarioStoreService.scenarioTypeOptions$.subscribe(data => {
      this.scenarioTypeOptions = data;
    });
    this.scenarioStoreService.stateOptions$.subscribe(data => {
      this.stateOptions = data;
    });
    this.scenarioStoreService.basinOptions$.subscribe(data => {
      this.basinOptions = data;
    });
    this.scenarioStoreService.geographicArea$.subscribe(data => {
      this.geographicAreaOptions = data;
    });
    this.bindToFormControls();

    this.scenarioStoreService.year$.subscribe(year => {
      if (year && this.lstYears.length == 0) {
        this.lstYears = ['2019', '2023', '2024'];
        this.selectedYear = year.toString();
        this.originalYear = year.toString();
      }
    });
  }

  public bindToFormControls(): void {
    this.developmentArea.valueChanges.subscribe(() => this.updateScenarioName());
    this.scenarioType.valueChanges.subscribe(() => this.updateScenarioName());
    this.handoverNumber.valueChanges.subscribe(() => this.updateScenarioName());
    this.flowlineThreshold.valueChanges.pipe(debounceTime(500)).subscribe(() => this.inputSummaryUpdated());
    this.assumedNumWellsPerTestSeparator.valueChanges.subscribe(() => this.inputSummaryUpdated());
    this.greenfieldInstallTimeframe.valueChanges.subscribe(() => this.inputSummaryUpdated());
    this.assumedNumWellsPerTestSeparator.valueChanges.subscribe(() => this.inputSummaryUpdated());
    this.scenarioName.valueChanges.subscribe(() => this.inputSummaryUpdated());
  }

  public updateScenarioName(): void {
    if (this.developmentArea.value) {
      this.scenarioName.enable({ emitEvent: false });
    } else {
      this.scenarioName.disable({ emitEvent: false });
    }

    if (!this.developmentArea.value) {
      return;
    }

    const devArea: IDevelopmentArea = this.developmentArea.value;
    const scenarioType: ScenarioType = this.scenarioType.value;
    if (scenarioType !== null) {
      if (scenarioType.name === 'Handover' || scenarioType.name === 'Recycle') {
        this.handoverNumber.enable({ emitEvent: false });
        this.scenarioName.setValue(
          devArea.name + ' ' + (scenarioType.name || '') + ' ' + (this.handoverNumber.value || ''),
          { emitEvent: false }
        );
      } else {
        this.handoverNumber.disable({ emitEvent: false });
        this.handoverNumber.setValue('', { emitEvent: false });
        this.scenarioName.setValue(devArea.name + ' ' + (scenarioType.name || ''), { emitEvent: false });
      }
    }

    this.inputSummaryUpdated();
  }

  public inputSummaryUpdated(): void {
    const devArea: IDevelopmentArea = this.developmentArea.value;
    if (!devArea) {
      return;
    }

    const state = this.stateOptions.find(s => s.id === devArea.stateId);
    const geographicArea = this.geographicAreaOptions.find(ga => ga.id === devArea.geographicAreaId);
    const basin = this.basinOptions.find(b => b.id === geographicArea.basinId);

    const inputSummary: InputSummaryModel = {
      developmentArea: devArea,
      scenarioType: this.scenarioType.value,
      state,
      handoverNumber: this.handoverNumber.value,
      scenarioName: this.scenarioName.value,
      basin,
      geographicArea,
      contingency: this.contingency,
      flowlineThreshold: this.flowlineThreshold.value,
      assumedNumWellsPerTestSeparator: this.assumedNumWellsPerTestSeparator.value,
      greenfieldInstallTimeframe: this.greenfieldInstallTimeframe.value,
      isDeleted: this.isDeleted,
      year: Number(this.selectedYear),
    };
    this.scenarioStoreService.inputSummaryUpdated(inputSummary);
  }

  public handleContingencyChange(value: any): void {
    if (!value && value !== 0) {
      this.scenarioStoreService.clearOverrideInputSummaryContingency();
      this.ntbContingency.writeValue(this.contingency);
    } else {
      this.scenarioStoreService.inputSummaryContingencyOverridden(value);
    }
  }

  public updateScenarioCost() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content:
        'Are you sure you want to update all associated costs for this scenario? This process will override individual pad year costs previously assigned.',
      actions: [{ text: 'No' }, { text: 'Yes', themeColor: 'primary' }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        console.log('close');
      } else {
        if (result.text === 'Yes') {
          this.spinner.show();
          this.scenarioStoreService.updateScenarioCost(this.selectedYear).subscribe(
            data => {
              this.originalYear = this.selectedYear;
              this.showUpdateCostButton = false;
              this.saveScenarioCost.next(true);
            },
            error => {
              this.spinner.hide();
              this.notificationService.show({
                content: 'There was an error updating Scenario Cost Year',
                position: { horizontal: 'center', vertical: 'top' },
                animation: { type: 'fade', duration: 700 },
                type: { style: 'error', icon: true },
              });
            }
          );
        } else {
          dialog.close();
        }
      }
    });
  }

  public cellClassForContingency(): string {
    let cellClass = 'k-widget k-numerictextbox ';

    if (this.scenarioStoreService.isInputSummaryContingencyOverryiden()) {
      cellClass += 'overridden-numericBoxField';
    }
    return cellClass;
  }

  public ngOnDestroy() {
    this.scenarioStoreService.deletePadbuilderDataImported();
    this.openDeletePads.complete();
    this.openCopyPads.complete();
    this.openImportPadBuilder.complete();
    this.openActiveForOutlook.complete();
    this.openSmartImport.complete();
    this.refreshSmartImportPads.complete();
  }

  public getScenarioStoreService() {
    return this.scenarioStoreService;
  }

  public handleChangeYear(value): void {
    this.selectedYear = value;

    if (!this.isNewScenario && this.selectedYear != this.originalYear) {
      this.showUpdateCostButton = true;
    } else {
      this.showUpdateCostButton = false;
    }

    this.scenarioStoreService.updateYear(value);
  }

  public onOpenDeletePads(args: any): void {
    this.openDeletePads.next(args);
  }

  public onOpenCopyPads(args: any): void {
    this.openCopyPads.next(args);
  }

  public onOpenActiveForOutlook(args: any): void {
    this.openActiveForOutlook.next(args);
  }

  public onOpenSmartImport(args: any): void {
    this.openSmartImport.next(args);
  }

  public onRefreshPadsSmartImport(args: any): void {
    this.refreshSmartImportPads.next(args);
  }

  public onOpenImportPadBuilder(args: any): void {
    this.openImportPadBuilder.next(args);
  }
}
