<kendo-grid #scopeNonStandard
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            class="scope-nonstandard-outside-factory-projects-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!scopeNonStandardOutsideFactoryProjectsService.hasChanges()"
            (click)="cancelChanges(scopeNonStandard)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" width="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" width="150px" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-dropdownlist popupAnchor
                          [data]="performanceBodPackagesList"
                          [defaultItem]="{ id: null, name: 'Select item...' }"
                          textField="name"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPackagesId')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description"
                     title="Project Description"
                     width="150px"
                     [editable]="true"></kendo-grid-column>
  <kendo-grid-column field="readyForServiceDate"
                     title="Ready for Service Date"
                     width="150px"
                     [editable]="true"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.readyForServiceDate"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('readyForServiceDate')">
      </kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="requestedOilCapacityBopd"
                     title="Requested Oil Capacity (BOPD)"
                     width="150px"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="requestedWaterCapacityBwpd"
                     title="Requested Water Capacity (BWPD)"
                     width="150px"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="requestedGasCapacityMmscfd"
                     title="Requested Gas Capacity (MMSCFD)"
                     width="150px"
                     [editable]="true"
                     editor="numeric"
                     filter="numeric">
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode"
                      title="Dummy Charge Code"
                      [editable]="true"
                      width="150">
  </kendo-grid-column>
  <kendo-grid-column field="cndHOEstimate" 
                     title="C&D/HO Estimate" 
                     [editable]="true" 
                     editor="numeric"
                     filter="numeric"
                     width="150">
  </kendo-grid-column>
  <kendo-grid-column field="afeNumber"
                    title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"
                     title="AFE Amount."
                     [editable]="true"
                     editor="numeric" 
                     filter="numeric"
                     width="150">

  </kendo-grid-column>
  
  <kendo-grid-column field="comments" title="Comments" width="150px" [editable]="true"></kendo-grid-column>
  <kendo-grid-command-column title="" width="130">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
