import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { process, State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { NgxSpinnerService } from 'ngx-spinner';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-performance-bod',
  templateUrl: './performance-bod.component.html',
  
})
export class PerformanceBODComponent implements OnInit {
  public gridData: GridDataResult;
  public perfBODData: IPerformanceBod[];
  public show: boolean = true;
  public routeName: any[];
  public showAllBods: boolean = false;
  public openedDelete: boolean;
  public performanceBODSelected: any;
  public hiddenAdmin: boolean;
  public canEditPerformanceBod: boolean = false;
  public isAdmin: boolean = false;
  public multiple = false;
  public allowUnsort = true;

  public sort: SortDescriptor[] = [
    {
      field: 'name',
      dir: 'asc',
    },
  ];

  public state: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  constructor(
    private scenarioStoreService: ScenarioStoreService,
    private router: Router,
    private performanceBodStoreService: PerformanceBodStoreService,
    private spinner: NgxSpinnerService,
    private permisssionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.isAdmin = this.permisssionsProvider.isAdmin;
    this.canEditPerformanceBod = this.permisssionsProvider.canEditPerformanceBod;
    this.authenticationService.isAdmin$.subscribe(value => {
      if (value) {
        this.hiddenAdmin = false;
      } else {
        this.hiddenAdmin = true;
      }
    });

    this.performanceBodStoreService.ListAllPerformanceBOD();
    this.routeName = this.router.url.split('/');
    const bodRoute = this.routeName[2];
    if (bodRoute === 'AllBODs') {
      this.showAllBods = true;
    }

    if (this.showAllBods === false) {
      this.performanceBodStoreService.performanceBodsAll$.subscribe(data => {
        this.perfBODData = data;
        for (let i = 0; i < this.perfBODData.length; i++) {
          let dateCreated = new Date(this.perfBODData[i].createdDate).toDateString();
          let _dateCreated = new Date(dateCreated);
          let dateUpdated = new Date(this.perfBODData[i].updatedDate).toDateString();
          let _dateUpdated = new Date(dateUpdated);

          this.perfBODData[i].createdDate =
            ((this.perfBODData[i].createdDate !== null)
              ? _dateCreated
              : this.perfBODData[i].createdDate = null);

          this.perfBODData[i].updatedDate =
            ((this.perfBODData[i].updatedDate !== null)
              ? _dateUpdated
              : this.perfBODData[i].updatedDate = null);

          this.perfBODData[i].performanceProjectManagerCai =
            ((this.perfBODData[i].performanceProjectManagerCai === null ||
              this.perfBODData[i].performanceProjectManagerCai === undefined
              ? ''
              : this.perfBODData[i].performanceProjectManagerCai));

          this.perfBODData[i].performanceTeamLeadCai =
            ((this.perfBODData[i].performanceTeamLeadCai === null ||
              this.perfBODData[i].performanceTeamLeadCai === undefined)
              ? ''
              : this.perfBODData[i].performanceTeamLeadCai);

          this.perfBODData[i].performanceReservoirEngineerCai =
            ((this.perfBODData[i].performanceReservoirEngineerCai === null ||
              this.perfBODData[i].performanceReservoirEngineerCai === undefined)
              ? ''
              : this.perfBODData[i].performanceReservoirEngineerCai);
        }

        this.gridData = process(
          this.perfBODData.filter(
            data =>
              data.createdBy === this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceProjectManagerCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceTeamLeadCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceReservoirEngineerCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase()
          ),
          this.state
        );
      });
    } else if (this.showAllBods === true) {
      this.performanceBodStoreService.performanceBodsAll$.subscribe(data => {
        this.perfBODData = data;
        for (let i = 0; i < this.perfBODData.length; i++) {
          let dateCreated = new Date(this.perfBODData[i].createdDate).toDateString();
          let _dateCreated = new Date(dateCreated);
          let dateUpdated = new Date(this.perfBODData[i].updatedDate).toDateString();
          let _dateUpdated = new Date(dateUpdated);

          this.perfBODData[i].createdDate !== null
            ? (this.perfBODData[i].createdDate = _dateCreated)
            : (this.perfBODData[i].createdDate = null);
          this.perfBODData[i].updatedDate !== null
            ? (this.perfBODData[i].updatedDate = _dateUpdated)
            : (this.perfBODData[i].updatedDate = null);
        }

        this.gridData = process(this.perfBODData, this.state);
      });
    }
  }

  public openHandler({ dataItem }) {
    if (dataItem) {
      this.router.navigate(['/performanceBOD', dataItem.id]);
    }
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridData = {
      data: orderBy(this.perfBODData, this.sort),
      total: this.perfBODData.length,
    };
  }

  public onDataStateChange(state): void {
    this.state = state;
    if (this.showAllBods === false) {
      this.performanceBodStoreService.performanceBodsAll$.subscribe(data => {
        this.perfBODData = data;
        for (let i = 0; i < this.perfBODData.length; i++) {
          let dateCreated = new Date(this.perfBODData[i].createdDate).toDateString();
          let _dateCreated = new Date(dateCreated);
          let dateUpdated = new Date(this.perfBODData[i].updatedDate).toDateString();
          let _dateUpdated = new Date(dateUpdated);

          this.perfBODData[i].createdDate =
            ((this.perfBODData[i].createdDate !== null)
              ? _dateCreated
              : null);
          this.perfBODData[i].updatedDate =
            ((this.perfBODData[i].updatedDate !== null)
              ? _dateUpdated
              : null);

          this.perfBODData[i].performanceProjectManagerCai =
            ((this.perfBODData[i].performanceProjectManagerCai === null ||
              this.perfBODData[i].performanceProjectManagerCai === undefined)
              ? ''
              : this.perfBODData[i].performanceProjectManagerCai);

          this.perfBODData[i].performanceTeamLeadCai =
            ((this.perfBODData[i].performanceTeamLeadCai === null ||
              this.perfBODData[i].performanceTeamLeadCai === undefined)
              ? ''
              : this.perfBODData[i].performanceTeamLeadCai);

          this.perfBODData[i].performanceReservoirEngineerCai =
            ((this.perfBODData[i].performanceReservoirEngineerCai === null ||
              this.perfBODData[i].performanceReservoirEngineerCai === undefined)
              ? '' : this.perfBODData[i].performanceReservoirEngineerCai);
        }

        this.gridData = process(
          this.perfBODData.filter(
            data =>
              data.createdBy === this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceProjectManagerCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceTeamLeadCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase() ||
              data.performanceReservoirEngineerCai.toLocaleUpperCase() ===
                this.authenticationService.getCurrentUser().toLocaleUpperCase()
          ),
          this.state
        );
      });
    } else if (this.showAllBods === true) {
      this.performanceBodStoreService.performanceBodsAll$.subscribe(data => {
        this.perfBODData = data;
        for (let i = 0; i < this.perfBODData.length; i++) {
          let dateCreated = new Date(this.perfBODData[i].createdDate).toDateString();
          let _dateCreated = new Date(dateCreated);
          let dateUpdated = new Date(this.perfBODData[i].updatedDate).toDateString();
          let _dateUpdated = new Date(dateUpdated);

          this.perfBODData[i].createdDate !== null
            ? (this.perfBODData[i].createdDate = _dateCreated)
            : (this.perfBODData[i].createdDate = null);
          this.perfBODData[i].updatedDate !== null
            ? (this.perfBODData[i].updatedDate = _dateUpdated)
            : (this.perfBODData[i].updatedDate = null);
        }

        this.gridData = process(this.perfBODData, this.state);
      });
    }
  }

  public getDateUTC(date) {
    date = new Date();
    if (date !== undefined && date !== null) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    } else {
      return date;
    }
  }

  public closeDelete(status) {
    this.openedDelete = false;
  }

  public openDelete(dataItem: any) {
    this.openedDelete = true;
    this.performanceBODSelected = dataItem;
  }

  public removeHandler(dataItem: any) {
    this.spinner.show();
    this.openedDelete = false;

    this.performanceBODSelected.isDeleted = true;
    this.performanceBodStoreService.deletePerformanceBOD(this.performanceBODSelected.id);

    setTimeout(() => {
      this.spinner.hide();
      this.ngOnInit();
    }, 4000);
  }
}
