<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" [width]="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid
  #edsIe
  [data]="view | async"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [resizable]="true"
  [sort]="gridState.sort"
  [sortable]="{ mode: 'multiple' }"
  (dataStateChange)="onStateChange($event)"
  filterable="menu"
  [filter]="filter"
  (filterChange)="handlerFilter($event)"
  class="infrastructure-eds-draggable"
  [rowClass]="rowCallback"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      class="k-button"
      [disabled]="!performanceBodEdsIeService.hasChanges()"
      (click)="cancelChanges(edsIe)"
      [primary]="true"
    >
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" [width]="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodId" title="PerdoformanceBodId" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column title="Well Pad Name" field="performanceBodPadsId" [editable]="true" [width]="130">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="wellPadName"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPadsList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-column="column"
      let-formGroup="formGroup"
      let-isNew="isNew"
    >
      <kendo-dropdownlist
        [data]="performanceBodPadsList"
        textField="wellPadName"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('performanceBodPadsId')"
        (valueChange)="changePackage($event, dataItem, formGroup)"
      >
      </kendo-dropdownlist>
      <span #labelPopup></span>
      <kendo-popup
        [anchor]="labelPopup"
        [hidden]="messageDuplicate"
        popupClass="k-widget k-tooltip k-tooltip-validation"
      >
        The Pad name is already selected.
      </kendo-popup>
    </ng-template>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false" [width]="130">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        textField="name"
        valueField="id"
        [filterService]="filterService"
        [currentFilter]="filter"
        [data]="performanceBodPackagesList"
      >
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{
        getPerformanceBodPackages(getPerformanceBodPads(dataItem.performanceBodPadsId)?.performanceBodPackagesId)?.name
      }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="readyForServiceDate"
    title="EDS/IE RFSD"
    [filterable]="filterable"
    filter="date"
    [width]="130"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.readyForServiceDate"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('readyForServiceDate')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="permitsRequiredBy"
    title="Permits Required By"
    [filterable]="true"
    filter="date"
    [width]="130"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.permitsRequiredBy | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker
        [value]="dataItem.permitsRequiredBy"
        [format]="'MM/dd/yyyy'"
        [formControl]="formGroup.get('permitsRequiredBy')"
      ></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="powerSupplySource" title="Power Supply / Source" [editable]="true" [width]="130">
  </kendo-grid-column>
  <kendo-grid-column field="from" title="From (Location)" [editable]="true" [width]="130"> </kendo-grid-column>
  <kendo-grid-column field="to" title="To (Location)" [editable]="true" [width]="130"> </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments" [editable]="true" [width]="150"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="150">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
