import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CustomCheckDirective } from './treeview-check.directive';
import { FitCcInCellTabDirective } from './incell-tab.directive';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [CustomCheckDirective, FitCcInCellTabDirective],
  exports: [CustomCheckDirective, FitCcInCellTabDirective],
})
export class FitCcDirectivesModule {}
