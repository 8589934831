<kendo-grid #performanceBodNotesAttachment
            [data]="view | async"
            [pageSize]="gridState.take" [skip]="gridState.skip" [sort]="gridState.sort"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [height]="490"
            [navigable]="true">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class='k-button'
            [hidden]="true"
            [disabled]="!summaryNotesAttachmentEditService.hasChanges()"
            (click)="saveChanges(performanceBodNotesAttachment);"
            [primary]="true">
      Save Changes
    </button>
    <button kendoButton
            class='k-button'
            [hidden]="true"
            [disabled]="!summaryNotesAttachmentEditService.hasChanges()"
            (click)="cancelChanges(performanceBodNotesAttachment);"
            [primary]="true">
      Cancel Changes
    </button>
    <button kendoGridAddCommand [primary]="true" [disabled]="false">Upload</button>
  </ng-template>
  <kendo-grid-column field="name" title="Attachment Name" [editable]="false">
    <ng-template kendoGridCellTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup">
      <div align="center">
        <a href="javascript:void(0)" class="k-link k-menu-link" (click)="download(dataItem.id)">{{dataItem.name}} </a>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="300px">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [disabled]="false">Remove</button>
    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>


<br>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()"> Cancel </button>
  <button kendoButton (click)="onConfirmAction()" [primary]="true"
          (disabled)="!summaryNotesAttachmentEditService.hasChanges()">
    Save &amp; Close
  </button>
</kendo-dialog-actions>