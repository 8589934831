import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LogService } from '../services/log-service';
import { loggingUserMessages } from '../api/mock/mockData';

export type HandleError = (sourceName: string) => (error: HttpErrorResponse) => Observable<any>;

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandler {
  constructor(private logService: LogService) {}

  createHandleError = () => (sourceName = '') => this.handleError(sourceName);

  handleError<T>(sourceName = '') {
    return (error: HttpErrorResponse): Observable<T> => {
      const message = error.message;
      const userMessage = loggingUserMessages.find(s => s.source === sourceName).userMessage;

      this.logService.logError(sourceName, message, userMessage);

      // tslint:disable-next-line: deprecation
      return Observable.throw(userMessage);
    };
  }
}
