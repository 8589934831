import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ButtonFillMode, ButtonThemeColor, PopupSettings, DropDownButtonComponent } from '@progress/kendo-angular-buttons';
import { IFitCcPopupItem } from '../models/popup-item';

@Component({
  selector: 'fit-cc-popup-button',
  templateUrl: './fit-cc-popup-button.component.html',
  styleUrls: ['./fit-cc-popup-button.component.scss']
})
export class FitCcPopupButtonComponent implements OnInit {

  @Input()
  buttonClass: any;

  @Input()
  label: string;

  @Input()
  icon: string;

  @Input()
  iconClass: string;

  @Input()
  imageUrl: string;

  @Input()
  fillMode: ButtonFillMode;

  @Input()
  themeColor: ButtonThemeColor = 'primary';

  @Input()
  tabIndex: number;

  @Input()
  textField: string;

  @Input()
  data: IFitCcPopupItem[];

  @Input()
  disabled: boolean;

  @Input()
  settings: PopupSettings;

  @Output()
  onClose = new EventEmitter();

  @Output()
  onItemClick = new EventEmitter();

  @Output()
  onBlur = new EventEmitter();

  @Output()
  onFocus = new EventEmitter();

  @Output()
  onOpen = new EventEmitter();

  @ViewChild('kendoDropdown', { static: true })
  kendoDd: DropDownButtonComponent;

  public isOpen: boolean;

  constructor() {
  }

  ngOnInit() {
    this.isOpen = this.kendoDd.isOpen;
  }

  onCloseHandler(event) {
    this.onClose.emit(event);
  }

  onItemClickHandler(event) {
    this.onItemClick.emit(event);
  }

  onBlurHandler(event) {
    this.onBlur.emit(event);
  }

  onFocusHandler(event) {
    this.onFocus.emit(event)
  }

  onOpenHandler(event) {
    this.onOpen.emit(event);
  }

  public blur() {
    this.kendoDd.blur();
  }

  public focus() {
    this.kendoDd.focus();
  }

  public toggle(open: boolean) {
    this.kendoDd.toggle(open);
  }

}
