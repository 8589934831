import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IFacilityItem } from '../../modules/admin/models/facility.item.model';
import { IFacilityStdDesign } from '../../modules/admin/models/facility.std.design.model';
import { ISectionType } from '../../modules/admin/models/section.type.model';
import { IFacilityBod } from '../_models/facility.bod.model';
import { IDisciplineType } from '../../modules/admin/models/discipline.type.model';
import { IFacilityItemType } from '../../modules/admin/models/facility.item.type.model';
import { FacilityStdDesignItems } from '../../modules/admin/models/facility.std.design.items.model';
import { IUnitOfMeasure } from '../../modules/admin/models/unit.of.measure.model';
import { IVendor } from '../../modules/admin/models/vendor.model';
import { IFacilityType } from '../../modules/admin/models/facility.type.model';
import { IUnitRateArea } from '../_models/unit.rate.area';
import { IBasin } from '../_models/basin.interface';
import { IGeographicArea } from '../_models/geographicarea.interface';
import { IDevArea } from '../_models/devarea.interface';
import { IContractType } from '../../api/contract-type';
import { ILocationCatalogModel } from '../_models/location-catalog-model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class ListService {
  constructor(private http: HttpClient) {}

  public getFacilityItems(): Observable<IFacilityItem[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityItem/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getStandardDesigns(): Observable<IFacilityStdDesign[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getAllStandardDesigns(): Observable<IFacilityStdDesign[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesign/ListAllFacilityStdDesign/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getSectionType(): Observable<ISectionType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'SectionType/List';

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilities(): Observable<IFacilityBod[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/List';
    return this.http.get<IFacilityBod[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDisciplineType(): Observable<IDisciplineType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'DisciplineType/List/';

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getAfeByFacilityBodId(facilityBodId: string): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityAfe/ListByFacilityBodId/' + facilityBodId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilityItemsbyEquipments(facilityBodId: string): Observable<IFacilityItem[]> {
    const serviceUrl: string =
      environment.PacerDomainURL + 'FacilityItem/GetFacilityItemsByEquipments/' + facilityBodId;
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilityItemTypes(): Observable<IFacilityItemType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityItemType/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilityStdDesignItems(): Observable<FacilityStdDesignItems[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityStdDesignItems/List';

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getUnitOfMeasure(): Observable<IUnitOfMeasure[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'UnitOfMeasure/List/';

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getVendors(): Observable<IVendor[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'Vendor/List/';

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilityBodById(facilityBodId: string): Observable<IFacilityBod[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityBod/getFacilityBodById/' + facilityBodId;

    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getFacilityItemsSortFilter(): Observable<IFacilityItem[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityItem/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        const data1 = data.filter(i => !i.isCndItem);
        const data2 = data1.sort(x => x.name);
        return data2; // data.filter(i => !i.isCndItem);
      })
    );
  }

  public getFacilityTypes(): Observable<IFacilityType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'FacilityType/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data.filter(d => d.isFitType === false);
      })
    );
  }

  public getUnitRateAreas(): Observable<IUnitRateArea[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'UnitRateArea/List';
    return this.http.get<IUnitRateArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getBasins(): Observable<IBasin[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'Basin/List';
    return this.http.get<IUnitRateArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDeletedBasins(): Observable<IBasin[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'Basin/ListDeleted';
    return this.http.get<IUnitRateArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getGeographicAreas(): Observable<IGeographicArea[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'GeographicArea/List';
    return this.http.get<IGeographicArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDeletedGeographicAreas(): Observable<IGeographicArea[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'GeographicArea/ListDeleted';
    return this.http.get<IGeographicArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDevAreas(): Observable<IDevArea[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'DevelopmentArea/List';
    return this.http.get<IDevArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getDeleteDevAreas(): Observable<IDevArea[]> {
    const serviceUrl: string = environment.PricetDomainURL + 'DevelopmentArea/ListDeleted';
    return this.http.get<IDevArea[]>(serviceUrl, httpOptions).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  public getContractTypes(): Observable<IContractType[]> {
    const serviceUrl: string = environment.PacerDomainURL + 'ContractType/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: IContractType[]) => {
        return data;
      })
    );
  }

  public getLocationCatalogs(): Observable<ILocationCatalogModel> {
    const serviceUrl: string = environment.PricetDomainURL + 'LocationCatalogs/List/';
    return this.http.get(serviceUrl, httpOptions).pipe(
      map((data: ILocationCatalogModel) => {
        return data;
      })
    );
  }
}
