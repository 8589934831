<div *ngIf="showProgressBar" class="mat-app-background basic-container">
    <app-progress-bar-indeterminate-example></app-progress-bar-indeterminate-example>
  </div>
  <form #Form="ngForm" [formGroup]="formUpload" novalidate (ngSubmit)="import(formUpload.value, formUpload.valid)">
    <div class="card-body">
      <div class="row no-gutters">
        <div class="col-sm-12">           
          <div class="form-group row">            
            <div class="col-sm-10">
              <kendo-upload
                #padbuilderUploader
                [saveUrl]="uploadSaveUrl"
                [removeUrl]=""
                [restrictions]="pbRestrictions"
                [multiple]="false"
                (error)="errorUpload($event)"
                (success)="successUpload($event)"
                (upload)="fileUploading($event)"
                (uploadProgress)="uploadProgressHandler($event)"
                class="form-control" 
              >
                <kendo-upload-messages headerStatusUploaded="File import in progress"> </kendo-upload-messages>
              </kendo-upload>
              <p style="color: #1984c8; font-size: .8em; margin-top: .5em;" [hidden]="!isExcelFile">
                Upload an excel file is required.
              </p>
            </div>
          </div>
          <div class="form-group row">
            <ng-template #notiftemplate></ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>
  <kendo-grid [data]="excelErrors" *ngIf="excelErrors">
    <kendo-grid-column field="rowNumber" title="Row" width="60"> </kendo-grid-column>
    <kendo-grid-column field="columnNumber" title="Column"  width="60"> </kendo-grid-column>
    <kendo-grid-column field="error" title="Error Message">
    </kendo-grid-column> 
  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
  </kendo-dialog-actions>  