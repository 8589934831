<div class="scenario-path">
  <span class="scenario-path-span">{{ scenarioName }}</span>
</div>
<div class="form-actions text-right" style="text-align:right;">
  <app-cnd-tool-bar
    (openPadCostByYear)="onOpenPadCostByYear($event)"
    (openEditPads)="onOpenEditPads($event)"
    (saveScenario)="onSaveScenario($event)"
  ></app-cnd-tool-bar>
</div>
<div id="scenario" class="cnd-input">
  <kendo-tabstrip #tabStrip [tabPosition]="position" (tabSelect)="onTabSelect($event)" [keepTabContent]="true">
    <kendo-tabstrip-tab [title]="'PRICET Summary'" [selected]="false">
      <ng-template kendoTabContent *loadOnDemand>
        <app-summary
          [originalInputInfraData]="inputComponent.inputItemsComponent.originalInputInfraData"
          [alternatePadCosts]="inputComponent.inputItemsComponent.alternatePadCosts"
        >
        </app-summary>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Inputs'" [selected]="true">
      <ng-template kendoTabContent>
        <app-input
          (openEditPads)="onOpenEditPads($event)"
          (openDeletePads)="onOpenDeletePads($event)"
          (openCopyPads)="onOpenCopyPads($event)"
          (openActiveForOutlook)="onOpenActiveForOutlook($event)"
          (openSmartImport)="onOpenSmartImport($event)"
          (openImportPadBuilder)="onOpenImportPadBuilder($event)"
          (saveScenarioCost)="onSaveScenarioCost($event)"
          (refreshSmartImportPads)="onRefreshSmartImportPads($event)"
        ></app-input>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Contingency'" [selected]="false">
      <ng-template kendoTabContent *loadOnDemand>
        <app-contingency></app-contingency>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Cost Sheet'" [selected]="false">
      <ng-template kendoTabContent *loadOnDemand>
        <app-cost-sheet
          [originalInputInfraData]="inputComponent.inputItemsComponent.originalInputInfraData"
          [alternatePadCosts]="inputComponent.inputItemsComponent.alternatePadCosts"
        ></app-cost-sheet>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<kendo-dialog
  title="Edit pads"
  *ngIf="openEditPadsDialog"
  (close)="closeEditPadsDialog()"
  [width]="1200"
  [height]="600"
>
  <app-edit-pads [padId]="selectedPadIdForEdit" [scenarioId]="scenarioId"></app-edit-pads>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeEditPadsDialog()">Cancel</button>
    <button kendoButton [primary]="true" [disabled]="isReadyToOverridaPadName()" (click)="submitEditPadsDialog()">
      Submit
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<div kendoDialogContainer></div>
