import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[fitCcTemplate]',
})
export class FitCCTemplateDirective {
  @Input()
  type: string;
  @Input('fitCcTemplate')
  name: string;

  constructor(public template: TemplateRef<any>) {}

  getName(): string {
    return this.name;
  }
}
