import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import {
  GridDataResult,
  ColumnReorderEvent,
  DataStateChangeEvent,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { SortDescriptor, process, orderBy, State } from '@progress/kendo-data-query';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { ICndCostMappingSft } from '../../../api/cnd-cost-mapping-sft';
import { ICostLogicDetector } from '../../../api/cost-logic-detector';
import { InputSummaryModel } from '../input-summary/input-summary-model';

@Component({
  selector: 'app-cost-sheet',
  templateUrl: './cost-sheet.component.html',
  styleUrls: ['./cost-sheet.component.css'],
})
export class CostSheetComponent implements OnInit {
  public costSheetData: any[];
  public pads: any[];
  public items: any[];
  public padBuilderPads: any[] = [];
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public padCountsColumnGroupName: string = 'Scope Costs per Pad ($M)';
  public state: State = {};
  public taxRate: number;
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public readonly hiddenRows: string[];
  public cndCostMappingSftList: ICndCostMappingSft[];
  public isLoading = true;
  public costYear = '2019';
  @Input() public alternatePadCosts;
  @Input() public originalInputInfraData;

  constructor(private scenarioStoreService: ScenarioStoreService, private changeDetector: ChangeDetectorRef) {
    this.hiddenRows = scenarioStoreService.hiddenRows;
  }

  public ngOnInit(): void {
    this.costSheetData = [];
    this.pads = [];
    this.scenarioStoreService.year$.subscribe(data => {
      this.costYear = data.toString();
    });

    this.scenarioStoreService.costSheetPadBuilderDataPage$.subscribe(data => {
      this.padBuilderPads = data;
      this.changeDetector.markForCheck();
    });

    this.scenarioStoreService.pricetSummary$.subscribe(data => {
      this.taxRate = data.tax;
      this.changeDetector.markForCheck();
    });

    this.scenarioStoreService.infrastructureItems$.subscribe(data => {
      this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
        if (detector.isInfrastructureItemsReady && detector.isMappedCostReady && detector.isPadBuilderDataReady) {
          this.pads = data.pads;
          this.items = this.hideRows(data.inputItems);
          this.costSheetData = this.calculateCostSheetData(this.items);
          this.loadItems();
          this.changeDetector.markForCheck();
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
    });

    this.scenarioStoreService.cndCostMappingSft$.subscribe(data => {
      this.cndCostMappingSftList = data;
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public getPadContingency(padName: string): string {
    const pad = this.pads.find(p => p.padName === padName);
    if (pad) {
      return pad.padContingency;
    } else {
      return '';
    }
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.costSheetData, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  public onColumnReorder(columnReorderEvent: ColumnReorderEvent): void {
    const columnGroup = columnReorderEvent.column.parent ? columnReorderEvent.column.parent.title : null;
    if (columnGroup && columnGroup === this.padCountsColumnGroupName) {
      this.scenarioStoreService.reorderPadbuilderPad(
        columnReorderEvent.column['field'],
        columnReorderEvent.oldIndex,
        columnReorderEvent.newIndex
      );
    }
  }

  public trackIndex(index: number): any {
    return index;
  }

  public getPadSum(padName: string): number {
    let total = 0;
    if (this.scenarioStoreService.isReadyForCalculation()) {
      const pad = this.pads.find(p => p.padName === padName);
      if (pad !== undefined && pad !== null) {
        this.items.forEach(item => {
          if (pad !== null) {
            if (pad.padContingency !== undefined && pad.padContingency !== null) {
              const padContingency = pad.padContingency;
              const padItemCount = item[pad.padName];
              const itemCostPunit = item.costPunit;
              let padItemCost = 0;
              if (
                item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
                item.name === 'Cost Adjustment/Allowance: General Infr' ||
                item.name === 'Cost Adjustment/Allowance: Water Infr' ||
                item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
                item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
              ) {
                padItemCost = this.scenarioStoreService.getItemCostPadByLogic(
                  pad.padName,
                  item.name,
                  item.id,
                  padItemCount,
                  itemCostPunit,
                  pad.yearCost,
                  this.alternatePadCosts,
                  this.originalInputInfraData
                );
              } else {
                padItemCost =
                  this.scenarioStoreService.getItemCostPadByLogic(
                    pad.padName,
                    item.name,
                    item.id,
                    padItemCount,
                    itemCostPunit,
                    pad.yearCost,
                    this.alternatePadCosts,
                    this.originalInputInfraData
                  ) *
                  (1 + this.taxRate) *
                  (1 + padContingency);
              }
              total += padItemCost;
            }
          }
        });
      }

      if (pad) {
        pad.total = total;
      }

      return total; // changed to $M, us:224479;
    }
  }

  public calculateCostSheetData(items: any[]): any[] {
    const costSheetData = [];
    items.forEach((item, index) => {
      costSheetData[index] = {
        name: item.name,
        displayName: item.displayName,
        costPunit: item.costPunit, // changed to $M, us:224479;
        itemDetailId: item.itemDetailId,
        itemDetail: item.itemDetail,
      };
      let totalItemCost = 0;
      this.pads.forEach(pad => {
        const padName = pad.padName;
        const padContingency = pad.padContingency;
        const padItemCount = item[padName];
        const itemCostPunit = isNaN(item.costPunit) ? 0 : item.costPunit;
        let padItemCost = 0;
        if (
          item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
          item.name === 'Cost Adjustment/Allowance: General Infr' ||
          item.name === 'Cost Adjustment/Allowance: Water Infr' ||
          item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
          item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
        ) {
          padItemCost = this.scenarioStoreService.getItemCostPadByLogic(
            pad.padName,
            item.name,
            item.id,
            padItemCount,
            itemCostPunit,
            pad.yearCost,
            this.alternatePadCosts,
            this.originalInputInfraData
          );
        } else {
          padItemCost =
            this.scenarioStoreService.getItemCostPadByLogic(
              pad.padName,
              item.name,
              item.id,
              padItemCount,
              itemCostPunit,
              pad.yearCost,
              this.alternatePadCosts,
              this.originalInputInfraData
            ) *
            (1 + this.taxRate) *
            (1 + padContingency);
        }
        padItemCost = isNaN(padItemCost) ? 0 : padItemCost;
        costSheetData[index][padName] = padItemCost; // changed to $M, us:224479;
        totalItemCost += padItemCost;
      });
      costSheetData[index].total = totalItemCost; // changed to $M, us:224479;
    });
    return costSheetData;
  }

  public hideRows(data: any[]): any[] {
    const inputItems = data.filter(ar => !this.hiddenRows.find(rm => rm === ar.name));

    return inputItems;
  }

  private loadItems(): void {
    if (!this.state.filter || !this.state.filter.filters || this.state.filter.filters.length === 0) {
      this.gridView = {
        data: orderBy(this.costSheetData.slice(this.skip, this.skip + this.pageSize), this.sort),
        total: this.costSheetData.length,
      };
    } else {
      this.gridView = process(this.costSheetData, this.state);
    }
  }
}
