import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { InlineActions } from '../api/inline-actions.enum';
import { zip } from 'rxjs';

@Injectable()
export abstract class InlineEditService extends BehaviorSubject<any[]> {
  public loader = new EventEmitter<any>();
  public refreshed = new EventEmitter<boolean>();
  public itIsDone = new EventEmitter<any>();

  public abstract createdItems: any[];
  public abstract updatedItems: any[];
  public abstract deletedItems: any[];
  public abstract data: any[];
  public abstract originalData: any[];

  public currencyFormatOptions: any = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'name',
  };

  private applySetOrder = false;
  private setOrderBy: string;

  constructor() {
    super([]);
  }

  public abstract isNew(item: any): boolean;
  public abstract itemIndex(item: any, data: any[]): number;
  public abstract create(): Observable<any[]>;
  public abstract retrieve(): Observable<any[]>;
  public abstract update(): Observable<any[]>;
  public abstract delete(): Observable<any[]>;

  public cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
    this.fetch(InlineActions.Retrieve);
  }

  public createItem(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);
    super.next(this.data);
  }

  public updateItem(item: any): void {
    if (!this.isNew(item)) {
      const index = this.itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public removeItem(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = this.itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = this.itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = this.itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length || this.updatedItems.length || this.createdItems.length || this.applySetOrder
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    // Apply order
    this.setOrder();

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.fetch(InlineActions.Delete, this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.fetch(InlineActions.Update, this.updatedItems));
    }

    if (this.createdItems.length) {
      completed.push(this.fetch(InlineActions.Create, this.createdItems));
    }
    this.reset();
    zip(...completed).subscribe(() => this.read());
  }

  public cancelChanges(): void {
    this.reset();
    this.read();
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  public reset(): void {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
    this.applySetOrder = false;
  }

  public fetch(action: InlineActions, data?: any): Observable<any[]> {
    switch (action) {
      case InlineActions.Create:
        data = this.create().subscribe(() => this.fetch(InlineActions.Retrieve));
        break;
      case InlineActions.Retrieve:
        data = this.retrieve().subscribe(dateReturned => {
          this.data = dateReturned;
          this.originalData = this.cloneData(dateReturned);
          super.next(dateReturned);
        });
        break;
      case InlineActions.Update:
        data = this.update().subscribe(() => this.fetch(InlineActions.Retrieve));
        break;
      case InlineActions.Delete:
        data = this.delete().subscribe(() => this.fetch(InlineActions.Retrieve));
        break;
      default:
        data = this.retrieve().subscribe(dateReturned => {
          this.data = dateReturned;
          this.originalData = this.cloneData(dateReturned);
          super.next(dateReturned);
        });
    }
    return data;
  }

  public refresh(): void {
    this.fetch(InlineActions.Retrieve);
  }

  public refreshNotified(): void {
    this.retrieve().subscribe(data => {
      this.data = data;
      this.originalData = this.cloneData(data);
      super.next(data);
      this.itIsDone.emit(true);
    });
  }

  public markAsSetOrder(cai: string): void {
    this.setOrderBy = cai;
    this.applySetOrder = true;
  }

  protected setOrder(): void {
    if (this.applySetOrder) {
      if (this.data.length > 0) {
        const obj = this.data[0];
        if (!obj.hasOwnProperty('sortOrder')) {
          return;
        }
      } else {
        // nothing to be order it
        return;
      }
      let order = 0;
      for (const dataItem of this.data) {
        dataItem.sortOrder = order;
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.setOrderBy;
        //dataItem.updatedDate = dataItem.updatedDate; //This line showing as major issue in sonarqube
        this.updateItem(dataItem);
        order++;
      }
    }
  }
}
