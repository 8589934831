<div class="row">
    <div class="col-12">
      <div class="mcbu__unauthorized__wrapper">
        <div class="float-left">
          <span class="mcbu__unauthorized__icon"></span>
        </div>
        <div class="float-left">
          <h1 class="mcbu__h1 mcbu__unauthorized__h1">{{ title }}</h1>
          <p>{{ subtitle }}</p>
          <a
            href="http://remedy.chevron.com/arsys/forms/remedydirect.chevron.com/SRS%3AServiceRequestConsole/Default+Administrator+View/?mode=submit&F303900000=4&F303900900=SRHAA5V0FEMCAAOIRNIH57W3Y52HOE&F303906700=0&F303902000=0&F303902100=0&cacheid=20177f"
            title="Request Access"
            target="_blank"
            class="btn mcbu__btn--primary"
            rel="noopener noreferrer"
            >{{ button }}</a
          >
          <div class="mt-3 mb-3">
            <a class="mcbu__unauthorized__small" href="http://go.chevron.com/fitprogram" title="FIT Program"
              ><i class="k-icon k-i-arrow-right"></i> Go to FIT Sharepoint</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  