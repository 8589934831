import { Component, AfterContentInit } from '@angular/core';
import { ScenarioStoreService } from './services/scenario-store.service';
import { PerformanceBodStoreService } from './services/performance-bod-store.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})
export class AppComponent implements AfterContentInit {
  public appVersion: string;
  public dbVersion: string;
  public environmentTag: string;

  constructor(
    private scenarioStoreService: ScenarioStoreService,
    private notificationService: NotificationService,
    private performanceBodStoreService: PerformanceBodStoreService,
    private router : Router,
    private authenticationService : AuthenticationService
  ) {
    this.environmentTag = environment.EnvironmentTag;
    this.appVersion = '4.1';
    this.dbVersion = '4.1';

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        this.authenticationService.authenticate();
      }
    });

  }

  public ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.scenarioStoreService.errorInitPricet) {
        this.notificationService.show({
          content: 'Error loading initial data. Please try refreshing browser.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'error', icon: true },
        });
      }

      if (this.performanceBodStoreService.errorInitPerformanceBod) {
        this.notificationService.show({
          content: 'Error loading initial data performance bod. Please try refreshing browser.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'error', icon: true },
        });
      }
    }, 5000);
  }
}
