import { Component, Input, TemplateRef, ViewChild, ElementRef, forwardRef, EventEmitter, Output } from '@angular/core';
import { ToolBarToolComponent, ToolBarComponent } from '@progress/kendo-angular-toolbar';

export function outerWidth(element: any): number {
  let width = element.offsetWidth;
  const style = getComputedStyle(element);

  width += parseFloat(style.marginLeft) || 0 + parseFloat(style.marginRight) || 0;
  return width;
}

@Component({
  providers: [
    {
      provide: ToolBarToolComponent,
      useExisting: forwardRef(() => MessageToolbarComponent),
    },
  ],
  selector: 'app-message-toolbar',
  templateUrl: './message-toolbar.component.html',

})
export class MessageToolbarComponent extends ToolBarComponent {
  @Input() showMessage: boolean;
  @Input() public text: string;

  @ViewChild('toolbarTemplate', { static: false }) public toolbarTemplate: TemplateRef<any>;
  @ViewChild('toolbarElement', { static: false }) public toolbarElement: ElementRef;

  public get outerWidth(): number {
    if (this.toolbarElement) {
      return outerWidth(this.toolbarElement.nativeElement);
    }
  }
}
