import { Component, Inject, OnInit } from '@angular/core';
import { process, State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { AuthenticationService } from '../../../services';
import { CndListService } from './cnd-list.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-open-scenario',
  templateUrl: './open-scenario.component.html',
  styleUrls: ['./open-scenario.component.css'],
})
export class OpenScenarioComponent implements OnInit {
  public multiple = false;
  public allowUnsort = true;
  public openedDelete: boolean;
  public scenarioSelected: any;
  public hiddenAdmin: boolean;
  public authBack = '';
  public canEditCnd: boolean = false;
  public isAdmin: boolean = false;
  public cndListService: CndListService;
  public sort: SortDescriptor[] = [];
  public gridData: Observable<GridDataResult>;
  public state: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(CndListService) cndListServiceFactory: any    
  ) {
    this.cndListService = cndListServiceFactory();
  }

  public ngOnInit(): void {
    this.isAdmin = this.permissionsProvider.isAdmin;
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.authenticationService.isAdmin$.subscribe(value => {
      if (value) {
        this.hiddenAdmin = false;
      } else {
        this.hiddenAdmin = true;
      }
    });

    this.gridData = 
    this.cndListService
    .pipe(map(data => {
      return process(orderBy(data, this.sort), this.state);      
    }),catchError(error => {
      this.notificationService.show({
        content: 'Error opening all Scenarios',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 700 },
        type: { style: 'info', icon: true },
      });
      this.cndListService.isLoading = false;
      return throwError(error);
    }));
    this.cndListService.read();
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.isActiveForOutlook.toLowerCase() === 'yes') {
      return { outlook: true };
    }
  };
  public onDataStateChange(state: any): void {
    this.state = state;
    this.cndListService.read();
  }

  public openHandler({ dataItem }) {
    if (dataItem) {
      this.router.navigate(['Scenario/id', dataItem.id]);
    }
  }

  public closeDelete(status) {
    this.openedDelete = false;
  }

  public openDelete(dataItem: any) {
    this.openedDelete = true;
    this.scenarioSelected = dataItem;
  }

  public removeHandler(dataItem: any) {
    this.openedDelete = false;
    this.cndListService.remove(this.scenarioSelected);
    this.cndListService.saveChanges();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.cndListService.read();
  }
}
