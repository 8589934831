import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IStatusIndicator } from '../../../api/status-indicator';
import { Globals } from '../../../_shared/Globals';
import { StatusIndicatorService } from '../../../services/status-indicator.service';
import { PerformanceBodDetailService } from '../performance-bod-detail/performance-bod-detail.service';
import { IPerformanceBodStatus } from 'src/app/api/performance-bod-status';
import { PerformanceBodStatusService } from './../services/performance-bod-status.service';
@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.css'],
})
export class StatusIndicatorComponent implements OnInit {
  public currentStatus: string;
  public hiddenReOpenButton = true;
  public hiddenCloseButton = true;
  public openDialog = false;
  private bodStatus: IPerformanceBodStatus[] = [];
  private performanceBodId: string;

  private performanceBodStatus:any;
  constructor(
    private route: ActivatedRoute,
    private statusIndicatorService: StatusIndicatorService,
    public globals: Globals,
    private performanceBodDetailService: PerformanceBodDetailService,
    private performanceBodStatusService: PerformanceBodStatusService
  ) {}

  public ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.performanceBodId = params.get('id');
      this.performanceBodStatusService.initialize(this.performanceBodId);
    });

    this.performanceBodStatusService.performanceBodStatus$.subscribe((bodStatus: IPerformanceBodStatus[]) => {
      this.bodStatus = bodStatus;
      if (this.bodStatus && this.bodStatus.length > 0) {
        this.statusIndicatorService.read();
      }
    });
    this.performanceBodDetailService.getPerformanceBodStatus().subscribe((status: IPerformanceBodStatus[]) => {
      this.performanceBodStatus = status.length > 0 ? status[0] : null;
    });

    this.statusIndicatorService.statusIndicator$.subscribe((statusIndicators: IStatusIndicator[]) => {
      if (statusIndicators.length > 0 && this.bodStatus.length > 0) {
        this.bodStatus = this.bodStatus.sort(
          (a, b) => new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime()
        );
        this.currentStatus = statusIndicators.find(s => s.id === this.bodStatus[0].statusIndicatorId).name;
      }
    });
  }
}
