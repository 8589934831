import { Component, OnInit, ViewChild, OnDestroy, Inject, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { FormCanDeactivate } from 'src/app/_guards/can-deactivate/form-candeactivate';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PerformanceBodDetailComponent } from '../performance-bod-detail/performance-bod-detail.component';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { StandardFacilitiesCentralTankBatteryComponent } from '../standard-facilities-central-tank-battery/standard-facilities-central-tank-battery.component';
import { InfrastructureWellPadComponent } from '../infrastructure-well-pad/infrastructure-well-pad.component';
import { InfrastructureFacilityPadsComponent } from '../infrastructure-facility-pads/infrastructure-facility-pads.component';
import { InfrastructurePipelinesComponent } from '../infrastructure-pipelines/infrastructure-pipelines.component';
import { InfrastructureWellArtificialLiftComponent } from '../infrastructure-well-artificial-lift/infrastructure-well-artificial-lift.component';
import { InfrastructureEdsIeComponent } from '../infrastructure-eds-ie/infrastructure-eds-ie.component';
import { InfrastructureFracPondComponent } from '../infrastructure-frac-pond/infrastructure-frac-pond.component';
import { InfrastructureSourceWaterComponent } from '../infrastructure-source-water/infrastructure-source-water.component';
import { InfrastructureRecycleComponent } from '../infrastructure-recycle/infrastructure-recycle.component';
import { InfrastructureWaterDisposalComponent } from '../infrastructure-water-disposal/infrastructure-water-disposal.component';
import { InfrastructureRegulatoryPermittingComponent } from '../infrastructure-regulatory-permitting/infrastructure-regulatory-permitting.component';
import { InfrastructureCommercialComponent } from '../infrastructure-commercial/infrastructure-commercial.component';
import { ScopeExistingFacilitiesComponent } from '../scope-existing-facilities/scope-existing-facilities.component';
import { ScopeNonStandardOutsideFactoryProjectsComponent } from '../scope-non-standard-outside-factory-projects/scope-non-standard-outside-factory-projects.component';
import { StandardFacilitiesCompressorComponent } from '../standard-facilities-compressor/standard-facilities-compressor.component';
import { StandardFacilitiesSaltWaterDisposalComponent } from '../standard-facilities-salt-water-disposal/standard-facilities-salt-water-disposal.component';
import { StandardFacilitiesSummaryComponent } from '../standard-facilities-summary/standard-facilities-summary.component';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SummaryPackageInformationComponent } from '../summary-package-information/summary-package-information.component';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { SummaryNotesComponent } from '../summary-notes/summary-notes.component';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { IPerformanceBodInput } from 'src/app/api/performance-bod-input';
import { UserPermissionsService } from 'src/app/services/user-permission.service';
import { IUsrPermissions } from 'src/app/api/user-permission';
import { IDevelopmentArea } from 'src/app/api/devArea';
import { Basin } from 'src/app/api/basin';
import { GeographicArea } from 'src/app/api/geographicArea';
import { UtilService } from 'src/app/services/util.service';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';
import { IPerformanceBodSignatures } from 'src/app/api/performance-bod-signatures';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { DatePipe } from '@angular/common';
import { CataloguesService } from 'src/app/services/catalogues.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { SummaryPeakProductionRatesComponent } from '../summary-peak-production-rates/summary-peak-production-rates.component';
import { WaterScopeDrillingSupplyComponent } from '../water-scope-drilling-supply/water-scope-drilling-supply.component';
import { WaterScopeFracSupplyComponent } from '../water-scope-frac-supply/water-scope-frac-supply.component';
import { WaterScopeWaterDisposalComponent } from '../water-scope-water-disposal/water-scope-water-disposal.component';
import { InfrastructureRoadMaintenanceComponent } from '../infrastructure-road/infrastructure-road-maintenance.component';
import { PerformanceAfeComponent } from '../performance-afe/performance-afe.component';

import { PerformanceConfigurationService } from '../../../services/performance-configuration-service';
import { Globals } from '../../../_shared/Globals';
//import { IPerformanceBodStatus } from '../models/performance-bod-status';
import { AuthenticationService } from 'src/app/services';
import { PerformanceBodStatusService } from './../services/performance-bod-status.service';
import { IPerformanceBodStatus } from 'src/app/api/performance-bod-status';
import { IStatusIndicator } from 'src/app/api/status-indicator';
interface IUser {
  cai: string;
  fullname: string;
}

@Component({
  selector: 'app-performance-bod-tool',
  styleUrls: ['./performance-bod-tool.component.css'],
  templateUrl: './performance-bod-tool.component.html',
})
export class PerformanceBodToolComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  public countNotification: number;
  public warningMessage: string =
    'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
  public openDialog: boolean = false;
  public position = 'top';
  public performanceBodName = 'Performance BOD Name';
  public performanceBodPadsList: IPerformanceBodPads[];
  public countWellPadName: number = 0;
  public usersCnD: IUsrPermissions[];
  public users: IUser[] = [];
  public systemCAIs: string[] = [];
  public data: string[];
  public developmentAreaOptions: IDevelopmentArea[];
  public basinOptions: Basin[];
  public geographicAreaOptions: GeographicArea[];
  public performanceBodSignaturesData: IPerformanceBodSignatures[];
  public canEditPerformanceBod: boolean = false;
  public isSignatured:boolean=false;
  public performanceBodStatus: IPerformanceBodStatus;
  public statusIndicators: IStatusIndicator[];

  @ViewChild('form', { static: true }) public form: NgForm;
  @ViewChild('tabStrip', { static: true }) public tabStrip: TabStripComponent;

  // Standard Facilities Tab
  @ViewChild(StandardFacilitiesCentralTankBatteryComponent, { static: false })
  public standardFacilitiesCentralTankBatteryComponent: StandardFacilitiesCentralTankBatteryComponent;
  @ViewChild(StandardFacilitiesCompressorComponent, { static: false })
  public standardFacilitiesCompressorComponent: StandardFacilitiesCompressorComponent;
  @ViewChild(StandardFacilitiesSaltWaterDisposalComponent, { static: false })
  public standardFacilitiesSaltWaterDisposalComponent: StandardFacilitiesSaltWaterDisposalComponent;
  @ViewChild(StandardFacilitiesSummaryComponent, { static: false })
  public standardFacilitiesSummaryComponent: StandardFacilitiesSummaryComponent;

  @ViewChild(PerformanceBodDetailComponent, { static: false })
  private performanceBodDetailComponent: PerformanceBodDetailComponent;
  @ViewChild(SummaryNotesComponent, { static: false })
  private summaryNotesComponent: SummaryNotesComponent;
  @ViewChild(SummaryPackageInformationComponent, { static: false })
  private packageInformationComponent: SummaryPackageInformationComponent;
  @ViewChild(SummaryPeakProductionRatesComponent, { static: false })
  private summaryPeakProductionRatesComponent: SummaryPeakProductionRatesComponent;

  // Infrastructure tab
  @ViewChild(InfrastructureWellPadComponent, { static: false })
  private infrastructureWellPadComponent: InfrastructureWellPadComponent;
  @ViewChild(InfrastructureFacilityPadsComponent, { static: false })
  private infrastructureFacilityPadsComponent: InfrastructureFacilityPadsComponent;
  @ViewChild(InfrastructurePipelinesComponent, { static: false })
  private infrastructurePipelinesComponent: InfrastructurePipelinesComponent;
  @ViewChild(InfrastructureWellArtificialLiftComponent, { static: false })
  private infrastructureWellArtificialLiftComponent: InfrastructureWellArtificialLiftComponent;
  @ViewChild(InfrastructureRoadMaintenanceComponent, { static: false })
  private infrastructureRoadMaintenanceComponent: InfrastructureRoadMaintenanceComponent;
  @ViewChild(InfrastructureEdsIeComponent, { static: false })
  private infrastructureEdsIeComponent: InfrastructureEdsIeComponent;
  @ViewChild(InfrastructureFracPondComponent, { static: false })
  private infrastructureFracPondComponent: InfrastructureFracPondComponent;
  @ViewChild(InfrastructureSourceWaterComponent, { static: false })
  private infrastructureSourceWaterComponent: InfrastructureSourceWaterComponent;
  @ViewChild(InfrastructureRecycleComponent, { static: false })
  private infrastructureRecycleComponent: InfrastructureRecycleComponent;
  @ViewChild(InfrastructureWaterDisposalComponent, { static: false })
  private infrastructureWaterDisposalComponent: InfrastructureWaterDisposalComponent;
  @ViewChild(InfrastructureRegulatoryPermittingComponent, { static: false })
  private infrastructureRegulatoryPermittingComponent: InfrastructureRegulatoryPermittingComponent;
  @ViewChild(InfrastructureCommercialComponent, { static: false })
  private infrastructureCommercialComponent: InfrastructureCommercialComponent;
  @ViewChild(PerformanceAfeComponent) performanceAfeComponent: PerformanceAfeComponent;

  private selectedTab = 0;
  private currentPerformanceBod: IPerformanceBod;

  // P&E Scope tab
  @ViewChild(ScopeExistingFacilitiesComponent, { static: false })
  private scopeExistingFacilitiesComponent: ScopeExistingFacilitiesComponent;
  @ViewChild(ScopeNonStandardOutsideFactoryProjectsComponent, { static: false })
  private scopeNonStandardOutsideFactoryProjectsComponent: ScopeNonStandardOutsideFactoryProjectsComponent;

  //Water Scope tab
  @ViewChild(WaterScopeDrillingSupplyComponent, { static: false })
  private waterScopeDrillingSupplyComponent: WaterScopeDrillingSupplyComponent;
  @ViewChild(WaterScopeFracSupplyComponent, { static: false })
  private waterScopeFracSupplyComponent: WaterScopeFracSupplyComponent;
  @ViewChild(WaterScopeWaterDisposalComponent, { static: false })
  private waterScopeWaterDisposalComponent: WaterScopeWaterDisposalComponent;
  private userPermissionsService: UserPermissionsService;
  private saveCounter = 0;
  private emisionCounter = 0;
  private allSaveChecked = false;
  private performanceBodId: string;
  public isDisableForCloseBod = true;
  public isDisableForReOpenBod = true;

  constructor(
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    private route: ActivatedRoute,
    private router: Router,
    public performanceBodStoreService: PerformanceBodStoreService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private performanceBodDataService: PerformanceBodDataService,
    private cataloguesService: CataloguesService,
    private utilService: UtilService,
    private scenarioStoreService: ScenarioStoreService,
    private permissionsProvider: PermissionsProvider,
    private datePipe: DatePipe,
    private performanceBodCalculationsService: PerformanceBodCalculationsService,
    private performanceConfigurationService: PerformanceConfigurationService,
    private authenticationService: AuthenticationService,
    public globals: Globals,
    private performanceBodStatusService: PerformanceBodStatusService,
  ) {
    super();
    this.userPermissionsService = userPermissionsServiceFactory();
  }

  public ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.performanceBodId = params.get('id');
    });
    // this.route.paramMap.pipe(switchMap((params: ParamMap) => {});
    //this.canEditPerformanceBod = this.permissionsProvider.canEditPerformanceBod;

    // Clear calculations
    this.performanceBodCalculationsService.clearCalculations();
    this.userPermissionsService.getAllUsers().subscribe(data => {
      this.usersCnD = data;
      for (const entry of this.usersCnD) {
        this.systemCAIs.push(entry.fullName);
        this.users.push({
          cai: entry.name,
          fullname: entry.fullName,
        });
      }
      this.systemCAIs = this.systemCAIs.filter((el, i, a) => i === a.indexOf(el));
      this.systemCAIs.sort((a,b)=> a.localeCompare(b));
      this.data = this.systemCAIs.slice();
    });

    this.spinner.show();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.performanceBodStoreService.open(params.get('id'));
    });
    this.performanceBodStoreService.refreshed.subscribe(
      (done: boolean) => {
        if (done) {
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.spinner.hide();
      }
    );

    this.performanceBodStoreService.performanceBod$.subscribe(performanceBod => {
      this.currentPerformanceBod = performanceBod;
    });

    this.performanceBodStoreService.performanceBodSignaturesData$.subscribe(data => {
      this.performanceBodSignaturesData = data;
    });

    this.scenarioStoreService.developmentAreaOptions$.subscribe(data => {
      this.developmentAreaOptions = data;
    });
    this.scenarioStoreService.basinOptions$.subscribe(data => {
      this.basinOptions = data;
    });
    this.scenarioStoreService.geographicArea$.subscribe(data => {
      this.geographicAreaOptions = data;
    });

    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });

    this.performanceBodStoreService.statusIndicator$.subscribe((data: IStatusIndicator[]) => {
      this.statusIndicators = data;
    });

    this.performanceBodStoreService.performanceBodStatus$.subscribe(status => {
      if (status) {
        this.performanceBodStatus = status.length > 0 ? status[0] : null;
      }
    });
    
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.isSignatured = isSignature;
      this.isDisableForCloseBod = !this.canCloseBod();
      this.isDisableForReOpenBod = !this.canReOpenBod();
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });

    // Load catalogues used at Performnace Bod Pipelines grid
    this.cataloguesService.load();
  }

  public nameEventHandler($event: any) {
    this.performanceBodName = $event;
  }

  public ngOnDestroy() {
    this.spinner.hide();
    this.performanceBodStoreService.cleanPeformanceBodObservables();
  }

  public onTabSelect(e: any) {
    this.selectedTab = e.index;
    if (e.index == 1) {
      this.standardFacilitiesCentralTankBatteryComponent.getAfeList();
      this.standardFacilitiesCompressorComponent.getAfeList();
      this.standardFacilitiesSaltWaterDisposalComponent.getAfeList();
    }
  }

  public onPerformanceBodUpdate(cai: string): void {
    const updated = new Date();
    // Save last updated
    this.currentPerformanceBod.updatedBy = cai;
    this.currentPerformanceBod.updatedDate = updated;
    this.performanceBodStoreService.update(this.currentPerformanceBod);

    // Refresh Bod detail section
    if (this.performanceBodDetailComponent) {
      this.performanceBodDetailComponent.setLastUpdate(cai, updated);
    }
  }

  public onPerformanceBodSign(cai: string): void {
    const updated = new Date();
    // Save last updated
    this.currentPerformanceBod.updatedBy = cai;
    this.currentPerformanceBod.updatedDate = updated;
    this.performanceBodStoreService.update(this.currentPerformanceBod);

    // Refresh Bod detail section
    if (this.performanceBodDetailComponent) {
      this.performanceBodDetailComponent.setLastUpdate(cai, updated);
    }

    this.saveAll();
  }

  public onPackageInformationUpdate(update: boolean): void {
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPadsList = data.filter(row => row.wellPadName !== '');
    });
    if (update) {
      if (this.infrastructurePipelinesComponent && this.infrastructureWellPadComponent) {
        this.infrastructurePipelinesComponent.infrastructurePipelinesService.cancelChanges();
        this.infrastructurePipelinesComponent.saveFromBOD();
        // Invoke refresh() to load WellPad Grid details
        this.infrastructureWellPadComponent.performanceBodWellPadService.refresh();
      }
    }
  }

  public saveAllGrids(): void {
    this.saveCounter = 0;
    this.allSaveChecked = false;

    this.spinner.show();

    // Summary Detail
    if (this.performanceBodDetailComponent !== undefined || this.performanceBodDetailComponent != null) {
      // Always is ready to save
      this.saveCounter++;
      const result = this.performanceBodDetailComponent.saveFromBOD();
    }

    // Note & attachments
    if (this.summaryNotesComponent && this.summaryNotesComponent.summaryNotesEditService.hasChanges()) {
      this.saveCounter++;
      this.summaryNotesComponent.saveFromBOD();
    }

    //Performance Bod AFE
    if(this.performanceAfeComponent && this.performanceAfeComponent.perfAfeSvc.hasChanges()){
      this.saveCounter++;
      this.performanceAfeComponent.saveFromBOD();
    }

    this.countNotification = 0;
    // Standard Facilities Central Tank Battery
    if (
      this.standardFacilitiesCentralTankBatteryComponent !== undefined &&
      this.standardFacilitiesCentralTankBatteryComponent != null &&
      this.standardFacilitiesCentralTankBatteryComponent.performanceBodCentralTankBatteryService.hasChanges()
    ) {
      this.saveCounter++;
      this.countNotification = this.standardFacilitiesCentralTankBatteryComponent.saveFromBOD(this.countNotification);
    }
    // Standard Facilities Compressor Station
    if (
      this.standardFacilitiesCompressorComponent !== undefined &&
      this.standardFacilitiesCompressorComponent != null &&
      this.standardFacilitiesCompressorComponent.performanceBodPerformanceBodCompressorService.hasChanges()
    ) {
      this.saveCounter++;
      this.countNotification = this.standardFacilitiesCompressorComponent.saveFromBOD(this.countNotification);
    }
    // Standard Facilities Salt Water Disposal
    if (
      this.standardFacilitiesSaltWaterDisposalComponent !== undefined &&
      this.standardFacilitiesSaltWaterDisposalComponent != null &&
      this.standardFacilitiesSaltWaterDisposalComponent.performanceBodSaltWaterDisposalService.hasChanges()
    ) {
      this.saveCounter++;
      this.countNotification = this.standardFacilitiesSaltWaterDisposalComponent.saveFromBOD(this.countNotification);
    }

    // Standard facilities Summary
    if (
      this.standardFacilitiesSummaryComponent !== undefined &&
      this.standardFacilitiesSummaryComponent != null &&
      this.standardFacilitiesSummaryComponent.standardFacilitiesSummaryService.hasChanges()
    ) {
      this.saveCounter++;
      this.standardFacilitiesSummaryComponent.saveFromBOD();
    }

    // Infrastructure Well Pad
    if (
      this.infrastructureWellPadComponent !== undefined &&
      this.infrastructureWellPadComponent != null &&
      this.infrastructureWellPadComponent.performanceBodWellPadService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureWellPadComponent.saveFromBOD();
    }

    // Infrastructure Facility Pads
    if (
      this.infrastructureFacilityPadsComponent !== undefined &&
      this.infrastructureFacilityPadsComponent != null &&
      this.infrastructureFacilityPadsComponent.infrastructureFacilityPadsService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureFacilityPadsComponent.saveFromBOD();
    }

    // Infrastructure Pipelines
    if (
      this.infrastructurePipelinesComponent !== undefined &&
      this.infrastructurePipelinesComponent != null &&
      this.infrastructurePipelinesComponent.infrastructurePipelinesService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructurePipelinesComponent.saveFromBOD();
    }

    // Infrastructure Well Artificial
    if (
      this.infrastructureWellArtificialLiftComponent !== undefined &&
      this.infrastructureWellArtificialLiftComponent != null &&
      this.infrastructureWellArtificialLiftComponent.infrastructureWellArtificialLiftService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureWellArtificialLiftComponent.saveFromBOD();
    }

    // Infrastructure EdsIe
    if (
      this.infrastructureEdsIeComponent !== undefined &&
      this.infrastructureEdsIeComponent != null &&
      this.infrastructureEdsIeComponent.performanceBodEdsIeService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureEdsIeComponent.saveFromBOD();
    }

    // Infrastructure FracPond
    if (
      this.infrastructureFracPondComponent !== undefined &&
      this.infrastructureFracPondComponent != null &&
      this.infrastructureFracPondComponent.performanceBodFracPondService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureFracPondComponent.saveFromBOD();
    }

    // Infrastructure SourceWater
    if (
      this.infrastructureSourceWaterComponent !== undefined &&
      this.infrastructureSourceWaterComponent != null &&
      this.infrastructureSourceWaterComponent.infrastructureSourceWaterService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureSourceWaterComponent.saveFromBOD();
    }

    // Infrastructure Recycle
    if (
      this.infrastructureRecycleComponent !== undefined &&
      this.infrastructureRecycleComponent != null &&
      this.infrastructureRecycleComponent.infrastructureRecycleService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureRecycleComponent.saveFromBOD();
    }

    // Infrastructure WaterDisposal
    if (
      this.infrastructureWaterDisposalComponent !== undefined &&
      this.infrastructureWaterDisposalComponent != null &&
      this.infrastructureWaterDisposalComponent.performanceBodWaterDisposalService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureWaterDisposalComponent.saveFromBOD();
    }

    // Infrastructure RegulatoryPermitting
    if (
      this.infrastructureRegulatoryPermittingComponent !== undefined &&
      this.infrastructureRegulatoryPermittingComponent != null &&
      this.infrastructureRegulatoryPermittingComponent.infrastructureRegulatoryPermittingService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureRegulatoryPermittingComponent.saveFromBOD();
    }

    // Infrastructure Commercial
    if (
      (this.infrastructureCommercialComponent !== undefined || this.infrastructureCommercialComponent != null) &&
      this.infrastructureCommercialComponent.infrastructureCommercialService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureCommercialComponent.saveFromBOD();
    }

    // Infrastructure Road Maintenance
    if (
      this.infrastructureRoadMaintenanceComponent !== undefined &&
      this.infrastructureRoadMaintenanceComponent != null &&
      this.infrastructureRoadMaintenanceComponent.infrastructureRoadMaintenanceService.hasChanges()
    ) {
      this.saveCounter++;
      this.infrastructureRoadMaintenanceComponent.saveFromBOD();
    }

    // P&E Scope Existing Facilities
    if (
      this.scopeExistingFacilitiesComponent !== undefined &&
      this.scopeExistingFacilitiesComponent != null &&
      this.scopeExistingFacilitiesComponent.scopeExistingFacilitiesEditService.hasChanges()
    ) {
      this.saveCounter++;
      this.scopeExistingFacilitiesComponent.saveFromBOD();
    }
    // P&E Scope Non Standard Outside Factory Projects
    if (
      this.scopeNonStandardOutsideFactoryProjectsComponent !== undefined &&
      this.scopeNonStandardOutsideFactoryProjectsComponent != null &&
      this.scopeNonStandardOutsideFactoryProjectsComponent.scopeNonStandardOutsideFactoryProjectsService.hasChanges()
    ) {
      this.saveCounter++;
      this.scopeNonStandardOutsideFactoryProjectsComponent.saveFromBOD();
    }

    // Peak production rates
    if (
      (this.summaryPeakProductionRatesComponent !== undefined || this.summaryPeakProductionRatesComponent != null) &&
      this.summaryPeakProductionRatesComponent.summaryPeakProductionRatesEditService.hasChanges()
    ) {
      this.saveCounter++;
      this.summaryPeakProductionRatesComponent.saveFromBOD();
    }

    // Drilling supply
    if (
      (this.waterScopeDrillingSupplyComponent !== undefined || this.waterScopeDrillingSupplyComponent != null) &&
      this.waterScopeDrillingSupplyComponent.performanceBodWSDrillingSupplyService.hasChanges()
    ) {
      this.saveCounter++;
      this.waterScopeDrillingSupplyComponent.saveFromBOD();
    }

    // Frac supply
    if (
      (this.waterScopeFracSupplyComponent !== undefined || this.waterScopeFracSupplyComponent != null) &&
      this.waterScopeFracSupplyComponent.performanceBodWSFracSupplyService.hasChanges()
    ) {
      this.saveCounter++;
      this.waterScopeFracSupplyComponent.saveFromBOD();
    }

    // Water Disposal
    if (
      (this.waterScopeWaterDisposalComponent !== undefined || this.waterScopeWaterDisposalComponent != null) &&
      this.waterScopeWaterDisposalComponent.performanceBodWSWaterDisposalService.hasChanges()
    ) {
      this.saveCounter++;
      this.waterScopeWaterDisposalComponent.saveFromBOD();
    }

    this.allSaveChecked = true;
    if (this.emisionCounter === this.saveCounter) {
      this.emisionCounter--;
      this.onSaveBodCompleted(true);
    }
  }

  public onSaveBodCompleted(successfulSave: boolean): void {
    this.emisionCounter++;
    if (this.emisionCounter === this.saveCounter && this.allSaveChecked) {
      this.saveCounter = 0;
      this.emisionCounter = 0;
      if (successfulSave) {
        this.spinner.hide();

        if (successfulSave) {
          this.notificationService.show({
            content: 'Performance BOD sucessfully saved',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 1000 },
            type: { style: 'success', icon: true },
            hideAfter: 700,
          });
        }
      } else {
        this.spinner.hide();
      }
    }
  }

  public saveAll(): void {
    if (this.performanceBodPadsList) {
      for (const bodPads of this.performanceBodPadsList) {
        if (bodPads.wellPadName === '') {
          this.countWellPadName = this.countWellPadName + 1;
        }
      }
      if (this.countWellPadName === this.performanceBodPadsList.length) {
        this.openDialog = true;
        this.countWellPadName = 0;

        if (this.packageInformationComponent.summaryPackageInformationEditService.hasChanges()) {
          this.warningMessage =
            'Please complete the remaining fields under Package Information and Save before proceeding';
          this.openDialog = true;
          return;
        }
        return;
      }

      if (this.packageInformationComponent.summaryPackageInformationEditService.hasChanges()) {
        this.warningMessage =
          'Please complete the remaining fields under Package Information and Save before proceeding';
        this.openDialog = true;
        return;
      } else {
        this.warningMessage =
          'Please provide a Well Pad Name on the Package Information Section (Performance BOD Summary Tab) before adding a new record';
        this.openDialog = false;
      }
      this.saveAllGrids();
    }
  }

  public closeKendoPopUp() {
    this.openDialog = false;
  }

  public exportToExcel() {
    const objdatetime = new Date();
    const timezone = objdatetime.toTimeString();
    const tzstr = timezone.split('(');
    const timezoneid = tzstr[1].toString().replace(')', '');

    const pbodInfo: IPerformanceBodInput = this.getPBODInput();
    pbodInfo.timeZoneOffSetName = timezoneid;
    const signatureInput: IPerformanceBodSignatures = this.getSignatureInput();
    const PMSignature = this.getInfoUser(signatureInput.performanceProjectManagerCai);
    const TLSSignature = this.getInfoUser(signatureInput.performanceTeamLeadCai);
    const EngineerSignature = this.getInfoUser(signatureInput.performanceReservoirEngineerCai);
    const WaterSignature = this.getInfoUser(signatureInput.performanceWaterEngineerCai);

    pbodInfo.performancePMSignature = PMSignature
      ? PMSignature +
        ' - Signed on ' +
        this.datePipe.transform(signatureInput.performanceProjectManagerDate, 'MM/dd/yyyy')
      : '';
    pbodInfo.performanceTLSignature = TLSSignature
      ? TLSSignature + ' - Signed on ' + this.datePipe.transform(signatureInput.performanceTeamLeadDate, 'MM/dd/yyyy')
      : '';
    pbodInfo.reservoirEngineerSignature = EngineerSignature
      ? EngineerSignature +
        ' - Signed on ' +
        this.datePipe.transform(signatureInput.performanceReservoirEngineerDate, 'MM/dd/yyyy')
      : '';
    pbodInfo.waterEngineerSignature = WaterSignature
      ? WaterSignature +
        ' - Signed on ' +
        this.datePipe.transform(signatureInput.performanceWaterEngineerDate, 'MM/dd/yyyy')
      : '';

    this.notificationService.show({
      content: ' Export to Excel may take a few minutes...please wait.',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 800 },
      type: { style: 'info', icon: true },
      hideAfter: 7000,
    });

    this.spinner.show();

    this.performanceBodDataService.downloadPerformanceBodExcelFile(pbodInfo).subscribe(
      data => {
        this.spinner.hide();

        if (data) {
          this.notificationService.show({
            content: ' Downloading the Excel File...',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 800 },
            type: { style: 'success', icon: true },
          });

          if (data) {
            // Second implementation using and html a element
            const e = document.createElement('a');
            e.href = window.URL.createObjectURL(data);
            e.download = pbodInfo.performanceBODName + '.xlsx';
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
          }
        }
      },
      error => {
        this.notificationService.show({
          content: 'There was an issue generating Export Data document. Please try again later.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'warning', icon: true },
        });
      }
    );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  public getInfoUser(cai: string): string {
    if (cai !== null && cai !== undefined) {
      const user = this.users.find(c =>
        c.cai !== null && c.cai !== undefined ? c.cai.toLowerCase() === cai.toLowerCase() : cai.toLowerCase() === ''
      );
      const showUser = user === undefined || user === null ? cai : user.fullname;
      return showUser;
    } else {
      return '';
    }
  }

  public getBasinArea(pid: string): string {
    if (pid !== null && pid !== undefined) {
      const obj = this.basinOptions.find(o => o.id === pid);
      return obj === undefined || obj === null ? '' : obj.name;
    } else {
      return '';
    }
  }

  public getDevelopmentArea(pid: string): string {
    if (pid !== null && pid !== undefined) {
      const obj = this.developmentAreaOptions.find(o => o.id === pid);
      return obj === undefined || obj === null ? '' : obj.name;
    } else {
      return '';
    }
  }

  public getGeographicArea(pid: string): string {
    if (pid !== null && pid !== undefined) {
      const obj = this.geographicAreaOptions.find(o => o.id === pid);
      return obj === undefined || obj === null ? '' : obj.name;
    } else {
      return '';
    }
  }

  public getPBODInput(): IPerformanceBodInput {
    if (this.currentPerformanceBod !== null && this.currentPerformanceBod !== undefined) {
      return {
        basinArea: this.getBasinArea(this.currentPerformanceBod.basinId),
        developmentArea: this.getDevelopmentArea(this.currentPerformanceBod.developmentAreaId),
        geographicArea: this.getGeographicArea(this.currentPerformanceBod.geographicAreaId),
        id: this.currentPerformanceBod.id,
        performanceProjectManager: this.getInfoUser(this.currentPerformanceBod.performanceProjectManagerCai),
        performanceTeamLead: this.getInfoUser(this.currentPerformanceBod.performanceTeamLeadCai),
        performanceTLSignature: '',
        performancePMSignature: '',
        reservoirEngineer: this.getInfoUser(this.currentPerformanceBod.performanceReservoirEngineerCai),
        reservoirEngineerSignature: '',
        waterEngineer: this.getInfoUser(this.currentPerformanceBod.performanceWaterEngineerCai),
        waterEngineerSignature: '',
        createdByName: this.getInfoUser(this.currentPerformanceBod.createdBy),
        updatedByName: this.getInfoUser(this.currentPerformanceBod.updatedBy),
        performanceBODName: this.utilService.getNoSpecialCharacters(this.currentPerformanceBod.name),
      };
    } else {
      return {
        basinArea: '',
        developmentArea: '',
        geographicArea: '',
        id: '',
        performanceProjectManager: '',
        performanceTeamLead: '',
        performanceTLSignature: '',
        performancePMSignature: '',
        reservoirEngineer: '',
        waterEngineer: '',
        reservoirEngineerSignature: '',
        waterEngineerSignature: '',
        createdByName: '',
        updatedByName: '',
        performanceBODName: '',
      };
    }
  }

  public getSignatureInput(): IPerformanceBodSignatures {
    if (
      this.performanceBodSignaturesData !== null &&
      this.performanceBodSignaturesData !== undefined &&
      this.performanceBodSignaturesData.length !== 0
    ) {
      return this.performanceBodSignaturesData[0];
    } else {
      return {
        id: '',
        performanceBodId: '',
        performanceProjectManagerSignature: '',
        performanceProjectManagerCai: '',
        performanceTeamLeadSignature: '',
        performanceTeamLeadCai: '',
        performanceReservoirEngineerSignature: '',
        performanceReservoirEngineerCai: '',
        performanceProjectManagerDate: null,
        performanceTeamLeadDate: null,
        performanceReservoirEngineerDate: null,
        performanceWaterEngineerCai: '',
        performanceWaterEngineerSignature: '',
        performanceWaterEngineerDate: null,
        isDeleted: false,
        createdBy: '',
        createdDate: null,
        updatedBy: '',
        updatedDate: null,
      };
    }
  }

  public onSpudDateUpdate(spudDatePadUpdated: any): void {
    this.performanceBodCalculationsService.spudDateChange([spudDatePadUpdated]);
    // Infrastructure Well Pad
    if (this.infrastructureWellPadComponent !== undefined && this.infrastructureWellPadComponent != null) {
      this.infrastructureWellPadComponent.performanceBodWellPadService.applyLogicFromPads();
    }
  }

  public onPopDateUpdate(popDatePadUpdated: any): void {
    this.performanceBodCalculationsService.popDateChange([popDatePadUpdated]);
    // Infrastructure Facility Pad
    if (this.infrastructureFacilityPadsComponent !== undefined && this.infrastructureFacilityPadsComponent != null) {
      this.infrastructureFacilityPadsComponent.infrastructureFacilityPadsService.applyLogicFromPads();
    }

    // Infrastructure Pipelines
    if (this.infrastructurePipelinesComponent !== undefined && this.infrastructurePipelinesComponent != null) {
      this.infrastructurePipelinesComponent.infrastructurePipelinesService.applyLogicFromPads();
    }

    // Commercial
    if (this.infrastructureCommercialComponent !== undefined && this.infrastructureCommercialComponent != null) {
      this.infrastructureCommercialComponent.infrastructureCommercialService.applyLogicFromPads();
    }

    // Standard Facilities Summary
    if (this.standardFacilitiesSummaryComponent !== undefined && this.standardFacilitiesSummaryComponent != null) {
      if (
        this.standardFacilitiesCompressorComponent !== undefined &&
        this.standardFacilitiesCompressorComponent != null
      ) {
        if (
          this.standardFacilitiesCentralTankBatteryComponent !== undefined &&
          this.standardFacilitiesCentralTankBatteryComponent != null
        ) {
          if (
            this.standardFacilitiesSaltWaterDisposalComponent !== undefined &&
            this.standardFacilitiesSaltWaterDisposalComponent != null
          ) {
            this.standardFacilitiesSummaryComponent.standardFacilitiesSummaryService.applyLogicFromPads(
              this.standardFacilitiesCompressorComponent.performanceBodPerformanceBodCompressorService.data,
              this.standardFacilitiesCentralTankBatteryComponent.performanceBodCentralTankBatteryService.data,
              this.standardFacilitiesSaltWaterDisposalComponent.performanceBodSaltWaterDisposalService.data
            );
          }
        }
      }
    }

    // Regulatory Permitting
    if (
      this.infrastructureRegulatoryPermittingComponent !== undefined &&
      this.infrastructureRegulatoryPermittingComponent != null
    ) {
      this.infrastructureRegulatoryPermittingComponent.infrastructureRegulatoryPermittingService.applyLogicFromPads();
    }

    // Existing Facilties
    if (this.scopeExistingFacilitiesComponent !== undefined && this.scopeExistingFacilitiesComponent != null) {
      this.scopeExistingFacilitiesComponent.scopeExistingFacilitiesEditService.applyLogicFromPads();
    }
  }

  public onNumberOfWellsUpdate(numberofWellsUpdated: any): void {
    this.performanceBodCalculationsService.numberOfWellsChange([numberofWellsUpdated]);
    // Infrastructure number of wells
    if (this.infrastructureWellPadComponent !== undefined && this.infrastructureWellPadComponent != null) {
      this.infrastructureWellPadComponent.performanceBodWellPadService.applyLogicFromPads2();
    }
  }

  public onWellCountsUpdate(wellCountsUpdated: any): void {
    this.performanceBodCalculationsService.wellCountsChange([wellCountsUpdated]);
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.packageInformationComponent.summaryPackageInformationEditService.applyLogicFromWellPads();
    }
  }

  public onWellPadCreate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.wellPadCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public onWellPadPermitsRequiredByUpdate(WellPadPermitsRequiredByUpdated: any): void {
    this.performanceBodCalculationsService.wellPadsPermitsRequiredByChange([WellPadPermitsRequiredByUpdated]);
    // Infrastructure number of wells
    if (
      this.infrastructureRegulatoryPermittingComponent !== undefined &&
      this.infrastructureRegulatoryPermittingComponent != null
    ) {
      this.infrastructureRegulatoryPermittingComponent.infrastructureRegulatoryPermittingService.applyLogicFromWellPads();
    }
  }

  public onFacilityPadCreate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.facilityPadCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public onPipelineCreate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.pipelinesCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public onCommercialCreate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.commercialCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public onStandardFacilityUpdate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.standardFacilityUpdateCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data,
        this.standardFacilitiesCompressorComponent.performanceBodPerformanceBodCompressorService.data,
        this.standardFacilitiesCentralTankBatteryComponent.performanceBodCentralTankBatteryService.data,
        this.standardFacilitiesSaltWaterDisposalComponent.performanceBodSaltWaterDisposalService.data
      );
    }
  }

  public onExistinFacilityCreate(newItem: any): void {
    // Infrastructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.existingFacilityCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public onRegulatoryPermittingCreate(newItem: any): void {
    // Infraestructure number of wells
    if (this.packageInformationComponent !== undefined && this.packageInformationComponent != null) {
      this.performanceBodCalculationsService.regulatoryPermittingCreationCalculation(
        newItem,
        this.packageInformationComponent.summaryPackageInformationEditService.data
      );
    }
  }

  public lockClass(): string {
       return this.isSignatured == true ? 'k-icon k-i-lock lock-icon' : 'k-icon k-i-unlock lock-icon';
  }

  public closeBod(): void {
    const closeStatus = this.performanceConfigurationService.appConfigs.find(
      config => config.code === this.globals.APP_CONFIG_STATUS_INDICATOR_CLOSED_ID
    );
    this.performanceBodStatus.statusIndicatorId = this.statusIndicators.find(
      s => s.name === 'Closed'
    ).id;
    this.performanceBodStoreService.updatePerformanceBodStatusAsync([this.performanceBodStatus]).subscribe(
      () => {
        this.onPerformanceBodUpdate(this.authenticationService.getCurrentUser());
        this.spinner.hide();
        this.showNotification('The BOD have been closed.', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  }

  private showNotification(message: string, style?: any): void {
    this.notificationService.show({
      content: message,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 800 },
      type: { style, icon: true },
    });
  }

  public canCloseBod(): boolean {
    if (this.performanceBodStatus !=null) {
      if (this.performanceBodId) {
        if (this.performanceBodStatus != null) {
          const performancePM = this.currentPerformanceBod.performanceProjectManagerCai ? this.currentPerformanceBod.performanceProjectManagerCai: '';
          const performanceRE = this.currentPerformanceBod.performanceReservoirEngineerCai? this.currentPerformanceBod.performanceReservoirEngineerCai: '';
          const performanceTL = this.currentPerformanceBod.performanceTeamLeadCai ? this.currentPerformanceBod.performanceTeamLeadCai : '';
          const performanceWE = this.currentPerformanceBod.performanceWaterEngineerCai ? this.currentPerformanceBod.performanceWaterEngineerCai : '';
          if (performancePM.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
            performanceRE.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
            performanceTL.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
            performanceWE.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase()) {
            const isApproved = this.performanceConfigurationService.appConfigs.find(
              x => x.code === this.globals.APP_CONFIG_STATUS_INDICATOR_APPROVED_ID
            );
            return isApproved ? isApproved.attributeId === this.performanceBodStatus.statusIndicatorId : false;
          }
        }
      }
    }

    return false;
  }

  public canReOpenBod(): boolean {
    if (this.performanceBodStatus != null) {
      const closedStatus = this.performanceConfigurationService.appConfigs.find(
        x => x.code === this.globals.APP_CONFIG_STATUS_INDICATOR_CLOSED_ID
      );
      const isClosed = closedStatus ? closedStatus.attributeId === this.performanceBodStatus.statusIndicatorId : false;
      const performancePM = this.currentPerformanceBod.performanceProjectManagerCai? this.currentPerformanceBod.performanceProjectManagerCai: '';
      const performanceRE = this.currentPerformanceBod.performanceReservoirEngineerCai? this.currentPerformanceBod.performanceReservoirEngineerCai: '';
      const performanceTL = this.currentPerformanceBod.performanceTeamLeadCai ? this.currentPerformanceBod.performanceTeamLeadCai : '';
      const performanceWE = this.currentPerformanceBod.performanceWaterEngineerCai ? this.currentPerformanceBod.performanceWaterEngineerCai : '';
      const isSigner =
        performancePM.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
        performanceRE.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
        performanceTL.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase() ||
        performanceWE.toLowerCase() === this.authenticationService.getCurrentUser().toLowerCase();
      return !isClosed && isSigner && this.isSignatured==true;
    }
    return false;
  }

  public reOpenBod(): void {

    if (this.performanceBodSignaturesData) {
      if (this.performanceBodSignaturesData.length > 0) {

        const reopenStatus = this.performanceConfigurationService.appConfigs.find(
          config => config.code === this.globals.APP_CONFIG_STATUS_INDICATOR_REOPEN_ID
        );
        let signDate: Date;
        signDate = new Date();
        this.performanceBodSignaturesData[0].performanceProjectManagerCai = null;
        this.performanceBodSignaturesData[0].performanceProjectManagerDate = null;
        this.performanceBodSignaturesData[0].performanceReservoirEngineerCai = null;
        this.performanceBodSignaturesData[0].performanceReservoirEngineerDate = null;
        this.performanceBodSignaturesData[0].performanceTeamLeadCai = null;
        this.performanceBodSignaturesData[0].performanceTeamLeadDate = null;
        this.performanceBodSignaturesData[0].performanceWaterEngineerCai = null;
        this.performanceBodSignaturesData[0].performanceWaterEngineerDate = null;
        this.performanceBodSignaturesData[0].updatedDate = signDate;
        this.performanceBodSignaturesData[0].updatedBy = this.authenticationService.getCurrentUser();
        this.spinner.show();
        this.performanceBodStoreService.updatePerformanceBodSignaturesAsync(this.performanceBodSignaturesData).subscribe(
          () => {
            this.performanceBodStatus.statusIndicatorId = this.statusIndicators.find(
              s => s.name === 'Awaiting Signatures'
            ).id;
            this.performanceBodStoreService.updatePerformanceBodStatusAsync([this.performanceBodStatus]).subscribe(
              () => {
                this.onPerformanceBodUpdate(this.authenticationService.getCurrentUser());
                this.spinner.hide();
                this.showNotification('The BOD have been re-open.', 'success');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              },
              (error: any) => {
                this.spinner.hide();
              }
            );
          },
          (error: any) => {
            this.spinner.hide();
          }
        );

      }
    }
  }
}
