import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.css'],
})
export class ErrorListComponent implements OnInit {
  @Input('duplicatesFound') public duplicatesFound = '';
  @Input('blanksFound') public blanksFound = new Array();
  @Input('PerformanceBodId') public PerformanceBodID = '';
  @Input('duplicateError') public duplicateError = '';
  @Input('buttonText') public buttonText = 'Save Changes';
  @Input('disabled') public disabled = false;
  @Output('SavingData') public SavingData = new EventEmitter<any>();

  public messageDialogOpened = false;
  public duplicateArrayList: string[] = [];

  constructor() {
    // empty
  }

  public ngOnInit(): void {
    // empty
  }

  public open(duplicatesFound: string = this.duplicatesFound, blanksFound: any[] = this.blanksFound) {
    if ((duplicatesFound === '' || duplicatesFound === undefined) && (!blanksFound || blanksFound.length === 0)) {
      this.SavingData.emit(this.PerformanceBodID);
    } else {
      this.messageDialogOpened = true;
      this.duplicateArrayList = duplicatesFound !== '' ? duplicatesFound.split(' - ') : [];
    }
  }

  public close() {
    this.messageDialogOpened = false;
    this.duplicateArrayList = [];
  }
}
