<div class="scenario-path">
    <span class="scenario-path-span">New Scenario</span>
</div> 

<div id="scenario">
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'PRICET'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="example-wrapper">
                    <div class="row no-gutters">
                        <div class="col-sm-12">
                            <div class="card-body">
                                
                            </div>
                        </div>
                    </div>
                    <kendo-dialog title="Open" *ngIf="dialogOpened" (close)="closeDialog()" [minWidth]="250" [width]="450">
                        <p style="margin: 30px; text-align: center;">PRICET Open!</p>
                        <kendo-dialog-actions>
                            <button kendoButton (click)="closeDialog()">Cancel</button>
                        </kendo-dialog-actions>
                    </kendo-dialog>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>