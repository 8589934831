import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fit-cc-lib',
  template: `
    <p>
      fit-common-components-lib works!
    </p>
  `,
  styles: []
})
export class FitCommonComponentsLibComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
