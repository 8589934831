import { FacilityItemTypeSerializer } from './../api/serializer';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PermissionsProvider } from './permissions.provider';
import {
  BasinModel,
  DevelopmentAreaModel,
  StateModel,
  GeographicAreaModel,
  CompressorCapacityAssumptionsModel,
  LandDamagesPipesModel,
  LandDamagesModel,
  PadContingencyAssumptionsModel,
  PrctItemDetailModel,
  PrctItemModel,
  ItemTypeModel,
  PrctInfrastructureClassModel,
  PrctInfraestructureScopeCategoryModel,
  RfsdTimingModel,
  KindModel,
  LocationModel,
  UnitOfMeasureModel,
  FacilityItemsAndYearlyCostMdtModel,
} from '../api/master-data';

import {
  BasinSerializer,
  DevelopmentAreaSerializer,
  StateSerializer,
  GeographicAreaSerializer,
  CompressorCapacityAssumptionsSerializer,
  LandDamagesPipesSerializer,
  LandDamagesSerializer,
  PadContingencyAssumptionsSerializer,
  PrctItemDetailSerializer,
  PrctItemSerializer,
  ItemTypeSerializer,
  PrctInfrastructureClassSerializer,
  PrctInfraestructureScopeCategorySerializer,
  RfsdTimingSerializer,
  KindSerializer,
  LocationSerializer,
  UnitOfMeasureSerializer,
  FacilityItemsAndYearlyCostMdtSerializer,
} from '../api/serializer';
import { ScenarioStoreService } from '../services/scenario-store.service';
import { IFacilityItemType } from '../api/facility-item-type-model';
import { IFacilityType } from '../modules/infrastructure/models/facility-type';
import { FitCcBaseService } from '../_shared/fit-common-components-lib/lib/fit-cc-base.service';
import { DialogService } from '@progress/kendo-angular-dialog';

@Injectable({ providedIn: 'root' })
export class BasinService extends FitCcBaseService<BasinModel> {
  
  public url = environment.PricetDomainURL;
  public endpoint = 'Basin';
  public serializer = new BasinSerializer(this.permissionProvider.cai);
  
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

}

@Injectable({ providedIn: 'root' })
export class DevelopmentAreaService extends FitCcBaseService<DevelopmentAreaModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }
  url = environment.PricetDomainURL;
  endpoint = 'DevelopmentArea';
  serializer = new DevelopmentAreaSerializer(this.permissionProvider.cai);
}

@Injectable({ providedIn: 'root' })
export class StateService extends FitCcBaseService<StateModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'State';
  serializer = new StateSerializer(this.permissionProvider.cai);
}

@Injectable({ providedIn: 'root' })
export class GeographicAreaService extends FitCcBaseService<GeographicAreaModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }
  url = environment.PricetDomainURL;
  endpoint = 'GeographicArea';
  serializer = new GeographicAreaSerializer(this.permissionProvider.cai);
}

@Injectable({ providedIn: 'root' })
export class CompressorCapacityAssumptionsService extends FitCcBaseService<CompressorCapacityAssumptionsModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  token = 'CompressorCapacityAssumptions';
  endpoint = 'CompressorCapacityAssumptions';
  serializer = new CompressorCapacityAssumptionsSerializer(this.permissionProvider.cai);

  public save(data: CompressorCapacityAssumptionsModel, isNew?: boolean) {
    super.save(data, isNew);
    this.reloadMasterDataTables();
  }
  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}

@Injectable({ providedIn: 'root' })
export class LandDamagesPipesService extends FitCcBaseService<LandDamagesPipesModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'LandDamagesPipes';
  serializer = new LandDamagesPipesSerializer(this.permissionProvider.cai);

  public save(data: LandDamagesPipesModel, isNew?: boolean) {
    super.save(data, isNew);
    this.reloadMasterDataTables();
  }
  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}

@Injectable({ providedIn: 'root' })
export class LandDamagesService extends FitCcBaseService<LandDamagesModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'LandDamages';
  serializer = new LandDamagesSerializer(this.permissionProvider.cai);

  public save(data: LandDamagesModel, isNew?: boolean) {
    super.save(data, isNew);
    this.reloadMasterDataTables();
  }

  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}

@Injectable({ providedIn: 'root' })
export class PadContingencyAssumptionsService extends FitCcBaseService<PadContingencyAssumptionsModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'PadContingencyAssumptions';
  serializer = new PadContingencyAssumptionsSerializer(this.permissionProvider.cai);

  public save(data: PadContingencyAssumptionsModel, isNew?: boolean) {
    super.save(data, isNew);
    this.reloadMasterDataTables();
  }

  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}

@Injectable({ providedIn: 'root' })
export class PrctItemDetailService extends FitCcBaseService<PrctItemDetailModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'PrctItemDetail';
  serializer = new PrctItemDetailSerializer();

  public listItemDetailToDropDown(): Observable<any[]> {
    const serviceUrl: string = this.url + this.endpoint + '/list/?ItemTypeId!=null';
    return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: PrctItemDetailModel[]) => {
        return data.map(d => {
          return { value: d.id, text: d.name };
        });
      }),
      catchError(this.handleError2)
    );
  }

  private handleError2(error: HttpErrorResponse) {
    console.log('handle error:', error);

    if (error.error instanceof Error) {
      const errMsg = error.error.message;
      return Observable.throw(errMsg);
    }

    return Observable.throw(error || 'WebAPI server error');
  }
}

@Injectable({ providedIn: 'root' })
export class ItemTypeService extends FitCcBaseService<ItemTypeModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'ItemType';
  serializer = new ItemTypeSerializer();
}

@Injectable({ providedIn: 'root' })
export class PrctInfrastructureClassService extends FitCcBaseService<PrctInfrastructureClassModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'InfrastructureClass';
  serializer = new PrctInfrastructureClassSerializer();
}

@Injectable({ providedIn: 'root' })
export class LocationMDTService extends FitCcBaseService<LocationModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'Location';
  serializer = new LocationSerializer();
}

@Injectable({ providedIn: 'root' })
export class PrctInfraestructureScopeCategoryService extends FitCcBaseService<PrctInfraestructureScopeCategoryModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'InfrastructureScopeCategory';
  serializer = new PrctInfraestructureScopeCategorySerializer();
}

@Injectable({ providedIn: 'root' })
export class RfsdTimingService extends FitCcBaseService<RfsdTimingModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'ReadyForServiceDateTiming';
  serializer = new RfsdTimingSerializer();
}

@Injectable({ providedIn: 'root' })
export class KindService extends FitCcBaseService<KindModel> {
  constructor(httpClient: HttpClient, notificationService: NotificationService, dialogService: DialogService) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PricetDomainURL;
  endpoint = 'Kind';
  serializer = new KindSerializer();
}

@Injectable({ providedIn: 'root' })
export class PrctItemService extends FitCcBaseService<PrctItemModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    private infrastructureScopeCategoryService: PrctInfraestructureScopeCategoryService,
    private infrastructureClassService: PrctInfrastructureClassService,
    private locationService: LocationMDTService,
    private rfsdTimingService: RfsdTimingService,
    private kindService: KindService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PacerDomainURL;
  endpoint = 'FacilityItem';
  serializer = new PrctItemSerializer(this.permissionProvider.cai);

  public read() {
    let infrastructureScopeCategoryList: PrctInfraestructureScopeCategoryModel[] = [],
      infrastructureClassList: PrctInfrastructureClassModel[] = [],
      locationList: LocationModel[] = [],
      rfsdTimingList: RfsdTimingModel[] = [],
      kindList: KindModel[] = [];
    if (this.data.length) {
      this.publish();
    }
    this.infrastructureScopeCategoryService
      .list()
      .subscribe(data => {
        infrastructureScopeCategoryList = data;
      })
      .add(() => {
        this.infrastructureClassService.list().subscribe(data => {
          infrastructureClassList = data;
        });
      })
      .add(() => {
        this.locationService.list().subscribe(data => {
          locationList = data;
        });
      })
      .add(() => {
        this.rfsdTimingService.list().subscribe(data => {
          rfsdTimingList = data;
        });
      })
      .add(() => {
        this.kindService.list().subscribe(data => {
          kindList = data;
        });
      })
      .add(() => {
        this.fetchItems(this.endpointList)
          .pipe(
            tap((items: PrctItemModel[]) => {
              items.forEach(item => {
                const infraScopeCategory = infrastructureScopeCategoryList.find(
                  i => i.id === item.infraestructureScopeCategoryId
                );
                item.infraestructureScopeCategoryName = infraScopeCategory?.name;

                const infraClass = infrastructureClassList.find(i => i.id === infraScopeCategory?.infrastructureClassId);
                item.infrastructureClassId = infraClass?.id;
                item.infrastructureClassName = infraClass?.name;

                const location = locationList.find(i => i.id === item.locationId);
                item.locationName = location.name;

                const rfsdTiming = rfsdTimingList.find(i => i.id === item.rfsdTimingId);
                item.rfsdTimingName = rfsdTiming.name;

                const kind = kindList.find(i => i.id === item.kindId);
                item.kindName = kind.name;
              });
              this.data = items;
            })
          )
          .subscribe(data => {
            this.data = data;
            this.publish();
          });
      });
  }

  private fetchItems(action: string = '', data?: any, isNew?: boolean): Observable<PrctItemModel[]> {
    const serviceUrl: string = this.url + this.endpoint + action;
    const items: PrctItemModel[] = [];

    if (action === this.endpointList) {
      return this.http.get(serviceUrl, { responseType: 'json' }).pipe(
        map((dataItems: any[]) => {
          dataItems.forEach(dataItem => {
            const item: PrctItemModel = {
              $id: '',
              id: dataItem.id,
              name: dataItem.name,
              displayName: dataItem.name,
              isDelete: false,
              itemDetailId: dataItem.id,
              itemDetailName: dataItem.type,
              infrastructureClassId: '',
              infrastructureClassName: '',
              locationId: dataItem.locationId,
              locationName: '',
              infraestructureScopeCategoryId: dataItem.infrastructureScopeCategoryId,
              infraestructureScopeCategoryName: '',
              rfsdTimingId: dataItem.readyForServiceDateTimingId,
              rfsdTimingName: '',
              kindId: dataItem.kindId,
              kindName: '',
              itemNotes: '',
              unitOfMeasure: dataItem.unitOfMeasureName,
              respToExpandPrctItemId: dataItem.parentFacilityItemId,
              respToExpandPrctItemName: '',
              itemTypeId: '',
              itemTypeName: '',
              OrdenItem: dataItem.sortOrder,
            };
            items.push(item);
          });
          return this.serializer.fromJson(items);
        })
      );
    }
  }

  // overriting the method save
  public save(data: PrctItemModel, isNew?: boolean) {
    this.http.get(`${environment.PacerDomainURL}FacilityItem/GetByKey/${data.id}`).subscribe((item: any) => {
      item.infrastructureScopeCategoryId = data.infraestructureScopeCategoryId;
      item.locationId = data.locationId;
      item.kindId = data.kindId;
      item.readyForServiceDateTimingId = data.rfsdTimingId;
      item.updatedDate = new Date(Date.now());
      item.updatedBy = this.permissionProvider.cai.toUpperCase();

      this.saveFacilityItem(item).subscribe();
      this.showNotification('Data has been saved successfully', 'success');
      this.reloadMasterDataTables();
    });
  }

  private saveFacilityItem(item: any) {
    const action = 'FacilityItem';
    const serviceUrl = environment.PacerDomainURL + action;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<string>(`${serviceUrl}/SaveCnDItem`, item, httpOptions).pipe(catchError(this.handleError2));
  }

  private handleError2(error: HttpErrorResponse) {
    console.log('handle error:', error);
    if (error.error instanceof Error) {
      const errMsg = error.error.message;
      return Observable.throw(errMsg);
    }

    return Observable.throw(error || 'WebAPI server error');
  }

  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}

@Injectable({ providedIn: 'root' })
export class UnitOfMeasureService extends FitCcBaseService<UnitOfMeasureModel> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }

  url = environment.PacerDomainURL;
  endpoint = 'UnitOfMeasure';
  serializer = new UnitOfMeasureSerializer(this.permissionProvider.cai);
}

@Injectable({ providedIn: 'root' })
export class FacilityItemTypeService extends FitCcBaseService<IFacilityItemType> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }


  url = environment.PacerDomainURL;
  endpoint = 'FacilityItemType';
  endpointList = '/ListByIsFitItemType'
  serializer = new FacilityItemTypeSerializer(this.permissionProvider.cai);
}

@Injectable({ providedIn: 'root' })
export class FacilityTypeService extends FitCcBaseService<IFacilityType> {
  constructor(
    httpClient: HttpClient,
    notificationService: NotificationService,
    dialogService: DialogService,
    @Inject(PermissionsProvider) private permissionProvider: PermissionsProvider
  ) {
    super(httpClient, notificationService, dialogService);
  }


  url = environment.PacerDomainURL;
  endpoint = 'FacilityType';
  endpointList = '/getFitFacilityType'
}

@Injectable({ providedIn: 'root' })
export class FacilityItemsAndYearlyCostMdtService extends FitCcBaseService<FacilityItemsAndYearlyCostMdtModel> {
  constructor(
    private httpClient: HttpClient,
    notificationService: NotificationService,
    private scenarioStoreService: ScenarioStoreService,
    dialogService: DialogService,
  ) {
    super(httpClient, notificationService, dialogService);
  }


  url = environment.PacerDomainURL;
  endpoint = 'FacilityItem';
  serializer = new FacilityItemsAndYearlyCostMdtSerializer();
  // overriting the method save
  public save(data: FacilityItemsAndYearlyCostMdtModel, isNew?: boolean) {
    this.SaveCnDItemDetailCost(data).subscribe();
    this.showNotification('Data has been saved successfully', 'success');
    this.reloadMasterDataTables();
  }

  public SaveCnDItemDetailCost(item: FacilityItemsAndYearlyCostMdtModel): Observable<string> {
    const serviceUrl: string = this.url + this.endpoint + '/SaveCnDItemDetailCost';
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post<string>(serviceUrl, item, httpOptions).pipe(catchError(this.handleError2));
  }

  private handleError2(error: HttpErrorResponse) {
    console.log('handle error:', error);

    if (error.error instanceof Error) {
      const errMsg = error.error.message;
      return Observable.throw(errMsg);
    }

    return Observable.throw(error || 'WebAPI server error');
  }

  public reloadMasterDataTables() {
    this.scenarioStoreService.initCatalogsObservables(false);
    this.scenarioStoreService.initializeMasterData();
  }
}
