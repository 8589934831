<div *ngIf="showProgressBar" class="mat-app-background basic-container">
    <app-progress-bar-indeterminate-example></app-progress-bar-indeterminate-example>
  </div>
  <form #Form="ngForm" [formGroup]="formActive" novalidate>
    <div class="card-body">
      <div class="row no-gutters">
        <div class="col-sm-12">
          <div class="form-group row">
            <kendo-grid #massUpdateOutlookGrid
            [data]="view | async"
            [skip]="gridState.skip" 
            [sort]="gridState.sort"
            [sortable]="true"
            [scrollable]="'scrollable'"
            [navigable]="true"
            style="height: 400px;"
            [kendoGridSelectBy]="'padName'"
            [(selectedKeys)]="mySelection"
            (selectedKeysChange)="onSelectedKeysChange($event)"
            (cellClose)="cellCloseHandler($event)"
            (cellClick)="cellClickHandler($event)">
              <kendo-grid-column field="pbPadName" editor="text" [editable]="false" title="Pad Name">
              </kendo-grid-column>
              <kendo-grid-column field="activeForOutlook" editor="text" [editable]="false" title="Active For Outlook">
              </kendo-grid-column>
              <kendo-grid-checkbox-column width="50">
                <ng-template kendoGridHeaderTemplate>
                  <input
                    class="k-checkbox"
                    id="selectAllCheckboxId"
                    kendoGridSelectAllCheckbox
                    [state]="selectAllState"
                    (selectAllChange)="onSelectAllChange($event)"
                  />
                  <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
              </kendo-grid-checkbox-column>
            </kendo-grid>
          </div>
          <div class="form-group row">
            
            <div class="col-sm-6"></div>
            <button kendoButton [primary]="true" class="k-button margin: 10px" (click)="massiveSetPadBuilderData('Yes')">Set selected To Yes</button>
            <div class="col-sm-1"></div>
            <button kendoButton [primary]="true" class="k-button margin: 10px" (click)="massiveSetPadBuilderData('No')">Set selected To No</button>
            <ng-template #notiftemplate></ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction('Cancel')">Cancel</button>
    <button kendoButton (click)="onClickOk('Submit')" [primary]="true">
            Submit
    </button>
</kendo-dialog-actions>  