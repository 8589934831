import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { GridComponent, GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { IPerformanceBod } from 'src/app/api/performance-bod';
import { PerformanceAFEService } from './performance-afe.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AuthenticationService } from 'src/app/services';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import * as Helpers from 'src/app/_shared/helpers';
import { PerformanceAFE } from 'src/app/api/performance-afe';
import { DateInputLocalizedMessagesDirective } from '@progress/kendo-angular-dateinputs';
import { PerfAfeRemovePopupComponent } from '../perf-afe-remove-popup/perf-afe-remove-popup.component';

@Component({
  selector: 'app-performance-afe',
  templateUrl: './performance-afe.component.html',
  styleUrls: ['./performance-afe.component.css'],
})
export class PerformanceAfeComponent implements OnInit {
  @Output() public getMessageStatusChange = new EventEmitter<boolean>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  @Output() public performanceBodUpdate = new EventEmitter<string>();

  @ViewChild('performanceAfe') public performanceAfe: GridComponent;
  @ViewChild('removeAFEDialog', { read: ViewContainerRef })
  public containerRef: ViewContainerRef;

  public hasChanges: boolean;
  public afeMask = 'AAAAA-AAAAA-AAA';
  public changes: any = {};
  public perfBodSelected: IPerformanceBod;
  public perfAfeSvc: PerformanceAFEService;
  public view: Observable<GridDataResult>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };
  public hasPermissionToEdit = false;
  public isLoading = true;
  public selectableSettings: SelectableSettings;

  constructor(
    private route: ActivatedRoute,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    public performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: FormBuilder,
    @Inject(PerformanceAFEService) performanceAFEServiceFactory: any,
    private dialogService: DialogService
  ) {
    this.perfAfeSvc = performanceAFEServiceFactory();
  }

  public ngOnInit(): void {
    //Make checkbox single mode
    this.selectableSettings = {
      mode: "single"
    };

    //whether user has permissions to edit Perf_bod
    if (this.permissionsProvider.canEditPerformanceBod) {
      this.hasPermissionToEdit = true;
    } else this.hasPermissionToEdit = false;

    //selected performance Bod
    this.performanceBodStoreService.performanceBod$.subscribe((performanceBod: IPerformanceBod) => {
      this.perfBodSelected = performanceBod;
    });

    //getting performance_bod id
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.perfAfeSvc.performanceBodId = params.get('id');
      this.perfAfeSvc.read();
    });

    this.perfAfeSvc.loader.subscribe(isLoaded => this.loadedData(isLoaded));

    this.view = this.perfAfeSvc.pipe(map(data => process(data, this.gridState)));
  }

  public loadedData(isLoaded: boolean) {
    this.isLoading = isLoaded;
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.perfAfeSvc.read();
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      this.perfAfeSvc.assignValues(dataItem, formGroup.value);
      this.perfAfeSvc.updateItem(dataItem);
      this.setStatus(this.perfAfeSvc.hasChanges());
    }
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      this.perfAfeSvc.createItem(formGroup.value);
      this.setStatus(this.perfAfeSvc.hasChanges());
      sender.closeRow(rowIndex);
    }
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id:
        dataItem.id != null && dataItem.id !== undefined
          ? dataItem.id
          : dataItem.dataItem != null
          ? dataItem.dataItem.id
          : null,
      performanceBodId:
        dataItem.performanceBodId != null && dataItem.performanceBodId !== undefined
          ? dataItem.performanceBodId
          : dataItem.dataItem != null
          ? dataItem.dataItem.performanceBodId
          : null,
      name: [
        dataItem.name != null && dataItem.name !== undefined
          ? dataItem.name
          : dataItem.dataItem != null
          ? dataItem.dataItem.name
          : null,
        Validators.required,
      ],
      chargeCode: [
        dataItem.chargeCode != null && dataItem.chargeCode !== undefined
          ? dataItem.chargeCode
          : dataItem.dataItem != null
          ? dataItem.dataItem.chargeCode
          : null,
        { validators: [Validators.maxLength(13), Validators.pattern('^[a-zA-Z0-9]{0,13}$')] },
      ],
      number: [
        dataItem.number != null && dataItem.number !== undefined
          ? dataItem.number
          : dataItem.dataItem != null
          ? dataItem.dataItem.number
          : null,
        Validators.required,
      ],
      amount: [
        dataItem.amount != null && dataItem.amount !== undefined
          ? dataItem.amount
          : dataItem.dataItem != null
          ? dataItem.dataItem.amount
          : null,
        Validators.required,
      ],
      createdBy: dataItem.createdBy,
      createdDate: dataItem.createdDate,
      updatedBy: dataItem.updatedBy,
      updatedDate: dataItem.updatedDate,
      isDefault: [
        dataItem.isDefault != null && dataItem.isDefault !== undefined
          ? dataItem.isDefault
          : dataItem.dataItem != null
          ? dataItem.dataItem.isDefault
          : false
      ],
    });
  }

  public setStatus(status: boolean) {
    this.getMessageStatusChange.emit(status);
  }

  public convertToAfeFormat(text: any) {
    return Helpers.convertToAfeFormat(text);
  }

  public saveFromBOD(emitCount: boolean = true) {
    this.hasChanges = this.perfAfeSvc.hasChanges();
    if (this.hasChanges) {
      this.perfAfeSvc.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

      const subscription = this.perfAfeSvc.subscribe(() => {
        if (emitCount) this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  public addHandler({ sender }) {
    const item = new PerformanceAFE();
    item.performanceBodId = this.perfBodSelected.id;
    const currentDate = new Date();
    item.createdBy = this.authenticationService.getCurrentUser();
    item.updatedBy = this.authenticationService.getCurrentUser();
    item.createdDate = currentDate;
    item.updatedDate = currentDate;

    sender.addRow(this.createFormGroup(item));
  }

  public removeHandler({ sender, dataItem }) {
    if (dataItem.isDefault) {
      const dialogRef = this.dialogService.open({
        appendTo: this.containerRef,
        title: 'Please confirm',
        content: PerfAfeRemovePopupComponent,
        width: 700,
      });

      dialogRef.result.subscribe((r: any) => {
        if (r.hasChanges !== undefined || r.hasChanges != null) {
          if (r.hasChanges) {
            this.perfAfeSvc.removeItem(r.deletedItem);
            // this.facilityAfeSvc.updatedFacilityCostItems = r.updatedCostItemsByAfe;
            sender.cancelCell();
            this.saveFromBOD(false);
          }
        }
      });

      const dialogInfo = dialogRef.content.instance;
      dialogInfo.afe = dataItem;
      dialogInfo.perfAfeService = this.perfAfeSvc;
      // dialogInfo.costItemsByAfeId = data;
    } else {
      this.perfAfeSvc.removeItem(dataItem);
      sender.cancelCell();
    }
  }
}
