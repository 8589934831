import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { DasSimpleDetailsComponent } from '../das-simple-details/das-simple-details.component';
import { DasSimpleScopeAssumptionsComponent } from '../das-simple-scope-assumptions/das-simple-scope-assumptions.component';
import { DasSimpleScopeCountComponent } from '../das-simple-scope-count/das-simple-scope-count.component';
import { DasSimpleCalculationsService } from '../../../services/das-simple-calculations.service';
import { DasSimpleService } from '../../../services/das-simple.service';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { UserPermissionsService } from '../../../services/user-permission.service';
import { IDasSimple } from '../../../api/das-simple';

@Component({
  selector: 'app-das-simple-summary',
  templateUrl: './das-simple-summary.component.html',
  styleUrls: ['./das-simple-summary.component.css'],
})
export class DasSimpleSummaryComponent implements OnInit, OnDestroy {
  public position = 'top';
  public canEditCnd = false;
  public isNewDasSimple = false;
  public currentDasSimple: IDasSimple;
  @ViewChild(DasSimpleDetailsComponent)
  private dasSimpleDetailsComponent: DasSimpleDetailsComponent;
  @ViewChild(DasSimpleScopeCountComponent)
  private dasSimpleScopeCountComponent: DasSimpleScopeCountComponent;
  @ViewChild(DasSimpleScopeAssumptionsComponent)
  private dasSimpleScopeAssumptionsComponent: DasSimpleScopeAssumptionsComponent;

  private userPermissionsService: UserPermissionsService;
  public dasSimpleId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(UserPermissionsService) userPermissionsServiceFactory: any,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private permissionsProvider: PermissionsProvider,
    private dasSimpleService: DasSimpleService,
    private dasSimpleCalculationsService: DasSimpleCalculationsService
  ) {
    this.spinner.show();
    this.userPermissionsService = userPermissionsServiceFactory();
  }

  public ngOnInit(): void {
    this.checkIsNew();
    this.canEditCnd = this.permissionsProvider.canEditCnd;

    if (!this.isNewDasSimple) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.dasSimpleService.initialize(params.get('id'));
        this.dasSimpleId = params.get('id');
      });

      this.dasSimpleCalculationsService.dasSimpleCosts$.subscribe((data: any[]) => {
        if (data !== null) {
          if (data.length > 0) {
            this.spinner.hide();
          }
        }
      });

      this.dasSimpleService.dasSimple$.subscribe((data: IDasSimple) => {
        if (data !== null) {
          this.currentDasSimple = data;
        }
      });
    }

    this.dasSimpleCalculationsService.load();

    this.dasSimpleCalculationsService.dasSimpleCosts$.subscribe(
      (costs: any[]) => {
        if (costs && costs.length > 0) {
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  }

  public ngOnDestroy(): void {
    this.dasSimpleCalculationsService.cleanObservables();
  }

  public onTabSelect(e: any) {
    //
  }

  public saveFromDasSimple(): void {
    // Das Simple Details
    const dasSimpleDetailsInstanceCreated =
      this.dasSimpleDetailsComponent !== undefined || this.dasSimpleDetailsComponent != null;
    const dasSimpleScopeCountInstanceCreated =
      this.dasSimpleScopeCountComponent !== undefined ||
      (this.dasSimpleScopeCountComponent != null && dasSimpleDetailsInstanceCreated);
    const dasSimpleScopeAssumptionsCreated =
      this.dasSimpleScopeAssumptionsComponent !== undefined || this.dasSimpleScopeAssumptionsComponent != null;

    if (dasSimpleDetailsInstanceCreated && dasSimpleScopeCountInstanceCreated) {
      this.dasSimpleScopeAssumptionsComponent.loadValuesFromForm();
      this.dasSimpleDetailsComponent.saveDasSimple(
        this.dasSimpleDetailsComponent.isNewDasSimple
          ? this.dasSimpleScopeCountComponent.dasSimpleScopeCountEditService.updatedItems
          : null,
        [this.dasSimpleScopeAssumptionsComponent.scopeAssumptionsData]
      );
    }

    // Das Simple Scope Count
    if (dasSimpleScopeCountInstanceCreated && dasSimpleDetailsInstanceCreated) {
      if (!this.dasSimpleDetailsComponent.isNewDasSimple) {
        this.dasSimpleScopeCountComponent.saveFromDasSimpleSummary();
      }
    }

    // Das Simple Scope Count
    if (dasSimpleScopeAssumptionsCreated && dasSimpleDetailsInstanceCreated) {
      if (!this.dasSimpleDetailsComponent.isNewDasSimple) {
        this.dasSimpleScopeAssumptionsComponent.saveDasSimple();
      }
    }

    if (!this.dasSimpleDetailsComponent.isNewDasSimple) {
      this.dasSimpleService.UploadDasSimpleCapex(this.dasSimpleId);
    }
  }

  public exportToExcel(): void {
    //
    const objdatetime = new Date();
    const timezone = objdatetime.toTimeString();
    const tzstr = timezone.split('(');
    const timezoneid = tzstr[1].toString().replace(')', '');

    this.notificationService.show({
      content: ' Export to Excel may take a few minutes...please wait.',
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 800 },
      type: { style: 'info', icon: true },
      hideAfter: 7000,
    });

    this.spinner.show();

    this.dasSimpleService.downloadDasSimpleExcelFile(this.currentDasSimple.id).subscribe(
      data => {
        this.spinner.hide();

        if (data) {
          this.notificationService.show({
            content: ' Downloading the Excel File...',
            position: { horizontal: 'center', vertical: 'top' },
            animation: { type: 'fade', duration: 800 },
            type: { style: 'success', icon: true },
          });

          if (data) {
            // Second implementation using and html a element
            const e = document.createElement('a');
            e.href = window.URL.createObjectURL(data);
            e.download = this.currentDasSimple.name + '.xlsx';
            e.style.display = 'none';
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
          }
        }
      },
      error => {
        this.notificationService.show({
          content: 'There was an issue generating Export Data document. Please try again later.',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: 'fade', duration: 700 },
          type: { style: 'warning', icon: true },
        });
      }
    );
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  public onSmartImportSequenceUpdate(sequences: any[]): void {
    // Das Simple Scope Assumptions
    if (this.dasSimpleScopeAssumptionsComponent !== undefined || this.dasSimpleScopeAssumptionsComponent != null) {
      this.dasSimpleScopeAssumptionsComponent.checkStateLogic(sequences);
    }

    // Das Simple Scope Count
    if (this.dasSimpleScopeCountComponent !== undefined || this.dasSimpleScopeCountComponent != null) {
      this.dasSimpleScopeCountComponent.addSmartImportSequences(sequences);
    }
  }

  private checkIsNew(): void {
    const routeName = this.router.url.split('/');
    const bodRoute = routeName[3];
    if (bodRoute === 'New') {
      this.isNewDasSimple = true;
    }
  }
}
