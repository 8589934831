<div class="card-body">
    <kendo-grid #editPadGrid
      [data]="view | async"
      [filter]="gridState.filter"
      [filterable]="true"
      (dataStateChange)="onDataStateChange($event)"
      [sortable]="{ allowUnsort: allowUnsort, mode: multiple ? 'multiple' : 'single' }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      [navigable]="true"
      style="min-height: 250px"
      (remove)="removeHandler($event)"
      (cellClose)="cellCloseHandler($event)"
      (cellClick)="cellClickHandler($event)">
      <ng-template kendoGridToolbarTemplate>
        <button kendoButton class='k-button' [disabled]="!editPadsService.hasChanges()" (click)="cancelChanges(editPadGrid);" [primary]="true">Cancel Changes</button>
      </ng-template>      
      <kendo-grid-column field="isSelected" editor="text" title="" [editable]="false"
      [filterable]="false" width="30">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="k-icon k-i-edit" *ngIf="dataItem.isSelected"></span>
      </ng-template>      
      </kendo-grid-column>
      <kendo-grid-column field="name" editor="text" title="Pad Name">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
            <input #input kendoGridFocusable type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
            <kendo-popup [anchor]="input" [hidden]="!isDuplicatedName" popupClass="k-widget k-tooltip k-tooltip-validation">
              The Pad Name already exist.
            </kendo-popup>
          </ng-template>           
      </kendo-grid-column>
      <kendo-grid-column field="id" editor="text" title="Id" [hidden]="true">        
      </kendo-grid-column>      
      <kendo-grid-command-column title="" width="220">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoGridRemoveCommand [primary]="true" [hidden]="!dataItem.isSelected">Cancel</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>