<br />
<form novalidate [formGroup]="formScopeAssumptions" id="scopeAssumptionsForm" #form="ngForm">
  <table style="width: 50%;" border="1" aria-hidden="true">
    <tbody>
      <tr>
        <td colspan="2" style="background-color:lightgray;">CTB Assumptions</td>
      </tr>
      <tr style="height:2px;">
        <td style="width: 50%;">Wells per test separator:</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="wellsPerTestSeparator"
            (valueChange)="onChange($event, 'wellsPerTestSeparator')"
            style="width: 100%;height:25px;"
            [step]="1"
            [min]="1"
            [max]="100"
            [decimals]="0"
            [format]="'n0'"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>

      <tr>
        <td colspan="2" style="background-color:lightgray;">Compressor Station Assumptions</td>
      </tr>
      <tr>
        <td style="width: 50%;">Rental compressors:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="rentalCompressors"
            (valueChange)="onChange($event, 'rentalId')"
            [data]="rentalCompressorsList"
            style="width: 100%;height:25px;"
            [textField]="'text'"
            [valueField]="'value'"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">COOP compressors:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="coopCompressors"
            (valueChange)="onChange($event, 'coopId')"
            [data]="coopCompressorsList"
            style="width: 100%;height:25px;"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>

      <tr>
        <td colspan="2" style="background-color:lightgray;">Water Assumptions</td>
      </tr>
      <tr>
        <td style="width: 50%;">Frac Pond Type:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="fracPondType"
            (valueChange)="onChange($event, 'fracPondTypeId')"
            [data]="fracPondTypeList"
            style="width: 100%;height:25px;"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>

      <tr>
        <td colspan="2" style="background-color:lightgray;">Pipeline / Infrastructure Assumptions</td>
      </tr>
      <tr>
        <td style="width: 50%;">SWD Injection Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="swdInjectionDetail"
            (valueChange)="onChange($event, 'swdInjLineId')"
            [data]="swdInjectionDetailList"
            style="width: 100%;height:25px;"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">LP Gas Gath. Line Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="lpGasLineDetail"
            (valueChange)="onChange($event, 'lpGasLineId')"
            [data]="lpGasLineDetailList"
            style="width: 100%;height:25px;"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">HP Gas Gath. Line Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="hpGasLineDetail"
            (valueChange)="onChange($event, 'hpGasLineId')"
            [data]="hpGasLineDetailList"
            style="width: 100%;height:25px;"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Prod. Water Gath. Line Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="prodWaterLineDetail"
            (valueChange)="onChange($event, 'prodWaterLineId')"
            style="width: 100%;height:25px;"
            [data]="prodWaterLineDetailList"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Liquids Transfer Line Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="liquidsTransferLineDetail"
            (valueChange)="onChange($event, 'liquidsTransferLineId')"
            style="width: 100%;height:25px;"
            [data]="liquidsTransferLineDetailList"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Gas Lift Distribution Line Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="gasLiftDetail"
            (valueChange)="onChange($event, 'gasLiftLineId')"
            style="width: 100%;height:25px;"
            [data]="gasLiftDetailList"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Flowline Detail:</td>
        <td style="width: 50%;">
          <kendo-dropdownlist
            formControlName="flowlineDetail"
            (valueChange)="onChange($event, 'flowlineDetailId')"
            style="width: 100%;height:25px;"
            [data]="flowlineDetailList"
            textField="text"
            valueField="value"
            [disabled]="!canEditCnd"
          >
          </kendo-dropdownlist>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Default Flowline Length (mi):</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="flowlineLength"
            (valueChange)="onChange($event, 'flowlineLength')"
            style="width: 100%;height:25px;"
            [step]="1"
            [min]="0"
            [max]="100"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Default Gas Lift Line Length (mi):</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="gasLiftLength"
            (valueChange)="onChange($event, 'gasLiftLength')"
            style="width: 100%;height:25px;"
            [step]="1"
            [min]="0"
            [max]="100"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;">Default Gen. Infr. Length (mi):</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="genInfrLength"
            (valueChange)="onChange($event, 'generalInfLength')"
            style="width: 100%;height:25px;"
            [step]="1"
            [min]="0"
            [max]="100"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>

      <tr>
        <td colspan="2" style="background-color:lightgray;">Cost</td>
      </tr>
      <tr>
        <td style="width: 50%;">Tax:</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="tax"
            (valueChange)="onChange($event, 'tax')"
            style="width: 100%;height:25px;"
            [decimals]="4"
            [format]="formatOptions"
            [step]="0.01"
            [min]="0"
            [max]="1"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>      
      <tr>
        <td style="width: 50%;">Contingency:</td>
        <td style="width: 50%;">
          <kendo-numerictextbox
            formControlName="contingency"
            (valueChange)="onChange($event, 'contingency')"
            style="width: 100%;height:25px;"
            [decimals]="4"
            [format]="formatOptions"
            [step]="0.01"
            [min]="0"
            [max]="1"
            [disabled]="!canEditCnd"
          >
          </kendo-numerictextbox>
        </td>
      </tr>
    </tbody>
  </table>
</form>
