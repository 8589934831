import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, distinct, filterBy } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'fit-cc-column-filter',
  templateUrl: './fit-cc-column-filter.component.html',
  styleUrls: ['./fit-cc-column-filter.component.scss'],
})
export class FitCcColumnFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public filterOperator: any = 'eq';
  @Input() public filterByText = false;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  private value: any[] = [];

  public textAccessor = (dataItem: any) => (this.isPrimitive ? dataItem : dataItem[this.textField]);
  public valueAccessor = (dataItem: any) => (this.isPrimitive ? dataItem : dataItem[this.valueField]);

  public ngAfterViewInit() {
    this.currentData = this.data.sort((a, b) => this.isPrimitive ? (a > b ? 1 : -1) : (a[this.textField] > b[this.textField] ? 1 : -1));
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => this.filterByText ? this.currentData.find(d => d[this.textField] === f.value)[this.valueField] : f.value);

    this.showFilter = typeof this.textAccessor(this.currentData[0]) === 'string';
  }

  public isItemSelected(item) {
    return this.value.some(x => x === this.valueAccessor(item));
  }

  public onSelectionChange(item, li) {
    if (this.value.some(x => x === item)) {
      this.value = this.value.filter(x => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
      filters: this.value.map(v => ({
        field: this.field,
        operator: this.filterOperator,
        value: (this.filterByText ? this.currentData.find(d => d[this.valueField] === v)[this.textField] : v)
      })),
      logic: 'or',
    });
  }

  public onInput(e: any) {
    this.currentData = distinct(
      [
        ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
        ...filterBy(this.data, {
          operator: 'contains',
          field: this.textField,
          value: e.target.value,
        }),
      ],
      this.textField
    );
  }

  public onFocus(li: any): void {
    const ul = li.parentNode;
    const below = ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = li.offsetTop;
    }
  }
}
