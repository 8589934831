<footer 
  *ngIf="!isFullScreen"
  class="fit-cc-footer"
  [ngClass]="{fixed: isFixed, fullscreen: isFullScreen}">
  <span>App Version: {{appVersion}} | DB Version: {{dbVersion}} | ©{{currentYear}} Chevron Corporation | Confidential Restricted</span>
  <span>Content Contact: <a [href]="contactUrl" target="_blank">{{contactCaption}}</a></span>
  <span>Technical Contact: <a [href]="techUrl" target="_blank">{{ techCaption }}</a></span>
  <span>Rate: <a [href]="feedbackUrl" target="_blank">{{ feedbackCaption }}</a></span>
  <span class="h5" title="Environment"><span class="label label-danger">{{appEnvironment}}</span></span>
  <span class="h5" style="float: right"><a href="https://hou15gist1.chevron.com/ags/rest/services/MCBU_FIT_TRACER_RO/TRACER_QGIS_Land_Agreements/MapServer" id="gis_ssl_backdoor" style="color: white" target="_blank" class="ng-isolate-scope" rel="noopener noreferrer">GIS</a></span>
</footer>
