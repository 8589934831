import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodCentralTankBattery } from 'src/app/api/performance-bod-central-tank-battery';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';

@Injectable({
  providedIn: 'root',
})
export class PerformanceBodCentralTankBatteryService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodCentralTankBattery[] = [];
  public originalData: IPerformanceBodCentralTankBattery[] = [];
  public createdItems: IPerformanceBodCentralTankBattery[] = [];
  public updatedItems: IPerformanceBodCentralTankBattery[] = [];
  public deletedItems: IPerformanceBodCentralTankBattery[] = [];
  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodCentralTankBattery[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodCentralTankBattery(this.createdItems);
  }

  public retrieve(): Observable<IPerformanceBodCentralTankBattery[]> {
    return this.performanceBodDataService.getPerformanceBodCentralTankBattery(this.performanceBodId).pipe(map(data => {
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCentralTankBattery(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodCentralTankBattery(this.deletedItems);
  }

  public facilityNameDuplicated(
    facilityNameA: string,
    facilityNameB: string,
    padsIdA: string,
    padsIdB: string
  ): boolean {
    if (facilityNameA.trim() === facilityNameB.trim() && padsIdA === padsIdB) {
      return true;
    } else {
      return false;
    }
  }
}
