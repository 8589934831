<div class="k-card-body">
  <kendo-tabstrip #tabstrip class="tabstrip-width" [keepTabContent]="true" [animate]="true">
    <kendo-tabstrip-tab
      [title]="'Select pads to import'"
      [disabled]="selectPadsAssignmentDisabled"
      [selected]="selectPadsAssignmentTab"
    >
      <ng-template kendoTabContent>
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-sm-12">
              <div class="form-group row">
                <div class="col-sm-6">
                  <button kendoButton [primary]="true" class="k-button" (click)="selectAllPadsToImport()">
                    Select All
                  </button>
                  <button kendoButton [primary]="true" class="k-button" (click)="clearAllPadsToImport()">
                    Clear All
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <kendo-multiselect
                    #multiSelectTo
                    [data]="padsBeforeSaveList"
                    textField="padName"
                    valueField="padName"
                    [autoClose]="isCloseDisabledTo"
                    (valueChange)="toPadSelectChange($event)"
                    (open)="onOpenTo($event)"
                    [hidden]="hiddenOptions"
                    style="width:100%;"
                    [popupSettings]="{ width: 320, height: auto }"
                    [filterable]="true"
                    (filterChange)="handleFilterScenarioToSelection($event)"
                  ></kendo-multiselect>
                </div>
              </div>
              <div class="form-group row " style="margin-bottom: 100px">
                <div class="col-sm-12">
                  <button
                    kendoButton
                    [primary]="true"
                    class="k-button"
                    (click)="moveSelectSummary()"
                    [disabled]="nextButtonSelectDisabled"
                    style="margin:10px"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      [title]="'Summary'"
      [hidden]="hiddenOptions"
      [disabled]="summaryDisabled"
      [selected]="summaryTab"
    >
      <ng-template kendoTabContent>
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-sm-12">
              <div class="form-group row">
                <div class="col-sm-12">
                  <kendo-grid [data]="summaryData">
                    <kendo-grid-column field="padNameTo" title="Pad Name"></kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                 
                  <button kendoButton [primary]="true" class="k-button" (click)="previousPage()">Previous</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button kendoButton primary="true" [disabled]="summaryDisabled" (click)="onClickOk()">Submit</button>
</kendo-dialog-actions>
