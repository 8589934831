import { Component, EventEmitter, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { process, State, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { ScopeNonStandardOutsideFactoryProjectsService } from './scope-non-standard-outside-factory-projects.service';
import { IScopeNonStandardOutsideFactoryProjects } from 'src/app/api/scope-non-standard-outside-factory-projects';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { UtilService } from 'src/app/services/util.service';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';

const DRAGGABLE_SELECTOR = '.scope-nonstandard-outside-factory-projects-draggable';

@Component({
  selector: 'app-scope-non-standard-outside-factory-projects',
  templateUrl: './scope-non-standard-outside-factory-projects.component.html',

})
export class ScopeNonStandardOutsideFactoryProjectsComponent implements OnInit {
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public view: Observable<GridDataResult>;
  public performanceBodPackagesList: IPerformanceBodPackages[];
  public performanceBodId: string;
  public padNames: string[] = [];
  public formGroup: FormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public canEditPerformanceBod: boolean = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  public scopeNonStandardOutsideFactoryProjectsService: ScopeNonStandardOutsideFactoryProjectsService;
  @ViewChild('scopeNonStandard')
  private scopeNonStandardGrid: any;
  private dragAndDropService: DragAndDropService;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,

    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private utilService: UtilService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(ScopeNonStandardOutsideFactoryProjectsService) scopeNonStandardOutsideFactoryProjectsServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.scopeNonStandardOutsideFactoryProjectsService = scopeNonStandardOutsideFactoryProjectsServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature ? false : this.permissionsProvider.canEditPerformanceBod;
    });

    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.scopeNonStandardOutsideFactoryProjectsService.performanceBodId = id;
    });

    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      console.log("data",data)
      this.performanceBodPackagesList = data;
    });

    this.view = this.scopeNonStandardOutsideFactoryProjectsService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.scopeNonStandardOutsideFactoryProjectsService.read();

    this.dragAndDropService.initialize(
      this.scopeNonStandardOutsideFactoryProjectsService.data,
      DRAGGABLE_SELECTOR,
      () => {
        this.scopeNonStandardOutsideFactoryProjectsService.markAsSetOrder(this.authenticationService.getCurrentUser());
      }
    );
  }

  public ngAfterViewInit(): void {
    // To Do
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(dataItem.id, [Validators.required]),
      performanceBodPackagesId: new FormControl(dataItem.performanceBodPackagesId, [Validators.required]),
      description: new FormControl(dataItem.description, [
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(500),
      ]),
      readyForServiceDate: new FormControl(dataItem.readyForServiceDate, [Validators.required]),
      requestedOilCapacityBopd: new FormControl(dataItem.requestedOilCapacityBopd, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      requestedWaterCapacityBwpd: new FormControl(dataItem.requestedWaterCapacityBwpd, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      requestedGasCapacityMmscfd: new FormControl(dataItem.requestedGasCapacityMmscfd, [
        Validators.min(0),
        Validators.max(1000000),
      ]),
      comments: new FormControl(dataItem.comments, [Validators.minLength(0), Validators.maxLength(500)]),
      isDeleted: new FormControl(dataItem.isDeleted, [Validators.required]),
      createdBy: new FormControl(dataItem.createdBy),
      createdDate: new FormControl(dataItem.createdDate),
      updatedBy: new FormControl(dataItem.updatedBy),
      updatedDate: new FormControl(dataItem.updatedDate),
      afeNumber: new FormControl(dataItem.afeNumber, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),  
      afeAmount: new FormControl(dataItem.afeAmount, [
        Validators.maxLength(8),
        Validators.pattern('^[a-zA-Z0-9]{0,8}$'),
      ]),
      chargeCode : new FormControl(dataItem.chargeCode, [
        Validators.maxLength(13),
        Validators.pattern('^[a-zA-Z0-9]{0,13}$'),
      ]),
      cndHOEstimate: new FormControl(dataItem.cndHOEstimate, [Validators.min(0), Validators.max(999999999999)]),
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      if (dataItem.readyForServiceDate !== undefined && columnIndex === 2) {
        if (dataItem.readyForServiceDate !== null) {
          const y = new Date(dataItem.readyForServiceDate.toString()).getFullYear();
          const m = new Date(dataItem.readyForServiceDate.toString()).getMonth();
          const d = new Date(dataItem.readyForServiceDate.toString()).getDate();
          dataItem.readyForServiceDate = new Date(y, m, d);
        }
      }

      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem, column } = args;

    let columnField = '';

    if (column.field === 'performanceBodPackagesName') {
      columnField = 'performanceBodPackagesId';
    } else {
      columnField = column.field;
    }

    const checkColumn = formGroup.controls[columnField];

    if (!checkColumn.valid) {
      args.preventDefault();
    } else if (checkColumn.dirty) {
      if (columnField === 'performanceBodPackagesId') {
        dataItem.performanceBodPackagesName = this.getPerformanceBodPackages(formGroup.controls[columnField].value);
      }
    }

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      // dataItem.updatedBy = this.currentUser.cai;

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;

      this.scopeNonStandardOutsideFactoryProjectsService.assignValues(dataItem, formGroup.value);
      this.scopeNonStandardOutsideFactoryProjectsService.updateItem(dataItem);
    }
    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public addHandler({ sender }) {
    const item: IScopeNonStandardOutsideFactoryProjects = {
      id: Guid.create().toString(),
      performanceBodPackagesId: '',
      description: null,
      requestedOilCapacityBopd: 0.0,
      requestedWaterCapacityBwpd: 0.0,
      requestedGasCapacityMmscfd: 0.0,
      readyForServiceDate: null,
      comments: null,
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      afeNumber: '',
      afeAmount: 0,
      chargeCode : null,
      cndHOEstimate: 0
    };

    item.createdDate = new Date();
    sender.addRow(this.createFormGroup(item));
    this.dragAndDropService.onEditingMode();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      this.scopeNonStandardOutsideFactoryProjectsService.createItem(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.scopeNonStandardOutsideFactoryProjectsService.data);
      this.dragAndDropService.refreshDragAndDrop();
    } else {
      // required fields
      this.utilService.markFormGroupAsTouched(formGroup);
    }
  }

  public removeHandler({ sender, dataItem }) {
    dataItem.isDeleted = true;
    this.scopeNonStandardOutsideFactoryProjectsService.removeItem(dataItem);

    sender.cancelCell();
    // this.isLoading = false;
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
    this.spinner.show();
    // calls this to add the attribute of current row
    this.dragAndDropService.refreshDragAndDrop();
    this.scopeNonStandardOutsideFactoryProjectsService.saveChanges();
    this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());

    this.scopeNonStandardOutsideFactoryProjectsService.retrieve().subscribe(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'P&E Scope - Non-Standard/Outside Factory Projects Information saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    });
    
    // Important to refresh grid
    this.scopeNonStandardOutsideFactoryProjectsService.cancelChanges();
    // setTimeout(() => {
    //   this.spinner.hide();
    //   this.notificationService.show({
    //     content: 'P&E Scope - Non-Standard/Outside Factory Projects Information saved',
    //     position: { horizontal: 'center', vertical: 'top' },
    //     animation: { type: 'fade', duration: 500 },
    //     type: { style: 'success', icon: true },
    //     hideAfter: 700,
    //   });
    //   // Important to refresh grid
    //   this.scopeNonStandardOutsideFactoryProjectsService.cancelChanges();
    // }, 3000);
  }

  public saveFromBOD(): void {
    this.scopeNonStandardGrid.closeCell();
    this.scopeNonStandardGrid.cancelCell();

    if (this.scopeNonStandardOutsideFactoryProjectsService.hasChanges()) {
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.scopeNonStandardOutsideFactoryProjectsService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.scopeNonStandardOutsideFactoryProjectsService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });
      subscription.unsubscribe();
      // setTimeout(() => {
      //   this.scopeNonStandardOutsideFactoryProjectsService.cancelChanges();
      // }, 5000);
    }
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.scopeNonStandardOutsideFactoryProjectsService.cancelChanges();
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.scopeNonStandardOutsideFactoryProjectsService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackages(id: string): any {
    if (this.performanceBodPackagesList === null || this.performanceBodPackagesList === undefined) {
      return '';
    }
    return this.performanceBodPackagesList.find(x => x.id === id);
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }
}
