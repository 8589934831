import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  ColumnReorderEvent,
} from '@progress/kendo-angular-grid';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { SortDescriptor, orderBy, State, process } from '@progress/kendo-data-query';
import getByPath from 'lodash.get';
import setByPath from 'lodash.set';
import { ScenarioStoreService } from '../../../services/scenario-store.service';
import { OverriddenFieldsService } from '../../../services/overridden-fields.service';
import { Rfsd } from '../../../api/rfsd';
import { InfrastructureClass } from '../../../api/infrastructure-class';
import { InfrastructureScopeCategory } from '../../../api/infrastructure-scope-category';
import { Location } from '../../../api/location';
import { Kind } from '../../../api/kind';
import { Guid } from 'guid-typescript';
import { UnitOfMeasure } from '../../../api/unitOfMeasure-model';
import { PermissionsProvider } from '../../../services/permissions.provider';
import { PopupRef } from '@progress/kendo-angular-popup';
import { IntlService } from '@progress/kendo-angular-intl';
import { InputSummaryModel } from '../input-summary/input-summary-model';
import { NotificationService } from '@progress/kendo-angular-notification';
import clonedeep from 'lodash.clonedeep';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScenarioAfeType } from 'src/app/api/scenarioAfeType';
import { ICostLogicDetector } from '../../../api/cost-logic-detector';
@Component({
  selector: 'app-input-items',
  templateUrl: './input-items.component.html',
  styleUrls: ['./input-items.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .alternate-cost-header {
        background-color: rgb(0, 157, 217, 0.5);
        color: #fff;
        font-weight: 500;
      }
    `,
  ],
})
export class InputItemsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public canEditCnd: boolean = false;
  public newScenario: boolean;
  @ViewChild('inputFacilityScopeCount')
  public grid: GridComponent;
  @ViewChild('dropdownEditor')
  public dropDownEditor: DropDownListComponent;
  public gridScrollTop = 0;
  public readonly nonEditableColumns: string[];
  public readonly editableOutputFields: string[];
  public readonly nonEditableFields: string[];
  public readonly hiddenRows: string[];
  public inputInfraData: any[];
  public originalInputInfraData: any[] = [];
  public pads: any[];
  public padBuilderPads: any[];
  public rfsdOptions: Rfsd[];
  public infrastructureTypeOptions: InfrastructureClass[];
  public scopeCategoryOptions: InfrastructureScopeCategory[];
  public locationOptions: Location[];
  public kindOptions: Kind[];
  public scenarioAfeTypeOptions: ScenarioAfeType[];
  public unitOfMeasureOptions: UnitOfMeasure[];
  public gridView: GridDataResult;
  public taxRate: number;
  public padByPadData: any[];
  public allPadBuilderPads: any[];
  public items: any[];
  public state: State = {
    filter: {
      logic: 'and',
      filters: [{ field: 'isVisible', operator: 'eq', value: 1 }],
    },
  };
  public pageSize = 20;
  public skip = 0;
  public padCountsColumnGroupName: string = 'Scope Count per Pad';
  public formGroup: UntypedFormGroup;
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public isMultipleFilterActivaded = false;
  public dialogOpenedPadComment = false;
  public padNameForComment: string = '';
  public formComment: UntypedFormGroup;
  public isLoading = false;
  public costYear = '2019';
  public alternatePadCosts = false;
  public highlightColumn = false;
  public selectedPad = null;
  public initialLoadInfra = true;
  public highlightColumnClass = { 'background-color': 'rgb(0, 157, 217, .5)', color: '#fff', 'font-weight': '500' };
  @Output() public openEditPads = new EventEmitter<any>();
  public excludeCopy: string[];
  private isCtrlKeyPressed = false;
  private isHeaderBtnExpandend = false;
  @ViewChild('notificationPlaceHolder', {read: ViewContainerRef}) notificationPlaceHolder: ViewContainerRef;

  constructor(
    private scenarioStoreService: ScenarioStoreService,
    private formBuilder: UntypedFormBuilder,
    private overriddenFieldsService: OverriddenFieldsService,
    private changeDetector: ChangeDetectorRef,
    private permissionsProvider: PermissionsProvider,
    public intl: IntlService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.nonEditableColumns = [
      'displayName',
      'unitOfMeasure.name',
      'infrastructureClass.name',
      'location.name',
      'infraestructureScopeCategory.name',
      'kind.name',
      'scenarioAfeType.name',
      'total',
      'isVisible',
      'isCloned',
      'respToExpandFacilityItemId',
    ];
    this.editableOutputFields = [
      'Greenfield 1-Train CTB Install',
      'CTB Train - 12000 BOPD',
      'CTB Tank',
      'CTB VRU',
      'CTB LACT',
      'CTB Header',
      'CTB Flash Gas Compressor',
      'Greenfield 2-Train SWD Install',
      'CTB Water Transfer Pump',
      'Extra CTB Header (4 slot)',
      'Satellite Header',
      'CTB Brownfield Adder',
      'Greenfield Satellite Install',
      'Hi-Accuracy Satellite Metering Upgrade',
      'Satellite Brownfield Adder',
      'HPFE Brownfield Adder',
      'CS Brownfield Adder',
      'SWD Brownfield Adder',
      'CTB Test Separator',
      'Satellite Test Separator',
      'CTB Pad Cost',
      'LP Gas Pipeline Riser',
      'HP Gas Pipeline Riser',
      'Condensate Pipeline Riser',
      'Gas Lift Pipeline Riser',
      'SWD Injection Line Riser',
      'Regional Water Pipeline Riser',
      'Produced Water Pipeline Riser',
      'Blanket Gas Pipeline Riser',
      'Skim Oil Return Pipeline Riser',
      'SWD Injection Line',
      'SWD Well Pad and Hookup',
      'Water Source Well D&C Costs',
      'Commercial Oil Tie-in',
      'Survey and Permitting',
      "60' Facility Radio Tower",
      'Standard Network Equipment Package',
      'Reserve Pit Construction and Closure',
      'Post Drill Pad Reclamation',
      'Wellhead and AL Hookup - Gas Lift',
      'Flowline Risers (2 Per Well)',
      'Well Pad - Single Use / Above-Grade WH',
      'Flowline Materials and Installation',
      'Flowline Trenching',
      'Aeration/Circulation Skid (for recycle)',
      'Land Damages',
      'Greenfield Compressor Station Install',
      'SWD Injection H-Pump (at station)',
      'SWD Train - 15000 BWPD',
      'Core Greenfield SWD Install',
      'Compressor Station VRU',
      'Flare System',
      'Liquids Pipeline Riser',
    ];
    this.nonEditableFields = [];
    this.hiddenRows = scenarioStoreService.hiddenRows;
    this.createFormGroup = this.createFormGroup.bind(this);
    this.excludeCopy = ['Greenfield 1-Train CTB Install', 'Frac Ponds', 'Well Pad - Single Use / Above-Grade WH'];
  }
  // Base Greenfield CTB Intall To Greenfield 1-Train CTB Install
  // CTB Pad Cost ($M/Msqft) To CTB Pad Cost
  // Rental Compressor Install - 3516 Pkg To Rental 3516

  public ngOnInit(): void {
    this.createNewFormGroup('');
    this.dialogOpenedPadComment = false;
    this.canEditCnd = this.permissionsProvider.canEditCnd;
    this.inputInfraData = [];
    this.pads = [];

    this.scenarioStoreService.loadSpinner$.subscribe(val => {
      if (val) {
        this.showSpinner();
      } else {
        this.hideSpinner();
      }
    });
 
    this.scenarioStoreService.newScenario$.subscribe(value => (this.newScenario = value));
    this.scenarioStoreService.scopeCountPadBuilderDataPage$.subscribe(data => {
      this.padBuilderPads = data;
      this.changeDetector.markForCheck();
    });

    this.scenarioStoreService.infrastructureItems$.subscribe(data => {
      let originalData = this.hideRows(data.inputItems);
      let infraData = clonedeep(originalData);
      this.inputInfraData = originalData;
      this.pads = data.pads;
      this.loadItems();
      this.changeDetector.markForCheck();
      if ((this.initialLoadInfra && data.inputItems.length > 0) || data.inputItems.length > 0) {
        if (this.initialLoadInfra) {
          this.initialLoadInfra = false;
          infraData.forEach(item => this.originalInputInfraData.push(Object.assign({}, item)));
        } else {
          this.originalInputInfraData = [];
          infraData.forEach(item => this.originalInputInfraData.push(Object.assign({}, item)));
        }
      }
      if (this.selectedPad) {
        this.selectedPad = null;
        this.highlightColumn = false;
        this.loadPadBracketCost(this.selectedPad);
      }
    });

    this.scenarioStoreService.rfsdOptions$.subscribe(data => {
      this.rfsdOptions = data;
    });
    this.scenarioStoreService.scopeCategoryOptions$.subscribe(data => {
      this.scopeCategoryOptions = data;
    });
    this.scenarioStoreService.infrastructureClassOptions$.subscribe(data => {
      this.infrastructureTypeOptions = data;
    });
    this.scenarioStoreService.locationOptions$.subscribe(data => {
      this.locationOptions = data;
    });
    this.scenarioStoreService.kindOptions$.subscribe(data => {
      this.kindOptions = data;
    });
    this.scenarioStoreService.unitOfMeasureOptions$.subscribe(data => {
      this.unitOfMeasureOptions = data;
    });

    this.scenarioStoreService.scenarioAfeTypeOptions$.subscribe(data => {
      this.scenarioAfeTypeOptions = data;
    });

    this.scenarioStoreService.pricetSummary$.subscribe(data => {
      this.taxRate = data.tax;
      this.scenarioStoreService.infrastructureItems$.subscribe(data => {
            this.scenarioStoreService.costLogicDetector$.subscribe((detector: ICostLogicDetector) => {
              if (detector.isInfrastructureItemsReady && detector.isMappedCostReady && detector.isPadBuilderDataReady) {
                this.pads = data.pads;
                this.allPadBuilderPads = data.pads;
                this.items = this.hideRows(data.inputItems);
                this.padByPadData = this.calculatePadByPadSummaryData(this.items, this.padBuilderPads, this.taxRate);
                this.padBuilderPads.forEach((pad, padIndex) => {
                pad.grandTotal=this.padByPadData.find(i=>i.afeType==='Grand Total')?.padsCost[padIndex];
                });
                this.changeDetector.markForCheck();
              } 
            });
      });
    });

    this.scenarioStoreService.year$.subscribe(data => {
      if (data) {
        this.costYear = data.toString();
      }
    });

    this.scenarioStoreService.scenarioPadBracketCosts$.subscribe(scenarioPadBracketCost => {
      if (scenarioPadBracketCost.length > 0) {
        if (this.scenarioStoreService.scenario) {
          //Costs will change only when selected pad year is different from scenario year
          if (this.selectedPad && this.selectedPad.yearCost != this.scenarioStoreService.scenario.costYear) {
            const selectedPadCosts = scenarioPadBracketCost.filter(o => o.yearCost === this.selectedPad.yearCost);
            this.inputInfraData.map(item => {
              let itemDetailId = null;

              //facility type is present(drop down in Detail col)
              //means we have to display the cost of the facility item with the type selected in Detail dropdown
              if (item && item.itemDetail && item.itemDetail.id) {
                itemDetailId = item.itemDetail.id;
              }

              const itemCost = selectedPadCosts.find(o => o.facilityItemId === (itemDetailId ? itemDetailId : item.id));
              if (itemCost) {
                if (itemCost.costOverride) {
                  item.costPunit = itemCost.costOverride;
                  item.costUnitOverride = 1;
                } else {
                  item.costPunit = itemCost.cost;
                  item.costUnitOverride = null;
                }
              }
            });

            this.changeDetector.markForCheck();
          } else {
            this.changeDetector.markForCheck();
          }
        }
      }
    });
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }
  public ngAfterViewInit(): void {
    const aThis = this;
    const scrollableElementGrid = document.querySelectorAll('app-input-items .k-grid-content.k-virtual-content')[0];
    if (scrollableElementGrid) {
      scrollableElementGrid.addEventListener('scroll', function(e) {
        if (
          aThis.gridScrollTop !== this.scrollTop &&
          aThis.grid.activeCell &&
          document.activeElement instanceof HTMLElement
        ) {
          document.activeElement.blur();
          if (aThis.grid.isEditingCell()) {
            aThis.grid.closeCell();
          }
        }
        aThis.gridScrollTop = this.scrollTop;
      });
    }
  }

  public ngAfterViewChecked(): void {
    const focusedCell = document.querySelector('app-input-items td.k-state-focused');
    if (focusedCell) {
      const focusedCellTemplate = document.querySelector('app-input-items td .focused-cell');
      if (focusedCellTemplate) {
        focusedCellTemplate.classList.remove('focused-cell');
      }
      if (focusedCell.children[0]) {
        focusedCell.children[0].classList.add('focused-cell');
      }
    } else {
      const focusedCellTemplate = document.querySelector('app-input-items td .focused-cell');
      if (focusedCellTemplate) {
        focusedCellTemplate.classList.remove('focused-cell');
      }
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  public cellClickHandler(args: any) {
    if (this.grid.isEditingCell()) {
      this.grid.closeCell();
    }
    if (args.column.field === undefined) {
      return;
    }
    if (
      this.cellClass(args.dataItem, args.column.field, args.column.parent === this.padCountsColumnGroupName) ===
      'non-editable-cell'
    ) {
      return;
    }
    if (!args.isEdited && this.canEditCnd) {
      args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem, args.column.field));
      const aThis = this;
      setTimeout(() => {
        if (aThis.dropDownEditor) {
          aThis.dropDownEditor.toggle(true);
        }
      }, 0);
    }
  }
 

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      args.preventDefault();
    } else if (formGroup.dirty) {
      const columnEdited = Object.keys(formGroup.value)[0];
      const columnGroup = args.column.parent ? args.column.parent.title : null;
      let itemName = args.dataItem.name;
      if (columnEdited === 'itemDetail') {
        if (typeof formGroup.value.itemDetail === 'string') {
          if (!dataItem.itemDetail) {
            dataItem.itemDetail = {};
          }
          dataItem.itemDetail.displayName = formGroup.value.itemDetail;
        } else {
          const dataitemId = clonedeep(dataItem.id);
          Object.assign(dataItem, formGroup.value);
          dataItem.id = formGroup.value.itemDetail.id;
          this.scenarioStoreService.isUpdateItemDetailLoading.subscribe(
            (isLoaded: boolean) => {
              this.isLoading = isLoaded;
            },
            (error: any) => {
              this.isLoading = false;
            }
          );
          this.scenarioStoreService
            .updateChildItemDetails(dataitemId, dataItem.itemDetail.id, dataItem.respToExpandFacilityItemId)
            .then(isSuccess => {
              if (isSuccess) {
                this.scenarioStoreService.updateItemDetail(
                  dataItem.name,
                  dataItem.id,
                  dataItem.itemDetail.id,
                  formGroup.value.costPunit,
                  formGroup.value.itemDetail.itemDetailNotes
                );
              }
            });
        }
      } else if (columnEdited === 'costPunit') {
        if (this.alternatePadCosts) {
          this.scenarioStoreService.setScenarioPadBracketOverrideCost(
            dataItem.itemDetailId,
            this.selectedPad.yearCost,
            formGroup.value.costPunit
          );
        } else {
          // If the costPunit was changed in the UI, check default value.
          Object.assign(dataItem, formGroup.value);
          this.scenarioStoreService.isUpdateItemDetailLoading.subscribe(
            (isLoaded: boolean) => {
              this.isLoading = isLoaded;
            },
            (error: any) => {
              this.isLoading = false;
            }
          );
          this.scenarioStoreService.updateItemCostPunit(
            dataItem.name,
            dataItem.id,
            dataItem.itemDetail.id,
            formGroup.value.costPunit
          );
        }
      } else if (!columnGroup || columnGroup !== this.padCountsColumnGroupName) {
        Object.assign(dataItem, formGroup.value);
        this.scenarioStoreService.updatedInfraItemsGrid();
      } else {
        if (!itemName) {
          itemName = args.dataItem.displayName;
          this.scenarioStoreService.updateAdditionalItemCount(columnEdited, itemName, formGroup.value[columnEdited]);
        } else {
          this.scenarioStoreService.updatePadItemCount(columnEdited, itemName, dataItem, formGroup.value[columnEdited]);
        }
      }
    }
  }

  // Releted to the feature of cell to cell Entry using tab
  public createFormGroup(dataItem: any, column: string, tab: boolean = false): UntypedFormGroup {
    const controlsConfig = {};
    let formGroup;
    const i = parseInt(column, null);
    if (tab) {
      if (!isNaN(i)) {
        if (i >= 13) {
          const j = this.padBuilderPads.slice(0 + (i - 13), i - 13 + 1);
          column = j[0].padName;
        } else if (i < 12) {
          switch (i) {
            case 0: {
              column = 'isCloned';
              break;
            }
            case 1: {
              column = 'isVisible';
              break;
            }
            case 2: {
              column = 'displayName';
              break;
            }
            case 3: {
              column = 'itemDetail';
              break;
            }
            case 4: {
              column = 'unitOfMeasure.name';
              break;
            }
            case 5: {
              column = 'costPunit';
              break;
            }
            case 6: {
              column = 'infrastructureClass.name';
              break;
            }
            case 7: {
              column = 'location.name';
              break;
            }
            case 8: {
              column = 'infraestructureScopeCategory.name';
              break;
            }
            case 9: {
              column = 'rfsd';
              break;
            }
            case 10: {
              column = 'kind.name';
              break;
            }
            case 11: {
              column = 'userCategory';
              break;
            }
            case 12: {
              column = 'total';
              break;
            }
          }
        }
      }
    }

    if (column === 'itemDetail' && (!dataItem.itemDetail || !dataItem.itemDetail.name)) {
      controlsConfig['itemDetail'] = '';
    } else {
      const dotIndex = column.indexOf('.');
      if (dotIndex > -1) {
        const complexColumnName = column.substring(0, dotIndex);
        controlsConfig[complexColumnName] = dataItem[complexColumnName];
      } else {
        setByPath(controlsConfig, column, getByPath(dataItem, column));
      }
    }

    formGroup = this.formBuilder.group(controlsConfig);
    return formGroup;
  }

  public isNonEditableItem(fieldName: string): boolean {
    return this.nonEditableFields.includes(fieldName);
  }

  public isEditableOutputItem(fieldName: string): boolean {
    return this.editableOutputFields.includes(fieldName);
  }

  public isNonEditableColumn(columnName: string): boolean {
    return this.nonEditableColumns.includes(columnName);
  }

  public isFieldOverridden(padName: string, itemName: string, itemId: string): boolean {
    if (this.isEditableOutputItem(itemName)) {
      return this.overriddenFieldsService.isPadItemCountOverridden(padName, itemName, itemId);
    } else {
      return false;
    }
  }

  public cellClass(dataItem, columnName, isItemCount) {
    if (dataItem.name === 'CTB Equipment') {
      return 'non-editable-cell';
    }
    if (dataItem.name === 'Compressor Installs') {
      return 'non-editable-cell';
    }
    if (columnName === 'costPunit' && this.highlightColumn) {
      return 'highlight-column';
    }
    if (
      (dataItem.name === 'CTB Brownfield Adder' && columnName == 'costPunit') ||
      (dataItem.name === 'Satellite Brownfield Adder' && columnName == 'costPunit') ||
      (dataItem.name === 'SWD Brownfield Adder' && columnName === 'costPunit')
    ) {
      return 'non-editable-cell';
    }
    if (!dataItem.name && columnName !== 'total') {
      return 'editable-cell';
    }
    if (columnName === 'expandCollapse' || columnName === 'cloneDelete') {
      return 'non-editable-cell';
    }
    if (columnName === 'itemDetail') {
      if (!dataItem.itemDetails || dataItem.itemDetails.length <= 1) {
        return 'non-editable-cell';
      }
    }
    if (this.nonEditableColumns.indexOf(columnName) > -1) {
      return 'non-editable-cell';
    }
    if (this.isNonEditableItem(dataItem.name)) {
      return 'non-editable-cell';
    } else if (isItemCount && this.isEditableOutputItem(dataItem.name)) {
      return 'editable-output-cell';
    } else {
      return 'editable-cell';
    }
  }

  public getPadTestLocation(padName: string): string {
    const pad = this.pads.find(p => p.padName === padName);
    if (pad) {
      return pad.wellTestLocation;
    } else {
      return '';
    }
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.state.take = this.state.take ? null : this.state.take;
    this.gridView = process(this.inputInfraData, this.state);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  // Behavior for the column reorder, drag and drop the column and reorder the two Grids (Scope Counts and Facility Assigment) are connected
  public onColumnReorder(columnReorderEvent: ColumnReorderEvent): void {
    const columnGroup = columnReorderEvent.column.parent ? columnReorderEvent.column.parent.title : null;
    if (columnGroup && columnGroup === this.padCountsColumnGroupName) {
      this.scenarioStoreService.reorderPadbuilderPad(
        columnReorderEvent.column['field'],
        columnReorderEvent.oldIndex,
        columnReorderEvent.newIndex
      );
    }
  }

  public trackIndex(index: number): any {
    return index;
  }

  public isCostOverridden(dataItem: any): boolean {
    if (dataItem !== null && dataItem !== undefined) {
      if (
        dataItem.costUnitOverride !== undefined &&
        dataItem.costUnitOverride !== null &&
        this.scenarioStoreService.isAdditionalItem(dataItem.id) === false
      ) {
        return dataItem.costUnitOverride === 1 ? true : false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public cellClassExpandCollapse(dataItem: any): any {
    let vclass: string;
    if (!dataItem !== undefined) {
      if (dataItem.isExpanded === false) {
        vclass = 'k-icon item-custom-icon-class-arrow-right';
      } else {
        vclass = 'k-icon item-custom-icon-class-arrow-down';
      }
      return vclass;
    }
  }

  public cellClassCloneDelete(dataItem: any): any {
    let vclass: string;
    if (dataItem !== undefined && dataItem.displayName) {
      if (dataItem.isCloned === true || dataItem.name == '') {
        vclass = 'k-icon k-i-delete k-i-trash';
      } else if (this.cellClass(dataItem, 'itemDetail', false) === 'editable-cell') {
        vclass = 'k-icon k-i-copy k-i-files';
      }
      return vclass;
    }
  }

  public isHiddenClonedDeleted(dataItem: any): boolean {
    if (dataItem !== undefined) {
      if (this.excludeCopy.includes(dataItem.displayName)) {
        return true;
      }
      if (dataItem.respToExpandFacilityItemId !== Guid.EMPTY) {
        return true;
      }
      //visible only for clone button and delete button
      if (dataItem.isCloned != undefined && dataItem.isCloned != null && dataItem.isCloned == true) {
        //cloned rows -> display delete button
        return false;
      } else if (
        dataItem.displayName &&
        this.cellClass(dataItem, 'itemDetail', false) === 'editable-cell' &&
        dataItem.respToExpandFacilityItemId === Guid.EMPTY
      )
        //Detail column can be edited =>- display Clone button
        return false;
      else return true;
    } else {
      return true;
    }
  }

  public isHiddenExpandCollapse(dataItem: any): boolean {
    if (dataItem !== undefined) {
      if (this.isMultipleFilterActivaded) {
        return true;
      } else {
        if (dataItem.id === Guid.EMPTY && dataItem.itemDetailId === Guid.EMPTY) {
            return true;
        } 
        else if(this.inputInfraData.filter(x=>x.respToExpandFacilityItemId == dataItem.id ?? dataItem.itemDetailId).length > 0)
        {
          return false;
        }
        else
        {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  // Ressponsible of change the values for expand an collapse changing the isExpanded propertie.
  public expandCollapseHandler({ sender, rowIndex, dataItem }) {
    if (
      dataItem !== undefined &&
      dataItem !== null &&
      dataItem.respToExpandFacilityItemId !== undefined &&
      dataItem.respToExpandFacilityItemId !== null
    ) {
      this.scenarioStoreService.updateItemIsVisible(dataItem.name, dataItem.id, dataItem.isExpanded);
    }
  }

  public expandCollapseAllHandler() {     
      this.isHeaderBtnExpandend = !this.isHeaderBtnExpandend;
      this.scenarioStoreService.updateAllItemIsVisible(this.isHeaderBtnExpandend); 
  }

  // Used to filter, if one or more filter are activated, the app cancel the filter of isVisible just to display all the items filtered
  // become visible or hidden according to the logic applied by the filters.
  public filterChange(e) {
    if (e.filters !== undefined) {
      if (e.filters.length === 0) {
        const addFilter = {
          field: 'isVisible',
          operator: 'eq',
          value: 1,
        };
        this.isMultipleFilterActivaded = false;
        e.filters.push(addFilter);
      } else {
        this.isMultipleFilterActivaded = true;
        e.filters = e.filters.filter(f => f.field !== 'isVisible');
      }
      this.gridView = process(this.inputInfraData, {
        filter: {
          logic: 'and',
          filters: e.filters,
        },
      });
    }
  }

  public getScenarioStoreService() {
    return this.scenarioStoreService;
  }

  public headerBtnCellClassIconPlus() {
    return 'k-icon item-custom-icon-class-plus';
  }

  public onPadCommentClick(pad: string) {
    if (pad !== undefined && pad !== null && pad !== '') {
      this.dialogOpenedPadComment = true;
      this.padNameForComment = pad;
      this.createNewFormGroup(this.getPadComments(pad));
    }
  }

  public closeDialogPadComment(): void {
    this.dialogOpenedPadComment = false;
    this.padNameForComment = '';
    this.formComment.reset();
  }

  public setPadComment(value: any, valid: boolean): void {
    if (valid) {
      this.scenarioStoreService.setPadComment(this.padNameForComment, value.padComment);
      this.closeDialogPadComment();
    }
  }

  public createNewFormGroup(comment: any) {
    this.formComment = new UntypedFormGroup({
      padComment: new UntypedFormControl(comment, Validators.maxLength(1000)),
    });
  }

  public getPadComments(padName: string): string {
    const pad = this.pads.find(p => p.padName === padName);
    if (pad) {
      return pad.comments;
    } else {
      return '';
    }
  }

  public hideRows(data: any[]): any[] {
    const inputItems = data.filter(ar => !this.hiddenRows.find(rm => rm === ar.name));

    return inputItems;
  }

  private loadItems(): void {
    if (!this.state.filter || !this.state.filter.filters || this.state.filter.filters.length === 0) {
      this.gridView = {
        data:
          this.newScenario === false
            ? orderBy(this.inputInfraData.slice(this.skip, this.skip + this.pageSize), this.sort)
            : [],
        total: this.newScenario === false ? this.inputInfraData.length : 0,
      };
    } else if (this.newScenario === false) {
      this.state.take = this.state.take ? null : this.state.take;
      this.gridView = process(this.inputInfraData, this.state);
    }
  }

  public hideController(dataItem) {
    if (dataItem.name === 'CTB Equipment' || dataItem.name === 'Compressor Installs') {
      return true;
    }
    return false;
  }

  public loadPrice(pad, item) {
    const padItemCount = item[pad.padName];
    const itemCostPunit = item.costPunit;
    const padRef = this.pads.find(p => p.padName === pad.padName);
    let padContingency;
    if (padRef != null && padRef != undefined) {
      padContingency = padRef.padContingency;
    } else {
      padContingency = pad.padContingency;
    }

    if (
      item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
      item.name === 'Cost Adjustment/Allowance: General Infr' ||
      item.name === 'Cost Adjustment/Allowance: Water Infr' ||
      item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
      item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
    ) {
      const cost = this.scenarioStoreService.getItemCostPadByLogic(
        pad.padName,
        item.name,
        item.id,
        padItemCount,
        itemCostPunit,
        pad.yearCost,
        this.alternatePadCosts,
        this.originalInputInfraData
      );

      return this.intl.formatNumber(cost, 'n2');
    }

    if (padContingency) {
      const cost =
        this.scenarioStoreService.getItemCostPadByLogic(
          pad.padName,
          item.name,
          item.id,
          padItemCount,
          itemCostPunit,
          pad.yearCost,
          this.alternatePadCosts,
          this.originalInputInfraData
        ) *
        (1 + this.taxRate) *
        (1 + padContingency);

      return this.intl.formatNumber(cost, 'n2');
    } else {
      const cost =
        this.scenarioStoreService.getItemCostPadByLogic(
          pad.padName,
          item.name,
          item.id,
          padItemCount,
          itemCostPunit,
          pad.yearCost,
          this.alternatePadCosts,
          this.originalInputInfraData
        ) *
        (1 + this.taxRate);
      return this.intl.formatNumber(cost, 'n2');
    }
  }

  public onEditPadOpenClick(padIdSelected: string): void {
    this.openEditPads.next({ padId: padIdSelected, open: true });
  }

  public clickPadBracket(pad: any): void {
    //No Pad Selected and highlight off
    if (!this.selectedPad && !this.highlightColumn) {
      this.selectedPad = pad;
      this.highlightColumn = true;
    }
    //another pad is selected
    else if (this.selectedPad !== pad) {
      this.selectedPad = pad;
    }
    //same pad is selected
    else if (this.selectedPad == pad) {
      this.highlightColumn = false;
      this.selectedPad = null;
    }
    this.loadPadBracketCost(this.selectedPad);
  }

  public loadPadBracketCost(pad: any): void {
    if (pad && !this.sameScenarioYear(pad)) {
      this.alternatePadCosts = true;
      this.scenarioStoreService.scenarioPadBracketCostsBs$.next(
        this.scenarioStoreService.scenarioPadBracketCostsBs$.value
      );
    } else {
      this.loadDefaultScenarioCosts();
      this.alternatePadCosts = false;
    }
  }

  public loadDefaultScenarioCosts() {
    this.inputInfraData.map(item => {
      const itemCost = this.originalInputInfraData.find(o => o.id === item.id);
      if (itemCost) {
        item.costPunit = itemCost.costPunit;
        item.costUnitOverride = itemCost.costUnitOverride;
      }
    });
  }

  public sameScenarioYear(pad: any): boolean {
    return pad ? pad.yearCost.toString() === this.costYear : false;
  }

  public resetPadCostVariables() {
    this.alternatePadCosts = false;
    this.highlightColumn = false;
    this.selectedPad = null;
    this.originalInputInfraData = [];
    this.initialLoadInfra = true;
  }

  async cloneOrDelete(dataItem: any, rowIndex: any) {
    if (dataItem) {
      let result = '';
      if (dataItem.isCloned || dataItem.name == '') {
        //delete the data at the existing row...
        result = await this.scenarioStoreService.updateInfrastructureItemDetails(dataItem, rowIndex, 'Delete');
      } //clone the data to the next line
      else {
        result = await this.scenarioStoreService.updateInfrastructureItemDetails(dataItem, rowIndex, 'Clone');
      }
      if (result.length > 0) {
        this.showWarning(result);
      }
    }
  }

  public showWarning(value: string) {
    this.notificationService.show({
      content: value,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 500 },
      type: { style: 'warning', icon: true },
    });
  }


  public calculatePadByPadSummaryData(inputItems: any[], pPads: any[], vtax: number): any[] {
    const padByPadSummaryData = [];
    const scenarioAfeTypeData = [];
    const map = new Map();
    if (!pPads) {
      pPads = [];
    }
    if (vtax !== null && vtax !== undefined) {
      inputItems.forEach(item => {
        if (!map.has(item.scenarioAfeType.id) && item.scenarioAfeType.name !== '' && item.name !== '') {
          map.set(item.scenarioAfeType.id, true);
          if (item.scenarioAfeType.name != null) {
            scenarioAfeTypeData.push({

              id: item.scenarioAfeType.id,
              name: item.scenarioAfeType.name,
              displayName: item.scenarioAfeType.displayName,
            });
          }
        }
      });

      const padGrandTotalCost: number[] = [];
      scenarioAfeTypeData.forEach(afeType => {
        const items = inputItems.filter(item => item.scenarioAfeType.id === afeType.id);

        const padsCost = [];
        pPads.forEach((pad, padIndex) => {
          if (!padGrandTotalCost[padIndex]) {
            padGrandTotalCost[padIndex] = 0;
          }
          let padItemsCost = 0;
          items.forEach(item => {
            const padName = pad.padName;
            let padContingency;
            const tmpPadWithContingency = this.pads.find(o => o.padName === pad.padName);
            if (tmpPadWithContingency !== null && tmpPadWithContingency !== undefined) {
              padContingency = tmpPadWithContingency.padContingency;
            } else {
              padContingency = pad.padContingency;
            }
            const padItemCount = item[padName];
            const itemCostPunit = item.costPunit;
            let itemCost;

            if (
              item.name === 'Cost Adjustment/Allowance: Gas Infr' ||
              item.name === 'Cost Adjustment/Allowance: General Infr' ||
              item.name === 'Cost Adjustment/Allowance: Water Infr' ||
              item.name === 'Cost Adjustment/Allowance: Shared Infr' ||
              item.name === 'Cost Adjustment/Allowance: Wellsite Infr'
            ) {
              itemCost = this.scenarioStoreService.getItemCostPadByLogic(
                pad.padName,
                item.name,
                item.id,
                padItemCount,
                itemCostPunit,
                pad.yearCost,
                this.alternatePadCosts,
                this.originalInputInfraData
              );
            } else {
              itemCost =
                this.scenarioStoreService.getItemCostPadByLogic(
                  pad.padName,
                  item.name,
                  item.id,
                  padItemCount,
                  itemCostPunit,
                  pad.yearCost,
                  this.alternatePadCosts,
                  this.originalInputInfraData
                ) *
                (1 + vtax) *
                (1 + padContingency);
            }
            itemCost = isNaN(itemCost) ? 0 : itemCost;
            padItemsCost += itemCost; // changed to $M, us:224479;
          });
          padGrandTotalCost[padIndex] += padItemsCost;
          padsCost.push(padItemsCost);
        });
        padByPadSummaryData.push({
          afeType: afeType.displayName,
          padsCost,
        });
      });

      padByPadSummaryData.push({
        afeType: 'Grand Total',
        padsCost: padGrandTotalCost,
      });
    }

    return padByPadSummaryData;
  }

  public headerCellClassExpandCollapse() {
    if (this.isHeaderBtnExpandend === false) {
      return 'k-icon item-custom-icon-class-arrow-right';
    } else {
      return 'k-icon item-custom-icon-class-arrow-down';
    }
  }

  public showMessage(value: string) {
    
   let notificationInstance = this.notificationService.show({
      content: value,
      position: { horizontal: 'right', vertical: 'bottom' },
      type: { style: 'success', icon: true },
      appendTo : this.notificationPlaceHolder
    });

    setTimeout(() => {
      notificationInstance.notification?.destroy();
    }, 500);
  }
}
