<button kendoButton *ngIf="!dialogOpened" (click)="openDialog()" [disabled]="!canEditCnd">
  Get PadBuilder Data
</button>

<button
  kendoButton
  *ngIf="!dialogSmartOpened"
  (click)="openSmarTImportDialog()"
  [disabled]="!canEditCnd"
  style="margin:5px"
>
  Import from SMART Data
</button>

<button kendoButton (click)="openDialogPad()" [disabled]="copyPadButtonDisabled" style="margin:5px">
  Copy Pads
</button>

<button kendoButton (click)="openDeletePadDialog()" [disabled]="copyPadButtonDisabled" style="margin:5px">
  Delete Pads
</button>

<button kendoButton (click)="openActiveForOutlookDialog()" [disabled]="copyPadButtonDisabled" style="margin:5px">
  Select Active for Outlook
</button>

<button kendoButton (click)="refreshSmarTImportPadsDialog()" [disabled]="copyPadButtonDisabled" style="margin:5px">
  Refresh Pads
</button>
