import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../../../services';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { IPAndEProjectBod } from '../_models/pAndE-project-bod';
import { IPAndEProjectCostEstimates } from '../_models/pAndE-project-cost-estimates';
import { PAndEProjectBodStatusService } from './p-and-e-project-bod-status.service';
import { PerformanceConfigurationService } from 'src/app/services/performance-configuration-service';
import { IPAndEProjectBodStatus } from '../_models/pAndE-project-bod-status';
import { Globals } from 'src/app/_shared/Globals';
import { IPAndEProjectCostSignatures } from '../_models/pAndE-project-cost-signatures';
import { PAndESignaturesService } from '../_services/p-and-e-signatures.service';

@Injectable({
  providedIn: 'root',
})
export class PAndEProviderService {
  public baseUrlPAndEProjectBod: string = environment.PacerDomainURL + 'PAndEProjectBod';
  public baseUrlPAndEProjectCostEstimates: string = environment.PacerDomainURL + 'PAndEProjectCostEstimates';
  public pAndEProjectBod$: Observable<IPAndEProjectBod>;
  public pAndEProjectCostEstimates$: Observable<IPAndEProjectCostEstimates[]>;
  public isReadOnlyFacility$: Observable<boolean>;
  private pAndEProjectBodValue$: BehaviorSubject<IPAndEProjectBod>;
  private pAndEProjectCostEstimatesValue$: BehaviorSubject<IPAndEProjectCostEstimates[]>;
  private isReadOnlyFacilityValue$: BehaviorSubject<boolean>;
  private handleError: HandleError;
  private signaturesList: IPAndEProjectCostSignatures[] = [];

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private authenticationService: AuthenticationService,
    private pAndEProjectBodStatusService: PAndEProjectBodStatusService,
    private pAndESignaturesService: PAndESignaturesService
  ) {
    this.pAndEProjectBodValue$ = new BehaviorSubject<IPAndEProjectBod>(null);
    this.pAndEProjectBod$ = this.pAndEProjectBodValue$.asObservable();
    this.pAndEProjectCostEstimatesValue$ = new BehaviorSubject<IPAndEProjectCostEstimates[]>([]);
    this.pAndEProjectCostEstimates$ = this.pAndEProjectCostEstimatesValue$.asObservable();
    this.handleError = httpErrorHandler.createHandleError();
    this.isReadOnlyFacilityValue$ = new BehaviorSubject<boolean>(true);
    this.isReadOnlyFacility$ = this.isReadOnlyFacilityValue$.asObservable();
  }

  public initialize(id: string) {
    this.pAndEProjectBodStatusService.initialize(id);

    const getgetPAndEProjectBod = this.getPAndEProjectBodById(id).pipe(
      map((pAndEProjectBod: IPAndEProjectBod) => {
        this.pAndEProjectBodValue$.next(pAndEProjectBod);
      }),
      take(1)
    );

    const getThenPAndEProjectCostEstimates = this.getPAndEProjectCostEstimatesByPAndEBodId(id).pipe(
      map((facilityProjectCostEstimates: IPAndEProjectCostEstimates[]) => {
        this.pAndEProjectCostEstimatesValue$.next(facilityProjectCostEstimates);
      }),
      take(1)
    );

    concat(getgetPAndEProjectBod, getThenPAndEProjectCostEstimates)
      .toPromise()
      .then(() => {
        this.pAndESignaturesService.next();
      });

    this.pAndESignaturesService.pAndEProjectCostSignatures$.subscribe(
      (signatures: IPAndEProjectCostSignatures[]) => {
        this.signaturesList = signatures;
        this.setFacilityVisibility();
      }
    );
  }

  public setFacilityBodValue(value: any) {
    this.pAndEProjectBodValue$.next(value);
  }

  public cleanObservables(): void {
    this.pAndEProjectBodValue$.next(null);
    this.pAndEProjectCostEstimatesValue$.next([]);
  }

  private setFacilityVisibility(): void {
    const pAndEProjectCostEstimates = this.pAndEProjectCostEstimatesValue$.value;
    if (pAndEProjectCostEstimates) {
      if (pAndEProjectCostEstimates.length > 0) {
        const projectEstimate = pAndEProjectCostEstimates[0];
        const createdBy = !projectEstimate.createdBy ? '' : projectEstimate.createdBy.toLowerCase();
        const updatedBy = !projectEstimate.updatedBy ? '' : projectEstimate.updatedBy.toLowerCase();
        const executionPmUserId = !projectEstimate.executionPmUserId
          ? ''
          : projectEstimate.executionPmUserId.toLowerCase();
        const performancePmUserId = !projectEstimate.performancePmUserId
          ? ''
          : projectEstimate.performancePmUserId.toLowerCase();
        const instrumentationElectricalPmUserId = !projectEstimate.instrumentationElectricalPmUserId
          ? ''
          : projectEstimate.instrumentationElectricalPmUserId.toLowerCase();
        if (
          this.authenticationService.getCurrentUser().toLowerCase() === createdBy ||
          this.authenticationService.getCurrentUser().toLowerCase() === updatedBy ||
          this.authenticationService.getCurrentUser().toLowerCase() === executionPmUserId ||
          this.authenticationService.getCurrentUser().toLowerCase() === performancePmUserId ||
          this.authenticationService.getCurrentUser().toLowerCase() === instrumentationElectricalPmUserId
        ) {
          if (this.signaturesList.length > 0) {
            this.isReadOnlyFacilityValue$.next(true);
          } else {
            this.isReadOnlyFacilityValue$.next(false);
          }
        } else {
          this.isReadOnlyFacilityValue$.next(true);
        }
      }
    }
  }

  private getPAndEProjectBodById(id: string): Observable<IPAndEProjectBod> {
    return this.http
      .get<IPAndEProjectBod>(`${this.baseUrlPAndEProjectBod}/GetGraphByKey/${id}`)
      .pipe(catchError(this.handleError('getPAndEProjectBodById')));
  }

  private getPAndEProjectCostEstimatesByPAndEBodId(
    pAndEProjectBodId: string
  ): Observable<IPAndEProjectCostEstimates[]> {
    return this.http
      .get<IPAndEProjectCostEstimates[]>(
        `${this.baseUrlPAndEProjectCostEstimates}/ListByPAndEProjectBodId/${pAndEProjectBodId}`
      )
      .pipe(catchError(this.handleError('getPAndEProjectCostEstimatesByPAndEBodId')));
  }
}
