import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { LocationFields } from '../../models/fit-cc-location-input-fields';
import { FitCcLocationFieldLabels } from '../../models/fit-cc-location-input-labels.enum';

@Component({
  selector: 'fit-cc-location-input',
  templateUrl: './fit-cc-location-input.component.html',
  styleUrls: ['./fit-cc-location-input.component.scss'],
})
export class FitCcLocationInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  locationForm: FormGroup;

  @Input()
  get value(): LocationFields {
    let location = this.locationForm.value;
    if (location.longitude != null && location.latitude != null) {
      return new LocationFields(location.longitude, location.latitude);
    }
    return null;
  }
  set value(location: LocationFields | null) {
    location = location || new LocationFields(null, null);
    this.locationForm.setValue({ longitude: location.longitude, latitude: location.latitude });
  }

  @Input()
  longitudeLabel: FitCcLocationFieldLabels = FitCcLocationFieldLabels.LONGITUDE_LABEL;

  @Input()
  latitudeLabel: FitCcLocationFieldLabels = FitCcLocationFieldLabels.LATITUDE_LABEL;

  @Input()
  longitudeValidators: ValidatorFn[];

  @Input()
  latitudeValidators: ValidatorFn[];

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  active: boolean;

  @Output()
  onChange: EventEmitter<any>;

  @Output()
  onMarkerClick: EventEmitter<any>;

  private ngUnsubscribe: Subject<void>;
  private isInitFormControl: boolean;
  onModelChange: Function;
  onModelTouched: Function;

  constructor(public ngControl: NgControl, fb: FormBuilder) {
    ngControl.valueAccessor = this;
    this.isInitFormControl = false;
    this.ngUnsubscribe = new Subject<void>();
    this.locationForm = fb.group({
      longitude: null,
      latitude: null,
    });
    this.onChange = new EventEmitter<any>();
    this.onMarkerClick = new EventEmitter<any>();
  }

  ngOnInit() {
    this.locationForm.get('longitude').setValidators(this.longitudeValidators);
    this.locationForm.get('latitude').setValidators(this.latitudeValidators);
    this.locationForm.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(value => this.onChangeEmit(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active) {
      this.active = changes.active.currentValue;
    }
  }

  onChangeEmit(value) {
    if (this.isInitFormControl) {
      this.onModelChange(value);
      this.onChange.emit(value);
    } else {
      this.isInitFormControl = true;
    }
  }

  longitudeHandler(event) {
    this.onChange.emit(event);
  }

  latitudeHandler(event) {
    this.onChange.emit(event);
  }

  iconHandler() {
    this.onMarkerClick.emit(this.ngControl.name);
  }

  writeValue(value: any): void {
    this.locationForm.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
}
