import { Globals } from 'src/app/_shared/Globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEmailModel } from '../_models/email.model';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient, public globals: Globals, public datePipe: DatePipe) {}

  public sendEmail(mail: IEmailModel): Observable<any> {
    const serviceUrl: string = environment.PacerDomainURL + 'Email/Send';

    return this.http.post(serviceUrl, JSON.stringify(mail), httpOptions).pipe();
  }

  public buildEmailMessageBod(bodName: string) {
    return (
      '<h1 style="margin: 0; color: #555555; font-size: 23px;' +
      ' font-family: Arial, Helvetica Neue, Helvetica, sans-serif;' +
      ' line-height: 120%; text-align: center; direction: ltr; font-weight: 700;' +
      ' letter-spacing: normal; margin-top: 0; margin-bottom: 0;">' +
      '<span class="tinyMce-placeholder">Performance BOD project ' +
      bodName +
      ' approved for execution</span></h1>\n' +
      '\n' +
      '<p style="margin: 0;">BOD project ' +
      bodName +
      ' has been approved for execution.</p>\n' +
      '</br>\n' +
      '<p style="margin: 0;"><strong>&nbsp;Please do not reply to this email , its autogenerated</strong></p>'
    );
  }

  public buildEmailMessageSFT(bodName: string, bodStatus: string, userRole: string, userName: string) {
    let action: string = bodStatus ? bodStatus : '';
    let bodurl = window.location.href;
    return (
      '<p style="margin: 0;">SFT project ' +
      bodName +
      ' has been ' +
      action +
      '. Click <a href="' +
      bodurl +
      '">here<a/> to view the BOD details.</p></br>\n' +
      '<table border="1" cellpadding="0" cellspacing="0" style="margin: 0;font-family: Arial, Helvetica Neue, Helvetica, sans-serif;"><tr><th style="text-align: left;padding:5px;"> ' +
      bodStatus +
      ' Date </th><th style = "text-align: left;padding:5px;"> User Role </th><th style ="text-align: left;padding:5px;"> User Name </th></tr><tr><td style="padding:5px">' +
      this.datePipe.transform(new Date(), 'MM/dd/yyyy', 'CST') +
      ' </td><td style="padding:5px";> ' +
      userRole +
      ' </td><td style="padding:5px";> ' +
      userName +
      ' </td></tr></table>\n' +
      '</br>\n' +
      '<p style="margin: 0;"><strong>&nbsp;This is a system generated email. Please DO NOT REPLY to this notification.</strong></p>'
    );
  }

  public buildEmailMessagePnE(pneName: string, pneStatus: string) {
    let action: string = pneStatus ? pneStatus : '';
    return (
      '<h1 style="margin: 0; color: #555555; font-size: 23px;' +
      ' font-family: Arial, Helvetica Neue, Helvetica, sans-serif;' +
      ' line-height: 120%; text-align: center; direction: ltr; font-weight: 700;' +
      ' letter-spacing: normal; margin-top: 0; margin-bottom: 0;">' +
      '<span class="tinyMce-placeholder">P&E project ' +
      pneName +
      ' ' +
      action +
      '</span></h1>\n' +
      '\n' +
      '<p style="margin: 0;">P&E project ' +
      pneName +
      ' has been ' +
      action +
      '.</p>\n' +
      '</br>\n' +
      '<p style="margin: 0;"><strong>&nbsp;Please do not reply to this email , its autogenerated</strong></p>'
    );
  }

  public buildEmailMessageFIT(bodName: string, bodStatus: string) {
    let action: string = bodStatus ? bodStatus : '';
    return (
      '<h1 style="margin: 0; color: #555555; font-size: 23px;' +
      ' font-family: Arial, Helvetica Neue, Helvetica, sans-serif;' +
      ' line-height: 120%; text-align: center; direction: ltr; font-weight: 700;' +
      ' letter-spacing: normal; margin-top: 0; margin-bottom: 0;">' +
      '<span class="tinyMce-placeholder">FIT project ' +
      bodName +
      ' ' +
      action +
      '</span></h1>\n' +
      '\n' +
      '<p style="margin: 0;">FIT project ' +
      bodName +
      ' has been ' +
      action +
      '.</p>\n' +
      '</br>\n' +
      '<p style="margin: 0;"><strong>&nbsp;Please do not reply to this email , its autogenerated</strong></p>'
    );
  }
}
