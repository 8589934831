<div>
  <kendo-grid
    #performanceBodSummary
    [data]="view | async"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    [navigable]="true"
    [resizable]="true"
    [sort]="gridState.sort"
    [sortable]="{ mode: 'multiple' }"
    (dataStateChange)="onStateChange($event)"
    filterable="menu"
    [filter]="filter"
    (filterChange)="handlerFilter($event)"
    class="standard-facilities-summary-draggable"
    [rowClass]="rowCallback"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        kendoButton
        class="k-button"
        [disabled]="!standardFacilitiesSummaryService.hasChanges()"
        (click)="cancelChanges(performanceBodSummary)"
        [primary]="true"
      >
        Cancel
      </button>
    </ng-template>
    <kendo-grid-column title="" field="" [hidden]="true" width="1">
      {{ rowLoaded() }}
    </kendo-grid-column>
    <kendo-grid-column field="facilityTypeName" title="Facility Type" [editable]="false" width="150">
      
    </kendo-grid-column>
    <kendo-grid-column title="Facility Name (All)" field="facilityName" [editable]="false" width="150"> </kendo-grid-column>    
    <kendo-grid-column title="Associated Well pads" field="performanceBodPadsId" [editable]="false" width="150">
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <multicheck-filter
          [isPrimitive]="false"
          [field]="column.field"
          textField="wellPadName"
          valueField="id"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="performanceBodPadsList"
        >
        </multicheck-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getPerformanceBodPads(dataItem.performanceBodPadsId)?.wellPadName }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false" [hidden]="true">
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <multicheck-filter
          [isPrimitive]="false"
          [field]="column.field"
          textField="name"
          valueField="id"
          [filterService]="filterService"
          [currentFilter]="filter"
          [data]="performanceBodPackagesList"
        >
        </multicheck-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getPerformanceBodPackages(dataItem.performanceBodPackagesId)?.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="New/Existing" field="isNew" [editable]="true" width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getIfStdFacilitIsNew(dataItem.isNew) }}
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-column="column"
        let-formGroup="formGroup"
        let-isNew="isNew"
      >
        <kendo-dropdownlist
          [data]="facilityStageList"
          [defaultItem]="{ id: null, name: 'Select ...' }"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('isNew')"
          (valueChange)="onChangeFacilityStage($event, dataItem, formGroup)"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="readyForServiceDate"
      title="Ready for Service Date"
      [editable]="true"
      editor="date"
      format="MM/dd/yyyy"
      [filterable]="filterable"
      filter="date"
      width="150"
    >
      >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ (!dataItem.isNew || dataItem.isNew === 1) ? (dataItem.readyForServiceDate | date: 'MM/dd/yyyy') : 'N/A' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker
          *ngIf="dataItem.isNew === 1"
          [value]="dataItem.readyForServiceDate"
          [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('readyForServiceDate')"
        ></kendo-datepicker>
        <span *ngIf="dataItem.isNew != 1">{{
          (!dataItem.isNew || dataItem.isNew === 1) ? (dataItem.readyForServiceDate | date: 'MM/dd/yyyy') : 'N/A'
        }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="bodLockdownDate"
      title="BOD Lockdown Date"
      [editable]="true"
      editor="date"
      format="MM/dd/yyyy"
      [filterable]="filterable"
      filter="date"
      width="150"
    >
      >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ (!dataItem.isNew || dataItem.isNew === 1) ? (dataItem.bodLockdownDate | date: 'MM/dd/yyyy') : 'N/A' }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-datepicker
          *ngIf="dataItem.isNew === 1"
          [value]="dataItem.bodLockdownDate"
          [format]="'MM/dd/yyyy'"
          [formControl]="formGroup.get('bodLockdownDate')"
        ></kendo-datepicker>
        <span *ngIf="dataItem.isNew != 1">{{
          (!dataItem.isNew || dataItem.isNew === 1) ? (dataItem.bodLockdownDate | date: 'MM/dd/yyyy') : 'N/A'
        }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="trainsCompressorPumpsId" title="Trains/Compressors/Pumps" [editable]="false" 
    [hidden]="true" width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getCtbCsSwD(dataItem.trainsCompressorPumpsId)?.name }}
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-column="column"
        let-formGroup="formGroup"
        let-isNew="isNew"
      >
        <kendo-dropdownlist
          [data]="ctbCsSWDList"
          [defaultItem]="{ name: 'Select item...', id: null }"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('trainsCompressorPumpsId')"
        >
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            {{ getCtbCsSwD(dataItem?.id)?.name }}
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ getCtbCsSwD(dataItem?.id)?.name }}
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="numberOfTrains"
      title="# Trains"
      [editable]="true"
      editor="numeric"
      width="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="numberOfPumps"
      title="# H-Pumps (for SWD only)"
      [editable]="true"
      editor="numeric"
      width="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="numberOfCompressors"
      title="# COOP Compressors (3608) (for CS only)"
      [editable]="true"
      editor="numeric"
      width="150"
    >
    </kendo-grid-column>
    <!--Rental Compressors-->
    <kendo-grid-column-group title="# Rental Compressors (for CS only)" [locked]="false">
      <kendo-grid-column
      field="numberOf3608"
      title="3608"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>
      <kendo-grid-column
      field="numberOf3516"
      title="3516"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
      <kendo-grid-column
      field="numberOf3606"
      title="3606"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
    </kendo-grid-column-group>      
    <kendo-grid-column field="sftDesignYear" title="SFT Design Year" [editable]="true" width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.sftDesignYear }}
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-column="column"
        let-formGroup="formGroup"
        let-isNew="isNew"
      >
        <kendo-dropdownlist
          popupAnchor
          [defaultItem]="''"
          [data]="designYearList"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('sftDesignYear')"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <!--Production Rates-->
    <kendo-grid-column-group title="Production Rates to facility (Total including existing pads)" [locked]="false">
      <kendo-grid-column
      field="productionRatesOil"
      title="Oil"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>
      <kendo-grid-column
      field="productionRatesWater"
      title="Water"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
      <kendo-grid-column
      field="productionRatesGas"
      title="Gas"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
    </kendo-grid-column-group>
    <!--Facility Capacity-->    
    <kendo-grid-column-group title="Facility Capacity (Total including existing pads)" [locked]="false">
      <kendo-grid-column
      field="facilityCapacityOil"
      title="Oil"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>
      <kendo-grid-column
      field="facilityCapacityWater"
      title="Water"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
      <kendo-grid-column
      field="facilityCapacityGas"
      title="Gas"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
    </kendo-grid-column-group>
    <!--Sp. Gravity-->    
    <kendo-grid-column-group title="Sp. Gravity" [locked]="false">
      <kendo-grid-column
      field="spGravityOil"
      title="Oil"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>
      <kendo-grid-column
      field="spGravityWater"
      title="Water"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
      <kendo-grid-column
      field="spGravityGas"
      title="Gas"
      [editable]="true"
      editor="numeric"
      width="150"
      >
      </kendo-grid-column>      
    </kendo-grid-column-group>
    <kendo-grid-column field="sourCorrosiveText" title="Sour/Corrosive?" [editable]="true" width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.sourCorrosiveText }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
        <kendo-dropdownlist
          popupAnchor
          [data]="SourCorrosiveList"
          [textField]="'text'"
          [valueField]="'value'"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('sourCorrosive')"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="numberAfe" editor="text" [editable]="true" title="AFE No." width="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ convertToAfeFormat(dataItem.numberAfe) }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-column="column"
                   let-formGroup="formGroup">
        <kendo-maskedtextbox [value]="dataItem.numberAfe" [mask]="afeMask" [formControl]="formGroup.get('numberAfe')">
        </kendo-maskedtextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    field="longitude"
    title="GPS Longitude"
    [editable]="true"
    editor="numeric"
    width="150"
    >
    </kendo-grid-column>      
    <kendo-grid-column
    field="latitude"
    title="GPS Latitude"
    [editable]="true"
    editor="numeric"
    width="150"
    >
    </kendo-grid-column>                  
    <kendo-grid-column field="pisAssociatedQuestion" 
    title="Are there any PIs associated with this project?" [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="stdDesignQuestion" 
    title="Are there any deviations from Standard Design?" [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="gasRequiredQuestion" 
    title="Gas debottlenecking required?" [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="hdpeQuestion" 
    title="Is FIT installing HDPE piping or does the piping network from CTB to CS or SWD include HDPE?"
    [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="fiberOpticQuestion" 
    title="Will fiber optic communications be installed from the EDS line to the Panel Rack?"
    [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="blmProjectQuestion" 
    title="BLM Project?" [editable]="true" width="150"> </kendo-grid-column>
    <kendo-grid-column field="facilityLocationQuestion"  width="150"
    title="Is the facility location close to any public receptors (e.g. residences, offices, buildings, etc.) or 3rd party Operator facilities within 1/4 mile? " [editable]="true"> </kendo-grid-column>
    <kendo-grid-column field="comments" title="Comments" [editable]="true"  width="150"> </kendo-grid-column>
  </kendo-grid>
</div>
