import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceBodNotesAttachment } from 'src/app/api/performance-bod-notes-attachment';
import { IPerformanceBodNotes } from 'src/app/api/performance-bod-notes';
import { IFileInfo } from 'src/app/api/fileInfo';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class SummaryNotesAttachmentEditService extends BehaviorSubject<any[]> {

  public createdItems: IPerformanceBodNotesAttachment[] = [];
  public performanceBodId: string;
  public performanceBodNotesId: string;
  public deletedAttachments: IFileInfo[] = [];
  public data: IPerformanceBodNotesAttachment[] = [];
  public wasModified = new EventEmitter<any>();
  private originalData: IPerformanceBodNotesAttachment[] = [];  
  private updatedItems: IPerformanceBodNotesAttachment[] = [];
  private deletedItems: IPerformanceBodNotesAttachment[] = [];  

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodStoreService: PerformanceBodStoreService
  ) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
    // for Lines with API Call, check kendo
    // Lines with observable
    this.performanceBodStoreService.performanceBodNotesAttachmentData$
    .subscribe((performanceBodNotesAttachment:IPerformanceBodNotesAttachment[]) => {
      this.data = performanceBodNotesAttachment;
      this.originalData = cloneData(performanceBodNotesAttachment);
      super.next(performanceBodNotesAttachment);
    });
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (this.deletedItems.length) {
      this.performanceBodDataService.removeAttachments(this.deletedAttachments).subscribe();
      completed.push(this.performanceBodDataService.deletePerformanceBodNotesAttachment(this.deletedItems).subscribe(() => {
        this.wasModified.emit(true);
      }));
    }

    if (this.updatedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceBodNotesAttachment(this.updatedItems).subscribe(() => {
        this.wasModified.emit(true);
      }));
    }

    if (this.createdItems.length) {

      completed.push(this.performanceBodDataService.createPerformanceBodNotesAttachment(this.createdItems).subscribe(() => {
        this.wasModified.emit(true);
      }));
    }

    this.reset();
    zip(...completed).subscribe(() => this.read());
  }

  public cancelChanges(): void {
    this.reset();

    // for Lines with API Call, check kendo
    // Line with observable
    this.performanceBodStoreService.retrieveNotesAttachmentByPerformanceBodNotesId(this.performanceBodNotesId);
  }

  public createWithAttachments(notes: IPerformanceBodNotes[]): Observable<any> {
    const toCreate = notes.map((item) => {
      return {
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        description: item.description,
        isDeleted: item.isDeleted,
        name: item.name,
        performanceBodId: item.performanceBodId,
        updatedBy: item.updatedBy,
        updatedDate: item.updatedDate
      };
    });

    return this.performanceBodDataService.createWithAttachments(toCreate);
  }


  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  public reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
    this.deletedAttachments = [];
  }
}
