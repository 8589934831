import { Injectable } from '@angular/core';
import { BehaviorSubject, zip } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ScenarioStoreService } from 'src/app/services/scenario-store.service';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class CostByPadDialogService extends BehaviorSubject<any[]> {
  public data: any[] = [];
  public originalData: any[] = [];
  public createdItems: any[] = [];
  public updatedItems: any[] = [];
  public deletedItems: any[] = [];
  public completed: boolean = false;
  public scenarioID: string;
  private applySetOrder = false;

  constructor(private scenarioStoreService: ScenarioStoreService, private dataService: DataService) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }
    if (this.completed) {
      return super.next(this.data);
    }

    this.scenarioStoreService.scenarioID$.subscribe(scenarioId => {
      this.scenarioID = scenarioId;
      this.dataService.getScenarioPadBracketByScenarioId(scenarioId).subscribe(data => {
        this.data = data;
        this.originalData = cloneData(data);
        super.next(data);
      });
    });
  }

  public getPadData() {
    return this.dataService.getScenarioPadBracketByScenarioId(this.scenarioID);
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length || this.updatedItems.length || this.createdItems.length || this.applySetOrder
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    // Apply order
    this.setOrder();

    const completed = [];
    // if (this.deletedItems.length) {
    //   completed.push(this.infrastructureBodEdsService.updateInfrastructureBodEds(this.deletedItems));
    // }

    if (this.updatedItems.length) {
      completed.push(this.dataService.processScenarioPadCost(this.updatedItems));
    }

    // if (this.createdItems.length) {
    //   completed.push(this.infrastructureBodEdsService.createInfrastructureBodEds(this.createdItems));
    // }

    this.reset();
    zip(...completed).subscribe(() => {
      this.completed = true;
      this.read();
    });
  }

  public cancelChanges(): void {
    this.reset();

    this.read();
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  protected setOrder(): void {
    if (this.applySetOrder) {
      if (this.data.length > 0) {
        const obj = this.data[0];
        if (!obj.hasOwnProperty('sortOrder')) {
          return;
        }
      } else {
        // nothing to be order it
        return;
      }
      let order = 0;
      for (const dataItem of this.data) {
        dataItem.sortOrder = order;
        this.update(dataItem);
        order++;
      }
    }
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
    this.applySetOrder = false;
  }

  public setNewOriginalData() {
    this.originalData = cloneData(this.data);
  }
}
