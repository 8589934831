export interface InputItem {
  name: string;
  displayName: string;
  itemNotes: string;
  unitOfMeasure: {
    id: string;
    name: string;
    displayName: string;
  };
  costPunit?: number;
  userCategory: string;
  total?: number;
  infraestructureScopeCategoryId: string;
  itemTypeId: string;
  itemDetailId: string;
  locationId: string;
  rfsdTimingId: string;
  kindId: string;
  scenarioAfeTypeId?: string;
  infraestructureScopeCategory: {
    id: string;
    name: string;
    displayName: string;
    infrastructureClassId: string;
  };
  infrastructureClass: {
    id: string;
    name: string;
    displayName: string;
  };
  location: {
    id: string;
    name: string;
    displayName: string;
  };
  rfsd: {
    id: string;
    name: string;
    displayName: string;
  };
  kind: {
    id: string;
    name: string;
    displayName: string;
  };
  scenarioAfeType: {
    id: string;
    name: string;
    displayName: string;
  };
  itemType: {
    id: string;
    name: string;
    displayName: string;
  };
  itemDetail: {
    id: string;
    name: string;
    displayName: string;
    itemTypeId: string;
    itemDetailNotes?: string;
  };
  id: string;
  orderItem?: number;
  costUnitOverride?: number;
  itemDetails?: any[];
  isVisible?: number;
  isCloned: boolean;
  isDeleted: boolean;
  respToExpandFacilityItemId?: string;
  isExpanded?: boolean;
  unitOfMeasureId: string;
}

export interface ItemCostModel {
  id: string;
  name: string;
  itemId: string;
  itemDetailId: string;
  stateAbbreviation: string;
  year: number;
}

export enum keyFacilityItem {
  SWD_TRAIN_15000_BWPD = 'SWD Train - 15000 BWPD',
  GREENFIELD_SATELLITE_INSTALL = 'Greenfield Satellite Install',
  HI_ACCURACY_SATELLITE_METERING_UPGRADE = 'Hi-Accuracy Satellite Metering Upgrade',
  SATELLITE_BROWNFIELD_ADDER = 'Satellite Brownfield Adder',
  SATELLITE_HEADER = 'Satellite Header',
  HPFE_BROWNFIELD_ADDER = 'HPFE Brownfield Adder',
  CTB_BROWNFIELD_ADDER = 'CTB Brownfield Adder',
  HIGH_PRESSURE_FRONT_END_MULTI_WELL_UNIT = 'High-Pressure Front End (multi-well unit)',
  GREENFIELD_COMPRESSOR_STATION_INSTALL = 'Greenfield Compressor Station Install',
  RENTAL_3516 = 'Rental 3516',
  SWD_INJECTION_H_PUMP_AT_STATION = 'SWD Injection H-Pump (at station)',
  GREENFIELD_2_TRAIN_SWD_INSTALL = 'Greenfield 2-Train SWD Install',
  COMPRESSOR_STATION_VRU = 'Compressor Station VRU',
  FLARE_SYSTEM = 'Flare System',
  CS_BROWNFIELD_ADDER = 'CS Brownfield Adder',
  SWD_BROWNFIELD_ADDER = 'SWD Brownfield Adder',
  RECYCLE_TRANSFER_PUMP_SKID = 'Recycle Transfer Pump Skid',
  SATELLITE_TEST_SEPARATOR = 'Satellite Test Separator',
  SATELLITE_PIMPING_SKID = 'Satellite Pumping Skid',
  GREENFIELD_1_TRAIN_CTB_INSTALL = 'Greenfield 1-Train CTB Install',
  CTB_TRAIN_12000_BOPD = 'CTB Train - 12000 BOPD',
  CTB_TRAIN_8500_BOPD = 'CTB Train - 8500 BOPD',
  CTB_TRAIN_TRUNCATED = 'CTB Train - Truncated',
  CTB_TRAIN_1000_BOPD_SINGLE_WELL = 'CTB Train - 1000 BOPD (Single Well)',
  CTB_TANK = 'CTB Tank',
  CTB_VRU = 'CTB VRU',
  CTB_LACT = 'CTB LACT',
  CTB_WATER_TRANSFER_PUMP = 'CTB Water Transfer Pump',
  CTB_FLASH_GAS_COMPRESSOR = 'CTB Flash Gas Compressor',
  CTB_HEADER = 'CTB Header',
  CTB_TEST_SEPARATOR = 'CTB Test Separator',
  HI_ACCURACY_CTB_METERING_UPGRADE = 'Hi-Accuracy CTB Metering Upgrade (GLO/BLM)',
  SWD_INJECTION_LINE = 'SWD Injection Line',
  SWD_WELL_PAD_AND_HOOKUP = 'SWD Well Pad and Hookup',
  WATER_SOURCE_WELL_D_C_COSTS = 'Water Source Well D&C Costs',
  WATER_WELL_PAD_AND_HOOKUP = 'Water Well Pad and Hookup',
  COMMERCIAL_OIL_TIE_IN = 'Commercial Oil Tie-in',
  COMMERCIAL_GAS_TIE_IN = 'Commercial Gas Tie-in (User Specified)',
  SURVEY_AND_PERMITTING = 'Survey and Permitting',
  STANDARD_NETWORK_EQUIPMENT_PACKAGE = 'Standard Network Equipment Package',
  FRAC_PONDS = 'Frac Ponds',
  EDS_MAIN_LINE_6_MVA = 'EDS Main Line (>6 MVA)',
  ROAD_CONSTRUCTION_AND_CALICHE = 'Road Construction and Caliche',
  WELL_PAD_SINGLE_USE_ABOVE_GRADE_WH = 'Well Pad - Single Use / Above-Grade WH',
  WELL_PAD_MULTI_USE_SUB_GRADE_WH = 'Well Pad - Multi-Use / Sub-Grade WH',
  IN_FIELD_GAS_GATHERING_SALES_LP = 'In-Field Gas Gathering/Sales, LP',
  IN_FIELD_GAS_GATHERING_SALES_HP = 'In-Field Gas Gathering/Sales, HP',
  GAS_LIFT_DISTRIBUTION = 'Gas Lift Distribution',
  REGIONAL_WATER_PIPELINE = 'Regional Water Pipeline',
  PRODUCED_WATER_GATHERING = 'Produced Water Gathering',
  SUPPLY_WATER_LINE = 'Supply Water Line',
  POND_TO_POND_TRANSFER_LINE = 'Pond to Pond Transfer Line',
  RECYCLE_WATER_TRANSFER_LINE = 'Recycle Water Transfer Line',
  COMMERCIAL_PIPELINE_OIL_GATHERING = 'Commercial Pipeline (oil gathering)',
  OIL_GATHERING = 'Oil Gathering',
  FLOWLINE_MATERIALS_AND_INSTALLATION = 'Flowline Materials and Installation',
  LAND_DAMAGES = 'Land Damages',
  FACILITY_RADIO_TOWER_60 = "60' Facility Radio Tower",
  RESERVE_PIT_CONSTRUCTION = 'Reserve Pit Construction and Closure',
  POST_DRILL_PAD_RECLAMATION = 'Post Drill Pad Reclamation',
  WELLHEAD_AL_HOOKUP = 'Wellhead and AL Hookup - ESP',
  WELLHEAD_AL_HOOKUP_GAS_LIFT = 'Wellhead and AL Hookup - Gas Lift',
  FLOWLINE_RISERS_2_PER_WELL = 'Flowline Risers (2 Per Well)',
  PER_WELL_COST_ADDER_SUB_GRADE_WD_CELLARS = 'Per-Well Cost Adder: Sub-Grade WH Cellars',
  FLOWLINES_LENGTH_OF_ROUTE = 'Flowlines - Length of Route',
  FLOWLINE_TRENCHING = 'Flowline Trenching',
  AERATION_SKID = 'Aeration/Circulation Skid (for recycle)',
  INFIELD_LIQUIDS_GATHERING_SATELLITE = 'In-Field Liquids Gathering from Satellite',
  LIQUIDS_PIPELINE_RISER = 'Liquids Pipeline Riser',
  FIXED_WATER_RECICLYE_UNIT_INSTALL = 'Fixed Water Recycle Unit Install',
  /// <**** 1b and 1a

  /// >**** 2a
  TRAIN_1_CTB_ADDITION = '1-Train CTB Addition',
  CTB_PAD_COST = 'CTB Pad Cost',
  IN_FIELD_LIQUIDS_GATHERING_FROM_SATELLITE = 'In-Field Liquids Gathering from Satellite',
  CONDENSATE_GATHERING = 'Condensate Gathering',
  BLANCKET_GAS_LINE = 'Blanket Gas Line',
  SKIM_OIL_RETURN_LINE = 'Skim Oil Return Line',
  LP_GAS_PIPELINE_RISER = 'LP Gas Pipeline Riser',
  HP_GAS_PIPELINE_RISER = 'HP Gas Pipeline Riser',
  CONDENSATE_PIPELINE_RISER = 'Condensate Pipeline Riser',
  GAS_LIFT_PIPELINE_RISER = 'Gas Lift Pipeline Riser',
  SWD_INJECTION_LINE_RIDER = 'SWD Injection Line Riser',
  REGIONAL_WATER_PIPELINE_RISER = 'Regional Water Pipeline Riser',
  PRODUCED_WATER_PIPELINE_RISER = 'Produced Water Pipeline Riser',
  BLANKET_GAS_PIPELINE_RISER = 'Blanket Gas Pipeline Riser',
  SKIM_OIL_RETURN_PIPELINE_RISER = 'Skim Oil Return Pipeline Riser',
  /// <**** 2a
  ONE_TRAIN_SWD_ADDITION = '1-Train SWD Addition',
  TWO_TRAIN_SWD_ADDITION = '2-Train SWD Addition',
  FIBER_OPTIC_CABLE = 'Fiber Optic Cable',
  RADIO_TOWER_BACKBONE = "300' Radio Tower (Backbone)",
}

export enum keyFacilityItemDetail {
  GREENFIELD_1_TRAIN_CTB_INSTALL_20_DESIGN = '0F7270FD-904C-406E-9732-CE9B9A7E9573',
  CTB_WATER_PURMP_19_DESIGN = '72D53CBE-7142-4A0A-9A58-098794D2EA65',
  CTB_WATER_PURMP_1718_DESIGN = '011DAF0D-2089-4801-AD55-6550A9CE55C4',
  CTB_VRU_FX_20 = 'FX20',
  CTB_VRU_FX_17 = 'FX17',
  CTB_VRU_FX_12 = 'FX12',
  CTB_VRU_FX_8 = '5B4BFD16-F774-48AE-8668-D4D673CD24D4',
  CTB_FLASH_GAS_COMPRESSOR_FX_20 = 'FX20',
  CTB_FLASH_GAS_COMPRESSOR_FX_17 = 'FX17',
  CTB_FLASH_GAS_COMPRESSOR_FX_12 = 'FX12',
  CTB_FLASH_GAS_COMPRESSOR_FX_8 = 'FX8',
  FRAC_PONDS_RWCS = '11C32853-C461-435B-880E-2D241D291F99',
  FRAC_PONDS_EPS = 'AAF79C61-4C22-4780-85EC-7A6CC846868F',
  WELL_PAD_MULTI_USE_SUB_GRADE_WH_BELOW_GRADE = '0D94AF2E-4AA2-4812-AC5A-7F844F6F3527',
  WELL_PAD_MULTI_USE_SUB_GRADE_WH_ABOVE_GRADE = '2749246E-D733-48C0-AFDD-98D1AF769DA4',
  FLOWLINE_RISERS_2_PER_WELL_FL = '5226D569-ADC0-4AF7-A84B-759163D51923',
  RECYCLE_WATER_TRANSFER_LINE_8_SDR7_HDPE = 'B45F9B95-E62B-453B-BA51-30B941AE860A',
}
export interface ItemAndDetailsModel {
  id: string;
  name: string;
  itemNotes: string;
  unitOfMeasureId: string;
  unitOfMeasureName: string;
  itemDetails?: IDetailsModel[];
  orderItem?: number;
}

export interface IDetailsModel {
  id: string;
  name: string;
  displayName: string;
  itemDetailNotes: string;
  itemName: string;
}
