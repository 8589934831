<button kendoButton type="button" class="k-button k-primary" (click)="openDialogForDataGrid()">
    <span class="k-icon k-i-file-excel m-1"></span> {{ buttonName }}</button>
<kendo-dialog title="Export to Excel" *ngIf="opened" (close)="closeDialogForDataGrid()" [minWidth]="250" [width]="450">
  <div class="card">
    <div class="card-block">
      <form class="k-form" [formGroup]="form">
        <div class="k-form-field">
          How do you want to export your data?
        </div>
        <div class="row">
          <div class="col">
            <div class="k-form-field">
              <input type="radio" formControlName="data" value="allData" class="k-radio" id="allData" />
              <label class="k-radio-label" for="allData">All data</label>
            </div>
            <div class="k-form-field">
              <input type="radio" formControlName="data" value="filteredData" class="k-radio" id="filteredData" />
              <label class="k-radio-label" for="filteredData">Filtered data</label>
            </div>
          </div>
          <div class="col">
            <div class="k-form-field">
              <input type="radio" formControlName="columns" value="allColumns" class="k-radio" id="allColumns" />
              <label class="k-radio-label" for="allColumns">All Columns </label>
            </div>
            <div class="k-form-field">
              <input type="radio" formControlName="columns" value="selectedColumns" class="k-radio" id="selectedColumns"/>
              <label class="k-radio-label" for="selectedColumns">Selected Columns </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <kendo-dialog-actions class="no-border k-dialog-buttongroup k-dialog-button-layout-stretched">
    <button
      type="button"
      class="k-button k-primary custom-dialog__btn"
      [disabled]="this.loading"
      kendoButton
      (click)="exportDataGrid()"
    >
      Export <span *ngIf="this.loading" class="k-icon k-i-loading"></span>
    </button>
    <button
      type="button"
      class="k-button k-button-solid-base custom-dialog__btn"
      [disabled]="this.loading"
      kendoButton
      (click)="closeDialogForDataGrid()"
    >
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- <kendo-grid-excel fileName="{{ fileName + '.xlsx' }}"></kendo-grid-excel> -->
