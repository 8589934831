import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcCellIndicatorComponent } from './fit-cc-cell-indicator.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [FitCcCellIndicatorComponent],
  exports: [FitCcCellIndicatorComponent],
})
export class FitCcCellIndicatorModule {}
