import { Injectable } from '@angular/core';
import { IScenario } from '../../../api/pricetScenario';
import { UtilService } from '../../../services/util.service';
import {
  IPadBracketFields,
  InputFacilityAssignmentModel,
} from '../input-facility-assignment/input-facility-assignment.model';
import { IPadBracketAndFacility } from '../../../api/padBracketAndFacility';
import { ScenarioPadEquipmentScopeCountModel } from '../../../api/padItemInventory';
import { InputItem } from '../../../api/input-item';
import { InputSummaryModel } from '../input-summary/input-summary-model';
import { IDevelopmentArea } from '../../../api/devArea';
import { State } from '../../../api/state';
import { ScenarioType } from '../../../api/scenarioType';
import { Basin } from '../../../api/basin';
import { GeographicArea } from '../../../api/geographicArea';

@Injectable({
  providedIn: 'root',
})
export class OpenScenarioService {
  public readonly PadFields: IPadBracketFields;
  constructor(private utilService: UtilService) {
    this.PadFields = InputFacilityAssignmentModel.IPadFields;
  }

  public transformInputSummary(
    scenario: IScenario,
    devAreas: IDevelopmentArea[],
    states: State[],
    scenarioTypes: ScenarioType[],
    basins: Basin[],
    geographicAreas: GeographicArea[]
  ): any {
    const devArea = devAreas.find(da => scenario.developmentAreaId === da.id);
    const stateF = states.find(s => scenario.stateId === s.id);
    const scenarioTypeF = scenarioTypes.find(t => scenario.scenarioTypeId === t.id);
    const basinF = basins.find(b => scenario.basinId === b.id);
    const geoArea = geographicAreas.find(g => scenario.geographicAreaId === g.id);
    const inputSummary: InputSummaryModel = {
      scenarioName: scenario.name,
      developmentArea: devArea,
      contingency: scenario.contingencyPercentage,
      flowlineThreshold: scenario.flowlineThresholdDistance,
      state: stateF,
      assumedNumWellsPerTestSeparator: scenario.assumedWellsPerTestSeparator,
      greenfieldInstallTimeframe: scenario.greenfieldInstallTimeframe,
      handoverNumber: scenario.handoverNumber,
      scenarioType: scenarioTypeF,
      basin: basinF,
      geographicArea: geoArea,
      isDeleted: scenario.isDeleted,
      year: scenario.costYear,
    };
    return inputSummary;
  }

  // Same code in inputFacilityAssignmentService, important when the code change.
  public transformPadBrackets(padBrackets: IPadBracketAndFacility[]): any[] {
    const padConfigData = [];
    for (const padBracket of padBrackets) {
      padBracket['pbPadName'] = padBracket.padName;
      padBracket.padName = this.utilService.camelize(padBracket.padName);
    }
    for (const fieldName in this.PadFields) {
      if (this.PadFields.hasOwnProperty(fieldName)) {
        const padConfig = {};
        switch (fieldName) {
          case 'padOrder':
            padConfig['fieldName'] = {
              name: 'padOrder',
              displayName: 'Pad Order',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.padOrder;
            }
            break;
          case 'spudDate':
            padConfig['fieldName'] = {
              name: 'spudDate',
              displayName: 'SPUD Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = new Date(padBracket.spudDate);
            }
            break;
          case 'padRigDownMoveOffDate':
            padConfig['fieldName'] = {
              name: 'padRigDownMoveOffDate',
              displayName: 'Pad RDMO Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] =
                padBracket.padRigDownMoveOffDate === undefined || padBracket.padRigDownMoveOffDate === null
                  ? null
                  : new Date(padBracket.padRigDownMoveOffDate);
            }
            break;
          case 'popDate':
            padConfig['fieldName'] = {
              name: 'popDate',
              displayName: 'POP Date',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = new Date(padBracket.popDate);
            }
            break;

          case 'wellCount':
            padConfig['fieldName'] = {
              name: 'wellCount',
              displayName: 'Well Count',
              isVisible: false,
              parentName: 'padOrder',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.wellCount;
            }
            break;
          case 'padStatus':
            padConfig['fieldName'] = {
              name: 'padStatus',
              displayName: 'Pad Status',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.padStatus;
            }
            break;
          case 'pgnNumber':
            padConfig['fieldName'] = {
              name: 'pgnNumber',
              displayName: 'PGN #',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.pgnNumber;
            }
            break;
          case 'activeForOutlook':
            padConfig['fieldName'] = {
              name: 'activeForOutlook',
              displayName: 'Active For Outlook',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.activeForOutlook;
            }
            break;
          case 'developmentArea':
            padConfig['fieldName'] = {
              name: 'developmentArea',
              displayName: 'Development Area',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.developmentArea;
            }
            break;
          case 'isPartOfCo2Development':
            padConfig['fieldName'] = {
              name: 'isPartOfCo2Development',
              displayName: 'Part of CO2 Development',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] =
                padBracket.isPartOfCo2Development != null
                  ? padBracket.isPartOfCo2Development === 1
                    ? 'Yes'
                    : 'No'
                  : null;
            }
            break;
          case 'targetSatellite':
            padConfig['fieldName'] = {
              name: 'targetSatellite',
              displayName: 'Target Satellite',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.targetSatellite;
            }
            break;
          case 'tragetSatelliteRfsd':
            padConfig['fieldName'] = {
              name: 'tragetSatelliteRfsd',
              displayName: 'Satellite RFSD',
              isVisible: false,
              parentName: 'targetSatellite',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.targetSatelliteRfsd ? new Date(padBracket.targetSatelliteRfsd) : null;
            }
            break;
          case 'satelliteExecutionCategory':
            padConfig['fieldName'] = {
              name: 'satelliteExecutionCategory',
              displayName: 'Satellite Execution Category',
              isVisible: false,
              parentName: 'targetSatellite',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.satelliteExecutionCategory;
            }
            break;
          case 'targetCtb':
            padConfig['fieldName'] = {
              name: 'targetCtb',
              displayName: 'Target CTB',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.targetCtb;
            }
            break;
          case 'tragetCtbRfsd':
            padConfig['fieldName'] = {
              name: 'tragetCtbRfsd',
              displayName: 'CTB RFSD',
              isVisible: false,
              parentName: 'targetCtb',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.targetCtbRfsd ? new Date(padBracket.targetCtbRfsd) : null;;
            }
            break;
          case 'ctbExecutionCategory':
            padConfig['fieldName'] = {
              name: 'ctbExecutionCategory',
              displayName: 'CTB Execution Category',
              isVisible: false,
              parentName: 'targetCtb',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.ctbExecutionCategory;
            }
            break;
          case 'targetTrain':
            padConfig['fieldName'] = {
              name: 'targetTrain',
              displayName: 'Target Train',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.targetTrain;
            }
            break;
          case 'wellTestLocation':
            padConfig['fieldName'] = {
              name: 'wellTestLocation',
              displayName: 'Well Test Location',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.wellTestLocation;
            }
            break;
          case 'cumulativeWellCount':
            padConfig['fieldName'] = {
              name: 'cumulativeWellCount',
              displayName: 'Cumulative Well Count',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.cumulativeWellCount;
            }
            break;
          case 'cumulativeHeaderSlots':
            padConfig['fieldName'] = {
              name: 'cumulativeHeaderSlots',
              displayName: 'Cumulative Header Slots',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.cumulativeHeaderSlots;
            }
            break;
          case 'cumulativeTestSeparators':
            padConfig['fieldName'] = {
              name: 'cumulativeTestSeparators',
              displayName: 'Cumulative Test Separators',
              isVisible: false,
              parentName: 'wellTestLocation',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.cumulativeTestSeparators;
            }
            break;
          case 'associatedCompStation':
            padConfig['fieldName'] = {
              name: 'associatedCompStation',
              displayName: 'Associated Comp. Station',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.associatedCompStation; // Maped with other entities
            }
            break;
          case 'compressionRfsd':
            padConfig['fieldName'] = {
              name: 'compressionRfsd',
              displayName: 'Compression RFSD',
              isVisible: false,
              parentName: 'associatedCompStation',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.compressionRfsd ? new Date(padBracket.compressionRfsd) : null;
            }
            break;
          case 'compExecutionCategory':
            padConfig['fieldName'] = {
              name: 'compExecutionCategory',
              displayName: 'Comp. Execution Category',
              isVisible: false,
              parentName: 'associatedCompStation',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.compExecutionCategory;
            }
            break;
          case 'associatedSwdFacility':
            padConfig['fieldName'] = {
              name: 'associatedSwdFacility',
              displayName: 'Associated SWD Facility',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.associatedSwdFacility;
            }
            break;
          case 'waterDisposalRfsd':
            padConfig['fieldName'] = {
              name: 'waterDisposalRfsd',
              displayName: 'Water Disposal RFSD',
              isVisible: false,
              parentName: 'associatedSwdFacility',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.waterDisposalRfsd
                ? new Date(padBracket.waterDisposalRfsd)
                : null;
            }
            break;
          case 'swdExecutionCategory':
            padConfig['fieldName'] = {
              name: 'swdExecutionCategory',
              displayName: 'SWD Execution Category',
              isVisible: false,
              parentName: 'associatedSwdFacility',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.swdExecutionCategory;
            }
            break;
          case 'fracPond':
            padConfig['fieldName'] = {
              name: 'fracPond',
              displayName: 'Frac Pond',
              isVisible: true,
              parentName: '0',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.fracPond;
            }
            break;
          case 'fracPondRfsd':
            padConfig['fieldName'] = {
              name: 'fracPondRfsd',
              displayName: 'Frac Pond RFSD',
              isVisible: false,
              parentName: 'fracPond',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.fracPondRfsd ? new Date(padBracket.fracPondRfsd) : null;
            }
            break;
          case 'pondExecutionCategory':
            padConfig['fieldName'] = {
              name: 'pondExecutionCategory',
              displayName: 'Pond Execution Category',
              isVisible: false,
              parentName: 'fracPond',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.pondExecutionCategory;
            }
            break;
          case 'padContingency':
            padConfig['fieldName'] = {
              name: 'padContingency',
              displayName: 'Pad Contingency',
              isVisible: true,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.padContingency;
            }
            break;
          case 'comments':
            padConfig['fieldName'] = {
              name: 'comments',
              displayName: 'Comments',
              isVisible: false,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.comments;
            }
            break;
          case 'isPadNameOverriden':
            padConfig['fieldName'] = {
              name: 'isPadNameOverriden',
              displayName: 'isPadNameOverriden',
              isVisible: false,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.isPadNameOverriden;
            }
            break;
          case 'id':
            padConfig['fieldName'] = {
              name: 'id',
              displayName: 'id',
              isVisible: false,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.id;
            }
            break;
          case 'yearCost':
            padConfig['fieldName'] = {
              name: 'yearCost',
              displayName: 'yearCost',
              isVisible: false,
              parentName: '-1',
              isExpanded: false,
            };
            for (const padBracket of padBrackets) {
              padConfig[padBracket.padName] = padBracket.yearCost;
            }
            break;
        }
        if (Object.keys(padConfig).length > 0) {
          padConfigData.push(padConfig);
        }
      }
    }
    return padConfigData;
  }

  public populateScopeCounts(
    inputItemsData: InputItem[],
    scopeCounts: ScenarioPadEquipmentScopeCountModel[],
    pads: any[]
  ): void {
    for (const scopeCount of scopeCounts) {
      scopeCount.padName = this.utilService.camelize(scopeCount.padName);
    }
    for (const input of inputItemsData) {
      for (const pad of pads) {        
        const scopeCount = scopeCounts.find(
          (sc, index, array) => sc.padName === pad.padName && sc.facilityItemId === input.id && sc.parentRespToExpandFacilityItemId == input.respToExpandFacilityItemId
        );
        if (scopeCount) {
          input[pad.padName] = scopeCount.scopeCount;
        }
      }
    }
  }
}
