import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScenarioStoreService } from '../../../services/scenario-store.service';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable()
export class EditPadsService extends BehaviorSubject<any[]> {
  public data: any[] = [];
  public originalData: any[] = [];
  public createdItems: any[] = [];
  public updatedItems: any[] = [];
  public deletedItems: any[] = [];

  constructor(private scenarioStoreService: ScenarioStoreService) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }

    this.scenarioStoreService.padBuilderData$.subscribe(data =>{
        this.data = data.map((item: any) => {
          return {
            id: item.id,
            name: item.pbPadName,
            isSelected: false
          };
        });
        this.originalData = cloneData(this.data);
        super.next(this.data);
    })
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isSelected !== undefined) {
      item.isSelected = false;
    }

    let index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    const found = this.originalData.find(d => d.id === item.id);
    if(found) {
      item.name = found.name;
    }
    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
  }

  public saveChanges(): Observable<any> {
    return this.scenarioStoreService.overridePaNames(this.updatedItems);
  }

  public cancelChanges(): void {
    this.reset();
    this.read();
  }

  public assignValues(target: any, source: any): void {
    if (target.name !== source.name) {
      source.isSelected = true;
    }
    Object.assign(target, source);
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }
}
