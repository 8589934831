export class InputFacilityAssignmentModel {
  // Order to the Facility Grid
  public static readonly IPadFields: IPadBracketFields = Object.freeze({
    padName: 'padName',
    padOrder: 'padOrder',
    spudDate: 'spudDate',
    padRigDownMoveOffDate: 'padRigDownMoveOffDate',
    popDate: 'popDate',
    wellCount: 'wellCount',
    padStatus: 'padStatus',
    pgnNumber: 'pgnNumber',
    activeForOutlook: 'activeForOutlook',
    developmentArea: 'developmentArea',
    isPartOfCo2Development: 'isPartOfCo2Development',
    targetSatellite: 'targetSatellite',
    tragetSatelliteRfsd: 'tragetSatelliteRfsd',
    satelliteExecutionCategory: 'satelliteExecutionCategory',
    targetCtb: 'targetCtb',
    tragetCtbRfsd: 'tragetCtbRfsd',
    ctbExecutionCategory: 'ctbExecutionCategory',
    targetTrain: 'targetTrain',
    wellTestLocation: 'wellTestLocation',
    cumulativeWellCount: 'cumulativeWellCount',
    cumulativeHeaderSlots: 'cumulativeHeaderSlots',
    cumulativeTestSeparators: 'cumulativeTestSeparators',
    associatedCompStation: 'associatedCompStation',
    compressionRfsd: 'compressionRfsd',
    compExecutionCategory: 'compExecutionCategory',
    associatedSwdFacility: 'associatedSwdFacility',
    waterDisposalRfsd: 'waterDisposalRfsd',
    swdExecutionCategory: 'swdExecutionCategory',
    fracPond: 'fracPond',
    fracPondRfsd: 'fracPondRfsd',
    pondExecutionCategory: 'pondExecutionCategory',
    padContingency: 'padContingency',
    comments: 'comments',
    isPadNameOverriden: 'isPadNameOverriden',
    yearCost: 'yearCost',
    id: 'id',
  });
}

export interface IPadBracketFields {
  padName: string;
  padOrder: string;
  spudDate: string;
  padRigDownMoveOffDate: string;
  popDate: string;
  wellCount: string;
  padStatus: string;
  pgnNumber: string;
  activeForOutlook: string;
  developmentArea?: string;
  isPartOfCo2Development: string;
  targetSatellite: string;
  tragetSatelliteRfsd:string
  satelliteExecutionCategory: string;
  targetCtb: string;
  tragetCtbRfsd
  ctbExecutionCategory: string;
  targetTrain: string;
  wellTestLocation: string;
  cumulativeWellCount: string;
  cumulativeHeaderSlots: string;
  cumulativeTestSeparators: string;
  associatedCompStation: string;
  compressionRfsd: string;
  compExecutionCategory: string;
  associatedSwdFacility: string;
  waterDisposalRfsd: string;
  swdExecutionCategory: string;
  fracPond: string;
  fracPondRfsd: string;
  pondExecutionCategory: string;
  padContingency: string;
  comments: string;
  isPadNameOverriden: string;
  yearCost: string;
  id: string;
}
