import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InlineEditService } from 'src/app/services/inline-edit.service';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { IEditableInline } from 'src/app/api/ieditable-inline';
import { IPerformanceBodPipelines } from 'src/app/api/performance-bod-pipelines';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import * as Helpers from 'src/app/_shared/helpers';
import { map } from 'rxjs/operators/map';

@Injectable({
  providedIn: 'root',
})
export class InfrastructurePipelinesService extends InlineEditService implements IEditableInline {
  public data: IPerformanceBodPipelines[] = [];
  public originalData: IPerformanceBodPipelines[] = [];
  public createdItems: IPerformanceBodPipelines[] = [];
  public updatedItems: IPerformanceBodPipelines[] = [];
  public deletedItems: IPerformanceBodPipelines[] = [];

  public performanceBodId: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService
  ) {
    super();
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public itemIndex(item: any, data: IPerformanceBodPipelines[]): number {
    for (let idx = 0; idx < data.length; idx++) {
      if (data[idx].id === item.id) {
        return idx;
      }
    }
    return -1;
  }

  public create(): Observable<any> {
    return this.performanceBodDataService.createPerformanceBodPipelines(this.createdItems);
  }

  public retrieve(): Observable<any> {

    return this.performanceBodDataService.getPerformanceBodPipelines(this.performanceBodId).pipe(map(data => {
      data.forEach(element => {
        if (element.pipelineRfsd !== null && element.pipelineRfsd !== undefined) {
          element.pipelineRfsd = Helpers.convertUtcToLocalTime(element.pipelineRfsd);
        } else {
          element.pipelineRfsd = undefined;
        }

        if (element.permitsRequiredBy !== null && element.permitsRequiredBy !== undefined) {
          element.permitsRequiredBy = Helpers.convertUtcToLocalTime(element.permitsRequiredBy);
        } else {
          element.permitsRequiredBy = undefined;
        }
      });
      return data;
    }));
  }

  public update(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodPipelines(this.updatedItems);
  }

  public delete(): Observable<any> {
    return this.performanceBodDataService.updatePerformanceBodPipelines(this.deletedItems);
  }

  public cancelChanges(): void {
    super.cancelChanges();
  }

  public applyLogicFromPads(): void {
    const pipelineChanges = this.performanceBodCalculationsService.pipelinesCalculations(this.data);
    for (const pipeline of pipelineChanges) {
      this.updateItem(pipeline);
    }
  }
}
