import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fit-cc-unauthorized',
  templateUrl: './fit-cc-unauthorized.component.html',
})
export class FitCcUnauthorizedComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() button: string;

  constructor() { }

  ngOnInit() {
    this.title = 'Access Denied'
    this.subtitle = 'You are not authorized to access this page.'
    this.button = 'Request Access'
  }

}
