import { Component, EventEmitter, OnInit, Output, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { IPerformanceBodPackages } from 'src/app/api/performance-bod-package';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SummaryPeakProductionRatesEditService } from './summary-peak-production-rates-edit.service';
import { IPerformanceExpectedPeakProductionRates } from 'src/app/api/performance-expected-peak-production-rates';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';
import { PermissionsProvider } from 'src/app/services/permissions.provider';
import { AuthenticationService } from 'src/app/services';
import { DragAndDropService } from 'src/app/_shared/drag-and-drop.service';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import * as Helpers from 'src/app/_shared/helpers';
import { Guid } from 'guid-typescript';

const DRAGGABLE_SELECTOR = '.peak-production-draggable';

@Component({
  selector: 'app-summary-peak-production-rates',
  templateUrl: './summary-peak-production-rates.component.html',
  styleUrls: ['./summary-peak-production-rates.component.css'],
})
export class SummaryPeakProductionRatesComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public performanceBodId: string;
  public formGroup: FormGroup;
  public gridState: State = {
    sort: [],
    skip: 0,
  };
  public filter: CompositeFilterDescriptor;
  public filterable: boolean = true;
  public canEditPerformanceBod: boolean = false;
  public performanceBodPackages: IPerformanceBodPackages[];
  public performanceBodPads: IPerformanceBodPads[];

  public changes: any = {};
  public IsClickedOnCancelButton = false;
  @Output() public performanceBodUpdate = new EventEmitter<string>();
  @Output() public saveBodCompleted = new EventEmitter<boolean>();
  public summaryPeakProductionRatesEditService: SummaryPeakProductionRatesEditService;
  private dragAndDropService: DragAndDropService;
  @ViewChild('performanceExpectedPeakProductionRatesGrid')
  private performanceExpectedPeakProductionRatesGrid: any;

  constructor(
    private performanceBodStoreService: PerformanceBodStoreService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private permissionsProvider: PermissionsProvider,
    private authenticationService: AuthenticationService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService,
    @Inject(DragAndDropService) dragAndDropServiceFactory: any,
    @Inject(SummaryPeakProductionRatesEditService) summaryPeakProductionRatesEditServiceFactory: any
  ) {
    this.dragAndDropService = dragAndDropServiceFactory();
    this.summaryPeakProductionRatesEditService = summaryPeakProductionRatesEditServiceFactory();
  }

  public ngOnInit(): void {
    this.performanceBodStoreService.performanceBodSignatured$.subscribe((isSignature) => {
      this.canEditPerformanceBod = isSignature? false: this.permissionsProvider.canEditPerformanceBod;
    });

    // Set the performance bod identification
    this.performanceBodStoreService.performanceBodId$.subscribe(id => {
      this.performanceBodId = id;
      this.summaryPeakProductionRatesEditService.performanceBodId = id;
      // Get async array
      this.summaryPeakProductionRatesEditService.read();
    });

    // get packages of store service
    this.performanceBodStoreService.performanceBodPackagesData$.subscribe(data => {
      this.performanceBodPackages = data;
    });

    // get pads of store service
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(data => {
      this.performanceBodPads = data;
    });

    this.view = this.summaryPeakProductionRatesEditService.pipe(
      map(data => {
        this.dragAndDropService.dataSourceRefreshed(data);
        // This of isClckedOnCanelButton is a PATCH once we get ready overridable logic perseved in DB this should be removed.
        if (this.IsClickedOnCancelButton) {
          this.checkIsOnPadDelete(data);
        } else {
          this.IsClickedOnCancelButton = false;
        }

        return process(filterBy(data, this.filter), this.gridState);
      })
    );

    this.dragAndDropService.initialize(this.summaryPeakProductionRatesEditService.data, DRAGGABLE_SELECTOR, () => {
      this.summaryPeakProductionRatesEditService.markAsSetOrder(this.authenticationService.getCurrentUser());
    });
  }

  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    this.dragAndDropService.refreshDragAndDrop();
    this.summaryPeakProductionRatesEditService.data = [];
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      id: [dataItem.id],
      isDeleted: [dataItem.isDeleted],
      createdBy: [dataItem.createdBy],
      createdDate: [dataItem.createdDate],
      updatedBy: [dataItem.updatedBy],
      updatedDate: [dataItem.updatedDate],
      oilBopd: [dataItem.oilBopd, [Validators.min(0), Validators.max(1000000)]],
      waterBwpd: [dataItem.waterBwpd, [Validators.min(0), Validators.max(1000000)]],
      lpGasMmscfd: [dataItem.lpGasMmscfd, [Validators.min(0), Validators.max(100000)]],
      hpGasMmscfd: [dataItem.hpGasMmscfd, [Validators.min(0), Validators.max(100000)]],
      totalGasMmscfd: [dataItem.totalGasMmscfd],
      gasLiftMmscfd: [dataItem.gasLiftMmscfd, [Validators.min(0), Validators.max(100000)]],
      totalCompressionMmscfd: [dataItem.totalCompressionMmscfd, [Validators.min(0), Validators.max(100000)]],
      comments: [dataItem.comments, Validators.maxLength(500)],
      performanceBodPadsId: [dataItem.performanceBodPadsId],
      packageName: dataItem.packageName,
      wellPadName: dataItem.wellPadName,
      isWellPadNameDisabled: dataItem.isWellPadNameDisabled
    });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.canEditPerformanceBod) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      this.dragAndDropService.onEditingMode();
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      dataItem.updatedDate = new Date();
      dataItem.updatedBy = this.authenticationService.getCurrentUser();
      dataItem.totalGasMmscfd = formGroup.value.lpGasMmscfd + formGroup.value.hpGasMmscfd;

      // Date is not working in Object.assign(), so need to be do it manually
      formGroup.value.updatedBy = dataItem.updatedBy;
      formGroup.value.updatedDate = dataItem.updatedDate;
      formGroup.value.totalGasMmscfd = dataItem.totalGasMmscfd;
      const isUnspecified = dataItem.wellPadName.toLowerCase().includes('unspecified');

      if (isUnspecified) {
        Helpers.setValueToOverridableProperties(dataItem, formGroup.value);
      }

      this.summaryPeakProductionRatesEditService.assignValues(dataItem, formGroup.value);

      if (!isUnspecified) {
        const packages = this.performanceBodPads
          .filter(p => p.id === dataItem.performanceBodPadsId)
          .map(item => {
            return item.performanceBodPackagesId;
          });
        this.performanceBodCalculationsService.expectedPEakProductionUnspecifiedPackages(packages);
      }

      this.summaryPeakProductionRatesEditService.update(dataItem);
      if (!isUnspecified) {
        this.summaryPeakProductionRatesEditService.applyUnexpectedSumLogic(this.performanceBodPads);
      }
    }

    // calls this to add the attribute of current row
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.dragAndDropService.onEditingClose();
    this.dragAndDropService.refreshDragAndDrop();
  }

  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      formGroup.patchValue({
        totalGasMmscfd: formGroup.value.lpGasMmscfd + formGroup.value.hpGasMmscfd,
      });
      this.summaryPeakProductionRatesEditService.create(formGroup.value);
      sender.closeRow(rowIndex);
      this.dragAndDropService.onEditingClose();
      this.dragAndDropService.dataSourceRefreshed(this.summaryPeakProductionRatesEditService.data);
      this.dragAndDropService.refreshDragAndDrop();
      this.summaryPeakProductionRatesEditService.markAsSetOrder(this.permissionsProvider.cai);
    }
  }

  public removeHandler({ sender, dataItem }) {
    this.summaryPeakProductionRatesEditService.remove(dataItem);
    sender.cancelCell();
  }

  public saveChanges(grid: any): void {
    this.saveFromBOD();

    setTimeout(() => {
      this.spinner.hide();
      this.notificationService.show({
        content: 'Expected Peak Production rates sucessfully saved',
        position: { horizontal: 'center', vertical: 'top' },
        animation: { type: 'fade', duration: 1000 },
        type: { style: 'success', icon: true },
        hideAfter: 700,
      });
    }, 3000);
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this.dragAndDropService.refreshDragAndDrop();
    this.summaryPeakProductionRatesEditService.cancelChanges();
  }

  public handlerFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter.filters) {
      if (filter.filters.length > 0) {
        this.dragAndDropService.onEditingMode();
      } else {
        this.dragAndDropService.onEditingClose();
        this.dragAndDropService.refreshDragAndDrop();
      }
    }
  }

  public onStateChange(state: State) {
    this.gridState = state;
    this.summaryPeakProductionRatesEditService.read();

    // const viewSubscription = this.view.subscribe(data => {
    //   this.dragAndDropService.dataSourceRefreshed(data.data);
    // });

    // viewSubscription.unsubscribe();
    // this.dragAndDropService.runZoneOnStable();
  }

  public getPerformanceBodPackage(performanceBodPadsId: string): any {
    if (
      this.performanceBodPackages === null ||
      (this.performanceBodPackages === undefined && this.performanceBodPads === null) ||
      this.performanceBodPads === undefined
    ) {
      return '';
    } else {
      const vpad = this.performanceBodPads.find(pad => pad.id === performanceBodPadsId);
      if (vpad !== null && vpad !== undefined) {
        return this.performanceBodPackages.find(pkg => pkg.id === vpad.performanceBodPackagesId);
      } else {
        return '';
      }
    }
  }

  public getPerformanceBodPad(performanceBodPadsId: string): any {
    if (this.performanceBodPads === null || this.performanceBodPads === undefined) {
      return '';
    } else {
      return this.performanceBodPads.find(pad => pad.id === performanceBodPadsId);
    }
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  public rowLoaded(): void {
    // to check until grid have been loeaded.
    this.dragAndDropService.checkIsOnEditMode();
    this.dragAndDropService.checkDraggableElementLoaded();
  }

  public saveFromBOD(): void {
    this.performanceExpectedPeakProductionRatesGrid.closeCell();
    this.performanceExpectedPeakProductionRatesGrid.cancelCell();

    if (this.summaryPeakProductionRatesEditService.hasChanges()) {
      this.spinner.show();
      // calls this to add the attribute of current row
      this.dragAndDropService.refreshDragAndDrop();
      this.summaryPeakProductionRatesEditService.saveChanges();
      this.performanceBodUpdate.next(this.authenticationService.getCurrentUser());
      const subscription = this.summaryPeakProductionRatesEditService.subscribe(() => {
        this.saveBodCompleted.next(true);
      });

      subscription.unsubscribe();
    }
  }

  private checkIsOnPadDelete(expectedPeaks: IPerformanceExpectedPeakProductionRates[]): void {
    this.IsClickedOnCancelButton = true;
    this.performanceBodStoreService.performanceBodPadsData$.subscribe(pads => {
      this.performanceBodCalculationsService.expectedPeakRateCalculations(expectedPeaks, pads);
    });
  }

  public addHandler({ sender }) {
    const item: IPerformanceExpectedPeakProductionRates = {
      id: Guid.create().toString(),
      isDeleted: false,
      createdBy: this.authenticationService.getCurrentUser(),
      createdDate: new Date(),
      updatedBy: this.authenticationService.getCurrentUser(),
      updatedDate: new Date(),
      oilBopd: null,
      waterBwpd: null,
      lpGasMmscfd: null,
      hpGasMmscfd: null,
      totalGasMmscfd: null,
      gasLiftMmscfd: null,
      totalCompressionMmscfd: null,
      comments: null,
      performanceBodPadsId: null,
      packageName: null,
      wellPadName: null,
      isWellPadNameDisabled: false,
      sortOrder: 0
    };

    sender.addRow(this.createFormGroup(item));
  }

  public changePackage(value, dataItem, formGroup: FormGroup) {
    let performanceBodPad: IPerformanceBodPads[];
    let packageName: string;
    let wellPadName: string;
    performanceBodPad = this.performanceBodPads.filter(s => s.id === value);
    performanceBodPad.forEach(element => {
      wellPadName = element.wellPadName;
      packageName = this.getPerformanceBodPackage(element.id).name;
    });

    dataItem.wellPadName = wellPadName;
    dataItem.packageName = packageName;
    formGroup.patchValue({
      packageName,
      wellPadName
    });
  }
}
