export enum CndCostLogicEnum {
  NO_MAPPING = -1,
  GREENFIELD_1TRAIN_CTB_INSTALL_19_DSGN_50_MBWD_MAX = 1,
  GREENFIELD_1TRAIN_CTB_INSTALL_19_DSGN_100_MBWD_MAX = 2,
  GREENFIELD_1TRAIN_CTB_INSTALL_17_18_DESIGN = 3,
  GREENFIELD_1TRAIN_CTB_INSTALL_20_DESIGN_8TANK_1TRAIN = 4,
  TRAIN1_CTB_ADDITION = 5,
  CTB_TANK = 6,
  CTB_HEADER = 7,
  CTB_TEST_SEPARATOR = 8,
  CTB_VRU_FX17 = 9,
  CTB_VRU_FX20 = 10,
  CTB_VRU_FX12 = 11,
  CTB_FLASH_GAS_COMPRESSOR_FX17 = 12,
  CTB_FLASH_GAS_COMPRESSOR_FX12 = 13,
  CTB_FLASH_GAS_COMPRESSOR_FX20 = 14,
  CTB_LACT = 15,
  CTB_WATER_TRANSFER_PUMP_17_18_DESIGN = 16,
  CTB_WATER_TRANSFER_PUMP_19_DESIGN = 17,
  CTB_BROWNFIELD_ADDER = 18,
  GREENFIELD_COMPRESSOR_STATION_INSTALL = 19,
  SATELLITE_BROWNFIELD_ADDER = 20,
  FLARE_SYSTEM_25_MMSCFD = 21,
  FLARE_SYSTEM_45_MMSCFD = 22,
  FLARE_SYSTEM_6_MMSCFD = 23,
  COMPRESSOR_STATION_VRU_FX8 = 24,
  COMPRESSOR_STATION_VRU_FX12 = 25,
  COMPRESSOR_STATION_VRU_FX17 = 26,
  COMPRESSOR_STATION_VRU_FX20 = 27,
  GREENFIELD_2TRAIN_SWD_INSTALL = 28,
  TRAIN1_SWD_ADDITION = 29,
  TRAIN2_SWD_ADDITION = 30,
  SWD_INJECTION_HPUMP = 31,
  SWD_BROWNFIELD_ADDER = 32,
  GREENFIELD_1TRAIN_CTB_INSTALL_20_DESIGN_8TANK_2TRAIN = 33,
  GREENFIELD_4TRAIN_SWD_INSTALL = 35
}
