export interface IFacilityAfe extends RequestInit {
  id: string;
  facilityBodId: string;
  name: string;
  number: string;
  amount: number;
  isDefault?: boolean;
  isDeleted: boolean;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
  type: string;
}

export class FacilityAfe implements IFacilityAfe {
  public id: string;
  public facilityBodId: string;
  public name: string;
  public number: string;
  public amount: number;
  public isDefault: boolean;
  public isDeleted: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public type: string;
}

export interface IFacilityAfeCostType {
  planCost: number;
  committedCost: number;
  spentCost: number;
  forecastCost: number;
  afeId: string;
}

export class FacilityAfeCostType implements IFacilityAfeCostType {
  public planCost: number;
  public committedCost: number;
  public spentCost: number;
  public forecastCost: number;
  public afeId: string;
}
