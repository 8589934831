import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { AuthenticationService } from '../../services/authentication.service';
import {
  RowClassArgs,
  GridComponent,
  ColumnReorderEvent,
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { IContacts } from '../../api/contacts-help';
import { setTimeout } from 'core-js';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent implements OnInit {
  gridView: GridDataResult;
  users: string;
  public usersListI: IContacts[];
  constructor(private utilService: UtilService, public authSvc: AuthenticationService) {
    this.usersListI = [];
  }
  ngOnInit() {
    this.authSvc.membersOfAdminGroup().subscribe(i => {
      this.users = i;
      // tslint:disable-next-line: no-unused-expression
      this.users = this.users.trim();
      const usersList = this.users.split(';');

      usersList.forEach(obj => {
        const fUsers = obj.split('°');
        this.usersListI.push({ name: fUsers[0], cai: fUsers[1] });
      });

      this.usersListI.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? (a.name > b.name ? 1 : -1) : -1));
      this.gridView = {
        data: this.usersListI,
        total: usersList.length,
      };
    });
  }

  isIE() {
    return this.utilService.isIE();
  }
}
