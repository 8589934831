<kendo-dialog title="Warning" *ngIf="openDialog" [minWidth]="250" width="450" (close)="closeKendoPopUp()">
  <p>{{ warningMessage }}</p>
</kendo-dialog>

<kendo-grid #performanceBodExistingFacilitiesGrid
            [data]="view | async"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            [navigable]="true"
            [resizable]="true"
            [sort]="gridState.sort"
            [sortable]="{ mode: 'multiple' }"
            (dataStateChange)="onStateChange($event)"
            filterable="menu"
            [filter]="filter"
            (filterChange)="handlerFilter($event)"
            class="scope-existing-facilities-draggable"
            [rowClass]="rowCallback">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            class="k-button"
            [disabled]="!scopeExistingFacilitiesEditService.hasChanges()"
            (click)="cancelChanges(performanceBodExistingFacilitiesGrid)"
            [primary]="true">
      Cancel
    </button>
    <button [icon]="'plus'" kendoGridAddCommand [primary]="true" [disabled]="!canEditPerformanceBod"></button>
  </ng-template>
  <kendo-grid-column title="" field="" [hidden]="true" width="1">
    {{ rowLoaded() }}
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodId" title="PerdoformanceBodId" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column field="name" title="Facility Name" [editable]="true">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input #input kendoGridFocusable type="text" [formControl]="formGroup.get(column.field)" class="k-textbox" />
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Facility Name already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Well Pad Name" field="performanceBodPadsId" [editable]="true">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="wellPadName"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPadsList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPads(dataItem.performanceBodPadsId)?.wellPadName }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist [data]="performanceBodPadsList"
                          textField="wellPadName"
                          valueField="id"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('performanceBodPadsId')"
                          (valueChange)="changePackage($event, dataItem, formGroup)">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          {{ getPerformanceBodPads(dataItem?.id)?.wellPadName }}
        </ng-template>
      </kendo-dropdownlist>
      <kendo-popup [anchor]="input" [hidden]="messageDuplicate" popupClass="k-widget k-tooltip k-tooltip-validation">
        The Facility Name already exist.
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="performanceBodPackagesId" title="Package Name" [editable]="false">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter [isPrimitive]="false"
                         [field]="column.field"
                         textField="name"
                         valueField="id"
                         [filterService]="filterService"
                         [currentFilter]="filter"
                         [data]="performanceBodPackagesList">
      </multicheck-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getPerformanceBodPackages(dataItem.performanceBodPadsId)?.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="readyForServiceDate"
                     title="Ready for Service Date"
                     [editable]="true"
                     editor="date"
                     width="150"
                     format="MM/dd/yyyy"
                     [filterable]="filterable"
                     filter="date">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.readyForServiceDate | date: 'MM/dd/yyyy' }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <kendo-datepicker [value]="dataItem.readyForServiceDate"
                        [format]="'MM/dd/yyyy'"
                        [formControl]="formGroup.get('readyForServiceDate')"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="sftDesignYear" title="SFT Design Year" [editable]="true">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <my-dropdown-filter [filter]="filter"
                          [data]="designYearList"
                          textField="text"
                          valueField="value"
                          value="sftDesignYear">
      </my-dropdown-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.sftDesignYear }}
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-dataItem="dataItem"
                 let-column="column"
                 let-formGroup="formGroup"
                 let-isNew="isNew">
      <kendo-dropdownlist popupAnchor
                          [data]="designYearList"
                          [defaultItem]="''"
                          textField="text"
                          valueField="value"
                          [valuePrimitive]="true"
                          [formControl]="formGroup.get('sftDesignYear')">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="scopeDefinition" title="Scope Definition" [editable]="true" width="180">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.scopeDefinition }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input kendoGridFocusable type="text" [formControl]="formGroup.get('scopeDefinition')" class="k-textbox" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="chargeCode"
                      title="Dummy Charge Code"
                      [editable]="true"
                      width="150">
</kendo-grid-column>
<kendo-grid-column field="cndHOEstimate" 
                     title="C&D/HO Estimate" 
                     [editable]="true" 
                     editor="numeric"
                     filter="numeric">
  </kendo-grid-column>
  <kendo-grid-column field="afeNumber"
                     title="AFE No."
                     [editable]="true"
                     width="150">

  </kendo-grid-column>

  <kendo-grid-column field="afeAmount"  
                    title="Approved AFE Amount" 
                    [editable]="true" 
                    editor="numeric" 
                    filter="numeric">
  </kendo-grid-column>

  <kendo-grid-column field="comments" title="Comments" [editable]="true" width="180">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.comments }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
      <input kendoGridFocusable type="text" [formControl]="formGroup.get('comments')" class="k-textbox" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="130">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridRemoveCommand [primary]="true" [icon]="'minus'" [disabled]="!canEditPerformanceBod"></button>
      <button kendoGridSaveCommand [primary]="true">Add</button>
      <button kendoGridCancelCommand [primary]="true">Cancel</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
