import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCCTemplateDirective } from './directives/fit-cc-template/fit-cc-template.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FitCCTemplateDirective],
  exports: [FitCCTemplateDirective],
})
export class FitCCTemplateModule {}
