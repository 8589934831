import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { PerformanceBodDataService } from 'src/app/services/performance-bod-data.service';
import { PerformanceBodStoreService } from 'src/app/services/performance-bod-store.service';
import { IPerformanceExpectedPeakProductionRates } from 'src/app/api/performance-expected-peak-production-rates';
import { PerformanceBodCalculationsService } from 'src/app/services/performance-bod-calculations.service';
import { IPerformanceBodPads } from 'src/app/api/performance-bod-pads';

const itemIndex = (item: any, data: any[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }
  return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root',
})
export class SummaryPeakProductionRatesEditService extends BehaviorSubject<any[]> {
  public performanceBodId: string;
  public data: IPerformanceExpectedPeakProductionRates[] = [];
  private originalData: IPerformanceExpectedPeakProductionRates[] = [];
  private createdItems: IPerformanceExpectedPeakProductionRates[] = [];
  private updatedItems: IPerformanceExpectedPeakProductionRates[] = [];
  private deletedItems: IPerformanceExpectedPeakProductionRates[] = [];
  private applySetOrder = false;
  private setOrderBy: string;

  constructor(
    private performanceBodDataService: PerformanceBodDataService,
    private performanceBodCalculationsService: PerformanceBodCalculationsService,
  ) {
    super([]);
  }

  public read() {
    if (this.data !== undefined) {
      if (this.data.length) {
        return super.next(this.data);
      }
    }

    this.performanceBodDataService.getPerformanceExpectedPeakProductionRates(this.performanceBodId).subscribe(returnedData => {
      this.data = returnedData;
      this.originalData = cloneData(returnedData);
      super.next(returnedData);
    });    
  }

  public create(item: any): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: any): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: any): void {
    if (item.isDeleted !== undefined) {
      item.isDeleted = true;
    }

    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: any): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length || this.updatedItems.length || this.createdItems.length || this.applySetOrder
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    // Apply order
    this.setOrder();

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceExpectedPeakProductionRates(this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.performanceBodDataService.updatePerformanceExpectedPeakProductionRates(this.updatedItems));
    }

    if (this.createdItems.length) {
      completed.push(this.performanceBodDataService.createPerformanceExpectedPeakProductionRates(this.createdItems));
    }

    this.reset();
    zip(...completed).subscribe(() => this.read());
  }

  public cancelChanges(): void {
    this.reset();
    this.read();
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  public markAsSetOrder(cai: string): void {
    this.setOrderBy = cai;
    this.applySetOrder = true;
  }

  public applyUnexpectedSumLogic(pads: IPerformanceBodPads[]): void {
    const expectedPeakChanges = this.performanceBodCalculationsService.expectedPeakRateCalculations(this.data, pads);
    for(const expectedPeak of expectedPeakChanges) {
      this.update(expectedPeak);
    }    
  }

  protected setOrder(): void {
    if (this.applySetOrder) {
      if (this.data.length > 0) {
        const obj = this.data[0];
        if (!obj.hasOwnProperty('sortOrder')) {
          return;
        }
      } else {
        // nothing to be order it
        return;
      }
      let order = 0;
      for (const dataItem of this.data) {
        dataItem.sortOrder = order;
        dataItem.updatedDate = new Date();
        dataItem.updatedBy = this.setOrderBy;
        dataItem.updatedDate = dataItem.updatedDate;
        this.update(dataItem);
        order++;
      }
    }
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
    this.applySetOrder = false;
  }
}
