<div class="card-body">
  <div class="row no-gutters">
    <div class="col-sm-12">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          Calculated Contingency
        </label>
        <div class="col-sm-8">
          <kendo-numerictextbox [readonly]="true"
                                [format]="'p'"
                                [decimals]=2
                                [step]="0.1"
                                [min]="0"
                                [max]="100"
          [autoCorrect]="true" [value]="contingencyData.valuePercentage"
                                [disabled]="!canEditCnd"></kendo-numerictextbox>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          Description of Estimate
        </label>
        <div class="col-sm-8">
          <kendo-dropdownlist [data]="listEstimateDescription"
                              (valueChange)="handleChangeEstimate($event)"
                              [value]="contingencyData.estimateDescription"
                              [disabled]="!canEditCnd">
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          How many development wells are currently producing in this field?
          <br>
          For a handover, consider only the number of wells in same/similar reservoirs and same/similar lateral lengths
        </label>
        <div class="col-sm-8">
          <kendo-dropdownlist [data]="listNumberOfDevWells"
                              (valueChange)="handleChangeNumWells($event)"
                              [value]="contingencyData.numberOfDevWells"
                              [disabled]="!canEditCnd">
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          What time frame does the estimate cover?
        </label>
        <div class="col-sm-8">
          <kendo-dropdownlist [data]="listEstimateTimeFrame"
                              (valueChange)="handleChangeTimeFrame($event)"
                              [value]="contingencyData.estimateTimeFrame"
                              [disabled]="!canEditCnd"></kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">
          Notes
        </label>
        <div class="col-sm-8">
          <textarea [formControl]="notes" kendoTextArea maxlength="120" [readOnly]="!canEditCnd"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
