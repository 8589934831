import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitCcExportToExcelComponent } from './fit-cc-export-to-excel.component';
import { Router, RouterModule } from '@angular/router';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule, DialogModule, ReactiveFormsModule],
  declarations: [FitCcExportToExcelComponent],
  exports: [FitCcExportToExcelComponent],
})
export class FitCcExportToExcelModule {}
