<kendo-dialog title="Get sequence data" *ngIf="openGetSequenceDialog" (close)="closeSequenceDialog()" [width]="600">
  <form novalidate [formGroup]="formGetSequence" id="getSequenceForm" #form="ngForm">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)" #tabStripSequence>
      <kendo-tabstrip-tab [title]="'Search for sequence'" [selected]="selectedOptionIndexTab1">
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Dev area:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="devArea"
                  [data]="devAreaList"
                  textField="text"
                  valueField="value"
                  (valueChange)="handleDevAreaChange($event, formGetSequence)"
                >
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Sequence status:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="sequenceStatus"
                  [data]="sequenceStatusList"
                  textField="text"
                  valueField="value"
                  (valueChange)="handleSequenceStatusChange($event, formGetSequence)"
                  [disabled]="!canEditCnd"
                >
                </kendo-dropdownlist>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label">
                  Sequence:
                </label>
              </div>
              <div class="col-sm-5">
                <kendo-dropdownlist
                  formControlName="sequence"
                  [data]="sequenceList"
                  textField="text"
                  [disabled]="disabled"
                  valueField="value"
                >
                </kendo-dropdownlist>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Provide link to SMART.'" [selected]="selectedOptionIndexTab2">
        <ng-template kendoTabContent>
          <br />
          <br />
          <div class="card-body-smart-link">
            <div class="row no-gutters">
              <div class="col-sm-10">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label class="col-form-label">
                      SMART link:
                    </label>
                  </div>
                  <div class="col-sm-6">
                    <textarea class="form-control" formControlName="smartLink" rows="5" maxlength="500"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeSequenceDialog()">Cancel</button>
    <button kendoButton primary="true" (click)="getSequenceById(formNewDasSimple)">Submit</button>
  </kendo-dialog-actions>
</kendo-dialog>

<form
  novalidate
  [formGroup]="formNewDasSimple"
  id="createNewDasSimpleForm"
  #form="ngForm"
  (ngSubmit)="saveDasSimple(null, null)"
>
  <div class="card-body-das-simple-detail">
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-4 offset-sm-12">
            <button
              kendoButton
              class="k-button"
              [disabled]="!isNewDasSimple || !canEditCnd || isGetSequenceComplete"
              (click)="openSequenceDialog()"
              type="button"
            >
              Get Sequence Data
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              DAS Project Name
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="name" [readonly]="!canEditCnd" />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              SMART Sequence Name
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="smartSeqName" readonly />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              SMART Sequence Link
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="smartSeqLink" readonly />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              SMART Last updated date
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="dasLastUpdatedDate" readonly />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              Development Area(s)
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="developmentAreasCode" readonly />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              State
            </label>
          </div>
          <div class="col-sm-6">
            <input type="text" class="k-textbox form-control" formControlName="stateCode" readonly />
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2">
            <label class="col-form-label">
              Notes
            </label>
          </div>
          <div class="col-sm-6">
            <textarea
              class="form-control"
              formControlName="comments"
              rows="5"
              maxlength="500"
              [readonly]="!canEditCnd"
            ></textarea>
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
    </div>
  </div>
</form>
